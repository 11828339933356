import React from "react";
import {freeSet} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {CDataTable, CTooltip} from "@coreui/react";
import {Surcharge} from "../../../../../models/retes";


type Props = {
    surcharges?: Surcharge[]
    onRemove?: (arg: Surcharge) => void;
    onEdit?: (arg: Surcharge) => void;
}


const SurchargesTable = ({surcharges, onRemove, onEdit}: Props) => {

    return (
        <CDataTable
            size="sm"
            hover
            scopedSlots={{
                actions: (e: Surcharge) => {
                    return <td className={"text-center"}>
                        <CTooltip content={"Edit"}>
                            <CIcon content={freeSet.cilPencil}
                                   style={{cursor: "pointer", marginRight: "5px"}}
                                   onClick={() => {
                                       onEdit?.(e)
                                   }}/>
                        </CTooltip>
                        <CTooltip content={"Delete"}>
                            <CIcon style={{cursor: "pointer"}} content={freeSet.cilTrash}
                                   onClick={() => onRemove?.(e)}/>
                        </CTooltip>

                    </td>
                },
                qualifier: (e: Surcharge) => {
                    return <td>
                        {e.qualifier ?? "-"}
                    </td>
                },
                groupingCode: (e: Surcharge) => {
                    return <td>
                        {e.groupingCode ?? "-"}
                    </td>
                },
            }}
            items={surcharges ?? []}
            fields={[
                {key: "type", label: "Surcharge"},
                {key: "calculationType", label: "Calculation Type"},
                {key: "order", label: "Order"},
                {key: "qualifier", label: "Qualifier"},
                {key: "allowDuplicate", label: "Allow Duplicate"},
                {key: "price", label: "Price"},
                {key: "minimumPrice", label: "Minimum Price"},
                {key: "groupingCode", label: "Grouping Code"},

                {key: "actions", label: "Actions", _style: {textAlign: "center"}}
            ]}
        />

    )
}

export default SurchargesTable;
