import {CContainer, CDataTable, CInputCheckbox, CTooltip} from "@coreui/react";
import React, {useEffect, useState} from "react";
import ShipClient from "../../../../clients/shipClient";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import GenerateReportModal from "../modals/GenerateReportModal";
import {faExclamationCircle, faEye} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {downloadBlob} from "../../../../utils/downloadUtils";
import {DiscrepancyCodRecord, GetDiscrepancyCodRecord} from "../../../../models/shipment";
import ShowDiscrepancyCODRecordsModal from "../modals/ShowDiscrepancyCODRecords";
import { useDispatch, useSelector } from "react-redux";

interface Props {
    setIsSubmitting: any
}

const PendingCODTable = ({setIsSubmitting}: Props) => {
    const shipClient = new ShipClient();
    const dispatch = useDispatch();
    const [showGenerateReport, setShowGenerateReport] = useState(false)
    const [reportData, setReportData] = useState<any>({})
    const [pendingCod, setPendingCod] = useState<any[]>([])
    const [args, setArgs] = useState<any>(["HasShipmentNumber", "ConsigneeCountry"])
    const [discrepancyRecords, setDiscrepancyRecords] = useState<DiscrepancyCodRecord[]>([])
    const [showDiscrepancyModal, setShowDiscrepancyModal] = useState<boolean>(false)
    const [loading,setLoading] = useState<boolean>(false)

    useEffect(() => {
       refresh()
    }, [args])

    const refresh = async () => {
        setLoading(true)
        const res = await shipClient.getPendingCOD(args)
        if(res){
            setPendingCod(res)
        }
        setLoading(false)
    }

    const handleArgs = (name: string) => {
        setPendingCod([])
        if (args.includes(name)) {
            setArgs([...args.filter((item: any) => item !== name)])
        } else {
            setArgs([...args, name])
        }
    }

    const handleHasTransferred = async (body: GetDiscrepancyCodRecord) => {
        const records = await shipClient.getCodRecord(body)
        if (!records)
            return
        setDiscrepancyRecords(records)
        setShowDiscrepancyModal(true)
    }

    const scopedSlots = {
        consigneeCountry: (item: any) => {
            return <td className={"text-center"}>{item.consigneeCountry ?? ""}</td>
        },
        payMode: (item: any) => {
            return <td className={"text-center"}>{item.payMode ?? ""}</td>
        },
        agent: (item: any) => {
            return <td className={"text-center"}>{item.agentName ?? ""}</td>
        },
        hasShipmentNumber: (item: any) => {
            return <td
                className={"text-center"}>{item.hasShipmentNumber == true ? "Yes" : item.hasShipmentNumber == false ? "No" : ""}</td>
        },
        totalShipments: (item: any) => {
            return <td className={"text-center"}>{item.totalShipments ?? ""}</td>
        },
        totalRecords: (item: any) => {
            return <td className={"text-center"}>{item.totalRecords ?? ""}</td>
        },
        totalAmount: (item: any) => {
            return <td style={{textAlign: 'end'}}>{item.totalAmount.toFixed(2) ?? ""}</td>
        },
        exportToExel: (item: any) => {
            return <td className={"text-end"}>
                <CTooltip content="Export to Exel">
                    <CContainer>
                        <CIcon content={freeSet.cilCloudDownload} size="lg"
                                         onClick={() =>
                                             shipClient.requestDownloadCOD(item.account, item.consigneeCountry, item.payMode, item.agent, item.hasShipmentNumber)
                                                 .then((res: any) => downloadBlob(res.location, res.name))}
                                         style={{cursor: "pointer"}}
                        />
                    </CContainer>
                </CTooltip>
            </td>
        },
        hasTransfer: (item: any) => {
            return item.hasTransfer ? <td className={"text-end"}>
                <CTooltip content="Discrepancy">
                    <CContainer>
                        <FontAwesomeIcon icon={faExclamationCircle} size="lg"
                                         onClick={() => handleHasTransferred({
                                             account: item.account,
                                             payMode: item.payMode,
                                             consigneeCountry: item.consigneeCountry,
                                             agent: item.agent,
                                             hasShipmentNumber: item.hasShipmentNumber
                                         })}
                                         color={"#FF8800"}
                                         style={{cursor: "pointer"}}
                        />
                    </CContainer>
                </CTooltip>
            </td> : <td></td>
        },
        generateReport: (item: any) => {
            return <td className={"text-center"}>
                <CTooltip content="Generate Report">
                    <CIcon
                        content={freeSet.cilDescription}
                        onClick={() => {
                            setReportData({
                                Account: item.account,
                                PayMode: item.payMode,
                                ConsigneeCountry: item.consigneeCountry,
                                Agent: item.agent,
                                hasShipmentNumber: item.hasShipmentNumber
                            })
                            setShowGenerateReport(!showGenerateReport)
                        }}
                        style={{cursor: "pointer"}}
                    />
                </CTooltip>
            </td>
        }
    }

    return (
        <>
            <CContainer>
                {showGenerateReport &&
                    <GenerateReportModal isShowing={showGenerateReport} setIsSubmitting={setIsSubmitting}
                                         onClose={() => setShowGenerateReport(!showGenerateReport)} data={reportData}/>}
                <CDataTable
                    loading={loading}
                    size="sm"
                    hover
                    columnHeaderSlot={{
                        payMode:
                            <><CInputCheckbox
                                style={{top: "0"}}
                                onChange={() => handleArgs("PayMode")}
                            />Payment type</>,
                        consigneeCountry:
                            <><CInputCheckbox
                                style={{top: "0"}}
                                checked={args.includes("ConsigneeCountry")}
                                onChange={() => handleArgs("ConsigneeCountry")}
                            />Country</>,
                        agent:
                            <><CInputCheckbox
                                style={{top: "0"}}
                                onChange={() => handleArgs("Agent")}
                            />Agent</>,
                        hasShipmentNumber:
                            <><CInputCheckbox
                                style={{top: "0"}}
                                checked={args.includes("HasShipmentNumber")}
                                onChange={() => handleArgs("HasShipmentNumber")}
                            />Has Shipment</>,
                    }}
                    scopedSlots={scopedSlots ?? {}}
                    items={Array.isArray(pendingCod) ? pendingCod : []}
                    fields={[
                        {key: "exportToExel", label: "", _style: {width: '5%', padding: '0.2rem'}},
                        {key: "hasTransfer", label: "", _style: {width: '5%', padding: '0.2rem'}},
                        {key: "accountName", label: "Account"},
                        {key: "hasShipmentNumber", label: "Has Shipment", _style: {textAlign: "center"}},
                        {key: "consigneeCountry", label: "Country", _style: {textAlign: "center"}},
                        {key: "payMode", label: "Payment type", _style: {textAlign: "center"}},
                        {key: "agent", label: "Agent", _style: {textAlign: "center"}},
                        {key: "totalShipments", label: "Shipment", _style: {textAlign: "center"}},
                        {key: "totalRecords", label: "Payments", _style: {textAlign: "center"}},
                        {key: "totalAmount", label: "Amount", _style: {textAlign: "end"}},
                        {
                            key: "generateReport",
                            label: "Actions",
                            _style: {textAlign: "center", width: '5%', padding: '0.2rem'}
                        }

                    ]}
                />
            </CContainer>

            {showDiscrepancyModal && <ShowDiscrepancyCODRecordsModal handleClose={() => setShowDiscrepancyModal(false)}
                                                                     discrepancyCODRecords={discrepancyRecords}/>}
        </>
    )
}

export default PendingCODTable