import React, { Fragment, useState } from "react";
import { CContainer } from "@coreui/react";
import AddForm from './components/addForm';
import PrintedShipmentTable from "../print/tables/printedShipmentTable";




const Reprint = () => {
    const [refreshPrintedShipmentTable,setRefreshPrintedShipmentTable] = useState<boolean>();
    return (
        <Fragment>
             <CContainer className="bg-white px-4 py-2 text-primary small">
                <AddForm refreshPrintedShipmentTable={()=>setRefreshPrintedShipmentTable(!refreshPrintedShipmentTable)} />
            </CContainer>
            <CContainer className="bg-white px-4 py-2 text-primary small">
                <PrintedShipmentTable refreshPrintedShipmentTable={refreshPrintedShipmentTable}/>
            </CContainer>
        </Fragment>
    )
}

export default Reprint

