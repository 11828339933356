import {CButton, CCol, CInput, CRow} from "@coreui/react";
import {Input} from "../../../SharedComponents/SWForm";
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {InvoiceBillingAddress, InvoiceStatus} from "../../../../models/accounting";
import SWInput from "../../../SharedComponents/SWInput";
import SWSelect from "../../../SharedComponents/SWSelect";
import LookupsClient from "../../../../clients/lookupsClient";
import AccountingClient from "../../../../clients/accountingClient";

interface IProps {
    billingAddress?: InvoiceBillingAddress
    account?: string
    active: boolean
    onSubmit: (billingAddress: InvoiceBillingAddress) => void
}

const InvoiceBillingAddressForm = (props: IProps) => {
    const lookupsClient = new LookupsClient();
    const accountingClient = new AccountingClient();
    const [countryLookups, setCountryLookups] = useState<{ [k: string]: string }>({})
    const {values, handleChange, handleSubmit, setFieldValue, setValues} = useFormik<InvoiceBillingAddress>({
        initialValues: {
            companyName: props.billingAddress?.companyName ?? "",
            contactName: props.billingAddress?.contactName ?? "",
            email: props.billingAddress?.email ?? "",
            street: props.billingAddress?.street ?? "",
            addressLine2: props.billingAddress?.addressLine2 ?? "",
            city: props.billingAddress?.city ?? "",
            country: props.billingAddress?.country ?? "",
            state: props.billingAddress?.state ?? "",
            zipcode: props.billingAddress?.zipcode ?? "",
            phone: props.billingAddress?.phone ?? "",
            vat: props.billingAddress?.vat ?? "",
            eori: props.billingAddress?.eori ?? ""
        },
        onSubmit: (val => props.onSubmit(val))
    })

    useEffect(() => {
        lookupsClient.getCountries().then(v => setCountryLookups(v))
    }, [])

    const handleReset = async () => {
        if (!props.account) return
        const account = await accountingClient.GetAccount(props.account)
        props.onSubmit(account.billingAddress)
    }

    return (
        <fieldset className="b-fieldset p-2">
            <legend className="b-legend">
                <h5>Billing Address</h5>
            </legend>
            <CRow>
                <CCol className={"col-sm-8 pr-0"}>
                    <CRow>
                        <CCol className="col-sm-3 pr-0">
                            <SWInput
                                label="Company Name"
                                name="companyName"
                                value={values.companyName}
                                readonly={!props.active}
                                onChangeFormik={handleChange}
                            />
                        </CCol>
                        <CCol className="col-sm-3 pr-0">
                            <SWInput
                                label="Contact Name"
                                name="contactName"
                                value={values.contactName}
                                readonly={!props.active}
                                onChangeFormik={handleChange}
                            />
                        </CCol>
                        <CCol className={"col-sm-3 pr-0"}>
                            <SWInput
                                value={values.email}
                                name={"email"}
                                label={"Email"}
                                readonly={!props.active}
                                onChangeFormik={handleChange}
                            />
                        </CCol>
                        <CCol className={"col-sm-3"}>
                            <SWInput
                                value={values.phone}
                                name={"phone"}
                                label={"Phone"}
                                readonly={!props.active}
                                onChangeFormik={handleChange}
                            />
                        </CCol>
                    </CRow>

                    <CRow>
                        <CCol className="col-sm-3 pr-0">
                            <SWInput
                                label="Street"
                                name="street"
                                value={values.street}
                                readonly={!props.active}
                                onChangeFormik={handleChange}
                            />
                        </CCol>
                        <CCol className="col-sm-3 pr-0">
                            <SWInput
                                label="Additional Address"
                                name="addressLine2"
                                value={values.addressLine2}
                                readonly={!props.active}
                                onChangeFormik={handleChange}
                            />
                        </CCol>
                        <CCol className="col-sm-6">
                            <CRow>
                                <CCol className="col-sm-4">
                                    <SWInput
                                        label="Zip Code"
                                        name="zipcode"
                                        value={values.zipcode}
                                        readonly={!props.active}
                                        onChangeFormik={handleChange}
                                    />
                                </CCol>
                                <CCol className="col-sm-8">
                                    <SWInput
                                        label="City"
                                        name="city"
                                        value={values.city}
                                        readonly={!props.active}
                                        onChangeFormik={handleChange}
                                    />
                                </CCol>
                            </CRow>
                        </CCol>
                    </CRow>
                </CCol>

                <CCol className="col-sm-4">
                    <CRow>
                        <CCol className="col-sm-6">
                            <SWInput
                                label="VAT"
                                name="vat"
                                value={values.vat}
                                readonly={!props.active}
                                onChangeFormik={handleChange}
                            />
                        </CCol>
                        <CCol className="col-sm-6">
                            <SWInput
                                label="Eori"
                                name="eori"
                                value={values.eori}
                                readonly={!props.active}
                                onChangeFormik={handleChange}
                            />
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol className="col-sm-12">
                            {" "}
                            <SWSelect
                                values={countryLookups}
                                label="Country"
                                name="country"
                                value={values.country}
                                readonly={!props.active}
                                onChange={v => setFieldValue("country", v)}
                            />
                        </CCol>
                    </CRow>
                </CCol>
            </CRow>
            {props.active &&
                <CRow>
                    <CCol className={"text-right"}>
                        <CButton
                            color={"primary"}
                            size={"sm"}
                            onClick={handleReset}
                        >Reset</CButton>
                        <CButton
                            className={"ml-2"}
                            color={"primary"}
                            size={"sm"}
                            onClick={handleSubmit}
                        >Save</CButton>
                    </CCol>
                </CRow>}
        </fieldset>)
}

export default InvoiceBillingAddressForm