import React from "react";
import { CModal, CModalHeader, CModalBody, CContainer, CRow, CCol, CModalFooter, CButton } from "@coreui/react";
import { dutiableOptions, RateEntry } from "../../../../../models/retes";
import SWInput from "../../../../SharedComponents/SWInput";
import SWSelect from "../../../../SharedComponents/SWSelect";
import { number, object, string } from "yup";
import { useFormik } from "formik";

type Props = {
    valuesToUpdate?: RateEntry;
    onClose: () => void
    onAdd?: (arg: RateEntry) => void;
    onUpdate?: (arg: RateEntry) => void;
    isUpdate?: boolean
}
const RatesEntryModal = ({ valuesToUpdate, onClose, onAdd, onUpdate, isUpdate }: Props) => {

    const initialValues: RateEntry = {
        dutiable: valuesToUpdate?.dutiable ?? "",
        originZone: valuesToUpdate?.originZone ?? "",
        destinationZone: valuesToUpdate?.destinationZone ?? "",
        from: valuesToUpdate?.from ?? 0,
        break: valuesToUpdate?.break ?? 0,
        step: valuesToUpdate?.step ?? 0,
        price: valuesToUpdate?.price ?? 0,
    }
    const validationSchema = () => object().shape({
        dutiable: string().required("Required"),
        originZone: string().required("Required"),
        destinationZone: string().required("Required"),
        from: number().required("Required"),
        break: number().required("Required"),
        step: number().required("Required"),
        price: number().required("Required"),
    })
    const handleSubmit = async (values: RateEntry, { resetForm }: any) => {
        isUpdate ? onUpdate?.(values) : onAdd?.(values)
        resetForm()
        onClose()
    }
    const formik = useFormik<RateEntry>({
        initialValues: initialValues,
        onSubmit: handleSubmit,
        validationSchema: validationSchema
    })
    const { values, setFieldValue, touched, errors, submitForm } = formik

    return (
        <CModal
           closeOnBackdrop={false}
            show={true}
            size={"xl"}
            className="small text-primary"
            centered
            onClose={() => onClose()}
        >
            <CModalHeader closeButton>
                <h5>Rates</h5>
            </CModalHeader>
            <CModalBody>
                <CContainer className="form-group mb-0 mt-2">
                    <CRow>
                        <CCol className="col-sm-3">
                            <SWSelect
                                label="Dutiable"
                                name="dutiable"
                                value={values?.dutiable ?? ""}
                                onChange={(e) => {
                                    setFieldValue("dutiable", e)
                                }}
                                values={dutiableOptions}
                                validationErrors={touched?.dutiable && errors?.dutiable?.toString() || ""}
                            />
                        </CCol>
                        <CCol className="col-sm-3">
                            <SWInput
                                label="Origin Zone"
                                name="originZone"
                                value={values?.originZone ?? ""}
                                onChange={(e) => {
                                    setFieldValue("originZone", e)
                                }}
                                validationErrors={touched?.originZone && errors?.originZone?.toString() || ""}
                            />
                        </CCol>
                        <CCol className="col-sm-3">
                            <SWInput
                                label="Destination Zone"
                                name="destinationZone"
                                value={values?.destinationZone ?? ""}
                                onChange={(e) => {
                                    setFieldValue("destinationZone", e)
                                }}
                                validationErrors={touched?.destinationZone && errors?.destinationZone?.toString() || ""}
                            />
                        </CCol>
                        <CCol className="col-sm-3" />
                        <CCol className="col-sm-3">
                            <SWInput
                                label="From"
                                name="from"
                                value={values?.from?.toString() ?? ""}
                                onChange={(e) => {
                                    setFieldValue("from", e)
                                }}
                                validationErrors={touched?.from && errors?.from?.toString() || ""}
                            />
                        </CCol>
                        <CCol className="col-sm-3">
                            <SWInput
                                label="Break"
                                name="break"
                                value={values?.break?.toString() ?? ""}
                                onChange={(e) => {
                                    setFieldValue("break", e)
                                }}
                                validationErrors={touched?.break && errors?.break?.toString() || ""}
                            />
                        </CCol>
                        <CCol className="col-sm-3">
                            <SWInput
                                type="number"
                                label="Step"
                                name="step"
                                value={values?.step.toString() ?? ""}
                                onChange={(e) => {
                                    setFieldValue("step", e)
                                }}
                                validationErrors={touched?.step && errors?.step?.toString() || ""}
                            />
                        </CCol>
                        <CCol className="col-sm-3">
                            <SWInput
                                type="number"
                                label="Price"
                                name="price"
                                value={values?.price.toString() ?? ""}
                                onChange={(e) => {
                                    setFieldValue("price", e)
                                }}
                                validationErrors={touched?.price && errors?.price?.toString() || ""}
                            />
                        </CCol>
                    </CRow>
                </CContainer>
            </CModalBody>
            <CModalFooter>
                <CButton
                    size="sm"
                    style={{ fontSize: "10px" }}
                    className="mr-2"
                    color="primary"
                    onClick={() => submitForm()}
                >
                    Save
                </CButton>
            </CModalFooter>
        </CModal>
    )
}

export default RatesEntryModal;