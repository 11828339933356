import {freeSet} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {CButton, CCardBody, CCol, CRow, CTooltip} from "@coreui/react";
import React, {useState} from "react";
import {errorLevel, errorSource, ShipmentGet, ShipmentSearch} from "../../../models/shipment";
import SendSMSModal from "./modals/SendSMSModal";
import ShipClient from "../../../clients/shipClient";
import {ShowNotification} from "../../../store/actions/auth";
import {useDispatch} from "react-redux";

interface IProps {
    shipment: ShipmentGet;
    onEditClick: () => any;
    countries: { [key: string]: string };
    onUpdateShipment: (shipment: ShipmentSearch) => void;
}

const getRecieverGoogleMapUrl = (shipment: ShipmentGet): string => {
    const url = "https://www.google.com/maps/place/";
    let p = "";

    if (shipment?.consigneeAddressLine1)
        p += shipment.consigneeAddressLine1 + "+";

    if (shipment?.consigneePostCode) p += shipment.consigneePostCode + "+";
    if (shipment?.consigneeCity) p += shipment.consigneeCity + "+";

    return `${url}${p}`;
};

const ViewReceiver = (props: IProps) => {
    const {shipment, countries, onUpdateShipment} = props;
    const shipClient = new ShipClient();
    const dispatch = useDispatch()
    const [isHover, setisHover] = useState(false);
    const recieverGoogleUrl = getRecieverGoogleMapUrl(shipment);
    const [showSendSMSModal, setShowSendSMSModal] = useState<boolean>(false);

    const handleSendSMS = async (message: string) => {
        const trace = {
            code: "SHDT003",
            comment1: message,
            actionDate: new Date()
        }
        const to = shipment.consigneePhone
        let rs = await shipClient.sendSMSToPhoneNumber(shipment.uid, {to, body: message})
        let traces = shipment.traces
        traces.push((trace as any))
        if (rs.succeeded) {
            let addTraceRS = await shipClient.AddTrace(shipment.number, (trace as any))
            if (!addTraceRS.succeeded) return;
            onUpdateShipment({...shipment, traces})
            dispatch(ShowNotification("Success", "SMS sent successfully", false))
        }
        setShowSendSMSModal(false)
    }

    return (
        <>
            <CCardBody
                className={` receiverCard mb-1 rounded  ${
                    isHover ? "bg-secondary" : ""
                }`}
                onMouseOver={() => {
                    setisHover(true);
                }}
                onMouseLeave={() => {
                    setisHover(false);
                }}
            >
                <CRow className="mb-2">
                    <CTooltip content="GoogleMaps location">
                        <CIcon
                            className="ml-3 mr-2"
                            content={freeSet.cilLocationPin}
                            size={"lg"}
                            onClick={() => {
                                window.open(recieverGoogleUrl);
                            }}
                        />
                    </CTooltip>
                    <h6>
                        <strong>Receiver:</strong>
                    </h6>
                    <CCol className="text-right">
                        <CIcon
                            style={{cursor: "pointer"}}
                            className={`${isHover ? "primary" : "text-white"}`}
                            content={freeSet.cilPencil}
                            size={"lg"}
                            onClick={props.onEditClick}
                        />
                    </CCol>
                </CRow>

                <CRow>
                    <CCol>Company Name:</CCol>
                    <CCol sm={8}>
                        {" "}
                        <strong>{shipment.consigneeCompanyName} </strong>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>Contact Name:</CCol>
                    <CCol sm={8}>
                        <strong>{shipment.consigneeName}</strong>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>Street:</CCol>
                    <CCol sm={8}>
                        <strong> {shipment.consigneeAddressLine1}</strong>{" "}
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>Add Extra:</CCol>
                    <CCol sm={8}>
                        <strong>
                            {shipment.consigneeAddressLine2}
                            {shipment.consigneeAddressLine3}
                        </strong>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>Country:</CCol>
                    <CCol sm={8}>
                        <strong>{countries[shipment.consigneeCountry]}</strong>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>Zip Code/City:</CCol>
                    <CCol sm={8}>
                        <strong>
                            {shipment.consigneePostCode}, {shipment.consigneeCity}
                        </strong>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>Reference:</CCol>
                    <CCol sm={8}>
                        <strong>
                            {shipment.consigneeReference}
                        </strong>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>Address Reference:</CCol>
                    <CCol sm={8}>
                        <strong>
                            {shipment.consigneeAddressReference}
                        </strong>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>Consignee EORI:</CCol>
                    <CCol sm={8}>
                        <strong>
                            {shipment?.consigneeEori}
                        </strong>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>Telephone:</CCol>
                    <CCol sm={8}>
                        <strong>{shipment.consigneePhone}
                            {
                                ("" + shipment.consigneePhone).length > 5 ?
                                    <CTooltip content="Send SMS">
                                        <CButton size="sm">
                                            <CIcon onClick={() => {
                                                setShowSendSMSModal(true)
                                            }} size={"lg"} content={freeSet.cilSpeech}/>
                                        </CButton>
                                    </CTooltip>
                                    : null
                            }
                            {shipment.errors?.map((ship) => {
                                return ship.level == errorLevel.warning ? ship.source == errorSource.SN ?
                                    <span style={{color: '#D3E24D'}}>{ship.message}</span> : null : null
                            })}
                        </strong>

                    </CCol>
                </CRow>
                <CRow>
                    <CCol>Email:</CCol>
                    <CCol sm={8}>
                        <strong>{shipment.consigneeEmail}</strong>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>Notes:</CCol>
                    <CCol sm={8}>
                        <strong>{shipment.consigneeNotes}</strong>
                    </CCol>
                </CRow>
                <CRow></CRow>
            </CCardBody>
            {showSendSMSModal && <SendSMSModal
                HandleClose={() => setShowSendSMSModal(false)}
                handleSubmit={handleSendSMS}
            />}
        </>
    );
};

export default ViewReceiver;
