import AccountingClient from "../../../clients/accountingClient";
import {useEffect, useState} from "react";
import {SearchModel} from "../../../models/app";
import {ChargeIsGroupedSearch, ChargeSearch, ChargeSearchFilters} from "../../../models/accounting";
import {CCol, CContainer, CSpinner} from "@coreui/react";
import ChargesSearchComponent from "./components/ChargesSearchComponent";
import ChargesTable from "./components/ChargesTable";
import LookupsClient from "../../../clients/lookupsClient";
import {ChargeType} from "../../../models/lookups";

const ViewCharges = () => {

    const accountingClient = new AccountingClient()
    const lookupsClient=new LookupsClient()
    const [chargeTypes, setChargeTypes] = useState<{ [k: string]: string }>({})
    const [data, setData] = useState<SearchModel<ChargeSearch| ChargeIsGroupedSearch >>()
    const [loader, setIsLoader] = useState(false)
    const [params, setParams] = useState<ChargeSearchFilters>({excludeDeleted:true})
    const onSubmitFilter = (filterData: ChargeSearchFilters) => {
        setParams({...filterData, offset: 0, limit: 20})
        fetchData({...filterData, offset: 0, limit: 20}).then()
    }
    const onPagination = (filterData: ChargeSearchFilters) => {
        fetchData({...params, offset: filterData.offset ?? 0, limit: filterData.limit ?? 20}).then()

    }
    const fetchData = async (chargeParams: ChargeSearchFilters) => {
        const {isGroupedSearch,...rest}=chargeParams

        setIsLoader(true)
        if(isGroupedSearch){
        const res = await accountingClient.SearchChargeGrouped(rest)
        if (res?.matches) setData(res)
}
        else {
            const res = await accountingClient.SearchCharge(rest)
            if (res?.matches)
                setData(res)
        }
        setIsLoader(false)
    }

    const onRefresh=()=>{
        fetchData(params).then()
    }
    useEffect(() => {
        lookupsClient.getCharges({availableOn: "shipment", format: 1}).then((c) => setChargeTypes(c))
        fetchData({...params,offset:0,limit:20}).then()
    }, []);

    return (
        <>
            <CContainer className="bg-white p-4">
                <ChargesSearchComponent chargesType={chargeTypes}  initialValues={params} onSubmit={onSubmitFilter}/>
                {loader ? <CSpinner className="mx-auto d-block my-5"
                                    color="primary"
                                    style={{width: "5em", height: "5em"}}/> :
                    <ChargesTable onRefresh={onRefresh} chargeTypes={chargeTypes} accountClient={accountingClient} isGrouped={params?.isGroupedSearch} total={data?.total ?? 0} offset={data?.offset ?? 0} data={data?.matches as ChargeSearch[] | ChargeIsGroupedSearch[]}
                                  limit={data?.limit ?? 20}
                                  onChangePagination={(p) => onPagination({offset: p.offset, limit: p.limit})}/>

                }
            </CContainer>
        </>
    )


}

export default ViewCharges
