import {
    CFormGroup,
    CFormText,
    CInput,
    CInputGroup,
    CInputGroupAppend,
    CInputGroupPrepend,
    CInputGroupText,
    CLabel
} from "@coreui/react";
import React, { CSSProperties } from "react";
import ReactSelect, { StylesConfig } from 'react-select';

import CIcon from "@coreui/icons-react";
import {ValueType} from "react-select/src/types";


interface MultiOptions {
    value: string;
    label: string;
}

const customMultiStyles: CSSProperties = {
    fontSize: "10px",
    backgroundColor: "#f7f7f7",
}

type IsMulti = false;

const selectStyle: StylesConfig<MultiOptions, IsMulti> = {
    control: (provided, state) => {
        return {
            ...provided,
            ...customMultiStyles,
            minHeight: "26px",
            backgroundColor: state.isDisabled? provided.backgroundColor : "none"
        };
    },
    indicatorsContainer: (provided, state) => {
        return {
            ...provided,
            ...customMultiStyles,
            height: "20px",
            marginTop: "3px",
            marginBottom: "3px",
            backgroundColor: state.isDisabled? provided.backgroundColor : "none"


        };
    },
    valueContainer: (provided, state) => {
        return {
            ...provided,
            ...customMultiStyles,
            height: "20px",
            marginTop: "0px",
            marginBottom: "3px",
            paddingTop: "0px",
            backgroundColor: "none"

        };
    },

    multiValue: (provided, state) => {
        return {
            ...provided,
            alignItems: "center",


        };
    },
    indicatorSeparator:(provided,state)=>{
        return{
            ...provided,
            backgroundColor:"none",
        }
    },

    dropdownIndicator:(provided,state)=>{
        return{
            ...provided,
            height: "26px",
            marginBottom:"14px"
        }
    },
};



interface Props {
    style?: React.CSSProperties;
    readonly?: boolean;
    label?: string;
    className?: string;
    append?: any;
    onChange?: (val?: any) => void
    onBlur?: (e: any) => void
    onFocus?: () => void
    icon?: any
    maxLength?: number
    id?: string
    name?: string
    key?: any
    //value?: {value: string, label: string}[] | null
    values: { [key: string]: string }
    validationErrors?: string
    isMulti?: boolean
    hideArrow?: boolean
    value?: any
}

const SWMultiSelect = (props: Props) => {
    const {
        key,
        id,
        name,
        label,
        append,
        icon,
        className,
        style,
        readonly,
        onChange,
        maxLength,
        onBlur,
        onFocus,
        validationErrors,
        values,
        hideArrow,
        value
    } = props;
    const options = values ? Object.entries(values).map((v) => ({
        label: v[1],
        value: v[0],
    })) : undefined;


    const getSelectedOptions = (vals: string[]) => options ? options.filter(o => vals.includes(o.value)) : null;
    const getSelectedOption = (val: string) => options ? options.find(o => o.value == val) : null;



    const handleChange = (e: any) => onChange && onChange(e.e.value);





    return (
        <div className={className} style={style}>
    <CFormGroup>
        {label && <CLabel className={"small"}>{label}</CLabel>}
        <CInputGroup>
        {icon && (
            <CInputGroupPrepend>
                <CInputGroupText>
                    <CIcon content={icon}/>
    </CInputGroupText>
    </CInputGroupPrepend>
)}
    <ReactSelect
    components={hideArrow ? {DropdownIndicator: () => null, IndicatorSeparator: () => null} : undefined}
    onFocus={(e) => onFocus ? onFocus() : undefined}
    isMulti={true}
    styles={selectStyle}
    style={{fontSize: "10px"}}
    maxLength={maxLength}
    readOnly={readonly}
    isDisabled={readonly}
    className="w-100"
    classNamePrefix="mySelect"
    id={id}
    onBlur={(e) => onBlur ? onBlur(e) : undefined}
    name={name}
    key={key}
    options={options}
    onChange={onChange}
    value={value}
    />
    {append && (
        <CInputGroupAppend>
            <CInputGroupText>{append}</CInputGroupText>
        </CInputGroupAppend>
    )}
    </CInputGroup>
    {validationErrors ? (
        <CFormText
            style={{fontSize: "1em"}}
        color="danger"
        className="help-block">
            {validationErrors}

            </CFormText>
    ) : (
        ""
    )}
    </CFormGroup>
    </div>
);
};

    export default SWMultiSelect;
