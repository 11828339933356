import {CContainer} from "@coreui/react";
import React from "react";

const ShipmentBlockedStamp = () => {
    return (
        <>
            <CContainer style={{left:'15%', top:"30%"}} size={100} className="stampContainer mb-1">
                <h4 className="stampText-blocked text-warning">Blocked Shipment</h4>
            </CContainer>
        </>
    );
}

export default ShipmentBlockedStamp;