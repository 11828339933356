const accessTokenName = "accessToken";
const refreshTokenName = "refreshToken";

export default {
  getAccessToken: () => getCookieValue(accessTokenName),
  getRefreshToken: () => getCookieValue(refreshTokenName),
  setAccessToken: (val: string) => setCookieValue(accessTokenName, val),
  setRefreshToken: (val: string) => setCookieValue(refreshTokenName, val),
  setCookieValue: (name: string, val: string) => setCookieValue(name, val),
  setCookieValues,
  getCookieValue,
  deleteAllCookies,
  deleteCookie,
};

function deleteAllCookies() {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

    // Set the cookie's expiry date to a past date
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";
  }
}

function setCookieValue(key: string, value: string): void {
  document.cookie = `${key}=${value}`;
}

function deleteCookie(cookieName: string) {
  document.cookie = `${cookieName}=; expires=${new Date().toUTCString()}; path=/;`;
}

function getCookieDictionary(cookie: string): { [k: string]: string } {
  return cookie
    .split(";")
    .map((cv) => cv.split("="))
    .reduce<{ [k: string]: string }>((pv, cv) => {
      const pvCopy = {
        ...pv,
      };
      pvCopy[cv[0].trim()] = cv[1];
      return pvCopy;
    }, {});
}

function setCookieValues(values: { [k: string]: string }) {
  for (let entry in Object.entries(values)) {
    setCookieValue(entry[0], entry[1]);
  }
}

function getCookieValue(key: string): string {
  const val = getCookieDictionary(document.cookie)[key];

  return val;
}
