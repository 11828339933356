import {
    CModal,
    CModalBody,
    CButton,
    CModalHeader,
    CRow,
    CCol, CContainer,
} from "@coreui/react";
import React from "react";
import {Field, Form, Formik} from "formik";
import {ShowNotification} from "../../../../store/actions/auth";
import InputField from "../../../SharedComponents/FormikCustomFields/InputField";
import DateInputField from "../../../SharedComponents/FormikCustomFields/DateInputField";
import {date, object, string} from "yup";
import ShipClient from "../../../../clients/shipClient";
import {useDispatch} from "react-redux";
import {downloadBlob} from "../../../../utils/downloadUtils";
import ActionButton from "../../../SharedComponents/ActionButton";

interface IProps {
    isShowing: boolean;
    onClose: () => any;
    data: {
        account: string;
        payMode: string | null;
        consigneeCountry: string | null;
        agent: string | null | number;
    }
    setIsSubmitting: any
}

const GenerateReportModal = (props: IProps) => {
    const shipClient = new ShipClient();
    const dispatch = useDispatch()
    const {isShowing, setIsSubmitting, data, onClose} = props;

    const initialValues = {
        DocumentNumber: "",
        DocumentDate: new Date()
    }

    const handleSubmit = async (e: any) => {
        const body = {...data, ...e}
        setIsSubmitting(true)
        const rs = await shipClient.generateReport(body)
        setIsSubmitting(false)
        if (rs.succeeded) {
            dispatch(ShowNotification("Success", "Report was generated successfully", false))
            downloadBlob(rs.data.location, `${e.DocumentNumber}_${new Date()}`);
        }
        onClose()
    }

    const validationSchema = () => object().shape({
        DocumentNumber: string().required("required"),
        DocumentDate: date().required("required"),
    })

    return (
        <>
            <CModal centered={true} show={isShowing} onClose={onClose}>
                <CModalHeader>
                    <h5>Generate Report</h5>
                </CModalHeader>
                <CModalBody>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        {() => (
                            <Form>
                                <CContainer className="form-group mb-0">
                                    <CRow>
                                        <CCol className="col-sm-6">
                                            <Field type="text" name="DocumentNumber" label={"Document Number"}
                                                   component={InputField}/>
                                        </CCol>
                                        <CCol className="col-sm-6">
                                            <Field label="Document Date" type="text" name="DocumentDate"
                                                   component={DateInputField}/>
                                        </CCol>
                                    </CRow>
                                </CContainer>
                                <CRow>
                                    <CCol>
                                        <CButton
                                            size="sm"
                                            style={{fontSize: "10px"}}
                                            variant="outline"
                                            color="danger"
                                            onClick={onClose}
                                        >
                                            Cancel
                                        </CButton>
                                    </CCol>
                                    <CCol md={2} className="text-right">
                                        <ActionButton
                                            text={"Generate"}
                                        />
                                    </CCol>
                                </CRow>
                            </Form>
                        )}
                    </Formik>
                </CModalBody>
            </CModal>
        </>
    );
};

export default GenerateReportModal;
