import React, {useState} from "react";
import {CTooltip} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import {ShipmentGet, ShipmentSearch, StandardShipmentTrace} from "../../../../models/shipment";
import {formatDate, formatTime} from "../../../../utils/dateUtil";
import {TableHeader} from "./TableHeader";
import ShipClient from "../../../../clients/shipClient";
import AddTraceModal from "../modals/AddTraceModal";

interface IProps {
    shipment: ShipmentGet;
    shipClient: ShipClient;
    refresh: () => void;
}

const Traces = (props: IProps) => {
    const {shipment, shipClient,refresh} = props;
    const [showAddTraceModal, setShowAddTraceModal] = useState<boolean>(false);
    const handleAddTrace = async (tr: StandardShipmentTrace) => {
        let rs = await shipClient.AddTrace(shipment.id.toString(), tr)

        if (!rs.succeeded) return;
        setShowAddTraceModal(false)
        refresh()
    }
    return (
        <>
            <div>
                <CTooltip content={"Add trace"}>
                    <CIcon
                        size={"xl"}
                        style={{cursor: "pointer"}}
                        color={"primary"}
                        content={freeSet.cilPlus}
                        onClick={() => setShowAddTraceModal(true)}
                    />
                </CTooltip>
                <table className="position-relative table table-hover table-sm">
                    <TableHeader header={["Date", "Time", "Location", "Status", "Comment", "User"]}/>
                    <tbody>
                    {shipment.traces?.length < 1 && (
                        <div className="mr-2 ml-2 mt-2 mb-2">
                            <span> No Available Tracking</span>
                        </div>
                    )}

                    {shipment.traces?.map((trace: StandardShipmentTrace, i) => (
                        <tr key={i}>
                            <td>{formatDate(trace.actionDate)}
                            </td>
                            <td>
                                {formatTime(trace.actionDate)}
                            </td>
                            <td>
                                <>
                                    {trace?.country && trace?.postCode
                                        ? trace?.country + "-" + trace?.postCode
                                        : " "}{" "}
                                </>
                            </td>

                            <td>{trace?.statusName || ""}</td>
                            <td>{trace?.comment1 || ""}</td>
                            <td>{trace?.createdBy || ""}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            {showAddTraceModal &&
                <AddTraceModal HandleClose={() => setShowAddTraceModal(false)} handleSubmit={handleAddTrace}
                               shipment={shipment}/>}
        </>

    )
}
export default Traces;
