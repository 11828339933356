import React, {useMemo, useState} from "react";
import {
    CButton,
    CCol,
    CContainer,
    CDataTable,
    CLabel,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CRow
} from "@coreui/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import SWInput from "./SWInput";
import SWSelect from "./SWSelect";

interface IProps {
    data?: Record<string, string>;
    onChange?: (newData: Record<string, string>) => void;
    keyLabel: string;
    valueLabel: string;
    hideValue?: boolean;
    keyLookupValues?: { label: string, value: string }[]
    valueLookupValues?: { label: string, value: string }[]

}

interface KeyValue {
    key: string;
    value: string;
}

const editDataInitialState = {key: "", value: ""}
const KeyvalueEditor = ({
                            data,
                            onChange,
                            keyLabel,
                            valueLabel,
                            hideValue,
                            keyLookupValues,
                            valueLookupValues
                        }: IProps) => {
    //const [values, setValues] = useState<KeyValue[]>([]);
    const [editData, setEditData] = useState<KeyValue>(editDataInitialState);
    const [editModalMode, setEditModalMode] = useState('');
    const [error, setError] = useState("");

    const values = useMemo(() => {
        if (data) {
            const v: KeyValue[] = Object.entries(data).map(([key, value]) => ({
                key,
                value: value,
            }));
            return v
            // setValues(v);
        }
        return []
    }, [data])
    // const toArray = () => {
    //  
    //
    // };
    //
    // useEffect(() => {
    //     toArray();
    // }, [data]);

    const onSave = (newValues: KeyValue[]) => {

        const newData = newValues.reduce((acc: any, curr) => {
            acc[curr.key] = curr.value;
            return acc;
        }, {});
        if (onChange) onChange(newData);
    };

    const onEditSave = () => {
        if (!editData?.key || !editData?.value) {
            setError("Invalid data");
            return;
        }

        if (editModalMode != 'add' && editModalMode != '') {
            onSave(values.map((val) =>
                val.key === editModalMode ? editData : val
            ))
        } else {
            onSave([...values, editData])
           
        }

        setEditData({key: "", value: ""});
        setEditModalMode('');
        setError("");
    };

    const onDelete = (key: string) => {
        onSave(values.filter((val) => val.key !== key))
    };

    const onEdit = (key: string) => {
        setEditData(values.find((val) => val.key === key) || {key: "", value: ""});
        setEditModalMode(key);
    };

    const scopedSlots = {
        links: (item: KeyValue) => (
            <td>
                <CRow>

                    <CButton onClick={() => onEdit(item.key)}>
                        <FontAwesomeIcon icon={faEdit}/>
                    </CButton>

                    <CButton className={"mr-2"} onClick={() => onDelete(item.key)}>
                        <FontAwesomeIcon icon={faTrash}/>
                    </CButton>

                </CRow>
            </td>
        ),
        value: (item: KeyValue) => <td>{hideValue ? "******" : item.value}</td>
    };

    return (
        <>
            <CModal show={editModalMode != ''} onClose={() => setEditModalMode('')}>
                <CModalHeader closeButton>
                    <CLabel>{editModalMode == 'add' ? "Add" : "Edit"}</CLabel>
                </CModalHeader>
                <CModalBody>
                    <CContainer>

                        {
                            (Array.isArray(keyLookupValues)) ? <SWSelect
                                className={"col-sm-6"}
                                values={keyLookupValues}
                                label={keyLabel}
                                value={editData?.key}
                                onChange={(e) => setEditData({...editData, key: e ?? ''})}
                            /> : <SWInput
                                className={"col-sm-6"}
                                label={keyLabel}
                                value={editData?.key ?? ''}
                                onChange={(e) => setEditData({...editData, key: e})}
                            />
                        }

                        {
                            (Array.isArray(valueLookupValues)) ? <SWSelect
                                className={"col-sm-6"}
                                values={valueLookupValues}
                                label={valueLabel}
                                value={editData?.value}
                                onChange={(e) => setEditData({...editData, value: e ?? ''})}
                            /> : <SWInput
                                className={"col-sm-6"}
                                label={valueLabel}
                                value={editData?.value ?? ''}
                                onChange={(e) => setEditData({...editData, value: e})}
                            />
                        }


                        <CLabel className={"text-red"}>{error}</CLabel>
                    </CContainer>
                </CModalBody>
                <CModalFooter>
                    <CButton color="primary" onClick={onEditSave}>
                        {editModalMode == 'add' ? "Add" : "Edit"}
                    </CButton>
                    <CButton color="secondary" onClick={() => {
                        setEditData(editDataInitialState)
                        setEditModalMode('')
                    }}>
                        Cancel
                    </CButton>
                </CModalFooter>
            </CModal>
            <CRow>
                <CCol className={"col-sm-6"}>

                    <CDataTable
                        size="sm"
                        hover
                        scopedSlots={scopedSlots}
                        items={values}
                        fields={[
                            {key: "key", label: keyLabel},
                            {key: "value", label: valueLabel},
                            {
                                key: "links",
                                label: (
                                    <CButton color="primary" onClick={() => setEditModalMode('add')}>
                                        <FontAwesomeIcon icon={faPlus}/>
                                    </CButton>
                                ),
                                _style: {width: "15%", padding: "0.25rem"},
                            },
                        ]}
                    />
                </CCol>
                {/*<CCol className={"col-sm-3"}>*/}
                {/*    <CButton color={"primary"} onClick={onSave}>Save</CButton>*/}
                {/*</CCol>*/}
            </CRow>
        </>
    );
};

export default KeyvalueEditor;

