import React, { useState } from 'react';
import { CRow, CCol, CCardBody, CTooltip } from '@coreui/react';
import { CreateShipment, createShipmentInitialValues, validateAddressBook } from '../../../../models/shipment';
import SWInput from '../../../SharedComponents/SWInput';
import SWSelect from '../../../SharedComponents/SWSelect';
import { FormikProps } from 'formik';
import { freeSet } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faFloppyDisk, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import RemoveDefault from '../../../../icons/removeDefault';
import GoogleLookupModal, { addressGoogleResults } from './googleLookupModal';
import ResetIcon from '../../../../icons/resetIcon';


interface IProps {
    formik: FormikProps<CreateShipment>;
    showFields: boolean;
    setShowFields: (e: boolean) => void
    setAddressBook: () => void
    saveAsDefault: () => void
    removeDefault: () => void
    saveInAddressBook: () => void
    type: "shipper" | "consignee"
    countryLookups: { [k: string]: string }
}

const ShipperDetails = ({ formik, showFields, countryLookups, setShowFields, setAddressBook, saveInAddressBook, saveAsDefault, removeDefault, type }: IProps) => {

    const { values, setFieldValue, touched, errors } = formik;
    const [focusedInput, setFocusedInput] = useState<boolean>(false)
    const [isHover, setIsHover] = useState(false);
    const [googleLookupModal, setGoogleLookupModal] = useState<boolean>(false);
    const [emailOrPhoneValidationError,setEmailOrPhoneValidationError] = useState<boolean>(false);
    const [companyNameOrNameValidationError,setCompanyNameOrNameValidationError] = useState<boolean>(false);



    const handleGoogleLookup = (results: addressGoogleResults) => {
        let combinedAddress = "";
        results?.forEach((e) => {
            if (e.types?.includes("locality")) {
                setFieldValue(`${type}.city`, e.long_name);
            } else if (e.types?.includes("country")) {
                setFieldValue(`${type}.country`, e.short_name);
            } else if (e.types?.includes("postal_code")) {
                setFieldValue(`${type}.postCode`, e.long_name);
            } else if (e.types?.includes("route")) {
                combinedAddress += e.long_name;
            } else if (e.types?.includes("street_number")) {
                combinedAddress = e.long_name + " " + combinedAddress;
            } else if (e.types?.includes("neighborhood")) {
                setFieldValue(`${type}.addressLine2`, e.long_name);
            } else if (e.types?.includes("establishment")) {
                setFieldValue(`${type}.companyName`, e.long_name);
            }else if (e.types?.includes("point_of_interest")) {
                setFieldValue(`${type}.companyName`, e.long_name);
            }

        });
        setFieldValue(`${type}.addressLine1`, combinedAddress);

    }
    const handleClear = () => {
        if (type == "shipper") {
            setFieldValue("shipper", createShipmentInitialValues().shipper)
        } else {
            setFieldValue("consignee", createShipmentInitialValues().consignee)
        }
    }

    const handleSaveInAddressBook = () => {
        setCompanyNameOrNameValidationError(false);
        setEmailOrPhoneValidationError(false);
        const { companyName, name,phone,email } = type === "shipper" ? values?.shipper : values?.consignee;
        const addressBookValidation = validateAddressBook({
            email,
            phone,
            companyName,
            name
        })
        if (addressBookValidation.isValid) {
            saveInAddressBook();
        } else {
            if(!addressBookValidation?.isCompanyNameOrContactNameValid) setCompanyNameOrNameValidationError(true)
            if(!addressBookValidation?.isEmailOrPhoneValid) setEmailOrPhoneValidationError(true)
        }
    };

    return (
        <>
            <CCardBody
                className={`mt-0 pt-2 pb-0 mt-0 mb-0  rounded text-black  ${(isHover || focusedInput) ? "bg-secondary" : ""
                    }`}
                onMouseOver={() => {
                    setIsHover(true);
                }}
                onMouseLeave={() => {
                    setIsHover(false);
                }}
            >

                <CRow className="mb-1">
                    <h6>
                        <strong>{type == "shipper" ? "Shipper:" : "Consignee:"}</strong>
                    </h6>
                    <CCol className="text-right" >
                        {(isHover || focusedInput) &&
                            <>
                                {(!showFields) &&
                                    <CTooltip content={"unlock"} >
                                        <CIcon
                                            content={freeSet.cilLockLocked}
                                            onClick={() => setShowFields(true)}
                                            style={{
                                                color: "black",
                                                height: "1.5em",
                                                width: "1.5em",
                                                cursor: "pointer",

                                            }}
                                        />
                                    </CTooltip>}
                                {showFields &&
                                    <>
                                        <ResetIcon onClick={() => handleClear()} tooltipText={"Clear"} />
                                        <CTooltip content={"Lock"} >
                                            <CIcon
                                                content={freeSet.cilLockUnlocked}
                                                onClick={() => saveAsDefault()}
                                                style={{
                                                    height: "1.5em",
                                                    width: "1.5em",
                                                    cursor: "pointer",
                                                    marginLeft: 8
                                                }}
                                            />
                                        </CTooltip>
                                    </>
                                }

                            </>
                        }
                    </CCol>
                </CRow>


                <CRow>
                    {showFields &&
                        <CCol md={2} >
                            <CTooltip content={"Google lookup"} >
                                <FontAwesomeIcon
                                    tabIndex={-1}
                                    icon={faMagnifyingGlass}
                                    onClick={() => setGoogleLookupModal(true)}
                                    color={(isHover || focusedInput) ? "black" : "#c8d2dc"}
                                    style={{ cursor: "pointer", width: 40, height: 40 }}
                                />
                            </CTooltip>

                        </CCol>}

                    <CCol md={10}>
                        <CRow>
                            <CCol md={4} sm={12}>Company Name:</CCol>
                            <CCol md={8} sm={12} >
                                {(showFields) ?
                                    <SWInput
                                        onfocus={() => setFocusedInput(true)}
                                        onBlur={() => setFocusedInput(false)}
                                        label={""}
                                        name={`${type}.companyName`}
                                        value={values?.[type]?.companyName ?? ""}
                                        onChange={(e) => setFieldValue(`${type}.companyName`, e)}
                                        type={"text"}
                                        validationErrors={touched?.[type]?.companyName && errors?.[type]?.companyName?.toString() || companyNameOrNameValidationError ? "Contact Name or Company Name is required" :""||""}
                                    /> :
                                    <strong>
                                        {values?.[type]?.companyName}
                                    </strong>}
                            </CCol>
                        </CRow>
                        <div style={{ marginTop: showFields ? -10 : 0 }}>
                            <CRow>
                                <CCol md={4} sm={12}>Contact Name:</CCol>
                                <CCol md={8} sm={12} >
                                    {showFields ?
                                        <SWInput
                                            onfocus={() => setFocusedInput(true)}
                                            onBlur={() => setFocusedInput(false)}
                                            label={""}
                                            name={`${type}.name`}
                                            value={values?.[type]?.name ?? ""}
                                            onChange={(e) => setFieldValue(`${type}.name`, e)}
                                            type={"text"}
                                            validationErrors={touched?.[type]?.name && errors?.[type]?.name?.toString()  || companyNameOrNameValidationError ? "Contact Name or Company Name is required" :""||""}
                                        /> :
                                        <strong>
                                            {values?.[type]?.name}
                                        </strong>}
                                </CCol>
                            </CRow>
                        </div>
                    </CCol>
                </CRow>



                <CRow>
                    {showFields &&
                        <CCol md={2} >
                            <div style={{ marginTop: showFields ? -10 : 0 }}>
                                <CTooltip content={"Address book"}>
                                    <FontAwesomeIcon icon={faAddressCard}
                                        tabIndex={-1}
                                        onClick={() => setAddressBook()}
                                        color={(isHover || focusedInput) ? "black" : "#c8d2dc"}
                                        style={{ cursor: "pointer", width: 45, height: 45, alignItems: "center" }}
                                    />
                                </CTooltip>

                            </div>
                        </CCol>}

                    <CCol md={10} >
                        <div style={{ marginTop: showFields ? -10 : 0 }}>
                            <CRow>
                                <CCol md={4} sm={12}>Street:</CCol>
                                <CCol md={8} sm={12} >
                                    {showFields ?
                                        <SWInput
                                            onfocus={() => setFocusedInput(true)}
                                            onBlur={() => setFocusedInput(false)}
                                            label={""}
                                            name={`${type}.addressLine1`}
                                            value={values?.[type]?.addressLine1 ?? ""}
                                            onChange={(e) => setFieldValue(`${type}.addressLine1`, e)}
                                            type={"text"}
                                            validationErrors={touched?.[type]?.addressLine1 && errors?.[type]?.addressLine1?.toString() || ""}
                                        /> :
                                        <strong>
                                            {values?.[type]?.addressLine1}
                                        </strong>}
                                </CCol>
                            </CRow>
                        </div>
                        <div style={{ marginTop: showFields ? -10 : 0 }}>
                            <CRow>
                                <CCol md={4} sm={12}>Address 2:</CCol>
                                <CCol md={8} sm={12} >
                                    {showFields ?
                                        <SWInput
                                            onfocus={() => setFocusedInput(true)}
                                            onBlur={() => setFocusedInput(false)}
                                            label={""}
                                            name={`${type}.addressLine2`}
                                            value={values?.[type]?.addressLine2 ?? ""}
                                            onChange={(e) => setFieldValue(`${type}.addressLine2`, e)}
                                            type={"text"}
                                            validationErrors={touched?.[type]?.addressLine2 && errors?.[type]?.addressLine2?.toString() || ""}
                                        /> :
                                        <strong>
                                            {values?.[type]?.addressLine2}
                                        </strong>}
                                </CCol>
                            </CRow>
                        </div>
                    </CCol>
                </CRow>


                <CRow>
                    {showFields &&
                        <CCol md={2} >
                            <div style={{ marginTop: showFields ? -10 : 0 }}>
                                <CTooltip content={"Save to address book"}>
                                    <FontAwesomeIcon icon={faFloppyDisk}
                                        tabIndex={-1}
                                        onClick={() => handleSaveInAddressBook()}
                                        color={(isHover || focusedInput) ? "black" : "#c8d2dc"}
                                        style={{ cursor: "pointer", width: 45, height: 45, alignItems: "center" }}
                                    />
                                </CTooltip>
                            </div>
                        </CCol>}

                    <CCol md={10} >
                        <div style={{ marginTop: showFields ? -10 : 0 }}>
                            <CRow>
                                <CCol md={4}>Zip Code /City:</CCol>
                                <CCol md={showFields ? 3 : 6} >
                                    {showFields ?
                                        <SWInput
                                            onfocus={() => setFocusedInput(true)}
                                            onBlur={() => setFocusedInput(false)}
                                            label={""}
                                            name={`${type}.postCode`}
                                            value={values?.[type]?.postCode ?? ""}
                                            onChange={(e) => setFieldValue(`${type}.postCode`, e)}
                                            type={"text"}
                                            validationErrors={touched?.[type]?.postCode && errors?.[type]?.postCode?.toString() || ""}
                                        /> : <strong>
                                            {`${!!values?.[type]?.postCode ? values?.[type]?.postCode : ""}, ${!!values?.[type]?.city ? values?.[type]?.city : ""}`}
                                        </strong>}
                                </CCol>
                                <CCol md={showFields ? 5 : 0} >
                                    {showFields ?
                                        <SWInput
                                            onfocus={() => setFocusedInput(true)}
                                            onBlur={() => setFocusedInput(false)}
                                            label={""}
                                            name={`${type}.city`}
                                            value={values?.[type]?.city ?? ""}
                                            onChange={(e) => setFieldValue(`${type}.city`, e)}
                                            type={"text"}
                                            validationErrors={touched?.[type]?.city && errors?.[type]?.city?.toString() || ""}
                                        /> :
                                        <></>

                                    }
                                </CCol>
                            </CRow>
                        </div>
                        <div style={{ marginTop: showFields ? -10 : 0 }}>
                            <CRow>
                                <CCol md={4} sm={12}>Country:</CCol>
                                <CCol md={8} sm={12} >
                                    {showFields ?
                                        <SWSelect
                                            onFocus={() => setFocusedInput(true)}
                                            onBlur={() => setFocusedInput(false)}
                                            label={""}
                                            validationErrors={touched?.[type]?.country && errors?.[type]?.country?.toString() || ""}
                                            name={`${type}.country`}
                                            value={values?.[type]?.country ?? ""}
                                            onChange={(e) => setFieldValue(`${type}.country`, e)}
                                            values={countryLookups}
                                        /> :
                                        <strong>
                                            {values?.[type]?.country}
                                        </strong>}
                                </CCol>
                            </CRow>
                        </div>
                    </CCol>
                </CRow>

                <CRow>
                    {showFields &&
                        <CCol md={2} >
                            <div style={{ marginTop: showFields ? -10 : 0 }}>
                                <RemoveDefault
                                    tabIndex={-1}
                                    tooltipText={"Remove default"}
                                    color={(isHover || focusedInput) ? "black" : "#c8d2dc"}
                                    onClick={() => {
                                        removeDefault()
                                    }} />
                            </div>
                        </CCol>}

                    <CCol md={10} >
                        <div style={{ marginTop: showFields ? -10 : 0 }}>
                            <CRow>
                                <CCol md={4} sm={12}>Telephone:</CCol>
                                <CCol md={8} sm={12} >
                                    {showFields ?
                                        <SWInput
                                            onfocus={() => setFocusedInput(true)}
                                            onBlur={() => setFocusedInput(false)}
                                            isFieldHidden={!showFields}
                                            labelClassName='m-0 small'
                                            label={""}
                                            name={`${type}.phone`}
                                            value={values?.[type]?.phone ?? ""}
                                            onChange={(e) => setFieldValue(`${type}.phone`, e)}
                                            type={"text"}
                                            validationErrors={touched?.[type]?.phone && errors?.[type]?.phone?.toString() || emailOrPhoneValidationError ? "Telephone or Email is required" :"" || ""}
                                        /> :
                                        <strong>
                                            {values?.[type]?.phone}
                                        </strong>}
                                </CCol>
                            </CRow>
                        </div>
                        <div style={{ marginTop: showFields ? -10 : 0 }}>
                            <CRow>
                                <CCol md={4} sm={12}>Email:</CCol>
                                <CCol md={8} sm={12} >
                                    {showFields ?
                                        <SWInput
                                            onfocus={() => setFocusedInput(true)}
                                            onBlur={() => setFocusedInput(false)}
                                            label={""}
                                            name={`${type}.email`}
                                            value={values?.[type]?.email ?? ""}
                                            onChange={(e) => setFieldValue(`${type}.email`, e)}
                                            type={"text"}
                                            validationErrors={touched?.[type]?.email && errors?.[type]?.email?.toString() || emailOrPhoneValidationError ? "Telephone or Email is required" :""||""}
                                        /> :
                                        <strong>
                                            {values?.[type]?.email}
                                        </strong>}
                                </CCol>
                            </CRow>
                        </div>
                    </CCol>
                </CRow>


                <CRow>
                    {showFields &&
                        <CCol md={2} >
                            <div style={{ marginTop: showFields ? -10 : 0 }}>
                                {/* <SetAsDefaultIcon
                                    tabIndex={-1}
                                    tooltipText={"Set as default"}
                                    color={(isHover || focusedInput) ? "black" : "#c8d2dc"}
                                    onClick={() => saveAsDefault()} /> */}
                            </div>
                        </CCol>}

                    <CCol md={10} >
                        <div style={{ marginTop: showFields ? -10 : 0 }}>
                            <CRow>
                                <CCol md={4}>Notes:</CCol>
                                <CCol md={8} sm={12} >
                                    {showFields ?
                                        <SWInput
                                            onfocus={() => setFocusedInput(true)}
                                            onBlur={() => setFocusedInput(false)}
                                            label={""}
                                            name={`${type}.notes`}
                                            value={values?.[type]?.notes ?? ""}
                                            onChange={(e) => setFieldValue(`${type}.notes`, e)}
                                            type={"text"}
                                            validationErrors={touched?.[type]?.notes && errors?.[type]?.notes?.toString() || ""}
                                        /> :
                                        <strong>
                                            {values?.[type]?.notes}
                                        </strong>}
                                </CCol>
                            </CRow>
                        </div>
                        <div style={{ marginTop: showFields ? -10 : 0 }}>
                            <CRow>
                                <CCol md={4}>EORI:</CCol>
                                <CCol md={8} sm={12} >
                                    {showFields ?
                                        <SWInput
                                            onfocus={() => setFocusedInput(true)}
                                            onBlur={() => setFocusedInput(false)}
                                            label={""}
                                            name={`${type}.eori`}
                                            value={values?.[type]?.eori ?? ""}
                                            onChange={(e) => setFieldValue(`${type}.eori`, e)}
                                            type={"text"}
                                            validationErrors={touched?.[type]?.eori && errors?.[type]?.eori?.toString() || ""}
                                        /> :
                                        <strong>
                                            {values?.[type]?.eori}
                                        </strong>}
                                </CCol>
                            </CRow>
                        </div>
                    </CCol>
                </CRow>
                {googleLookupModal &&
                    <GoogleLookupModal
                        onSubmit={(e: addressGoogleResults) => {
                            handleGoogleLookup(e)
                            setGoogleLookupModal(false)
                        }}
                        onClose={() => setGoogleLookupModal(false)}
                    />
                }
            </CCardBody>
        </>

    )

}

export default ShipperDetails;