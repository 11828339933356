export type SearchRateCardModel = {
    cardId: number;
    cardName: string;
    cardDescription: string;
    agent: string;
    customer: string;
    service: string;
    expiresOn: string | null;
}


export interface RateItem {
    id:number;
    cardId: string;
    cardName: string;
    cardDescription: string;
    weightFactor: number;
    weightUnit: WeightUnit;
    dimensionUnit: DimensionUnit;
    expiresOn: string | null;
    perPiece: boolean;
    hasPickup: boolean;
    hasWeekend1: boolean;
    hasWeekend2: boolean;
    hasIdCheck: boolean;
    hasInsurance: boolean;
    hasCOD: boolean;
    entryCount: number;
    surchargeCount: number;
    originZoneCount: number;
    uniqueOriginZones: string;
    destinationZoneCount: number;
    uniqueDestinationZones: string;
    entries: RateEntry[];
    surcharges: Surcharge[];
    originZones: Zone[];
    destinationZones: Zone[];
    rateItemServices: RateItemService[];
    agent: string;
    service: string;
    serviceName:string;
    serviceDescription:string;
}

export interface RateEntry {
    dutiable: string;
    originZone: string;
    destinationZone: string;
    from: number;
    break: number;
    step: number;
    price: number;
}

export interface ServiceAdd {
    serviceCode: string;
    serviceName: string;
    serviceDescription: string;
    showIfNoRate?:boolean
}
export interface Surcharge {
    type: string;
    qualifier: string;
    minimumPrice: number;
    price: number;
    order: number;
    calculationType: string
    allowDuplicate:boolean
    groupingCode:string
}

export interface Zone {
    code: string;
    country: string;
    fromPostCode: number | null;
    toPostCode: number | null;
    type: string;
    value: string;
    agent: string;
    pickup: boolean | null;
    delivery: boolean | null;
}

export interface RawZone {
    code: string;
    country: string;
    from: string;
    to: string;
}

export interface RateItemService {
    account: string;
    agent: string;
    service: string;
}
export interface RateSearchRequest {
    cardName?: string;
    agent?: string;
    customer?: string;
    service?: string
}

export interface SellingSearch {
    searchBy?: "cardName" | "customer" ,
    searchValue?: string;
}

export interface RateUpsertCost {
    description: string;
    name: string;
    weightFactor: number;
    weightUnit: WeightUnit;
    dimensionUnit: DimensionUnit;
    validFrom: string;
    perPiece: boolean;
    hasPickup: boolean;
    hasWeekend1: boolean;
    hasWeekend2: boolean;
    hasIdCheck: boolean;
    hasInsurance: boolean;
    hasCOD: boolean;
    originZones: Zone[];
    destinationZones: Zone[];
    entries: RateEntry[];
    surcharges: Surcharge[];
    agent: string;
    service: string;
    serviceName:string;
    serviceDescription:string;
}
export interface RateUpsertSelling {
    description: string;
    name: string;
    weightFactor: number;
    weightUnit: WeightUnit;
    dimensionUnit: DimensionUnit;
    validFrom: string;
    perPiece: boolean;
    hasPickup: boolean;
    hasWeekend1: boolean;
    hasWeekend2: boolean;
    hasIdCheck: boolean;
    hasInsurance: boolean;
    hasCOD: boolean;
    originZones: Zone[];
    destinationZones: Zone[];
    entries: RateEntry[];
    surcharges: Surcharge[];
    rateItemServices: RateItemService[];
}

export enum ChargeCalculationType {
    Unknown,
    Percentage,
    Fixed,
    Weight
}

export interface RateExportRequest {
    rateVersionId: number;
    exportDataType: "OrgZone" | "DestZone" | "RateEntry" | "Surcharges";
}
export const zoneKeys = ["Code", "Type", "Country", "FromPostCode", "ToPostCode", "Value"];
export const ratesKeys = ["Dutiable", "OriginZone", "DestinationZone", "From", "Break", "Step", "Price"];
export const surchargesKeys = ["Type", "Qualifier", "Price", "MinimumPrice", "Order", "CalculationType","Trigger","TriggerOn","AllowDuplicate"];

export interface RateDataImportRequest {
    rateVersionId: number;
    fileUrl: string;
    importDataType: string;
    map: { [key: string]: string|null; };
}

export interface ListFscRequest {
    rateCardId: string;
    agent: string;
    service: string;
}

export interface ListFscResponse {
    rateCardId: string;
    agent: string;
    service: string;
    validFrom: string | null;
    price: number | null;
    fscUrl: string;
}

export interface UpdateAgentFscRequest {
    validFrom: string | null;
    price: number;
}

export interface StreetAddress {
    country:string
    city:string
    state:string
    street:string[]
    postCode:string
}
export interface RateQueryPiece {
    weight: number;
    length: number | null;
    width: number | null;
    height: number | null;
}

export interface RateQuery {
    costOriginZone?: string;
    costDestinationZone?: string;
    customer?: string;
    agent?: string;
    service?: string;
    rateCard?: string;
    from: StreetAddress;
    to: StreetAddress;
    dutiable: boolean; // todo check
    pieces?: RateQueryPiece[];
    pickup: boolean;
    weekend1?: boolean;
    weekend2?: boolean;
    insurance?: boolean;
    idCheck?: boolean;
    cOD: boolean;
    residential?: boolean;
    valueOfGoods: number | null;
    pickupDate?: string | null;
    oPA?: string[];
    oDA?: string[];
    customerTaxNo?: string;
    customerTaxCode?: string;
    dimensionUnit?:DimensionUnit
    weightUnit?:WeightUnit
    items?:RateQueryItem[]
}
 export interface RateQueryItem {
     productCode: string;
     quantity?: number;
     weight: number;
     warehouse: string;
 }
export enum RateQueryResultStatus {
    Unknown = 0,
    Success = 1,
    OriginZoneNotFound = 2,
    DestinationZoneNotFound = 4,
    BaseRateNotFound = 8
}
export enum WeightUnit {
    gm = 0,
    kg = 1,
    lb = 2,
    oz= 3,
}
export enum DimensionUnit {
    cm = 0,
    M = 1,
    in = 2
}

export interface RateQueryResult {
    status: RateQueryResultStatus;
    originZone: string;
    destinationZone: string;
    oPA: string;
    oDA: string;
    perPiece: boolean;
    agent: string;
    service: string;
    rateCard: string;
    chargeWeight: number | null;
    dutiable: boolean;
    charges: RateQueryResultCharge[];
    pieces: RateQueryResultPiece[];
    agentName?:string
    serviceDescription?:string
}

export interface RateQueryResultCharge {
    type: string;
    name: string;
    amount: number;
    taxAmount: number;
    taxCode: string;
}


export interface RateQueryResultPiece {
    info: RateQueryPiece;
    chargeWeight: number | null;
}

export interface RateQueryPiece {
    weight: number;
    length: number | null;
    width: number | null;
    height: number | null;
}

export const zoneTypeOptions = [
    {
        label: "Unknown",
        value: "Unknown",
    },
    {
        label: "Country",
        value: "Country",
    },
    {
        label: "Numerical Post Code Range",
        value: "NumericalPostCodeRange",
    },
    {
        label: "Post Code Collection",
        value: "PostCodeCollection",
    },
    {
        label: "Post Code Prefix",
        value: "PostCodePrefix",
    },
]

export const dutiableOptions = [
    {
        label: "Both",
        value: "B",
    },
    {
        label: "Document",
        value: "D",
    },
    {
        label: "Parcel",
        value: "P",
    },
]
export const chargeCalculationTypeOptions = [
    {
        label: "Unknown",
        value: "Unknown",
    },
    {
        label: "Percentage",
        value: "Percentage",
    },
    {
        label: "Fixed",
        value: "Fixed",
    },
    {
        label: "Weight",
        value: "Weight",
    },
]

const mapUnitToCode = (unit: string | number, unitMapping: Record<string, string>): string => {
    return unitMapping[unit.toString()];
};

export const mapWeightUnit = (weightUnit: string | number): string => {
    const weightUnitMapping: Record<string, string> = {
        "gm": "0",
        "0": "0",
        "kg": "1",
        "1": "1",
        "lb": "2",
        "2": "2",
        "oz": "3",
        "3": "3",
    };
    return mapUnitToCode(weightUnit, weightUnitMapping);
};

export const mapDimUnit = (dimUnit: string): string => {
    const dimUnitMapping: Record<string, string> = {
        "cm": "0",
        "0": "0",
        "M": "1",
        "1": "1",
        "in": "2",
        "2": "2",
    };
    return mapUnitToCode(dimUnit, dimUnitMapping);
};
