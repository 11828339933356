import {ApiResponse} from "@simplify9/simplyapiclient";
import {FilterGroup} from "../models/filter";
import {filtersToSearchy, queryParamsToFilters, toQueryParam} from "../utils/filterUtils";
import {RemoteBlob, SearchyResult} from "../models/app";
import {
    AddContainerAttachmentRequest,
    ContainerCreate,
    ContainerSearch,
    ContainerShipmentSearch,
    ContainerUpdate,
    UpdateContainerAttachmentRequest,
} from "../models/container";
import {SortingRuleInformation} from "../models/sortingrule";
import {
    AddShipmentAttachmentRequest,
    AddShipmentTrace,
    BatchShipmentGenerateRequest,
    BookingCreateRequest,
    BookingUpdate,
    CheckReprintShipmentRes,
    ContainerAddShipmentsResult,
    DiscrepancyCodRecord,
    DownloadShipmentPrintRequest,
    GenerateLabelRequest,
    GenerateReport,
    GenerateShipmentRequest,
    GetContainersByIdsRequest,
    GetDiscrepancyCodRecord,
    GetNotGeneratedDetailRequest,
    GetNotPrintedDetailRequest,
    IAddCOD,
    PendingGenerateWithErrors,
    PendingGenerateWithErrorsDetail,
    PendingPrinting,
    PendingPrintingDetail,
    PrintShipmentRequest,
    ReprintShipmentRequest,
    SendShipmentAttachmentRequest,
    ShipmentAddTraceResult,
    ShipmentAttachment,
    ShipmentBlockRequest,
    ShipmentCOD,
    ShipmentCreateRequest,
    ShipmentGet,
    ShipmentSearch,
    ShipmentSearchRequest,
    ShipmentsImportParameters,
    ShipmentsImportRequest,
    ShipmentsImportSearch,
    ShipmentsImportSearchRequest,
    StandardShipmentTrace,
    UpdateShipmentAttachmentRequest
} from "../models/shipment";
import {ContainerType} from "../models/containertype";
import {SheetInfo} from "../models/sheetInfo";

import ClientWithErrorNotifier from "./clientWithErrorNotifier";
import {MachineSortingHistory, MachineSortingQueryModel} from "../models/machineSorting";
import {CreateShipmentReturn} from "../models/ShipmentReturns";
import {
    AddressBook,
    AddressBookSearchRequest,
    GetDefaultAddressRequest,
    RemoveDefaultAddressRequest,
    SetDefaultAddressRequest
} from "../models/addressBook";
import {shipDefaultAccountResponse} from "../models/accounting";

const localUrl = 'http://localhost:5000'


class ShipClient {
    static getShipment() {
        throw new Error("Method not implemented.");
    }

    BaseUrl: string;
    pageSize: number;
    apiClient: ClientWithErrorNotifier;

    constructor() {
        this.BaseUrl = `${process.env.REACT_APP_API_BASE_URL}/ship/api`;
        //  this.BaseUrl = `http://localhost:5000/ship/api`;
        //  this.BaseUrl = `https://localhost:14181/ship/api`;


        this.pageSize = 20;
        this.apiClient = new ClientWithErrorNotifier(this.BaseUrl);
    }


    async getShipments(body: ShipmentSearchRequest) {
        const rs = await this.apiClient.postAsync("/shipments/search", body);
        return rs.data;
    }

    async getShipmentsForStaff(body: ShipmentSearchRequest) {
        const rs = await this.apiClient.postAsync("/shipments/staffsearch", body);
        return rs.data;
    }

    async getShipment(uid: string): Promise<ShipmentGet> {
        const rs = await this.apiClient.getAsync(`/shipments/${encodeURI(uid)}`);
        return rs.data;
    }


    async getGeneratedCod(filter: any): Promise<any> {
        const rs = await this.apiClient.getAsync(`/shipments/generatedcod?${toQueryParam(filter)}`)
        return rs.data
    }

    async getGeneratedMonthReport(filter: any): Promise<any> {
        const rs = await this.apiClient.getAsync(`/Reports?${toQueryParam(filter)}`)
        return rs.data
    }

    async generateReport(body: GenerateReport) {
        const rs = await this.apiClient.postAsync(`/shipments/marktransferred`, body);

        return rs;
    }

    async releaseOnHoldCods() {
        const rs = await this.apiClient.getAsync(`/shipments/ReleaseOnHoldCods`);
        return rs;
    }

    async generateMonthReport(body: any) {
        const rs = await this.apiClient.postAsync(`/Reports/generate`, body);

        return rs;
    }

    async getPendingCOD(args: string[]): Promise<ShipmentGet[]> {
        const keys = args?.length ? args?.map((arg, index) => {
            if (index == 0) {
                return `?${arg}=true`
            } else {
                return `&${arg}=true`
            }
        })?.join('') : ''
        const rs = await this.apiClient.getAsync(`/shipments/pendingcod${keys}`);
        return rs.data;
    }

    async getGroupedOnHoldCods(): Promise<ShipmentCOD[]> {
        const rs = await this.apiClient.getAsync(`/shipments/GetGroupedOnHoldCods`);
        return rs.data;
    }

    async searchBankDepositReports() {
        const rs = await this.apiClient.getAsync(`/shipments/SearchBankDepositReports`);
        return rs;
    }

    async getPendingMonthReports(pickupDate: Date, profile: string, args: string[]): Promise<ShipmentGet[]> {
        let argsBody: any = {}
        args.map((item) => argsBody[item] = true)
        const rs = await this.apiClient.postAsync(`/Reports/pending`, {
            ...argsBody,
            pickupDate,
            profile
        });
        return rs.data;
    }

    async getCodRecord(body: GetDiscrepancyCodRecord): Promise<DiscrepancyCodRecord[]> {
        const rs = await this.apiClient.postAsync("/shipments/getrecords", body)
        return rs.data
    }

    async requestDownloadCOD(account: string, consigneeCountry: string, payMode: string, agent: string, hasShipmentNumber: boolean): Promise<ShipmentGet[]> {
        let query = `Account=${account}`
        if (consigneeCountry)
            query += `&ConsigneeCountry=${consigneeCountry}`
        if (payMode)
            query += `&PayMode=${payMode}`
        if (agent)
            query += `&Agent=${agent}`
        if (hasShipmentNumber != undefined)
            query += `&HasShipmentNumber=${hasShipmentNumber}`

        const rs = await this.apiClient.getAsync(`/shipments/pendingcoditem?${query}`);
        return rs.data;
    }

    async addShipmentCOD(body: IAddCOD): Promise<any> {
        const rs = await this.apiClient.postAsync(`/shipments/addcod`, body);
        return rs;
    }

    async getGeneratedReport(documentNumber: any): Promise<any> {
        const rs = await this.apiClient.getAsync(`/shipments/${documentNumber}/getreport`);
        return rs.data;
    }

    async getProfilesReporting(): Promise<any> {
        const rs = await this.apiClient.getAsync(`/profiles`);
        return rs;
    }

    async getGeneratedMonth(documentNumber: any): Promise<any> {
        const rs = await this.apiClient.getAsync(`/Reports/${documentNumber}`);
        return rs.data;
    }


    async getShipmentByNumber(number: string): Promise<{ shipment?: ShipmentSearch, found: boolean } | null> {
        const rs = await this.apiClient.getAsync(`/shipments?filter=Number:1:${number}&page=0&size=10&format=0`);
        if (rs.succeeded && rs.data.result.length > 0) {
            return {shipment: rs.data.result[0], found: true};
        }

        if (rs.succeeded && rs.data.result.length == 0) {
            return {shipment: undefined, found: false};
        }

        return null;
    }

    async getShipmentByNumber2(number: string): Promise<any> {
        const rs = await this.apiClient.getAsync(`/shipments/${number}/bynumber`);

        return rs?.data;


    }

    //https://stg.traxis.app/ship/api/shipments?filter=Number:1:84120000075324&page=0&size=10&format=0


    async updateShipment(uid: string, body: ShipmentSearch) {
        const rs = await this.apiClient.postAsync(`/shipments/${uid}`, body, {headers: undefined});

        return rs.succeeded;
    }

    async deleteShipment(uid: string, force?: boolean) {
        const rs = !force ? await this.apiClient.deleteAsync(`/shipments/${uid}`, {}, {ignoreError: true}) : await this.apiClient.postAsync(`/shipments/${uid}/ForceDelete`, {})

        return rs
    }

    // async updateShipment(uid: string, body:ShipmentSearch) {
    //   // const req = new Promise(((resolve,rej)=>{
    //   //   setTimeout(() => {
    //   //     return resolve("success");
    //   //   }, 3000);

    //   // }))

    //   // return req;
    //   // const rs = await this.client.SimplyPostAsync(`/shipments/${uid}`,body);
    //   // return rs;
    // }


    async CloseContainer(id: string) {
        const rs = await this.apiClient.postAsync(`/containers/${id}/close`, {
            shipments: [null],
        });
        return rs.succeeded;
    }

    async GetContainers(
        filterGroup: FilterGroup,
        format: number = 0
    ): Promise<SearchyResult<ContainerSearch> | RemoteBlob> {
        const searchyString = filtersToSearchy(filterGroup);
        const rs = await this.apiClient.getAsync(
            `/containers?${searchyString}&format=${format}`
        );
        return rs.data;
    }

    async CreateSortingRule(body: SortingRuleInformation) {
        const rs = await this.apiClient.postAsync(`/sortingrules`, body);
        return rs.data;
    }

    async GetContainerType(id: string) {
        const rs = await this.apiClient.getAsync(`/containertypes/${id}`);
        return rs.data;
    }

    async CreateContainerType(body: ContainerType) {
        const rs = await this.apiClient.postAsync(`/containertypes`, body);
        return rs.data;
    }

    async UpdateContainerType(id: string, body: ContainerType) {
        const rs = await this.apiClient.postAsync(`/containertypes/${id}`, body);
        return rs.data;
    }

    async GetSortingRule(id: string): Promise<SortingRuleInformation> {
        const rs = await this.apiClient.getAsync(`/sortingrules/${id}`);
        return rs.data;
    }

    async getTrackingLink(number: string) {
        let encoded = encodeURI((number));
        return await this.apiClient.getAsync(`/shipments/${encoded}/trackinglink`);
    }

    async UpdateSortingRule(id: string, body: SortingRuleInformation) {
        const rs = await this.apiClient.postAsync(`/sortingrules/${id}`, body);
        return rs.data;
    }

    async GetContainerReport(id: string, body: { reportId: number }) {
        const rs = await this.apiClient.getAsync(`/containers/${id}/report?ReportId=${body.reportId}`);
        return rs.data as RemoteBlob;
    }

    async deleteContainer(id: string) {
        return await this.apiClient.deleteAsync(`/containers/${id}`, {})
    }

    async AddTrace(id: string, trace: StandardShipmentTrace) {
        const rs = this.apiClient.postAsync(`/shipments/${id}/addtrace`, trace);
        return rs;
    }

    async AddTraceByCode(id: string, code: string) {
        const rs = this.apiClient.postAsync(`/shipments/${id}/addtrace`, {
            code: code,
            actionDate: new Date(),
        });
        return rs;
    }

    async AddBulkTraces(shipments: string[], trace: AddShipmentTrace): Promise<ShipmentAddTraceResult> {
        const newTrace = {...trace, actionDate: trace.actionDate};
        const rs = await this.apiClient.postAsync(`shipments/addbulktraces`, {
            shipments: [...shipments],
            trace: newTrace,
        });
        return rs.data ?? {details: [], totalAdded: 0};
    }

    async AddBulkTracesByBarcode(shipments: string[], trace: AddShipmentTrace): Promise<ShipmentAddTraceResult> {
        const newTrace = {...trace, actionDate: trace.actionDate};
        const rs = await this.apiClient.postAsync(`shipments/AddBulkTraceByBarcode`, {
            shipments: [...shipments],
            trace: newTrace,
        });
        return rs.succeeded ? rs.data : {details: [], totalAdded: 0};
    }


    async AddBulkTracesOnContainer(id: string, trace: StandardShipmentTrace) {
        trace.actionDate = new Date().toISOString();
        const rs = await this.apiClient.postAsync(
            `containers/${id}/addshipmenttraces`,
            trace
        );
        return rs.succeeded;
    }

    async OriginReceipt(id: string) {
        const rs = await this.apiClient.postAsync(
            `/shipments/${id}/originreceipt`,
            {}
        );
        return rs as ApiResponse;
    }

    async GetSortingRules(
        filterGroup: FilterGroup
    ): Promise<SearchyResult<ContainerSearch>> {
        const searchyString = filtersToSearchy(filterGroup);
        const rs = await this.apiClient.getAsync(
            `/sortingrules?${searchyString}`
        );
        return rs.data;
    }

    async GetContainerTypes(
        filterGroup: FilterGroup | null,
        lookup: boolean = false
    ): Promise<{ [k: string]: string } | SearchyResult<ContainerType>> {
        const rs = await this.apiClient.getAsync(
            `/containertypes${lookup ? "?format=1" : ""}`
        );
        return rs.data;
    }

    async GetContainer(id: string) {
        const rs = await this.apiClient.getAsync(`/containers/${id}`);
        return rs.data;
    }

    async UpdateContainer(id: string, container: ContainerUpdate) {
        const rs = await this.apiClient.postAsync(
            `/containers/${id}/update`,
            container
        );
        return rs.data;
    }

    async ScanShipmentsToContainer(
        id: string,
        shipments: string[]
    ): Promise<ShipmentSearch[]> {
        const rs = await this.apiClient.postAsync(`/containers/${id}/scanin`, {
            References: shipments,
        });
        return rs.data;
    }


    async AddShipmentsToContainer(
        id: string,
        shipments: string[]
    ): Promise<ContainerAddShipmentsResult> {
        const rs = await this.apiClient.postAsync(`/containers/${id}/addshipments`, {
            References: shipments,
        });
        return rs.data;
    }

    async CreateContainer(body: ContainerCreate): Promise<number | false> {
        const rs = await this.apiClient.postAsync(`/containers`, body);
        const id = Number.parseInt(rs.data as string);
        return Number.isNaN(id) ? false : id;
    }

    async getShipmentsImport(query: ShipmentsImportSearchRequest): Promise<SearchyResult<ShipmentsImportSearch>> {
        const queryParams = [];
        if (query.account)
            queryParams.push(`account=${query.account}`)
        if (query.createdOnFrom)
            queryParams.push(`createdOnFrom=${query.createdOnFrom}`)
        if (query.createdOnTo)
            queryParams.push(`createdOnTo=${query.createdOnTo}`)
        if (query.finished)
            queryParams.push(`finished=${query.finished}`)
        if (query.offset)
            queryParams.push(`offset=${query.offset}`)
        if (query.limit)
            queryParams.push(`limit=${query.limit}`)
        else
            queryParams.push("limit=1")

        const queryString = queryParams.map(s => encodeURI(s)).join("&");

        const rs = await this.apiClient.getAsync(`/shipmentsImport?${queryString}`);
        return rs.data;

    }

    async getShipmentsImportById(id: string): Promise<ShipmentsImportSearch> {

        const rs = await this.apiClient.getAsync(`/shipmentsImport/${id}`);
        return rs.data;

    }

    async GetDefaultImportOptions(account: string): Promise<ShipmentsImportParameters | null> {
        const rs = await this.apiClient.getAsync(`/shipmentsimport/${account}/getdefault`);

        return rs.data
    }

    async UploadExcelFile(file: File): Promise<{ blobInfo: RemoteBlob, sheets: SheetInfo[] }> {

        let formData = new FormData();

        formData.append("File", file);

        let res: ApiResponse = await this.apiClient.postFormAsync(
            "attachments/Upload/excel",
            formData
        );
        return res.data;

    };

    async Upload(file: File): Promise<RemoteBlob> {

        let formData = new FormData();

        formData.append("File", file);

        let res: ApiResponse = await this.apiClient.postFormAsync(
            "attachments/Upload",
            formData
        );
        return res.data;

    };

    async importShipments(body: ShipmentsImportRequest): Promise<number | string> {
        const rs = await this.apiClient.postAsync("/shipmentsImport", body, {ignoreError: true});
        if (rs.succeeded)
            return rs.data;

        if (rs.status == 400)
            return rs.data.SWException[0];

        if (rs.status > 500)
            return "Server error, please try again later";

        return `Error, status code ${rs.status}`;

    }

    async rerun(container: ContainerSearch, integrationCode: string, shipments: ContainerShipmentSearch[]) {

        return await this.apiClient.postAsync(`/containers/${container.id}/rerun`, {
            shipments: shipments.map(s => s.id),
            integrationCode: integrationCode
        });
    }

    async rerunFailed(container: ContainerSearch, integrationCode: string) {

        return await this.apiClient.postAsync(`/containers/${container.id}/retryfailed`, {
            integrationCode: integrationCode
        });
    }

    async batchDelete(body: string[]) {
        return await this.apiClient.postAsync("/shipments/batchdelete", {
            keys: body
        })
    }

    async getMachineSortingHistory(reportParams: MachineSortingQueryModel): Promise<SearchyResult<MachineSortingHistory>> {
        const rs = await this.apiClient.getAsync(`/SortingMachine?${toQueryParam(reportParams)}`);
        return rs.data ?? {totalCount: 0, result: []};
    }

    async uploadMachinePending(): Promise<MachineSortingHistory> {
        const rs = await this.apiClient.postAsync("/SortingMachine/upload", {onlyPending: true});
        return rs.data ?? {};
    }

    async downloadMachinePending(): Promise<MachineSortingHistory[]> {
        const rs = await this.apiClient.postAsync("/SortingMachine/download", {});
        return rs.data ?? undefined;
    }

    async recalculateShipmentUids(uids: string[]): Promise<number> {
        const rs = await this.apiClient.postAsync("/shipments/recalculateroutes", {uids: uids});
        return rs.data ?? -1;
    }

    async recalculateShipment(request: ShipmentSearchRequest): Promise<number> {

        const query = filtersToSearchy(queryParamsToFilters(window.location.search));
        const rs = await this.apiClient.postAsync("/shipments/recalculateroutes", {criteria: request});
        return rs.data ?? 0;
    }

    async addComment(id: string, comment: string) {
        const body = {
            Comment: comment
        }

        const rs = await this.apiClient.postAsync(`/shipments/${id}/addcomment`, body)
        return rs
    }

    async getShipmentNumbersByUids(uids: string[]) {
        let rs = await this.apiClient.postAsync("/shipments/getnumbers", uids)

        return rs.data
    }

    async UploadFile(file: any, number: string): Promise<{ location: string }> {
        let formData = new FormData();

        formData.append("Label", file);
        const url = encodeURI(`attachments/Upload/newattachment/${number}`)
        let res: ApiResponse = await this.apiClient.postFormAsync(
            url,
            formData
        );

        return res.data;
    }

    async addAttachment(uid: string, body: AddShipmentAttachmentRequest): Promise<ShipmentAttachment> {
        const res = await this.apiClient.postAsync(`/attachments/${uid}/add`, body)

        return res.data
    }

    async addBulkAttachment(uid: string, body: AddShipmentAttachmentRequest[]): Promise<ShipmentAttachment> {
        const res = await this.apiClient.postAsync(`/attachments/${uid}/AddBulk`, body)
        return res.data
    }

    async updateAttachment(uid: string, body: UpdateShipmentAttachmentRequest): Promise<ShipmentAttachment> {
        const rs = await this.apiClient.postAsync(`/attachments/${uid}/update`, body)

        return rs.data
    }

    async deleteAttachment(uid: string, attachmentId: number) {
        return await this.apiClient.postAsync(`/attachments/${uid}/delete`, {Id: attachmentId})
    }

    async sendAttachmentToShipper(body: SendShipmentAttachmentRequest) {
        return await this.apiClient.postAsync("/attachments/sendtoshipper", body)
    }

    async sendAttachmentToConsignee(body: SendShipmentAttachmentRequest) {
        return await this.apiClient.postAsync("/attachments/sendtoconsignee", body)
    }

    async sendSMSToPhoneNumber(uid: string, body: object) {
        return await this.apiClient.postAsync(`/shipments/${uid}/sendsms`, body);
    }

    async updateBooking(uid: string, body: BookingUpdate) {
        return await this.apiClient.postAsync(`/shipments/${uid}/updatebooking`, body)
    }

    async searchReturns(filters: FilterGroup) {
        const queryParam = filtersToSearchy(filters)
        const rs = await this.apiClient.getAsync(`/shipments/searchreturn?${queryParam}`)

        return rs.data
    }

    async createReturns(body: CreateShipmentReturn) {
        return await this.apiClient.postAsync("/shipments/return", body)
    }

    async getAttachmentTypeLookups(appliesOn: string) {
        const rs = await this.apiClient.getAsync(`attachments/types?AppliesOn=${appliesOn}&lookup=true`)
        return rs.data
    }

    async GenerateShipmentAttachment(uid: string): Promise<ApiResponse> {
        const rs = await this.apiClient.getAsync(`shipments/${uid}/generateattachment`)

        return rs
    }

    async BlockShipment(uid: string, request: ShipmentBlockRequest) {
        const rs = await this.apiClient.postAsync(`shipments/${uid}/block`, request)
        return rs
    }

    async UnblockShipment(uid: string) {
        const rs = await this.apiClient.getAsync(`shipments/${uid}/unblock`)

        return rs;
    }

    async AddContainerAttachment(containerId: number, request: AddContainerAttachmentRequest) {
        const rs = await this.apiClient.postAsync(`containers/${containerId}/addAttachment`, request)

        return rs.data
    }

    async AddContainerBulkAttachment(containerId: number, request: AddContainerAttachmentRequest[]) {
        const rs = await this.apiClient.postAsync(`containers/${containerId}/addBulkAttachments`, request)
        return rs.data
    }

    async UpdateContainerAttachment(attachmentId: number, request: UpdateContainerAttachmentRequest) {
        const rs = await this.apiClient.postAsync(`containers/${attachmentId}/updateAttachment`, request)

        return rs.data
    }

    async DeleteContainerAttachment(attachmentId: number) {
        const rs = await this.apiClient.getAsync(`containers/${attachmentId}/deleteAttachment`)

        return rs.succeeded
    }

    async SearchContainers(
        filterGroup: FilterGroup,
        containerId?: string
    ): Promise<SearchyResult<ContainerSearch>> {
        const {page, limit} = filterGroup
        console.log(filterGroup)
        const rs = await this.apiClient.getAsync(
            `/containers?filter=Type:1:${containerId}&page=${page}&size=${limit}&format=0`
        );
        return rs.data;
    }

    async CheckShipmentAlreadyPrinted(
        request: ReprintShipmentRequest
    ): Promise<CheckReprintShipmentRes> {
        const rs = await this.apiClient.postAsync(
            `/attachments/CheckShipmentAlreadyPrinted`, request
        );
        return rs.data;
    }

    async ReprintShipments(
        request: ReprintShipmentRequest
    ): Promise<number> {
        const rs = await this.apiClient.postAsync(
            `/attachments/ReprintShipments`, request
        );
        return rs.data;
    }

    async GetPendingPrintingShipments(args: string[]): Promise<PendingPrinting[]> {
        const keys = args?.length ? args?.map((arg, index) => {
            if (index == 0) {
                return `?${arg}=true`
            } else {
                return `&${arg}=true`
            }
        })?.join('') : ''
        const rs = await this.apiClient.getAsync(`/shipmentsPrinting/getPending${keys}`);
        return rs.data;
    }


    async GetPendingShipmentBatch(args: string[]): Promise<ApiResponse> {
        const keys = args?.length ? args?.map((arg, index) => {
            if (index == 0) {
                return `?${arg}=true`
            } else {
                return `&${arg}=true`
            }
        })?.join('') : ''
        const rs = await this.apiClient.getAsync(`/shipmentsBatch/getPending${keys}`);
        return rs;
    }

    async GetDetailsPrintingShipments(data: GetNotPrintedDetailRequest): Promise<PendingPrintingDetail[]> {
        const {hasCod, account, agent, generated} = data;
        let path = `shipmentsPrinting/getDetails?account=${account}`;
        if ((hasCod == true) || (hasCod == false)) path += `&hasCod=${hasCod}`;
        if (!!agent) path += `&agent=${agent}`;
        if (!!generated) path += `&generated=${generated}`;
        const rs = await this.apiClient.getAsync((path))
        return rs.data
    }

    async GetShipmentBatchDetails(data: GetNotGeneratedDetailRequest): Promise<PendingPrintingDetail[]> {
        const {hasCod, account, agent, generated} = data;
        let path = `shipmentsBatch/getDetails?account=${account}`;
        if ((hasCod == true) || (hasCod == false)) path += `&hasCod=${hasCod}`;
        if (!!agent) path += `&agent=${agent}`;
        if (!!generated) path += `&generated=${generated}`;
        const rs = await this.apiClient.getAsync((path))
        return rs.data
    }

    async PrintShipments(body: PrintShipmentRequest): Promise<number> {
        const rs = await this.apiClient.postAsync(`shipmentsPrinting/print`, body)
        return rs.data
    }

    async GenerateShipmentsBatch(body: BatchShipmentGenerateRequest): Promise<number> {
        const rs = await this.apiClient.postAsync(`shipmentsBatch/generate`, body)
        return rs.data
    }

    async DownloadPrintedShipments(id: string, body: DownloadShipmentPrintRequest) {
        const rs = await this.apiClient.postAsync(`/shipmentsPrinting/${id}/Download`, body)
        return rs.data
    }

    async GenerateShipment(uid: string, request: GenerateShipmentRequest) {
        const rs = await this.apiClient.postAsync(`/Shipments/${uid}/Generate/`, request)
        return rs.data
    }

    async GetPrintedContainerReport(id: string) {
        const rs = await this.apiClient.getAsync(`/attachments/${id}/GetPrintedContainerReport/`)
        return rs.data
    }

    async SearchAddressBook(query: AddressBookSearchRequest) {
        const rs = await this.apiClient.getAsync(`/AddressBook?account=${query?.account}&lookup=${query?.lookup}`)
        return rs.data
    }

    async UpdateAddressBook(request: AddressBook) {
        const rs = await this.apiClient.postAsync(`/AddressBook/${request?.id}`, request)
        return rs
    }

    async DeleteAddressBook(id: string) {
        return await this.apiClient.deleteAsync(`/AddressBook/${id}`, {})
    }

    async AddAddressBook(request: AddressBook) {
        const rs = await this.apiClient.postAsync(`/AddressBook`, request)
        return rs
    }

    async GenerateAttachment(id: string, body: GenerateLabelRequest) {
        const rs = await this.apiClient.postAsync(`/shipments/${id}/generateAttachment`, body);
        return rs.data;
    }

    async GenerateAttachmentByNumber(id: string, body: GenerateLabelRequest) {
        const rs = await this.apiClient.postAsync(`/shipments/${id}/generateAttachmentByNumber`, body);
        return rs.data;
    }


    async SetDefault(id: string, body: SetDefaultAddressRequest) {
        const rs = await this.apiClient.postAsync(`/AddressBook/${id}/SetDefault`, body);
        return rs.data;
    }

    async RemoveDefault(query: RemoveDefaultAddressRequest) {
        const rs = await this.apiClient.getAsync(`/AddressBook/RemoveDefault?account=${query?.account}&DefaultAddressFor=${query?.defaultAddressFor}`);
        return rs.data;
    }

    async ProfileLookups() {
        const rs = await this.apiClient.getAsync(`/profiles/ProfileLookups`);
        return rs.data;
    }

    async GetDefault(query: GetDefaultAddressRequest) {
        const rs = await this.apiClient.getAsync(`/AddressBook/GetDefault?account=${query?.account}`);
        return rs.data;
    }

    async GetAccountDefault(id: string) {
        const rs = await this.apiClient.getAsync(`/AccountsDefault/${id}`, {ignoreError:true});
        return rs.data;
    }

    async UpdateAccountDefault(id: string, query: shipDefaultAccountResponse) {
        return await this.apiClient.postAsync(`/AccountsDefault/${id}/UpdateJsonData`, query);
    }

    async BookingCreate(request: BookingCreateRequest) {
        const rs = await this.apiClient.postAsync(`/shipments/booking`, request)
        return rs
    }

    async Create(request: ShipmentCreateRequest) {
        const rs = await this.apiClient.postAsync(`/shipments`, request)
        return rs
    }

    async GetContainersByIds(request: GetContainersByIdsRequest) {
        const rs = await this.apiClient.postAsync(`/containers/GetByIds`, request)
        return rs
    }

    async DashboardCounts() {
        const rs = await this.apiClient.getAsync(`/shipments/dashboardCounts`);
        return rs;
    }

    async AssignedToCounts() {
        const rs = await this.apiClient.getAsync(`/shipments/AssignedToCounts`);
        return rs;
    }

    async GetPendingWithErrors(): Promise<PendingGenerateWithErrors[]> {
        const rs = await this.apiClient.getAsync(`/shipmentsBatch/GetPendingWithErrors`);
        return rs.data;
    }

    async GetPendingWithErrorsDetails(request: { account: string }): Promise<PendingGenerateWithErrorsDetail[]> {
        const rs = await this.apiClient.getAsync(`/shipmentsBatch/${request?.account}/GetPendingWithErrorsDetails`);
        return rs.data;
    }

    async assignToOthers(uid: string, username: string): Promise<any> {
        const rs = await this.apiClient.postAsync(`/shipments/${uid}/AssignToOthers`, {username});
        return rs.data;
    }

    async assignToMe(uid: string): Promise<any> {
        const rs = await this.apiClient.postAsync(`/shipments/${uid}/AssignToMe`, {});
        return rs.data;
    }

    async removeAssignTo(uid: string): Promise<any> {
        const rs = await this.apiClient.postAsync(`/shipments/${uid}/RemoveAssignTo`, {});
        return rs.data;
    }


    async addShipImportData(accountId: string, name: string, value: string, position: number, type: string, removeLeadingZeros: boolean, force: boolean,) {

        return await this.apiClient.postAsync(`/AccountsDefault/AddShipImportData`, {
            accountId,
            name,
            value,
            position,
            type,
            removeLeadingZeros,
            force
        })
    }

    async addBookingData(accountId: string, name: string, value: string, position: number, type: string, removeLeadingZeros: boolean, force: boolean,) {

        return await this.apiClient.postAsync(`/AccountsDefault/AddBookingData`, {
            accountId,
            name,
            value,
            position,
            type,
            removeLeadingZeros,
            force
        })
    }

    async SearchUsersCanTakeTask() {
        const rs = await this.apiClient.getAsync(`/Profiles/SearchUsersCanTakeTask`);
        return rs.data;
    }

    async addShipmentBoxItem(shipBoxId: number, productCode: string, quantity: number, checkSystem: boolean) {
        return await this.apiClient.postAsync(`/ShipmentBoxes/${shipBoxId}/AddShipmentBoxItem`, {
            productCode,
            quantity,
            checkSystem
        })

    }

    async createShipmentBox(shipmentUid: number, Number: number) {
        return await this.apiClient.postAsync(`/ShipmentBoxes`, {
            shipmentUid,
            Number
        })

    }

    async getShipBox(shipmentId: number) {
        return await this.apiClient.getAsync(`/ShipmentBoxes/${shipmentId}/GetShipmentBox`)
    }


    async generateShipmentBoxes(shipmentUid: number, quantity: number) {
        return await this.apiClient.postAsync(`/ShipmentBoxes/CreateShipmentBoxes`, {
            shipmentUid,
            quantity
        })

    }

    async updateShipmentBoxItem(shipBoxItemId: number, quantity: number) {
        return await this.apiClient.postAsync(`/ShipmentBoxes/${shipBoxItemId}/UpdateShipmentBoxItem`, {
            quantity,
        })

    }

}

export default ShipClient;
