import {
    CModal,
    CModalBody,
    CModalFooter,
    CButton,
    CModalHeader,
    CRow,
    CCol,
} from "@coreui/react";
import React from "react";
import ActionButton from "../../../SharedComponents/ActionButton";

interface IProps {
    isShowing: boolean;
    onClose: () => any;
    onSave: () => any;
    shipmentCodNumber: number;
    amount: number | string
    shipmentCod: any[]
}
const WarningModal = (props: IProps) => {
    const { isShowing, shipmentCodNumber,shipmentCod, amount, onClose, onSave } = props;

    let codAmount = 0
    shipmentCod.map((item: any)=> {
        codAmount += item.amount
    })
    return (
        <>
            <CModal centered={true} show={isShowing} onClose={onClose}>
                <CModalHeader>
                    <h5 className="text-danger">Warning</h5>
                </CModalHeader>
                <CModalBody>
                    <p className={"mb-2"}>
                        Expected COD: <strong>{shipmentCodNumber ?? 0}</strong>
                        {/*COD Total does not equal to ( total Items Value :<> <strong className="text-danger">${sum}</strong> </> )...*/}
                        <br/>
                        <br/>
                        Total COD collection will be: <strong>{codAmount + +amount}</strong>
                        <br/>
                        <br/>

                        Are you sure you want to add COD ?
                    </p>
                </CModalBody>
                <CModalFooter>
                    <CRow className="justify-content-between w-100">
                        <CCol className="text-left">
                            <CButton variant="outline" color="danger" onClick={onClose}>
                                No
                            </CButton>
                        </CCol>
                        <CCol className="text-right">
                            <ActionButton
                                text={"Yes"}
                                onClick={onSave}
                            />
                        </CCol>
                    </CRow>
                </CModalFooter>
            </CModal>
        </>
    );
};

export default WarningModal;
