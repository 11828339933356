import {CButton, CButtonGroup, CCol, CModal, CModalBody, CModalFooter, CModalHeader, CRow} from "@coreui/react";
import SWSelect from "../../../SharedComponents/SWSelect";
import SWDateAndTimePicker from "../../../SharedComponents/SWDateAndTimePicker";
import React, {useState} from "react";
import ActionButton from "../../../SharedComponents/ActionButton";

type Props={
    onClose:()=>void
    onSave:()=>void
    onCreate:(data:{labelSize:string|undefined,pickupDate:string|null}) => void
    errorLabel:boolean
    dataUid:string
}
const GenerateLabelAgentModal=(props:Props)=>{
    const [labelSize,setLabelSize]=useState<string|undefined>()
    const [pickupDate, setPickupDate] = useState<string | null>(null)

    return(
        <div className="small text-primary">

            <CModal
                show={true}
                centered
                onClose={props.onClose}
                closeOnBackdrop={false}
            >
                <CModalHeader closeButton>
                    <h5>Generate Label</h5>
                </CModalHeader>
                <CModalBody>
                    <CCol>{`Are you sure you want to generate label for shipment "${props.dataUid}"?`}</CCol>
                <CRow className={"mt-3"}>
                    <CCol md={5}>
                        <SWSelect
                            values={[{label: "A4", value: "A4"}, {label: "A6", value: "A6"}]}
                            value={labelSize ?? ""} name={"size"}
                            isClearable={true}
                            onChange={e => {
                                setLabelSize(e)
                            }}
                            label={"Size"}
                            validationErrors={props.errorLabel ? 'required' : ''}
                        />
                    </CCol>
                    <CCol md={5}>
                        <SWDateAndTimePicker
                            showTimeInput
                            label={"Pickup date"}
                            name={"pickupDate"}
                            value={pickupDate}
                            handleOnChange={(e) => setPickupDate(e)}
                        />
                    </CCol>

                </CRow>
                </CModalBody>
                    <CModalFooter>
                        <ActionButton
                                   text={'Save as draft'}
                                    onClick={(e:any)=>{
                                        e.preventDefault();
                                        props.onSave()
                                    }}
                              />

                        <ActionButton
                            text={'Create'}
                            onClick={(e:any)=>{
                                e.preventDefault();
                                props.onCreate({labelSize:labelSize,pickupDate:pickupDate})                            }}
                        />

                    </CModalFooter>

            </CModal>
        </div>
    )


}
export default GenerateLabelAgentModal
