import {
  CForm,
  CCard,
  CCardBody,
  CFormGroup,
  CLabel,
  CInputGroup,
  CInput,
  CInputGroupAppend,
  CButton,
  CFormText,
  CCollapse,
  CCardFooter,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import ShipClient from "../../../../clients/shipClient";
import { AppModel, RemoteBlob } from "../../../../models/app";
import { FilterType } from "../../../../models/filter";
import { SetContainersFilterGroupFilters } from "../../../../store/actions/containers";
import { filterGroupToQueryParams } from "../../../../utils/filterUtils";
import ExportToExcelButton from "../../../SharedComponents/ExportToExcelButton";
import SWAdvancedSearch from "../../../SharedComponents/SWAdvancedSearch/SWAdvancedSearch";

interface Props {}
const SearchForm = (props: Props) => {
  const [containerNumber, setContainerNumber] = useState("");
  const [showAdvanced, setShowAdvanced] = useState(false);
  const dispatch = useDispatch();
  const loc = useLocation();
  const hist = useHistory();
  const shipClient = new ShipClient();
  const filterGroup = useSelector(
    (state: AppModel) => state.container.containersSearch.filterGroup
  );

  const [containerTypes, setContainerTypes] = useState({});

  useEffect(() => {
    shipClient.GetContainerTypes(null, true).then((v) => {
      setContainerTypes(v as { [k: string]: string });
    });
  }, []);

  return (
      <fieldset className="b-fieldset p-2 text-primary">
        <legend className="b-legend" style={{width:"92px"}}>
          <h5>Containers</h5>
        </legend>
        <SWAdvancedSearch
          className="p-0 m-0"
          cancelCb={() => setShowAdvanced(false)}
          filter={(fg) => {
            hist.push(
              `${loc.pathname}?${filterGroupToQueryParams({
                filters: fg,
                page: 0,
                limit: 20,
              })}`
            );
          }}
          filterGroup={filterGroup}
          defaultFilters={[
            {
              field: "Number",
              type: FilterType.Like,
              inputType: "text",
              value: "",
            },
          ]}
          availableFilters={[
            {
              field: "Number",
              type: FilterType.Equals,
              inputType: "text",
              value: "",
            },
            {
              field: "Type",
              type: FilterType.Equals,
              inputType: "select",
              value: "",
              lookUp: containerTypes,
            },
            {
              field: "OpenedOn",
              type: FilterType.Range,
              inputType: "date",
              value: new Date(),
            },
            {
              field: "ClosedOn",
              type: FilterType.Range,
              inputType: "date",
              value: new Date(),
            },
            {
              field: "IsOpen",
              type: FilterType.Equals,
              inputType: "text",
              lookUp: {
                true: "true",
                false: "false",
              },
              value: "true",
            },
          ]}
        >
          <ExportToExcelButton
            fileNamePrefix="containers"
            getData={() => {
              return shipClient.GetContainers(
                filterGroup!,
                2
              ) as Promise<RemoteBlob>;
            }}
          />
        </SWAdvancedSearch>
      </fieldset>
  );
};

export default SearchForm;
