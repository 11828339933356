import {CModal, CModalHeader, CModalBody, CDataTable} from "@coreui/react"
import {CodStatusDetails, NewCodStatusDetails} from "../../../../models/shipment";

import {formatDate} from "../../../../utils/dateUtil";

interface IProps {
    ShipmentNumber: string
    newData: NewCodStatusDetails[]
    onClose: () => void
}
const CodStatusDetailsModal = (props : IProps) => {
  return (
      <CModal show={true} onClose={props.onClose} size={"lg"}>
          <CModalHeader className={"text-primary"}>
              <h5>
                  Collection details for shipment: {props.ShipmentNumber}
              </h5>
          </CModalHeader>
          <CModalBody>
              <CDataTable
                  items={props.newData}
                  fields={[
                      {key: "amount", label: "Amount"},
                      {key: "date", label: "Collected On",},
                      {key: "payMode", label: "Collection Type"},
                      {key: "documentDate", label: "Transferred On"},
                      {key: "documentNumber", label: "Transfer Number"},
                      {key: "checkNumber", label: "Check Number"},
                      {key: "createdBy", label: "By"}
                  ]}
                  scopedSlots={{
                      date:(item: NewCodStatusDetails) => (
                          <td>{formatDate(item.date)}</td>
                      ),
                      documentDate:(item: NewCodStatusDetails) => (
                          <td>{item.documentNumber ? formatDate(item?.documentDate): ""}</td>
                      )
                  }}
              />
          </CModalBody>
      </CModal>
  )
}

export default CodStatusDetailsModal;