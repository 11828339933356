import {FilterGroup} from "../models/filter";
import { LoginRequest, UserCreateRequest, UserUpdate} from "../models/user";
import {filtersToSearchy} from "../utils/filterUtils";
import ClientWithErrorNotifier from "./clientWithErrorNotifier";

class AuthClient {
    client: ClientWithErrorNotifier;
    BaseUrl: string;
    BaseUrlNoApi: string;
    pageSize: number;

    constructor() {
        this.BaseUrlNoApi = `${process.env.REACT_APP_API_BASE_URL}/auth`;
        this.BaseUrl = `${process.env.REACT_APP_API_BASE_URL}/auth/api`;
         //this.BaseUrl = `http://localhost:5000/auth/api`;

        this.client = new ClientWithErrorNotifier(this.BaseUrl)
        this.pageSize = 20;
    }

    async Login(body:LoginRequest) {
        const rs = this.client.postAsync("/users/login", body);
        return rs;
    }

    async SearchUsers(filterGroup: FilterGroup) {
        const searchyString = filtersToSearchy(filterGroup);
        const rs = await this.client.getAsync(searchyString ? `/users?${searchyString}` : `/users`);
        return rs.data;
    }



    async GetUser(username: string) {
        const rs = await this.client.getAsync(`/users/${username}`);
        return rs.data;
    }

    async UpdateUser(accountId: string, user: UserUpdate) {
        const rs = this.client.postAsync(`/users/${accountId}`, user);
        return rs;
    }

    async CreateUser(user: UserCreateRequest) {
        const rs = this.client.postAsync("/users", user);
        return rs;
    }
    async InitiatePasswordReset(user: {emailOrUserName:string}) {
        const rs = this.client.postAsync("users/InitiatePasswordReset", user);
        return rs;
    }
    async PasswordReset(accountId:string,password: {newPassword:string,token:string}) {
        const rs = this.client.postAsync(`users/${accountId}/resetPassword`, password);
        return rs;
    }
    async ChangePassword(changePasswordRequest: {newPassword:string,currentPassword:string}) {
        const rs = this.client.postAsync(`users/ChangePassword`, changePasswordRequest);
        return rs;
    }

    async Me() {
        const rs = await this.client.getAsync(`/users/Me`);
        return rs.data;
    }
}

export default AuthClient;
