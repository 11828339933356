import React from "react";
import { CCard, CCardBody, CCardTitle, CCardText, CCol, CButton, CRow } from "@coreui/react";
import SWInput from "../../../SharedComponents/SWInput";
import { useFormik } from 'formik';
import { useHistory } from "react-router";
import ShipClient from "../../../../clients/shipClient";
import { ShowNotification } from "../../../../store/actions/auth";
import { useDispatch } from "react-redux";
import { DataType, SearchyFilter, SearchyRequest } from "../../../../models/Searchy";
import RestIcon from '../../../../icons/resetIcon';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { SetShipmentsFilterGroupFilters } from "../../../../store/actions/shipments";
import { SearchFilter, SearchFilterInitialValues, searchFilterValidationSchema, SearchyRequestInitialValues } from "../../../../models/dashboard";

const SearchBar = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const shipClient = new ShipClient();
    const searchyRequest: SearchyRequest = SearchyRequestInitialValues();
    const { resetForm, setFieldValue, values, touched, errors, handleSubmit } = useFormik<SearchFilter>({
        enableReinitialize: true,
        initialValues: SearchFilterInitialValues(),
        validationSchema: searchFilterValidationSchema(),
        onSubmit: async (values) => {
            let filters = [] as SearchyFilter[]
            !!values?.number && filters?.push({ field: "Number", rule: 1, value: values.number });
            !!values?.accountReference && filters?.push({ field: "AccountReference", rule: 1, value: values.accountReference });
            const searchReq = {
                ...searchyRequest,
                conditions: [{ filters }],
            };
            const res = await shipClient.getShipmentsForStaff(searchReq);
            if (res?.totalCount === 0) {
                dispatch(ShowNotification("Error", "Shipment does not exist!", true));
            } else if (res?.totalCount === 1) {
                const shipmentUid = res.result[0].uid;
                history.push({ pathname: `/shipments/${shipmentUid}` });
            } else if (res?.totalCount > 1) {
                const shipmentFilters = filters
                    .filter((item) => !!item.value)
                    .map((item) => ({
                        field: item.field,
                        type: Number(item.rule),
                        value: item.value,
                        dataType: DataType.string, // Number & AccountReference
                    }));
                dispatch(SetShipmentsFilterGroupFilters(shipmentFilters));
                history.push({
                    pathname: '/shipments/search',
                });
            } else {
                dispatch(ShowNotification("Error", "Error", true));
            }
        },
    })

    return (
        <CCard>
            <CCardBody>
                <CCardTitle
                    className={"text-primary"}
                    style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <CCol className={"m-0 p-0"}>
                        Quick Search
                        <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            tabIndex={-1}
                            size={"xl"}
                            style={{ alignItems: "center", marginLeft: 8 }} />
                    </CCol>
                    <CCol
                        className={"m-0 p-0"}
                        style={{ textAlign: "end" }}>
                        <RestIcon
                            color={"#00007d"}
                            tabIndex={-1}
                            onClick={() => resetForm()}
                            tooltipText={'Clear all'} />
                    </CCol>
                </CCardTitle>
                <CCardText />
                <CRow>
                    <CCol md={4} sm={12}>
                        <SWInput
                            className="text-primary"
                            label={"Hawb"}
                            name={`number`}
                            value={values?.number ?? ""}
                            onChange={(e) => setFieldValue('number', e?.trim())}
                            type={"text"}
                            validationErrors={touched?.number && errors?.number?.toString() || ""}
                        />
                    </CCol>
                    <CCol md={4} sm={12}>
                        <SWInput
                            className="text-primary"
                            label={"Reference"}
                            name={`accountReference`}
                            value={values?.accountReference ?? ""}
                            onChange={(e) => setFieldValue('accountReference', e?.trim())}
                            type={"text"}
                            validationErrors={touched?.accountReference && errors?.accountReference?.toString() || ""}
                        />
                    </CCol>
                    <CCol md={4} sm={12} className={"mt-1"}>
                        <CButton
                            size="md"
                            style={{ fontSize: "10px" }}
                            className="mr-2 mt-4"
                            color="primary"
                            onClick={() => handleSubmit()}
                        >
                            Search
                        </CButton>
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>

    )
}

export default SearchBar;