import {CButton, CCol, CInputRadio, CLabel, CRow,} from "@coreui/react";
import React from "react";
import {useFormik} from "formik";
import {useLocation} from "react-router";
import {querytoMap} from "../../../utils/filterUtils";

import SWDateAndTimePicker from "../../SharedComponents/SWDateAndTimePicker";
import {MachineSortingQueryModel} from "../../../models/machineSorting";
import ActionButton from "../../SharedComponents/ActionButton";

interface Props {
    onSearchClicked: (filter: MachineSortingQueryModel) => void;
    onClearClicked: () => void;
}

const SearchForm = (props: Props) => {
    let {search} = useLocation();

    const queryMap = querytoMap<MachineSortingQueryModel>(search);
    const {
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
    } = useFormik<MachineSortingQueryModel>({
        initialValues: {

            pageSize: 20,
            pageIndex: 0,
            from: "",
            to: "",
        },
        onSubmit: props.onSearchClicked,
    });


    return (
        <fieldset className="b-fieldset p-2 text-primary">
            <legend className="b-legend" style={{width: "115px"}}>
                <h5 style={{width: "115px"}}>Machine Logs</h5>
            </legend>
            <CRow className={"col-md-12"}>
                <CCol md={2}>
                    <CInputRadio
                        style={{
                            width: "1.25em",
                            height: "1.25em",
                            fontSize: "10px",
                        }}
                        // disabled={quotation.attachments?.name == ""}
                        checked={values.isDownload == null || values.isDownload == undefined}
                        onClick={(e) => {
                            setFieldValue("isDownload", undefined);
                        }}
                    />
                    <CLabel className="text-primary">All</CLabel>
                </CCol>
                <CCol md={3}>
                    <CInputRadio
                        style={{
                            width: "1.25em",
                            height: "1.25em",
                            fontSize: "10px",
                        }}
                        // disabled={quotation.attachments?.name == ""}
                        checked={values.isDownload}
                        onClick={(e) =>
                            setFieldValue("isDownload", e.currentTarget.checked)
                        }
                    />
                    <CLabel className="text-primary">Download</CLabel>
                </CCol>
                <CCol md={3}>
                    <CInputRadio
                        style={{
                            width: "1.25em",
                            height: "1.25em",
                            fontSize: "10px",
                        }}
                        // disabled={quotation.attachments?.name == ""}
                        checked={values.isDownload == false}
                        onClick={(e) =>
                            setFieldValue("isDownload", false)
                        }
                    />
                    <CLabel className="text-primary">Upload</CLabel>
                </CCol>
            </CRow>


            <CCol md={3}>
                <SWDateAndTimePicker
                    label={"On From"}
                    name={"from"}
                    value={values.from}
                    handleOnChange={(e) => setFieldValue("from", e)}
                />
            </CCol>
            <CCol md={3}>
                <SWDateAndTimePicker
                    label={"On To"}
                    name={"to"}
                    value={values.to}
                    handleOnChange={(e) => setFieldValue("to", e)}
                />
            </CCol>

            <CRow>
                <CCol md={3}>
                    <CButton
                        size="sm"
                        style={{fontSize: "10px"}}
                        variant="outline"
                        color="danger"
                        onClick={() => {
                            resetForm();
                            props.onClearClicked();
                        }}
                    >
                        Clear all
                    </CButton>
                </CCol>
                <CCol/>
                <CCol className={"text-right"}>
                    <ActionButton
                        text={"Search"}
                        onClick={handleSubmit}
                    />
                </CCol>
            </CRow>
        </fieldset>
    );
};


export default SearchForm;
