import { CButton, CContainer, CRow, CTooltip } from "@coreui/react";
import React, { useEffect } from "react";
import ShipClient from "../../../clients/shipClient";
import {useHistory, useLocation} from "react-router";
import { queryParamsToFilters } from "../../../utils/filterUtils";
import { useSearchOnFilterGroup } from "../../../hooks/helperHooks";

import { useDispatch, useSelector } from "react-redux";
import { AppModel } from "../../../models/app";
import {
  SetSortingRuleResult,
  SetSortingRulesFilterGroupFilters,
  SetSortingRuleIsLoading,
  SetSortingRulePage,
  SetSortingRulesFilterGroup,
} from "../../../store/actions/sortingrules";

import SearchForm from "./components/SearchForm";
import SearchResult from "./components/SearchResult";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";


const SearchSortingRules = () => {
  const dispatch = useDispatch();
  const shipClient = new ShipClient();

  const hist = useHistory()

    useSearchOnFilterGroup({
      updateFilterGroup: (fg) => dispatch(SetSortingRulesFilterGroup(fg)),
      useFilterGroupSelector: () =>
          useSelector(
              (state: AppModel) => state.sortingrule.sortingrulesSearch.filterGroup!
          ),
      searchFunction: shipClient.GetSortingRules.bind(shipClient),
      setIsLoading: (loading) => dispatch(SetSortingRuleIsLoading(loading)),
      setResult: (result) =>
          dispatch(
              SetSortingRuleResult(result.page!, result.count!, result.result!)
          ),
  });

  const loc = useLocation();

  const searchSortingRules = () => {
    const fg = queryParamsToFilters(loc.search);
    dispatch(SetSortingRulesFilterGroup(fg));
  };

  useEffect(() => {
    searchSortingRules();
  }, [loc.search]);

  return (
    <CContainer className="bg-white p-4 text-primary">
      <SearchForm />
        <CRow className={"justify-content-start text-primary p-1"}>
            <CTooltip content={"add new sorting role"}>
            <CIcon
                onClick={() => hist.push("/settings/sortingrules/create")}
                content={freeSet.cilPlus}
                className="ml-3 mt-1"
                style={{
                    height: "1.5em",
                    width: "1.5em",
                    cursor: "pointer"
                }}
            />
            </CTooltip>
        </CRow>
      <SearchResult />
    </CContainer>
  );
};

export default SearchSortingRules;
