
import React, {useEffect, useState} from "react";
import {ErrorDescription} from "../../../../../models/shipment";
import {CButton, CCard, CContainer, CDataTable, CLink, CRow, CTooltip} from "@coreui/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle, faEye} from "@fortawesome/free-solid-svg-icons";
import {convertToLocalDate, formatDateTime} from "../../../../../utils/dateUtil";
import WarehousesClient from "../../../../../clients/warehousesClient";
import {SkuImportSearch} from "../../../../../models/sku";
import ImportErrorTable from "../../../ShipmentsImport/Modals/ImportErrorTable";
import DownloadIcon from "../../../../../icons/downloadIcon";
import ProgressBar from "../../../../SharedComponents/ProgressBar";

const SearchSkuImport = () => {

    const warehousesClient = new WarehousesClient();
    const [importRecords, setImportRecords] = useState<SkuImportSearch[] | undefined>(undefined)
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
    const [selectedErrors, setSelectedErrors] = useState<ErrorDescription[]>([])
    const [selectedAccount, setSelectedAccount] = useState<string>()
    const loadImport = async () => {
        const rs = await warehousesClient.SearchSkuImport({limit: 400})

        setImportRecords(rs);
    }
    useEffect(() => {

        const interval =setInterval(()=>{
            loadImport();

        },2000)

        return()=>clearInterval(interval)

    }, [importRecords])


    const scopedSlots = {
        links: (item: SkuImportSearch) => {
            return <td>

                <CLink  href={item?.fileUrl}
                        target="_blank" className="card-header-action">
                    <DownloadIcon onClick={()=>{}}/>

                </CLink>

            </td>;
        },
        hasErrors: (item: SkuImportSearch) => {
            return item.errors && item.errors.length > 0 ?
                <td>
                    <CContainer>
                        <CTooltip content={"Show error(s)"}>
                            <FontAwesomeIcon icon={faExclamationCircle} size="lg"
                                             color={"red"}
                                             style={{cursor: "pointer"}}
                                             onClick={() => {
                                                 setSelectedAccount(item.accountName)
                                                 setSelectedErrors(item?.errors ??[])
                                                 setShowErrorModal(true)
                                             }}
                            />
                        </CTooltip>
                    </CContainer>
                </td> :
                <td/>
        },
        createdOn: (item: SkuImportSearch) => {
            return <td>{formatDateTime(convertToLocalDate(item?.createdOn??''))}</td>
        },
        rowsImported: (item: SkuImportSearch) => {
            return <td>{(item?.skusAdded?? 0 )+ (item?.skusUpdated ?? 0)}</td>
        },
        rowsFailed: (item: SkuImportSearch) => {

            if(item.finished)
                return <td>{Number((item?.totalRows??0) - (item?.skusAdded ?? 0) - (item?.skusUpdated??0) )}</td>
            return <td>{item?.errors?.length}</td>
        },
        progress:(item:SkuImportSearch)=> {
            let total = 1
            if(item?.totalRows ?? 0>0)
                total=item.totalRows ??0
            if(item.finished)
                return <td>  <ProgressBar value={100}/></td>
           return <td>

                <ProgressBar value={Number(((((item?.skusAdded ?? 0 ) + (item?.skusUpdated??0) +(item?.errors?.length ?? 0)) /total) * 100)?.toFixed(2))}/>
            </td>
        }
    };
    return (
        <CCard className="bg-white p-4 text-black w-100">

            <CDataTable
                size="sm"
                hover
                scopedSlots={scopedSlots}
                items={importRecords}
                fields={[
                    {key: "links", label: "", _style: {width: '7%', padding: '0.25rem'}},
                    {key: "hasErrors", label: "", _style: {width: '2%', padding: '0.25rem'}},
                    {key: "id", label: "id"},
                    {key: "accountName", label: "account"},
                    {key: "createdOn", label: "On"},
                    {key: "createdBy", label: "By"},
                    {key: "finished", label: "completed"},
                    {key: "totalRows", label: "Rows"},
                    {key: "progress", label: "progress"},
                    {key: "skusAdded", label: "Added"},
                    {key: "skusUpdated", label: "Updated"},
                    {key: "rowsFailed", label: "Failed"},

                ]}

            />
            {showErrorModal && <ImportErrorTable account={selectedAccount} handleClose={() => {
                setSelectedAccount(undefined)
                setSelectedErrors([])
                setShowErrorModal(false)
            }} errors={selectedErrors}/>}
        </CCard>
    );
}

export default SearchSkuImport;
