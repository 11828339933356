import {CButton, CCol, CContainer, CDataTable, CInputCheckbox, CSpinner, CTooltip} from "@coreui/react";
import React, {useEffect, useState} from "react";
import ShipClient from "../../../../clients/shipClient";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import GenerateMonthReportModal from "../modals/GenerateMonthReportModal";
import {Field, Form, Formik} from "formik";
import DateInputField from "../../../SharedComponents/FormikCustomFields/DateInputField";
import {downloadBlob} from "../../../../utils/downloadUtils";
import GenerateMonthReportArrayModal from "../modals/GenerateMonthReportArrayModal";
import SelectField from "../../../SharedComponents/FormikCustomFields/SelectField";
import ActionButton from "../../../SharedComponents/ActionButton";
import {useSelector} from "react-redux";
import {AppModel} from "../../../../models/app";
import SWDateAndTimePicker from "../../../SharedComponents/SWDateAndTimePicker";
import SWSelect from "../../../SharedComponents/SWSelect";
import DateFormat from "dateformat";
import {ref} from "yup";
import {is} from "date-fns/locale";


interface Props {
    profile: any;
    profileLookup: any;
    onChange: (e: any) => void
}

const PendingReportTable = ({profile, profileLookup, onChange}: Props) => {
    const shipClient = new ShipClient();
    const [showGenerateReport, setShowGenerateReport] = useState(false)
    const [showGenerateReportArray, setShowGenerateReportArray] = useState(false)
    const [reportData, setReportData] = useState<any>({})
    const [pendingCod, setPendingCod] = useState<any[]>([])
    const [args, setArgs] = useState<any>([])
    const [pickupDate, setPickupDate] = useState(new Date(new Date().setDate(0)))
    const [isSubmitting, setIsSubmitting] = useState(false)
    const isLoading = useSelector((state: AppModel) => state.app.isLoading);
    const [allPendingSelected, setAllPendingSelected] = useState(false)

    
    useEffect(() => {
        if (isLastDay(new Date()))
            setPickupDate(new Date())
    }, [])
    
    const refreshData = (selectedArgs: string[]) => {

        setIsSubmitting(true)
        setPendingCod([])
        if (selectedReports.length > 0) {
            setSelectedReports([])
        }
        if (profile) {
            shipClient.getPendingMonthReports(pickupDate, profile, selectedArgs).then(res => {
                if (res && res?.length >= 0) {
                    setPendingCod(res)
                } else {
                    setPendingCod([])
                }
                setIsSubmitting(false)
            })
        } else {
            setIsSubmitting(false)
        }

    }

    const handleArgs = (name: string) => {
        setSelectedReports([])

        const newArgs = args.includes(name) ? [...args.filter((item: any) => item !== name)] : [...args, name];

        setArgs(newArgs);
        refreshData(newArgs)
    }

    const isLastDay = (dt: Date) => {
        return new Date(dt.getTime() + 86400000).getDate() === 1;
    }

    const scopedSlots = {
        consigneeCountry: (item: any) => {
            return <td className={"text-center"}>{item.consigneeCountry ?? ""}</td>
        },
        agent: (item: any) => {
            return <td className={"text-center"}>{item.agent ?? ""}</td>
        },
        numberOfShipments: (item: any) => {
            return <td className={"text-center"}>{item.numberOfShipments ?? ""}</td>
        },
        numberOfReturns: (item: any) => {
            return <td className={"text-center"}>{item.numberOfReturns ?? ""}</td>
        },
        exportToExel: (item: any) => {
            return <td className={"text-end"}>
                <CTooltip content="Export to Exel">
                    <CContainer>
                        <CIcon content={freeSet.cilCloudDownload} size="lg"
                                         onClick={async () => {
                                             const body = {
                                                 account: item.account,
                                                 consigneeCountry: item.consigneeCountry,
                                                 agent: item.agent,
                                                 generate: false,
                                                 pickupDate: pickupDate
                                             }
                                             const rs = await shipClient.generateMonthReport(body)
                                             const data = rs.data
                                             downloadBlob(data.location, data.name, "_self")
                                         }}
                                         style={{cursor: "pointer"}}
                        />
                    </CContainer>
                </CTooltip>
            </td>
        },
        generateReport: (item: any) => {
            return <td className={"text-center"}>
                <CTooltip content="Generate Report">
                    <CIcon
                        content={freeSet.cilDescription}
                        onClick={() => {
                            setReportData({
                                account: item.account,
                                consigneeCountry: item.consigneeCountry,
                                agent: item.agent,
                                generate: true,
                                pickupDate: pickupDate
                            })
                            setShowGenerateReport(!showGenerateReport)
                        }}
                        style={{cursor: "pointer"}}
                    />
                </CTooltip>
            </td>
        },
        selectReport: (item: any) => {
            return <td className={"text-center"}>
                {item.account ?
                    <CTooltip content="Select Report">
                        <CInputCheckbox
                            checked={selectedReports.filter((i: any) => i.id == generateId(item)).length > 0 ?? false}
                            style={{marginTop: "auto"}}
                            onChange={() => handleSelectReport(item)}
                        />
                    </CTooltip> : <CTooltip content="Report can not be generated with out 'Account'">
                        <CInputCheckbox
                            style={{marginTop: "auto"}}
                            disabled={true}
                        />
                    </CTooltip>
                }
            </td>
        }
    }

    const [selectedReports, setSelectedReports] = useState<any>([])

    const generateId = (item: any) => "" + item.account + "-" + item.consigneeCountry + "-" + item.agent
    const generateName = (item: any) => {
        const dateReverse = DateFormat(pickupDate, "yyyy-mm-dd")
        const nameElements = [item.account, item.consigneeCountry, item.agent, dateReverse]
        return nameElements.filter(i => !!i).join("-").replace(/\//g, '-')
    }


    const handleSelectReport = (item: any) => {
        let checked = selectedReports.filter((i: any) => i.id == generateId(item))?.length > 0
        if (checked) {
            setSelectedReports(selectedReports.filter((i: any) => i.id != generateId(item)))
        } else {
            const report = {
                account: item.account,
                consigneeCountry: item.consigneeCountry,
                agent: item.agent,
                generate: true,
                pickupDate: pickupDate,
                name: generateName(item),
                id: generateId(item)
            }
            setSelectedReports([...selectedReports, report])
        }
    }
    const handleSelectAll = () => {
        if (allPendingSelected) {
            setSelectedReports([])
            setAllPendingSelected(false)
        } else {
            let reports = pendingCod.filter(i => i.account).map(item => {
                return {
                    account: item.account,
                    consigneeCountry: item.consigneeCountry,
                    agent: item.agent,
                    generate: true,
                    pickupDate: pickupDate,
                    name: generateName(item),
                    id: generateId(item)
                }
            })
            setSelectedReports(reports ?? [])
            setAllPendingSelected(true)
        }
    }

    return (

        <>
            <CContainer>
                <Formik
                    initialValues={{
                        pickupDate,
                        profile
                    }}
                    enableReinitialize={true}
                    onSubmit={(values) => {
                        setPickupDate(values.pickupDate)
                        //onChange(values.profile)
                        setSelectedReports([])
                        setAllPendingSelected(false)

                    }}

                >
                    {() => (
                        <Form>
                            <CCol className="col-sm-6 d-flex align-items-center mt-2">
                                <CCol>
                                    <SWSelect
                                        label={"Profile"}
                                        values={profileLookup} value={profile} name="profile"
                                        onChange={onChange}/>

                                </CCol>
                                <CCol>
                                    <SWDateAndTimePicker label="Date" name="pickupDate" showTimeInput={false}
                                                         handleOnChange={(e) => setPickupDate(e)} value={pickupDate}/>
                                </CCol>
                                <CCol className={"d-flex align-items-center"}>
                                    <ActionButton text={"Search"} extraClass={"ml-2"} onClick={() => {

                                        refreshData(args);
                                    }} type={"button"}/>
                                </CCol>
                            </CCol>
                        </Form>)}
                </Formik>

                {showGenerateReport &&
                    <GenerateMonthReportModal isShowing={showGenerateReport} setIsSubmitting={setIsSubmitting}
                                              isSubmitting={isSubmitting}
                                              onClose={(refresh) => {
                                                  setShowGenerateReport(!showGenerateReport)
                                                  if (refresh)
                                                      refreshData(args)

                                              }}
                                              data={reportData}/>}
                {showGenerateReportArray &&
                    <GenerateMonthReportArrayModal isShowing={showGenerateReportArray} setIsSubmitting={setIsSubmitting}
                                                   onDone={() => {
                                                       setSelectedReports([])
                                                       refreshData(args)
                                                   }}
                                                   onClose={() => {
                                                       setShowGenerateReportArray(!showGenerateReportArray)
                                                       refreshData(args)
                                                   }}
                                                   data={selectedReports}/>}
                {selectedReports.length > 0 ?
                    <CCol className={"d-flex w-100 align-items-center justify-content-end"}>
                        <CButton
                            size="sm"
                            style={{fontSize: "10px", marginBottom: "10px"}}
                            variant="outline"
                            color="primary"
                            onClick={() => {
                                setShowGenerateReportArray(true)
                            }
                            }
                        >
                            Generate {selectedReports.length} report{selectedReports.length > 1 ? 's' : ''}
                        </CButton>
                    </CCol> : <div style={{height: 35}}></div>
                }
                {isSubmitting ?
                    <CSpinner
                        className="mx-auto d-block my-5"
                        color="primary"
                        style={{width: "5em", height: "5em"}}
                    />
                    :
                    <CDataTable
                        size="sm"
                        hover
                        columnHeaderSlot={{
                            consigneeCountry:
                                <><CInputCheckbox
                                    style={{top: "0"}}
                                    checked={args.includes("ConsigneeCountry")}
                                    onChange={() => handleArgs("ConsigneeCountry")}
                                />Country</>,
                            agent:
                                <><CInputCheckbox
                                    style={{top: "0"}}
                                    checked={args.includes("Agent")}
                                    onChange={() => handleArgs("Agent")}

                                />Agent</>,
                            selectReport: <CInputCheckbox
                                style={{top: "0"}}
                                onClick={handleSelectAll}
                            />
                        }}
                        scopedSlots={scopedSlots}
                        items={pendingCod}
                        loading={isSubmitting}
                        fields={[
                            {key: "exportToExel", label: "", _style: {width: '5%', padding: '0.2rem'}},
                            {key: "accountName", label: "Account"},
                            {key: "consigneeCountry", label: "Country", _style: {textAlign: "center"}},
                            {key: "agent", label: "Agent", _style: {textAlign: "center"}},
                            {key: "numberOfShipments", label: "Shipments", _style: {textAlign: "center"}},
                            {key: "numberOfReturns", label: "Returns", _style: {textAlign: "center"}},
                            {
                                key: "generateReport",
                                label: "Actions",
                                _style: {textAlign: "center", width: '5%', padding: '0.2rem'}
                            },
                            {
                                key: "selectReport",
                                label: "Actions",
                                _style: {textAlign: "center", width: '5%', padding: '0.2rem'}
                            }

                        ]}
                    />
                }
            </CContainer>
        </>
    )
}

export default PendingReportTable