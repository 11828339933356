import {CContainer} from "@coreui/react";
import React, {useEffect, useState} from "react";
import SearchForm from "./SearchForm";
import SearchResult from "./SearchResult";
import {querytoMap, toQueryParam} from "../../../utils/filterUtils";
import {useHistory, useLocation} from "react-router";
import {ISearchyPaging} from "../../../models/filter";
import ShipClient from "../../../clients/shipClient";
import {SearchyResult} from "../../../models/app";
import {MachineSortingHistory, MachineSortingQueryModel} from "../../../models/machineSorting";
import ConfirmModal from "../../SharedComponents/ConfirmModal";
import {ShowNotification} from "../../../store/actions/auth";
import {useDispatch} from "react-redux";
import { SetIsLoading } from "../../../store/actions/ui";

interface Props {
}


const SearchMachineLogs = (props: Props) => {
    const client = new ShipClient();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false)
    const [exportDisabled, setExportDisabled] = useState(false)
    const [pullDisabled, setPullDisabled] = useState(false)
    const [confirmPushModel, setConfirmPushModel] = useState<boolean>(false)
    const [confirmPullModal, setConfirmPullModel] = useState<boolean>(false)
    const [paginatedLogs, setPaginatedLogs] = useState<SearchyResult<MachineSortingHistory>>({
        result: [],
        totalCount: 0
    })
    const [pagination, setPagination] = useState<ISearchyPaging>({
        pageSize: 20,
        pageIndex: 0,
    });
    let {search, pathname} = useLocation();
    let hist = useHistory();


    useEffect(() => {
        const map = querytoMap<MachineSortingQueryModel>(search);
        setPagination((state) => ({
            pageIndex: map.pageIndex || state.pageIndex,
            pageSize: map.pageSize || state.pageSize
        }));
        handleSearch(map);
    }, [search])


    const handleSearch = async (filter: MachineSortingQueryModel) => {
        setLoading(true)
        dispatch(SetIsLoading(true));
        const res = await client.getMachineSortingHistory(filter)
        setPaginatedLogs({result: res.result, totalCount: res.totalCount});
        setLoading(false)
    }

    const onPushConfirmed = async () => {
        setExportDisabled(true);
        const result = await client.uploadMachinePending();
        setConfirmPushModel(false)
        setExportDisabled(false);
        if (result.id)
            dispatch(ShowNotification("Success", `${result.totalShipments} Shipments Uploaded`));
    }
    const onPullConfirmed = async () => {
        setPullDisabled(true);
        const result = await client.downloadMachinePending();
        setConfirmPullModel(false)
        setPullDisabled(false);
        if (result ){
            const totalShipments= result.reduce((n, {totalShipments}) => n + totalShipments, 0);
            dispatch(ShowNotification("Success", `${result.length} Files Downloaded ` + 
                `${totalShipments} sorting records`));
        }
    }
    return (
        <>
            <CContainer className="bg-white p-4">
                <SearchForm onClearClicked={() => hist.push(pathname)} onSearchClicked={(filter) => {
                    hist.push(
                        `${pathname}?${toQueryParam({...pagination, ...filter})}`
                    );
                }}/>
                {/*<CRow className={"justify-content-start text-primary p-1"}>*/}
                {/*    <CTooltip content={"New "}>*/}
                {/*        <CIcon*/}
                {/*            size={"xl"}*/}
                {/*            style={{cursor: 'pointer'}}*/}
                {/*            className="btn-icon"*/}
                {/*            content={freeSet.cilPlus}*/}
                {/*        />*/}
                {/*    </CTooltip>*/}
                {/*</CRow>*/}
                <SearchResult
                    paginatedLogs={paginatedLogs}
                    isLoading={loading}
                    pagination={pagination}
                    exportDisabled={exportDisabled}
                    onPullClick={()=> setConfirmPullModel(true)}
                    onExportClick={() => setConfirmPushModel(true)}
                />
            </CContainer>
            {confirmPushModel &&
                <ConfirmModal onClose={() => setConfirmPushModel(false)} onSubmit={onPushConfirmed}
                              title={"Push Shipments To Machine"}
                              body={"Are you sure you want to push shipments to machine"}/>}

            {confirmPullModal &&
                <ConfirmModal onClose={() => setConfirmPullModel(false)} onSubmit={onPullConfirmed}
                              title={"Pull Sorting Records From Machine"}
                              body={"Are you sure you want to pull sorting records from machine"}/>}

        </>
    );
};

export default SearchMachineLogs;
