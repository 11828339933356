import {CSpinner, CDataTable, CButton, CCol, CRow} from "@coreui/react";
import React, {useState} from "react";
import PageCount from "../../SharedComponents/PageCount";
import {useHistory, useLocation} from "react-router";

import {ISearchyPaging} from "../../../models/filter";
import {SearchyResult} from "../../../models/app";
import {MachineSortingHistory} from "../../../models/machineSorting";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import {ShowNotification} from "../../../store/actions/auth";
import {useDispatch} from "react-redux";
import {formatDateTime} from "../../../utils/dateUtil";
import ActionButton from "../../SharedComponents/ActionButton";

interface Props {
    paginatedLogs: SearchyResult<MachineSortingHistory>
    pagination: ISearchyPaging
    isLoading: boolean
    onExportClick: () => void
    onPullClick: () => void
    exportDisabled: boolean
}

const SearchResult = (props: Props) => {
    const [pageSize, setPageSize] = useState<number>(20)

    const hist = useHistory();
    const loc = useLocation();
    const dispatch = useDispatch();
    const handleCopy = async (text: string) => {
        await navigator.clipboard.writeText(text)
        dispatch(ShowNotification("Success", "Url coped to clipboard", false))
    }

    const handleDownload = (text: string) => {
        const link = document.createElement("a");
        link.href = text;
        link.click();
        link.remove();
    }

    return (
        <div className="small">
            {props.isLoading ? (
                <CSpinner
                    className="mx-auto d-block my-5"
                    color="primary"
                    style={{width: "5em", height: "5em"}}
                />
            ) : (
                <>
                    <CDataTable
                        hover
                        size="sm"
                        
                        scopedSlots={
                            {

                                createdOn: (item: MachineSortingHistory) => {
                                    return <td>{formatDateTime(item.createdOn)}</td>
                                },
                                fileUrl: (item: MachineSortingHistory) => {
                                    return <td>
                                        <CIcon
                                            content={freeSet.cilCloudDownload}
                                            style={{cursor: "pointer", margin: "auto"}}
                                            onClick={() => handleDownload(item.fileUrl)}
                                        />
                                        <CIcon
                                            content={freeSet.cilCopy}
                                            style={{cursor: "pointer", marginLeft: "10px"}}
                                            onClick={() => handleCopy(item.fileUrl)}
                                        />
                                    </td>
                                },
                                isDownload: (item : MachineSortingHistory) => {
                                    let content = item.isDownload ? freeSet.cilArrowBottom : freeSet.cilArrowTop
                                    return <td>
                                        <CIcon
                                            content={content}
                                        />
                                    </td>
                                }

                            }
                        }
                        items={props.paginatedLogs.result}
                    />
                    <CRow>
                        <CCol>
                            <PageCount
                                onPageChange={(p) => {
                                    const modifiedSearch = new URLSearchParams(loc.search);
                                    modifiedSearch.delete("pageIndex");
                                    hist.push(
                                        `${loc.pathname}?${modifiedSearch.toString()}&pageIndex=${p * props?.pagination?.pageSize!}`
                                    );
                                }}
                                onPageSizeChange={(ps) => {
                                    const modifiedSearch = new URLSearchParams(loc.search);
                                    modifiedSearch.delete("PageSize");
                                    hist.push(
                                        `${loc.pathname}?${modifiedSearch.toString()}&PageSize=${ps}`
                                    );
                                    setPageSize(ps)
                                }}
                                maxFound={props.paginatedLogs.totalCount}
                                currentPage={Math.ceil(props?.pagination?.pageIndex! / pageSize!)}
                                maxPage={Math.ceil(props.paginatedLogs?.totalCount! / pageSize!)}
                            />
                        </CCol>
                        <CCol className={"text-right"} style={{marginTop:"16px"}}>
                            <ActionButton
                                text={"Pull Now From Machine"}
                                onClick={() => props.onPullClick()}
                            />
                            <ActionButton
                                text={"Push Now To Machine"}
                                disabled={props.exportDisabled}
                                onClick={() => props.onExportClick()}
                            />
                        </CCol>
                    </CRow>
                </>
            )}
        </div>
    );
};

export default SearchResult;
