import React, { useEffect, useState } from "react";
import { CDataTable, CLink, CTooltip } from "@coreui/react";
import { ContainerSearch } from "../../../../../models/container";
import {  formatDate } from "../../../../../utils/dateUtil";
import CIcon from "@coreui/icons-react";
import { freeSet } from "@coreui/icons";
import PageCount from "../../../../SharedComponents/PageCount";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AppModel, SearchyResult } from "../../../../../models/app";
import ShipClient from "../../../../../clients/shipClient";
import { DownloadShipmentPrintRequest, ShipmentPrintType } from "../../../../../models/shipment";
import FilledDownloadIcon from "../../../../../icons/filledDownloadIcon";
import DownloadIcon from "../../../../../icons/downloadIcon";
import { ShowNotification } from "../../../../../store/actions/auth";
import { FilterGroup } from "../../../../../models/filter";
import { SetIsLoading } from "../../../../../store/actions/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    refreshPrintedShipmentTable?: boolean

}
const ContainersTable = ({ refreshPrintedShipmentTable }: IProps) => {
    const labelGenerationContainerId = process.env.REACT_APP_REPRINT_CONTAINER_ID ?? "5"
    const hist = useHistory();
    const dispatch = useDispatch();
    const shipClient = new ShipClient();
    const isLoading = useSelector((state: AppModel) => state.app.isLoading);
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
    const [filterGroup, setFilterGroup] = useState<FilterGroup>({
        page: 0,
        pageSize: 20,
        limit: 20
    });
    const [data, setData] = useState<SearchyResult<ContainerSearch>>();

    const fetchData = async (filterGroup: FilterGroup) => {
        dispatch(SetIsLoading(true))
        const res = await shipClient.SearchContainers(filterGroup, labelGenerationContainerId);
        setData(res)
    };

    useEffect(() => {
        fetchData(filterGroup)
    }, [refreshPrintedShipmentTable]);

    const handleGetPrintedContainerReport = async (id: number) => {
        let res = await shipClient.GetPrintedContainerReport(id.toString());
        window.open(res.location, "_blank", "noreferrer");
    };

    const getContainersByIds = async () => {
        const totalCount = data?.totalCount;
        const results = [...data?.result || []];
        let processingContainersIds = data?.result?.filter((item) => item?.processing)?.map((e) => e?.id) || []
        if (processingContainersIds?.length > 0) {
            try {
                let res = await shipClient.GetContainersByIds({ ids: processingContainersIds });
                if (res?.succeeded) {
                    res?.data?.forEach((container: ContainerSearch) => {
                        const toBeUpdatedIndex = results?.findIndex(obj => obj?.id === container?.id);
                        if (toBeUpdatedIndex !== -1) {
                            results[toBeUpdatedIndex] = {
                                ...results[toBeUpdatedIndex],
                                processing: container?.processing,
                                attachments: container?.attachments,
                            };
                        } else {
                            console.log(`id ${container?.id} not found`);
                        }
                    });
                    setData({ result: results, totalCount: totalCount ?? 0 })
                } else {
                    dispatch(ShowNotification("Error", res?.error, true));
                }

            } catch (error) {
                console.error("Error fetching containers:", error);
            }
        }
    }

    useEffect(() => {
        if (data?.result?.some(item => item?.processing)) {
            const id = setInterval(() => {
                getContainersByIds();
            }, 5000);
            setIntervalId(id);
        } else {
            if (intervalId !== null) {
                clearInterval(intervalId);
            }
            setIntervalId(null);
        }
        return () => {
            if (intervalId !== null) {
                clearInterval(intervalId);
            }
            setIntervalId(null);
        };
    }, [data?.result]);


    useEffect(() => {
        return () => {
            if (intervalId !== null) {
                clearInterval(intervalId);
                setIntervalId(null);
            }
        };
    }, []);


    const typeResolver = (type: string): ShipmentPrintType => {
        switch (type) {
            case "Label":
                return 0
            case "COD":
                return 1
            case "Shipment Invoice":
                return 2
            case "Packing List":
                return 3
            default:
                return 0;
        }
    }

    const handelDownload = async (id: number, data: DownloadShipmentPrintRequest) => {
        let res = await shipClient.DownloadPrintedShipments(id.toString(), data);
        window.open(res?.location, "_blank", "noreferrer");
        fetchData(filterGroup)
    }
    const filledIcon = (type: ShipmentPrintType, e: ContainerSearch): JSX.Element => {
        return (
            <FilledDownloadIcon color="#00007d" onClick={() => {
                handelDownload(e?.id, { printType: type })
            }} />
        )
    }
    const unFilledIcon = (type: ShipmentPrintType, e: ContainerSearch): JSX.Element => {
        return (
            <DownloadIcon color="#00007d" onClick={() => {
                handelDownload(e?.id, { printType: type })
            }} />
        )
    }
    const iconResolver = (type: ShipmentPrintType, e: ContainerSearch): JSX.Element => {
        switch (type) {
            case 0:
                return !!e?.additionalData?.LabelPrinted ?
                    unFilledIcon(type, e) : filledIcon(type, e)
            case 1:
                return !!e?.additionalData?.CodPrinted ?
                    unFilledIcon(type, e) : filledIcon(type, e)
            case 2:
                return !!e?.additionalData?.InvoicePrinted ?
                    unFilledIcon(type, e) : filledIcon(type, e)
            case 3:
                return !!e?.additionalData?.PackingPrinted ?
                    unFilledIcon(type, e) : filledIcon(type, e)
            default:
                return <></>;
        }
    }
    const renderAttachment = (e: ContainerSearch, type: ShipmentPrintType) => {
        return (
            <td>
                {(e?.attachments?.filter((item) => typeResolver(item.type) == type).length > 0) ?
                    iconResolver(type, e)
                    : "-"}
            </td>
        )
    }

    const scopedSlots = {
        date: (e: ContainerSearch) => {
            return <td>
                {!!e.closedOn ? formatDate(e.closedOn) : "-"}
            </td>
        },
        records: (e: ContainerSearch) => {
            return <td>
                {e.totalShipments ?? "-"}
            </td>
        },
        actions: (e: ContainerSearch) => {
            return <td className="" style={{ display: "flex", flexDirection: "row" }}>
                <CLink to={`/containers/${e?.id}?isEdit=false`} className="card-header-action">
                    <CTooltip content={"View Container"}>
                        <FontAwesomeIcon
                            icon={faEye}
                        />
                    </CTooltip>
                </CLink>
                {e?.processing &&
                    <CIcon
                        content={freeSet.cilSync}
                        className={"ml-2 rotate-icon"}
                    />
                }
                <CIcon content={freeSet.cilCloudDownload} onClick={() => handleGetPrintedContainerReport(e?.id)}
                    style={{ cursor: "pointer" }}
                    className={"ml-2"}
                />
            </td>
        },
        openedOn: (e: ContainerSearch) => {
            return <td>{formatDate(e.openedOnFormatted)}</td>
        },
        account: (e: ContainerSearch) => {
            return <td>
                {e.shipments?.[0]?.accountName ?? "-"}
            </td>
        },
        label: (e: ContainerSearch) => {
            return renderAttachment(e, 0) // label
        },
        cod: (e: ContainerSearch) => {
            return renderAttachment(e, 1) // cod
        },
        invoice: (e: ContainerSearch) => {
            return renderAttachment(e, 2) // Invoice
        },
        packing: (e: ContainerSearch) => {
            return renderAttachment(e, 3) // Packing
        },
    };

    return (
        <>
            <CDataTable
                loading={isLoading}
                size="sm"
                hover
                scopedSlots={scopedSlots}
                items={data?.result || []}
                fields={
                    [
                        { key: "actions", label: "", _style: { width: "1%" } },
                        { key: "openedOn", label: "Date" },
                        { key: "account", label: "Account" },
                        { key: "records", label: "Records" },
                        { key: "label", label: "Label" },
                        { key: "cod", label: "COD" },
                        { key: "invoice", label: "Invoice" },
                        { key: "packing", label: "Packing" },
                        { key: "createdBy", label: "User" },
                    ]
                }

            />

            <PageCount
                maxFound={data?.totalCount ?? 0}
                onPageChange={(p) => {
                    setFilterGroup({ ...filterGroup, page: p })
                    fetchData({ ...filterGroup, page: p })
                }}
                onPageSizeChange={(ps) => {
                    setFilterGroup({ ...filterGroup, limit: ps })
                    fetchData({ ...filterGroup, limit: ps })
                }}
                currentPage={filterGroup?.page ?? 1}
                maxPage={Math.ceil(data?.totalCount! / filterGroup?.limit!)}
            />
        </>
    )
}

export default ContainersTable