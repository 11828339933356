import {
    CButton,
    CButtonGroup,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CFormText
} from "@coreui/react";
import React, {useEffect, useState} from "react";

interface Props {
    currentPage: number;
    pageSize?: number
    maxPage: number;
    onPageChange: (page: number) => void;
    onPageSizeChange: (size: number) => void;
    maxFound: number;
}

const PageCount = (props: Props) => {
    const pageSizes=[10,20,30,40,50,100,150,200,250,300,350,400,450,500];
    const [limit, setLimit] = useState<number>(20)

    const {currentPage, maxPage, onPageChange, maxFound, onPageSizeChange} = props;
    const maxPageDisplay = 10;
    let pageArr: number[] = [currentPage];

    for (
        let i = currentPage + 1, counter = 0;
        i < maxPage && counter < Math.floor(maxPageDisplay / 2);
        i++, counter++
    ) {
        pageArr.push(i);
    }

    for (
        let i = currentPage - 1, counter = 0;
        i >= 0 && counter < Math.floor(maxPageDisplay / 2);
        i--, counter++
    ) {
        pageArr.unshift(i);
    }

    useEffect(() => {
        let limit = Math.ceil(maxFound! / maxPage!)
        setLimit(limit)
    }, [maxPage, maxFound])




    return (
        <div>
            <CFormText>Total found: {maxFound ?? 0} </CFormText>
            <CButtonGroup>
                <CButton
                    className="small"
                    key={"first"}
                    variant="outline"
                    color="primary"
                    onClick={() => onPageChange(0)}
                >
                    First
                </CButton>
                {pageArr.map((p) => (
                    <CButton
                        className="small"
                        key={p}
                        active={p === currentPage}
                        variant="outline"
                        color="primary"
                        onClick={() => onPageChange(p)}
                    >
                        {p + 1}
                    </CButton>
                ))}

                <CButton
                    className="small"
                    key={"last"}
                    variant="outline"
                    color="primary"
                    onClick={() => onPageChange(maxPage - 1)}
                >
                    Last ({maxPage})
                </CButton>
                <CDropdown style={{marginLeft: "25px"}}>
                    <CDropdownToggle className={"small"} variant="outline" color="primary">Show {limit} Lines</CDropdownToggle>
                    <CDropdownMenu>
                        {pageSizes.map(ps =>
                            <CDropdownItem
                                key={ps}
                                onClick={() => {
                                    setLimit(ps)
                                    onPageSizeChange(ps)
                                }}>{ps}</CDropdownItem>)}
                    </CDropdownMenu>
                </CDropdown>
            </CButtonGroup>
        </div>
    );
};

export default PageCount;
