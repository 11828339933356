import { Action, AuthenticationAppModel } from "../../models/app";
import initialState from "../initialStates/appInitialState";
import {LOG_OUT, SET_IS_LOADING, SET_LOGGING_IN, SET_NOTIFICATION, SET_USER} from "../types";
import cookieManager from "../../services/cookieManager";

const appReducer = (
  state: AuthenticationAppModel = initialState,
  action: Action
): AuthenticationAppModel => {
  switch (action.type) {
    case LOG_OUT:
      cookieManager.deleteAllCookies()
      return {
        ...state,
        loggingIn: false,
        user: null,
        isAuthenticated: false,
      };
      case SET_LOGGING_IN:
        return {
          ...state,
          loggingIn: action.payload!.loggingIn
        }
    case SET_USER:
      return {
        ...state,
        isAuthenticated: !!action.payload!.user,
        user: {
          ...action.payload!.user,
        },
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload!.isLoading
      }
    case SET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload!.notification
      }
    default:
      return state;
  }
};

export default appReducer;
