import {CContainer, CProgress, CProgressBar,} from "@coreui/react";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Redirect, Route, Switch, useHistory} from "react-router";
import {AppModel} from "../../models/app";
import routes from "../../routes";
import {routeDataToRoute} from "../../utils/routeUtils";
import Sidebar from "./Sidebar";
import Alert from '../SharedComponents/Alert';
import {HideNotification} from "../../store/actions/auth";
import {freeSet} from "@coreui/icons";
import CIcon from "@coreui/icons-react";

interface Props {
}

const Main = (props: Props) => {
    const {isAuthenticated, isLoading} = useSelector(
        (state: AppModel) => state.app
    );


    const hist = useHistory()
    return (
        <>
        {isAuthenticated && <div className="c-app c-default-layout">
                <>
                    {/*{isLoading && <Loading/>}*/}
                    {isLoading &&
                        <div style={{zIndex: 9999, top: 0, left: 0, right: 0, textAlign: "center", position: "fixed"}}>
                            <div className={"bg-secondary"}>
                                <div className="progress-bar">
                                    <div className="progress-bar-value"></div>
                                </div>
                            </div>
                        </div>}
                    <Sidebar/>
                    <div className="c-wrapper">
                        {/* <Header /> */}
                        <CContainer className="my-3 position-relative mx-3 container-fluid">

                            <Switch>{routes.map((r) => routeDataToRoute(r))}</Switch> </CContainer>
                    </div>
                </>

        </div>}
        </>

    );
};

export default Main;
