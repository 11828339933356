import { ShipmentSearch } from "../../models/shipment";
import { Filter, FilterGroup } from "../../models/filter";
import {
    SET_CONTAINER_FILTER_GROUP,
    SET_QUOTATION_FILTER_GROUP,


    SET_QUOTATION_IS_LOADING, SET_QUOTATION_RESULT,
} from "../types";
import {QuotationSearch} from "../../models/quotation";

export const SetQuotationPage = (page: number) => {
    return {
        type: SET_QUOTATION_FILTER_GROUP,
        payload: {
            page: page,
        },
    };
};

export const SetQuotationsFilterGroupFilters = (filters: Filter[]) => {
    return {
        type: SET_QUOTATION_FILTER_GROUP,
        payload: {
            page: 0,
            filters: filters,
        },
    };
};

export const SetQuotationIsLoading = (loading: boolean) => {
    return {
        type: SET_QUOTATION_IS_LOADING,
        payload: {
            isLoading: loading,
        },
    };
};



export const SetQuotationsFilterGroup = (filterGroup: FilterGroup) => {
    return {
        type: SET_QUOTATION_FILTER_GROUP,
        payload: filterGroup,
    };
};

export const SetQuotationResult = (
    page: number,
    count: number,
    result: QuotationSearch[],
    error?: string
) => {
    return {
        type: SET_QUOTATION_RESULT,
        payload: {
            count: count,
            result: result,
            error: error,
        },
    };
};

export const setQuotationSearchLimit = (limit: number) => {
  return {
      type: SET_QUOTATION_RESULT,
      payload: {
          limit: limit
      }
  }
}