import {
    CButton,
    CCard,
    CCardBody,
    CCol,
    CContainer,
    CInputCheckbox,
    CInputGroup,
    CLabel,
    CNav,
    CNavLink,
    CRow,
    CSpinner,
    CTabContent,
    CTabPane,
    CTabs,
    CTooltip
} from "@coreui/react";
import React, {useEffect, useState} from "react";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import GatewayClient from "../../../clients/gatewayClient";
import {AgentModel} from "../../../models/agents";
import {useHistory, useParams} from "react-router";
import SWInput from "../../SharedComponents/SWInput";
import KeyvalueEditor from "../../SharedComponents/keyvalueEditor";
import LookupsClient from "../../../clients/lookupsClient";
import SWSelect from "../../SharedComponents/SWSelect";

const gatewayClient = new GatewayClient();
const lookups = new LookupsClient()
const ViewAgent = () => {
    const {id} = useParams() as any;
    const hist = useHistory()
    const [traceMapsLookup, setTraceMapsLookup] = useState<{ label: string, value: string }[]>([]);
    const [agent, setAgent] = useState<AgentModel>();

    const gatewayClient = new GatewayClient();
    const searchTraceMaps = async () => {
        let maps = await gatewayClient.SearchTraceMaps();
        if (maps) {
            setTraceMapsLookup(maps.map(i => ({label: i.name!, value: i.id!})));
        }
    }
    useEffect(() => {
        searchTraceMaps();
    }, [])
    const getAgent = async () => {
        let agents = await gatewayClient.SearchAgents(id);
        setAgent(agents[0]);
    }


    useEffect(() => {
        getAgent();
    }, [])

    const onSettingsChange = async (newSettings: any) => {
        await gatewayClient.UpdateAgent(agent!.id!, {...agent, settings: newSettings})
        getAgent();
    }

    return (
        <>
            <CContainer className=" p-4">
                {!agent ? <CSpinner
                        className="mx-auto d-block my-5"
                        color="primary"
                        style={{width: "5em", height: "5em"}}
                    />
                    :
                    <CCard>
                        <CCardBody>
                            <ul className="nav ">
                                <li className="nav-item">
                                    <CTooltip content="Back">
                                        <CButton>
                                            <CIcon
                                                content={freeSet.cilArrowLeft}
                                                onClick={() => hist.goBack()}
                                            />
                                        </CButton>
                                    </CTooltip>
                                </li>

                                <li className="nav-item mt-2">
                                    <CLabel>{agent?.id}</CLabel>

                                </li>

                            </ul>

                            <fieldset className="b-fieldset p-2">
                                <legend className="b-legend">
                                    <h5>Agent settings</h5>
                                </legend>
                                <CRow>

                                    <CCol className="col-sm-3">
                                        <SWInput
                                            className={"col-sm-12"}
                                            label="ID"
                                            name="id"

                                            value={agent.id}
                                        />
                                        <SWInput
                                            className={"col-sm-12"}
                                            label="Adapter Prefix"
                                            name="adapterPrefix"
                                            value={agent.adapterPrefix}
                                        />
                                    </CCol>
                                    <CCol className="col-sm-3 ">
                                        <CLabel>Capabilities</CLabel>
                                        <CInputGroup>
                                            <CInputCheckbox
                                                style={{cursor: "pointer"}}
                                                checked={agent?.capabilities?.find(c => c.id == "track") && !agent?.capabilities?.find(c => c.id == "track")?.disabled}
                                                disabled={true}
                                            />
                                            <CLabel className="text-primary">Track shipments</CLabel>
                                        </CInputGroup>
                                        <CInputGroup>
                                            <CInputCheckbox
                                                style={{cursor: "pointer"}}
                                                checked={agent?.capabilities?.find(c => c.id == "create") && !agent?.capabilities?.find(c => c.id == "track")?.disabled}
                                                disabled={true}
                                            />
                                            <CLabel className="text-primary">Create shipments</CLabel>
                                        </CInputGroup>
                                        <CInputGroup>
                                            <CInputCheckbox
                                                style={{cursor: "pointer"}}
                                                checked={agent?.capabilities?.find(c => c.id == "delete") && !agent?.capabilities?.find(c => c.id == "track")?.disabled}
                                                disabled={true}
                                            />
                                            <CLabel className="text-primary">Delete shipments</CLabel>
                                        </CInputGroup>

                                    </CCol>
                                    <CCol className="col-sm-3">
                                        <SWSelect
                                            className={"col-sm-12"}
                                            values={traceMapsLookup}
                                            label="Trace Map"
                                            value={agent.traceMap?.id}
                                            onChange={(e) => setAgent({
                                                ...agent,
                                                traceMap: {...agent?.traceMap, id: e}
                                            })}
                                        />
                                    </CCol>

                                </CRow>


                            </fieldset>

                            <CTabs>

                                <CContainer>
                                    <>

                                        <br/>
                                        <CNav variant="tabs">
                                            <CNavLink data-tab="settings" default>Settings</CNavLink>
                                        </CNav>
                                        <CTabContent>
                                            <CTabPane active data-tab="settings">
                                                <div className={"py-3"}>
                                                    <KeyvalueEditor data={agent.settings}
                                                                    key={JSON.stringify(agent.settings) ?? 'settings'}
                                                                    keyLabel={"Key"}
                                                                    valueLabel={"Value"} hideValue={true}
                                                                    onChange={onSettingsChange}/>
                                                </div>
                                            </CTabPane>

                                        </CTabContent>
                                    </>
                                </CContainer>
                            </CTabs>

                        </CCardBody>
                    </CCard>
                }


            </CContainer>

        </>
    )
}

export default ViewAgent;
