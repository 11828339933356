
import React, {useEffect, useState} from "react";
import { CButton, CButtonGroup, CCol, CInputCheckbox, CLabel, CModal, CModalBody, CModalFooter, CModalHeader, CRow } from "@coreui/react";
import SWSelect from "../../../SharedComponents/SWSelect";
import GatewayClient from "../../../../clients/gatewayClient";


interface Props {
    onClose: () => void
    onSubmit: (creationType: "booking" | "fullShipment", labelSize?: string) => void
    submitting: boolean
    agent:string;
    onSaveHandler?:()=>void
}

export type labelSize={
    label:string,
    value:string
}
const ConfirmShipmentModal = (props: Props) => {
    const { onSubmit, onClose, submitting,agent } = props
    const [createAsDraft, setCreateAsDraft] = useState<boolean>(false)
    let [labelSize, setLabelSize] = useState<string | undefined>("A6");
    const [labelSizeValidation, setLabelSizeValidation] = useState<boolean>(false);
    const [agentAvailableSizes, setAgentAvailableSizes] = useState<labelSize[] | undefined>([]);
const gatewayClient=new GatewayClient();

    useEffect(() => {
        getLabelSizesForAgent(agent)
    }, []);
const getLabelSizesForAgent= async (id:string)=>{
    let rs = await gatewayClient.SearchAgents(id)
    if (rs.length>0 && rs[0].id!=null) {
        let arr:any=[]
        rs[0].availableLabelSizes?.forEach(x=> arr.push({label:x,value:x}))
        setAgentAvailableSizes(arr)
    }


}



    return (
        <div className=" text-primary">
            <CModal
                show={true}
                centered
                onClose={() => {
                    setCreateAsDraft(false)
                    onClose()
                }}
            >
                <CModalHeader closeButton>
                    <h5>Create Shipment</h5>
                </CModalHeader>
                <CModalBody>
                    <CRow className={"ml-2"}>
                        <CCol md={4} className={"ml-2 mt-4"}>
                            <CInputCheckbox
                                name={"createAsDraft"}
                                checked={createAsDraft}
                                onChange={(e) => {
                                    setCreateAsDraft(!createAsDraft)
                                    setLabelSize(undefined)
                                    setLabelSizeValidation(false)
                                }}
                            />
                            <CLabel className={"mt-1"}>Create as draft</CLabel>
                        </CCol>
                        <CCol md={4}>
                            {!createAsDraft &&
                                <SWSelect
                                    values={agentAvailableSizes || []}
                                    value={labelSize ?? ""} name={"size"}
                                    isClearable={true}
                                    onChange={e => {
                                        setLabelSize(e)
                                    }}
                                    label={"Label size"}
                                    validationErrors={labelSizeValidation ? 'Required' : ''}
                                />}
                        </CCol>
                    </CRow>
                </CModalBody>

                <CModalFooter>
                    <CRow className="justify-content-between w-100">
                        <CCol className="text-right">
                            <CButtonGroup className="mb-0 mr-2">
                                <CButton
                                    variant="outline"
                                    color="primary"
                                    onClick={() => {
                                        if (createAsDraft) {
                                            onSubmit("booking")
                                        } else {
                                            if (labelSize) {
                                                setLabelSizeValidation(false)
                                                onSubmit("fullShipment", labelSize)
                                            } else {
                                                setLabelSizeValidation(true)
                                            }
                                        }
                                    }}
                                    style={{ fontSize: "10px" }}
                                >
                                    {submitting ? "Loading..." : "Create"}
                                </CButton>
                                { props.onSaveHandler && <CButton
                                    variant="outline"
                                    color="primary"
                                    onClick={props.onSaveHandler ? props.onSaveHandler : ()=>{}}
                                    style={{ fontSize: "10px" }}
                                >
                                    Save
                                </CButton>}
                            </CButtonGroup>
                        </CCol>
                    </CRow>
                </CModalFooter>
            </CModal>
        </div>

    )
}

export default ConfirmShipmentModal
