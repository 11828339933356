import {PathLogsType} from "./viewLogs";
import {CLabel, CRow} from "@coreui/react";

type Props = {
    logsPath: PathLogsType[]
    removePath: (name: string) => void
}
const LogsPath = (props: Props) => {
    const removePath = (name: string) => {
        props.removePath(name)
    }

    return (
        <>
            <CRow>
                {props.logsPath.map((log, index) => {
                    return <CLabel
                        className={' ml-1 font-lg'}> <CLabel
                        style={{color: props.logsPath.length - 1 === index ? '#0d86ff' : '#000000'}}
                        className={`font-weight-bold ${props.logsPath.length - 1 === index ? "red" : "logs-label-path"}`}
                        onClick={() => removePath(log.name)}>{log.title}</CLabel>
                        {props.logsPath.length - 1 === index ? <></> : <CLabel className={'ml-1'}>/ </CLabel> }
                    </CLabel>
                })}
            </CRow>
        </>
    )
}
export default LogsPath
