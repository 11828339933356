import React from "react";
import {
    CModalHeader, CModal, CModalBody, CButton, CModalFooter, CContainer,
    CRow, CCol, CCard, CCardHeader, CCardBody
} from "@coreui/react";
import {useDispatch, useSelector} from "react-redux";
import {AppModel} from "../../../../models/app";
import {ToggleShipmentView} from "../../../../store/actions/shipments";
import {formatDate, formatDateTime} from "../../../../utils/dateUtil";

interface HtmlLineItem {
    label?: string;
    value: string | number;
}

const ResultDetails = () => {
    const {view, toggleView} = useSelector((state: AppModel) => state.shipment.shipmentsSearch);
    const dispatch = useDispatch();
    
    const element = (val: HtmlLineItem, index: number, withBreak: boolean) =>
        val.label ? (
            <React.Fragment key={index}>
                <strong>{val.label}:</strong>
                {val.value} {withBreak ? <br/> : ""}
            </React.Fragment>
        ) : (
            <React.Fragment key={index}>
                {val.value}
                {withBreak ? <br/> : ""}
            </React.Fragment>
        );


    const elementsIntTd = (vals: HtmlLineItem[]) => {
        const items = vals.slice();
        const [last] = items.splice(-1);
        const withBreaks = items.map((el, i) => element(el, i, true));
        withBreaks.push(element(last, vals.length - 1, false));
        return <>{withBreaks}</>;
    };

    const references = () => {

        if (!view) return "";
        let refs: HtmlLineItem[] = [];
        if (view.number) refs.push({label: "AWB", value: view.number});
        if (view.uid) refs.push({label: "UID", value: view.uid});
        if (view.account) refs.push({label: "Account", value: view.account});
        if (view.accountReference)
            refs.push({label: "Account Ref", value: view.accountReference});
        if (view.shipperReference)
            refs.push({label: "Shipper Ref", value: view.shipperReference});
        if (view.consigneeReference)
            refs.push({label: "Consignee Ref", value: view.consigneeReference});

        if (refs.length == 0) return "";

        return elementsIntTd(refs);
    }

    const shipper = () => {

        if (!view) return "";

        let addr: HtmlLineItem[] = [];

        if (view.shipperCompanyName || view.shipperName) {
            if (
                view.shipperName &&
                view.shipperCompanyName &&
                view.shipperCompanyName !== view.shipperName
            )
                addr.push({
                    value: `${view.shipperCompanyName}, ${view.shipperName}`,
                });
            else if (
                view.shipperName &&
                view.shipperCompanyName &&
                view.shipperCompanyName === view.shipperName
            )
                addr.push({value: view.shipperName});
            else if (view.shipperName) addr.push({value: view.shipperName});
            else addr.push({value: view.shipperCompanyName});
        }

        if (view.shipperAddressLine1)
            addr.push({value: view.shipperAddressLine1});
        if (view.shipperAddressLine2)
            addr.push({value: view.shipperAddressLine2});
        if (view.shipperAddressLine3)
            addr.push({value: view.shipperAddressLine3});

        if (view.shipperCity || view.shipperPostCode) {
            if (view.shipperPostCode && view.shipperCity)
                addr.push({value: `${view.shipperPostCode}, ${view.shipperCity}`});
            else if (view.shipperPostCode)
                addr.push({value: view.shipperPostCode});
            else addr.push({value: view.shipperCity});
        }

        if (view.shipperCountry) addr.push({value: view.shipperCountry});
        if (view.shipperEmail) addr.push({value: view.shipperEmail});
        if (view.shipperPhone) addr.push({value: view.shipperPhone});

        if (addr.length == 0) return "";

        return elementsIntTd(addr);
    }
    
    const consignee = () => {

        if (!view) return "";
        let addr: HtmlLineItem[] = [];

        if (view.consigneeReference || view.consigneeName) {
            if (
                view.consigneeName &&
                view.consigneeCompanyName &&
                view.consigneeCompanyName !== view.consigneeName
            )
                addr.push({
                    value: `${view.consigneeCompanyName}, ${view.consigneeName}`,
                });
            else if (
                view.consigneeName &&
                view.consigneeCompanyName &&
                view.consigneeCompanyName === view.consigneeName
            )
                addr.push({value: view.consigneeName});
            else if (view.consigneeName) addr.push({value: view.consigneeName});
            else addr.push({value: view.consigneeCompanyName});
        }

        if (view.consigneeAddressLine1)
            addr.push({value: view.consigneeAddressLine1});
        if (view.consigneeAddressLine2)
            addr.push({value: view.consigneeAddressLine2});
        if (view.consigneeAddressLine3)
            addr.push({value: view.consigneeAddressLine3});

        if (view.consigneeCity || view.consigneePostCode) {
            if (view.consigneePostCode && view.consigneeCity)
                addr.push({
                    value: `${view.consigneePostCode}, ${view.consigneeCity}`,
                });
            else if (view.consigneePostCode)
                addr.push({value: view.consigneePostCode});
            else addr.push({value: view.consigneeCity});
        }

        if (view.consigneeCountry) addr.push({value: view.consigneeCountry});
        if (view.consigneeEmail) addr.push({value: view.consigneeEmail});
        if (view.consigneePhone) addr.push({value: view.consigneePhone});

        if (addr.length == 0) return "";

        return elementsIntTd(addr);
    }

    const details = () => {

        if (!view) return "";
        let refs: HtmlLineItem[] = [];
        refs.push({label: "Created ", value: formatDateTime(view.createdOn)});
        if (view.totalPieces) refs.push({label: "Picked up", value: formatDate(view.pickupDate)});
        
        if (view.totalPieces) refs.push({label: "Total Pieces", value: view.totalPieces});
        
        if (view.totalItems) refs.push({label: "Total Items", value: view.totalItems});

        if (view.weight) refs.push({label: "Weight", value: `${view.weight} ${view.weightUnit}`  });
        if (view.chargeWeight) refs.push({label: "Charge Weight", value: `${view.chargeWeight} ${view.weightUnit}`  });
        if (view.value) refs.push({label: "Value", value: `${view.value} ${view.valueCurrency}`  });
        if (view.cOD) refs.push({label: "COD", value: `${view.cOD} ${view.cODCurrency}`  });
        
        return elementsIntTd(refs);
    }
    return (
        <CModal
            show={toggleView}
            onClose={() => dispatch(ToggleShipmentView(view))}
            size="xl"
        >
            <CModalHeader className="bg-white text-black" closeButton>Shipment Details</CModalHeader>
            <CModalBody className="bg-white text-black">
                <CContainer fluid>
                    <CRow>
                        <CCol sm="3">
                            <CCard className='h-100'>
                                <CCardHeader>
                                    References
                                </CCardHeader>
                                <CCardBody>
                                    {references()}
                                </CCardBody>

                            </CCard>
                        </CCol>
                        <CCol sm="3">
                            <CCard className='h-100'>
                                <CCardHeader>
                                    Shipper
                                </CCardHeader>
                                <CCardBody>
                                    {shipper()}
                                </CCardBody>
                            </CCard>
                        </CCol>
                        <CCol sm="3">
                            <CCard className='h-100'>
                                <CCardHeader>
                                    Consignee
                                </CCardHeader>
                                <CCardBody>
                                    {consignee()}
                                </CCardBody>
                                
                                
                            </CCard>
                        </CCol>
                        <CCol sm="3">
                            <CCard className='h-100'>
                                <CCardHeader>
                                    Details
                                </CCardHeader>
                                <CCardBody>
                                    {details()}
                                </CCardBody>


                            </CCard>
                        </CCol>

                    </CRow>
                </CContainer>
                
            </CModalBody>
            <CModalFooter>
                <CButton
                    color="secondary"
                    onClick={() => dispatch(ToggleShipmentView(view))}
                >Close</CButton>
            </CModalFooter>
        </CModal>
    )

}

export default ResultDetails;