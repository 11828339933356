import {CButton, CInputGroup} from "@coreui/react";
import React, {useEffect, useState} from "react";
import {formatDate} from "../../../../utils/dateUtil";
import {
    InventoryDetailsReportItem, InventoryDetailsReportQueryParameters,
    InventoryTransactionDetailDto,
    SkuSearch,
    SkuStatus, WeightUnit
} from "../../../../models/warehouse";
import SWInputNumber from "../../../SharedComponents/SWInputNumber";
import WarehousesClient from "../../../../clients/warehousesClient";

interface IProps {
    width: number
    selected: FilterValues
    updateStock: InventoryTransactionDetailDto[]
    setUpdateStock: (details: InventoryTransactionDetailDto[]) => void
    account: string
    warehouse: string
    skus: SkuSearch[]
    skuStatus: { [k: string]: string }
    filterStockLocations: (locationLookups: {[k: string]: string}) => void
}

interface QuantityHelper extends InventoryDetailsReportItem {
    quantityChange?: number
    newStatus?: number
    toLocation?: string
}

interface FilterValues {
    sku?: string
    status?: SkuStatus
    location?: string
    batchNumber?: string
    itemNumber?: string
    expiryDate?: string
}

const StockQuantityHelper = (props: IProps) => {
    const warehouseClient = new WarehousesClient()
    const [selectedSkuQuantities, setSelectedSkuQuantities] = useState<QuantityHelper[]>([])
    const [selectedSku, setSelectedSku] = useState<SkuSearch>()

    useEffect(() => {
        handleSkuChanged(props.selected.sku).then((d) => {
            handleUpdateQuantity(d)
            filterAllowedLocations(d)
        })
    }, [props.selected.sku])

    const handleUpdateQuantity = (stockDetails: QuantityHelper[]) => {
        let newDetails: QuantityHelper[] = [...stockDetails]
        props.updateStock.forEach(us => 
        {
            if (us.lot.sku === props.selected.sku) {
                let itemFound = newDetails.find(c => c.location == us.location &&
                    c.batchNumber == us.lot.batchNumber && c.itemNumber == us.lot.itemNumber &&
                    dateAreEqual(c.expiryDate, us.lot.expiryDate) && c.status == us.lot.status);

                if (itemFound) {
                    const index = newDetails.indexOf(itemFound)
                    let newDetail = {...newDetails[index]}
                    newDetail.quantityChange = us.quantity
                    newDetails[index] = newDetail
                }
            }
        })
        setSelectedSkuQuantities(newDetails)
    }
    
    const filterAllowedLocations = (stockDetails: QuantityHelper[]) => {
        const locations = stockDetails?.map(s => s.location)
        const locationLookups: {[k: string]: string} = {}
        locations.forEach(l => locationLookups[l] = l)
        props.filterStockLocations(locationLookups)
    }

    const handleSkuChanged = async (s?: string) => {
        const sku = props.skus.find(s => s.code == props.selected.sku)
        setSelectedSku(sku)

        let body: InventoryDetailsReportQueryParameters = {
            code: s ?? "",
            account: props.account,
            warehouse: props.warehouse,
            excludeZero: true
        }

        let data = await warehouseClient.inventoryDetailsReport(body)

        setSelectedSkuQuantities(data);
        return data
    }

    const dateAreEqual = (left: string | null | undefined, right: string | null | undefined) => {
        if (!left && !right)
            return true;
        if (!left && right)
            return false;
        if (left && !right)
            return false;
        return formatDate(left) == formatDate(right);
    }

    const quantityHelperQuantityChanged = (stock: QuantityHelper[], quantityHelperItem: QuantityHelper, quantity?: number) => {
        if (quantity && quantityHelperItem.quantityAvailable + quantityHelperItem.quantity < (quantity))
            return;

        setSelectedSkuQuantities(stock.map((sq, i) => i === stock.indexOf(quantityHelperItem) ? {
            ...sq,
            quantityChange: quantity
        } : sq))
    }

    const addFromHelper = (index: number) => {
        const item = selectedSkuQuantities[index];

        const itemFound = props.updateStock.find(c => c.lot.sku == selectedSku?.code && c.location == item.location &&
            c.lot.batchNumber == item.batchNumber && c.lot.itemNumber == item.itemNumber &&
            dateAreEqual(c.lot.expiryDate, item.expiryDate) && c.lot.status == item.status);

        if (itemFound) {
            const index = props.updateStock.indexOf(itemFound);

            props.setUpdateStock([
                ...props.updateStock.slice(0, index),
                {
                    ...props.updateStock[index],
                    quantity: item.quantityChange,
                },
                ...props.updateStock.slice(index + 1)
            ])
        } else {
            let newStock: InventoryTransactionDetailDto = {
                area: item.area,
                store: item.store,
                lot: {
                    account: props.account,
                    batchNumber: item.batchNumber,
                    itemNumber: item.itemNumber,
                    sku: selectedSku?.code ?? "",
                    skuName: selectedSku?.name ?? "",
                    skuWeight: selectedSku?.weight.value ?? undefined,
                    skuWeightUnit: selectedSku?.weight.unit ? WeightUnit[selectedSku?.weight.unit] : undefined,
                    expiryDate: item.expiryDate,
                    status: item.status
                },
                warehouse: item.warehouse,
                location: item.location ?? "",
                quantity: item.quantityChange,
            }
            props.setUpdateStock([...props.updateStock, newStock])

        }
    }


    return <>
        {selectedSkuQuantities && selectedSkuQuantities.length > 0 &&
            <div className="position-relative table-responsive">
                <table className="table table-borderless table-sm">
                    <thead>
                    <tr>
                        {props.width > 500 &&
                            <th className="">
                                <div className="d-inline">Quantity</div>
                            </th>
                        }
                        <th className="">
                            <div className="d-inline">Available</div>
                        </th>
                        <th className="">
                            <div className="d-inline">Location</div>
                        </th>
                        <th className="">
                            <div className="d-inline">Sku Code</div>
                        </th>
                        <th className="">
                            <div className="d-inline">Sku Name</div>
                        </th>
                        <th className="">
                            <div className="d-inline">Status</div>
                        </th>
                        <th className="">
                            <div className="d-inline">Batch Number</div>
                        </th>
                        <th className="">
                            <div className="d-inline">Batch Barcode</div>
                        </th>
                        <th className="">
                            <div className="d-inline">Expiry Date</div>
                        </th>
                        <th className="">
                            <div className="d-inline">Current</div>
                        </th>
                        <th className="">
                            <div className="d-inline"/>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {selectedSkuQuantities.filter(s => {
                        let match = true;

                        if ((props.selected.status || props.selected.status === 0) && props.selected.status != s.status)
                            match = false;
                        if (props.selected.location && props.selected.location != s.location)
                            match = false;
                        if (props.selected.batchNumber && props.selected.batchNumber != s.batchNumber)
                            match = false;
                        if (props.selected.itemNumber && props.selected.itemNumber != s.itemNumber)
                            match = false;
                        if (props.selected.expiryDate && !dateAreEqual(props.selected.expiryDate, s.expiryDate))
                            match = false;

                        return match;
                    }).map((sq, index) => <tr key={index}>
                        {props.width > 500 &&
                            <td style={{minWidth: "40px"}}>
                                <CInputGroup>
                                    <SWInputNumber style={{width: "90px"}} name={'quantity'}
                                                   value={sq.quantityChange}
                                                   onChange={val => quantityHelperQuantityChanged(selectedSkuQuantities ,sq, val)}/>
                                    <CButton size={"sm"} style={{height: "28px"}}
                                             disabled={!sq.quantityChange}
                                             onClick={() => addFromHelper(index)}
                                             variant="outline" color="primary"
                                    >
                                        Update
                                    </CButton>

                                </CInputGroup>
                            </td>
                        }

                        <td>{sq.quantityAvailable}</td>
                        <td>{sq.location}</td>
                        <td>{selectedSku?.code}</td>
                        <td>{selectedSku?.name}</td>
                        <td>{props.skuStatus[sq.status]}</td>
                        <td>{sq.batchNumber}</td>
                        <td>{sq.itemNumber}</td>
                        <td>{formatDate(sq.expiryDate)}</td>

                        <td>{sq.quantity}</td>
                    </tr>)}
                    </tbody>
                </table>
            </div>}
    </>
}

export default StockQuantityHelper