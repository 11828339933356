import {CContainer} from "@coreui/react";
import React from "react";
import {InvoiceDto} from "../../../../models/accounting";
import {formatDate} from "../../../../utils/dateUtil";

interface IProps {
    invoice?: InvoiceDto
}
const InvoiceStepper = (props: IProps) => {
    const invoiceNumber = props.invoice?.alternativeInvoiceNumber ? `${props.invoice.invoiceNumber} - ${props.invoice.alternativeInvoiceNumber}` : props.invoice?.invoiceNumber
    const renderStep = (
        number: string,
        title: string,
        subTitle?:string,
        msg?: string,
        active?: boolean,
        final?: boolean
    ) => (
        <>
            <div className={`step'}`} data-target="#test-l-1">
                <CContainer className="p-1 d-flex flex-row align-items-center">
                    <span className={`bs-stepper-circle  ${active ? "bg-primary " : "bg-secondary"}`}>{number} </span>
                    <div>
                        <div>
                            <span className={"font-weight-bold text-primary"}>{subTitle}</span>
                            <span className={`bs-stepper-label m-0 ml-1 ${active ? "text-primary " : "text-secondary"}`}>{title} </span>
                        </div>
                        <div>
                            <span className={"small"}>{msg}</span>
                        </div>
                    </div>

                </CContainer>
            </div>
            {!final && (
                <div
                    className={`line  ${active ? "bg-primary " : "bg-secondary"}`}
                ></div>
            )}
        </>
    );

    return (
        <>
            <div id="stepper1" className="bs-stepper" style={{ fontSize: ".8rem" }}>
                <div className="bs-stepper-header">
                    {renderStep("1", "Draft", "", formatDate(props.invoice?.invoiceDate), Boolean(props.invoice?.id))}
                    {renderStep("2", "Issued", "", props.invoice?.invoiceNumber ? `Number: ${invoiceNumber}` : "", Boolean(props.invoice?.invoiceNumber))}
                    {renderStep("3", "Sent","",formatDate(props.invoice?.sentOn), Boolean(props.invoice?.sentOn))}
                    {renderStep( "4","Paid","", formatDate(props.invoice?.paidOn),Boolean(props.invoice?.paidOn),true)}
                </div>
            </div>
        </>
    );
};

export default InvoiceStepper;