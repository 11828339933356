import {CCardBody, CCol, CRow} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import React, {useState} from "react";
import {ShipmentSearch} from "../../../models/shipment";

interface IProps {
    shipment: ShipmentSearch
    onEditClick: () => any;
}

const ReferenceCard = (props: IProps) => {
    const {shipment} = props
    const [isHover, setisHover] = useState<boolean>(false)

    return (
        <>
            <CCardBody
                className={`shipperCard mb-1 rounded   ${
                    isHover ? "bg-secondary" : ""
                }`}
                onMouseOver={() => {
                    setisHover(true);
                }}
                onMouseLeave={() => {
                    setisHover(false);
                }}
            >
                <CRow>
                    <CCol>
                        Shipment Number:
                    </CCol>
                    <CCol className={"text-primary"} sm={7}><strong>{shipment.number}</strong></CCol>
                    <CCol sm={1} className="text-right">
                        <CIcon
                            style={{cursor: "pointer"}}
                            className={`${isHover ? "primary" : "text-white"}`}
                            content={freeSet.cilPencil}
                            size={"lg"}
                            onClick={props.onEditClick}
                        />
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>
                        Reference:
                    </CCol>
                    <CCol sm={8}><strong> {shipment.accountReference}</strong></CCol>
                </CRow>
            </CCardBody>
        </>
    )
}

export default ReferenceCard