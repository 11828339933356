import { CContainer } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import ShipClient from "../../../clients/shipClient";
import { useSearchOnFilterGroup } from "../../../hooks/helperHooks";
import { AppModel } from "../../../models/app";
import {
  SetContainerResult,
  SetContainersFilterGroup,
  SetContainersIsLoading,
} from "../../../store/actions/containers";
import { queryParamsToFilters } from "../../../utils/filterUtils";
import SearchForm from "./components/SearchForm";
import SearchResult from "./components/SearchResult";

interface Props {}
const SearchContainers = () => {
  const shipClient = new ShipClient();
  const loc = useLocation();

  const dispatch = useDispatch();

  useSearchOnFilterGroup({
      updateFilterGroup: (fg) => dispatch(SetContainersFilterGroup(fg)),
      useFilterGroupSelector: () =>
          useSelector(
              (state: AppModel) => state.container.containersSearch.filterGroup!
          ),
      searchFunction: shipClient.GetContainers.bind(shipClient) as any,
      setIsLoading: (loading) => dispatch(SetContainersIsLoading(loading)),
      setResult: (result) =>
          dispatch(SetContainerResult(result.page!, result.count!, result.result!)),
  });

  return (
    <CContainer className="bg-white p-4">
      <SearchForm />
      <SearchResult />
    </CContainer>
  );
};

export default SearchContainers;
