import {
  CButton,
  CButtonGroup,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalHeader,
  CNav,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CTabs,
} from "@coreui/react";
import React, {useEffect, useState} from "react";
import {ShipmentSearch, StandardShipmentItem,} from "../../../../models/shipment";
import SWSelect from "../../../SharedComponents/SWSelect";
import UpdateItems from "./UpdateItems";
import WarehouseClient from "../../../../clients/warehousesClient";
import {SkuSearch} from "../../../../models/warehouse";

interface IProps {
  shipment: ShipmentSearch;
  index?: number;
  isEdit?: boolean;
  isShowing: boolean;
  // onSave: () => any;
  countries: { [key: string]: string };
  onClose: () => any;

  onChange: (shipment: ShipmentSearch) => any;
}

const AddUpdateItems = (props: IProps) => {
  const { shipment, index, isEdit, onChange, onClose, countries } = props;

  const initialItemValue =
    index != undefined ? { ...shipment.items[index] } : {};

  const [item, setItem] = useState<StandardShipmentItem>(initialItemValue);
  const [skuLookup, setSkuLookup] = useState<{ [k: string]: string }>({})
  const [warehousesLookUp, setWarehousesLookUp] = useState<any>({})

  useEffect(() => {
    setItem(initialItemValue);
    loadLookups()
  }, []);
  const fromStock:any = index !=undefined ? skuLookup[({ ...shipment.items[index] }.productCode as any)] : false
  const loadLookups = async () => {
    const client = new WarehouseClient();

    const warehouses = await client.searchWarehouse()

    const skuResult = await client.SearchSku({limit:30000, accountNumber: shipment.account})
    const skus : SkuSearch[] = skuResult.data.matches
    const skuLookup : {[k: string] : string} = {}
    skus.forEach((s) => {
      let warehouse = '';
      if (s.allowedWarehouses?.length > 0) {
        let selectedWarehouse = warehouses.find(w => w.id == s.allowedWarehouses[0])
        warehousesLookUp[selectedWarehouse?.code as string] = "" + selectedWarehouse?.name
        setWarehousesLookUp({...warehousesLookUp})
        warehouse = selectedWarehouse?.code ?? ""
      } else if (s.warehouse)
        warehouse = s.warehouse

      if(warehouse){
        skuLookup[s.code] = `${warehouse ? warehouse + " - " : ""}${s.code} - ${s.name}`
      }
    })
    setSkuLookup(skuLookup)
  }

  const handleChange = (e: any) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setItem({
      ...item,
      [name]: value,
    });
  };

  const handleChangeNumber = (e: any) => {
    const target = e.target;
    const value = Number(target.value);
    const name = target.name;

    setItem({
      ...item,
      [name]: value,
    });
  };

  const handleSave = () => {
    if (index == undefined) {
      const newItems = [...shipment.items, item];

      const updatedShipment = { ...shipment, items: newItems };
      onChange(updatedShipment);
    } else {
      const newItems = [];
      for (let i = 0; i < shipment.items.length; i++) {
        if (i == index) {
          newItems.push(item);
        } else newItems.push({ ...shipment.items[i] });
      }
      const updatedShipment = { ...shipment, items: newItems };
      onChange(updatedShipment);
    }
    onClose();
  };

  const handleSelectChange = (name: string, value: string | undefined) => {
    setItem({
      ...item,
      [name]: value,
    });

    
  };

  return (
    <>
      <CModal
        className="ml-auto mr-auto"
        size={"lg"}
        show={props.isShowing}
        centered={true}
        onClose={props.onClose}
        closeOnBackdrop={false}
      >
        <CForm
        //onChange={handleChange}
        >
          <CModalBody>
            <CContainer className="form-group small">
              <>
                <CCardBody className="editShipperDetailsCard ml-3 mb-3">
                  <h6>
                    <CModalHeader className={"px-0"}>
                      <strong>Add Item:</strong>
                    </CModalHeader>
                  </h6>
                  {/*<CTabs>*/}
                  {/*  <CNav variant="tabs">*/}
                  {/*    {!isEdit ? <>*/}
                  {/*      <CNavLink>New</CNavLink>*/}
                  {/*     <CNavLink>From Stock</CNavLink>*/}
                  {/*    </>:<>*/}
                  {/*        {fromStock?<CNavLink>From Stock</CNavLink>:<CNavLink>New</CNavLink>}*/}
                  {/*        </>*/}
                  {/*    }*/}
                  {/*  </CNav>*/}
                    {/*<CTabContent>*/}
                      {/*{isEdit*/}
                      {/*    ? <>*/}
                      {/*      {fromStock?<CTabPane>*/}
                      {/*        <UpdateItems*/}
                      {/*            onChange={onChange}*/}
                      {/*            onClose={props.onClose}*/}
                      {/*            isEdit={isEdit}*/}
                      {/*            shipment={shipment}*/}
                      {/*            index={index}*/}
                      {/*        />*/}
                      {/*      </CTabPane>: <CTabPane>*/}
                              <CRow className="mb-4 mt-4">
                                <CCol>
                                  <CLabel className="ml-0 position-relative mr-2">
                                    Description:
                                  </CLabel>
                                  <CInput
                                      style={{fontSize: "10px"}}
                                      onChange={handleChange}
                                      name="description"
                                      value={item?.description}
                                  ></CInput>
                                </CCol>
                                <CCol>
                                  <CLabel>Reference:</CLabel>
                                  <CInput
                                      style={{fontSize: "10px"}}
                                      onChange={handleChange}
                                      name="reference"
                                      value={item?.reference}
                                  ></CInput>
                                </CCol>
                                <CCol>
                                  <CLabel>Product Code:</CLabel>
                                  <CInput
                                      style={{fontSize: "10px"}}
                                      onChange={handleChange}
                                      name="productCode"
                                      value={item?.productCode}
                                  ></CInput>
                                </CCol>
                                <CCol>
                                  <CLabel>Country of Origin:</CLabel>
                                  <SWSelect
                                      onChange={(value) => {
                                        handleSelectChange("countryOfOrigin", value);
                                      }}
                                      values={countries}
                                      value={item?.countryOfOrigin}
                                      style={{fontSize: "10px"}}
                                      name="countryOfOrigin"
                                  />
                                </CCol>
                                <CCol>
                                  <CLabel>Quantity:</CLabel>
                                  <CInput
                                      type="number"
                                      style={{fontSize: "10px"}}
                                      onChange={handleChangeNumber}
                                      name="quantity"
                                      value={item.quantity}
                                  ></CInput>
                                </CCol>
                              </CRow>
                              <CRow className="mb-4 mt-4">
                                <CCol>
                                  <CLabel>Value:</CLabel>
                                  <CInput
                                      style={{fontSize: "10px"}}
                                      onChange={handleChangeNumber}
                                      name="value"
                                      type="number"
                                      value={item.value}
                                  ></CInput>
                                </CCol>
                                <CCol>
                                  <CLabel>Currency:</CLabel>
                                  <CInput
                                      style={{fontSize: "10px"}}
                                      onChange={handleChange}
                                      name="valueCurrency"
                                      value={shipment.valueCurrency}
                                  ></CInput>
                                </CCol>
                                <CCol>
                                  <CLabel>HS Code:</CLabel>
                                  <CInput
                                      style={{fontSize: "10px"}}
                                      onChange={handleChange}
                                      name="hsCode"
                                      value={item.hsCode}
                                  ></CInput>
                                </CCol>
                                <CCol>
                                  <CLabel>Weight ({shipment?.weightUnit}):</CLabel>
                                  <CInput
                                      type="number"
                                      style={{fontSize: "10px"}}
                                      onChange={handleChangeNumber}
                                      name="weight"
                                      value={item.weight}
                                  ></CInput>
                                </CCol>

                                <CCol>
                                  <CLabel>Dutiable:</CLabel>
                                  <CInput
                                      disabled
                                      style={{fontSize: "10px"}}
                                      onChange={handleChange}
                                      name="dutiable"
                                      value={item?.dutiable ? "Yes" : " No"}
                                  ></CInput>
                                </CCol>
                              </CRow>
                              <CCol className="text-right">
                                <CButtonGroup className="mb-0 mt-4">
                                  <CButton
                                      onClick={handleSave}
                                      type="button"
                                      className="w-auto bg-primary text-white ml-1 mr-1"
                                  >
                                    Save
                                  </CButton>
                                  <CButton
                                      className="w-auto bg-primary text-white ml-1 mr-1"
                                      onClick={props.onClose}
                                  >
                                    Close
                                  </CButton>
                                </CButtonGroup>
                              </CCol>
                            {/*</CTabPane>}*/}
                            {/*</>*/}
                          {/*: <>*/}
                          {/*  <CTabPane>*/}
                          {/*    <CRow className="mb-4 mt-4">*/}
                          {/*      <CCol>*/}
                          {/*        <CLabel className="ml-0 position-relative mr-2">*/}
                          {/*          Description:*/}
                          {/*        </CLabel>*/}
                          {/*        <CInput*/}
                          {/*            style={{fontSize: "10px"}}*/}
                          {/*            onChange={handleChange}*/}
                          {/*            name="description"*/}
                          {/*            value={item.description}*/}
                          {/*        ></CInput>*/}
                          {/*      </CCol>*/}
                          {/*      <CCol>*/}
                          {/*        <CLabel>Reference:</CLabel>*/}
                          {/*        <CInput*/}
                          {/*            style={{fontSize: "10px"}}*/}
                          {/*            onChange={handleChange}*/}
                          {/*            name="reference"*/}
                          {/*            value={item.reference}*/}
                          {/*        ></CInput>*/}
                          {/*      </CCol>*/}
                          {/*      <CCol>*/}
                          {/*        <CLabel>Product Code:</CLabel>*/}
                          {/*        <CInput*/}
                          {/*            style={{fontSize: "10px"}}*/}
                          {/*            onChange={handleChange}*/}
                          {/*            name="productCode"*/}
                          {/*            value={item.productCode}*/}
                          {/*        ></CInput>*/}
                          {/*      </CCol>*/}
                          {/*      <CCol>*/}
                          {/*        <CLabel>Country of Origin:</CLabel>*/}
                          {/*        <SWSelect*/}
                          {/*            onChange={(value) => {*/}
                          {/*              handleSelectChange("countryOfOrigin", value);*/}
                          {/*            }}*/}
                          {/*            values={countries}*/}
                          {/*            value={item?.countryOfOrigin}*/}
                          {/*            style={{fontSize: "10px"}}*/}
                          {/*            name="countryOfOrigin"*/}
                          {/*        />*/}
                          {/*      </CCol>*/}
                          {/*      <CCol>*/}
                          {/*        <CLabel>Quantity:</CLabel>*/}
                          {/*        <CInput*/}
                          {/*            type="number"*/}
                          {/*            style={{fontSize: "10px"}}*/}
                          {/*            onChange={handleChangeNumber}*/}
                          {/*            name="quantity"*/}
                          {/*            value={item.quantity}*/}
                          {/*        ></CInput>*/}
                          {/*      </CCol>*/}
                          {/*    </CRow>*/}
                          {/*    <CRow className="mb-4 mt-4">*/}
                          {/*      <CCol>*/}
                          {/*        <CLabel>Value:</CLabel>*/}
                          {/*        <CInput*/}
                          {/*            style={{fontSize: "10px"}}*/}
                          {/*            onChange={handleChangeNumber}*/}
                          {/*            name="value"*/}
                          {/*            type="number"*/}
                          {/*            value={item.value}*/}
                          {/*        ></CInput>*/}
                          {/*      </CCol>*/}
                          {/*      <CCol>*/}
                          {/*        <CLabel>Currency:</CLabel>*/}
                          {/*        <CInput*/}
                          {/*            style={{fontSize: "10px"}}*/}
                          {/*            onChange={handleChange}*/}
                          {/*            name="valueCurrency"*/}
                          {/*            value={shipment.valueCurrency}*/}
                          {/*        ></CInput>*/}
                          {/*      </CCol>*/}
                          {/*      <CCol>*/}
                          {/*        <CLabel>HS Code:</CLabel>*/}
                          {/*        <CInput*/}
                          {/*            style={{fontSize: "10px"}}*/}
                          {/*            onChange={handleChange}*/}
                          {/*            name="hsCode"*/}
                          {/*            value={item.hsCode}*/}
                          {/*        ></CInput>*/}
                          {/*      </CCol>*/}
                          {/*      <CCol>*/}
                          {/*        <CLabel>Weight ({shipment?.weightUnit}):</CLabel>*/}
                          {/*        <CInput*/}
                          {/*            type="number"*/}
                          {/*            style={{fontSize: "10px"}}*/}
                          {/*            onChange={handleChangeNumber}*/}
                          {/*            name="weight"*/}
                          {/*            value={item.weight}*/}
                          {/*        ></CInput>*/}
                          {/*      </CCol>*/}

                          {/*      <CCol>*/}
                          {/*        <CLabel>Dutiable:</CLabel>*/}
                          {/*        <CInput*/}
                          {/*            disabled*/}
                          {/*            style={{fontSize: "10px"}}*/}
                          {/*            onChange={handleChange}*/}
                          {/*            name="dutiable"*/}
                          {/*            value={item?.dutiable ? "Yes" : " No"}*/}
                          {/*        ></CInput>*/}
                          {/*      </CCol>*/}
                          {/*    </CRow>*/}
                          {/*    <CCol className="text-right">*/}
                          {/*      <CButtonGroup className="mb-0 mt-4">*/}
                          {/*        <CButton*/}
                          {/*            onClick={handleSave}*/}
                          {/*            type="button"*/}
                          {/*            className="w-auto bg-primary text-white ml-1 mr-1"*/}
                          {/*        >*/}
                          {/*          Save*/}
                          {/*        </CButton>*/}
                          {/*        <CButton*/}
                          {/*            className="w-auto bg-primary text-white ml-1 mr-1"*/}
                          {/*            onClick={props.onClose}*/}
                          {/*        >*/}
                          {/*          Close*/}
                          {/*        </CButton>*/}
                          {/*      </CButtonGroup>*/}
                          {/*    </CCol>*/}
                            {/*</CTabPane>*/}
                            {/*<CTabPane>*/}
                            {/*  <UpdateItems*/}
                            {/*      onChange={onChange}*/}
                            {/*      isEdit={isEdit}*/}
                            {/*      onClose={props.onClose}*/}
                            {/*      shipment={shipment}*/}
                            {/*      index={index}*/}
                            {/*  />*/}
                            {/*</CTabPane>*/}
                  {/*          </>*/}
                  {/*    }*/}
                  {/*  </CTabContent>*/}
                  {/*</CTabs>*/}



                </CCardBody>
              </>
            </CContainer>

          </CModalBody>
        </CForm>
      </CModal>
    </>
  );
};

export default AddUpdateItems;
