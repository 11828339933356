import CheckoutClient from "../../../../clients/checkoutClient";
import {useEffect, useState} from "react";
import {CCol, CRow, CSpinner} from "@coreui/react";
import ActionButton from "../../../SharedComponents/ActionButton";
import SWMultiSelect from "../../../SharedComponents/SWMultiSelect";

type Props = {
    accountId: string
}
const preferredLanguagesValues = {
    "en": "English",
    "fr": "France",

};
const ViewAccountCheckout = ({accountId}: Props) => {
    const checkoutClient = new CheckoutClient()
    const [loader, setLoader] = useState(false)
    const [preferredLanguages, setPreferredLanguages] = useState<string[]>([])

    const fetchData = async () => {
        setLoader(true)
        const res = await checkoutClient.GetAccount(accountId)
        if (res)
            setPreferredLanguages(res?.preferredLanguages ?? [])
        setLoader(false)
    }

    useEffect(() => {
        fetchData().then()
    }, [accountId]);

    const handleSubmit = async () => {
        setLoader(true)
        await checkoutClient.UpdateAccount(accountId, {preferredLanguages: preferredLanguages})
        fetchData().then()

    }
    return (
        <CCol md={12}>
            {loader ? <CSpinner className="mx-auto d-block my-5"
                                color="primary"
                                style={{width: "3em", height: "3em"}}/> : <CCol md={4}>

                <SWMultiSelect label={'Languages'} values={preferredLanguagesValues}
                               value={preferredLanguages.map(value => {
                                   return {
                                       value: value,
                                       label: preferredLanguagesValues[value as keyof typeof preferredLanguagesValues]
                                   }
                               })}
                               onChange={(e: { value: string, label: string }[]) => {
                                   setPreferredLanguages(e.map(v => v.value))
                               }}/>
            </CCol>}
            <CRow className={'justify-content-end'}>
                <ActionButton disabled={loader} onClick={() => handleSubmit()} text={"update"}/>
            </CRow>
        </CCol>
    )
}
export default ViewAccountCheckout
