import React, {useEffect, useState} from "react";
import ShipClient from "../../../../clients/shipClient";
import {shipDefaultAccountResponse} from "../../../../models/accounting";
import ViewShipData from "./ViewShipmentData";


const ViewShipAccount = ({accountId}: any) => {

    const shipClient = new ShipClient();
    const [accountData, setAccountData] = useState<shipDefaultAccountResponse>();

    const fetchData = async () => {
        const rs = await shipClient.GetAccountDefault(accountId)
        if (rs.successed) {
            setAccountData({
                bookingData: {
                    fields: rs.data.bookingData.fields,
                    quotationScenarioId: rs.data.bookingData.quotationScenarioId,
                    quotationProfileId: rs.data.bookingData.quotationProfileId,

                },
                shipmentImportData: {
                    fileType: rs.data.shipmentImportData.fileType,
                    fieldDelimited: rs.data.shipmentImportData.fieldDelimited,
                    noOfHeaders: rs.data.shipmentImportData.noOfHeaders,
                    noOfFooters: 0,
                    fields: rs.data.shipmentImportData.fields
                }
            });
        }

    }


    useEffect(() => {
        fetchData().then(() => {
        })
    }, []);


    return (
        <>
            <ViewShipData accountData={accountData!} setAccountData={setAccountData} accountId={accountId}
                          isBookingData/>
            <ViewShipData accountData={accountData!} setAccountData={setAccountData} accountId={accountId}
                          isBookingData={false}/>

        </>
    )

}
export default ViewShipAccount;
