import SWInput from "./SWInput";
import SWSubmit from "./SWSubmit";
import SWForm from "./SWForm";
import {
  SWFormFields,
  SWFormRef,
  SWInputFieldValue,
  SWValidator,
} from "./model";

export const Form = SWForm;
export const Input = SWInput;
export const Submit = SWSubmit;

export type InputFieldValue = SWInputFieldValue;
export type Validator = SWValidator;
export interface FormFields extends SWFormFields {}
export interface FormRef extends SWFormRef {}
