import { CModal, CModalBody, CModalHeader } from "@coreui/react";
import React from "react";

interface IProps {
    missingShipments: string[] | undefined
    handleClose: () => void
}

const NotFoundShipmentsModal = (props : IProps) => (
    <CModal show={true} onClose={props.handleClose}>
        <CModalHeader>
            <h5>Shipments not found</h5>
        </CModalHeader>
        <CModalBody>
            {props.missingShipments?.map(m => <h5>{m + "\n"}</h5>)}
        </CModalBody>
    </CModal>
)

export default NotFoundShipmentsModal;