import {ExternalCallLog} from "../../../../models/getShipmentLogs";
import {PathLogsType} from "./viewLogs";
import {CCol, CLabel, CRow} from "@coreui/react";
import React from "react";

type Props = {
    data: ExternalCallLog
    addPath: (req: PathLogsType) => void

}
const LogsExternalDetails = (props: Props) => {
    const addPathHandler = (req: PathLogsType) => {
        props.addPath(req)
    }

    return (
        <CCol md={12}>
            <CCol className={'p-0'}>

                <CRow className={'mb-3'}>
                    <CLabel className={'font-sm font-weight-bold'}>Duration:</CLabel>
                    <CLabel className={'font-sm font-weight-bold'}>{props.data.DurationInMillisecond} ms</CLabel>

                </CRow>
            </CCol>

            <CRow className={'logs-values-path-container'}>
            {Boolean(props.data.RequestBodyJson) ?

             <><CCol className={'logs-values-path'} >
                    <CLabel onClick={()=>addPathHandler({title:'Request Body Json',name:'VIEW_DATA',type:'json',dataToView:JSON.stringify(props.data.RequestBodyXml)})} className={'logs-values-path'}>Request Body Json</CLabel>

                </CCol>
                    <CCol className={'logs-values-path'}>
                        <CLabel onClick={()=>addPathHandler({title:'Respond Json',name:'VIEW_DATA',type:'json',dataToView:JSON.stringify(props.data.ResponseJson)})} className={'logs-values-path'}>Response Json</CLabel>
                    </CCol>

                </>
                :
                <>
                    <CCol className={'logs-values-path'} >
                        <CLabel  onClick={()=>addPathHandler({title:'Request Body Xml',name:'VIEW_DATA',type:'xml',dataToView:props.data.RequestBodyXml})} className={'logs-values-path'}>Request Body Xml</CLabel>
                    </CCol>
                    <CCol className={'logs-values-path'} >
                        <CLabel onClick={()=>addPathHandler({title:'Respond Xml',name:'VIEW_DATA',type:'xml',dataToView:props.data.ResponseXml})} className={'logs-values-path'}>Response Xml</CLabel>
                    </CCol>

                </>

            }
                </CRow>


        </CCol>
    )
}
export default LogsExternalDetails
