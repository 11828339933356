import { CSpinner, CDataTable } from "@coreui/react";
import React from "react";
import { AppModel } from "../../../../models/app";
import { ContainerType } from "../../../../models/containertype";
import PageCount from "../../../SharedComponents/PageCount";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {setContainerTypeSearchLimit} from "../../../../store/actions/containertypes";

interface Props {}

const SearchResult = (props: Props) => {
  const dispatch = useDispatch();
  const hist = useHistory();
  const loc = useLocation();

  const containertypeSearchResult = useSelector((state: AppModel) => {
    return {
      result: state.containertype.containertypesSearch.result,
      isLoading: state.containertype.containertypesSearch.isLoading,
      page: state.containertype.containertypesSearch.page,
      filterGroup: state.containertype.containertypesSearch.filterGroup,
      maxPage: Math.ceil(
        state.containertype.containertypesSearch.count! /
          state.containertype.containertypesSearch.limit!
      ),
    };
  });

  const scopedSlots = {
    totalFields: (data: ContainerType) => {
      return <td>{data.additionalFields.length}</td>;
    },
  };

  return (
    <div className="small">
      {containertypeSearchResult.isLoading ? (
        <CSpinner
          className="mx-auto d-block my-5"
          color="primary"
          style={{ width: "5em", height: "5em" }}
        />
      ) : (
        <>
          <CDataTable
          size="sm"
          hover
            clickableRows={true}
            onRowClick={(e: ContainerType) =>
              hist.push(`/settings/containertypes/${e.id}`)
            }
            items={containertypeSearchResult.result}
            scopedSlots={scopedSlots}
            fields={["id", "name", "totalFields"]}
          />
          <PageCount
              
            onPageChange={(p) => {
              const modifiedSearch = new URLSearchParams(loc.search);
              modifiedSearch.delete("page");
              hist.push(
                `${loc.pathname}?${modifiedSearch.toString()}&page=${p}`
              );
            }}
            onPageSizeChange={(ps) => {
              const modifiedSearch = new URLSearchParams(loc.search);
              modifiedSearch.delete("limit");
              hist.push(
                  `${loc.pathname}?${modifiedSearch.toString()}&limit=${ps}`
              );
              dispatch(setContainerTypeSearchLimit(ps))
            }}
            currentPage={containertypeSearchResult.filterGroup?.page ?? 0}
            maxPage={containertypeSearchResult.maxPage}
            maxFound={containertypeSearchResult.maxPage}
          />
        </>
      )}
    </div>
  );
};

export default SearchResult;
