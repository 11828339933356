import {CCol, CContainer, CDataTable, CRow, CSpinner, CTooltip} from "@coreui/react";
import React, {useEffect, useState} from "react";
import ShipClient from "../../../../clients/shipClient";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import {downloadBlob} from "../../../../utils/downloadUtils";
import {formatDate} from "../../../../utils/dateUtil";
import PageCount from "../../../SharedComponents/PageCount";
import {useHistory, useLocation} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {querytoMap} from "../../../../utils/filterUtils";
import {IPagination} from "../../../../models/filter";
import {SkuSearchFilter} from "../../../../models/warehouse";
import {SetLoading} from "../../../../store/actions/auth";
import {Field, Form, Formik} from "formik";
import SelectField from "../../../SharedComponents/FormikCustomFields/SelectField";
import {AppModel} from "../../../../models/app";
import SWSelect from "../../../SharedComponents/SWSelect";

interface Props {
    profile: any;
    profileLookup: any;
    trigger: boolean
    onChange: (e: any) => void
}


const months = {
    "0": "Select",
    "1": "January",
    "2": "February",
    "3": "March",
    "4": "April",
    "5": "May",
    "6": "June",
    "7": "July",
    "8": "August",
    "9": "September",
    "10": "October",
    "11": "November",
    "12": "December"
}

const getYears = () => {
    const currentYear = new Date().getFullYear();
    let y= [{label:"Select", value:"0" }];
    for (let i = currentYear; i > (currentYear - 8); i--) {
        y.push({label: i.toString(), value: i.toString()})
    }
    return y;
}

interface GeneratedMonthReport extends IPagination
{
    profile?:string
    month?:string
    year?:string
}

const GeneratedReportsTable = ({profile, profileLookup, trigger, onChange}: Props) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [selectedYear, setSelectedYear] = useState<string>("0");
    const [selectedMonth, setSelectedMonth] = useState<string>("0");
    const years = getYears();
    const shipClient = new ShipClient();
    let {search} = useLocation();
    const loc = useLocation();
    const hist = useHistory();
    const [limit, setLimit] = useState<number>(20)

    const dispatch = useDispatch();

    const [pagination, setPagination] = useState<IPagination>({
        limit: 20,
        offset: 0,
    });

    const [pendingCod, setPendingCod] = useState<{ matches: any[], total: number }>({matches: [], total: 0})

    useEffect(() => {
        refreshGeneratedCOD()
    }, [search, trigger])

    const refreshGeneratedCOD = async () => {
        setIsSubmitting(true)
        let map = querytoMap<GeneratedMonthReport>(search)

        setPagination((state) => ({
            offset: map.offset || state.offset,
            limit: map.limit || state.limit,
        }));
        
        if(map.year)
            setSelectedYear(map.year);
        else
            setSelectedYear("0");
        
        if(map.month)
            setSelectedMonth(map.month);
        else
            setSelectedMonth("0");
        await handleSearch(map);
    }

    const handleSearch = async (filter: GeneratedMonthReport) => {
        dispatch(SetLoading(true));
        const res = await shipClient.getGeneratedMonthReport(filter);

        setPendingCod({matches: res?.matches, total: res?.total});

        dispatch(SetLoading(false));
        setIsSubmitting(false)
    };

    const scopedSlots = {
        pickupDate: (item: any) => {
            return <td className={"text-center"}>{formatDate(item?.pickupDate) ?? ""}</td>
        },
        agent: (item: any) => {
            return <td className={""}>{item?.agent ?? ""}</td>
        },
        consigneeCountry: (item: any) => {
            return <td className={""}>{item?.consigneeCountry ?? ""}</td>
        },
        numberOfReturns: (item: any) => {
            return <td className={"text-center"}>{item?.numberOfReturns ?? ""}</td>
        },
        numberOfShipments: (item: any) => {
            return <td className={"text-center"}>{item?.numberOfShipments ?? ""}</td>
        },
        exportToExel: (item: any) => {
            return <td className={"text-center"}>
                <CTooltip content="Export to Exel">
                    <CIcon
                        content={freeSet.cilCloudDownload}
                        onClick={() => shipClient.getGeneratedMonth(item.id)
                            .then((res: any) => downloadBlob(res.location, res.name, "_self"))}

                    />

                </CTooltip>
            </td>
        }
    }

    return isSubmitting ? (
        <CSpinner
            className="mx-auto d-block my-5"
            color="primary"
            style={{width: "5em", height: "5em"}}
        />
    ) : (
        <>
            <CContainer>
                <Formik
                    initialValues={{
                        profile
                    }}
                    enableReinitialize={true}

                    onSubmit={(values) => {
                        
                        onChange(values.profile)
                    }}

                >
                    {(formik) => (
                        <Form>
                            <CRow>
                                <CCol className="col-sm-3 d-flex align-items-center mt-2">
                                    <CCol>
                                        <SWSelect
                                            label={"Profile"}
                                            values={profileLookup} value={profile} name="profile"
                                            onChange={onChange}/>

                                        {/*<Field label="Profile" type="text" name="profile" change={(e:any)=> {*/}
                                        {/*    hist.push({*/}
                                        {/*        pathname: loc.pathname,*/}
                                        {/*        search: '?profile='+e.id*/}
                                        {/*    })*/}
                                        {/*    formik.setFieldValue("profile", e.id)*/}
                                        {/*}} options={profileLookup} component={SelectField}/>*/}
                                    </CCol>
                                </CCol>
                                <CCol className="col-sm-3 d-flex align-items-center mt-2">
                                    <CCol>
                                        <SWSelect label="Month" name="month" value={selectedMonth}
                                                  onChange={(e) => {
                                                      if(!e) return;
                                                      const modifiedSearch = new URLSearchParams(loc.search);
                                                      modifiedSearch.delete("month");
                                                      const toAdd = e != "0" ? `&month=${e}` : "";
                                                      hist.push(`${loc.pathname}?${modifiedSearch.toString()}${toAdd}`);
                                                      
                                                  }} values={months}/>
                                    </CCol>

                                </CCol>
                                <CCol className="col-sm-3 d-flex align-items-center mt-2">
                                    <CCol>
                                        <SWSelect label="Year" name="year" value={selectedYear}
                                                  onChange={(e) => {
                                                      if(!e) return;
                                                      const modifiedSearch = new URLSearchParams(loc.search);
                                                      modifiedSearch.delete("year");
                                                      const toAdd = e != "0" ? `&year=${e}` : "";
                                                      hist.push(`${loc.pathname}?${modifiedSearch.toString()}${toAdd}`);
                                                  }} values={years}/>
                                    </CCol>

                                </CCol>
                            </CRow>

                        </Form>)}
                </Formik>
                <CDataTable
                    size="sm"
                    loading={isSubmitting}
                    hover
                    scopedSlots={scopedSlots}
                    items={pendingCod?.matches ?? []}
                    fields={[
                        {
                            key: "exportToExel",
                            label: "",
                            _style: {textAlign: "center", width: '6%', padding: '0.25rem'}
                        },
                        {key: "accountName", label: "Company Name"},
                        {key: "name", label: "Document Name"},
                        {key: "consigneeCountry", label: "Country"},
                        {key: "agent", label: "Agent"},
                        {key: "numberOfShipments", label: "Shipments", _style: {textAlign: "center"}},
                        {key: "numberOfReturns", label: "Returns", _style: {textAlign: "center"}},
                        {key: "pickupDate", label: "Document Date", _style: {textAlign: "center"}},
                    ]}
                />
                <CCol>
                    <PageCount
                        onPageChange={(p) => {
                            const modifiedSearch = new URLSearchParams(loc.search);
                            modifiedSearch.delete("offset");
                            hist.push(
                                `${loc.pathname}?${modifiedSearch.toString()}&offset=${
                                    p * limit!
                                }`
                            );
                        }}
                        onPageSizeChange={(ps) => {
                            const modifiedSearch = new URLSearchParams(loc.search);
                            modifiedSearch.delete("limit");
                            hist.push(
                                `${loc.pathname}?${modifiedSearch.toString()}&limit=${ps}`
                            );
                            setLimit(ps)
                        }}
                        currentPage={pagination.offset! / limit!}
                        maxPage={Math.ceil(
                            pendingCod.total! / limit!
                        )}
                        maxFound={pendingCod.total}
                    />
                </CCol>

            </CContainer>
        </>
    )
}

export default GeneratedReportsTable