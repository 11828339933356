import { CCol, CForm, CRow, CContainer } from "@coreui/react";
import { Form, Input, Submit } from "../../SharedComponents/SWForm";
import React from "react";
import ShipClient from "../../../clients/shipClient";
import validator from "validator";
import ContainerTypeInputs from "./components/ContainerTypeInputs";
import { useHistory } from "react-router";
import ActionButton from "../../SharedComponents/ActionButton";

interface Props {}
const CreateSortingRule = (props: Props) => {
  const shipClient = new ShipClient();
  const hist = useHistory();

  return (
    <CContainer className="bg-white p-4 text-black">
      <h5>Create Container Type</h5>
      <Form
        className="mt-5"
        onSubmit={async (body) =>
          shipClient.CreateContainerType(body).then((v) => {
            hist.push(`/settings/containertypes/search`);
          })
        }
      >
        <ContainerTypeInputs />
        <CRow>
          <CCol>
              <ActionButton
                  text={"Create"}
              />
          </CCol>
        </CRow>
      </Form>
    </CContainer>
  );
};

export default CreateSortingRule;
