import React from "react";
import { freeSet } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CDataTable, CTooltip } from "@coreui/react";
import { RateEntry } from "../../../../../models/retes";


type Props = {
    entries?: RateEntry[]
    onRemove?: (arg: RateEntry) => void;
    onEdit?: (arg: RateEntry) => void;
}


const RateEntryTable = ({ entries, onRemove, onEdit }: Props) => {

    return (

        <CDataTable
            size="sm"
            hover
            scopedSlots={{
                actions: (e: RateEntry) => {
                    return <td className={"text-center"}>
                        <CTooltip content={"Edit"}>
                            <CIcon content={freeSet.cilPencil}
                                style={{ cursor: "pointer", marginRight: "5px" }}
                                onClick={() => {
                                    onEdit?.(e)
                                }} />
                        </CTooltip>
                        <CTooltip content={"Delete"}>
                            <CIcon style={{ cursor: "pointer" }} content={freeSet.cilTrash} onClick={() => onRemove?.(e)} />
                        </CTooltip>

                    </td>
                },

            }}
            items={entries ?? []}
            fields={[
                { key: "dutiable", label: "Dutiable" },
                { key: "originZone", label: "Origin Zone" },
                { key: "destinationZone", label: "Destination Zone" },
                { key: "from", label: "From" },
                { key: "break", label: "Break" },
                { key: "step", label: "Step" },
                { key: "price", label: "Price" },
                { key: "actions", label: "Actions", _style: { textAlign: "center" } }
            ]}
        />

    )
}

export default RateEntryTable;