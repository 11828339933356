import React, {useState} from "react";
import {CTooltip} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import {
    ShipmentGet,
    ShipmentSearch,
    StandardShipmentPiece,
} from "../../../../models/shipment";
import {TableHeader} from "./TableHeader";
import AddUpdatePieces from "../modals/AddUpdatePieces";
import DeletePieceModal from "../modals/DeletePieceModal";

interface IProps {
    shipment: ShipmentGet;
    onUpdateShipment: (shipment: ShipmentSearch) => void;
    handleFormChange: (shipment: ShipmentSearch) => void;
}

const Pieces = (props: IProps) => {
    const {shipment,onUpdateShipment, handleFormChange} = props;

    const [pieceModalIsShowing, setPieceModalIsShowing] = useState(false);
    const [pieceIndex, setPieceIndex] = useState<number | undefined>();
    const [deletePieceModal, setDeletePieceModal] = useState(false);
    const showAddEditPieceModal = (index?: number) => {
        setPieceModalIsShowing(true);
        setPieceIndex(index);
    };

    const handleDeletePiece = () => {
        //delete piece logic
        if (pieceIndex !== undefined) {
            const newPieces = [
                ...shipment.pieces.filter((piece, index) => index != pieceIndex),
            ];
            onUpdateShipment({...shipment, pieces: newPieces});
            setPieceIndex(0);
            setDeletePieceModal(false);
        }
    };

    const showDeletePieceModal = (index: number) => {
        setDeletePieceModal(true);
        setPieceIndex(index);
    };

    return (
        <>
            <div className="piecesTabTable">
                <CTooltip content={"Add piece"}>
                    <CIcon
                        size={"xl"}
                        color={"primary"}
                        style={{cursor: "pointer"}}
                        content={freeSet.cilPlus}
                        onClick={() => showAddEditPieceModal()}
                    />
                </CTooltip>
                <table className="position-relative table table-hover table-sm">
                    <TableHeader header={["Number", "Reference", "Weight", "Volumetric", "Calculated",
                        "Length", "Width", "Height", "Edit",]} />

                    <tbody>
                    {shipment.pieces?.map(
                        (piece: StandardShipmentPiece, index) => {
                            let volumetric = (piece?.height && piece?.width && piece?.length) ? Math.round((piece.height * piece.width * piece.length / 5000 + Number.EPSILON) * 100) / 100 : 0;
                            volumetric = shipment.weightUnit?.toLowerCase() == "gm" ? volumetric * 1000 : volumetric
                            let calculated = piece.weight ? (volumetric > piece?.weight ? volumetric : piece?.weight) : volumetric
                            return (<tr key={piece.reference}>
                                    <td>{piece?.number}</td>
                                    <td>{piece?.reference}</td>
                                    <td> {piece?.weight ? piece?.weight + ` (${shipment?.weightUnit})` : ""} </td>
                                    <td> {volumetric ? volumetric + ` (${shipment?.weightUnit})` : ""} </td>
                                    <td> {calculated ? calculated + ` (${shipment?.weightUnit})` : ""} </td>
                                    <td>
                                        {piece?.length
                                            ? piece?.length + ` (${shipment?.dimensionUnit})`
                                            : ""}
                                    </td>
                                    <td>
                                        {piece?.width
                                            ? piece?.width + ` (${shipment?.dimensionUnit})`
                                            : ""}
                                    </td>
                                    <td>
                                        {piece?.height
                                            ? piece?.height + ` (${shipment?.dimensionUnit})`
                                            : ""}
                                    </td>
                                    <td>
                                        <CIcon
                                            content={freeSet.cilPencil}
                                            style={{cursor: "pointer"}}
                                            onClick={() => showAddEditPieceModal(index)}
                                        />
                                        <CIcon
                                            content={freeSet.cilTrash}
                                            style={{cursor: "pointer"}}
                                            onClick={() => showDeletePieceModal(index)}
                                        />
                                    </td>
                                </tr>
                            )
                        }
                    )}
                    </tbody>
                </table>
            </div>
            <DeletePieceModal
                isShowing={deletePieceModal}
                onClose={() => {
                    setPieceIndex(0);
                    setDeletePieceModal(false);
                }}
                onSave={handleDeletePiece}
            />
            {pieceModalIsShowing && (
                <AddUpdatePieces
                    onChange={handleFormChange}
                    shipment={shipment}
                    isShowing={pieceModalIsShowing}
                    index={pieceIndex}
                    onClose={() => {
                        setPieceIndex(0);
                        setPieceModalIsShowing(false);
                    }}
                />
            )}
        </>

    )
}
export default Pieces;