import {
    CButton,
    CCard,
    CCardBody,
    CCol,
    CContainer,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CInputCheckbox,
    CInputGroup,
    CLabel,
    CLink,
    CNav,
    CNavLink,
    CRow,
    CSpinner,
    CTabContent,
    CTabPane,
    CTabs,
    CTooltip
} from "@coreui/react";
import {useHistory, useParams} from "react-router";
import React, {useEffect, useState} from "react";
import {
    BillingType,
    ChargeAddUpdate,
    ChargeSearch,
    InvoiceAttachmentType,
    InvoiceBillingAddress,
    InvoiceDto,
    InvoiceStatus,
    IssueCreditNoteRequest,
    IssueInvoiceRequest,
    MarkInvoicePaidRequest
} from "../../../models/accounting";
import AccountingClient from "../../../clients/accountingClient";
import {downloadBlob} from "../../../utils/downloadUtils";
import InvoiceSummeryChargesTable from "./components/InvoiceSummeryChargesTable";
import InvoiceDetailedChargesTable from "./components/InvoiceDetailedChargesTable";
import {useDispatch} from "react-redux";
import {ShowNotification} from "../../../store/actions/auth";
import ConfirmModal from "../../SharedComponents/ConfirmModal";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import AddEditCharge from "../Shipments/modals/AddEditCharge";
import LookupsClient from "../../../clients/lookupsClient";
import InvoiceAttachmentsTable from "./components/InvoiceAttachmentsTable";
import CheckoutClient from "../../../clients/checkoutClient";
import InvoiceStepper from "./components/InvoiceStepper";
import InvoiceBillingAddressForm from "./components/InvoiceBillingAddressForm";
import ShipClient from "../../../clients/shipClient";
import IssueInvoiceModal from "./modals/IssueInvoiceModal";
import ConfirmInvoicePayment from "./modals/ConfirmInvoicePayment";

const ViewInvoice = () => {
    const {id} = useParams() as any;
    const accountingClient = new AccountingClient();
    const checkoutClient = new CheckoutClient();
    const dispatch = useDispatch()
    const hist = useHistory()
    useEffect(() => {
        lookupsClient.getCharges({availableOn: "shipment", format: 1}).then(v => setChargeTypes(v))
    }, [])

    const [chargeTypes, setChargeTypes] = useState<{ [K: string]: string }>({})
    const [editCharge, setEditCharge] = useState<ChargeSearch>()
    const [showAddEditCharge, setShowAddEditCharge] = useState<boolean>(false)
    const lookupsClient = new LookupsClient();
    const shipClient = new ShipClient();
    const [invoice, setInvoice] = useState<InvoiceDto>()
    const [loading, setLoading] = useState<boolean>(true)
    const [showDeleteInvoice, setShowDeleteInvoice] = useState<boolean>(false)
    const [showIssueInvoice, setShowIssueInvoice] = useState<boolean>(false)
    const [showSendInvoice, setShowSendInvoice] = useState<boolean>(false)
    const [showDeleteChargeModal, setShowDeleteChargeModal] = useState<boolean>(false)
    const [selectedChargeId, setSelectedChargeId] = useState<number>()
    const [showDetachCharge, setShowDetachCharge] = useState<boolean>(false)
    const [showConfirmPaymentModal, setShowConfirmPaymentModal] = useState<boolean>(false)
    const [showCreditNoteModal, setShowCreditNoteModal] = useState<boolean>(false)

    useEffect(() => {
        RefreshInvoice()
    }, [id])

    const RefreshInvoice = () => {
        setLoading(true)
        accountingClient.GetInvoice(id).then(v => {
            setInvoice(v)
            setLoading(false)
        })
    }

    const GetAttachment = async (type: InvoiceAttachmentType) => {
        const blob = await accountingClient.GetInvoiceAttachment(id, type)

        downloadBlob(blob.location, blob.name, "_blank")
    }

    const HandleDelete = async () => {
        setLoading(true)
        const rs = await accountingClient.DeleteInvoice(id);

        if (rs.succeeded) {
            dispatch(ShowNotification("Success", "Invoice had been deleted successfully", false))
            setLoading(false)
            hist.push("/financial/billing")
        }
    }

    const handleIssueInvoice = (body: IssueInvoiceRequest) => {
        setLoading(true)
        accountingClient.IssueInvoice(id, body).then(v => {
            if (v.succeeded) {
                dispatch(ShowNotification("Success", "Invoice issued successfully", false))
                RefreshInvoice()
            }
            setShowIssueInvoice(false)
            setLoading(false)
        })
    }

    const handleSendInvoice = async () => {
        let rs;
        if (invoice?.quotationNumber)
            rs = await checkoutClient.SendInvoice(invoice.quotationNumber)
        else
            rs = await accountingClient.SendInvoice(invoice?.id ?? 0)
        if (rs)
            dispatch(ShowNotification("Success", "invoice sent successfully"))
        setShowSendInvoice(false)
        await RefreshInvoice()
    }

    const handleSaveCharge = async (charge: ChargeAddUpdate) => {
        let rs: number;
        if (charge.id)
            rs = await accountingClient.UpdateCharge(charge)
        else
            rs = await accountingClient.AddCharge(charge)
        await accountingClient.AddChargeToInvoice(invoice?.id ?? 0, rs)
        if (rs) {
            dispatch(ShowNotification("Success", `Change ${charge.id ? "updated" : "added"} successfully`))
            setEditCharge(undefined)
            setShowAddEditCharge(false)
            await RefreshInvoice()
        }
    }

    const HandlePayment = async (body: MarkInvoicePaidRequest) => {
        const rs = await accountingClient.MarkAsPaid(invoice?.id ?? 0, body)
        if (rs)
            dispatch(ShowNotification("Success", "Invoice marked as payed", false))
        setShowConfirmPaymentModal(false)
        await RefreshInvoice()
    }

    const handleUpdateBillingAddress = async (billingAddress: InvoiceBillingAddress) => {
        if (!invoice) return
        const rs = await accountingClient.UpdateBillingAddress(invoice.id, billingAddress)

        if (rs)
            dispatch(ShowNotification("Success", "Billing address updated successfully", false))
        await RefreshInvoice();
    }

    const HandleCreditNote = async () => {
        const body: IssueCreditNoteRequest = {
            invoiceId: invoice?.id ?? 0
        }
        const creditNoteId = await accountingClient.CreditNote(body)
        if (creditNoteId) {
            dispatch(ShowNotification("Success", "Credit note issued successfully", false))
            hist.push(`/financial/billing/${creditNoteId}`)
        }
        setShowCreditNoteModal(false)
    }

    return (
        <CContainer>
            <CCard>
                <CCardBody>
                    <ul className="nav">
                        <li className="nav-item">
                            <CTooltip content="Back">
                                <CButton>
                                    <CIcon
                                        content={freeSet.cilArrowLeft}
                                        onClick={() => hist.goBack()}
                                    />
                                </CButton>
                            </CTooltip>
                        </li>
                        <li className="nav-item">
                            <CDropdown>
                                <CDropdownToggle>
                                    <CTooltip content="Download Invoice">
                                        <CIcon content={freeSet.cilFile}/>
                                    </CTooltip>
                                </CDropdownToggle>
                                <CDropdownMenu>
                                    {(invoice?.status == InvoiceStatus.Draft || invoice?.preferredLanguages?.includes("en")) &&
                                        <CDropdownItem onClick={() => {
                                            accountingClient.GetInvoiceAttachment(invoice?.id.toString() ?? "", InvoiceAttachmentType.Invoice, "en").then(v => downloadBlob(v.location, v.name))
                                        }}>Invoice English</CDropdownItem>}
                                    {(invoice?.status == InvoiceStatus.Draft || invoice?.preferredLanguages?.includes("fr")) &&
                                        <CDropdownItem onClick={() => {
                                            accountingClient.GetInvoiceAttachment(invoice?.id.toString() ?? "", InvoiceAttachmentType.Invoice, "fr").then(v => downloadBlob(v.location, v.name))
                                        }}>Invoice French</CDropdownItem>}
                                    <CDropdownItem onClick={() => {
                                        accountingClient.GetInvoiceAttachment(invoice?.id.toString() ?? "", InvoiceAttachmentType.ExcelDescription).then(v => downloadBlob(v.location, v.name))
                                    }}>Excel Details</CDropdownItem>
                                </CDropdownMenu>
                            </CDropdown>
                        </li>
                        <li className={"nav-item ml-auto"}/>
                        {invoice?.creditNoteId &&
                            <li className={"nav-item mt-2"}>
                                <CLink to={`${invoice?.creditNoteId}`}>Credit
                                    Note: {invoice.creditNoteNumber ?? "Pending"}</CLink>
                            </li>}
                        {invoice?.paymentAmount != undefined && invoice.paymentAmount > 0 && invoice.status != InvoiceStatus.Paid &&
                            <li className={"nav-item mt-2 ml-2"}>
                                Partial paid:({invoice.paymentAmount})
                            </li>
                        }
                        {invoice?.creditedInvoiceId &&
                            <li className={"nav-item mt-2"}>
                                <CLink to={`${invoice?.creditedInvoiceId}`}>Credited
                                    Invoice: {invoice.creditedInvoiceNumber ?? "Pending"}</CLink>
                            </li>}
                        <li className="nav-item ml-2 mt-2">
                            {invoice?.accountName}
                        </li>
                        {invoice?.status != InvoiceStatus.Draft &&
                            <li className="nav-item ml-5 mr-0 mt-2">
                                <CInputGroup>
                                    <CInputCheckbox
                                        style={{cursor: "pointer"}}
                                        checked={invoice?.status == InvoiceStatus.Paid}
                                        disabled={invoice?.status == InvoiceStatus.Paid}
                                        onChange={() => setShowConfirmPaymentModal(true)}
                                    />
                                    <CLabel className="text-primary">Paid</CLabel>
                                </CInputGroup>
                            </li>}
                    </ul>
                    <CRow>
                        <CCol>
                            <InvoiceStepper invoice={invoice}/>
                        </CCol>
                    </CRow>
                    <CTabs>

                        <CContainer>
                            {loading ? <CSpinner
                                    className="mx-auto d-block my-5"
                                    color="primary"
                                    style={{width: "5em", height: "5em"}}
                                />
                                : <>
                                    <InvoiceBillingAddressForm billingAddress={invoice?.billingAddress}
                                                               account={invoice?.account} active={true}
                                                               onSubmit={handleUpdateBillingAddress}/>
                                    <br/>
                                    <CNav variant="tabs">
                                        <CCol>
                                            <CRow className={"d-felx justify-content-between"}>
                                                <CCol md={9}>
                                                    <CRow>
                                                        <CNavLink>Details</CNavLink>
                                                        <CNavLink>Summary</CNavLink>
                                                        <CNavLink>Attachments</CNavLink>
                                                    </CRow>
                                                </CCol>
                                                <CCol md={3} className={"d-flex flex-row-reverse"}>
                                                    <CRow className={"text-right"}>
                                                        {invoice?.status == InvoiceStatus.Draft ?
                                                            <CRow>
                                                                <CButton variant={"outline"} color={"danger"}
                                                                         size={"sm"}
                                                                         className={"mr-3"}
                                                                         onClick={() => setShowDeleteInvoice(true)}>Delete</CButton>
                                                                <CButton
                                                                    variant={"outline"}
                                                                    color={"primary"}
                                                                    size={"sm"}
                                                                    onClick={() => setShowIssueInvoice(true)}
                                                                >Issue Invoice</CButton>
                                                            </CRow>
                                                            : <CRow>
                                                                <CCol className={"text-right"}>
                                                                    {!invoice?.creditNoteId &&
                                                                        <CButton
                                                                            variant={"outline"}
                                                                            size={"sm"}
                                                                            color={"danger"}
                                                                            onClick={() => setShowCreditNoteModal(true)}
                                                                        >Credit Note</CButton>}
                                                                    <CButton
                                                                        className={"ml-3"}
                                                                        variant={"outline"}
                                                                        color={"primary"}
                                                                        size={"sm"}
                                                                        onClick={() => setShowSendInvoice(true)}
                                                                    >{invoice?.sentOn ? "Resend Invoice" : "Send Invoice"}</CButton>
                                                                </CCol>
                                                            </CRow>}
                                                    </CRow>
                                                </CCol>
                                            </CRow>

                                        </CCol>


                                    </CNav>
                                    <CTabContent>
                                        <CTabPane>
                                            <div className={"py-3"}>


                                            </div>
                                            <InvoiceDetailedChargesTable invoice={invoice} addCharge={() => {
                                                setEditCharge(undefined)
                                                setShowAddEditCharge(true)
                                            }} deleteCharge={(chargeId) => {
                                                setSelectedChargeId(chargeId)
                                                setShowDeleteChargeModal(true)
                                            }}
                                                                         detachCharge={id => {
                                                                             setSelectedChargeId(id)
                                                                             setShowDetachCharge(true)
                                                                         }}
                                                                         updateCharge={id => {
                                                                             let chargeTpUpdate = invoice?.detailedCharges.find(c => c.id == id)
                                                                             if (!chargeTpUpdate) return
                                                                             let body: ChargeSearch = {
                                                                                 createdBy: "",
                                                                                 createdOn: "",
                                                                                 taxValue: chargeTpUpdate.taxValue,
                                                                                 totalValue: chargeTpUpdate.value + chargeTpUpdate.taxValue,
                                                                                 id: chargeTpUpdate.id,
                                                                                 billingType: BillingType.Selling,
                                                                                 account: chargeTpUpdate.account,
                                                                                 value: chargeTpUpdate.value,
                                                                                 currency: "EUR",
                                                                                 isReadyToBeInvoiced: true,
                                                                                 taxRate: chargeTpUpdate.taxRate,
                                                                                 typeId: chargeTpUpdate.typeId,
                                                                                 taxCode: chargeTpUpdate.taxCode,
                                                                                 isTaxable: chargeTpUpdate.taxCode == "Y",
                                                                                 addedManually: true,
                                                                                 shipmentUid: chargeTpUpdate.shipmentUid,
                                                                                 deleted: false,
                                                                                 isGrouped: chargeTpUpdate.isGrouped,
                                                                                 comments:chargeTpUpdate?.comments,
                                                                             }
                                                                             setEditCharge(body)
                                                                             setShowAddEditCharge(true)
                                                                             // accountingClient.UpdateCharge(body).then(v => {
                                                                             //     if (v) {
                                                                             //         setEditCharge(undefined)
                                                                             //         setShowAddEditCharge(true)
                                                                             //     }
                                                                             // })
                                                                         }}/>
                                        </CTabPane>
                                        <CTabPane>
                                            <InvoiceSummeryChargesTable charges={invoice?.summaryCharges}
                                                                        invoice={invoice}/>
                                        </CTabPane>
                                        <CTabPane>
                                            <InvoiceAttachmentsTable invoice={invoice}
                                                                     refreshData={() => RefreshInvoice()}/>
                                        </CTabPane>
                                    </CTabContent>
                                </>}
                        </CContainer>
                    </CTabs>

                </CCardBody>
            </CCard>
            {showDeleteInvoice &&
                <ConfirmModal onClose={() => setShowDeleteInvoice(false)} onSubmit={() => HandleDelete()}
                              title={"Delete invoice"}
                              body={`Are you sure you want to delete invoice for account: ${invoice?.accountName}`}/>
            }
            {showIssueInvoice &&
                <IssueInvoiceModal onClose={() => setShowIssueInvoice(false)}
                                   onSubmit={async (body) => handleIssueInvoice(body)} submitting={loading}
                                   defaultDueDays={invoice?.dueDays} defaultInvoiceDate={invoice?.invoiceDate ?? ""}/>
            }
            {showAddEditCharge &&
                <AddEditCharge hideIsGroupedCheckBox={false} hideIsReadyToBeInvoicedCheckbox={true} onClose={() => {
                    setEditCharge(undefined)
                    setShowAddEditCharge(false)
                }} shipClient={shipClient} shipmentUid={editCharge?.shipmentUid} onSave={c => handleSaveCharge(c)}
                               chargeTypes={chargeTypes}
                               billingType={BillingType.Selling}
                               chargeToEdit={editCharge}
                               account={invoice?.account ?? ""} showList={false} accountingClient={accountingClient}/>
            }
            {
                showDeleteChargeModal && <ConfirmModal onClose={() => {
                    setShowDeleteChargeModal(false)
                }} onSubmit={() => {
                    accountingClient.DeleteCharge(selectedChargeId ?? 0).then(v => {
                        if (v) {
                            dispatch(ShowNotification("Success", "Charge deleted successfully", false))
                            setSelectedChargeId(undefined)
                            setShowDeleteChargeModal(false)
                            RefreshInvoice()
                        }
                    })
                }} title={"Delete charge"} body={"Are you sure you want to delete this charge?"}/>
            }
            {
                showDetachCharge && <ConfirmModal onClose={() => {
                    setSelectedChargeId(undefined)
                    setShowDetachCharge(false)
                }} onSubmit={() => {
                    accountingClient.DetachCharge(selectedChargeId ?? 0).then(v => {
                        if (v) {
                            dispatch(ShowNotification("Success", "Charge successfully removed from invoice", false))
                            setSelectedChargeId(undefined)
                            setShowDetachCharge(false)
                            RefreshInvoice()
                        }
                    })
                }} title={"Detach charge"} body={"Are you sure you want to remove this charge from invoice?"}/>
            }
            {
                showSendInvoice && <ConfirmModal onClose={() => setShowSendInvoice(false)} onSubmit={handleSendInvoice}
                                                 title={`Send invoice: ${invoice?.invoiceNumber}`}
                                                 body={"Are you sure you want to send invoice to customer?"}/>
            }
            {
                showConfirmPaymentModal &&
                <ConfirmInvoicePayment invoice={invoice} onClose={() => setShowConfirmPaymentModal(false)}
                                       onSubmit={v => HandlePayment(v)}/>
            }
            {
                showCreditNoteModal &&
                <ConfirmModal onClose={() => setShowCreditNoteModal(false)} onSubmit={() => HandleCreditNote()}
                              title={"Issue credit note"}
                              body={"Are you sure you want to issue credit note for this invoice?"}/>
            }
        </CContainer>
    )
}

export default ViewInvoice