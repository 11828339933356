import {CCol, CCollapse, CContainer, CDataTable, CInputCheckbox, CRow, CTooltip} from "@coreui/react";
import React, {useEffect, useState} from "react";
import ShipClient from "../../../../../clients/shipClient";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import {faArrowCircleDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    BatchShipmentGenerateRequest,
    PendingPrinting,
    PrintShipmentRequest,
    selectedPendingPrinting
} from "../../../../../models/shipment";
import {useHistory} from "react-router";
import ConfirmModal from "../../../../SharedComponents/ConfirmModal";
import ActionButton from "../../../../SharedComponents/ActionButton";
import {useDispatch} from "react-redux";
import {SetIsLoading} from "../../../../../store/actions/ui";
import PendingDetailsInnerTable from "./pendingDetailsInnerTable";
import {ShowNotification} from "../../../../../store/actions/auth";

interface Props {
    refreshGeneratedShipmentsTable: () => void
}

const PendingTable = ({refreshGeneratedShipmentsTable}: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const shipClient = new ShipClient();
    const [pendingShipmentBatch, setPendingShipmentBatch] = useState<PendingPrinting[]>([])
    const [args, setArgs] = useState<string[]>(['Agent']);
    const [fetch, setFetch] = useState<number>(0);
    const [details, setDetails] = useState<{ account: string, agent?: string }[]>([])
    const [detailsLoading, setDetailsLoading] = useState<{ [key: string]: boolean }>({});
    const [detailsData, setDetailsData] = useState<{ [key: string]: any[] }>({});
    const [loading, setLoading] = useState<boolean>(false)
    const [batchShipmentGenerateRequest, setBatchShipmentGenerateRequest] = useState<PrintShipmentRequest>()
    const [showConfirmGenerate, setShowConfirmGenerate] = useState<boolean>(false)
    const [showConfirmBulkPrint, setShowConfirmBulkPrint] = useState<boolean>(false)
    const [bulkGenerateRequest, setBulkGenerateRequest] = useState<selectedPendingPrinting[]>()
    const [selectedRows, setSelectedRows] = useState<selectedPendingPrinting[] | null>(null)


    useEffect(() => {
        fetchData();
    }, [args]);

    const fetchData = async () => {
        setLoading(true);
        const res = await shipClient.GetPendingShipmentBatch(args);
        if (res?.succeeded) {
            setPendingShipmentBatch(res?.data);
        } else {
            dispatch(ShowNotification("Error", res?.error ?? "Error", true));
        }
        setLoading(false);
    };

    const fetchDetails = async (account: string, agent: string, hasCod: boolean | undefined) => {
        const key = `${account}-${agent}`;
        setDetailsLoading({...detailsLoading, [key]: true});
        const shipments = await shipClient.GetShipmentBatchDetails({
            account,
            agent,
            hasCod: !!args?.includes("Cod") ? hasCod : undefined,
        });
        setDetailsLoading({...detailsLoading, [key]: false});
        setDetailsData({...detailsData, [key]: shipments});

    };

    const handelGenerate = (generateRequestData: BatchShipmentGenerateRequest, item?: any) => {
        setBatchShipmentGenerateRequest(generateRequestData)
        setShowConfirmGenerate(true);

    };

    const handleBulkGenerate = () => {
        setBulkGenerateRequest(selectedRows!)
        setShowConfirmBulkPrint(true);
    };

    const OnConfirmGenerate = async () => {
        if (!batchShipmentGenerateRequest) return;
        dispatch(SetIsLoading(true))
        const res = await shipClient.GenerateShipmentsBatch(batchShipmentGenerateRequest);
        if (res) {
            const key = `${batchShipmentGenerateRequest.account}-${batchShipmentGenerateRequest.agent}`;
            setDetailsData(prevState => ({
                ...prevState,
                [key]: prevState[key]?.filter((item: any) => item.skuCode != batchShipmentGenerateRequest?.skuCode),
            }));
            batchShipmentGenerateRequest?.account && fetchDetails(
                batchShipmentGenerateRequest?.account,
                batchShipmentGenerateRequest?.agent,
                batchShipmentGenerateRequest?.hasCod);
            refreshGeneratedShipmentsTable()
        }
        dispatch(SetIsLoading(false))

    };

    const OnConfirmBulkGenerate = async () => {
        if (!bulkGenerateRequest || bulkGenerateRequest.length === 0) return;
        dispatch(SetIsLoading(true))
        let containerIds = [] as number[];
        await Promise.all(
            bulkGenerateRequest?.map(async (item) => {
                let res = await shipClient.GenerateShipmentsBatch(item);
                containerIds.push(res);
            })
        );
        setSelectedRows(null)
        fetchData();
        refreshGeneratedShipmentsTable();
        setPendingShipmentBatch(pendingShipmentBatch.filter(item =>
            !bulkGenerateRequest.some(req => req.account === item.account && req.agent === item.agent)
        ));
        dispatch(SetIsLoading(false));

    };

    const handleArgs = (name: string) => {
        setPendingShipmentBatch([]);
        if (args?.includes(name)) {
            setArgs([...args?.filter((item: string) => item !== name)])
        } else {
            setArgs([...args, name])
        }
    };

    const toggleDetails = async (account: string, agent: string, hasCod: boolean | undefined) => {
        const position = details?.findIndex(item => item?.account === account && item?.agent === agent);
        let newDetails;
        if (position !== -1) {
            newDetails = details?.slice();
            newDetails?.splice(position, 1);
        } else {
            newDetails = [...details, {account, agent}];
        }

        setDetails(newDetails);
        if (position !== -1) {
            return;
        }

        fetchDetails(account, agent, hasCod);
    };

    const scopedSlots = {
        account: (item: PendingPrinting) => {

            return <td>{item?.accountName ?? ""}</td>
        },
        agent: (item: PendingPrinting) => {
            return <td>{item?.agent ?? ""}</td>
        },
        numberOfShipments: (item: PendingPrinting) => {
            return <td>{item?.numberOfShipments}</td>
        },
        hasCOD: (item: PendingPrinting) => {
            return <td>{item?.hasCod == true ? "Yes" : item?.hasCod == false ? "No" : ""}</td>
        },
        viewDetails: (item: PendingPrinting, index: number) => {
            return <td className={""}>
                <CTooltip content="Sku details">
                    <CContainer>
                        <FontAwesomeIcon icon={faArrowCircleDown} size="lg"
                                         onClick={() => {
                                             toggleDetails(item.account, item.agent, item.hasCod)
                                         }}
                                         color={"#FF8800"}
                                         style={{cursor: "pointer"}}
                        />
                    </CContainer>
                </CTooltip>
            </td>
        },
        generate: (item: PendingPrinting, index: number) => {
            return <td>
                <CTooltip content="Generate">
                    <CIcon
                        content={freeSet.cilDescription}
                        onClick={() => {
                            handelGenerate({
                                account: item?.account,
                                hasCod: !!args?.includes("Cod") ? item?.hasCod : undefined,
                                agent: item?.agent,
                            })
                        }}
                        style={{cursor: "pointer"}}
                    />
                </CTooltip>

                <CInputCheckbox
                    style={{marginLeft: 4, marginTop: 2}}
                    checked={Boolean(selectedRows?.find(item => item?.index == index))}
                    onChange={() => {
                        if (Boolean(selectedRows?.find(item => item?.index == index))) {
                            setSelectedRows(selectedRows?.filter((item => item?.index !== index)) || [])
                        } else {
                            setSelectedRows([...selectedRows || [], {...item, index}])
                        }
                    }}
                />
            </td>
        },
        "details":
            (item: any, index: number) => {
                return (
                    <CCollapse
                        show={details?.some(detail => detail?.account === item?.account && detail?.agent === item?.agent)}>
                        <PendingDetailsInnerTable
                            shipments={detailsData[`${item.account}-${item.agent}`] ?? []}
                            loading={detailsLoading[`${item.account}-${item.agent}`]}
                            handleGenerate={(e: BatchShipmentGenerateRequest) => {
                                handelGenerate(e, item)

                            }}
                            account={item?.account}
                            hasCod={!!args?.includes("Cod") ? item?.hasCod : undefined}
                            agent={item?.agent}
                        />
                    </CCollapse>
                )
            }
    }
    return (
        <>
            <fieldset className="b-fieldset p-2 text-primary">
                <CRow style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                    <CCol className={"text-right mr-3 mb-1"}>
                        {(selectedRows?.length && selectedRows?.length > 0) ?
                            <ActionButton
                                text={`Generate`}
                                onClick={() => handleBulkGenerate()}/> : <></>}
                    </CCol>
                </CRow>
                <legend className="b-legend" style={{width: "120px"}}>
                    <h5>Not Generated</h5>
                </legend>
                <CContainer>
                    <CDataTable
                        loading={loading}
                        size="sm"
                        hover
                        columnHeaderSlot={{
                            agent:
                                <><CInputCheckbox
                                    style={{top: "0"}}
                                    checked={args.includes("Agent")}
                                    onChange={() => handleArgs("Agent")}
                                />Agent</>,
                            hasCOD:
                                <><CInputCheckbox
                                    style={{top: "0"}}
                                    checked={args.includes("Cod")}
                                    onChange={() => handleArgs("Cod")}
                                />Has COD</>,

                        }}
                        scopedSlots={scopedSlots ?? {}}
                        items={Array.isArray(pendingShipmentBatch) ? pendingShipmentBatch : []}
                        fields={[
                            {key: "viewDetails", label: "", _style: {width: "5%"}},
                            {key: "account", label: "Account", _style: {width: "25%"}},
                            {key: "hasCOD", label: "Has COD", _style: {width: "25%"}},
                            {key: "agent", label: "Agent", _style: {width: "25%"}},
                            {key: "numberOfShipments", label: "Number of shipments", _style: {width: "15%"}},
                            {key: "generate", label: "Actions", _style: {width: "5%"}}
                        ]}
                    />
                </CContainer>
            </fieldset>

            {showConfirmGenerate && <ConfirmModal
                onClose={() => {
                    setShowConfirmGenerate(false);
                    setBatchShipmentGenerateRequest(undefined);
                }} title={`Generate shipments ?`} body={"Are you sure you want to generate these shipments"}
                onSubmit={() => {
                    setShowConfirmGenerate(false);
                    OnConfirmGenerate();
                }}/>
            }

            {showConfirmBulkPrint && <ConfirmModal
                onClose={() => {
                    setShowConfirmBulkPrint(false);
                    setBulkGenerateRequest(undefined);
                }} title={`Generate shipments ?`} body={"Are you sure you want to generate these shipments"}
                onSubmit={() => {
                    setShowConfirmBulkPrint(false);
                    OnConfirmBulkGenerate();
                }}/>
            }
        </>
    )
}

export default PendingTable