import {CButton, CCol, CModal, CModalBody, CModalFooter, CModalHeader, CRow} from "@coreui/react";
import {useFormik} from "formik";
import {CreateInvoiceRequest} from "../../../../models/accounting";
import SWInput from "../../../SharedComponents/SWInput";
import SWDateAndTimePicker from "../../../SharedComponents/SWDateAndTimePicker";
import {useEffect, useState} from "react";
import RatesClient from "../../../../clients/ratesClient";
import SWSelect from "../../../SharedComponents/SWSelect";

interface IProps {
    onClose: () => void
    onsubmit: (body: CreateInvoiceRequest) => void
}

const CreateInvoiceModal = (props: IProps) => {
    const ratesClient = new RatesClient();
    const [accountLookups, setAccountLookups] = useState<{[k: string]: string}>({})
    
    useEffect(() => {
        ratesClient.LookupCustomers().then(v => setAccountLookups(v))
    }, [])
    
    const {values, handleChange, setFieldValue, handleSubmit} = useFormik<CreateInvoiceRequest>({
        initialValues: {
            account: "",
            invoiceDate: new Date(),
            currency: "EUR"
        },
        onSubmit: values => props.onsubmit(values)
    })
    return (
        <CModal show={true} onClose={props.onClose} centered>
            <CModalHeader>Create Invoice</CModalHeader>
            <CModalBody>
                <CRow>
                    <CCol>
                        <SWSelect values={accountLookups} value={values.account} label={"Account"} onChange={v => setFieldValue("account", v)}/>
                    </CCol>
                    <CCol>
                        <SWDateAndTimePicker value={values.invoiceDate} label={"Invoice Date"} handleOnChange={v => setFieldValue("invoiceDate", v)} />
                    </CCol>
                </CRow>
            </CModalBody>
            <CModalFooter>
                <CCol>
                    <CButton color={"danger"} variant={"outline"} size={"sm"} onClick={props.onClose}>Cancel</CButton>
                </CCol>
                <CCol className={"text-right"}>
                    <CButton color={"primary"} variant={"outline"} size={"sm"} onClick={handleSubmit}>Submit</CButton>
                </CCol>
            </CModalFooter>
        </CModal>
    )
}

export default CreateInvoiceModal