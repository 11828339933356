import {CSpinner, CDataTable, CLink} from "@coreui/react";
import React, {useEffect, useState} from "react";
import {AppModel} from "../../../../models/app";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye} from '@fortawesome/free-solid-svg-icons';
import {IAddAccount} from "../../../../models/shipment";

interface Props {
    onRowClick?: (e: IAddAccount) => void;
    className?: string;
}

export const countryNames = new Map<string, string>([
    ["AL", "Albania"],
    ["AD", "Andorra"],
    ["AM", "Armenia"],
    ["AT", "Austria"],
    ["AZ", "Azerbaijan"],
    ["BY", "Belarus"],
    ["BE", "Belgium"],
    ["BA", "Bosnia and Herzegovina"],
    ["BG", "Bulgaria"],
    ["HR", "Croatia"],
    ["CY", "Cyprus"],
    ["CZ", "Czech Republic"],
    ["DK", "Denmark"],
    ["EE", "Estonia"],
    ["FI", "Finland"],
    ["FR", "France"],
    ["GE", "Georgia"],
    ["DE", "Germany"],
    ["GR", "Greece"],
    ["HU", "Hungary"],
    ["IS", "Iceland"],
    ["IE", "Ireland"],
    ["IT", "Italy"],
    ["KZ", "Kazakhstan"],
    ["XK", "Kosovo"],
    ["LV", "Latvia"],
    ["LI", "Liechtenstein"],
    ["LT", "Lithuania"],
    ["LU", "Luxembourg"],
    ["MT", "Malta"],
    ["MD", "Moldova"],
    ["MC", "Monaco"],
    ["ME", "Montenegro"],
    ["NL", "Netherlands"],
    ["MK", "North Macedonia"],
    ["NO", "Norway"],
    ["PL", "Poland"],
    ["PT", "Portugal"],
    ["RO", "Romania"],
    ["RU", "Russia"],
    ["SM", "San Marino"],
    ["RS", "Serbia"],
    ["SK", "Slovakia"],
    ["SI", "Slovenia"],
    ["ES", "Spain"],
    ["SE", "Sweden"],
    ["CH", "Switzerland"],
    ["TR", "Turkey"],
    ["UA", "Ukraine"],
    ["GB", "United Kingdom"],
    ["VA", "Vatican City"]
]);

const AccountSearchResult = (props: Props) => {
    const scopedSlots = {
        links: (item: IAddAccount) => (
            <td>
                <CLink to={`/financial/account/view/${item.id}`} className="card-header-action">
                    <FontAwesomeIcon icon={faEye}/>
                </CLink>
            </td>
        ),
        country: (item: IAddAccount) => (
            <td>
                {countryNames.get(item.country)?? '-npm'}
            </td>
        ),
        accountType: (item: IAddAccount) => (
            <td>
                {item.accountType == 1 ? "Customer" : item.accountType == 2 ? "Agent" : "Both"}
            </td>
        ),

        phone: (item: IAddAccount) => (
            <td>
                {item.phone ?? '-'}
            </td>
        ),
        email: (item: IAddAccount) => (
            <td>
                {item.email ?? '-'}
            </td>
        )
    };

    const {className, onRowClick} = props;


    const accountSearchResult = useSelector((state: AppModel) => {
        const {result, isLoading, page, filterGroup, count, limit} = state.account.accountSearch;
        return {
            result,
            isLoading,
            page,
            filterGroup,
            maxFound: count,
            maxPage: Math.ceil(count! / limit!),
            limit
        };
    });


    const {result} = accountSearchResult;

    return (
        <div className={className}>
            {accountSearchResult.isLoading ? (
                <CSpinner
                    className="mx-auto d-block my-5"
                    color="primary"
                    style={{width: "5em", height: "5em"}}
                />
            ) : (
                <>
                    <div className="small">

                        <CDataTable
                            size="sm"
                            hover
                            items={((result ?? []).length) > 0 ? result : []}
                            pagination
                            itemsPerPage={20}
                            scopedSlots={scopedSlots}
                            fields={[
                                {key: "links", label: "", _style: {width: '1%'}},
                                "name",
                                "id",
                                "email",
                                "phone",
                                "country",
                                "accountType",
                            ]}
                        />

                    </div>
                </>
            )}
        </div>
    );
};

export default AccountSearchResult;
