import React, { Fragment, useEffect, useState } from "react";
import {CBadge, CButton, CContainer, CDataTable,CTooltip} from "@coreui/react";
import SWInput from "../../SharedComponents/SWInput";
import SWDateAndTimePicker from "../../SharedComponents/SWDateAndTimePicker";
import { ListFscResponse } from "../../../models/retes";
import { useDispatch } from "react-redux";
import { SetIsLoading } from "../../../store/actions/ui";
import RatesClient from "../../../clients/ratesClient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { ShowNotification } from "../../../store/actions/auth";



const FuelSurcharges = () => {
    const ratesClient = new RatesClient();
    const dispatch = useDispatch();
    const [data, setData] = useState<ListFscResponse[]>([]);
    const [priceValidationErrors, setPriceValidationErrors] = useState<{ [key: number]: boolean }>({});
    const [validFromValidationErrors, setValidFromValidationErrors] = useState<{ [key: number]: boolean }>({});

    const fetchData = async () => {
        let res: Array<ListFscResponse> = []
        dispatch(SetIsLoading(true))
        res = (await ratesClient.ListFsc())
        if (res) {
            setData(res)
        }
        dispatch(SetIsLoading(false))
    }
    useEffect(() => {
        fetchData()
    }, []);
    const handleApply = async (item: ListFscResponse, index: number) => {
        const isPriceValid = Boolean(item?.price) || item?.price === 0;
        const isValidFromValid = Boolean(item?.validFrom);
        setPriceValidationErrors((prevState) => ({
            ...prevState,
            [index]: !isPriceValid,
        }));
        setValidFromValidationErrors((prevState) => ({
            ...prevState,
            [index]: !isValidFromValid,
        }));
        if (isPriceValid && isValidFromValid) {
            dispatch(SetIsLoading(true));
            const res = await ratesClient.UpdateAgentFsc(item?.rateCardId, {
                validFrom: item?.validFrom,
                price: item?.price!,
            });
            if (res?.succeeded) {
                dispatch(ShowNotification("Success", "Successfully Applied!"));
            } else {
                dispatch(ShowNotification("Error", res?.error, true));
            }
            fetchData();
            dispatch(SetIsLoading(false));
        };
    };
    const handleInputChange = (index: number, key: keyof ListFscResponse, value: string) => {
        setData((prevState) => {
            const newData = [...(prevState || [])];
            newData[index] = {
                ...newData[index],
                [key]: key === 'price' ? Number(value) : value,
            };
            return newData;
        });
    };

    const isSameMonth = (data: string): boolean => {
        const givenDate = new Date(data);
        const currentDate = new Date();
        return (
            givenDate.getMonth() === currentDate.getMonth() &&
            givenDate.getFullYear() === currentDate.getFullYear()
        );
    };
    return (
        <Fragment>
            <CContainer className="bg-white p-4 text-primary small ">
                <h5 style={{ color: "gray" }}>FSC</h5>
                <div className={"py-4"}></div>
                <CDataTable
                    size="sm"
                    hover
                    scopedSlots={{
                        'fscUrl': (item: ListFscResponse, index: number) => (
                            Boolean(item?.fscUrl) ?
                                <td>
                                    <CTooltip content={item.fscUrl}  >
                                        <FontAwesomeIcon
                                            icon={faExternalLink}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => window.open(item?.fscUrl, '_blank',)}

                                        />
                                    </CTooltip>

                                </td>
                                : <td></td>
                        ),
                        'price': (item: ListFscResponse, index: number) => (
                            <td width={100}>
                                <SWInput
                                    name={`price-input-${index}`}
                                    value={item.price !== null ? item.price.toString() : ''}
                                    onChange={(e) => handleInputChange(index, 'price', e)}
                                    type="number"
                                    validationErrors={priceValidationErrors[index] && 'Required' || ''}
                                />
                            </td>
                        ),
                        'validFrom': (item: ListFscResponse, index: number) => (
                            <td width={150}>
                                <SWDateAndTimePicker
                                    name={`validFrom-input-${index}`}
                                    value={item.validFrom !== null ? item.validFrom.toString() : ''}
                                    handleOnChange={(e) => handleInputChange(index, 'validFrom', e)}
                                    validationErrors={validFromValidationErrors[index] && 'Required' || ''}
                                />
                            </td>

                        ),
                        'apply': (item: ListFscResponse, index: number) => (
                            <td>
                                <CButton
                                    size="sm"
                                    style={{ fontSize: "10px" }}
                                    className="mr-2"
                                    variant="outline"
                                    color="primary"
                                    onClick={() => handleApply(item, index)}
                                >
                                    Apply
                                </CButton>
                            </td>
                        ),
                        'status': (item: ListFscResponse, index: number) => {
                            if (Boolean(!item?.validFrom) || (item?.validFrom && !isSameMonth(item?.validFrom))) {
                                return (
                                    <td>
                                        <CBadge
                                            style={{ backgroundColor: "#FF8800", marginLeft: 6 }}
                                            color={"info"}>
                                            Outdated
                                        </CBadge>
                                    </td>
                                )
                            } else {
                                return <td />
                            }
                        },
                    }}
                    items={data || []}
                    fields={[
                        { key: "fscUrl", label: "", _style: { width: "2%" } },
                        { key: "status", label: "", _style: { width: "6%" } },
                        { key: "agent", label: "Agent" },
                        { key: "service", label: "Service" },
                        { key: "rateCardId", label: "Card" },
                        { key: "price", label: "Price" },
                        { key: "validFrom", label: "Apply Date" },
                        { key: "apply", label: "Apply" },
                    ]}
                />
            </CContainer>
        </Fragment >
    )
}

export default FuelSurcharges
