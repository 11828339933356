import React from "react";
import { freeSet } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CDataTable, CTooltip } from "@coreui/react";
import { Zone } from "../../../../../models/retes";


type Props = {
    zones?: Zone[]
    onRemove?: (arg: Zone) => void;
    onEdit?: (arg: Zone) => void;
}

const ZoneTable = ({ zones,onRemove,onEdit }: Props) => {
    return (
        <CDataTable
            size="sm"
            hover
            scopedSlots={{
                fromPostCode: (e: Zone) => {
                    return <td>
                        {e.fromPostCode ?? "-"}
                    </td>
                },
                toPostCode: (e: Zone) => {
                    return <td>
                        {e.toPostCode ?? "-"}
                    </td>
                },
                value: (e: Zone) => {
                    return <td>
                        {e.value ?? "-"}
                    </td>
                },
                actions: (e: Zone) => {
                    return <td className={"text-center"}>
                        <CTooltip content={"Edit"}>
                            <CIcon content={freeSet.cilPencil}
                                style={{ cursor: "pointer", marginRight: "5px" }}
                                onClick={() => {
                                    onEdit?.(e)
                                }} />
                        </CTooltip>
                        <CTooltip content={"Delete"}>
                            <CIcon style={{ cursor: "pointer" }} content={freeSet.cilTrash} onClick={() => 
                                onRemove?.(e)
                                } />
                        </CTooltip>
                    </td>
                },

            }}
            items={zones ?? []}
            fields={[
                { key: "code", label: "Zone" },
                { key: "type", label: "Type" },
                { key: "country", label: "Country" },
                { key: "fromPostCode", label: "From Post Code" },
                { key: "toPostCode", label: "To Post Code" },
                { key: "value", label: "Value" },
                { key: "actions", label: "Actions", _style: { textAlign: "center" } }
            ]}
        />

    )
}

export default ZoneTable;