import {CButton, CContainer, CRow, CTooltip} from "@coreui/react";
import React, { useEffect } from "react";
import ShipClient from "../../../clients/shipClient";
import {useHistory, useLocation} from "react-router";
import { queryParamsToFilters } from "../../../utils/filterUtils";
import { useSearchOnFilterGroup } from "../../../hooks/helperHooks";

import { useDispatch, useSelector } from "react-redux";
import { AppModel } from "../../../models/app";
import {
  SetContainerTypeResult,
  SetContainerTypesFilterGroupFilters,
  SetContainerTypeIsLoading,
  SetContainerTypePage,
  SetContainerTypesFilterGroup,
} from "../../../store/actions/containertypes";

import SearchForm from "./components/SearchForm";
import SearchResult from "./components/SearchResult";
import {Link} from "react-router-dom";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";

const SearchContainerTypes = () => {
  const dispatch = useDispatch();
  const shipClient = new ShipClient();

  useSearchOnFilterGroup({
      updateFilterGroup: (fg) => dispatch(SetContainerTypesFilterGroup(fg)),
      useFilterGroupSelector: () =>
          useSelector(
              (state: AppModel) =>
                  state.containertype.containertypesSearch.filterGroup!
          ),
      searchFunction: shipClient.GetContainerTypes.bind(shipClient) as any,

      setIsLoading: (loading) => dispatch(SetContainerTypeIsLoading(loading)),
      setResult: (result) =>
          dispatch(
              SetContainerTypeResult(result.page!, result.count!, result.result!)
          ),
  });

  const loc = useLocation();
  const hist = useHistory()

  const searchContainerTypes = () => {
    const fg = queryParamsToFilters(loc.search);
    dispatch(SetContainerTypesFilterGroup(fg));
  };

  useEffect(() => {
    searchContainerTypes();
  }, [loc.search]);

  return (
    <CContainer className="bg-white p-4 text-primary">
      <SearchForm />
        <CRow className={"justify-content-start text-primary p-1"}>
            <CTooltip content={"Add new container type"}>
                <CIcon
                content={freeSet.cilPlus}
                onClick={() => hist.push("/settings/containertypes/create")}
                className="ml-3 mt-1"
                style={{
                height: "1.5em",
                width: "1.5em",
                cursor: "pointer"
                }}
                />
            </CTooltip>
        </CRow>
      <SearchResult />
    </CContainer>
  );
};

export default SearchContainerTypes;
