import { ShipmentAppModel } from "../../models/app";

const shipmentState: ShipmentAppModel = {
  shipmentsSearch: {
    count: 0,
    isLoading: false,
    limit: 20,
    page: 0,
    result: [],
    toggleView: false,
    missingShipments: undefined,
    filterGroup: {
      page: 0,
      limit: 10,
      filters: [],
    },
  },
  shipmentReprintSearch:{
    count: 0,
    isLoading: false,
    limit: 20,
    page: 0,
    result: [],
    toggleView: false,
    missingShipments: undefined,
    filterGroup: {
      page: 0,
      limit: 10,
      filters: [],
    },
  },
  createShipmentData:undefined
};

export default shipmentState;
