import { CCol, CForm, CRow, CContainer } from "@coreui/react";
import { Form, Input, Submit } from "../../SharedComponents/SWForm";
import React from "react";
import ShipClient from "../../../clients/shipClient";
import validator from "validator";
import SortingRuleInputs from "./components/SortingRuleInputs";
import { useHistory } from "react-router";
import ActionButton from "../../SharedComponents/ActionButton";

interface Props {}
const CreateSortingRule = (props: Props) => {
  const shipClient = new ShipClient();
  const hist = useHistory();

  return (
    <CContainer className="bg-white p-3 text-primary">
      <h5 style={{color:"gray"}}>Create Sorting Rule</h5>
      <Form
        className="mt-5"
        onSubmit={async (body) =>
          shipClient.CreateSortingRule(body).then((v) => {
            hist.push("/settings/sortingrules/search");
          })
        }
      >
        <SortingRuleInputs />
        <CRow>
          <CCol>
              <ActionButton
                  text={"Create"}
              />
          </CCol>
        </CRow>
      </Form>
    </CContainer>
  );
};

export default CreateSortingRule;
