import {CCol, CContainer, CRow,} from "@coreui/react";

import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ShipClient from "../../../clients/shipClient";
import LookupsClient from "../../../clients/lookupsClient";
import {ShowNotification} from "../../../store/actions/auth";
import {ShipmentAddTraceResult} from "../../../models/shipment";
import {freeSet} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import BulkTraceResultModal from "../Shipments/modals/BulkTraceResultModal";
import SWInput from "../../SharedComponents/SWInput";
import SWSelect from "../../SharedComponents/SWSelect";
import SWDateAndTimePicker from "../../SharedComponents/SWDateAndTimePicker";
import SWTextArea from "../../SharedComponents/SWTextArea";
import {getLocalDateTime} from "../../../utils/dateUtil";
import ActionButton from "../../SharedComponents/ActionButton";
import { SetIsLoading } from "../../../store/actions/ui";
import { AppModel } from "../../../models/app";

export const reasonLookups = {
    "Refused": "Refused",
    "Not Claimed": "Not Claimed",
    "Bad Address": "Bad Address",
    "Damaged": "Damaged",
    "Customer Claim": "Customer Claim",
    "Other": "Other"
}


const TrackingEvents = () => {
    const shipClient = new ShipClient();
    const lookupsClient = new LookupsClient();
    const dispatch = useDispatch();
    const isLoading = useSelector((state: AppModel) => state.app.isLoading);

    const [tracking, setTracking] = useState<{ [k: string]: string }>({});

    const [selectedCode, setSelectedCode] = useState<string | undefined>(undefined)
    const [shipments, setShipments] = useState("");
    const [actionDate, setActionDate] = useState(getLocalDateTime());

    const [comment, setComment] = useState("");
    const [comment2, setComment2] = useState("");

    const [shipmentAddTraceResults, setShipmentAddTraceResults] = useState<ShipmentAddTraceResult>({
        details: [],
        totalAdded: 0
    });
    const [showResult, setShowResult] = useState<boolean>(false);

    const [disableSubmit,setDisableSubmit] = useState<boolean>(false)


    useEffect(() => {
        lookupsClient.getTraces().then((v) => setTracking(v));
    }, []);

    const onSubmit = async () => {
       setDisableSubmit(true)
       dispatch(SetIsLoading(true))
        const apiResult = await shipClient.AddBulkTracesByBarcode(shipments.split("\n"), {
            actionDate: actionDate,
            code: selectedCode ?? "",
            comment1: comment2 ? `${comment}-${comment2}` : comment,
        });
        setShipmentAddTraceResults(apiResult);
        if (apiResult.totalAdded > 0){
            dispatch(ShowNotification("Success ", `${apiResult.totalAdded} Trace(s) added Successfully`, false));
            setShipments("");
        }
        const notFound = apiResult.details.filter(s => s.shipmentNotFound).map(s => s.barcode);
        if (notFound.length > 0){
            setShipments(notFound.join("\r\n"));
        }
        setSelectedCode(undefined);
        setActionDate((new Date(Date.now())).toISOString());
        setComment("");
        setDisableSubmit(false)
    }
    const isDisabled = !shipments || !selectedCode;

    return (
        <Fragment>
            <CContainer className="bg-white p-4 text-primary small">
                <h5 style={{color: "gray"}}>Bulk Traces</h5>
                {shipmentAddTraceResults.details.length > 0 &&
                    <CRow>
                        <CCol>
                            <CIcon
                                content={freeSet.cilWarning}
                                size={"xl"}
                                style={{marginLeft: "10%", color: "red", cursor: "pointer"}}
                                onClick={() => setShowResult(true)}
                            />
                        </CCol>
                    </CRow>
                }

                <CRow>
                    <CCol>
                        <SWTextArea rows={10}
                                    label={"Shipment numbers"} name={"ShipmentNumbers"} value={shipments}
                                    onChange={(s) => setShipments(s)}/>

                    </CCol>
                </CRow>
                <CRow>
                    <CCol>
                        <SWSelect

                            label={"Trace type"} values={tracking}
                            value={selectedCode}
                            name="warehouse" onChange={(s) => setSelectedCode(s)}/>

                    </CCol>
                    {selectedCode == "SHDL201" ?
                        <>
                            <CCol>
                                <SWSelect values={reasonLookups} label={"Comment"} value={comment ?? ""} onChange={v => {
                                    setComment(v ?? "")
                                    setComment2("")
                                }} />
                            </CCol>
                                <CCol style={{marginTop:"24px"}}>
                                    <SWInput value={comment2} onChange={v => setComment2(v)} />
                                </CCol>
                        </> :
                        <CCol>
                            <SWInput label="Comment" value={comment ?? ""} onChange={(s) => setComment(s)}/>
                        </CCol>}
                    <CCol>
                        <SWDateAndTimePicker showTimeInput
                                             label={"Action Date"} name={"actionDate"} value={actionDate}
                                             handleOnChange={(e) => setActionDate(e)}/>

                    </CCol>
                    <CCol md={1}>
                        <div style={{marginTop: "32%", marginLeft: "20%"}}>
                            <ActionButton
                                text={"Submit"}
                                disabled={disableSubmit || isDisabled}
                                onClick={onSubmit}
                            />
                        </div>
                    </CCol>
                </CRow>

            </CContainer>
            {showResult &&
                <BulkTraceResultModal result={shipmentAddTraceResults.details}
                                      handleClose={() => setShowResult(false)}/>}
        </Fragment>
    );
};

export default TrackingEvents;
