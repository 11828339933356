import React, {useState} from 'react';
import {
    CButton,
    CCol,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CRow
} from "@coreui/react";
import ActionButton from "../../../SharedComponents/ActionButton";


interface IProps{
    onClose:()=>any
    onSubmit:() => any
}

const Component = (props:IProps) => {
    const { onClose,onSubmit} = props;
    const [disabled, setDisabled] = useState(false);
  const onClick = () => {
    setDisabled(true);
    onSubmit();
    
  }

    return (
        <CModal
            show={true}
            onClose={onClose}
        >
            <CModalHeader>
                <h5>Confirm</h5>
            </CModalHeader>
            <CModalBody>
                <p className={"mb-2"}>Are you sure you want reverse to unpaid</p>
              
            </CModalBody>
            <CModalFooter>
                <CRow className="justify-content-between w-100">
                    <CCol className="text-left">
                        <CButton
                            variant="outline"
                            color="danger"
                            onClick={onClose}
                        >
                            Cancel
                        </CButton>
                    </CCol>
                    <CCol className="text-right">
                        <ActionButton
                            text={"Yes"}
                            disabled={disabled}
                            onClick={onClick}
                        />
                    </CCol>
                </CRow>
            </CModalFooter>
        </CModal>
    )
}

export default Component;