import React, { useEffect, useState } from "react";
import { CButton, CButtonGroup, CCol, CContainer, CInputCheckbox, CLabel, CRow } from "@coreui/react";
import { useFormik } from "formik";
import * as yup from "yup"
import SWSelect from "../../../../SharedComponents/SWSelect";
import { useDispatch } from "react-redux";
import { SetIsLoading } from "../../../../../store/actions/ui";
import RatesClient from "../../../../../clients/ratesClient";
import SWTextArea from "../../../../SharedComponents/SWTextArea";
import ShipClient from "../../../../../clients/shipClient";
import { CheckReprintShipmentRes } from "../../../../../models/shipment";
import ConfirmModal from "../../../../SharedComponents/ConfirmModal";
interface IProps {
    refreshPrintedShipmentTable: () => void
}

const ShipmentReprintForm = ({ refreshPrintedShipmentTable }: IProps) => {
    const [accountLookup, setAccountLookup] = useState<{ [k: string]: string }>({})
    const dispatch = useDispatch();
    const shipClient = new ShipClient();
    const ratesClient = new RatesClient();
    const [checkShipmentData, setCheckShipmentData] = useState<CheckReprintShipmentRes | null>(null)
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
    const [searchBy, setSearchBy] = useState<string | undefined>("1")
    const [mergeLabelWithPackingList, setMergeLabelWithPackingList] = useState<boolean>(true);
    const { values, handleSubmit, setFieldValue, resetForm, errors, touched } = useFormik<{ numbers: string, references: string, account: string }>({
        initialValues: {
            numbers: "",
            references: "",
            account: ""
        },
        onSubmit: async (values) => {
            if (!!values?.numbers?.trim() || (!!values?.references?.trim() && !!values?.account)) {
                dispatch(SetIsLoading(true))
                const parsedValues = {
                    ...values,
                    numbers: values?.numbers?.trim()?.split('\n').map((s: string) => s.trim()),
                    references: values?.references?.trim()?.split('\n').map((s: string) => s.trim())
                }
                const res = await shipClient.CheckShipmentAlreadyPrinted(parsedValues)
                if (res) {
                    setShowConfirmModal(true)
                    setCheckShipmentData({
                        type: res?.type,
                        alreadPrinted: res?.alreadPrinted,
                        notFoundShipments: res?.notFoundShipments
                    })
                }
                dispatch(SetIsLoading(false))
            }

        },
        validationSchema: yup.object({})
    })
    const reprintShipments = async () => {
        const parsedValues = {
            ...values,
            numbers: values?.numbers?.trim()?.split('\n')?.filter((e: string) => !checkShipmentData?.notFoundShipments?.includes(e)),
            references: values?.references?.trim()?.split('\n')?.filter((e: string) => !checkShipmentData?.notFoundShipments?.includes(e)),
        }
        let res = await shipClient.ReprintShipments({ ...parsedValues, mergeLabelWithPackingList })
        if (res) {
            setShowConfirmModal(false);
            searchBy == "2" ? setFieldValue("references", checkShipmentData?.notFoundShipments?.join('\n')) : setFieldValue("numbers", checkShipmentData?.notFoundShipments?.join('\n'))
            refreshPrintedShipmentTable()
        }
        setMergeLabelWithPackingList(true);
    }

    useEffect(() => {
        getAccounts()
    }, []);

    const getAccounts = async () => {
        let accounts = await ratesClient.LookupCustomers()
        setAccountLookup(accounts)
    }

    const handleClear = async () => {
        resetForm()
        setSearchBy(undefined)
    }

    return (
        <>
            {
                showConfirmModal && <ConfirmModal onClose={() => {
                    setShowConfirmModal(false)
                    setCheckShipmentData(null)
                    setMergeLabelWithPackingList(true);
                }} onSubmit={() => reprintShipments()}
                    title={'Reprint'}
                    body={""}
                    children={
                        <div>
                            <CCol md={12} className={"mt-1"}>
                                <h6>{'Are you sure you want to continue?'}</h6>
                            </CCol>

                            <CCol md={12} className={"ml-3"}>
                                <CInputCheckbox
                                    name={"mergeLabelWithPackingList"}
                                    checked={mergeLabelWithPackingList}
                                    onChange={(e) => {
                                        setMergeLabelWithPackingList(!mergeLabelWithPackingList)
                                    }}
                                />
                                <CLabel style={{ fontSize: "12px" }} className={"mt-1"}>Merge packing with label</CLabel>
                            </CCol>

                            {checkShipmentData?.notFoundShipments?.length! > 0 ?
                                <div>
                                    <hr />
                                    <CCol md={12} className={"mt-1"}>
                                        <h6>{'Not found shipments:'}</h6>
                                    </CCol>
                                    {checkShipmentData?.notFoundShipments?.map((e) => {
                                        return (
                                            <CCol md={12} className={"mt-1"}>
                                                <h6>{e}</h6>
                                            </CCol>
                                        )
                                    })}

                                </div> : <></>
                            }
                        </div>

                    }
                />
            }
            <fieldset className="b-fieldset p-2 text-primary">
                <legend className="b-legend" style={{ width: "70px" }}>
                    <h5>Reprint</h5>
                </legend>

                <CContainer className="form-group mb-0">
                    <CRow>
                        <CCol className="col-sm-3">
                            <SWSelect
                                values={[
                                    {
                                        label: "Shipment numbers", value: "1"
                                    },
                                    {
                                        label: "Reference numbers", value: "2"
                                    }]}
                                value={searchBy} name={"searchBy"}
                                onChange={e => {
                                    resetForm()
                                    setSearchBy(e)
                                }}
                                label={"Search by"} />
                            {searchBy == "2" &&
                                <SWSelect
                                    values={accountLookup} value={values?.account} name={"account"}
                                    isClearable={true}
                                    onChange={e => setFieldValue("account", e)}
                                    label={"Account"} />}
                        </CCol>

                        {searchBy == "2" &&
                            <>
                                <CCol className="col-sm-3">
                                    <SWTextArea rows={8}
                                        label={"Reference numbers"}
                                        name={"references"}
                                        value={values?.references ?? ""}
                                        onChange={(e) => setFieldValue("references", e)}
                                        type={"text"}
                                        validationErrors={touched?.references && errors?.references?.toString() || ""}
                                    />
                                </CCol>
                            </>
                        }
                        {searchBy == "1" &&

                            <CCol className="col-sm-3">
                                <SWTextArea rows={8}
                                    label={"Shipment numbers"}
                                    name={"numbers"}
                                    value={values?.numbers ?? ""}
                                    onChange={(e) => {
                                        setFieldValue("numbers", e)
                                    }}
                                    type={"text"}
                                    validationErrors={touched?.numbers && errors?.numbers?.toString() || ""}
                                />
                            </CCol>

                        }
                    </CRow>
                </CContainer>
                {!!searchBy &&
                    <CRow style={{ marginTop: "10px" }}>
                        <CCol md={3}>
                            <CButton
                                className="ml-3"
                                size="sm"
                                style={{ fontSize: "10px" }}
                                variant="outline"
                                color="danger"
                                onClick={handleClear}
                            >Clear all</CButton>
                        </CCol>
                        <CCol className={"text-right"}>
                            <CButtonGroup className="mr-4">
                                <CButton
                                    size="sm"
                                    style={{ fontSize: "10px" }}
                                    className="mr-2"
                                    variant="outline"
                                    color="primary"
                                    onClick={handleSubmit}
                                >
                                    Reprint
                                </CButton>
                            </CButtonGroup>
                        </CCol>
                    </CRow>}
            </fieldset>
        </>

    )
}

export default ShipmentReprintForm