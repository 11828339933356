import {
  Action,
  AuthenticationAppModel,
  ContainerAppModel,
} from "../../models/app";
import initialState from "../initialStates/containerInitialState";
import {
  SET_CONTAINER_FILTER_GROUP,
  SET_CONTAINER_IS_LOADING,
  SET_CONTAINER_RESULT,
} from "../types";

const containerReducer = (
  state: ContainerAppModel = initialState,
  action: Action
): ContainerAppModel => {
  switch (action.type) {
    case SET_CONTAINER_FILTER_GROUP:
      return {
        ...state,
        containersSearch: {
          ...state.containersSearch,
          filterGroup: {
            ...state.containersSearch.filterGroup,
            ...action.payload!,
          },
        },
      };

    case SET_CONTAINER_IS_LOADING:
    case SET_CONTAINER_RESULT:
      return {
        ...state,
        containersSearch: {
          ...state.containersSearch,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default containerReducer;
