import React from "react";
import { Route, Link } from "react-router-dom";
import { JsxEmit } from "typescript";
import { RouteData } from "../routes";

export const routeDataToRoute = (routeData: RouteData) =>
  _routeDataToRoute(routeData);

const _routeDataToRoute = (
  routeData: RouteData,
  prefix?: string,
  routeElems?: JSX.Element[]
): JSX.Element[] => {
  const path = prefix ? `${prefix}${routeData.path}` : routeData.path;
  routeElems = routeElems ? routeElems : [];
  if (routeData.component) {
    routeElems.push(
      <Route
        key={path}
        exact
        path={path}
        component={routeData.component}
        //name={routeData.name}
      />
    );
  }
  if (routeData.subRoutes) {
    routeData.subRoutes.forEach((rd) =>
      _routeDataToRoute(rd, path, routeElems)
    );
  }
  return routeElems;
};
