import React from "react";
import { CModal, CModalBody, CModalFooter, CModalHeader } from "@coreui/react";
import { CreateShipment } from "../../../../models/shipment";
import AgentsRates from "./agentsRates";

interface Props {
    onClose: () => void
    data?: CreateShipment
    isSelling?: boolean
    isCost?: boolean
    title:string
}

const QuickPricingModal = (props: Props) => {
    const { onClose, data, isSelling, isCost,title } = props
    return (
        <CModal
            show={true}
            centered
            onClose={onClose}
            size={"lg"}
        >
            <CModalHeader>
                <h5>{title}</h5>
            </CModalHeader>
            <CModalBody />
            <AgentsRates
                isQuickPricing
                isSellingPricing={isSelling}
                isCostPricing={isCost}
                data={data}
            />
            <CModalFooter />
        </CModal >
    )
}

export default QuickPricingModal