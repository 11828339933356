import React, {useMemo} from "react";
import SyntaxHighlighter from 'react-syntax-highlighter';
import {xcode} from "react-syntax-highlighter/dist/cjs/styles/hljs";
import ReactJson from "react-json-view";

type Props = {
    name: string;
    data: string;
    type: 'xml' | 'json';
};


function removeInvalidXmlChars(input: string) {
    const invalidChars = /[\x00-\x08\x0B\x0C\x0E-\x1F]/g;
    return input.replace(invalidChars, '').replace(/&#xB;/g, '');
}

function isValidXML(xmlString: string) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "application/xml");
    return !xmlDoc.getElementsByTagName("parsererror").length;
}

const prettifyXml = (sourceXml: string) => {
    const xmlDoc = new DOMParser().parseFromString((sourceXml), 'application/xml');
    const xsltDoc = new DOMParser().parseFromString([
        '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
        '  <xsl:strip-space elements="*"/>',
        '  <xsl:template match="para[content-style][not(text())]">',
        '    <xsl:value-of select="normalize-space(.)"/>',
        '  </xsl:template>',
        '  <xsl:template match="node()|@*">',
        '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
        '  </xsl:template>',
        '  <xsl:output indent="yes"/>',
        '</xsl:stylesheet>',
    ].join('\n'), 'application/xml');

    const xsltProcessor = new XSLTProcessor();
    xsltProcessor.importStylesheet(xsltDoc);
    const resultDoc = xsltProcessor.transformToDocument(xmlDoc);
    const resultXml = new XMLSerializer().serializeToString(resultDoc);
    return resultXml;
};
const ViewXmlOrJson: React.FC<Props> = ({name, type, data}) => {

    const formattedData = useMemo(() => {

        if (type === "xml") {
            try {
                if (!isValidXML(data))
                    return data
                return prettifyXml(removeInvalidXmlChars(data))
            } catch (e) {
                return data;
            }
        }
        if (type === "json") {
            try {
                return JSON.parse(data)
            } catch (e) {
                //todo
                return data;
            }
        }

        return data;
    }, [type, data])

    return (
        <div style={{
            paddingLeft: '0.25rem',
            paddingRight: '0.25rem',
            minWidth: '1000px',
            overflowX: 'scroll',

        }}>

            <p style={{
                whiteSpace: "break-spaces",
                wordBreak: 'break-all',
            }}>
                {type === "xml" && formattedData &&
                    <SyntaxHighlighter wrapLines={true} customStyle={{fontSize:'15px'}} language="xml" showLineNumbers style={xcode}>
                        {formattedData}
                    </SyntaxHighlighter>
                }
                {type === "json" && typeof formattedData === "object" &&
                    //@ts-ignore
                    <ReactJson src={formattedData as any}/>
                }
                {type === "json" && typeof formattedData === "string" &&
                    <div>
                        {formattedData}
                    </div>
                }
            </p>
        </div>
    );
};

export default ViewXmlOrJson;
