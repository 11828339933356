import React, { useState } from "react";
import {
  CCol,
  CContainer,
  CRow,
  CLink,
  CFormGroup,
  CLabel,
  CInputGroup,
  CCard,
  CTooltip,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import SWInput from "../../../SharedComponents/SWInput";
import RatesClient from "../../../../clients/ratesClient";
import ShipClient from "../../../../clients/shipClient";
import { RemoteBlob } from "../../../../models/app";
import { SheetInfo } from "../../../../models/sheetInfo";
import ExcelMapper from "./ExcelMapper";
import { ShowNotification } from "../../../../store/actions/auth";
import { useDispatch } from "react-redux";
import ConfirmModal from "../../../SharedComponents/ConfirmModal";
import ActionButton from "../../../SharedComponents/ActionButton";
import _ from "lodash";

interface Props {
  keys: string[]
  cardId?: number
  importDataType?: "DestZone" | "OrgZone" | "RateEntry" | "Surcharges"
  getCard?: () => void
  onClose: () => void
}

const CreateImport = ({ keys, cardId, importDataType, getCard, onClose }: Props) => {
  const ratesClient = new RatesClient();
  const shipClient = new ShipClient();
  const dispatch = useDispatch();
  const [file, setFile] = useState<File | undefined>(undefined);
  const [map, setMap] = useState<{ [k: string]: string | null }>({});
  const [confirm, setConfirm] = useState(false);
  const [confirmReset, setConfirmReset] = useState(false);
  const [excelInfo, setExcelInfo] = useState<{
    blobInfo: RemoteBlob | null;
    sheets: SheetInfo[] | null;
  }>({
    blobInfo: null,
    sheets: null,
  });
  const { blobInfo, sheets } = excelInfo;
  const [fileLength, setFileLength] = useState<number | null>(0)

  const fileChosen = async (file?: File) => {
    setFile(file);
    if (!file) return;

    if (!file.name.endsWith("xlsx") && !file.name.endsWith("csv") && !file.name.endsWith("xls")) {
      dispatch(ShowNotification("Error", "File is not in the correct format", true))
      return
    }


    const response = await shipClient.UploadExcelFile(file);
    if (!response?.blobInfo) {
      dispatch(ShowNotification("Error", "Invalid excel file", true))
      return
    }
    setExcelInfo(response);
    const sheet = response?.sheets?.[0];
    const newMap: { [k: string]: string | null } = {};
    setFileLength(sheet?.rowCount - 1)
    const header = sheet?.values["0"].map((v) => v?.toString().trim() ?? "");
    for (const v in map) {
      if (header.includes(v)) {
        newMap[v] = map[v];
      }
    }
    setMap(newMap);
  };

  const reset = () => {
    setMap({});
    setFile(undefined);
    setFileLength(0);
    setConfirmReset(false);
    setExcelInfo({ sheets: null, blobInfo: null });
  };

  const handleImport = async () => {
    if (!map || !blobInfo) return;
    if (!cardId) {
      setConfirm(false);
      dispatch(ShowNotification("Error", "You must create a card first", true));
      return;
    }
    if (cardId && importDataType) {
      const result = await ratesClient.ImportRatesData({
        rateVersionId: cardId,
        map: map,
        fileUrl: blobInfo.name,
        importDataType: importDataType
      });
      if (!Boolean(result?.totalRows) || !Boolean(result?.importedRows)) {
        setConfirm(false);
        dispatch(ShowNotification("Error", "Error", true));
        return;
      }
      setConfirm(false);
      onClose()
      getCard?.()
    }
  };

  const handleMapChange = (key: string, selected?: string | null) => {
    if (selected && Object.values(map).includes(selected)) {
      const headerKey = Object.keys(map).find((key) => map[key] === selected);
      dispatch(
        ShowNotification("Error", `Already mapped to ${headerKey}`, true)
      );
      return;
    }

    setMap({
      ...map,
      [key]: selected == "notMapped" ? null : selected ?? null,
    });
  };


  return (
    <CModal
      closeOnBackdrop={false}
      show={true}
      centered
      onClose={() => onClose()}
      size={"xl"}
    >
      <CModalHeader closeButton>
        <CModalTitle>{"Import"}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer className="bg-white text-primary mt-3 ">
          {blobInfo ?
            <CRow>
              <CCol md={"4"}>
                <CFormGroup>
                  <CLabel>File</CLabel>
                  <CInputGroup>
                    <div className="form-control">
                      <CTooltip content={file?.name}>
                        <CLink
                          style={{ fontSize: "10px" }}
                          className="d-inline-block text-truncate w-100"
                          href={blobInfo.location}
                          target="_blank"
                        >
                          {file?.name}
                        </CLink>
                      </CTooltip>
                    </div>
                  </CInputGroup>
                </CFormGroup>
                <CLabel>
                  Items to be imported:  {fileLength}
                </CLabel>
              </CCol>
              <CCol md={"4"} className={"mt-3"}>
                <CRow className="d-flex p-2">
                  <ActionButton
                    color="danger"
                    text={"Reset"}
                    extraClass={'mr-2'}
                    disabled={
                      !sheets ||
                      !map}
                    onClick={() => setConfirmReset(true)}
                  />
                  <ActionButton
                    text={"Import"}
                    extraClass={'mr-2'}
                    disabled={
                      !sheets ||
                      !map}
                    onClick={() => setConfirm(true)}
                  />
                </CRow>
              </CCol>
            </CRow>
            :
            <CRow>
              <CCol md={"4"}>
                <SWInput
                  type="file"
                  label="File"
                  id={"input-file"}
                  onChangeFile={fileChosen}
                  valueFile={file}
                />
              </CCol>
            </CRow>
          }
          {sheets &&
            (
              <>
                <CRow>
                  <CCard className="text-center">
                    <ExcelMapper
                      onMapChange={handleMapChange}
                      sheet={sheets[0]}
                      map={map}
                      objectDifferentiator="Number"
                      keys={keys}
                    />
                  </CCard>
                </CRow>
              </>
            )}
          {confirm && (
            <ConfirmModal
              onClose={() => setConfirm(false)}
              onSubmit={handleImport}
              title="Are you sure you want to start import?"
              body="This action will start adding data"
            />
          )}
          {confirmReset && (
            <ConfirmModal
              onClose={() => setConfirmReset(false)}
              onSubmit={reset}
              title="Are you sure you want to start over"
              body="This action will reset the form"
            />
          )}
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default CreateImport;
