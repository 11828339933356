import React, { useState } from "react";
import { CButton, CCol, CLabel, CLink, CModal, CModalBody, CModalFooter, CModalHeader, CRow } from "@coreui/react";
import PdfViewer from "../../../SharedComponents/pdfViewer";
import ActionButton from "../../../SharedComponents/ActionButton";
import { ShipmentCreateResult } from "../../../../models/shipment";
import printJS from 'print-js'
import CameraIcon from "../../../../icons/camera";
import CaptureImageModal from "../../../SharedComponents/CaptureImageModal";
import { RemoteBlob } from "../../../../models/app";
import ShipClient from "../../../../clients/shipClient";
import { useDispatch } from "react-redux";
import { ShowNotification } from "../../../../store/actions/auth";
import SWSelect from "../../../SharedComponents/SWSelect";

interface Props {
    data?: ShipmentCreateResult
    navigateToNewShipment: (shipmentId: string) => void
}

const LabelModal = (props: Props) => {
    const { data, navigateToNewShipment } = props;
    const shipClient = new ShipClient();
    const dispatch = useDispatch();
    const [captureModal, setCaptureModal] = useState<boolean>(false);
    const [selectedDocument, setSelectedDocument] = useState<string>("");
    const labelLink = data?.attachments?.find((e) => e?.type == "Label")?.location ;
    const otherDoc = data?.attachments?.filter((e) => e?.type != "Label");
    const printPDF = () => {
        !!labelLink && printJS(labelLink, 'pdf');
    };
    const handleAddBulkAttachments = async (attachments: RemoteBlob[]) => {
        const mappedAttachments = attachments?.map((attachment: RemoteBlob) => ({
            fileName: attachment?.name,
            location: attachment?.location,
            type: "Shipment Photo",
            hideOnCustomer:false,
        }))
        const res = data?.uid && await shipClient.addBulkAttachment(data?.uid, mappedAttachments)
        setCaptureModal(false)
        dispatch(ShowNotification("Success", "Saved successfully!"));
    };
    return (
        <CModal
            closeOnBackdrop={false}
            show={true}
            centered
            size={"lg"}
            onClose={() => data?.uid && navigateToNewShipment(data?.uid)}
        >
            <CModalHeader>
                <h5>Shipment Label </h5>
            </CModalHeader>
            <CModalBody className={"mb-1"} style={{ height: '600px' }}>
                {data?.uid &&
                    <CRow className={"mb-1 align-items-center"}>
                        <CCol md={2}>
                            <CLabel className={"small"}>Shipment Number:</CLabel>
                        </CCol>
                        <CCol >
                            <CLink className={"font-weight-bold"} style={{ textDecoration: "underline" }} to={`/shipments/${data?.uid}`}>{data?.number}</CLink>
                        </CCol>
                        <CCol className="text-right">
                            <CameraIcon
                                style={{
                                    marginRight: 9
                                }}
                                onClick={() => setCaptureModal(true)}
                                tooltipText={'Capture'} />
                            <ActionButton
                                text={"Print"}
                                onClick={() => printPDF()}
                            />
                        </CCol>
                    </CRow>}
                {otherDoc && otherDoc?.length > 0 &&
                    <CRow className={"mb-1 align-items-center"}>
                        <CCol md={6}>
                            <SWSelect
                                label={"Documents"}
                                name={`documents`}
                                value={selectedDocument ?? ""}
                                onChange={(e) => {
                                    if (e) {
                                        setSelectedDocument(e)
                                        window.open(e, "_blank")
                                    } else {
                                        setSelectedDocument("")
                                    }
                                }}
                                values={otherDoc?.map((document) => ({ value: document?.location, label: document?.type })) ?? []}
                            />
                        </CCol>

                    </CRow>}
                {!!labelLink &&
                    <PdfViewer link={`${labelLink}?view=true`} />}
            </CModalBody>

            <CModalFooter>
                <CRow className="justify-content-between w-100">
                    <CCol className="text-right">
                        <CButton
                            style={{ fontSize: "10px" }}
                            variant="outline"
                            color="primary"
                            onClick={() => data?.uid && navigateToNewShipment(data?.uid)}
                        >
                            Back to Data Entry
                        </CButton>
                    </CCol>
                </CRow>
            </CModalFooter>
            {captureModal &&
                <CaptureImageModal
                    onClose={() => {
                        setCaptureModal(false)
                    }}
                    onSave={(e) => {
                        handleAddBulkAttachments(e)
                    }}
                />}
        </CModal >
    )
}

export default LabelModal