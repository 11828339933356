import {CCol, CInputRadio, CLabel, CRow} from "@coreui/react";
import React from "react";

type Props= {
    title:string,
    values:{label:string,value:string|boolean|null}[]
    valueInput:string|null|boolean
    onChange:(v:string|boolean|null)=>void
}

const SWRadioButtons=({title,values,valueInput,onChange}:Props)=>{
    return(
        <CCol md={12}>
            <CRow style={{marginLeft: "1px"}}>
                <CLabel>{title}</CLabel>
            </CRow>
            <CRow style={{marginLeft: "6px"}}>
                {values.map(({label,value})=>{
                    return (
                       <CCol key={`${label}-${value}`}><CInputRadio
                        style={{
                            width: "1.25em",
                            height: "1.25em",
                            fontSize: "10px",
                        }}
                        name={`${title}`}
                        value={value?.toString()}
                        checked={value === valueInput}
                        onChange={() => {
                            onChange(value)
                        }}
                    /><CLabel>{label}</CLabel></CCol>
                    )
                })}
            </CRow>
        </CCol>

    )
}
export default SWRadioButtons
