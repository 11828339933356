import {CCardBody, CCol, CRow} from "@coreui/react";
import React, {useState} from "react";
import {ShipmentSearch} from "../../../models/shipment";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";

interface IProps {
    shipment: ShipmentSearch
    account: any | null
    onEditClick: () => any;
}

const AccountCard = (props : IProps) => {
    const [isHover, setisHover] = useState<boolean>(false)
    const {shipment, account} = props

    return (
        <>
            <CCardBody
                className={`shipperCard mb-1 rounded   ${
                    isHover ? "bg-secondary" : ""
                }`}
                onMouseOver={() => {
                    setisHover(true);
                }}
                onMouseLeave={() => {
                    setisHover(false);
                }}
            >
                <CRow>
                    <CCol>
                        Account Name:
                    </CCol>
                    <CCol sm={7}><strong>{account?.name}</strong></CCol>
                    <CCol sm={1} className="text-right">
                        <CIcon
                            style={{ cursor: "pointer" }}
                            className={`${isHover ? "primary" : "text-white"}`}
                            content={freeSet.cilPencil}
                            size={"lg"}
                            onClick={props.onEditClick}
                        />
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>
                        Account number:
                    </CCol>
                    <CCol sm={8}><strong> {shipment.account}</strong></CCol>
                </CRow>
            </CCardBody>
        </>
    )
}

export default AccountCard