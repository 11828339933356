import {
    CButtonGroup,
    CCol,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CInputCheckbox, CLabel,
    CRow
} from "@coreui/react";
import SWSelect from "../../../SharedComponents/SWSelect";
import React, {useEffect, useState} from "react";
import {GeneratedInvoiceRequest, InvoiceDto} from "../../../../models/accounting";
import AccountingClient from "../../../../clients/accountingClient";
import {useFormik} from "formik";
import SWInput from "../../../SharedComponents/SWInput";
import RatesClient from "../../../../clients/ratesClient";
import SWDateAndTimePicker from "../../../SharedComponents/SWDateAndTimePicker";
import DateRangeFilter from "../../../SharedComponents/SWAdvancedSearch/DateRangeFilter";
import ActionButton from "../../../SharedComponents/ActionButton";
import {Report} from "../../../../models/Report";
import LookupsClient from "../../../../clients/lookupsClient";
import {downloadBlob} from "../../../../utils/downloadUtils";

interface IProps {
    onSubmit: (body: GeneratedInvoiceRequest) => void
    onClear: () => void
}

const GeneratedInvoiceForm = (props: IProps) => {
    const [accounts, setAccounts] = useState<{ [k: string]: string }>({})
    const [dateTypeFilter, setDateTypeFilter] = useState<string | undefined>("eq")
    const [reports, setReports] = useState<Report[]>([])

    const accountingClient = new AccountingClient();
    const ratesClient = new RatesClient();
    const lookupsClient = new LookupsClient();

    const invStatus = {
        "0": "Draft",
        "1": "Issued",
        "2": "Sent",
        "3": "Paid"
    };

    useEffect(() => {
        ratesClient.LookupCustomers().then(v => setAccounts(v))
        lookupsClient.GetUiReports("InvoiceSearch").then(v => setReports(v))
    }, [])

    const {values, handleChange, handleSubmit, setFieldValue, resetForm} = useFormik<GeneratedInvoiceRequest>({
        initialValues: {
            profileId: undefined,
            status: undefined,
            account: undefined,
            invoiceNumber: undefined,
            invoiceDate: "",
            invoiceDateFrom: "",
            invoiceDateTo: "",
            hasPartialPayment: false,
            format: 0
        },
        onSubmit: (values) => props.onSubmit(values)
    })

    const handleReportDownload = async (reportId: number) => {
        let remoteBlob = await accountingClient.GetGeneratedInvoicesReport(values, reportId)
        if (remoteBlob?.location)
            downloadBlob(remoteBlob.location, remoteBlob.name)
    }

    return <>
        <CRow className={"mt-2 col-12"}>
         
            <CCol md={3}>
                <SWSelect label={"Account"} values={accounts} value={values.account}
                          onChange={v => setFieldValue("account", v)} isClearable/>
            </CCol>
            <CCol md={3}>
                <SWInput label={"Invoice Number"} value={values.invoiceNumber} id={"invoiceNumber"}
                         name={"invoiceNumber"} onChangeFormik={handleChange}/>
            </CCol>
            <CCol md={3}>
                <SWSelect
                    values={invStatus}
                    value={values.status}
                    label={"Status"}
                    onChange={v => setFieldValue("status", v)}
                    isClearable
                />
            </CCol>
        </CRow>
        <CRow className={"mt-2 col-12"}>
            <CCol md={3}>
                <SWSelect
                    values={{
                        "eq": "Equal",
                        "lt": "Less Than",
                        "gt": "Greater Than",
                        "rg": "Range"
                    }}
                    label={"Invoice Date"}
                    value={dateTypeFilter}
                    onChange={v => setDateTypeFilter(v)}
                />
            </CCol>
            {dateTypeFilter == "eq" &&
                <CCol md={3} style={{marginTop: "28px"}}>
                    <SWDateAndTimePicker value={values.invoiceDate} handleOnChange={e => {
                        setFieldValue("invoiceDate", e)
                        setFieldValue("invoiceDateFrom", "")
                        setFieldValue("invoiceDateTo", "")
                    }}/>
                </CCol> ||
                dateTypeFilter == "lt" &&
                <CCol md={3} style={{marginTop: "28px"}}>
                    <SWDateAndTimePicker value={values.invoiceDateTo} handleOnChange={e => {
                        setFieldValue("invoiceDate", "")
                        setFieldValue("invoiceDateFrom", "")
                        setFieldValue("invoiceDateTo", e)
                    }}/>
                </CCol> ||
                dateTypeFilter == "gt" &&
                <CCol md={3} style={{marginTop: "28px"}}>
                    <SWDateAndTimePicker value={values.invoiceDateFrom} handleOnChange={e => {
                        setFieldValue("invoiceDate", "")
                        setFieldValue("invoiceDateFrom", e)
                        setFieldValue("invoiceDateTo", "")
                    }
                    }/>
                </CCol> ||
                dateTypeFilter == "rg" &&
                <CCol md={3} style={{marginTop: "28px"}}>
                    <DateRangeFilter value={`date|${values.invoiceDateFrom}|${values.invoiceDateTo}`} onChange={val => {
                        let dates = val.split("|")
                        setFieldValue("invoiceDateFrom", dates[1])
                        setFieldValue("invoiceDateTo", dates[2])
                        setFieldValue("invoiceDate", "")
                    }}
                    />
                </CCol>}
            <CCol className={"mt-4 ml-4"}>
                <CInputCheckbox checked={values.hasPartialPayment}
                                onChange={() => setFieldValue("hasPartialPayment", !values.hasPartialPayment)}/>
                <CLabel className={"mt-1"}>Has Partial Payment</CLabel>
            </CCol>
        </CRow>
        <CRow className={"mt-2 mb-2 col-12"}>
            <CCol md={9}>
                <CButtonGroup>
                    <ActionButton
                        color={"danger"}
                        variant={"outline"}
                        onClick={() => {
                            resetForm()
                            props.onClear()
                            setDateTypeFilter("eq")
                        }}
                        text="Clear All"
                    />
                </CButtonGroup>
            </CCol>
            <CCol className={"text-right"}>
                <CButtonGroup className="mr-4">
                    <CDropdown>
                        <CDropdownToggle color="success" variant={"outline"} size={"sm"} style={{fontSize: "10px"}}>Download
                            Report</CDropdownToggle>
                        <CDropdownMenu>
                            {reports.length > 0 ? reports?.map((r) => (
                                    <CDropdownItem onClick={() => handleReportDownload(r.id)}
                                                   key={r.id}>{r.name}</CDropdownItem>
                                )) :
                                <CDropdownItem disabled>No Reports Configured</CDropdownItem>}
                        </CDropdownMenu>
                    </CDropdown>
                </CButtonGroup>
                <CButtonGroup>
                    <ActionButton
                        text={"Search"}
                        onClick={handleSubmit}
                    />
                </CButtonGroup>
            </CCol>
        </CRow>
    </>
}

export default GeneratedInvoiceForm