import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import DateFormat from "dateformat";
import {formatDate} from "../../../utils/dateUtil";

interface Props {
  withTime?: boolean;
  onChange: (val: string) => void;
  value: string | Date;
}
const DateRangeFilter = (props: Props) => {
  const { withTime, onChange, value } = props;
  
  const valueSplit =
    value && !(value instanceof Date) && value.includes("|")
      ? value
          .split("|")
          .slice(1)
          .map((v) => {
            if (v) {
              return new Date(v);
            } else return null;
          })
      : [null, null];

  const [range, setRange] = useState<(Date | null)[]>(valueSplit);

  const changeRange = (date: any, first?: boolean) => {
    setRange(date);
  };

  const getRangeValue = (first?: boolean) => {
    return range[first ? 0 : 1];
  };

  useEffect(() => {
    const valueFormat = "yyyy-mm-dd'T'HH:MM:ss.l";
    // onChange(
    //   `${getRangeVaue(true).toISOString()}%${getRangeVaue(false).toISOString()}`
    // );
    if (range[1]) {
      
      onChange(
        `date|${ DateFormat(getRangeValue(true) as Date, valueFormat)}|${DateFormat(getRangeValue(false) as Date, valueFormat)}`
      );
    }
  }, [range]);

  const CustomInput = forwardRef<any, any>(({ value, onClick }, ref) => {
    return (
      <span className="form-control" onClick={onClick} ref={ref}>
        {formatDate(getRangeValue(true) ?? new Date())}
        {" to "}
        {formatDate(getRangeValue(false) ?? new Date())}
      </span>
    );
  });

  return (
    <div>
      <DatePicker
          showYearDropdown
        className="form-control small"
        showTimeInput={withTime}
        selected={getRangeValue(true)}
        startDate={getRangeValue(true)}
        endDate={getRangeValue(false)}
        onInputClick={() => setRange([null, null])}
        selectsRange={true}
        onChange={(e) => {
          changeRange(e as Date, true);
        }}
        startOpen={true}
        open={!getRangeValue(false)}
        customInput={<CustomInput range={range} />}
      />
    </div>
  );
};

export default DateRangeFilter;
