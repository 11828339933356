import {CButton, CButtonGroup, CContainer, CRow, CTooltip,} from "@coreui/react";
import {Form} from "../../SharedComponents/SWForm";
import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import {useComplexState} from "../../../hooks/helperHooks";
import ShipClient from "../../../clients/shipClient";

import {SortingRuleInformation} from "../../../models/sortingrule";
import SortingRuleInputs from "./components/SortingRuleInputs";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import ActionButton from "../../SharedComponents/ActionButton";

interface Props {}

const ViewSortingRule = (props: Props) => {
  const { id } = useParams() as any;

  const hist = useHistory();
  const [
    sortingrule,
    setSortingRuleProp,
    setSortingRule,
  ] = useComplexState<SortingRuleInformation>({});
  const [inEditMode, setInEditMode] = useState(false);

  const shipClient = new ShipClient();

  const saveChanges = async () => {
    shipClient.UpdateSortingRule(id, sortingrule).then((v) => {
      hist.push(`/settings/sortingrules/search`);
    });
  };

  useEffect(() => {
    shipClient.GetSortingRule(id).then((v) => {
      setSortingRule(v);
    });
  }, [id, inEditMode]);

  return (
    <CContainer className="bg-white p-4 text-black">
      <CRow>
        <CTooltip content="Back">
          <CButton>
            <CIcon
                content={freeSet.cilArrowLeft}
                onClick={() => hist.goBack()}
            />
          </CButton>
        </CTooltip>
      </CRow>
      <h5>Sorting Rule</h5>
      <Form
        readonly={!inEditMode}
        className="mt-5"
        initialState={sortingrule}
        onSubmit={async (body) => saveChanges()}
      >
        <SortingRuleInputs />
        <CButtonGroup>
          <CButton
            variant="outline"
            color="primary"
            onClick={() => setInEditMode(!inEditMode)}
            className="mr-2"
          >
            {inEditMode ? "Cancel" : "Edit"}
          </CButton>
          {inEditMode && (
            <ActionButton
            text={"Save"}
            onClick={() => inEditMode && saveChanges()}
            disabled={!inEditMode}
            />
          )}
        </CButtonGroup>
      </Form>
    </CContainer>
  );
};

export default ViewSortingRule;
