import {
    CButton,
    CCol,
    CContainer,
    CInputGroup,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CRow
} from "@coreui/react";
import React, {useEffect, useState} from "react";
import WarehousesClient from "../../../../../clients/warehousesClient";
import SWSelect from "../../../../SharedComponents/SWSelect";
import {useFormik} from "formik";
import {
    AllocationStrategy, InventoryDetailsReportItem,
    InventoryDetailsReportQueryParameters,
    InventoryTransaction,
    SkuSearch, warehouseDto
} from "../../../../../models/warehouse";
import SWInputNumber from "../../../../SharedComponents/SWInputNumber";
import SWInput from "../../../../SharedComponents/SWInput";
import SWDateAndTimePicker from "../../../../SharedComponents/SWDateAndTimePicker";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import {SetLoading} from "../../../../../store/actions/auth";
import {formatDate} from "../../../../../utils/dateUtil";
import ActionButton from "../../../../SharedComponents/ActionButton";

interface IProps {
    sku: SkuSearch | undefined
    type: string
    handleClose: () => void
}

interface QuantityHelper extends InventoryDetailsReportItem {
    quantityChange?: number
    newStatus?: number
    toLocation?: string
}

const EditQuantity = (props: IProps) => {
    const [typesLookup, setTypesLookups] = useState<{ [K: string]: string }>({})
    const [warehouseLookups, setWarehouseLookups] = useState<{ [K: string]: string }>({})
    const [locationLookups, setLocationLookups] = useState<{ [K: string]: string }>({})
    const [selectedSkuQuantities, setSelectedSkuQuantities] = useState<QuantityHelper[]>([])
    const [selectedWarehouse, setSelectedWarehouse] = useState<warehouseDto>()
    const [error, setError] = useState<string>()
    const {height, width} = useWindowDimensions();

    const status = {
        0: "intact",
        1: "Cover Damaged",
        2: "Damaged"
    }

    useEffect(() => {
        getTypes()
        getWarehouses()
        setFieldValue("sku", props.sku?.code)
        setFieldValue("accountNumber", props.sku?.accountNumber)
        setFieldValue("subType", props.type)
        setFieldValue("type", props.type)
        getDefaultWarehouse().then(r => getQuantityHelper())
    }, [])

    let client = new WarehousesClient()

    const getTypes = async () => {
        let types = await client.getSubTypesLookup()
        delete types["TSFR"]
        delete types["ChangeStatus"]
        setTypesLookups(types)
    }

    const getWarehouses = async () => {
        let warehouses = await client.warehousesLookup()
        setWarehouseLookups(warehouses)
    }

    const handleWarehouseChange = async (w?: string) => {
        await getQuantityHelper(w)
        await LoadAreasAndLocation(w)
        await setFieldValue("warehouse", w)
        await setFieldValue("area", "MAIN")
        await setFieldValue("location", "")
        const data: warehouseDto = await client.GetWarehouse(w)
        setSelectedWarehouse(data);
    }

    const getType = async (type: string) => {
        let subType = await client.getSubTypes(type)
        await setFieldValue("type", subType.type)
    }

    const
        {
            values,
            setFieldValue,
            handleChange,
            handleSubmit,
        } = useFormik<InventoryTransaction>({
            initialValues: {
                type: "",
                subType: "",
                accountNumber: "",
                warehouse: "",
                store: "MAIN",
                area: "MAIN",
                location: "",
                details: [],
                quantity: 0,
                status: 0,
                on: (new Date(Date.now())).toISOString(),
            },
            onSubmit: async () => {
                const postBody = {
                    ...values,
                    post: true,
                    details: values.details.filter(d => d.quantity !== undefined || d.quantity !== 0)
                }
                let type = values.type
                let res;
                switch (type) {
                    case "RECV" :
                        res = await client.createReceiving(postBody)
                        break;
                    case "CONS" :
                        res = await client.createConsuming(postBody)
                        break;
                }

                if (res?.succeeded) {
                    document.location.reload();
                } else {
                    setError(res?.error)
                }
            }
        })

    const handleSubtypeChange = async (val?: string) => {
        await getType(val ?? "")
        await setFieldValue("subType", val)
    }

    const getDefaultWarehouse = async () => {
        let data = await client.GetAccount(props.sku?.accountNumber ?? "")
        if (data.warehouse) {
            await setFieldValue("warehouse", data.warehouse);
            let location = await client.locationLookups(data.warehouse, "MAIN", "MAIN")
            setLocationLookups(location)
        }
    }

    const LoadAreasAndLocation = async (w?: string) => {
        let data = await client.GetWarehouseWithLookups(w)

        setLocationLookups(data.locationLookup)
    }

    const handleLocationChange = async (loc?: string) => {
        await setFieldValue("location", loc)
        let location = selectedWarehouse?.location.find(l => l.code == loc)
        await setFieldValue("area", location?.area)
    }

    const handleSubmitting = async () => {
        await setFieldValue("details", [...values.details, {
            area: values.area,
            store: "MAIN",
            lot: {
                account: props.sku?.accountNumber,
                batchNumber: values.batchNumber,
                itemNumber: values.itemNumber,
                sku: props.sku?.code ?? "",
                skuName: props.sku?.name,
                expiryDate: values.expiryDate,

                //serialNumber: values.serialNumber,
                status: values.status
            },
            location: values.location ?? "",
            warehouse: values.warehouse,
            quantity: values.quantity ?? 1,
            type: values.type,
            subType: values.subType,
            on: new Date()
        }])
        handleSubmit()
    }

    const dateAreEqual = (left: string | null | undefined, right: string | null | undefined) => {
        if (!left && !right)
            return true;
        if (!left && right)
            return false;
        if (left && !right)
            return false;
        return formatDate(left) == formatDate(right);

    }

    const getQuantityHelper = async (w?: string) => {
        if (values.type != "CONS") {
            setSelectedSkuQuantities([])
        }
        let body: InventoryDetailsReportQueryParameters = {
            code: props.sku?.code ?? "",
            account: props.sku?.accountNumber ?? "",
            warehouse: w ?? values.warehouse,
            excludeZero: true
        }

        let data = await client.inventoryDetailsReport(body)
        setSelectedSkuQuantities(data);
    }

    const quantityHelperQuantityChanged = (index: number, quantity?: number) => {

        if (values.type != "RECV" && quantity && selectedSkuQuantities[index].quantityAvailable < (quantity)) return;

        setSelectedSkuQuantities(selectedSkuQuantities.map((sq, i) => i == index ? {
            ...sq,
            quantityChange: quantity
        } : sq))
    }

    const addFromHelper = (index: number) => {
        const item = selectedSkuQuantities[index];

        const itemFound = values.details.find(c => c.lot.sku == props.sku?.code && c.location == item.location &&
            c.newStatus == item.newStatus && c.toLocation == item.toLocation &&
            c.lot.batchNumber == item.batchNumber && c.lot.itemNumber == item.itemNumber &&
            dateAreEqual(c.lot.expiryDate, item.expiryDate) && c.lot.status == item.status);


        if (itemFound) {
            const index = values.details.indexOf(itemFound);

            setFieldValue("details", [
                ...values.details.slice(0, index),
                {
                    ...values.details[index],
                    quantity: item.quantityChange,
                    newStatus: item.newStatus,
                    toLocation: item.toLocation
                },
                ...values.details.slice(index + 1)
            ])
        } else {
            setFieldValue("details", [...values.details, {
                area: item.area,
                store: item.store,
                lot: {
                    account: values.accountNumber,
                    batchNumber: item.batchNumber,
                    itemNumber: item.itemNumber,
                    sku: props.sku?.code,
                    skuName: props.sku?.name,
                    expiryDate: item.expiryDate,
                    status: item.status
                },
                warehouse: item.warehouse,
                location: item.location ?? "",
                quantity: item.quantityChange,
                type: values.type,
                subType: values.subType,
                available: item.quantityAvailable,
                current: item.quantity
            }])
        }
        handleSubmit()
    }

    return (
        <CModal show={true} onClose={props.handleClose} closeOnBackdrop={false} size={"lg"}>
            <CModalHeader>
                <CCol md={4}>
                    <SWSelect values={typesLookup} value={values.subType} name={"subType"}
                              onChange={handleSubtypeChange}/>
                </CCol>
            </CModalHeader>
            <CModalBody>
                <CContainer>
                    <CRow>
                        <CCol md={4}>
                            <SWSelect values={warehouseLookups} value={values.warehouse} name={"warehouse"}
                                      label={"Warehouse"} onChange={handleWarehouseChange}/>
                        </CCol>
                        <CCol md={4}>
                            <SWSelect values={locationLookups} value={values.location} name={"location"}
                                      label={"Location"} onChange={handleLocationChange}/>
                        </CCol>
                        <CCol md={4}>
                            <SWSelect values={status} label={"Status"} name={"status"} value={values.status?.toString()}
                                      onChange={s => setFieldValue("status", s)}/>
                        </CCol>
                    </CRow>
                    {(props.sku?.allocationStrategy == AllocationStrategy.ExpiryDate || props.sku?.allocationStrategy == AllocationStrategy.BatchNumber) &&
                        <CRow>
                            <CCol md={4}>
                                <SWInput label={"Batch Number"} name={"batchNumber"} value={values.batchNumber}
                                         onChangeFormik={handleChange}/>
                            </CCol>
                            <CCol md={4}>
                                <SWInput label={"Batch Barcode"} name={"itemNumber"} value={values.itemNumber}
                                         onChangeFormik={handleChange}/>
                            </CCol>
                            {props.sku.allocationStrategy == AllocationStrategy.ExpiryDate &&
                                <CCol md={4}>
                                    <SWDateAndTimePicker label={"Expiry Date"} name={"expiryDate"}
                                                         value={values.expiryDate}
                                                         handleOnChange={(e) => setFieldValue("expiryDate", e)}/>
                                </CCol>}
                        </CRow>}
                    <CRow>
                        <CCol md={4}>
                            <SWInputNumber name={"quantity"} value={values.quantity} label={"Quantity"}
                                           onChange={n => setFieldValue("quantity", n)}/>
                        </CCol>
                        <CCol md={4}>
                            <SWDateAndTimePicker value={values.on} handleOnChange={(v) => setFieldValue("on", v)} label={"Date"} name={"on"} />
                        </CCol>
                    </CRow>
                </CContainer>
                {selectedSkuQuantities && selectedSkuQuantities.length > 0 && values.type == "CONS" &&
                    <div className="position-relative table-responsive">
                        <table className="table table-borderless table-sm">
                            <thead>
                            <tr>
                                {width > 500 &&
                                    <th className="">
                                        <div className="d-inline">Quantity</div>
                                    </th>
                                }
                                <th className="">
                                    <div className="d-inline">Available</div>
                                </th>
                                <th className="">
                                    <div className="d-inline">Location</div>
                                </th>
                                <th className="">
                                    <div className="d-inline">Status</div>
                                </th>
                                <th className="">
                                    <div className="d-inline">Batch Number</div>
                                </th>
                                <th className="">
                                    <div className="d-inline">Batch Barcode</div>
                                </th>
                                <th className="">
                                    <div className="d-inline">Expiry Date</div>
                                </th>
                                <th className="">
                                    <div className="d-inline">Current</div>
                                </th>
                                <th className="">
                                    <div className="d-inline"/>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {selectedSkuQuantities.filter(s => {
                                let match = true;

                                if (values.status && values.status != s.status)
                                    match = false;
                                if (values.location && values.location != s.location)
                                    match = false;
                                if (values.batchNumber && values.batchNumber != s.batchNumber)
                                    match = false;
                                if (values.itemNumber && values.itemNumber != s.itemNumber)
                                    match = false;

                                if (values.expiryDate && !dateAreEqual(values.expiryDate, s.expiryDate))
                                    match = false;

                                return match;
                            }).map((sq, index) => <tr key={index}>
                                {width > 500 &&
                                    <td style={{minWidth: "40px"}}>
                                        <CInputGroup>
                                            <SWInputNumber style={{width: "70px"}} name={'quantity'}
                                                           value={sq.quantityChange}
                                                           onChange={val => quantityHelperQuantityChanged(index, val)}/>
                                            <CButton size={"sm"} style={{height: "28px"}}
                                                     disabled={!sq.quantityChange || (values.type == "TSFR" && !sq.toLocation) || (values.type == "CHST" && !sq.newStatus)}
                                                     onClick={() => addFromHelper(index)}
                                                     variant="outline" color="primary"
                                            >
                                                Update
                                            </CButton>

                                        </CInputGroup>
                                    </td>
                                }

                                <td>{sq.quantityAvailable}</td>
                                <td>{sq.location}</td>
                                <td>{status[sq.status]}</td>
                                <td>{sq.batchNumber}</td>
                                <td>{sq.itemNumber}</td>
                                <td>{formatDate(sq.expiryDate)}</td>

                                <td>{sq.quantity}</td>


                            </tr>)}
                            </tbody>
                        </table>
                    </div>
                }
            </CModalBody>
            <CModalFooter>
                <CRow className={"text-center"}>
                    <p color={"red"}>{error}</p>
                </CRow>
                <CCol>
                    <CButton color={"danger"} variant={"outline"} onClick={props.handleClose}>
                        Cancel
                    </CButton>
                </CCol>
                <CCol className={"text-right"}>
                    {values.type == "RECV" &&
                        <ActionButton
                        text={"Submit"}
                        onClick={handleSubmitting}
                        />}
                </CCol>
            </CModalFooter>
        </CModal>
    )
}

export default EditQuantity;