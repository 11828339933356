import {
    CCol,
    CRow,
    CContainer,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CButton, CSpinner
} from "@coreui/react";
import React from "react";
import {useFormik} from "formik";
import {CreateUser, UserCreateRequest, UserUpdate} from "../../../../models/user";
import * as yup from "yup"
import SWInput from "../../../SharedComponents/SWInput";


interface Props {
    onClose: () => void
    onSubmit: (v:UserUpdate) => void
    loader:boolean
    initialValue:UserUpdate

}

const UpdateUserModal = (props: Props) => {
    const {values, setFieldValue, handleSubmit, errors} = useFormik<UserUpdate>({
        initialValues:props.initialValue,
        onSubmit: async (v) => {
            props.onSubmit(v)
        },
        validationSchema: yup.object({
            email: yup.string().email('Should be Email format').required('Email is Required'),
            name: yup.string().required('Name is required'),

        }),
        validateOnBlur: false,
        validateOnChange: false

    })
    return (
        <CContainer className="bg-white p-4 text-black">
            <CModal show={true} size={'lg'} onClose={props.onClose} centered>
                <CModalHeader style={{width: '100%', justifyContent: 'space-between'}}  closeButton={!props.loader}>
                    <CRow className={'flex-row w-100 d-flex justify-content-between align-items-center'}>
                        <h5 className={'text-center'}>Update User</h5>
                        {props.loader && <CSpinner
                            color="primary" style={{width: '20px', height: '20px'}}/>}
                    </CRow>

                </CModalHeader>
                <CModalBody>
                    <CRow>
                        <CCol md={12}>

                            <SWInput name={'name'} label={'Name'} validationErrors={errors.name} value={values.name}
                                     onChange={(e: string) => setFieldValue('name', e)}/>

                            <SWInput name={'Email'} label={'Email'} validationErrors={errors.email} value={values.email}
                                     onChange={(e: string) => setFieldValue('email', e)}/>

                        </CCol>
                    </CRow>

                </CModalBody>
                <CModalFooter >
                    <CButton disabled={props.loader} size="sm"
                             className="mr-2"
                             color="primary" onClick={handleSubmit}>
                        Update
                    </CButton>
                </CModalFooter>
            </CModal>

        </CContainer>
    );
};

export default UpdateUserModal;
