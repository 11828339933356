export const skuFlattedKeys = [
    "Code",
    "InternalCode",
    "Name",
    "DimensionUnit",
    "Length",
    "Width",
    "Height",
    "WeightUnit",
    "WeightValue",
    "Active",
    "AllocationStrategy",
    "AllocationType",
    "Seller",
    "WarehouseCode",
    "Barcode",
    "Warehouse",
    "BreakCount",
    "MinReorder",
    "AllowedWarehouses",
    "MinimumExpiryDays"
];

export interface SkuImportRequest {
    fileUrl: string;
    accountId: string;
    parameters: SkusImportParameters;
    notifyEmail?: string;
    senderEmail?: string;
    fileName?: string;
}

export interface SkuImportSearch {
    id?:number;
    fileUrl?: string;
    finished?: boolean;
    totalRows?: number;
    skusAdded?: number;
    skusUpdated?: number;
    parameters?: SkusImportParameters;
    createdOn?: string;
    createdBy?: string;
    modifiedOn?:string;
    modifiedBy?: string;
    accountId?: string;
    accountName?: string;
    errors?: SkuImportItemError[];
    notifyEmail?: string;
    senderEmail?: string;
    fileName?: string;
    failedFileUrl?: string;
}

interface SkusImportParameters {
    map: { [key: string]: string |null };
    defaultValue: SkuFlattened;
}
export interface SkuFlattened  {
    code?: string;
    internalCode?: string;
    name?: string;
    barcode?: string;
    dimensionUnit?: string;
    length?: number;
    width?: number;
    height?: number;
    weightValue?: number;
    weightUnit?: string;
    active?: boolean;
    allocationStrategy?: string;
    allocationType?: string;
    seller?: string;
    warehouseCode?: string;
    warehouse?: string;
    breakCount?: number;
    minReorder?: number;
    allowedWarehouses?: string;
    minimumExpiryDays?: number;
}


interface SkuImportItemError {
    rowNumber: number;
    errorCode: string;
    errorDescription: string;
}
export interface SkusImportSearchRequest {
    limit?: number
    offset?: number
    finished?: boolean
    account?: string
}
