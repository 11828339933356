import { ContainerSearch } from "../../models/container";
import { Filter, FilterGroup } from "../../models/filter";
import {
  SET_CONTAINER_FILTER_GROUP,
  SET_CONTAINER_IS_LOADING,
  SET_CONTAINER_RESULT,
} from "../types";

export const SetContainerPage = (page: number) => {
  return {
    type: SET_CONTAINER_FILTER_GROUP,
    payload: {
      page: page,
    },
  };
};

export const SetContainersIsLoading = (loading: boolean) => {
  return {
    type: SET_CONTAINER_IS_LOADING,
    payload: {
      isLoading: loading,
    },
  };
};

export const SetContainersFilterGroupFilters = (filters: Filter[]) => {
  return {
    type: SET_CONTAINER_FILTER_GROUP,
    payload: {
      page: 0,
      filters: filters,
    },
  };
};

export const SetContainersFilterGroup = (filterGroup: FilterGroup) => {
  return {
    type: SET_CONTAINER_FILTER_GROUP,
    payload: filterGroup,
  };
};

export const SetContainerResult = (
  page: number,
  count: number,
  result: ContainerSearch[],
  error?: string
) => {
  return {
    type: SET_CONTAINER_RESULT,
    payload: {
      count: count,
      result: result,
      error: error,
    },
  };
};

export const setContainerSearchLimit = (limit: number) => {
  return {
    type: SET_CONTAINER_RESULT,
    payload: {
      limit: limit
    }
  }
}
