import {useLocation} from "react-router";
import {useEffect, useMemo} from "react";
import EmailValidation from "./EmailValidation";
import EnterNewPassword from "./EnterNewPassword";

const ResetPassword = () => {
    const loc = useLocation()
    const params = new URLSearchParams(loc.search)

    const body = useMemo(() => {
        if (params.get('Token') && params.get('AccountId'))
            return <EnterNewPassword accountId={params.get('AccountId') as string}
                                     token={params.get('Token') as string}/>

        return <EmailValidation/>

    }, [loc.search])

    console.log(loc.search, 'space', params.get('Token'), params.get('AccountId'))
    return (
        <>
            {body}
        </>
    )
}


export default ResetPassword
