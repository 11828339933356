import { CButton, CCol, CContainer, CInputCheckbox, CLabel, CModal, CModalBody, CRow } from "@coreui/react"
import React, { useEffect, useState } from "react"
import SWInput from "../../../SharedComponents/SWInput";
import SWSelect from "../../../SharedComponents/SWSelect";
import {
    AddShipmentAttachmentRequest,
    ShipmentAttachment,
    UpdateShipmentAttachmentRequest
} from "../../../../models/shipment";
import ShipClient from "../../../../clients/shipClient";
import { useFormik } from "formik";

interface IProps {
    handleClose: () => void
    handleAdd: (attachment: ShipmentAttachment) => void
    handleUpdate: (attachment: ShipmentAttachment) => void
    uid: string
    number: string
    attachments: ShipmentAttachment[]
    attachmentIndex: number | undefined
    fileUploaded: any
    location: string | undefined
}

const AddAttachmentModal = (props: IProps) => {
    const shipClient = new ShipClient();
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [attachmentTypes, setAttachmentTypes] = useState<{ [k: string]: string }>({})
    const [hideOnCustomer, setHideOnCustomer] = useState<boolean>(true);

    const {values, handleSubmit, setFieldValue, handleChange} = useFormik({
        initialValues: {
            location: undefined,
            type: undefined,
            fileName: undefined
        },
        onSubmit: async (values) => {
            if (isEdit) {
                if (props.attachmentIndex == undefined) return
                const attachment = props.attachments[props.attachmentIndex]
                const body: UpdateShipmentAttachmentRequest = {
                    id: attachment.id,
                    type: values.type,
                    fileName: values.fileName,
                    hideOnCustomer
                }
                const rs = await shipClient.updateAttachment(props.uid, body)
                if (rs)
                    props.handleUpdate(rs)
            } else {
                const body: AddShipmentAttachmentRequest = {
                    type: values.type ?? "",
                    location: props.location,
                    fileName: values.fileName,
                    hideOnCustomer
                }
                const attachment = await shipClient.addAttachment(props.uid, body)
                props.handleAdd(attachment)
            }
        }
    })

    useEffect(() => {
        shipClient.getAttachmentTypeLookups("shipment").then(v => setAttachmentTypes(v))
        if (props.attachmentIndex != undefined) {
            const attachment = props.attachments[props.attachmentIndex]
            setFieldValue("type", attachment.type)
            setFieldValue("fileName", attachment.fileName)
            setHideOnCustomer(attachment?.hideOnCustomer)
            setIsEdit(true)
        } else {
            setIsEdit(false)
        }
    }, [])

    return (
        <CModal show={true} onClose={props.handleClose} className={"ml-auto mr-auto"} centered>
            <CModalBody>
                <CContainer className="bg-white p-4 text-primary">
                    <h5>
                        {isEdit ? "Update Attachment:" : "Add Attachment:"}
                    </h5>
                    <CRow>
                        <CCol>
                            <SWSelect label={"Type"} value={values.type} values={attachmentTypes}
                                onChange={t => setFieldValue("type", t)} />
                        </CCol>
                    </CRow>
                    {props.fileUploaded &&
                        <CRow>
                            <CCol>
                                <SWInput value={values.fileName} name={"fileName"} label={"File Name"} onChangeFormik={handleChange} />
                            </CCol>
                        </CRow>}
                    <CRow>
                        <CCol className={"ml-4 mt-2 mb-4"}>
                            <CInputCheckbox
                                name={"hideOnCustomer"}
                                checked={hideOnCustomer}
                                onChange={() => setHideOnCustomer(!hideOnCustomer)} />
                            <CLabel className={"mt-1"}>Hide on customer</CLabel>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol>
                            <CButton
                                color={"danger"}
                                variant={"outline"}
                                size={"sm"}
                                onClick={props.handleClose}>
                                Cancel
                            </CButton>
                        </CCol>
                        <CCol md={2} className={"text-right"} style={{ marginRight: "5px" }}>
                            <CButton
                                color={"primary"}
                                variant={"outline"}
                                size={"sm"}
                                onClick={() => handleSubmit()}
                            >{isEdit ? "Update" : "Add"}</CButton>
                        </CCol>
                    </CRow>
                </CContainer>
            </CModalBody>
        </CModal>
    )
}

export default AddAttachmentModal