import React, {useEffect, useRef, useState} from "react";
import {CCol, CRow} from "@coreui/react";
import SWInput from "../../../SharedComponents/SWInput";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import {formatDateTime} from "../../../../utils/dateUtil";
import {AccountAttachment} from "../../../../store/actions/accounts";
import {IAddAccount} from "../../../../models/shipment";
import AddAccountAttachmentModal from "../modals/AddAccountAttachmentModal";
import AccountingClient from "../../../../clients/accountingClient";
import {SetLoading, ShowNotification} from "../../../../store/actions/auth";
import {useDispatch} from "react-redux";
import {ref} from "yup";
import ConfirmModal from "../../../SharedComponents/ConfirmModal";
import ShipClient from "../../../../clients/shipClient";
import CameraIcon from "../../../../icons/camera";
import CaptureImageModal from "../../../SharedComponents/CaptureImageModal";
import {RemoteBlob} from "../../../../models/app";

type Props = {
    accountInfo?: IAddAccount
}
const ViewAccountingAttachment = ({accountInfo}: Props) => {

    const [attachmentIndex, setAttachmentIndex] = useState<number>()
    const [accountAttachment, setAccountAttachment] = useState<any[]>([]);
    const [showAttachmentModal, setShowAttachmentModal] = useState<boolean>(false)
    const [fileLoading, setFileLoading] = useState<boolean>(false)
    const [fileUploaded, setFileUploaded] = useState<boolean>(false)
    const [fileLocation, setFileLocation] = useState<string>()
    const [isEditItem, setIsEditItem] = useState<boolean>(false);
    const [refreshPage, setRefreshPage] = useState<boolean>(false);
    const [deleteAttachmentModal, setDeleteAttachmentModal] = useState<boolean>(false);
    const [captureModal, setCaptureModal] = useState<boolean>(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);


    const accountingClient = new AccountingClient();


    const dispatch = useDispatch();
    const tableRef = useRef<HTMLTableElement>(null);
    const handleAddAttachment = async (attachment: AccountAttachment) => {
        setAttachmentIndex(undefined)
        accountAttachment?.push(attachment)
        setShowAttachmentModal(false)
        setRefreshPage(!refreshPage);

    }

    const handleUpdateAttachment = (attachment: AccountAttachment) => {
        if (attachmentIndex == undefined) return
        let attachments = accountAttachment
        if (attachments != null) {
            attachments[attachmentIndex] = attachment
            setShowAttachmentModal(false)
            setAttachmentIndex(undefined)
        }
        setRefreshPage(!refreshPage);
    }

    const handleDeleteAttachment = async () => {
        if (attachmentIndex == undefined)
            return
        let attachments = accountAttachment
        let attachmentId = attachments[attachmentIndex].id
        setAttachmentIndex(undefined)
        const res = await accountingClient.deleteAttachment(accountInfo?.id ?? "", {id: attachmentId})
        if (res.succeeded) {
            setDeleteAttachmentModal(false)
            setRefreshPage(!refreshPage);
        }
    }

    const handleFileImport = async (file?: File) => {
        setFileUploaded(false)
        setFileLoading(true)
        if (!file) return
        let res = await accountingClient.UploadFile(file)
        await setFileLocation(res.location)
        if (res.location) {
            setFileUploaded(true)
            setShowAttachmentModal(true)
            setFileLoading(false)
        }
        setIsEditItem(false)
    }

    const onUpdateAttachment = (index: number) => {
        setAttachmentIndex(index)
        setShowAttachmentModal(true)
    }

    useEffect(() => {
        dispatch(SetLoading(true))
        accountingClient.searchAttachment("shipment", accountInfo?.id ?? "").then((v) => {
            setAccountAttachment(v);
        })
        dispatch(SetLoading(false));
    }, [refreshPage]);


    const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFileImport(e.dataTransfer.files[0]);
            e.dataTransfer.clearData();
        }
    };

    const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };


    const renderHeader = (header: string[]) => header.map((value, index) => {
        return (
            <th className="sticky-top bg-white" key={index}>
                {value}
            </th>
        );
    });


    const handleDownload = (text: string) => {
        const link = document.createElement("a");
        link.href = text;
        link.click();
        link.remove();
    }

    const handleCopy = async (text: string) => {
        await navigator.clipboard.writeText(text)
        dispatch(ShowNotification("Success", "Url copied to clipboard", false))
    }


    const showDeleteAttachmentModal = (index: number) => {
        setAttachmentIndex(index)
        setDeleteAttachmentModal(true)
    }

    const handleAddBulkAttachments = async (attachments: RemoteBlob[]) => {
        const mappedAttachments = attachments?.map((attachment: RemoteBlob) => ({
            fileName: attachment?.name,
            location: attachment?.location,
            type: "Shipment Photo",

        }))
        const res = await accountingClient.addBulkAttachment(accountInfo?.id ?? "", mappedAttachments)
        setCaptureModal(false)
        setRefreshPage(!refreshPage)
    }

    return (
        <>
            <>
                <CCol>
                    <CRow className={" w-20 mt-3 px-2"}>
                        <CameraIcon
                            style={{
                                marginRight: 9
                            }}
                            tabIndex={-1}
                            onClick={() => setCaptureModal(true)}
                            tooltipText={'Capture'}/>
                        <div
                            className="file-input-container"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            style={{
                                width: '97%',
                            }}
                        >
                            {!fileLoading ? (
                                <SWInput
                                    type={"file"}
                                    id={"input-file"}
                                    style={{
                                        width: '100%', transition: 'background-color 0.3s ease',
                                        backgroundColor: isHovered ? '#f0f0f0' : 'transparent',
                                    }}
                                    onChangeFile={(f) => handleFileImport(f)}
                                />
                            ) : (
                                <div
                                    style={{width: '100%', height: '70%'}}
                                    className="rounded mb-3 m-0 py-1 px-2 d-flex align-items-center justify-content-center  bg-gray-200"
                                >
                                    Please wait...
                                </div>
                            )}
                        </div>
                    </CRow>

                </CCol>
            </>
            <div
                onDragOver={onDragOver}
                onDrop={onDrop}
                ref={tableRef}
            >
                <table className="position-relative table table-hover table-sm">
                    <thead>{renderHeader(["File Name", "Attachment Type", "Download", "Created By",
                        "Created On", "Modified By", "Edit"])}</thead>
                    <tbody>
                    {accountAttachment?.length < 1 && (
                        <div className="mr-2 ml-2 mt-2 mb-2">
                            <span> No Available Attachments</span>
                        </div>
                    )}
                    {accountAttachment?.map((a, i) => (
                        <tr key={i}>
                            <td>{a.fileName}</td>
                            <td>{a.type}</td>
                            <td>
                                <CIcon
                                    content={freeSet.cilCloudDownload}
                                    style={{cursor: "pointer", margin: "auto"}}
                                    onClick={() => handleDownload(a.location)}

                                />
                                <CIcon
                                    content={freeSet.cilCopy}
                                    style={{cursor: "pointer", marginLeft: "8px"}}
                                    onClick={() => handleCopy(a.location)}

                                />
                            </td>
                            <td>{a.createdBy}</td>
                            <td>{formatDateTime(a.createdOn)}</td>
                            <td>{a.modifiedBy ?? "-"}</td>

                            <td>
                                <CIcon
                                    content={freeSet.cilPencil}
                                    style={{cursor: "pointer"}}
                                    onClick={() => onUpdateAttachment(i)}

                                />
                                <CIcon
                                    content={freeSet.cilTrash}
                                    style={{cursor: "pointer", marginLeft: "10px"}}
                                    onClick={() => {
                                        showDeleteAttachmentModal(i)
                                    }}

                                />
                            </td>
                        </tr>
                    ))
                    }
                    </tbody>
                </table>
            </div>

            {
                showAttachmentModal && <AddAccountAttachmentModal
                    handleClose={() => {
                        setAttachmentIndex(undefined)
                        setFileLoading(false)
                        setFileUploaded(false)
                        setFileLocation(undefined)
                        setShowAttachmentModal(false)
                    }}
                    handleAdd={(file: any) => handleAddAttachment(file)}
                    handleUpdate={handleUpdateAttachment}
                    attachments={accountAttachment}
                    fileUploaded={true}
                    location={fileLocation}
                    attachmentIndex={attachmentIndex}
                    id={accountInfo?.id ?? ""}
                />
            }
            {deleteAttachmentModal &&
                <ConfirmModal onClose={() => {
                    setDeleteAttachmentModal(false)
                }} onSubmit={handleDeleteAttachment}
                              title={"Delete Attachment"} body={"Are you sure you want to delete this attachment"}/>}

            {captureModal &&
                <CaptureImageModal

                    onClose={() => {
                        setCaptureModal(false)
                    }}
                    onSave={(e) => {
                        handleAddBulkAttachments(e)
                    }}
                />}
        </>
    )
}
export default ViewAccountingAttachment
