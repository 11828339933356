import {CCard, CContainer, CNav, CNavLink, CTabContent, CTabPane, CTabs} from "@coreui/react";
import React, {Fragment, useEffect, useState} from "react";
import AddCODForm from "./components/AddCODForm";
import PendingCODTable from "./components/PendingCODTable";
import GeneratedCodTable from "./components/GeneratedCodTable";
import BankDepositsTable from "./components/BankDeposits";
import AddAccountForm from "./components/AddAccountForm";
import LookupsClient from "../../../clients/lookupsClient";

const CreateAccount = () => {
    const [countryLookups, setCountryLookups] = useState<{ [k: string]: string }>({});
    const [isSubmitting, setIsSubmitting] = useState(false)


    const lookups = new LookupsClient();
    const getCountries = async () => {
        let countries = await lookups.getCountries();
        setCountryLookups(countries);
    };

    useEffect(() => {
        getCountries();
    }, []);
    return (
        <Fragment>
            <CContainer className="bg-white p-4 text-primary small">
                <AddAccountForm countryLookups={countryLookups} setIsSubmitting={setIsSubmitting} isEdit={false}/>
                <div className={"py-4"}></div>

            </CContainer>
        </Fragment>
    )
}

export default CreateAccount