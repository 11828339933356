import React, { useState } from "react";
import {
  CButton,
  CCol,
  CInput,
  CInputCheckbox,
  CInputGroup,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
} from "@coreui/react";
import DateAndTimePicker from "../../../SharedComponents/SWForm/DateAndTimePicker";
import ActionButton from "../../../SharedComponents/ActionButton";

interface IProps {
  onClose: () => any;
  initialDueIn: number;
  onSubmit: (date: string, dueIn: number, sendInvoice: boolean) => any;
}

const Component = (props: IProps) => {
  const [date, setDate] = useState<any>(new Date(Date.now()));

  const { onClose, onSubmit, initialDueIn } = props;
  const [dueIn, setDueIn] = useState(initialDueIn);

  const [sendInvoice, setSendInvoice] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const onClick = () => {
    setDisabled(true);
    onSubmit(date, dueIn, sendInvoice);
    
  }


  return (
    <div className="small">
      <CModal show={true} onClose={onClose}>
        <CModalHeader>
          <h5>Confirm</h5>
        </CModalHeader>
        <CModalBody>
          Are you sure you want to issue the invoice
          <CCol>
            <CRow className="mt-3 mb-3">
              <CLabel>Invoice Date:</CLabel>
              <DateAndTimePicker
                value={date}
                handleOnChange={(v: string) => setDate(v)}
              />
            </CRow>
            <CRow className="mt-3 mb-3">
              <CLabel>Due In:</CLabel>
              <CInput
                style={{ fontSize: "10px" }}
                value={dueIn}
                type="number"
                name="dueIn"
                onChange={(e) => setDueIn(e.currentTarget.value)}
              />
            </CRow>

            <CRow className="ml-2">
              <CInputGroup>
                <CInputCheckbox
                  style={{ cursor: "pointer" }}
                  checked={sendInvoice}
                  onClick={() => setSendInvoice(!sendInvoice)}
                />
                <CLabel className="text-primary">Send Invoice </CLabel>
              </CInputGroup>
            </CRow>
          </CCol>
        </CModalBody>
        <CModalFooter>
          <CRow className="justify-content-between w-100">
            <CCol className="text-left">
              <CButton
                style={{ fontSize: "10px" }}
                variant="outline"
                color="danger"
                onClick={onClose}
              >
                Cancel
              </CButton>
            </CCol>
            <CCol className="text-right">
              <ActionButton
                  text={"Yes"}
                  disabled={disabled}
                  onClick={onClick}
              />
            </CCol>
          </CRow>
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default Component;
