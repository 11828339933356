import {
    CModal,
    CModalBody,
    CButton,
    CModalHeader,
    CRow,
    CCol, CContainer,
} from "@coreui/react";
import React, {useState} from "react";
import {Field, Form, Formik} from "formik";
import {ShowNotification} from "../../../../store/actions/auth";
import InputField from "../../../SharedComponents/FormikCustomFields/InputField";
import DateInputField from "../../../SharedComponents/FormikCustomFields/DateInputField";
import {date, object, string} from "yup";
import ShipClient from "../../../../clients/shipClient";
import {useDispatch} from "react-redux";
import {downloadBlob} from "../../../../utils/downloadUtils";
import ActionButton from "../../../SharedComponents/ActionButton";
import SWInput from "../../../SharedComponents/SWInput";
import {is, tr} from "date-fns/locale";

interface IProps {
    isShowing: boolean;
    onClose: (refresh:boolean) => any;
    data: {
        account: string;
        consigneeCountry: string | null;
        agent: string | null | number;
        pickupDate: string;
    }
    setIsSubmitting: any
    isSubmitting: boolean
}

const GenerateMonthReportModal = (props: IProps) => {
    const shipClient = new ShipClient();
    const dispatch = useDispatch()
    const {isShowing, setIsSubmitting, isSubmitting, data, onClose} = props;
    const [reportName, setReportName] = useState("")

    const handleSubmit = async (e: any) => {
        
        const body = {...data, name: reportName}
        setIsSubmitting(true)
        const rs = await shipClient.generateMonthReport(body)
        setIsSubmitting(false)
        if (rs.succeeded) {
            dispatch(ShowNotification("Success", "Report was generated successfully", false))
            downloadBlob(rs.data.location, `${e.name}_${new Date()}`, "_self");
        }
        onClose(true)
    }

    const validationSchema = () => object().shape({
        name: string().required("required"),
    })

    return (
        <>
            <CModal centered={true} show={isShowing} onClose={onClose}>
                <CModalHeader>
                    <h5>Generate Report</h5>
                </CModalHeader>
                <CModalBody>
                    <>
                        <CContainer className="form-group mb-0">
                            <CRow>
                                <CCol className="col-sm-6">
                                    <SWInput label={"Report Name"}  value={reportName} onChange={(v)=> setReportName(v)} />
                                </CCol>
                            </CRow>
                        </CContainer>
                        <CRow>
                            <CCol>
                                <CButton
                                    size="sm"
                                    style={{fontSize: "10px"}}
                                    variant="outline"
                                    color="danger"
                                    onClick={onClose}
                                >
                                    Cancel
                                </CButton>
                            </CCol>
                            <CCol md={2} className="text-right">
                                <CButton
                                    size="sm"
                                    style={{fontSize: "10px"}}
                                    variant="outline"
                                    color={"primary"}
                                    onClick={handleSubmit}
                                    disabled={!reportName || isSubmitting }

                                >
                                    {isSubmitting ? "Generating..." : "Generate"}
                                </CButton>
                            </CCol>
                        </CRow>
                    </>
                </CModalBody>
            </CModal>
        </>
    );
};

export default GenerateMonthReportModal;
