import {
    CCol,
    CRow,
    CContainer,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CButton, CSpinner
} from "@coreui/react";
import React from "react";
import {useFormik} from "formik";
import {CreateUser, UserCreateRequest} from "../../../../models/user";
import * as yup from "yup"
import SWInput from "../../../SharedComponents/SWInput";


interface Props {
    onClose: () => void
    onSubmit: (v:UserCreateRequest) => void
    loader:boolean

}

const CreateUserModal = (props: Props) => {
    const {values, setFieldValue, handleSubmit, errors} = useFormik<CreateUser>({
        initialValues: {
            password: '',
            confirmPassword: '',
            userName: '',
            email: '',
            emailProvider: 0,
            name: ''

        },
        onSubmit: async (v) => {
            const {confirmPassword, ...rest} = v;
           props.onSubmit(rest)
        },
        validationSchema: yup.object({
            confirmPassword: yup.string().test('confirm-Password-is-required', 'Password is not Match', function (value) {
                return value === this.parent.password;
            }).min(8, 'Minimum is 8 digits'),
            email: yup.string().email('Should be Email format').required('Email is Required'),
            userName: yup.string().required('username is Required'),
            password: yup.string().required('Password is required').min(8, 'Minimum is 8 digits'),
            name: yup.string().required('Name is required'),

        }),
        validateOnBlur: false,
        validateOnChange: false

    })
    return (
        <CContainer className="bg-white p-4 text-black">
            <CModal show={true} size={'lg'} onClose={props.onClose} centered>
                <CModalHeader style={{width: '100%', justifyContent: 'space-between'}}  closeButton={!props.loader}>
                    <CRow className={'flex-row w-100 d-flex justify-content-between align-items-center'}>
                        <h5 className={'text-center'}>Create User</h5>
                        {props.loader && <CSpinner
                                                   color="primary" style={{width: '20px', height: '20px'}}/>}
                    </CRow>

                </CModalHeader>
                <CModalBody>
                    <CRow>
                        <CCol md={12}>

                            <SWInput name={'name'} label={'Name'} validationErrors={errors.name} value={values.name}
                                     onChange={(e: string) => setFieldValue('name', e)}/>
                            <SWInput name={'userName'} label={'User Name'} validationErrors={errors.userName}
                                     value={values.userName}
                                     onChange={(e: string) => setFieldValue('userName', e)}/>
                            <SWInput name={'Email'} label={'Email'} validationErrors={errors.email} value={values.email}
                                     onChange={(e: string) => setFieldValue('email', e)}/>
                            <SWInput name={'password'} validationErrors={errors.password} label={'Password'}
                                     type={'password'} value={values.password}
                                     onChange={(e: string) => setFieldValue('password', e)}/>
                            <SWInput name={'confirmPassword'} label={'Confirm Password'}
                                     validationErrors={errors.confirmPassword} type={'password'}
                                     value={values.confirmPassword}
                                     onChange={(e: string) => setFieldValue('confirmPassword', e)}/>


                        </CCol>
                    </CRow>

                </CModalBody>
                <CModalFooter >
                    <CButton disabled={props.loader} size="sm"
                             className="mr-2"
                             color="primary" onClick={handleSubmit}>
                        Create
                    </CButton>
                </CModalFooter>
            </CModal>

        </CContainer>
    );
};

export default CreateUserModal;
