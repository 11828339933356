import {
    CModal,
    CModalHeader,
    CModalBody,
    CCol,
    CRow,
    CModalFooter,
    CButton,
  } from "@coreui/react";
import ActionButton from "../../../SharedComponents/ActionButton";
import React from "react";

  interface IProps{
    onSave: ()=>any;
    onClose:()=>any;
    isShowing:boolean;

}

  function DeleteItemModal(props:IProps) {
    const {isShowing,onSave,onClose}=props;

    return (
      <div>
        <CModal
          show={isShowing}
          onClose={onClose}
        >
          <CModalHeader>
            <h5>Confirm</h5>
          </CModalHeader>
          <CModalBody>Are you sure you want to Delete this Item ?</CModalBody>
          <CModalFooter>
            <CRow className="justify-content-between w-100">
              <CCol className="text-left">
                <CButton
                  style={{ fontSize: "10px" }}
                  variant="outline"
                  color="danger"
                  onClick={onClose}
                >
                  Cancel
                </CButton>
              </CCol>
              <CCol className="text-right">
                  <ActionButton
                      text={"Yes"}
                      onClick={onSave}
                  />
              </CCol>
            </CRow>
          </CModalFooter>
        </CModal>
      </div>
    );
  }
  
  export default DeleteItemModal;
  