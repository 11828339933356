import {
  Action,
  AuthenticationAppModel,
  ContainerTypeAppModel,
} from "../../models/app";
import initialState from "../initialStates/containerTypeInitialState";
import {
  SET_CONTAINERTYPE_FILTER_GROUP,
  SET_CONTAINERTYPE_IS_LOADING,
  SET_CONTAINERTYPE_RESULT,
} from "../types";
import { ContainerType } from "../../models/containertype";

const containertypeReducer = (
  state: ContainerTypeAppModel = initialState,
  action: Action
): ContainerTypeAppModel => {
  switch (action.type) {
    case SET_CONTAINERTYPE_FILTER_GROUP:
      return {
        ...state,
        containertypesSearch: {
          ...state.containertypesSearch,
          filterGroup: {
            ...state.containertypesSearch.filterGroup,
            ...action.payload!,
          },
        },
      };

    case SET_CONTAINERTYPE_IS_LOADING:
    case SET_CONTAINERTYPE_RESULT:
      return {
        ...state,
        containertypesSearch: {
          ...state.containertypesSearch,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default containertypeReducer;
