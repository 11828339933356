import { CButton, CContainer } from "@coreui/react";
import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { queryParamsToFilters } from "../../../utils/filterUtils";
import { useSearchOnFilterGroup } from "../../../hooks/helperHooks";

import { useDispatch, useSelector } from "react-redux";
import { AppModel } from "../../../models/app";
import {
  SetUserResult,
  SetUsersFilterGroupFilters,
  SetUserIsLoading,
  SetUserPage,
  SetUsersFilterGroup,
} from "../../../store/actions/users";

import SearchForm from "./components/SearchForm";
import SearchResult from "./components/SearchResult";
import AuthClient from "../../../clients/authClient";

const SearchUsers = () => {
  const dispatch = useDispatch();
  const userClient = new AuthClient();
  const user=useSelector((state:AppModel)=>state.app.user?.role)
  useSearchOnFilterGroup({
      updateFilterGroup: (fg) => dispatch(SetUsersFilterGroup(fg)),
      useFilterGroupSelector: () =>
          useSelector((state: AppModel) => state.user.usersSearch.filterGroup!),
      searchFunction: userClient.SearchUsers.bind(userClient) as any,

      setIsLoading: (loading) => dispatch(SetUserIsLoading(loading)),
      setResult: (result) =>
          dispatch(SetUserResult(result.page!, result.count!, result.result!)),
  });



  return (
    <CContainer className="bg-white p-4">
      <SearchForm userRole={user??''} />
      <SearchResult userRole={user??''}/>
    </CContainer>
  );
};

export default SearchUsers;
