import { SortingRuleAppModel } from "../../models/app";

const sortingruleState: SortingRuleAppModel = {
  sortingrulesSearch: {
    count: 0,
    isLoading: false,
    limit: 10,
    page: 0,
    result: [],
    filterGroup: {
      page: 0,
      limit: 10,
      filters: [],
    },
  },
};

export default sortingruleState;
