import {CButton, CCol, CModal, CModalBody, CModalHeader, CRow,} from "@coreui/react";
import React, {useContext, useEffect, useRef, useState} from "react";
import validator from "validator";
import LookupsClient from "../../../../clients/lookupsClient";
import {QuotationChargeInfo} from "../../../../models/quotation";
import {notEmpty} from "../../../../utils/commonValidators";
import {Form, FormRef, Input, Submit} from "../../../SharedComponents/SWForm";
import SWFormContext from "../../../SharedComponents/SWForm/SWFormContext";
import ActionButton from "../../../SharedComponents/ActionButton";

interface Props {
  onAdd: (body: QuotationChargeInfo) => void;
  onEdit: (idx: number, body: QuotationChargeInfo) => void;
  editTarget: number | null;
  createMode: boolean;
  setCreateMode: (createMode: boolean) => void;
  setEditTarget: (idx: number | null) => void;
  items: QuotationChargeInfo[];
  readonly?: boolean;
  taxRateAllowed: boolean;
}

const AddEditItem = (props: Props) => {
  const {
    items,
    onAdd,
    onEdit,
    readonly,
    createMode,
    setCreateMode,
    editTarget,
    setEditTarget,
    taxRateAllowed,
  } = props;
  const lookupsClient = new LookupsClient();

  const formRef = useRef<FormRef | null>(null);

  const ctx = useContext(SWFormContext);

  const [chargeTypes, setChargeTypes] = useState<{ [k: string]: string }>({});

  useEffect(() => {
    lookupsClient.getCharges({availableOn: "quotation", format: 1}).then((v) => {
      v && setChargeTypes(v);
    });
  }, []);

  return (
    <div className="my-3">
      {(createMode || editTarget != null) && (
        <CModal
          closeOnBackdrop={false}
          onClose={() => {
            setEditTarget(null);
            setCreateMode(false);
          }}
          show={createMode || editTarget != null}
          className="p-3"
        >
          <CModalHeader>{createMode ? "Add" : "Edit"} Item</CModalHeader>
          <CModalBody>
            <Form
              ref={formRef}
              initialState={editTarget != null ? items[editTarget] : {}}
              className="p-3 small"
              onSubmit={(body) => {
                // const transformedBody = { ...body, taxRate: body.taxRate / 100 };
                const transformedBody = body;

                let itemValue= Number(transformedBody.value);
                let itemQuantity =Number(transformedBody.quantity) ;
                let itemTaxRate =Number(transformedBody.taxRate) / 100;
                let itemTotalcharge =itemQuantity* itemValue 


                transformedBody.totalValue = itemTotalcharge +  (itemTaxRate * itemTotalcharge);
                transformedBody.quantity = itemQuantity;
                if (editTarget == null) {
                  onAdd(transformedBody);
                } else {
                  onEdit(editTarget, transformedBody);
                }

                setEditTarget(null);
                setCreateMode(false);

                return {
                  taxRate: "",
                  value: "",
                };
              }}
            >


              <CRow>
                
                <CCol>
                <Input
                field="typeId"
                type="typeahead"
                label="Type"
                onChange={(val) => {
                  lookupsClient.getCharge(val.toString()).then((v) => {
                    formRef.current?.changeValue(
                      "taxRate",
                      taxRateAllowed ? v?.taxRate : 0
                    );
                    formRef.current?.changeValue("value", v?.defaultValue ?? 0);
                    formRef.current?.changeValue("quantity", 1);
                  });
                }}
                validators={[
                  (val) => {
                    if (validator.isEmpty(val as string)) return "Required";
                    return "";
                  },
                ]}
                lookUp={chargeTypes}
              />
                  </CCol>
                  
                  <CCol>
                  <Input
                validators={[
                  notEmpty,
                  (val) => {
                    if (!validator.isNumeric(val.toString())) {
                      return "Must be a number";
                    }
                    return "";
                  },
                  (val) => {
                    if (Number.parseFloat(val as string) > 100) {
                      return "Must be less than 100";
                    }
                    if (Number.parseFloat(val as string) < 0) {
                      return "Must be more than, or equal, to 0";
                    }
                    return "";
                  },
                ]}
                label="Tax Rate"
                field="taxRate"
                type="text"
                append="%"
                readonly
              />
                    </CCol>
               </CRow>


               <CRow>

                 <CCol> <Input
                validators={[
                  notEmpty,
                  (val) => {
                    if (Number.parseFloat(val as string) <= 0) {
                      return "Must be more than 0";
                    }
                    return "";
                  },
                ]}
                label="Quantity"
                field="quantity"
                type="text"
              /></CCol>
                 <CCol> <Input
                validators={[
                  notEmpty,
                  (val) => {
                    if (Number.parseFloat(val as string) <= 0) {
                      return "Must be more than 0";
                    }
                    return "";
                  },
                ]}
                label="Value"
                field="value"
                type="text"
              /></CCol>
               </CRow>
              
             

             

              <Input
                validators={[]}
                label="Comments"
                field="comments"
                type="text"
                maxLength={1000}
              />
              <CRow className="justify-content-between">
                <CCol>
                  <CButton
                  style={{fontSize:"10px"}}
                    onClick={() => {
                      setEditTarget(null);
                      setCreateMode(false);
                    }}
                    color="danger"
                    variant="outline"
                  >
                    Cancel
                  </CButton>
                </CCol>
                <CCol className="text-right">
                  <ActionButton
                      extraClass={"ml-2"}
                      text={`${editTarget != null ? "Update" : "Add new "} item`}
                  />
                </CCol>
              </CRow>
            </Form>
          </CModalBody>
        </CModal>
      )}
    </div>
  );
};

export default AddEditItem;
