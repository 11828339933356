import {CCol, CContainer, CInputCheckbox, CLabel, CRow, CTooltip} from "@coreui/react";
import React, {useEffect, useState} from "react";
import SearchForm from "./components/SearchForm";
import {
    InventoryTransaction, InventoryTransactionDetailDto,
    InventoryTransactionSearchFilter,
} from "../../../../models/warehouse";
import SearchResult from "./components/SearchResult";
import WarehouseClient from "../../../../clients/warehousesClient";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import {querytoMap, toQueryParam} from "../../../../utils/filterUtils";
import {useHistory, useLocation} from "react-router";
import {IPaginatedResult, IPagination} from "../../../../models/filter";
import RatesClient from "../../../../clients/ratesClient";
import SearchResultDetails from "./components/SearchResultDetails";
import shipClient from "../../../../clients/shipClient";
import { useDispatch } from "react-redux";
import { SetIsLoading } from "../../../../store/actions/ui";

const SearchStock = () => {
    const client = new WarehouseClient();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState<boolean>(false)
    const [paginatedStock, setPaginatedStock] = useState<IPaginatedResult<InventoryTransaction>>({
        matches: [],
        total: 0
    })
    const [paginatedStockDetails, setPaginatedStockDetails] = useState<IPaginatedResult<InventoryTransactionDetailDto>>({
        matches: [],
        total: 0
    })
    const [pagination, setPagination] = useState<IPagination>({
        limit: 20,
        offset: 0,
    });
    let {search, pathname} = useLocation();
    const hist = useHistory();
    const loc = useLocation()
    const param = new URLSearchParams(loc.search)
    const [accountsLookup, setAccountsLookup] = useState<{ [k: string]: string }>({});
    const [types, setTypes] = useState<{ [k: string]: string }>({})
    const [searchDetails, setSearchDetails] = useState<boolean>(false)

    useEffect(() => {
        const map = querytoMap<InventoryTransactionSearchFilter>(search);
        setPagination((state) => ({offset: map.offset || state.offset, limit: map.limit || state.limit}));
        handleSearch(map);
    }, [search])

    useEffect(() => {
        loadAccountsLookup();
        getTypes()
    }, [])

    const handleSearch = async (filter: InventoryTransactionSearchFilter) => {
        dispatch(SetIsLoading(true))
        setLoading(true)
        let res;
        if (searchDetails) {
            res = await client.searchStockDetails(filter)
            setPaginatedStockDetails({matches: res.matches, total: res.total});
        }
        else
        {
            res = await client.searchStock(filter)
            setPaginatedStock({matches: res.matches, total: res.total});
        }
        setLoading(false)
    }
    const loadAccountsLookup = async () => {
        const ratesClient = new RatesClient();
        const customers = await ratesClient.LookupCustomers();
        setAccountsLookup(customers);
    }

    const HandleUpdate = (code: string) => {
        hist.push(`transactions/${code}`)
    }

    const getTypes = async () => {
        let types = await client.getSubTypesLookup()
        setTypes(types)
    }

    const handleShowDetailsChange = async () => {
        let isCheck = !searchDetails
        const modifiedSearch = new URLSearchParams(loc.search);
        if (!isCheck) {
            modifiedSearch.set("showDetails",'false');
            hist.push(
                `${loc.pathname}?${modifiedSearch.toString()}`
            )
        }
        else {
            modifiedSearch.set("showDetails",'true');
            hist.push(
                `${loc.pathname}?${modifiedSearch.toString()}`
            );
        }
        setSearchDetails(isCheck)
    }
    return (
        <>
            <CContainer className="bg-white p-4">
                <SearchForm onClearClicked={() => {
                    hist.push(pathname)
                    setSearchDetails(false)
                }} accountsLookup={accountsLookup}
                            onSearchClicked={(filter) => {
                                hist.push(
                                    `${pathname}?${toQueryParam({...pagination, ...filter})}`
                                );
                            }} typesLookup={types}
                            showDetails={searchDetails}
                />
                <CRow className={"justify-content-start text-primary p-1"}>
                    <CCol>
                    <CTooltip content={"Add new stock transaction"}>
                        <CIcon
                            content={freeSet.cilPlus}
                            onClick={() => hist.push("transactions/create")}
                            className="ml-3 mt-1"
                            style={{
                                height: "1.5em",
                                width: "1.5em",
                                cursor: "pointer"
                            }}
                        />
                    </CTooltip>
                    </CCol>
                    <CCol md={2} style={{maxWidth:"10%"}}>
                        <CInputCheckbox
                            checked={searchDetails}
                            onChange={() => handleShowDetailsChange()}
                        /><CLabel>Show Details</CLabel>
                    </CCol>
                </CRow>
                {Boolean(param.get("showDetails") ==='true') ?
                    <SearchResultDetails
                        paginatedStock={paginatedStockDetails}
                        pagination={pagination}
                        isLoading={loading}
                        accountsLookup={accountsLookup}
                        type={types}
                    />
                    :
                    <SearchResult
                    paginatedStock={paginatedStock}
                isLoading={loading}
                pagination={pagination}
                accountsLookup={accountsLookup}
                type={types}
                onUpdate={HandleUpdate}
            />}
            </CContainer>
        </>
    );
};

export default SearchStock;
