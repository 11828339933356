import { CCol, CDataTable,CLink, CBadge, CRow } from "@coreui/react";
import React, { useState } from "react";
import PageCount from "../../../../SharedComponents/PageCount";
import {useHistory, useLocation} from "react-router";
import {
    AllocationStrategy,
    AllocationType, InventoryTransaction,
    SkuSearch,
} from "../../../../../models/warehouse";
import { IPaginatedResult, IPagination } from "../../../../../models/filter";
import { CIcon } from "@coreui/icons-react";
import { freeSet } from "@coreui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import ViewImagesModal from "../../../../SharedComponents/ViewImagesModal";

interface Props {
    paginatedSkus: IPaginatedResult<SkuSearch>;
    pagination: IPagination;
    isLoading: boolean;
    accountsLookup: { [k: string]: string };
    onUpdate: (id: number) => void;
    handleDuplicate: (item: SkuSearch) => void
    handleEditQuantities: (item: SkuSearch, type: string) => void
}

const SearchResult = (props: Props) => {
    const [limit, setLimit] = useState<number>(20)
    const hist = useHistory();
    const loc = useLocation();
    const [showImagesModal, setShowImagesModal] = useState<{ isOpen: boolean, images: string[] } | null>(null);

    return (
        <div className="small">
            {/*{props.isLoading ? (*/}
            {/*  <CSpinner*/}
            {/*    className="mx-auto d-block my-5"*/}
            {/*    color="primary"*/}
            {/*    style={{ width: "5em", height: "5em" }}*/}
            {/*  />*/}
            {/*) : (*/}
            <>
                <CDataTable
                    size="sm"
                    hover
                    loading={props.isLoading}
                    scopedSlots={{
                        name: (item: SkuSearch) => {
                            return <td>
                                <CCol>{item.name}</CCol>
                            </td>
                        },
                        allocationType: (data: SkuSearch) => {
                            return <td>{AllocationType[data.allocationType]}</td>;
                        },
                        allocationStrategy: (data: SkuSearch) => {
                            return <td>{AllocationStrategy[data.allocationStrategy]}</td>;
                        },
                        accountName: (data: SkuSearch) => {
                            return <td>{props.accountsLookup[data.accountNumber]}</td>;
                        },
                        actions: (item: SkuSearch) => {
                            return <td><CLink to={`/stock/skus/${item.id}`} className="card-header-action">
                                <FontAwesomeIcon icon={faEye}/>
                            </CLink></td>
                        },
                        internalCode: (item: SkuSearch) => {
                            return <td>{item.internalCode}</td>
                        },
                        editQuantity: (item: SkuSearch) => {
                            return <td>
                                <CRow>
                                    {item.active &&
                                        <>
                                            <CIcon
                                                content={freeSet.cilMinus}
                                                style={{cursor: "pointer", margin: "auto"}}
                                                onClick={() => props.handleEditQuantities(item, "CONS")}
                                            />
                                            <CIcon
                                                content={freeSet.cilPlus}
                                                style={{cursor: "pointer", margin: "auto"}}
                                                onClick={() => props.handleEditQuantities(item, "RECV")}
                                            />
                                        </>}
                                    {!item.active &&
                                        <CBadge
                                            style={{fontSize: "10px"}}
                                            shape="pill"
                                            color="danger"
                                        >InActive
                                        </CBadge>
                                    }
                                    <CIcon
                                        content={freeSet.cilCopy}
                                        style={{ cursor: "pointer", margin: "auto" }}
                                        onClick={() => props.handleDuplicate(item)}
                                    />
                                    {Boolean(item?.imageUrl) &&
                                        <CIcon
                                            content={freeSet.cilImage1}
                                            style={{ cursor: "pointer", margin: "auto" }}
                                            onClick={() => setShowImagesModal({ isOpen: true, images: [item?.imageUrl!] })}
                                        />
                                    }
                                </CRow>
                            </td>
                        },
                        duplicate: (item: SkuSearch) => (
                            <td></td>
                        )
                    }}
                    items={props.paginatedSkus.matches}
                    fields={[
                        {key: "actions", label: "", _style: {width: '1%'}},
                        "name",
                        "code",
                        "internalCode",
                        "allocationStrategy",
                        "allocationType",
                        "accountName",
                        "quantity",
                        "quantityAvailable",
                        {key: "editQuantity", label: "Actions"},
                        {key: "duplicate", label: ""}
                    ]}
                />
                <PageCount
                    onPageChange={(p) => {
                        const modifiedSearch = new URLSearchParams(loc.search);
                        modifiedSearch.delete("offset");
                        hist.push(
                            `${loc.pathname}?${modifiedSearch.toString()}&offset=${
                                p * limit!
                            }`
                        );
                    }}
                    onPageSizeChange={(ps) => {
                        const modifiedSearch = new URLSearchParams(loc.search);
                        modifiedSearch.delete("limit");
                        hist.push(
                            `${loc.pathname}?${modifiedSearch.toString()}&limit=${ps}`
                        );
                        setLimit(ps)
                    }}
                    currentPage={Math.ceil(
                        props?.pagination?.offset! / limit!
                    )}
                    maxPage={Math.ceil(
                        props.paginatedSkus?.total! / limit!
                    )}
                    maxFound={props.paginatedSkus?.total}
                />
            </>
            {/*)}*/}
            {
                showImagesModal?.isOpen &&
                <ViewImagesModal
                    onClose={() => setShowImagesModal(null)}
                    images={showImagesModal?.images}
                />
            }
        </div>
    );
};

export default SearchResult;
