
import { RateSearchRequest } from "../../models/retes";
import {
  SET_SEARCH_COSTS,
  SET_SEARCH_SELLING
} from "../types";


export const setSearchCosts = (payload: RateSearchRequest) => {
  return {
    type: SET_SEARCH_COSTS,
    payload,
  }
}

export const setSearchSelling = (payload: RateSearchRequest) => {
    return {
      type: SET_SEARCH_SELLING,
      payload,
    }
  }
