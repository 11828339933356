import {AddressBook} from "../../../../models/addressBook";
import {CCol, CContainer, CDataTable, CInputCheckbox, CRow} from "@coreui/react";
import React from "react";
import ActionButton from "../../../SharedComponents/ActionButton";
import {freeSet} from "@coreui/icons";
import CIcon from "@coreui/icons-react";

type Props = {
    handleClick: (e: { type: 'EDIT' | 'DELETE' | 'ADD', id: string | null }) => void
    data: AddressBook[]
}
const AddressBookTable = ({data, handleClick}: Props) => {
    const scopedSlots = {
        delete: (item: AddressBook) => <td><CIcon
            content={freeSet.cilTrash}
            onClick={() => handleClick({type: 'DELETE', id: item.id?.toString() ?? null})}
            style={{cursor: "pointer", margin: "auto"}}
        /></td>,
        edit: (item: AddressBook) => <td><CIcon
            onClick={() => handleClick({type: 'EDIT', id: item.id?.toString() ?? null})}
            content={freeSet.cilPencil} style={{cursor: "pointer", margin: "auto"}}/></td>,
        defaultForShipper: (item: AddressBook) => {
            return <td>{item.defaultForShipper != null ? item.defaultForShipper ? 'Yes' : 'No' : ''}</td>
        },
        defaultForConsignee: (item: AddressBook) => {
            return <td>{item.defaultForConsignee != null ? item.defaultForConsignee ? 'Yes' : 'No' : ''}</td>
        }
    }
    return (
        <>
            <CContainer>
                <CDataTable
                    size={"sm"}
                    hover
                    items={data}
                    scopedSlots={scopedSlots}

                    fields={[
                        {key: "id", label: "Id"},
                        {key: "name", label: "Name"},
                        {key: "accountName", "label": "Account"},
                        {key: "email", label: "Email"},
                        {key: "phone", label: "Phone"},
                        {key: "country", label: "Country"},
                        {key: "city", label: "City"},
                        {key: "eori", label: "Eori"},
                        {key: "defaultForShipper", label: "Default Shipper"},
                        {key: "defaultForConsignee", "label": "Default Consignee"},
                        {key: "edit", label: ""},
                        {key: "delete", label: ""}

                    ]}
                />
                <CRow className="d-flex justify-content-end">
                    <CCol md={3} className="d-flex justify-content-end">
                        <ActionButton text={'Add'} onClick={() => handleClick({type: 'ADD', id: null})}/>
                    </CCol>
                </CRow>
            </CContainer>
        </>
    )
}

export default AddressBookTable
