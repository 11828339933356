import {
    CCol,
    CContainer,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CRow,
} from "@coreui/react";
import SWSelect from "../../../SharedComponents/SWSelect";
import SWInput from "../../../SharedComponents/SWInput";
import {
    getAllocationStrategyLoookup,
    getAllocationTypeLoookup,
    getUnitLoookup,
    getWeightUnitLoookup,
} from "../../../../utils/lookupUtils";
import React, { useEffect, useState } from "react";
import RatesClient from "../../../../clients/ratesClient";
import { setNestedObjectValues, useFormik } from "formik";
import {
    AllocationStrategy,
    AllocationType,
    DimensionUnit,
    SkuCreate, SkuSearch,
    SkuUpdate, warehouse,
    WeightUnit,
} from "../../../../models/warehouse";
import WarehouseClient from "../../../../clients/warehousesClient";
import { useDispatch } from "react-redux";
import { SetLoading } from "../../../../store/actions/auth";
import * as yup from "yup";
import SWInputNumber from "../../../SharedComponents/SWInputNumber";
import SWMultiSelect from "../../../SharedComponents/SWMultiSelect";
import SWTextArea from "../../../SharedComponents/SWTextArea";
import ActionButton from "../../../SharedComponents/ActionButton";
import CameraIcon from "../../../../icons/camera";
import CaptureImageModal from "../../../SharedComponents/CaptureImageModal";

interface IProps {
    onSubmitCompleted: (sku: SkuCreate) => any;
    onClose: () => any;
    isShowing: boolean;
    updateId?: number;
    warehouse: string
    accountNumber: string
    seller: string | null | undefined
    duplicateSku?: SkuSearch
}

function CreateUpdateSKUModal(props: IProps) {
    const warehouseClient = new WarehouseClient();
    const { isShowing, onSubmitCompleted, onClose, updateId, warehouse, accountNumber, seller } = props;
    const [captureModal, setCaptureModal] = useState<boolean>(false);
    const [fileLoading, setFileLoading] = useState<boolean>(false);
    const [attachmentLink, setAttachmentLink] = useState<string>('');
    useEffect(() => {
        if (props.duplicateSku) {
            let sku = props.duplicateSku
            setFieldValue("accountNumber", sku.accountNumber)
            setFieldValue("seller", sku.seller)
            setFieldValue("code", sku.code)
            setFieldValue("name", sku.name)
            setFieldValue("internalCode", sku.internalCode)
            setFieldValue("barcode", sku.barcode)
            setFieldValue("weight", sku.weight)
            setFieldValue("dimensions", sku.dimensions)
            setFieldValue("breakCount", sku.breakCount)
            setFieldValue("minReorder", sku.minReorder)
            setFieldValue("allocationStrategy", sku.allocationStrategy)
            setFieldValue("allocationType", sku.allocationType)
            setFieldValue("expiryDays", sku.expiryDays)
            sku?.imageUrl && setAttachmentLink(sku?.imageUrl)
            getWarehouseCodes(sku.allowedWarehouses)
        }
    }, [props.duplicateSku])

    useEffect(() => {
        loadLookups();
        sellersLookups();
    }, []);
    const dispatch = useDispatch();

    const [accountsLookup, setAccountsLookup] = useState<{
        [k: string]: string;
    }>();
    const [sellersLookup, setSellersLookup] = useState<{ [k: string]: string }>({});
    const [fullWarehouse, setFullWarehouse] = useState<warehouse[]>()
    const [warehouseLookup, setWarehouseLookup] = useState<{
        [k: string]: string;
    }>({});
    const [allowedSellerCodes, setAllowedSellerCodes] = useState<string[]>()
    const [allowedWarehouseIds, setAllowedWarehouseIds] = useState<number[]>()
    const [selectedWarehouses, setSelectedWarehouses] = useState<{ value: string, label: string }[]>([])
    const [warehouseValidator, setWarehouseValidator] = useState<boolean>(false)
    const [errMessage, setErrMessage] = useState<string | null>(null)

    const sellersLookups = async () => {
        const client = new WarehouseClient();

        const sellers = await client.SellersLookup();
        setSellersLookup({null: " None", ...sellers});
    };
    const loadLookups = async () => {
        const ratesClient = new RatesClient();
        let warehouseClient = new WarehouseClient();

        // const warehouses = await warehouseClient.warehousesLookup();
        // setWarehouseLookup(warehouses);
        const customers = await ratesClient.LookupCustomers();
        setAccountsLookup(customers);
    };

    const getWarehouseCodes = async (ids?: number[]) => {
        if (!ids) return
        let client = new WarehouseClient()
        let warehouses: warehouse[] = await client.searchWarehouse()
        let allowed = warehouses.filter(w => ids.indexOf(w.id) !== -1)
        setFullWarehouse(allowed)
        let res: { value: string, label: string }[] = []
        allowed.forEach(a => {
            res.push({value: a.code, label: a.code})
        })
        setSelectedWarehouses(res)
    }

    const {
        setTouched,
        setValues,
        values,
        errors,
        handleBlur,
        touched,
        handleChange,
        resetForm,
        setFieldValue,
        handleSubmit,
    } = useFormik<SkuCreate>({
        initialValues: {
            imageUrls: [],
            expiryDays: null,
            warehouse: warehouse,
            barcode: "",
            id: 0,
            name: "",
            accountNumber: accountNumber,
            active: null,
            allocationStrategy: AllocationStrategy.None,
            allocationType: AllocationType.Fifo,
            code: "",
            dimensions: {
                height: null,
                length: null,
                width: null,
                unit: DimensionUnit.cm,
            },
            internalCode: '',
            breakCount: 1,
            minReorder: 0,
            seller: seller,
            weight: {
                value: null,
                unit: WeightUnit.kg,
            },
        },
        onSubmit: async (values) => {

            dispatch(SetLoading(true));
            let client = new WarehouseClient();
            let res;
            setErrMessage(null)

            // @ts-ignore
            let warehouses: warehouse[] =
                selectedWarehouses.map(w => {
                    return fullWarehouse?.find(v => v.code == w.value)
                })
            let allowedWarehouses = warehouses.map(w => {
                return w.id
            })
            let req = {
                ...values,
                allowedWarehouses,
                imageUrls: !!attachmentLink ? [...values?.imageUrls || [], attachmentLink] : values?.imageUrls
            }

            if (values.barcode) {
                let barcodes = values.barcode.split("\n")
                req = {...req, barcodes}
            }

            if (updateId) res = await client.UpdateSKU(updateId, req as SkuUpdate);
            else res = await client.CreateSKU(req);
            dispatch(SetLoading(false));


            if (res.succeeded) {
                resetForm();
                onSubmitCompleted(values);
                onClose();
            } else if (res.status === 400) {
                if (res.data.SWException) {
                    setErrMessage(res.data.SWException[0]);
                }
                else {
                    const messageKey = Object.keys(res.data)[0];
                    const messageValue = res.data[messageKey][0];
                    setErrMessage(messageValue)
                }
            }
        },
        validationSchema: yup.object({
            code: yup.string().max(20).required(),
            name: yup.string().max(100).required(),
            accountNumber: yup.string().required(),
            breakCount: yup.number().required(),
            minReorder: yup.number().required(),
            weight : yup.object({
                value: yup.string().nullable().required("Required")
            })
        }),
    });


    const save = () => {

        if (Object.keys(errors).length > 0) {
            setTouched(setNestedObjectValues(errors, true));

        }
        if (selectedWarehouses.length == 0) {
            setWarehouseValidator(true)
            return
        }
        handleSubmit();
    }
    const getAccount = async () => {
        let client = new WarehouseClient();
        let res = await client.GetAccount(values.accountNumber);
        if (res?.defaultSellerCode)
            await setFieldValue("seller", res?.defaultSellerCode);
        if (res?.allowedSellers)
            setAllowedSellerCodes(res.allowedSellers)
        if (res?.allowedWarehouses)
            setAllowedWarehouseIds(res.allowedWarehouses)
    }

    useEffect(() => {
        if (values.accountNumber) {

            (async () => await getAccount())()
        }
    }, [updateId, values.accountNumber]);

    useEffect(() => {
        if (updateId) {
            setSku();
        }
    }, [updateId]);

    useEffect(() => {
        SetAllowedWarehouses()
    }, [allowedWarehouseIds])

    useEffect(() => {
        SetAllowedSellers()
    }, [allowedSellerCodes])

    const setSku = async () => {
        let client = new WarehouseClient();
        let sku = await client.getSku(updateId);
        await setValues({...sku});


    };

    const handleClose = () => {
        onClose();
        resetForm();
    };

    const HandleAccountChange = async (val: string | undefined) => {
        await setFieldValue("accountNumber", val)
        await setFieldValue("seller", "")
        await setFieldValue("allowedWarehouseCodes", [])
        setSelectedWarehouses([])
        let client = new WarehouseClient
        if (val != undefined) {
            let account = await client.GetAccount(val);

            let sellerCode = account.defaultSellerCode;
            if (sellerCode) {
                await setFieldValue("seller", sellerCode)
            }
            setAllowedSellerCodes(account?.allowedSellers ?? [])
            setAllowedWarehouseIds(account.allowedWarehouses ?? [])

        }
    }

    const SetAllowedWarehouses = async () => {
        let client = new WarehouseClient()
        let warehouses: warehouse[] = await client.searchWarehouse()
        let allowed: warehouse[]
        if (!allowedWarehouseIds) {
            allowed = warehouses
        } else {
            allowed = warehouses.filter(w => allowedWarehouseIds?.indexOf(w.id) !== -1)
        }
        setFullWarehouse(allowed)
        let lookup: { [K: string]: string } = {}
        allowed.map(a => lookup[a.code] = a.name)
        setWarehouseLookup(lookup)
        if (!allowedWarehouseIds) return
        let selected: { label: string, value: string }[] = []
        allowed.forEach(a => selected.push({label: a.name, value: a.code}))
        setSelectedWarehouses(selected)
    }

    const SetAllowedSellers = async () => {
        let client = new WarehouseClient()
        let sellers: { [K: string]: string } = await client.SellersLookup()
        let lookup: { [K: string]: string } = {}
        allowedSellerCodes?.forEach(s => {
            lookup[s] = sellers[s]
        })
        if (!allowedSellerCodes)
            lookup = sellers
        setSellersLookup(lookup)
    }

    const handleFileImport = async (file?: File) => {
        setFileLoading(true);
        if (!file) return
        let res = await warehouseClient.UploadSkuImage(file, values?.accountNumber)
        if (res.location) {
            setAttachmentLink(res?.location)
            setFileLoading(false)
        }
    }

    return (
        <div>
            <CModal show={isShowing} onClose={handleClose} closeOnBackdrop={false} size={"xl"}>
                <CModalHeader closeButton>
                    <h5>{props.updateId ? "Update SKU" : "Create SKU"}</h5>
                </CModalHeader>
                <CModalBody>
                    <CContainer className="bg-white p-4 text-black row">
                        <CCol md={3}>
                            <SWSelect readonly={Boolean(updateId)} onBlur={handleBlur}
                                validationErrors={touched.accountNumber && errors.accountNumber || ""}
                                label={"Account"} values={accountsLookup as { [k: string]: string }}
                                value={values.accountNumber} name={"accountNumber"}
                                onChange={HandleAccountChange} />
                        </CCol>
                        <CCol md={3}>
                            <SWMultiSelect
                                value={selectedWarehouses}
                                className="basic-multi-select"
                                label={"Allowed Warehouses"}
                                values={warehouseLookup}
                                name="allowedWarehouseCodes"
                                onChange={(e: { value: string, label: string }[]) => {
                                    setSelectedWarehouses(e)
                                    if (e.length == 0)
                                        setWarehouseValidator(true)
                                    else
                                        setWarehouseValidator(false)
                                }}
                                validationErrors={warehouseValidator && "please select at least one warehouse" || ""}
                            />
                        </CCol>


                        <CCol md={3}>
                            <SWSelect
                                onBlur={handleBlur}
                                validationErrors={(touched.seller && errors.seller) || ""}
                                label={"Seller"}
                                values={sellersLookup as { [k: string]: string }}
                                value={values.seller}
                                name={"seller"}
                                isClearable={true}
                                onChange={(e) => setFieldValue("seller", e)}
                            />
                        </CCol>

                        <CRow className={"col-md-12"}>
                            <CCol md={3}>
                                <SWInput
                                    readonly={Boolean(updateId)}
                                    onBlur={handleBlur}
                                    validationErrors={(touched.code && errors.code) || ""}
                                    label={"Code"}
                                    name={"code"}
                                    value={values.code}
                                    onChangeFormik={handleChange}
                                />
                            </CCol>
                            <CCol md={3}>
                                <SWInput
                                    onBlur={handleBlur}
                                    validationErrors={(touched.name && errors.name) || ""}
                                    label={"Name"}
                                    name={"name"}
                                    value={values.name}
                                    onChangeFormik={handleChange}
                                />
                            </CCol>
                            <CCol md={3}>
                                <SWInput
                                    onBlur={handleBlur}
                                    label={"Internal Code"}
                                    name={"internalCode"}
                                    value={values.internalCode}
                                    onChangeFormik={handleChange}
                                />
                            </CCol>
                            <CCol md={3}>
                                <SWTextArea
                                    readonly={Boolean(updateId)}
                                    onBlur={handleBlur}
                                    validationErrors={(touched.barcode && errors.barcode) || ""}
                                    label={"Barcodes"}
                                    value={values.barcode}
                                    name={"barcode"}
                                    onChangeFormik={handleChange}
                                />
                            </CCol>
                        </CRow>
                        <CRow className={"col-md-12"}>
                            <CCol md={3}>
                                <SWSelect

                                    label={"Allocation Strategy"}
                                    values={
                                        getAllocationStrategyLoookup() as { [k: string]: string }
                                    }
                                    value={values.allocationStrategy.toString()}
                                    name={"allocationStrategy"}
                                    onChange={(e) => setFieldValue("allocationStrategy", e)}
                                />
                            </CCol>
                            <CCol md={3}>
                                <SWSelect
                                    label={"Allocation Type"}
                                    values={getAllocationTypeLoookup() as { [k: string]: string }}
                                    value={values.allocationType?.toString()}
                                    name={"allocationType"}
                                    onChange={(e) => setFieldValue("allocationType", e)}
                                />
                            </CCol>

                            <CCol md={3}>
                                <SWInputNumber
                                    label={"Break Count"}
                                    name={"breakCount"}
                                    onChangeFormik={handleChange}
                                    value={values.breakCount ?? undefined}
                                    validationErrors={touched.breakCount && errors.breakCount || ""}
                                />
                            </CCol>
                            <CCol md={3}>
                                <SWInputNumber
                                    label={"Minimum Reorder"}
                                    name={"minReorder"}
                                    onChangeFormik={handleChange}
                                    value={values.minReorder}
                                    validationErrors={touched.minReorder && errors.minReorder || ""}

                                />
                            </CCol>
                        </CRow>

                        <CRow className={"col-md-12"}>
                            <CCol md={6} className={"h6"}>
                                Weight
                            </CCol>
                            <CCol md={6} className={"h6"}>
                                Dimensions
                            </CCol>
                        </CRow>
                        <CRow className={"col-md-12"}>
                            <CCol md={3}>
                                <SWInputNumber
                                    onBlur={handleBlur}
                                    validationErrors={
                                        (touched.weight?.value && errors.weight?.value) || ""
                                    }
                                    name={"weight.value"}
                                    value={values.weight.value ?? undefined}
                                    onChangeFormik={handleChange}
                                />
                            </CCol>
                            <CCol md={3}>
                                <SWSelect
                                    values={getWeightUnitLoookup() as { [k: string]: string }}
                                    value={values.weight?.unit?.toString()}
                                    name={"weight.unit"}
                                    onChange={(e) => setFieldValue("weight.unit", e)}
                                />
                            </CCol>

                            <CCol>
                                <SWInputNumber
                                    onBlur={handleBlur}
                                    validationErrors={
                                        (touched.dimensions?.width && errors.dimensions?.width) ||
                                        ""
                                    }
                                    // label={"Width"}
                                    name={"dimensions.width"}
                                    value={values.dimensions?.width ?? undefined}
                                    onChangeFormik={handleChange}
                                />
                            </CCol>

                            <CCol>
                                <SWInputNumber
                                    onBlur={handleBlur}
                                    validationErrors={
                                        (touched.dimensions?.height && errors.dimensions?.height) ||
                                        ""
                                    }
                                    // label={"Height"}
                                    name={"dimensions.height"}
                                    value={values.dimensions?.height ?? undefined}
                                    onChangeFormik={handleChange}
                                />
                            </CCol>
                            <CCol>
                                <SWInputNumber
                                    onBlur={handleBlur}
                                    validationErrors={
                                        (touched.dimensions?.length && errors.dimensions?.length) ||
                                        ""
                                    }
                                    // label={"Length"}
                                    name={"dimensions.length"}
                                    value={values.dimensions?.length ?? undefined}
                                    onChangeFormik={handleChange}
                                />
                            </CCol>
                            <CCol>
                                <SWSelect
                                    // label={"Unit"}
                                    values={getUnitLoookup() as { [k: string]: string }}
                                    value={values.dimensions?.unit?.toString()}
                                    name={"dimensions.unit"}
                                    onChange={(e) => setFieldValue("dimensions.unit", e)}
                                />
                            </CCol>
                        </CRow>
                        <CRow className={"col-md-12"}>
                            <CCol md={3}  >
                                <SWInputNumber
                                    onBlur={handleBlur}
                                    validationErrors={(touched.expiryDays && errors.expiryDays) || ""}
                                    label={"Expiry Days"}
                                    name={"expiryDays"}
                                    value={values.expiryDays ?? undefined}
                                    onChangeFormik={handleChange}
                                />
                            </CCol>
                            <CCol md={3}  >
                                <SWInput
                                    label={"Attachment Link"}
                                    name={"attachmentLink"}
                                    value={attachmentLink}
                                    onChange={(e) => setAttachmentLink(e)}
                                />
                            </CCol>

                            <CCol style={{ display: "flex", alignItems: "center" }}  >
                                {!fileLoading ? <SWInput
                                    className="mt-4" type={"file"} id={"input-file"}
                                    onChangeFile={(f) => handleFileImport(f)} />
                                    : <div
                                        className={"rounded mb-3 h-100 m-0 py-1 px-2 d-flex align-items-center justify-content-center border-primary"}>Please
                                        wait...</div>}

                                <CameraIcon
                                    style={{
                                        marginLeft: "1.5rem",
                                        marginTop: "0.5rem"
                                    }}
                                    tabIndex={-1}
                                    onClick={() => setCaptureModal(true)}
                                    tooltipText={'Capture'} />
                            </CCol>
                        </CRow>
                        {errMessage &&
                            <CRow>
                                <CCol className={"text-danger"}>
                                    <h5>{errMessage}</h5>
                                </CCol>
                            </CRow>}
                    </CContainer>
                </CModalBody>

                <CModalFooter>
                    <CRow className="justify-content-between w-100">
                        <CCol className="text-right">
                            <ActionButton
                                text={props.updateId ? "Update" : "Create"}
                                onClick={save}
                            />
                        </CCol>
                    </CRow>
                </CModalFooter>
                {captureModal &&
                    <CaptureImageModal
                        allowOneCaptureOnly
                        onClose={(e) => {
                            if (e) {
                                setAttachmentLink(e?.[0]?.location) // one capture
                            }
                            setCaptureModal(false)
                        }}
                        skusUploadData={{account:values?.accountNumber}}
                    />}
            </CModal>
        </div>
    );
}

export default CreateUpdateSKUModal;
