import {CCol, CInput, CRow,} from "@coreui/react";
import React, {forwardRef} from "react";
import DatePicker from "react-datepicker";
import DateFormat from "dateformat";
import {parseISO} from 'date-fns'
import CIcon from "@coreui/icons-react";
import { cilCalendar, freeSet } from "@coreui/icons";

interface Props {
    readOnly?: boolean;
    value: string | any | null | undefined;
    showTimeInput?: boolean
    field?: any
    handleOnChange: (value: any) => void
    className?:any

}

const valueFormat = "yyyy-mm-dd'T'HH:MM:ss.l";

const ToCoreFormat = (value: string | any | null | undefined): Date | null | undefined => {

    if (!value || value instanceof Date)
        return value;

    return parseISO(value);
    //return new Date(value)
}

const CustomInput = forwardRef<any, any>(({coreValue, showTime, onClick}, ref) => {

    return (

        <span className="form-control small" onClick={onClick} ref={ref}> 
       <CRow className="d-flex justify-content-between ml-1 mr-1"> {formatValue(coreValue,showTime) }<CIcon content={freeSet.cilCalendar}/></CRow>
      </span>
    );
});

const formatValue = (value: Date | null | undefined, showTime?: boolean) => {

    if (!value) return ""

    if (showTime)
        return DateFormat(value, "dd-mm-yyyy hh:MM TT")
    else
        return DateFormat(value, "dd-mm-yyyy")
}


const DateAndTimePicker = (props: Props) => {

    const {readOnly, value, showTimeInput, field, handleOnChange} = props;

    const coreValue = ToCoreFormat(value);

    const onChange = (value: Date | null) => {
        if (!value)
            handleOnChange(value);
        //2021-04-10T10:00:00.0000000
        // @ts-ignore
        // @ts-ignore
        const valueToHandleOnChange = showTimeInput ? DateFormat(value as Date, valueFormat) : DateFormat(new Date(value.getFullYear(), value.getMonth(), value.getDate()), valueFormat)

        handleOnChange(valueToHandleOnChange);
    }

    if (readOnly) {

        return <CInput
            style={{fontSize:"10px"}}
            readOnly={readOnly}
            type="text"
            id={field}
            name={field}
            key={field}
            disabled={readOnly}
            value={formatValue(coreValue, showTimeInput)}

        />;
    } else {
        return (<DatePicker
                showYearDropdown
                readOnly={readOnly}
                selected={coreValue}
                showTimeInput={showTimeInput}
                onChange={onChange}
                customInput={<CustomInput  coreValue={coreValue} showTime={showTimeInput}/>}


            />
        );
    }
};

export default DateAndTimePicker;

