import validator from "validator";
import { SWInputFieldValue } from "../components/SharedComponents/SWForm/model";

export const notEmpty = (val: SWInputFieldValue) => {
  if (val == null || validator.isEmpty(val.toString())) {
    return "This field is required";
  }
  return "";
};

export const isNumber = (input: string) => {
  return /^[0-9.]+$/.test(input);
};
