import {
    CContainer,
    CDataTable,
    CLink,
    CSpinner
} from "@coreui/react";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import GatewayClient from "../../../clients/gatewayClient";
import {AgentTraceMap} from "../../../models/agents";
import { useDispatch } from "react-redux";
import { SetIsLoading } from "../../../store/actions/ui";


const TraceMaps = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [searchResult, setSearchResult] = useState<AgentTraceMap[]>([]);
    const gatewayClient = new GatewayClient();
    const searchTraceMaps = async () => {
        dispatch(SetIsLoading(true))
        let maps = await gatewayClient.SearchTraceMaps();
        setSearchResult(maps);
    }
    useEffect(() => {
        searchTraceMaps();
    }, [])
    const scopedSlots = {
        links: (item: AgentTraceMap) => {
            return <td>
                <CLink to={`/settings/tracemaps/${item.id}`} className="card-header-action">
                    <FontAwesomeIcon icon={faEye}/>
                </CLink>

            </td>;
        },
    }

    return (
        <>
            <CContainer className="bg-white p-4">
                {loading ? (
                    <CSpinner
                        className="mx-auto d-block my-5"
                        color="primary"
                        style={{width: "5em", height: "5em"}}
                    />
                ) : (
                    <>
                        <div className="small">
                            <CDataTable
                                size="sm"
                                hover
                                scopedSlots={scopedSlots}
                                items={searchResult}
                                fields={[
                                    {key: "links", label: "", _style: {width: '7%', padding: '0.25rem'}},
                                    {key: "id", label: "ID"},
                                    {key: "name", label: "Name"}
                                ]}
                            />

                        </div>
                    </>
                )}
            </CContainer>

        </>
    )
}

export default TraceMaps;
