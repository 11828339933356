import { freeSet } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {CButton, CCardBody, CCol, CRow, CTooltip } from "@coreui/react";
import React, { useState } from "react";
import { ShipmentSearch } from "../../../models/shipment";
import SendSMSModal from "./modals/SendSMSModal";
import ShipClient from "../../../clients/shipClient";
import {ShowNotification} from "../../../store/actions/auth";
import {useDispatch} from "react-redux";

interface IProps {
  shipment: ShipmentSearch;
  onEditClick: () => any;
  countries: {[key:string]:string};
  onUpdateShipment: (shipment: ShipmentSearch) => void;
}

const getShipperGoogleMapUrl = (shipment: ShipmentSearch): string => {
  const url = "https://www.google.com/maps/place/";
  let p = "";

  if (shipment?.shipperAddressLine1) p += shipment.shipperAddressLine1 + "+";

  if (shipment?.shipperPostCode) p += shipment.shipperPostCode + "+";
  if (shipment?.shipperCity) p += shipment.shipperCity + "+";

  return `${url}${p}`;
};

const ViewShipper = (props: IProps) => {
  const { shipment,countries } = props;
  const shipClient = new ShipClient();
  const [isHover, setisHover] = useState(false);
  const shipperGoogleUrl = getShipperGoogleMapUrl(shipment);
  const dispatch = useDispatch()
  const [showSendSMSModal, setShowSendSMSModal] = useState<boolean>(false);

  const handleSendSMS = async (message: string) => {
    const to = shipment.shipperPhone
    let rs = await shipClient.sendSMSToPhoneNumber(shipment.uid, {to, body: message})
    if (rs.succeeded) {
      dispatch(ShowNotification("Success", "SMS sent successfully", false))
    }
    setShowSendSMSModal(false)
  }

  return (
    <>
      <CCardBody
        className={`shipperCard mb-1 rounded   ${
          isHover ? "bg-secondary" : ""
        }`}
        onMouseOver={() => {
          setisHover(true);
        }}
        onMouseLeave={() => {
          setisHover(false);
        }}
      >
        <CRow className="mb-2">
          {" "}
          <CTooltip content="GoogleMaps location">
            <CIcon
              className="ml-3 mr-2"
              content={freeSet.cilLocationPin}
              size={"lg"}
              onClick={() => {
                window.open(shipperGoogleUrl);
              }}
            />
          </CTooltip>
          <h6>
            <strong>Shipper:</strong>
          </h6>
          <CCol className="text-right">
            <CIcon
              style={{ cursor: "pointer" }}
              className={`${isHover ? "primary" : "text-white"}`}
              content={freeSet.cilPencil}
              size={"lg"}
              onClick={props.onEditClick}
            />
          </CCol>
        </CRow>
       
        <CRow>
          <CCol>Company Name:</CCol>
          <CCol sm={8}>
            <strong>{shipment.shipperCompanyName} </strong>{" "}
          </CCol>
        </CRow>
        <CRow>
          <CCol>Contact Name:</CCol>
          <CCol sm={8}>
            <strong>{shipment.shipperName} </strong>
          </CCol>
        </CRow>
        <CRow>
          <CCol>Street:</CCol>
          <CCol sm={8}>
            <strong>{shipment.shipperAddressLine1}</strong>{" "}
          </CCol>
        </CRow>
        <CRow>
          <CCol>Add Extra:</CCol>
          <CCol sm={8}>
            <strong>
              {shipment.shipperAddressLine2} {shipment.shipperAddressLine3}{" "}
            </strong>
          </CCol>
        </CRow>
        <CRow>
          <CCol>Country:</CCol>
          <CCol sm={8}>
            <strong>{countries[shipment.shipperCountry]} </strong>
          </CCol>
        </CRow>
        <CRow>
          <CCol>Zip Code /City:</CCol>
          <CCol sm={8}>
            <strong>
              {shipment.shipperPostCode}, {shipment.shipperCity}
            </strong>
          </CCol>
        </CRow>
        <CRow>
          <CCol>Reference:</CCol>
          <CCol sm={8}>
            <strong>
              {shipment.shipperReference}
            </strong>
          </CCol>
        </CRow>
        <CRow>
          <CCol>Address Reference:</CCol>
          <CCol sm={8}>
            <strong>
              {shipment.shipperAddressReference}
            </strong>
          </CCol>
        </CRow>
        <CRow>
        <CCol>Shipper EORI:</CCol>
          <CCol sm={8}>
            <strong>
              {shipment?.shipperEori}
            </strong>
          </CCol>
        </CRow>
        <CRow>
          <CCol>Telephone:</CCol>
          <CCol sm={8}>
            <strong>{shipment.shipperPhone}
              {
              (""+shipment.shipperPhone).length > 5 ?
                  <CTooltip content="Send SMS">
                    <CButton size="sm">
                      <CIcon onClick={() => {
                        setShowSendSMSModal(true)
                      }} size={"lg"} content={freeSet.cilSpeech}/>
                    </CButton>
                  </CTooltip>
                  : null
            }

            </strong>
          </CCol>
        </CRow>
        <CRow>
          <CCol>Email:</CCol>
          <CCol sm={8}>
            <strong>{shipment.shipperEmail} </strong>
          </CCol>
        </CRow>
        <CRow>
          <CCol>Notes:</CCol>
          <CCol sm={8}>
            <strong>{shipment.shipperNotes}</strong>
          </CCol>
        </CRow>
        <CRow></CRow>
      </CCardBody>
      {showSendSMSModal && <SendSMSModal
          HandleClose={() => setShowSendSMSModal(false)}
          handleSubmit={handleSendSMS}
      />}
    </>
  );
};

export default ViewShipper;
