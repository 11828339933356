import React, {useEffect, useState} from "react";
import {
    CButton,
    CButtonGroup,
    CCol,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CInputCheckbox,
    CLabel,
    CRow,
} from "@coreui/react";
import SWSelect from "../../../SharedComponents/SWSelect";
import SWTextArea from "../../../SharedComponents/SWTextArea";
import {useFormik} from "formik";
import SWInput from "../../../SharedComponents/SWInput";
import {freeSet} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import RatesClient from "../../../../clients/ratesClient";
import {AppModel, RemoteBlob} from "../../../../models/app";
import ShipClient from "../../../../clients/shipClient";
import SWDateAndTimePicker from "../../../SharedComponents/SWDateAndTimePicker";
import DateRangeFilter from "../../../SharedComponents/SWAdvancedSearch/DateRangeFilter";
import NotFoundShipmentsModal from "../modals/NotFoundShipmentsModal";
import {convertFilterType, DataType, SearchyCondition, SearchyFilter, SearchyRule} from "../../../../models/Searchy";
import LookupsClient from "../../../../clients/lookupsClient";
import {Report} from "../../../../models/Report";
import {ShipmentSearchRequest} from "../../../../models/shipment";
import {downloadBlob} from "../../../../utils/downloadUtils";
import {useDispatch, useSelector, useStore} from "react-redux";
import {SetLoading} from "../../../../store/actions/auth";
import {SetShipmentsFilterGroupFilters} from "../../../../store/actions/shipments";
import ActionButton from "../../../SharedComponents/ActionButton";
import AuthClient from "../../../../clients/authClient";
import AccountingClient from "../../../../clients/accountingClient";

interface IProps {
    additionalFilters: {
        type: "text" | "select" | "date" | "number",
        field: string,
        title: string,
        lookups?: { [k: string]: string },
    }[]
    handleFilters: (condition: SearchyCondition) => void,
    missingShipmentNumbers: string[]
    allowedUsers: { [p: string]: string }
}

type AdditionalFiltersType = {
    name: string,
    type: string,
    dataType: number,
    value: string
}
const NewSearchFrom = (props: IProps) => {
    const initialFilters = useSelector((state: AppModel) => state.shipment?.shipmentsSearch?.filterGroup?.filters);
    const [accountLookup, setAccountLookup] = useState<{ [k: string]: string }>({})
    const [agentLookup, setAgentLookup] = useState<{ [k: string]: string }>({})
    const [report, setReport] = useState<Report[]>([])
    const [additionalFiltersLookup, setAdditionalFiltersLookup] = useState<{ [k: string]: string }>({})
    const [additionalFilters, setAdditionalFilters] = useState<AdditionalFiltersType[]>([])
    const [searchCondition, setSearchCondition] = useState<SearchyCondition>({
        filters: []
    })
    const [shipmentBlockedReasons, setShipmentBlockedReasons] = useState<{ [k: string]: string }>({});
    const [profileLookups, setProfileLookups] = useState<{ [k: string]: string }>({});

    const [showMissingNumbersModal, setShowMissingNumberModal] = useState<boolean>(false)
    const [selectedAccountingFilter, setSelectedAccountingFilter] = useState<string | undefined>("0")
    const shipClient = new ShipClient()
    const authClient = new AuthClient();
    const lookupsClient = new LookupsClient();
    const accountingClient = new AccountingClient();
    const ratesClient = new RatesClient()
    const dispatch = useDispatch()
    const store = useStore()
    useEffect(() => {
        accountingClient.LookupProfiles().then(p => setProfileLookups(p))
    }, []);

    /* const ops = {
        "1": "Equals",
        "2": "Not Equals",
        "4": "Like",
        "5": "GreaterThan",
        "7": "LessThan",
        "9": "One Of",
        "21": "Range",
    } */

    const textOps = {
        "1": "Equals",
        "2": "Not Equals",
        "4": "Like",
        "9": "One Of",
    }

    const selectOps = {
        "1": "Equals",
        "2": "Not Equals",
    }

    const dateTimeOps = {
        "1": "Equals",
        "5": "GreaterThan",
        "7": "LessThan",
        "21": "Range",
    }

    const numberOps = {
        "1": "Equals",
        "2": "Not Equals",
        "5": "GreaterThan",
        "7": "LessThan"
    }

    const shipmentStatus = {
        "0": "All",
        "1": "Pending",
        "2": "Delivered",
        "3": "Final",
        "4": "Returned",
        "5": "Discrepancy",
        "6": "Critical"
    }

    useEffect(() => {

        ratesClient.LookupCustomers().then(v => setAccountLookup(v))
        ratesClient.LookupAgents().then(v => setAgentLookup(v))
        lookupsClient.GetUiReports("ShipmentSearch").then(v => setReport(v))

        loadAdditionalFilterLookup()
    }, [])


    const {values, setFieldValue, handleSubmit, handleChange, resetForm} = useFormik({
        initialValues: {
            filters: [
                {
                    name: "Number",
                    type: initialFilters?.find((e) => e?.field === "Number")?.type?.toString() ?? "9",
                    dataType: DataType.string,
                    value: initialFilters?.find((e) => e?.field === "Number")?.value?.toString() ?? ""
                },
                {
                    name: "HasBooking",
                    type: "1",
                    dataType: DataType.boolean,
                    value: "true"
                },
                {
                    name: "Account",
                    type: "1",
                    dataType: DataType.string,
                    value: ""
                },
                {
                    name: "AccountReference",
                    type: initialFilters?.find((e) => e?.field === "AccountReference")?.type?.toString() ?? "4",
                    dataType: DataType.string,
                    value: initialFilters?.find((e) => e?.field === "AccountReference")?.value?.toString() ?? ""
                },
                {
                    name: "pickupDate",
                    type: "1",
                    dataType: DataType.date,
                    value: ""
                },
                {
                    name: "Deleted",
                    type: "1",
                    dataType: DataType.boolean,
                    value: "false"
                },
                {
                    name: "Agent",
                    type: "1",
                    dataType: DataType.string,
                    value: ""
                },
                {
                    name: "Status",
                    type: "1",
                    dataType: DataType.number,
                    value: "0"
                },
                {
                    name: "PendingCosts",
                    type: "1",
                    dataType: DataType.boolean,
                    value: ""
                },
                {
                    name: "PendingSelling",
                    type: "1",
                    dataType: DataType.boolean,
                    value: ""
                },
                {
                    name: "PendingPayment",
                    type: "1",
                    dataType: DataType.boolean,
                    value: ""
                },
                {
                    name: "HasErrors",
                    type: "1",
                    dataType: DataType.boolean,
                    value: ""
                },
                {
                    name: "Blocked",
                    type: "1",
                    dataType: DataType.boolean,
                    value: ""
                },
                {
                    name: "UnpaidCod",
                    type: "1",
                    dataType: DataType.boolean,
                    value: ""
                },
                {
                    name: "BlockReasonCode",
                    type: "1",
                    dataType: DataType.string,
                    value: ""
                },
                {
                    name: "AssignedTo",
                    type: "1",
                    dataType: DataType.string,
                    value: ""
                },
                {
                    name: "ProfileId",
                    type: "1",
                    dataType: DataType.string,
                    value: ""
                },

            ],
        },
        onSubmit: async () => {
            let filters = [...values.filters, ...additionalFilters]
            let index = filters.findIndex(f => f.name == "Number")
            if (index >= 0) {
                let numberFilter = filters[index]
                numberFilter.value = numberFilter.value?.replace(/ /g, "")
                filters[index] = numberFilter
            }
            let sc: SearchyCondition = {
                filters: []
            }
            filters.forEach((f) => {
                if (f.value) {
                    let isRange = f.type == "9" || f.type == "21"
                    let searchyFilter: SearchyFilter = {
                        field: f.name,
                        rule: f.type && Number(f.type) ? Number(f.type) : SearchyRule.EqualsTo,
                        value: convertFilterType(f.value, f.dataType, isRange)
                    }
                    if (isRange) {
                        if (f.dataType == DataType.string) {
                            searchyFilter.valueStringArray = searchyFilter.value
                            searchyFilter.value = undefined
                        }
                        if (f.dataType == DataType.number) {
                            searchyFilter.valueDecimalArray = searchyFilter.value
                            searchyFilter.value = undefined
                        }
                        if (f.dataType == DataType.date) {
                            searchyFilter.valueDateTimeArray = searchyFilter.value
                            searchyFilter.value = undefined
                        }
                    }
                    sc.filters?.push(searchyFilter)
                }
            })


            dispatch(SetShipmentsFilterGroupFilters(filters.filter(i => !!i.value).map(item => {
                let newItem = {
                    field: item.name,
                    type: Number(item.type),
                    value: item.value,
                    dataType: item.dataType
                }
                return (newItem)
            })))
            props.handleFilters(sc)
        }
    })

    useEffect(() => {
        let filters = [...values.filters, ...additionalFilters]
        let index = filters.findIndex(f => f.name == "Number")
        if (index >= 0) {
            let numberFilter = filters[index]
            numberFilter.value = numberFilter.value?.replace(/ /g, "")
            filters[index] = numberFilter
        }
        let sc: SearchyCondition = {
            filters: []
        }
        filters.forEach((f) => {
            if (f.value) {
                let isRange = f.type == "9" || f.type == "21"
                let searchyFilter: SearchyFilter = {
                    field: f.name,
                    rule: f.type && Number(f.type) ? Number(f.type) : SearchyRule.EqualsTo,
                    value: convertFilterType(f.value, f.dataType, isRange)
                }
                if (isRange) {
                    if (f.dataType == DataType.string) {
                        searchyFilter.valueStringArray = searchyFilter.value
                        searchyFilter.value = undefined
                    }
                    if (f.dataType == DataType.number) {
                        searchyFilter.valueDecimalArray = searchyFilter.value
                        searchyFilter.value = undefined
                    }
                    if (f.dataType == DataType.date) {
                        searchyFilter.valueDateTimeArray = searchyFilter.value
                        searchyFilter.value = undefined
                    }
                }
                sc.filters?.push(searchyFilter)
            }
        })
        setSearchCondition(sc)
    }, [values.filters, additionalFilters])


    const getAllowedAccountsFromProfile = async () => {
        if (values.filters[16].value != '') {
            const res = await accountingClient.GetAccounts(values.filters[16].value)
            if (res != null) {
                const formattedData: { [key: string]: string } = {};
                res.map((index: any) => {
                    formattedData[index] = index;
                })
                setAccountLookup(formattedData)
            }
        } else {
            ratesClient.LookupCustomers().then(v => setAccountLookup(v))
        }
    }
    useEffect(() => {
        getAllowedAccountsFromProfile()
    }, [values.filters[16].value]);


    const loadAdditionalFilterLookup = () => {
        let lookup: { [k: string]: string } = {}
        props.additionalFilters.forEach(fi => {
            lookup[fi.field] = fi.title
        })
        setAdditionalFiltersLookup(lookup)
    }


    const AddFilter = () => {
        setAdditionalFilters([...additionalFilters, {name: "", type: "1", dataType: DataType.string, value: ""}])
    }

    const RemoveFilter = (index: number) => {
        const list = [...additionalFilters]
        list.splice(index, 1)
        setAdditionalFilters(list)
    }

    const handleAdditionalFilterChange = (value: string, type: "name" | "type" | "value", index: number) => {
        let filters = [...additionalFilters]
        let filter = filters[index]
        filter[type] = value
        let dataType = DataType.string;

        if (value.includes("date")) {
            dataType = DataType.date
        }

        if (type == "type") {
            if (value == "text" || value == "select")
                dataType = DataType.string
            if (value == "date" || value.includes("date"))
                dataType = DataType.date
            if (value == "number")
                dataType = DataType.number
        }
        filters[index] = filter
        filter["dataType"] = dataType
        setAdditionalFilters(filters)
    }

    const getOpsTypeByName = (field: string) => {
        let filter = props.additionalFilters.find(af => af.field == field)
        switch (filter?.type) {
            case "text" :
                return textOps as { [l: string]: string }
            case "date":
                return dateTimeOps as { [l: string]: string }
            case "select" :
                return selectOps as { [l: string]: string }
            case "number" :
                return numberOps as { [l: string]: string }
        }
    }

    const getAdditionalFilterType = (field: string) => {
        let filter = props.additionalFilters.find(f => f.field == field)
        return filter?.type
    }

    const handleClear = () => {
        resetForm()
        setAdditionalFilters([{name: "shipper", type: "1", dataType: DataType.string, value: ""}])
        window.location.reload()
    }

    const getAdditionalFilterLookup = (field: string) => {
        let filter = props.additionalFilters.find(f => f.field == field)
        return filter?.lookups ?? {}
    }

    const handleReportDownload = async (reportId: number) => {
        dispatch(SetLoading(true))
        const rq: ShipmentSearchRequest = {
            conditions: [searchCondition],
            format: 2,
            reportId: reportId
        }
        let remoteBlob = await shipClient.getShipments(rq) as RemoteBlob
        if (!remoteBlob?.location)

            return;
        else
            downloadBlob(remoteBlob.location, remoteBlob.name)
    }

    useEffect(() => {
        const filtersFromStore = store.getState().shipment.shipmentsSearch.filterGroup.filters
        if (filtersFromStore.length > 0) {
            filtersFromStore.map((item: any) => {
                const indexOfItemInFilter = values.filters.findIndex(p => p.name == item.field)
                if (indexOfItemInFilter !== -1) {
                    setFieldValue(`filters[${indexOfItemInFilter}].value`, item.value)
                } else {
                    setAdditionalFilters([...additionalFilters, {
                        name: item.field,
                        type: "" + item.type,
                        dataType: DataType.string,
                        value: item.value
                    }])
                }
            })
            //handleSubmit()
        }
    }, [])

    useEffect(() => {
        if (!values.filters[8].value && !values.filters[9].value && !values.filters[13].value) return;
        if (values.filters[8].value == "true") setSelectedAccountingFilter("1")
        if (values.filters[9].value == "true") setSelectedAccountingFilter("2")
        if (values.filters[13].value == "true") setSelectedAccountingFilter("3")
    }, [values.filters])

    const handleAccountingFilterChange = (v?: string) => {
        setSelectedAccountingFilter(v)
        setFieldValue("filters[8].value", v == "1" ? "true" : "")
        setFieldValue("filters[9].value", v == "2" ? "true" : "")
        setFieldValue("filters[13].value", v == "3" ? "true" : "")
        setFieldValue("filters[10].value", v == "4" ? "true" : "")
    }


    useEffect(() => {
        const getShipmentBlockedReasons = async () => {
            const res = await lookupsClient.configurations({type: "ShipmentBlockedReasons"})
            const formattedData: { [key: string]: string } = {};
            if (res?.succeeded) {
                res?.data?.[0]?.data?.forEach((item: { [k: string]: string }) => {
                    formattedData[item.code] = item.value;
                });
                setShipmentBlockedReasons(formattedData)
            }
        }


        getShipmentBlockedReasons();
    }, [])
    return (
        <fieldset className="b-fieldset p-2 text-primary">
            <legend className="b-legend" style={{width: "90px"}}>
                <h5>Shipments</h5>
            </legend>
            <CRow>

                <CCol md={2} className={'d-flex flex-column justify-content-between '}>
                    <SWSelect label={"Hawb"} values={textOps} value={values.filters[0].type}
                              name={"filters[0].type"} onChange={e => setFieldValue("filters[0].type", e)}/>


                </CCol>
                <CCol md={3}>
                    <div>
                        {values.filters[0].type == "9" ?
                            <SWTextArea value={values.filters[0].value} name={"filters[0].value"} rows={6}
                                        onChangeFormik={handleChange}
                                        style={{marginTop: "28px"}}/> :
                            <SWInput value={values.filters[0].value} name={"filters[0].value"}
                                     onChangeFormik={handleChange} style={{marginTop: "28px"}}/>

                        }

                    </div>
                </CCol>
                <CCol md={5}>
                    <CRow>

                        <CCol md={7}>
                            <SWSelect values={profileLookups} value={values.filters[16].value}
                                      name={"filters[16].value"}
                                      isClearable={true}
                                      onChange={e => setFieldValue("filters[16].value", e ?? "")} label={"Profile"}/>
                        </CCol>
                        <CCol style={{height: "10%"}}>
                            <SWSelect values={props.allowedUsers} value={values.filters[15].value}
                                      name={"filters[15].value"}
                                      isClearable={true}
                                      onChange={e => setFieldValue("filters[15].value", e ?? "")}
                                      label={"Assigned User"}/>
                        </CCol>
                    </CRow>

                    <CRow>
                        <CCol>
                            <SWSelect values={accountLookup} value={values.filters[2].value} name={"filters[2].value"}
                                      isClearable={true}
                                      onChange={e => setFieldValue("filters[2].value", e ?? "")} label={"Account"}/>
                        </CCol>
                    </CRow>


                </CCol>
                <CCol>
                    <CRow style={{marginLeft: "52px"}}>
                        <CInputCheckbox
                            checked={values.filters[1].value == "true"}
                            onChange={() => setFieldValue("filters[1].value", values.filters[1].value == "true" ? "false" : "true")}
                        /> <CLabel>Label Generated</CLabel>
                    </CRow>
                    <CRow style={{marginLeft: "52px"}}>
                        <CInputCheckbox
                            checked={values.filters[5].value == "true"}
                            onChange={() => setFieldValue("filters[5].value", values.filters[5].value == "true" ? "" : "true")}
                        /> <CLabel>Deleted</CLabel>
                    </CRow>

                    <CRow style={{marginLeft: "52px"}}>
                        <CInputCheckbox
                            checked={values.filters[11].value == "true"}
                            onChange={() => setFieldValue("filters[11].value", values.filters[11].value == "true" ? "" : "true")}
                        /> <CLabel>Has errors</CLabel>
                    </CRow>
                    <CRow style={{marginLeft: "52px"}}>
                        <CInputCheckbox
                            checked={values.filters[12].value == "true"}
                            onChange={() => {
                                const isBlocked = values.filters[12].value == "true";
                                setFieldValue("filters[12].value", isBlocked ? "" : "true");
                                if (isBlocked) {
                                    setFieldValue("filters[14].value", "");
                                }
                            }}
                        /> <CLabel>Blocked</CLabel>
                    </CRow>
                    {values.filters[12].value && <CRow style={{marginLeft: "20px", width: '100%'}}>
                        <SWSelect label={"Reason"} value={values.filters[14].value}
                                  style={{width: '88%'}}
                                  values={shipmentBlockedReasons}
                                  onChange={(e) => setFieldValue("filters[14].value", e)}/>
                    </CRow>}


                </CCol>
                <CCol md={6}>
                    {props.missingShipmentNumbers &&
                        <CIcon
                            content={freeSet.cilWarning}
                            size={"xl"}
                            style={{marginLeft: "10%", color: "red", cursor: "pointer"}}
                            onClick={() => setShowMissingNumberModal(true)}
                        />}
                </CCol>
                <CCol md={4}/>
            </CRow>
            <CRow>


                <CCol md={2}>
                    <SWSelect values={textOps} value={values.filters[3].type}
                              name={"filters[3].type"}
                              onChange={e => setFieldValue("filters[3].type", e)} label={"Reference"}/>
                </CCol>
                <CCol md={3} className={'pt-4'}>
                    {values.filters[3].type == "9" ?
                        <SWTextArea value={values.filters[3].value} style={{marginTop: '4px'}} name={"filters[3].value"}
                                    onChangeFormik={handleChange}/> :
                        <SWInput value={values.filters[3].value} style={{marginTop: '4px'}} name={"filters[3].value"}
                                 onChangeFormik={handleChange}/>
                    }
                </CCol>
                <CCol md={4}>
                </CCol>


                <CCol md={3} className={'d-flex justify-content-end'}>

                    <CCol md={8} style={{marginRight: '-15px'}}>
                        <SWSelect

                            label={"$"}
                            values={{
                                "0": "All",
                                "1": "Has Pending Cost",
                                "2": "Has Pending Selling",
                                "3": "Has Unpaid Cod",
                                "4": "Has Unpaid Online Cod"
                            }}
                            value={selectedAccountingFilter} onChange={v => handleAccountingFilterChange(v)}
                        />
                    </CCol>
                </CCol>

            </CRow>

            <CRow>
                <CCol md={2}>
                    <SWSelect values={dateTimeOps} value={values.filters[4].type} name={"filters[4].type"}
                              label={"Pickup Date"} onChange={e => {
                        setFieldValue("filters[4].value", "")
                        setFieldValue("filters[4].type", e)
                    }}/>
                </CCol>
                <CCol md={3} style={{marginTop: "28px"}}>
                    {values.filters[4].type == "21" &&
                        <DateRangeFilter value={values.filters[4].value}
                                         onChange={e => setFieldValue("filters[4].value", e)}/> ||
                        <SWDateAndTimePicker value={values.filters[4].value}
                                             handleOnChange={e => setFieldValue("filters[4].value", e)}/>}
                </CCol>
                <CCol md={5}>
                    <SWSelect values={agentLookup} value={values.filters[6].value} name={"filters[6].value"}
                              isClearable={true}
                              onChange={e => setFieldValue("filters[6].value", e ?? "")} label={"Agent"}/>
                </CCol>
                <CCol style={{marginLeft: "20px"}}>
                    <SWSelect values={shipmentStatus} label={"Status"}
                              value={values.filters[7].value}
                              name={"filters[7].value"} onChange={v => setFieldValue("filters[7].value", v)}
                              backgroundColor={"#00007d"} textColor={"white"}/>
                </CCol>
            </CRow>
            <CRow>
                <CCol md={10}>
                    <CIcon
                        size={"xl"}
                        style={{cursor: 'pointer', marginBottom: "20px"}}
                        className="btn-icon"
                        content={freeSet.cilPlus}
                        onClick={AddFilter}
                    />
                </CCol>

            </CRow>
            {additionalFilters.map((v, i) => (
                <CRow style={{paddingLeft: "0"}} key={i}>
                    <CCol md={2} style={{maxWidth: "30%", paddingTop: '30px'}}>
                        <SWSelect values={additionalFiltersLookup} value={additionalFilters[i].name}
                                  onChange={e => {
                                      handleAdditionalFilterChange(e ?? "", "name", i)
                                      handleAdditionalFilterChange("", "type", i)
                                      handleAdditionalFilterChange("", "value", i)
                                  }}/>
                    </CCol>
                    <CCol md={2} style={{width: "20px", paddingTop: '30px '}}>
                        <SWSelect values={getOpsTypeByName(additionalFilters[i].name) ?? {}}
                                  value={additionalFilters[i].type}
                                  onChange={e => {
                                      handleAdditionalFilterChange(e ?? "", "type", i)
                                      handleAdditionalFilterChange("", "value", i)
                                  }}/>
                    </CCol>
                    <CCol md={3} style={{paddingTop: '30px'}}>
                        {getAdditionalFilterType(additionalFilters[i].name) == "date" &&
                            (additionalFilters[i].type == "21" &&
                                <DateRangeFilter value={additionalFilters[i].value}
                                                 onChange={e => {
                                                     handleAdditionalFilterChange(e ?? "", "value", i)
                                                     // handleAdditionalFilterChange("", "type", i)
                                                 }}/> ||
                                <SWDateAndTimePicker value={additionalFilters[i].value}
                                                     handleOnChange={e => handleAdditionalFilterChange(e ?? "", "value", i)}/>) ||
                            getAdditionalFilterType(additionalFilters[i].name) == "select" &&
                            <SWSelect values={getAdditionalFilterLookup(additionalFilters[i].name)}
                                      onChange={e => handleAdditionalFilterChange(e ?? "", "value", i)}
                                      value={additionalFilters[i].value}/> ||
                            getAdditionalFilterType(additionalFilters[i].name) == "text" &&
                            (additionalFilters[i].type == "9" &&
                                <SWTextArea value={additionalFilters[i].value} name={"additionalFilters[i].value"}
                                            onChange={e => handleAdditionalFilterChange(e ?? "", "value", i)}/> ||
                                <SWInput value={additionalFilters[i].value} name={"additionalFilters[i].value"}
                                         onChange={e => handleAdditionalFilterChange(e ?? "", "value", i)}/>) ||
                            getAdditionalFilterType(additionalFilters[i].name) == "number" &&
                            <SWInput value={additionalFilters[i].value} name={"additionalFilters[i].value"}
                                     onChange={e => handleAdditionalFilterChange(e, "value", i)}/>
                        }
                    </CCol>
                    <CCol md={1} style={{paddingTop: '30px'}}>
                        <CIcon
                            size={"lg"}
                            content={freeSet.cilTrash}
                            onClick={() => RemoveFilter(i)}
                            style={{cursor: "pointer"}}
                        />
                    </CCol>

                </CRow>
            ))}
            <CRow>
                <CCol md={3}>
                    <CButton
                        size="sm"
                        style={{fontSize: "10px"}}
                        variant="outline"
                        color="danger"
                        onClick={handleClear}
                    >Clear all</CButton>
                </CCol>
                <CCol className={"text-right"}>
                    <CButtonGroup className="mr-4">
                        <CDropdown>
                            <CDropdownToggle color="success" variant={"outline"} size={"sm"} style={{fontSize: "10px"}}>Download
                                Report</CDropdownToggle>
                            <CDropdownMenu>
                                {report?.map((r) => (
                                    <CDropdownItem onClick={() => handleReportDownload(r.id)}
                                                   key={r.id}>{r.name}</CDropdownItem>
                                )) ?? <CDropdownItem disabled>No Reports Configured</CDropdownItem>}
                            </CDropdownMenu>
                        </CDropdown>
                    </CButtonGroup>
                    <CButtonGroup>
                        <ActionButton
                            text={"Search"}
                            onClick={handleSubmit}
                        />
                    </CButtonGroup>
                </CCol>
            </CRow>
            {showMissingNumbersModal && <NotFoundShipmentsModal missingShipments={props.missingShipmentNumbers}
                                                                handleClose={() => setShowMissingNumberModal(false)}/>}
        </fieldset>
    )
}

export default NewSearchFrom;
