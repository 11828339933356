import { CSpinner } from "@coreui/react";
import React from "react";

type Props={
    size?:number
}
const Loading = ({size}:Props) => {
  return (
      <div
          className="overlay spinner"
      >
        <CSpinner
            className="mx-auto my-auto d-block "
            color="primary"
            style={{ width: `${size ?? '5'}em`, height: `${size ?? '5'}em` }}
        />
      </div>
  );
};

export default Loading;
