import React, {useState} from 'react';
import {
    CButton,
    CCol,
    CInput,
    CInputCheckbox,
    CInputGroup,
    CLabel, CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CRow
} from "@coreui/react";
import DateAndTimePicker from "../../../SharedComponents/SWForm/DateAndTimePicker";
import ActionButton from "../../../SharedComponents/ActionButton";


interface IProps{
    onClose:()=>any
    onSubmit:(date:any,reference:any,methods:any) => any
}

const Component = (props:IProps) => {
    const [date,setDate] = useState<any>(new Date(Date.now()));
    const [methods, setMethods] = useState<string[]>([]);
    const [reference, setReference] = useState('');
    const { onClose,onSubmit} = props;
    const [disabled, setDisabled] = useState(false);
  const onClick = () => {
    setDisabled(true);
    onSubmit(date,reference,methods);
    
  }

    return (
        <CModal
            show={true}
            onClose={onClose}
        >
            <CModalHeader>
                <h5>Confirm</h5>
            </CModalHeader>
            <CModalBody>
                <p className={"mb-2"}>Are you sure you want to mark this quotation as paid</p>
                <DateAndTimePicker value={date} handleOnChange={(v: string) => setDate(v)}/>
                <CInput
                    className={"my-3"}
                    placeholder={"Payment reference"}
                    value={reference}
                    onChange={(e: any) => setReference(e.target.value)}
                />
                <CRow className={'mt-2 pl-4'}>
                    <CCol>
                        <CInputGroup className="">

                            <CInputCheckbox
                                checked={methods.includes('sms')}
                                onChange={() => methods.includes('sms') ? setMethods([...methods.filter(m => m != 'sms')]) : setMethods([...methods, 'sms'])}

                            />
                            <CLabel>SMS</CLabel>
                        </CInputGroup>
                        <CInputGroup className="">

                            <CInputCheckbox

                                checked={methods.includes('email')}
                                onChange={() => methods.includes('email') ? setMethods([...methods.filter(m => m != 'email')]) : setMethods([...methods, 'email'])}
                            />
                            <CLabel>Email</CLabel>
                        </CInputGroup>

                    </CCol>

                </CRow>
            </CModalBody>
            <CModalFooter>
                <CRow className="justify-content-between w-100">
                    <CCol className="text-left">
                        <CButton
                            variant="outline"
                            color="danger"
                            onClick={onClose}
                        >
                            Cancel
                        </CButton>
                    </CCol>
                    <CCol className="text-right">
                        <ActionButton
                            text={"Yes"}
                            disabled={disabled || reference.length<1}
                            onClick={onClick}
                        />
                    </CCol>
                </CRow>
            </CModalFooter>
        </CModal>
    )
}

export default Component;