import {CContainer} from "@coreui/react";
import React from "react";

const ShipmentDeletedStamp = () => {
    return (
        <>
            <CContainer style={{left:'15%', top:"30%"}} size={100} className="stampContainer mb-1">
                <h4 className="stampText text-danger">Deleted Shipment</h4>
            </CContainer>
        </>
    );
}

export default ShipmentDeletedStamp;