import React, {CSSProperties} from "react";
import SWSelect from "../../../SharedComponents/SWSelect";
import {SheetInfo} from "../../../../models/sheetInfo";

const tdStyle: CSSProperties = {width: "auto", whiteSpace: "nowrap", minWidth: "170px"};

interface Props {
    onMapChange: (key: string, selected?: string | null) => void;
    sheet: SheetInfo;
    map: { [k: string]: string | null }
    objectDifferentiator?: string
    keys:string[]
}

const ExcelMapper = (props: Props) => {

    const {sheet, onMapChange, map, objectDifferentiator,keys} = props;


    let numberIndex: number | undefined;
    let styleMap: { [k: string]: CSSProperties } = {};
    if (sheet && sheet.values && map && objectDifferentiator && Object.values(map).includes(objectDifferentiator)) {
        const numberKey = Object.keys(map).find(k => map[k] == objectDifferentiator) ?? "";

        numberIndex = sheet.values["0"].indexOf(numberKey);


        let switchBoolean = true;
        let number = "";

        for (const val in sheet.values) {

            if (sheet.values[val][numberIndex] != number) {
                switchBoolean = !switchBoolean;
                number = sheet.values[val][numberIndex];
            }
            styleMap[val] = {...tdStyle, backgroundColor: switchBoolean ? "white" : "gainsboro"}
        }
    }

    return (
        <div style={{overflowY: "scroll", overflowX: "scroll", height:"350px"}}>
            <table className="table table-hover table-sm table-bordered border-success ">
                <thead className="border-danger">
                <tr>
                    {sheet.values["0"].map((i, index) =>
                        (
                            <th key={index} style={tdStyle}>
                                {i && (i instanceof String || typeof i === 'string') &&
                                <SWSelect

                                    values={Object.assign({"notMapped": ""}, ...keys?.map((x) => ({[x]: x})))}
                                    //@ts-ignore
                                    value={map ? map[i] ? map[i] : "notMapped" : "notMapped"}
                                    //@ts-ignore
                                    onChange={(val) => onMapChange(i, val)}/>
                                }

                            </th>
                        ))}
                </tr>
                <tr>
                    {sheet.values[0].map((i: any, index) =>
                        (<th key={index} style={tdStyle}>{i}</th>))}
                </tr>
                </thead>
                <tbody>

                {Object.keys(sheet.values).filter((_, i) => i != 0).map((k, index) => (
                    <tr key={index}>
                        {sheet.values[k].map((val, i) => (
                            <td key={`${i}${val}`} style={styleMap ? styleMap[k] : tdStyle}>
                               {sheet?.values?.[0]?.[i] === "Price" ? val?.toFixed(2):val}
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>

            </table>
        </div>
    )
}

export default ExcelMapper;