import {
    CButton,
    CCol,
    CContainer,
    CDataTable,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CRow, CSpinner,
    CTooltip
} from "@coreui/react"
import React, { useEffect, useState } from "react"
import { AllocationStrategy, InventoryTransactionDetailDto, SkuSearch, SkuStatus, warehouse } from "../../../../models/warehouse";
import { formatDate } from "../../../../utils/dateUtil";
import SWSelect from "../../../SharedComponents/SWSelect";
import SWInput from "../../../SharedComponents/SWInput";
import SWDateAndTimePicker from "../../../SharedComponents/SWDateAndTimePicker";
import { ShipmentGet } from "../../../../models/shipment";
import CIcon from "@coreui/icons-react";
import { freeSet } from "@coreui/icons";
import WarehousesClient from "../../../../clients/warehousesClient";
import StockQuantityHelper from "../components/StockQuantityHelper";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

interface IProps {
    stockItems: InventoryTransactionDetailDto[]
    shipment: ShipmentGet
    skus: SkuSearch[]
    onClose: () => void
    onSave: () => void
    account?: string
}

interface FilterValues {
    sku?: string
    status?: SkuStatus
    location?: string
    batchNumber?: string
    itemNumber?: string
    expiryDate?: string
}

const skuStatus: { [p: string]: string } = {
    0: "intact",
    1: "Cover Damaged",
    2: "Damaged"
};

const EditItemsFromStock = (props: IProps) => {
    const { stockItems, shipment, skus, onClose, onSave, account } = props
    const warehouseClient = new WarehousesClient();
    const { width } = useWindowDimensions()
    const initFilterValues: FilterValues = {
        sku: undefined,
        location: undefined,
        status: undefined,
        batchNumber: undefined,
        itemNumber: undefined,
        expiryDate: undefined,
    }

    const [filterValues, setFilterValues] = useState(initFilterValues)
    const [updatedStock, setUpdatedStock] = useState<InventoryTransactionDetailDto[]>(stockItems)
    const [skuLookups, setSkuLookups] = useState<{ [k: string]: string }>({})
    const [locationLookups, setLocationLookups] = useState<{ [k: string]: string }>({})
    const [allocationStrategy, setAllocationStrategy] = useState<AllocationStrategy>()
    const [allSkus, setAllSkus] = useState<SkuSearch[]>([])
    const [loaded, setLoaded] = useState<boolean>(false)
    const [warehouseSelected, setWarehouseSelected] = useState<string>()
    const [warehouseLookups, setWarehouseLookups] = useState<{ [k: string]: string }>({})

    const [saveIconDisabled,setSaveIconDisabled] = useState<boolean>(false);

    useEffect(() => {
        if (stockItems?.length == 0) {
            getAccount()
        } else {
            const warehouseCode = stockItems[0]?.warehouse
            if (warehouseCode) {
                setWarehouseSelected(warehouseCode)
                initSkus(warehouseCode)
            } else
                setLoaded(true)
        }
    }, [])

    const getAccount = async () => {
        if (account) {
            const res = await warehouseClient.GetAccount(account);
            if (res?.warehouse) {
                setWarehouseSelected(res?.warehouse); // default warehouse
                await initSkus(res?.warehouse)
            }

            if (res?.allowedWarehouses) {
                let warehouses: warehouse[] = await warehouseClient.searchWarehouse();
                const allowedIds = new Set(res.allowedWarehouses);
                const allowed = warehouses.filter((e) => allowedIds.has(e.id));
                const warehouseLookup: { [k: string]: string } = {};
                allowed?.forEach((warehouse) => {
                    warehouseLookup[warehouse.code] = warehouse.name;
                });
                setWarehouseLookups(warehouseLookup);
            }
            setLoaded(true)
        }
    };

    const handleRemoveStockItem = (i: number) => {
        let newItems = [...updatedStock]
        newItems.splice(i, 1)
        setUpdatedStock(newItems)
    }


    const handleSave = async () => {
        setSaveIconDisabled(true)
        let res;
        if (shipment.number)
            res = await warehouseClient.itemConsumptionsUpdateItems(shipment.uid, updatedStock)
        else
            res = await warehouseClient.itemReservationsUpdateItems(shipment.uid, updatedStock)

        if (res.succeeded) {
            onSave()
        }
        setSaveIconDisabled(false)
    }

    const initSkus = async (w?: string) => {
        setLoaded(false)
        const warehouses = await warehouseClient.searchWarehouse()
        const skuSearch = await warehouseClient.SearchSku({
            accountNumber: shipment.account,
            limit: 3000,
            warehouse: w
        })
        const skus: SkuSearch[] = skuSearch?.data?.matches
        setAllSkus(skus)
        const skuLookup: { [k: string]: string } = {}
        skus.forEach(s => {
            let warehouse = '';
            if (s.allowedWarehouses?.length > 0) {
                let selectedWarehouse = warehouses.find(w => w.id == s.allowedWarehouses[0])
                warehouse = selectedWarehouse?.code ?? ""
            } else if (s.warehouse)
                warehouse = s.warehouse
            skuLookup[s.code] = `${warehouse ? warehouse + " - " : ""}${s.code} - ${s.name}`
        })
        setSkuLookups(skuLookup)
        let data = await warehouseClient.GetWarehouseWithLookups(w)
        setLocationLookups(data.locationLookup)
        setLoaded(true);
    }

    const handleSkuChanged = (code?: string, detail?: InventoryTransactionDetailDto) => {
        const sku = skus.find(s => s.code == code)
        if (sku)
            setAllocationStrategy(sku.allocationStrategy)
        else
            setAllocationStrategy(undefined)

        if (detail) {
            setFilterValues({
                sku: code,
                location: detail.location,
                itemNumber: detail.lot.itemNumber,
                expiryDate: detail.lot.expiryDate,
                status: detail.lot.status,
                batchNumber: detail.lot.batchNumber
            })
            return;
        }
        setFilterValues({
            sku: code,
            location: undefined,
            itemNumber: undefined,
            expiryDate: undefined,
            status: undefined,
            batchNumber: undefined
        })
    }

    const renderHeader = (header: string[]) => header.map((value, index) => {
        return (
            <th className="position-sticky bg-white" key={index}>
                {value}
            </th>
        );
    });

    return (
        <CModal show onClose={onClose} size={"xl"} centered closeOnBackdrop={false}>
            <CContainer>
                <CModalHeader>
                    <CCol md={10} className={"mr-2"}>
                        <h4>Edit Stock:</h4>
                    </CCol>
                    <CCol className={"text-right"}>
                        <CTooltip content={"Save"}>
                            <CIcon
                                disabled={saveIconDisabled}
                                className={"mr-2"}
                                style={{ cursor: "pointer" }}
                                content={freeSet.cilSave}
                                onClick={() => handleSave()}
                            />
                        </CTooltip>
                        <CTooltip content={"Reset"}>
                            <CIcon
                                className={"mr-2"}
                                style={{ cursor: "pointer" }}
                                content={freeSet.cilLoopCircular}
                                onClick={() => setUpdatedStock(stockItems)}
                            />
                        </CTooltip>
                        <CTooltip content={"Cancel"}>
                            <CIcon
                                style={{ cursor: "pointer" }}
                                content={freeSet.cilX}
                                onClick={() => onClose()}
                            />
                        </CTooltip>
                    </CCol>
                </CModalHeader>
                <CModalBody>
                    <CRow>
                        {!loaded ? <strong>Loading Stock Data...</strong> :
                            <table className={"position-relative table table-hover table-sm"}>
                                <thead>{renderHeader(["Sku Nane", "Sku Code", "Status", "Quantity", "Batch Number", "Batch Barcode", "Expiry Date", "Weight", "Location", ""])}</thead>
                                <tbody>
                                    {updatedStock.map((stock, index) => {
                                        return <tr key={index}
                                            className={stock.lot?.sku === filterValues.sku ? "bg-secondary" : ""}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => handleSkuChanged(stock.lot.sku, stock)}>
                                            <td>{stock.lot.skuName || ""}</td>
                                            <td>{stock.lot.sku || ""}</td>
                                            <td>{skuStatus[stock.lot.status] || ""}</td>
                                            <td>{stock.quantity || ""}</td>
                                            <td>{stock.lot.batchNumber || ""}</td>
                                            <td>{stock.lot.itemNumber || ""}</td>
                                            <td>{formatDate(stock.lot.expiryDate) || ""}</td>
                                            <td>{stock.lot.skuWeight ? stock.lot.skuWeight + " " + stock.lot.skuWeightUnit : ""}</td>
                                            <td>{stock.location}</td>
                                            <td>
                                                <CIcon
                                                    content={freeSet.cilTrash}
                                                    onClick={() => handleRemoveStockItem(index)}
                                                />
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>}
                    </CRow>
                    {!loaded ?
                        <CSpinner
                            className="mx-auto d-block my-5"
                            color="primary"
                            style={{ width: "5em", height: "5em" }}
                        /> :
                        <CRow>
                            {!stockItems[0]?.warehouse &&
                                <CCol md={3}>
                                    <SWSelect
                                        label={"Warehouse"} values={warehouseLookups}
                                        value={warehouseSelected}
                                        readonly={updatedStock.length > 0}
                                        onChange={async (w) => {
                                            setWarehouseSelected(w)
                                            await initSkus(w)
                                        }} isClearable />
                                </CCol>
                            }
                            <CCol md={3}>
                                <SWSelect label={"Sku"} values={skuLookups} value={filterValues.sku}
                                    onChange={s => handleSkuChanged(s)} isClearable />
                            </CCol>
                            <CCol md={2}>
                                <SWSelect values={skuStatus} value={filterValues.status?.toString()} label={"Status"}
                                    onChange={s => setFilterValues({ ...filterValues, status: Number(s) })}
                                    isClearable />
                            </CCol>
                            <CCol md={2}>
                                <SWSelect values={locationLookups} value={filterValues.location} label={"Location"}
                                    onChange={l => setFilterValues({ ...filterValues, location: l })} isClearable />
                            </CCol>
                            {allocationStrategy == AllocationStrategy.BatchNumber || allocationStrategy == AllocationStrategy.ExpiryDate &&
                                <>
                                    <CCol md={2}>
                                        <SWInput label={"Batch Number"} value={filterValues.batchNumber}
                                            onChange={bn => setFilterValues({ ...filterValues, batchNumber: bn })} />
                                    </CCol>
                                    <CCol md={2}>
                                        <SWInput label={"Batch Barcode"} value={filterValues.itemNumber}
                                            onChange={it => setFilterValues({ ...filterValues, itemNumber: it })} />
                                    </CCol>
                                    {allocationStrategy == AllocationStrategy.ExpiryDate &&
                                        <CCol md={2}>
                                            <SWDateAndTimePicker value={filterValues.expiryDate}
                                                handleOnChange={(v) => setFilterValues({
                                                    ...filterValues,
                                                    expiryDate: v
                                                })} />
                                        </CCol>
                                    }
                                </>
                            }
                        </CRow>}
                    <CRow>
                        <StockQuantityHelper
                            width={width}
                            selected={filterValues}
                            updateStock={updatedStock}
                            setUpdateStock={setUpdatedStock}
                            account={shipment.account}
                            skus={allSkus}
                            skuStatus={skuStatus}
                            warehouse={stockItems[0]?.warehouse}
                            filterStockLocations={locationLookups => setLocationLookups(locationLookups)}
                        />
                    </CRow>
                </CModalBody>
            </CContainer>
        </CModal>
    )
}

export default EditItemsFromStock