import { CCol, CDataTable, CLink, CModal, CModalBody, CModalHeader, CRow, CTooltip } from "@coreui/react";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContainerShipmentSearch } from "../../../../../models/container";
import { groupErrorsBySource } from "../../../../../models/shipment";

interface IProps {
    onClose: () => void
    data?: ContainerShipmentSearch[]
    isOpen?: boolean
}

const ErrorsModal = ({ isOpen, data, onClose }: IProps) => {
    const scopedSlots = {
        accountReference: (e: ContainerShipmentSearch) => {
            return <td>
                {e.accountReference ?? "-"}
            </td>
        },
        error: (e: ContainerShipmentSearch) => {
            return <td>
                {e?.errors && groupErrorsBySource(e?.errors)?.map(([sourceName, groupedErrors]) => (
                    <CRow className={"mb-1"} key={sourceName}>
                        <CCol md={3} className={"text-danger"}>
                            <strong>{sourceName}:</strong>
                        </CCol>
                        <CCol className={"text-danger"}>
                            {groupedErrors.map((error, index) => (
                                <CRow key={index}>
                                    {error.message}
                                </CRow>
                            ))}
                        </CCol>
                    </CRow>
                ))}
            </td>
        },
        actions: (e: ContainerShipmentSearch) => {
            return <td>
                <CLink to={`/shipments/${e?.shipmentUid}`} target="_blank">
                    <CTooltip content={"View Shipment"}>
                        <FontAwesomeIcon
                            color="primary"
                            icon={faExternalLink}
                        />
                    </CTooltip>
                </CLink>
            </td>
        },
    };
    return (
        <CModal
            className={"text-primary"}
            closeOnBackdrop={false}
            show={isOpen}
            centered
            size="lg"
            onClose={() => onClose()}>
            <CModalHeader closeButton>
                <h5>Errors</h5>
            </CModalHeader>
            <CModalBody>
                <CDataTable
                    size="sm"
                    hover
                    scopedSlots={scopedSlots}
                    items={data || []}
                    fields={
                        [
                            { key: "actions", label: "", _style: { width: "1%" } },
                            { key: "accountReference", label: "Reference" },
                            { key: "error", label: "Error" },
                        ]
                    }

                />
            </CModalBody>

        </CModal>
    )
}

export default ErrorsModal;