import React, {useState} from "react";
import {CDataTable, CLink, CSpinner} from "@coreui/react";
import {calculateVolumetricAndChargeWeight, getWeightUnitAsString} from "../../../../models/shipment";
import {formatDate} from "../../../../utils/dateUtil";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faCheck} from "@fortawesome/free-solid-svg-icons";
import {BillingType, PendingShipmentsResponse} from "../../../../models/accounting";
import AddEditCharge from "../../Shipments/modals/AddEditCharge";
import AccountingClient from "../../../../clients/accountingClient";

interface HtmlLineItem {
    label?: string;
    value: string;
}

interface IProps {
    shipments: PendingShipmentsResponse[]
    loading: boolean,
    accountingClient: AccountingClient,
    chargeTypes: { [k: string]: string }
}

const ShipmentsWithoutCharges = (props: IProps) => {

    const {chargeTypes,shipments,accountingClient,loading } =props;
    const [showAddCharge, setShowAddCharge] = useState<PendingShipmentsResponse>();
    const [shipmentsChargesAddedManually, setShipmentsChargesAddedManually] = useState<string[]>([]);
    
    const totalChargesText = shipments.length > 1 ? `${shipments.length} shipments` : "1 shipment"
    const updateShipmentHasCharges = (hasCharges: boolean) => {

        const position = shipmentsChargesAddedManually.indexOf(showAddCharge?.uid ?? "")
        let newDetails = shipmentsChargesAddedManually.slice()
        if(hasCharges && position === -1)
                newDetails = [...shipmentsChargesAddedManually, showAddCharge?.uid?? ""]
        else if(!hasCharges && position !==-1)
            newDetails.splice(position, 1)
        setShipmentsChargesAddedManually(newDetails);
    }
    const element = (val: HtmlLineItem, index: number, withBreak: boolean) =>
        val.label ? (
            <React.Fragment key={index}>
                <strong>{val.label}:</strong>
                {val.value} {withBreak ? <br/> : ""}
            </React.Fragment>
        ) : (
            <React.Fragment key={index}>
                {val.value}
                {withBreak ? <br/> : ""}
            </React.Fragment>
        );

    const inTd = (val: any) => <td>{val}</td>;
    const elementsIntTd = (vals: HtmlLineItem[]) => {
        const items = vals.slice();
        const [last] = items.splice(-1);
        const withBreaks = items.map((el, i) => element(el, i, true));
        withBreaks.push(element(last, vals.length - 1, false));
        return <td>{withBreaks}</td>;
    };
    
    const scopedSlots = {
        links: (item: PendingShipmentsResponse) => {
            return <td>
                
                <CLink to={`/shipments/${encodeURI(item.uid)}`} >
                    <FontAwesomeIcon icon={faEye}/>

                </CLink>

            </td>;
        },
        actions: (item: PendingShipmentsResponse) => {
            return <td>
                
                <CIcon
                    content={freeSet.cilPlus}
                    style={{cursor: "pointer", margin: "auto"}}
                    onClick={() => {setShowAddCharge(item)} }
                />
                {shipmentsChargesAddedManually.indexOf(item.uid) !== -1 &&
                    <FontAwesomeIcon icon={faCheck} size={"2x"} />
                }
            </td>;
        },
        awb: (item: PendingShipmentsResponse) => {
            const details =
                <>
                    {item.number ?? item.uid}
                </>
            ;
            return inTd(details);
        },
        receiver: (item: PendingShipmentsResponse) => inTd(item.shipmentData?.consignee?.name),

        company: (item: PendingShipmentsResponse) => inTd(item.shipmentData?.consignee?.companyName),
        shipper: (i: PendingShipmentsResponse) => {
            let addr: HtmlLineItem[] = [];
            
            const item = i.shipmentData

            if (item.shipper?.companyName || item.shipper?.name) {
                if (
                    item.shipper?.name &&
                    item.shipper?.companyName &&
                    item.shipper?.companyName !== item.shipper?.name
                )
                    addr.push({
                        value: `${item.shipper?.companyName}, ${item.shipper?.name}`,
                    });
                else if (
                    item.shipper?.name &&
                    item.shipper?.companyName &&
                    item.shipper?.companyName === item.shipper?.name
                )
                    addr.push({value: item.shipper?.name});
                else if (item.shipper?.name) addr.push({value: item.shipper?.name});
                else addr.push({value: item.shipper?.companyName ?? ""});
            }

            if (item.shipper?.addressLine1)
                addr.push({value: item.shipper?.addressLine1});
            if (item.shipper?.addressLine2)
                addr.push({value: item.shipper?.addressLine2});
            if (item.shipper?.addressLine3)
                addr.push({value: item.shipper?.addressLine3});

            if (item.shipper?.city || item.shipper?.postCode) {
                if (item.shipper?.postCode && item.shipper?.city)
                    addr.push({value: `${item.shipper?.postCode}, ${item.shipper?.city}`});
                else if (item.shipper?.postCode)
                    addr.push({value: item?.shipper.postCode});
                else addr.push({value: item.shipper?.city ?? ""});
            }

            if (item.shipper?.country) addr.push({value: item.shipper.country});
            if (item.shipper?.email) addr.push({value: item.shipper.email});
            if (item.shipper?.phone) addr.push({value: item.shipper?.phone});

            if (addr.length == 0) return inTd("");

            return elementsIntTd(addr);
        },

        pickupDate: (item: PendingShipmentsResponse) =>
            inTd(formatDate(item.shipmentData.pickupDate)),
        reference: (item: PendingShipmentsResponse) => inTd(item.shipmentData?.accountReference),
        chargeableWeight: (item: PendingShipmentsResponse) => inTd(`${calculateVolumetricAndChargeWeight(item.shipmentData.pieces,getWeightUnitAsString(item.shipmentData?.weight?.unit)).totalCalculated} ${getWeightUnitAsString(item.shipmentData?.weight?.unit)}`),
        weight: (item: PendingShipmentsResponse) => inTd(`${item.shipmentData?.weight?.value} ${getWeightUnitAsString(item.shipmentData?.weight?.unit)}`),
        origin: (item: PendingShipmentsResponse) =>
            inTd(`${item.shipmentData?.shipper?.country}-${item.shipmentData?.shipper?.postCode}`),
        destination: (item: PendingShipmentsResponse) =>
            inTd(`${item.shipmentData?.consignee?.country}-${item.shipmentData?.consignee?.postCode}, ${item.shipmentData?.consignee?.city}`),
        number: (item: PendingShipmentsResponse) => inTd(item.number ?? ""),
        agent: (item: PendingShipmentsResponse) => inTd(item.bookings[0]?.agent ?? ""),
        
        
    };
    return loading ? (
        <CSpinner
            className="mx-auto d-block my-5"
            color="primary"
            style={{width: "5em", height: "5em"}}
        />
    ) : (
        <>
            
            <div className="small" style={{overflowY: "scroll", overflowX: "scroll", maxHeight:"350px", zIndex:0 }}>
                <CDataTable
                    size="sm"
                    hover
                    scopedSlots={scopedSlots}
                    items={shipments}
                    fields={[
                        {key: "links", label: totalChargesText, _style: {width: '7%', padding: '0.25rem'}},
                        {key: "pickupDate", label: "Pickup Date"},
                        {key: "awb", label: "Number"},
                        {key: "reference", label: "Reference"},
                        {key: "weight", label: "Weight"},
                        {key: "chargeableWeight", label: "Charge Weight"},
                        {key: "receiver", label: "Receiver"},
                        {key: "company", label: "Company"},
                        {key: "origin", label: "Origin"},
                        {key: "destination", label: "Destination"},
                        {key: "agent", label: "Agent"},
                        {key: "actions", label: ""}
                        
                    ]}
                />
                

            </div>
            {showAddCharge &&
                <AddEditCharge  onClose={() => setShowAddCharge(undefined)} account={showAddCharge.account}
                               chargeTypes={chargeTypes} billingType={BillingType.Selling} shipmentUid={showAddCharge.uid}
                               showList accountingClient={accountingClient} onChargePresent={updateShipmentHasCharges} hideIsGroupedCheckBox={true}  />}
        </>
    );
};

export default ShipmentsWithoutCharges;
