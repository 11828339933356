import {CCol, CContainer, CInputCheckbox, CLabel, CRow, CTooltip} from "@coreui/react";
import React, {useEffect, useState} from "react";
import SearchForm from "./components/SearchForm";
import {SkuSearch, SkuSearchDetailsReportItem, SkuSearchFilter} from "../../../../models/warehouse";
import SearchResult from "./components/SearchResult";
import WarehouseClient from "../../../../clients/warehousesClient";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import CreateUpdateSKUModal from "../modals/CreateUpdateSKUModal";
import {querytoMap, toQueryParam} from "../../../../utils/filterUtils";
import {useHistory, useLocation} from "react-router";
import {IPaginatedResult, IPagination} from "../../../../models/filter";
import RatesClient from "../../../../clients/ratesClient";
import {SetLoading} from "../../../../store/actions/auth";
import {useDispatch, useSelector} from "react-redux";
import {AppModel} from "../../../../models/app";
import EditQuantity from "./modals/EditQuantity";
import SearchResultDetails from "./components/SearchResultDetails";

interface Props {
}

const SearchSkus = (props: Props) => {
    const client = new WarehouseClient();
    const dispatch = useDispatch();
    const [paginatedSkus, setPaginatedSkus] = useState<IPaginatedResult<SkuSearch>>({matches: [], total: 0});
    const [paginatedSkuDetails, setPaginatedSkuDetails] = useState<IPaginatedResult<SkuSearchDetailsReportItem>>({
        matches: [],
        total: 0
    });
    const [showDetails, setShowDetails] = useState<boolean>(false)

    const [pagination, setPagination] = useState<IPagination>({
        limit: 20,
        offset: 0,
    });
    const isLoading = useSelector((state: AppModel) => state.app.isLoading);
    let {search, pathname} = useLocation();
    let hist = useHistory();
    let param = new URLSearchParams(search)
    const [accountsLookup, setAccountsLookup] = useState<{ [k: string]: string }>({});

    const [initialWarehouse, setInitialWarehouse] = useState("");
    const [initialAccount, setInitialAccount] = useState("");
    const [initialSeller, setInitialSeller] = useState<string | null | undefined>(null);
    const [isSkuModalShowing, setSkuModalShowing] = useState(false);
    const [skuUpdateId, setSkuUpdateId] = useState<number>();
    const [duplicated, setDuplicated] = useState<SkuSearch>()
    const [skuForEditQuantities, setSkuForEditQuantities] = useState<SkuSearch>()
    const [transactionType, setTransactionType] = useState<string>("")
    const [editQuantityModal, setEditQuantityModal] = useState<boolean>(false)


    useEffect(() => {
        refreshSkusAsync();
    }, [search]);

    useEffect(() => {
        loadAccountsLookup();
    }, []);
    const refreshSkusAsync = async () => {
        const map = querytoMap<SkuSearchFilter>(search);
        setPagination((state) => ({
            offset: map.offset || state.offset,
            limit: map.limit || state.limit,
        }));
        await handleSearch(map);
    };

    const handleDuplicate = (sku: SkuSearch) => {
        setDuplicated(sku)
        setSkuModalShowing(true)
    }

    const handleSkuModalClose = async () => {
        setSkuUpdateId(0);
        setSkuModalShowing(false);
        await refreshSkusAsync();
        setDuplicated(undefined)
    };
    const handleSkuModalShowing = async () => {
        setSkuModalShowing((state) => !state);
        await refreshSkusAsync();
    };
    const handleSearch = async (filter: SkuSearchFilter) => {
        dispatch(SetLoading(true));

        const res = await client.SearchSku(filter);

        setPaginatedSkus({matches: res.data.matches, total: res.data.total});

        const detailResult = await client.SearchSkuDetails(filter);

        setPaginatedSkuDetails({matches: detailResult.data.matches, total: detailResult.data.total})
        dispatch(SetLoading(false));
    };
    const loadAccountsLookup = async () => {
        const ratesClient = new RatesClient();
        const customers = await ratesClient.LookupCustomers();
        setAccountsLookup(customers);
    };

    const handleEditQuantity = (sku: SkuSearch, type: string) => {
        setSkuForEditQuantities(sku);
        setTransactionType(type);
        setEditQuantityModal(true)
    }

    const handleEditQuantityClose = () => {
        setEditQuantityModal(false)
    }

    const handleShowDetailsChange = async () => {
        let isCheck = !showDetails
        const modifiedSearch = new URLSearchParams(search);
        if (!isCheck) {
            modifiedSearch.delete("showDetails");
            hist.push(
                `${pathname}?${modifiedSearch.toString()}`
            )
        }
        else {
            hist.push(
                `${pathname}?${modifiedSearch.toString()}&showDetails=true`
            );
        }
        setShowDetails(isCheck)
    }

    return (
        <>
        <CContainer className="bg-white p-4">
            <SearchForm
                accountsLookup={accountsLookup}
                onClearClicked={() => {
                    hist.push(pathname)
                    setShowDetails(false)
                }}
                onSearchClicked={(filter) => {
                    hist.push(
                        `${pathname}?${toQueryParam({...pagination, ...filter})}`
                    );
                }}
                showDetails={showDetails}
            />
            <CRow className={"justify-content-start text-primary p-1"}>
                <CCol>
                    <CTooltip content="Add New SKU">
                        <CIcon
                            onClick={handleSkuModalShowing}
                            className="ml-3 mt-1"
                            style={{
                                height: "1.5em",
                                width: "1.5em",
                                cursor: "pointer"
                            }}
                            content={freeSet.cilPlus}
                        />
            </CTooltip>
                </CCol>
                <CCol md={2} style={{maxWidth:"10%"}}>
                    <CInputCheckbox
                        checked={showDetails}
                        onChange={() => handleShowDetailsChange()}
                    /><CLabel>Show Details</CLabel>
                </CCol>
            </CRow>
        {!param.has("showDetails") ?
            <SearchResult
                handleDuplicate={handleDuplicate}
                handleEditQuantities={handleEditQuantity}
                paginatedSkus={paginatedSkus}
                isLoading={isLoading}
                pagination={pagination}
                accountsLookup={accountsLookup}
                onUpdate={(id) => {
                    setSkuUpdateId(id);
                    setSkuModalShowing(true);
                }}
            /> :
            <SearchResultDetails
                paginationSkuDetails={paginatedSkuDetails}
                pagination={pagination}
                accountsLookup={accountsLookup}
            />
        }
        </CContainer>
    {
        isSkuModalShowing &&
        <CreateUpdateSKUModal
            duplicateSku={duplicated}
            seller={initialSeller}
            accountNumber={initialAccount}
            warehouse={initialWarehouse}
            updateId={skuUpdateId}
            onSubmitCompleted={(sku) => {
                setInitialAccount(sku.accountNumber);
                setInitialSeller(sku.seller);
                setInitialWarehouse(sku.warehouse);
                handleSearch(querytoMap<SkuSearchFilter>(search))
            }}
            onClose={handleSkuModalClose}
            isShowing={isSkuModalShowing}
        />
    }
    {
        editQuantityModal &&
        <EditQuantity sku={skuForEditQuantities} type={transactionType} handleClose={handleEditQuantityClose}/>
    }
</>
)
    ;
};

export default SearchSkus;
