import {ClientFactory} from "@simplify9/simplyapiclient";
import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import {CountryLookup} from "../models/shipment";
import {SearchyResult} from "../models/app";
import {ConfigurationTypeSearchRequest, SearchChargeTypeRequest, TraceType} from "../models/lookups";
import {Report} from "../models/Report";
import {toQueryParam} from "../utils/filterUtils";
import {ReportTemplateSearchRequest} from "../models/ReportTemplateSearchRequest";

class LookupsClient {
    client: IClient;
    BaseUrl: string;
    pageSize: number;

    constructor() {
        this.BaseUrl = `${process.env.REACT_APP_API_BASE_URL}/lookups/api`;
        // this.BaseUrl = `https://localhost:14188/lookups/api`;

        this.client = ClientFactory({
            baseUrl: this.BaseUrl,
            authType: "bearer",
            //getBearer: () => CookieManager.getAccessToken(),
            getBearer: () =>
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImFkbWluQHh5ei5jb20iLCJuYW1laWQiOiIxIiwiZ2l2ZW5fbmFtZSI6IkFkbWluIiwibmJmIjoxNjA3MjQ4MzI0LCJleHAiOjE2MzMxNjgzMjQsImlhdCI6MTYwNzI0ODMyNCwiaXNzIjoibG9jYWwiLCJhdWQiOiJsb2NhbCJ9._nz5lVYYdU9bs2KH6lzSlbNpqVKidj4-gmv4pKdiLCk",
        });
        this.pageSize = 20;
    }

    async getCharges(body: SearchChargeTypeRequest) {
        const rs = await this.client.SimplyGetAsync(`/charges?${toQueryParam(body)}`);
        return rs.data;
    }

    async getCharge(id: string) {
        const rs = await this.client.SimplyGetAsync(`/charges/${id}`);
        return rs.data;
    }

    async getTraces() {
        const rs = await this.client.SimplyGetAsync("/traces?format=1");
        return rs.data;
    }

    async searchTraces(): Promise<SearchyResult<TraceType>> {
        const rs = await this.client.SimplyGetAsync("/traces");
        return rs.data;
    }

    async getCountries() {
        const rs = await this.client.SimplyGetAsync("/countries?format=1");
        return rs.data as CountryLookup;
    }

    async GetUiReports(id: string): Promise<Report[]> {
        const rs = await this.client.SimplyGetAsync(`/Reports/${id}/uireports`)

        return rs.data
    }

    async searchReports(reportSearchModal: ReportTemplateSearchRequest): Promise<Report[]> {
        return await this.client.SimplyGetAsync(`/Reports?${toQueryParam(reportSearchModal)}`).data
    }

    async getSurcharges() {
        const rs = await this.client.SimplyGetAsync("/charges?format=1");
        return rs.data;
    }

    async configurations(req: ConfigurationTypeSearchRequest) {
        const rs = await this.client.SimplyGetAsync(`/configurations?${toQueryParam(req)}`);
        return rs;
    }

}

export default LookupsClient;
