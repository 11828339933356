import {ShipmentTraceSummaryItem} from "../../../../models/shipment";
import DateFormat from "dateformat";
import React from "react";
import {CContainer} from "@coreui/react";

const ShipmentSteps = ({data}:{data: ShipmentTraceSummaryItem}) => {
    const renderStep = (
        number: string,
        title: string,
        subTitle?:string,
        msg?: string,
        active?: boolean,
        final?: boolean
    ) => (
        <>
            <div className={`step'}`} data-target="#test-l-1">
                <CContainer className="p-1 d-flex flex-row align-items-center">
                    <span className={`bs-stepper-circle  ${active ? "bg-primary " : "bg-secondary"}`}>{number} </span>
                    <div>
                        <div>
                            <span className={"font-weight-bold text-primary"}>{subTitle}</span>
                            <span className={`bs-stepper-label m-0 ml-1 ${active ? "text-primary " : "text-secondary"}`}>{title} </span>
                        </div>
                        <div>
                            <span className={"small"}>{msg}</span>
                        </div>
                    </div>

                </CContainer>
            </div>
            {!final && (
                <div
                    className={`line  ${active ? "bg-primary " : "bg-secondary"}`}
                ></div>
            )}
        </>
    );


    switch (data.friendlyCodeName) {
        case "PREPARED":
        case "CANCELLED":
            return (<>
                {renderStep("1",  data.lastUpdateCode, data.friendlyCodeName, DateFormat(data?.lastUpdateDate!, "dd-mm-yyyy HH:MM:ss"), true)}
                {renderStep("2", "SHIPPED", "", "", false)}
                {renderStep("3", "TRANSIT", "", "", false)}
                {renderStep("4", "DELIVERED", "", "", false, true)}</>)

        case "SHIPPED":
            return (<>
                {renderStep("1", "PREPARED", "", "", true)}
                {renderStep("2", data.lastUpdateCode, data.friendlyCodeName, DateFormat(data?.lastUpdateDate!, "dd-mm-yyyy HH:MM:ss"), true)}
                {renderStep("3", "TRANSIT", "", "", false)}
                {renderStep("4", "DELIVERED", "", "", false, true)}</>)

        case "TRANSIT":
        case "DISCREP":
        case "CUSTOMS":
        case "AGENCY":
        case "RETURNING":
            return (<>
                {renderStep("1", "PREPARED", "", "", true)}
                {renderStep("2", "SHIPPED", "", "", true)}
                {renderStep("3",  data.lastUpdateCode,data.friendlyCodeName, DateFormat(data?.lastUpdateDate!, "dd-mm-yyyy HH:MM:ss"), true)}
                {renderStep("4", "DELIVERED", "", "", false, true)}</>)

        case "DELIVERED":
        case "RETURNED":
        case "PROBLEM":
            return (<>
                {renderStep("1", "PREPARED", "", "", true)}
                {renderStep("2", "SHIPPED", "", "", true)}
                {renderStep("3", "TRANSIT",  "", "", true)}
                {renderStep("4",  data.lastUpdateCode,data.friendlyCodeName, DateFormat(data?.lastUpdateDate!, "dd-mm-yyyy HH:MM:ss"), true, true)}</>)

        default:
            return (<>
                {renderStep("1", "PREPARED", "", "", false)}
                {renderStep("2", "SHIPPED", "", "", false)}
                {renderStep("3", "TRANSIT",  "", "", false)}
                {renderStep("4",  "DELIVERED","", "", false, true)}</>)

    }
}

export default ShipmentSteps