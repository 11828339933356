import React, { useEffect, useState } from "react";
import { CDataTable } from "@coreui/react";
import { faHourglassHalf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import ShipClient from "../../../../clients/shipClient";
import { PendingPrinting } from "../../../../models/shipment";
import { SetIsLoading } from "../../../../store/actions/ui";
import Card from "./card";
import { ShowNotification } from "../../../../store/actions/auth";

const PendingOrders = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const shipClient = new ShipClient();
    const [pendingShipmentData, setPendingShipmentData] = useState<PendingPrinting[]>([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        dispatch(SetIsLoading(true))
        const res = await shipClient.GetPendingShipmentBatch([]);
        if(res?.succeeded){
            setPendingShipmentData(res?.data);
        }else{
            dispatch(ShowNotification("Error", res?.error??"Error", true));
        }
    };

    const scopedSlots = {
        account: (item: PendingPrinting) => {
            return <td>{item.accountName ?? ""}</td>
        },
        numberOfShipments: (item: PendingPrinting) => {
            return <td>{item.numberOfShipments ?? ""}</td>
        },
    };

    let totalNumberOfShipments = pendingShipmentData?.length > 0 ? pendingShipmentData?.reduce((total, object) => {
        return total + object?.numberOfShipments;
    }, 0) : "-";

    const handleView = () => {
        history.push({
            pathname: '/shipments/print',
            search: '?notGenerated=true',
        });
    };

    return (
        <Card
            title={"Pending Orders"}
            subTitle={""}
            total={totalNumberOfShipments?.toString()}
            onView={() => handleView()}
            icon={
                <FontAwesomeIcon
                    icon={faHourglassHalf}
                    tabIndex={-1}
                    style={{  width: 45, height: 45, alignItems: "center" }}
                />
            }
            children={
                <div className="small" style={{ overflowY: "auto", maxHeight: "100px" }}>
                    <CDataTable
                        header={false}
                        size="sm"
                        hover
                        scopedSlots={scopedSlots ?? {}}
                        items={pendingShipmentData ?? []}
                        fields={[
                            { key: "account", label: "Account" },
                            { key: "numberOfShipments", label: "Number of shipments" },
                        ]}
                    />
                </div>
            }
        />
    )
}

export default PendingOrders;