import React, { useState } from "react";
import {
  CButton,
  CCol,
  CContainer,
  CInput,
  CInputCheckbox,
  CInputGroup,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
  CTextarea,
} from "@coreui/react";
import ActionButton from "../../../SharedComponents/ActionButton";

interface IProps {
  onClose: () => any;
  onSubmit: (methods: any) => any;
}

const Component = (props: IProps) => {
  const { onClose, onSubmit } = props;
  const [methods, setMethods] = useState<string[]>(["sms", "email"]);
  const [disabled, setDisabled] = useState(false);
  const onClick = () => {
    setDisabled(true);
    onSubmit(methods);
  };

  return (
    <div className="small">
      <CModal show={true} centered>
        <CModalHeader>
          <h6>Are you sure you want to request a payment?</h6>
        </CModalHeader>
        <CModalBody>
          <CCol>
            <CRow>
              <span>
                This action will send to the customer through the selected
                channels.
              </span>
            </CRow>
            <CRow className={"mt-2 pl-2"}>
              <CCol>
                <CInputGroup className="">
                  <CInputCheckbox
                    checked={methods.includes("sms")}
                    onChange={() =>
                      methods.includes("sms")
                        ? setMethods([...methods.filter((m) => m != "sms")])
                        : setMethods([...methods, "sms"])
                    }
                  />
                  <CLabel>SMS</CLabel>
                </CInputGroup>
                <CInputGroup className="">
                  <CInputCheckbox
                    checked={methods.includes("email")}
                    onChange={() =>
                      methods.includes("email")
                        ? setMethods([...methods.filter((m) => m != "email")])
                        : setMethods([...methods, "email"])
                    }
                  />
                  <CLabel>Email</CLabel>
                </CInputGroup>
              </CCol>
            </CRow>
            {/* <CRow className="mt-4">
              <CLabel> Text:</CLabel>
              <CTextarea />
            </CRow> */}
          </CCol>
        </CModalBody>
        <CModalFooter>
          <CRow className="justify-content-between w-100">
            <CCol className="text-left">
              <CButton
                style={{ fontSize: "10px" }}
                variant="outline"
                color="danger"
                onClick={onClose}
              >
                Cancel
              </CButton>
            </CCol>
            <CCol className="text-right">
              <ActionButton
                  text={"Yes"}
                  disabled={disabled}
                  onClick={onClick}
              />
            </CCol>
          </CRow>
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default Component;
