import React, {useEffect} from "react";
import "./scss/style.scss";
import "./App.css";
import {BrowserRouter as Router, Route, Switch,} from "react-router-dom";
import Main from "./components/LayoutComponents/Main";
import {useDispatch, useSelector} from "react-redux";
import {AppModel} from "./models/app";
import cookieManager from "./services/cookieManager";
import {HideNotification, LogOut, SetUser} from "./store/actions/auth";
import jwt from "jsonwebtoken";
import {now} from "lodash";
import CookieManager from "./services/cookieManager";
import Alert from "./components/SharedComponents/Alert";
import PublicMain from "./components/LayoutComponents/PublicMain";

function App() {
    const notification = useSelector((state: AppModel) => state.app.notification)
    const dispatch = useDispatch();
    const loggingIn = useSelector((state: AppModel) => state.app.loggingIn);

    useEffect(() => {
        //todo remove
        const accessToken = CookieManager.getAccessToken();
        console.log(accessToken)

        if (!accessToken) {
            dispatch(LogOut());
            return;
        }
        const jwtUser = jwt.decode(accessToken) as { [k: string]: any };
        let expCode: number = jwtUser['exp']
        let expiryDate = new Date(expCode * 1000)
        if (expiryDate < new Date(now())) {
            dispatch(LogOut())
            return;
        }
        if (jwtUser)
            cookieManager.deleteCookie("loginError");

        if (jwtUser) {
            dispatch(
                SetUser({
                    name: jwtUser.nameid ?? jwtUser.Username,
                    displayName: jwtUser.given_name ?? jwtUser.nameid ?? jwtUser.Username,
                    role:jwtUser.role,
                    emailProvider:jwtUser.EmailProvider
                })
            );
        }

    }, [CookieManager,loggingIn]);

    return (
        <>
            {notification.show &&
                <Alert title={notification.title} message={notification.message} onClose={()=>dispatch(HideNotification())} error={notification.error} />
            }

        <Router>
               <PublicMain/>
                    <Main/>
        </Router>
        </>
    );
}

export default App;
