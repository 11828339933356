import {CCol, CCollapse, CContainer, CDataTable, CInputCheckbox, CLabel, CRow, CTooltip} from "@coreui/react";
import React, {useEffect, useState} from "react";
import ShipClient from "../../../../../clients/shipClient";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import {faArrowCircleDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PendingPrinting, PrintShipmentRequest, selectedPendingPrinting} from "../../../../../models/shipment";
import ConfirmModal from "../../../../SharedComponents/ConfirmModal";
import ActionButton from "../../../../SharedComponents/ActionButton";
import {useDispatch} from "react-redux";
import {SetIsLoading} from "../../../../../store/actions/ui";
import PendingDetailsInnerTable from "./pendingDetailsInnerTable";

interface Props {
    refreshPrintedShipmentTable: () => void
}

const PendingTable = ({refreshPrintedShipmentTable}: Props) => {
    const dispatch = useDispatch();
    const shipClient = new ShipClient();
    const [pendingData, setPendingData] = useState<any[]>([]);
    const [args, setArgs] = useState<string[]>(['Agent']);
    const [details, setDetails] = useState<{ account: string, agent?: string }[]>([]);
    const [detailsLoading, setDetailsLoading] = useState<{ [key: string]: boolean }>({});
    const [detailsData, setDetailsData] = useState<{ [key: string]: any[] }>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [printRequest, setPrintRequest] = useState<PrintShipmentRequest>();
    const [showConfirmPrint, setShowConfirmPrint] = useState<boolean>(false);
    const [showConfirmBulkPrint, setShowConfirmBulkPrint] = useState<boolean>(false);
    const [bulkPrintRequest, setBulkPrintRequest] = useState<selectedPendingPrinting[]>();
    const [selectedRows, setSelectedRows] = useState<selectedPendingPrinting[] | null>(null);

    const [mergeLabelWithPackingList, setMergeLabelWithPackingList] = useState<boolean>(true);

    useEffect(() => {
        fetchData();
    }, [args]);

    const fetchData = async () => {
        setLoading(true);
        const res = await shipClient.GetPendingPrintingShipments(args);
        setPendingData(res);
        setLoading(false);
    };

    const fetchDetails = async (account: string, agent: string, hasCod: boolean | undefined) => {
        const key = `${account}-${agent}`;
        setDetailsLoading({...detailsLoading, [key]: true});
        const shipments = await shipClient.GetDetailsPrintingShipments({
            account,
            agent,
            hasCod: !!args?.includes("Cod") ? hasCod : undefined,
        });
        setDetailsLoading({...detailsLoading, [key]: false});
        setDetailsData({...detailsData, [key]: shipments});

    };


    const handelPrint = (printData: PrintShipmentRequest) => {
        setPrintRequest(printData)
        setShowConfirmPrint(true)
    }

    const handleBulkPrinting = () => {
        setBulkPrintRequest(selectedRows!)
        setShowConfirmBulkPrint(true)
    }

    const OnConfirmPrinting = async () => {
        if (!printRequest) return
        dispatch(SetIsLoading(true))
        const res = await shipClient.PrintShipments({...printRequest, mergeLabelWithPackingList});
        if (res) {
            fetchData();
            printRequest?.account && fetchDetails(
                printRequest?.account,
                printRequest?.agent,
                printRequest?.hasCod)
            refreshPrintedShipmentTable();
        }
        setMergeLabelWithPackingList(true)
        dispatch(SetIsLoading(false))
    }

    const OnConfirmBulkPrinting = async () => {
        if (!bulkPrintRequest || bulkPrintRequest.length === 0) return;
        dispatch(SetIsLoading(true))
        let containerIds = [] as number[];
        await Promise.all(
            bulkPrintRequest.map(async (item) => {
                let res = await shipClient.PrintShipments({...item, mergeLabelWithPackingList});
                containerIds.push(res);
            })
        );
        setSelectedRows(null)
        setMergeLabelWithPackingList(true)
        fetchData();
        refreshPrintedShipmentTable();
        dispatch(SetIsLoading(false))
    };

    const handleArgs = (name: string) => {
        setPendingData([])
        if (args.includes(name)) {
            setArgs([...args.filter((item: any) => item !== name)])
        } else {
            setArgs([...args, name])
        }
    };

    const toggleDetails = async (account: string, agent: string, hasCod: boolean | undefined) => {
        const position = details.findIndex(item => item.account === account && item.agent === agent);
        let newDetails;
        if (position !== -1) {
            newDetails = details.slice();
            newDetails.splice(position, 1);
        } else {
            newDetails = [...details, {account, agent}];
        }
        setDetails(newDetails);
        if (position !== -1) {
            return;
        }
        ;
        fetchDetails(account, agent, hasCod);
    };


    const scopedSlots = {
        account: (item: PendingPrinting) => {
            return <td>{item.accountName ?? ""}</td>
        },
        agent: (item: PendingPrinting) => {
            return <td>{item.agent ?? ""}</td>
        },
        numberOfShipments: (item: PendingPrinting) => {
            return <td>{item.numberOfShipments ?? ""}</td>
        },
        hasCOD: (item: PendingPrinting) => {
            return <td>{item.hasCod == true ? "Yes" : item.hasCod == false ? "No" : ""}</td>
        },
        viewDetails: (item: PendingPrinting, index: number) => {
            return <td className={""}>
                <CTooltip
                    content="Sku details"
                >
                    <CContainer>
                        <FontAwesomeIcon icon={faArrowCircleDown} size="lg"
                                         onClick={() => {

                                             toggleDetails(item.account, item.agent, item.hasCod)
                                         }}
                                         color={"#FF8800"}
                                         style={{cursor: "pointer"}}
                        />
                    </CContainer>
                </CTooltip>
            </td>
        },
        print: (item: PendingPrinting, index: number) => {
            return <td>
                <CTooltip content="Print">
                    <CIcon
                        content={freeSet.cilDescription}
                        onClick={() => {
                            handelPrint({
                                account: item?.account,
                                hasCod: !!args?.includes("Cod") ? item?.hasCod : undefined,
                                agent: item?.agent,
                            })
                        }}
                        style={{cursor: "pointer"}}
                    />
                </CTooltip>

                <CInputCheckbox
                    style={{marginLeft: 4, marginTop: 2}}
                    checked={Boolean(selectedRows?.find(item => item.index == index))}
                    onChange={() => {
                        if (Boolean(selectedRows?.find(item => item.index == index))) {
                            setSelectedRows(selectedRows?.filter((item => item?.index !== index)) || [])

                        } else {
                            setSelectedRows([...selectedRows || [], {...item, index}])
                        }

                    }}
                />
            </td>
        },
        "details":
            (item: any, index: number) => {
                return (
                    <CCollapse
                        show={details?.some(detail => detail.account === item.account && detail.agent === item.agent)}>
                        <PendingDetailsInnerTable
                            shipments={detailsData[`${item.account}-${item.agent}`] ?? []}
                            loading={detailsLoading[`${item.account}-${item.agent}`]}
                            handlePrint={(e: PrintShipmentRequest) => handelPrint(e)}
                            account={item?.account}
                            hasCod={!!args?.includes("Cod") ? item?.hasCod : undefined}
                            agent={item?.agent}
                        />
                    </CCollapse>
                )
            }
    }
    return (
        <>
            <fieldset className="b-fieldset p-2 text-primary">
                <CRow style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                    <CCol className={"text-right mr-3 mb-1"}>
                        {(selectedRows?.length && selectedRows?.length > 0) ?
                            <ActionButton
                                text={`Print`}
                                onClick={() => handleBulkPrinting()}/> : <></>}
                    </CCol>
                </CRow>
                <legend className="b-legend" style={{width: "50px"}}>
                    <h5>Print</h5>
                </legend>
                <CContainer>
                    <CDataTable
                        loading={loading}
                        size="sm"
                        hover
                        columnHeaderSlot={{
                            agent:
                                <><CInputCheckbox
                                    style={{top: "0"}}
                                    checked={args.includes("Agent")}
                                    onChange={() => handleArgs("Agent")}
                                />Agent</>,
                            hasCOD:
                                <><CInputCheckbox
                                    style={{top: "0"}}
                                    checked={args.includes("Cod")}
                                    onChange={() => handleArgs("Cod")}
                                />Has COD</>,

                        }}
                        scopedSlots={scopedSlots ?? {}}
                        items={Array.isArray(pendingData) ? pendingData : []}
                        fields={[
                            {key: "viewDetails", label: "", _style: {width: "5%"}},
                            {key: "account", label: "Account", _style: {width: "25%"}},
                            {key: "hasCOD", label: "Has COD", _style: {width: "25%"}},
                            {key: "agent", label: "Agent", _style: {width: "25%"}},
                            {key: "numberOfShipments", label: "Number of shipments", _style: {width: "15%"}},
                            {key: "print", label: "Actions", _style: {width: "5%"}}
                        ]}
                    />
                </CContainer>
            </fieldset>

            {showConfirmPrint && <ConfirmModal
                children={
                    <CRow className={"ml-2 mt-4"}>
                        <CCol md={12}>
                            <CInputCheckbox
                                name={"mergeLabelWithPackingList"}
                                checked={mergeLabelWithPackingList}
                                onChange={(e) => {
                                    setMergeLabelWithPackingList(!mergeLabelWithPackingList)
                                }}
                            />
                            <CLabel style={{fontSize: "12px"}} className={"mt-1"}>Merge packing with label</CLabel>
                        </CCol>
                    </CRow>
                }
                onClose={() => {
                    setShowConfirmPrint(false);
                    setPrintRequest(undefined);
                    setMergeLabelWithPackingList(true);
                }} title={`Print shipments ?`} body={"Are you sure you want to print these shipments"}
                onSubmit={() => {
                    setShowConfirmPrint(false);
                    OnConfirmPrinting();
                }}/>
            }

            {showConfirmBulkPrint && <ConfirmModal
                children={
                    <CRow className={"ml-2 mt-4"}>
                        <CCol md={12}>
                            <CInputCheckbox
                                name={"mergeLabelWithPackingList"}
                                checked={mergeLabelWithPackingList}
                                onChange={(e) => {
                                    setMergeLabelWithPackingList(!mergeLabelWithPackingList)
                                }}
                            />
                            <CLabel style={{fontSize: "12px"}} className={"mt-1"}>Merge packing with label</CLabel>
                        </CCol>
                    </CRow>
                }
                onClose={() => {
                    setShowConfirmBulkPrint(false);
                    setBulkPrintRequest(undefined);
                    setMergeLabelWithPackingList(true);
                }} title={`Print shipments ?`} body={"Are you sure you want to print these shipments"}
                onSubmit={() => {
                    setShowConfirmBulkPrint(false);
                    OnConfirmBulkPrinting();
                }}/>
            }
        </>
    )
}

export default PendingTable