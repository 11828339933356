import {

    CRow,
    CCol,
    CButton,
} from "@coreui/react";
import React, {useEffect, useState} from "react";
import SWSelect from "../../../SharedComponents/SWSelect";
import SWInput from "../../../SharedComponents/SWInput";
import ActionButton from "../../../SharedComponents/ActionButton";
import {useHistory} from "react-router";
import {AccountFilterGroup} from "../../../../models/filter";
import AccountingClient from "../../../../clients/accountingClient";

interface Props {
    newFilters: AccountFilterGroup,
    setNewFilters: React.Dispatch<React.SetStateAction<AccountFilterGroup>>
}

const AccountSearchForm = (props: Props) => {

    const history = useHistory();
    const accountingClient = new AccountingClient();


    const [accountingProfiles, setAccountingProfiles] = useState<{ [k: string]: string }>({});


    useEffect(() => {
        GetAccountingProfiles();
    }, [])

    const GetAccountingProfiles = async () => {
        const profiles = await accountingClient.LookupProfiles()
        const res = await accountingClient.GetAllowedProfiles({})
        if (res?.allowAllProfiles) {
            setAccountingProfiles(profiles)
            return
        }
        const allowedProfileIds = res?.allowedProfileIds
        if (res.allowedProfileIds) {
            let dictionary: {
                [k: string]: string
            } = Object.assign({}, ...allowedProfileIds.map((x) => ({[x]: profiles[x]})));
            setAccountingProfiles(dictionary)
        }
    }

    return (
        <fieldset className="b-fieldset p-2 text-primary">
            <legend className="b-legend" style={{width: "92px"}}>
                <h5>Accounts</h5>
            </legend>
            <CRow>
                <CCol md={3} sm={12}>
                    <SWSelect
                        label={"Account Type"}
                        name={`accountType`}
                        isClearable
                        value={props.newFilters.filters?.accountType?.toString() ?? ""}
                        onChange={(e) => {
                            props.setNewFilters({
                                ...props.newFilters,
                                filters: {
                                    ...props.newFilters.filters, accountType: e ? parseInt(e) : undefined
                                }
                            })
                        }}

                        values={[
                            {value: "1", label: "Customer"},
                            {value: "2", label: "Agent"},
                            {value: "3", label: "Both"}
                        ]}
                    />
                </CCol>
                <CCol md={3}>
                    <SWSelect values={accountingProfiles} value={props.newFilters.filters?.profileId?.toString() ?? ""}
                              label={"Profile"}
                              onChange={(e) => {
                                  props.setNewFilters({
                                      ...props.newFilters,
                                      filters: {
                                          ...props.newFilters.filters, profileId: e ? parseInt(e) : undefined
                                      }
                                  })
                              }} isClearable/>
                </CCol>
                <CCol md={3}>
                    <SWInput label={"Quick Search"} name={"field"} value={props.newFilters.filters?.field} onChange={(e) => {
                        props.setNewFilters({
                            ...props.newFilters,
                            filters: {
                                ...props.newFilters.filters, field: e ? e : ''
                            }
                        })
                    }}/>
                </CCol>
                <CCol>

                    <CRow className={'mt-4'}>
                        <ActionButton
                            text={"Create"}
                            color={"success"}
                            onClick={() => history.push({pathname: `/financial/account/create`})}
                        />
                    </CRow>
                </CCol>

            </CRow>
        </fieldset>
    );
};

export default AccountSearchForm;
