import React from "react";

export const TableHeader = (props: { header: string[] }) => {
    return (
        <thead>
        {props.header.map((value, index) => (
            <th className="sticky-top bg-white" key={index}>
                {value}
            </th>))
        }
        </thead>)
}

