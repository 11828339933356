import { combineReducers } from "redux";
import appReducer from "./appReducer";
import containerReducer from "./containerReducer";
import sortingruleReducer from "./sortingRuleReducer";
import shipmentReducer from "./shipmentReducer";
import quotationReducer from "./quotationReducer";
import containertypeReducer from "./containerTypeReducer";
import userReducer from "./userReducer";
import ratesReducer from "./ratesReducer";
import AccountsReducer from "./accountsReducer";

export default combineReducers({
  container: containerReducer,
  shipment: shipmentReducer,
  quotation: quotationReducer,
  account :AccountsReducer,
  app: appReducer,
  sortingrule: sortingruleReducer,
  containertype: containertypeReducer,
  user: userReducer,
  rates:ratesReducer,
});
