import React from "react";
import { CCard, CCardBody, CCardFooter, CCardText, CCardTitle, CCol, CRow } from "@coreui/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";


interface IProps {
    title: string
    children?: JSX.Element
    onView: () => void
    total?: string
    subTitle: string
    icon: JSX.Element
}

const Card = (props: IProps) => {
    const { title, children, onView, total, subTitle, icon } = props;
    return (
        <CCard className={"border-0"}>
            <CCardBody>
                <CCardTitle>
                    <CRow style={{ display: "flex", alignItems: "center",justifyContent:"space-between" }}>
                            <p className="text-primary ml-1 " style={{ fontSize: "26px"}}>
                                {icon}
                            </p>

                            <p className="text-orange mr-1 " style={{ fontSize: "2.5em" }}>
                                {total}
                            </p>
                    </CRow>
                    <p className="text-primary text-right" style={{ fontSize: "22px" }}>
                        {title}
                    </p>
                </CCardTitle>
                <CCardText>
                    {subTitle}
                </CCardText>
                {children}
            </CCardBody>
            <CCardFooter
                className={"text-orange"}
                style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "space-between", backgroundColor: "#fbfcff" }}
                onClick={() => onView()}>
                <small className="text-medium-emphasis">View Details</small>
                <FontAwesomeIcon
                    icon={faChevronRight}
                />
            </CCardFooter>
        </CCard>
    )
}

export default Card;