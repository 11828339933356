import { CModal, CModalHeader, CModalTitle, CModalBody, CImg } from "@coreui/react";

interface IProps {
    onClose: () => void
    images: string[]
}
const ViewImagesModal = ({ onClose, images }: IProps) => {

    return (
        <CModal
            className={"text-primary"}
            size={"lg"}
            show={true}
            onClose={onClose}
            closeOnBackdrop={false}>
            <CModalHeader closeButton>
                <CModalTitle >Images</CModalTitle>
            </CModalHeader>
            <CModalBody className={"d-flex flex-column align-items-center"}>
                {images?.length > 0 && (
                    <div
                        style={{
                            overflowX: 'auto',
                            borderRadius: '10px',
                            width: '100%',
                            height: 300,
                            display: 'flex',
                            justifyContent: images?.length == 1 ? "center" : 'flex-start',
                            alignItems: 'center',
                            columnGap: '1rem',
                            rowGap: '1rem',
                            overflowY: 'hidden',
                            padding: '0 1rem'
                        }}
                    >
                        {images?.map((img, index) => (
                            <div key={index} style={{ position: 'relative', flexShrink: 0,border: '1px solid lightGray', borderRadius: '10px'  }}>
                                <CImg
                                    style={{ cursor: "pointer", width: 200, padding:2,height: 200, objectFit: 'contain', borderRadius: '10px' }}
                                    onClick={() => window.open(img, '_blank', 'noreferrer')}
                                    src={`${img}`}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </CModalBody>
        </CModal>

    )
}

export default ViewImagesModal;