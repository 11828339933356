import {CDataTable, CLink} from "@coreui/react"
import React, {useState} from "react"
import {IPaginatedResult, IPagination} from "../../../../../models/filter";
import {SkuSearch, SkuSearchDetailsReportItem} from "../../../../../models/warehouse";
import PageCount from "../../../../SharedComponents/PageCount";
import {useHistory, useLocation} from "react-router";
import {formatDate} from "../../../../../utils/dateUtil";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";

interface IProps {
    paginationSkuDetails: IPaginatedResult<SkuSearchDetailsReportItem>
    pagination: IPagination
    accountsLookup: { [k: string]: string }
}

const SearchResultDetails = (props: IProps) => {
    const hist = useHistory()
    const loc = useLocation()
    const [limit, setLimit] = useState<number>(20)
    
    return (
        <div className={"small"}>
            <CDataTable
                size="sm"
                hover
                items={props.paginationSkuDetails.matches}
                fields={[
                    {key: "actions", label: "", _style: {width: '1%'}},
                    "name",
                    "code",
                    "account",
                    "status",
                    "batchNumber",
                    "itemNumber",
                    "expiryDate",
                    "warehouse",
                    "location",
                    "quantity",
                    {key:"quantityAvailable", label:"Available"}
                ]}
                scopedSlots={{
                    actions: (item: SkuSearchDetailsReportItem) => {
                        return <td><CLink to={`/stock/skus/${item.id}`} className="card-header-action">
                            <FontAwesomeIcon icon={faEye}/>
                        </CLink></td>
                    },
                    account: (item: SkuSearchDetailsReportItem) => {
                        return <td>{props.accountsLookup[item.accountNumber]}</td>
                    },
                    batchNumber: (item: SkuSearchDetailsReportItem) => {
                        return item.batchNumber ? <td>{item.batchNumber}</td> : <td />
                    },
                    itemNumber: (item: SkuSearchDetailsReportItem) => {
                        return item.itemNumber ? <td>{item.itemNumber}</td> : <td />
                    },
                    expiryDate: (item: SkuSearchDetailsReportItem) => {
                        return <td>{formatDate(item.expiryDate)}</td>
                    }
                }}
            />
            <PageCount
                onPageChange={(p) => {
                    const modifiedSearch = new URLSearchParams(loc.search);
                    modifiedSearch.delete("offset");
                    hist.push(
                        `${loc.pathname}?${modifiedSearch.toString()}&offset=${
                            p * limit!
                        }`
                    );
                }}
                onPageSizeChange={(ps) => {
                    const modifiedSearch = new URLSearchParams(loc.search);
                    modifiedSearch.delete("limit");
                    hist.push(
                        `${loc.pathname}?${modifiedSearch.toString()}&limit=${ps}`
                    );
                    setLimit(ps)
                }}
                currentPage={Math.ceil(
                    props?.pagination?.offset! / limit!
                )}
                maxPage={Math.ceil(
                    props.paginationSkuDetails?.total! / limit!
                )}
                maxFound={props.paginationSkuDetails?.total}
            />
        </div>
    )
}

export default SearchResultDetails