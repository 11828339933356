import React, { useState } from 'react';
import { CDataTable, CContainer, CTooltip } from '@coreui/react';
import { AddAttachmentRequest, CreateShipment } from '../../../models/shipment';
import { freeSet } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { FormikProps } from "formik";
import { downloadBlob } from '../../../utils/downloadUtils';
import AttachmentModal from './components/attachemntModal';

interface IProps {
    formik: FormikProps<CreateShipment>;
}

const Attachments = ({ formik }: IProps) => {

    const { values,setFieldValue } = formik
    const [editAttachment, setEditAttachment] = useState<{ isShown: boolean, data: AddAttachmentRequest,index:number } | null>(null)


    const handleEditAttachment = (index: number,fileName?: string,type?:string) => {
        !!fileName && setFieldValue(`attachments[${index}].fileName`, fileName);
        !!type && setFieldValue(`attachments[${index}].type`, type);
    };
    const handleDeleteAttachment = (index: number) => {
        const updatedValues = [...values.attachments || []];
        updatedValues?.splice(index, 1);
        setFieldValue("attachments", updatedValues);
    };


    const scopedSlots = {
        fileName: (e: AddAttachmentRequest) => {
            return <td>
                {e?.fileName}
            </td>
        },
        attachmentType: (e: AddAttachmentRequest) => {
            return <td>
                {e?.type}
            </td>
        },
        download: (e: AddAttachmentRequest) => {
            return (
                <td>
                    <CTooltip content={"Download"}>
                        <CIcon
                            content={freeSet.cilCloudDownload}
                            style={{ cursor: "pointer", margin: "auto" }}
                            onClick={() => downloadBlob(e?.location, e?.fileName)}
                        />
                    </CTooltip>
                </td>
            )
        },
        action: (e: AddAttachmentRequest,index:number) => {
            return <td>
                <CTooltip content={"Edit"}>
                    <CIcon
                        content={freeSet.cilPencil}
                        style={{ cursor: "pointer", padding: "0 0 4px 4px" }}
                        size={"lg"}
                        color={"primary"}
                        onClick={() => {
                            setEditAttachment({ isShown: true, data: e,index });
                        }}
                    />
                </CTooltip>
                <CTooltip content={"Delete"}>
                    <CIcon
                        content={freeSet.cilTrash}
                        style={{ cursor: "pointer" }}
                        onClick={() => { handleDeleteAttachment(index)}}
                    />
                </CTooltip>
            </td>
        },
    };

    return (
        <CContainer className="bg-white p-4 text-primary small ">
            <CDataTable
                size="sm"
                hover
                scopedSlots={scopedSlots}
                items={values?.attachments || []}
                fields={[
                    { key: "fileName", label: "File Name" },
                    { key: "attachmentType", label: "Attachment Type" },
                    { key: "download", label: "	Download" },
                    { key: "action", label: "Actions" }
                ]}
            />
            {editAttachment?.isShown &&
                <AttachmentModal
                    onClose={() => setEditAttachment(null)}
                    onSubmit={(fileName?:string,type?:string) => {
                        handleEditAttachment(editAttachment?.index,fileName,type)
                        setEditAttachment(null)
                    }}
                />
            }
        </CContainer>
    )
}

export default Attachments;