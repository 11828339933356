import {
    CButton,
    CCol,
    CContainer,
    CDataTable, CDropdown, CDropdownItem, CDropdownMenu,
    CDropdownToggle,
    CInputCheckbox, CLabel, CLink, CModal, CModalBody, CModalFooter, CModalHeader, CRow,
    CSpinner,
} from "@coreui/react";
import React, {useEffect, useState} from "react";
import ShipClient from "../../../clients/shipClient";
import {Form, Input} from "../../SharedComponents/SWForm";
import {
    GenerateLabelRequest,
    ScanResult, ShipmentAttachment, shipmentBox, ShipmentBoxesResponse, shipmentBoxItem,

} from "../../../models/shipment";
import ActionButton from "../../SharedComponents/ActionButton";
import {Report} from "../../../models/Report";
import LookupsClient from "../../../clients/lookupsClient";
import {HideNotification, SetLoading, ShowNotification} from "../../../store/actions/auth";
import {AppModel, RemoteBlob} from "../../../models/app";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import PrintOriginReciptLabelModal from "./PrintOriginReciptModal";
import SWInput from "../../SharedComponents/SWInput";
import {fi} from "date-fns/locale";
import SWSelect from "../../SharedComponents/SWSelect";
import {Value} from "sass";
import ConfirmModal from "../../SharedComponents/ConfirmModal";
import PdfViewer from "../../SharedComponents/pdfViewer";
import WarehousesClient from "../../../clients/warehousesClient";
import {SkuSearchResultForBoxes} from "../../../models/warehouse";


const ShipmentBox = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedShipment, setSelectedShipment] = useState<{
        shipNumber?: string,
        shipBox?: number,
        checkSystem: boolean
        isManualBoxNumberInput: boolean

    }>({
        shipNumber: '',
        shipBox: undefined,
        checkSystem: true,
        isManualBoxNumberInput: false

    })
    const [shipmentBoxes, setShipmentBoxes] = useState<shipmentBox[]>([]);
    const [shipmentBoxItems, setShipmentBoxItems] = useState<shipmentBoxItem[]>()
    const [manualShipmentBox, setManualShipmentBox] = useState<number>();
    const [shipmentDetails, setShipmentDetails] = useState<{
        id: string,
        uid: string,
        account: string,
        items: shipmentBoxItem[]
        skus: SkuSearchResultForBoxes[]
    }>();
    const [numberOfGeneratedBox, setNumberOfGeneratedBox] = useState<number | null>();
    const shipClient = new ShipClient();
    const warehousesClient = new WarehousesClient();
    const manualEntry = {
        label: 'New Box',
        value: "-1",
        item: [],
    }

    const dispatch = useDispatch();
    const handleShipmentBoxes = async (shipNumber: string) => {
        setIsLoading(true)


        const shipmentRes = await getShipmentByNumber(shipNumber)
        if (shipmentRes?.ShipmentNotFound == null) {
            setSelectedShipment({
                ...selectedShipment,
                shipBox: selectedShipment.shipBox,
                shipNumber: shipNumber,

            })


            const mappedBoxes = shipmentRes.shipmentBoxes?.map((box: ShipmentBoxesResponse) => ({
                label: box.number || 'No Label',
                value: box.id.toString(),
                item: box.items
            })) || [];
            const response = [manualEntry, ...mappedBoxes]
            setShipmentBoxes(response);


            const warehouseRes = await warehousesClient.SearchSku({limit: 30000, accountNumber: shipmentRes.account})

            const formattedData: SkuSearchResultForBoxes[] = [];
            warehouseRes.data.matches.map((sku: SkuSearchResultForBoxes) => {
                formattedData.push({code: sku.code, barcodes: sku.barcodes})
            })
            
            setShipmentDetails({
                account: shipmentRes.account,
                uid: shipmentRes.uid,
                id: shipmentRes.id?.toString(),
                skus: formattedData,
                items: shipmentRes?.items

            })
        }


    }
    const handleKeyDown = async (e: any, field: string) => {
        setIsLoading(true)
        let res = null;
        if (e.code === "Enter") {
            if (field == 'shipment') {
                await handleShipmentBoxes(e.target.value)
            } else if (field.toLowerCase() == 'sku') {
                let data = e.target.value;

                if (selectedShipment.checkSystem) {

                    const boxItem = shipmentDetails?.items.find((item: shipmentBoxItem) => {
                        return (item.productCode.toLowerCase() == data.toLowerCase())
                    })
                    if (boxItem) {
                        shipmentDetails?.skus.find((sku: SkuSearchResultForBoxes) => {
                            if (sku.barcodes.find((barcode: string) => {
                                if (barcode == data) {
                                    data = sku.code
                                    return true;
                                }
                            })) {
                                return true;
                            }
                        })

                        res = await shipClient.addShipmentBoxItem(selectedShipment?.shipBox ?? 0, data, 1, selectedShipment.checkSystem)
                        if (res.succeeded) {
                            await handleChangeSkuItems()
                        }
                    } else {
                        dispatch(ShowNotification("Failed", "Sku is not in the shipment items", true))

                        setTimeout(() => {
                            dispatch(HideNotification())
                        }, 3000);


                    }
                }

            }

        }
        setIsLoading(false)
    };


    const getShipmentByNumber = async (shipmentNumber: string) => {
        return await shipClient.getShipmentByNumber2(shipmentNumber)
    }
    const handleCloseShipment = () => {
        setShipmentBoxes([]);
        setSelectedShipment({...selectedShipment, shipNumber: '', shipBox: undefined});
        setShipmentDetails(undefined);
        setShipmentBoxItems([]);
    };

    const handleQuantityChange = (itemId: number, newQuantity: number) => {
        setShipmentBoxItems(prevItems =>
            prevItems?.map(item =>
                item.id === itemId
                    ? {...item, quantity: newQuantity}
                    : item
            )
        );
    };

    const handleBlurForQuantity = async (itemId: number, quantity: number) => {
        const res = await shipClient.updateShipmentBoxItem(itemId, quantity)
        if (res.succeeded) {
            dispatch(ShowNotification("Success", "Quantity Changed!", false))
            setTimeout(() => {
                dispatch(HideNotification())
            }, 3000);
            handleChangeSkuItems();
        }
    }


    const handleAddShipBoxNumberManually = async () => {
        let res = null;
        res = await shipClient.createShipmentBox(parseInt(shipmentDetails?.uid ?? '-1'), manualShipmentBox ?? -1)


        setManualShipmentBox(undefined)
        setSelectedShipment({...selectedShipment, shipBox: undefined})
        res = await shipClient.getShipBox(parseInt(shipmentDetails?.id ?? '-1'))
        handleShipmentBoxChange(res);
    }

    const handleChangeSkuItems = async () => {


        const res = await shipClient.getShipBox(parseInt(shipmentDetails?.id ?? '-1'))
        handleShipmentBoxChange(res);


        const result = res.data.find((box: ShipmentBoxesResponse) => {
            return box.id == selectedShipment.shipBox
        })
        setShipmentBoxItems(result.items);

    }

    const handleClearShipmentBox = () => {
        setShipmentBoxItems(undefined)
        setSelectedShipment({...selectedShipment, shipBox: undefined, checkSystem: false});

    }

    const handleGenerateBoxNumber = async () => {
        await shipClient.generateShipmentBoxes(parseInt(shipmentDetails?.uid ?? '-1'), numberOfGeneratedBox ?? -1);
        const res = await shipClient.getShipBox(parseInt(shipmentDetails?.id ?? '-1'))
        if (res.succeeded) {
            dispatch(ShowNotification("Success", "Generated!", false))
            setTimeout(() => {
                dispatch(HideNotification())
            }, 3000);
        }
        handleShipmentBoxChange(res);
        setManualShipmentBox(undefined)
        setSelectedShipment({...selectedShipment, shipBox: undefined})
    }

    const handleTotalQuantityInBoxes
        = (code: string) => {
        let count = 0;
        shipmentBoxes.map((box: shipmentBox) => {
            box.item.map((boxItem: shipmentBoxItem) => {
                if (boxItem.productCode == code) {
                    count += boxItem.quantity;
                }
            })
        })
        return count;
    }

    const handleTotalQuantityInShipItems = (code: string) => {
        let count = 0;

        shipmentDetails?.items.map((item: shipmentBoxItem) => {
            if (item.productCode == code) {
                count += item.quantity
            }
        })

        return count;

    }

    const handleShipmentBoxChange = (res: any) => {

        const mappedBoxes = res.data?.map((box: ShipmentBoxesResponse) => ({
            label: box.number || 'No Label',
            value: box.id.toString(),
            item: box.items
        })) || [];

        const response = [manualEntry, ...mappedBoxes]
        setShipmentBoxes(response);
    }


    return (
        <>
            <CContainer className="bg-white p-4 text-primary small">
                <h5 style={{color: "gray"}}>Box {(shipmentDetails?.id ? "(" + selectedShipment?.shipNumber + ")" : null)}</h5>


                {isLoading ? <CSpinner
                    className="mx-auto d-block my-5"
                    color="primary"
                    style={{width: "5em", height: "5em"}}
                /> : <CRow>
                    <CCol md={5} className={''}>
                        <CRow className={'d-flex justify-content-between'}>
                            <div className={'font-sm'} style={{width: '20%'}}>
                                Shipment
                            </div>
                            <SWInput readonly={shipmentDetails?.id != null} value={selectedShipment.shipNumber}
                                     className={' w-50'} type="text"
                                     onChange={(e) => {
                                         setSelectedShipment({...selectedShipment, shipNumber: e})
                                     }}
                                     onKeyDown={(e) => {
                                         handleKeyDown(e, 'shipment')
                                     }}
                            />
                            <div className={'w-25 '}>

                                <ActionButton text={'Close Shipment'} extraClass={'w-100'}
                                              disabled={shipmentDetails?.id == null}
                                              onClick={handleCloseShipment}/>
                            </div>
                        </CRow>
                        <CRow className={'d-flex justify-content-between'}>
                            <div className={'font-sm'} style={{width: '20%'}}> Box</div>
                            <SWSelect className={' w-50'} value={selectedShipment?.shipBox?.toString()}
                                      readonly={shipmentDetails?.id == null}
                                      values={shipmentBoxes || []} onChange={(e) => {
                                setSelectedShipment({...selectedShipment, shipBox: parseInt(e ?? '0')})
                                setShipmentBoxItems(shipmentBoxes.find((index) => {
                                    return index.value == e
                                })?.item);

                            }}/>
                            <div className={'w-25'}>
                                <ActionButton text={'Close Box (Finish)'} extraClass={'w-100'}
                                              disabled={selectedShipment?.shipBox == undefined}
                                              onClick={handleClearShipmentBox}/>
                            </div>
                        </CRow>
                        <CRow className={'d-flex justify-content-between'}>
                            <div className={'font-sm'} style={{width: '20%'}}> Sku</div>
                            <SWInput
                                readonly={selectedShipment?.shipBox == null}
                                className={'w-50'}
                                type="text"
                                onKeyDown={(e) => {
                                    handleKeyDown(e, 'sku')
                                }}
                            />
                            <div className={'w-25 d-flex justify-content-between pl-4'}>
                                <div>check system</div>

                                <CInputCheckbox checked={selectedShipment?.checkSystem} onClick={() => {
                                    setSelectedShipment({
                                        ...selectedShipment,
                                        checkSystem: !selectedShipment.checkSystem
                                    })
                                }}/></div>


                        </CRow>

                        {(shipmentBoxItems?.length ?? 0) > 0 &&
                            <CRow className={'d-flex justify-content-around w-100 mb-4'}>
                                <div className={'font-sm'}
                                     style={{width: '50%', fontWeight: 'bold'}}>Product code
                                </div>
                                <div className={'font-sm'} style={{width: '10%', fontWeight: 'bold'}}>Qty</div>

                                <div className={'font-sm'}
                                     style={{width: '10%', fontWeight: 'bold'}}> Prepared
                                </div>

                            </CRow>}
                        {shipmentBoxItems?.map((index) => (<CCol md={12}>
                            <CRow className={'d-flex justify-content-around w-100'}>
                                <div className={'font-sm'}
                                     style={{width: '50%', overflow: 'clip'}}>{index.productCode}</div>
                                <SWInput readonly={false} style={{width: "20%"}} type="number"
                                         value={index.quantity.toString()} onChange={(e) => {
                                    handleQuantityChange(index.id, parseInt(e));

                                }} onBlur={() => {
                                    handleBlurForQuantity(index.id, index.quantity)
                                }}/>
                                <div className={'font-sm'}
                                     style={{width: '10%'}}> {handleTotalQuantityInBoxes(index.productCode) + ' / ' + handleTotalQuantityInShipItems(index.productCode)}</div>

                            </CRow>

                        </CCol>))

                        }


                    </CCol>

                </CRow>}

                {selectedShipment.shipBox?.toString() == '-1' &&
                    <CModal
                        closeOnBackdrop={false}
                        show={true}
                        centered
                        size={"lg"}
                        onClose={() => {
                            setSelectedShipment({...selectedShipment, shipBox: undefined})
                        }}
                    >
                        <CModalHeader>
                            <h5> Add Box </h5>
                        </CModalHeader>
                        <CModalBody className={"mb-1"} style={{height: '150px'}}>

                            <CRow className={"mb-1"}>
                                <CCol md={12}>
                                    <div className={'w-25 d-flex justify-content-between pl-4'}>
                                        <div>Manual box number input</div>

                                        <CInputCheckbox checked={selectedShipment?.isManualBoxNumberInput}
                                                        onClick={() => {
                                                            setSelectedShipment({
                                                                ...selectedShipment,
                                                                isManualBoxNumberInput: !selectedShipment.isManualBoxNumberInput
                                                            })
                                                        }}/></div>


                                </CCol>
                                <CCol md={12}>
                                    {selectedShipment.isManualBoxNumberInput ?
                                        <CRow width={'100%'} className={'d-flex justify-content-center mt-5'}>
                                            <SWInput value={manualShipmentBox?.toString()} className={' w-25'}
                                                     onChange={(e) => {
                                                         setManualShipmentBox(e ? parseInt(e) : undefined)
                                                     }}/>
                                            <div style={{width: '30%'}}>
                                                <CButton
                                                    style={{fontSize: "10px", marginLeft: '10px'}}
                                                    variant="outline"
                                                    disabled={manualShipmentBox == undefined}
                                                    color="success"
                                                    onClick={handleAddShipBoxNumberManually}
                                                >

                                                    Add

                                                </CButton>
                                            </div>
                                        </CRow> :
                                        <CRow width={'100%'} className={'d-flex justify-content-center mt-5'}><SWInput
                                            readonly={false} className={' w-25'} type="number"
                                            onChange={(e) => {
                                                setNumberOfGeneratedBox(parseInt(e))
                                            }}/>
                                            <div style={{width: '30%', marginLeft: '10px'}}>
                                                <ActionButton text={'Generate Box Number'}
                                                              onClick={handleGenerateBoxNumber}/>
                                            </div>
                                        </CRow>}


                                </CCol>


                            </CRow>


                        </CModalBody>

                        <CModalFooter>
                            <CRow className="justify-content-between w-100">
                                <CCol className="text-right">

                                    <CButton
                                        style={{fontSize: "10px"}}
                                        variant="outline"
                                        color="danger"
                                        onClick={() => {
                                            setManualShipmentBox(undefined)
                                            setSelectedShipment({...selectedShipment, shipBox: undefined})
                                        }}
                                    >
                                        Close
                                    </CButton>
                                </CCol>
                            </CRow>
                        </CModalFooter>

                    </CModal>}
            </CContainer>
        </>
    )
        ;
};

export default ShipmentBox;
