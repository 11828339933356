
import {SET_IS_LOADING} from '../types';
import Action from "../../models/actions";


export const SetIsLoading = (isLoading: boolean ):Action => {

    return {
        type: SET_IS_LOADING,
        payload:{
            isLoading
        }
    }
}
