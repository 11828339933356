import {CContainer} from "@coreui/react";
import React from "react";

interface IProps {}

const StockStamp = () => (
    <>
        <CContainer style={{left:'0', top:"30%"}} size={100} className="stampContainer mb-1">
            <h4 className="stampText text-danger">Reversed</h4>
        </CContainer>
    </>
)

export default StockStamp;