import {CContainer} from "@coreui/react";
import React, {useEffect, useState} from "react";
import CheckoutClient from "../../../clients/checkoutClient";
import {useLocation} from "react-router";
import {queryParamsToFilters} from "../../../utils/filterUtils";
import {useSearchOnFilterGroup} from "../../../hooks/helperHooks";

import {useDispatch, useSelector} from "react-redux";
import {AppModel} from "../../../models/app";
import {
    SetQuotationIsLoading,
    SetQuotationsFilterGroup,
    SetQuotationResult,
} from "../../../store/actions/quotations";

import SearchResult from "./components/SearchResult";
import NewSearchForm from "./components/NewSearchForm";
import {QuotationScenario} from "../../../models/quotation";

const SearchQuotations = () => {
    const dispatch = useDispatch();
    const checkoutClient = new CheckoutClient();
    const [scenarioLookups, setScenarioLookups] = useState<{ [k: string]: string }>()

    useSearchOnFilterGroup({
        updateFilterGroup: (fg) => dispatch(SetQuotationsFilterGroup(fg)),
        useFilterGroupSelector: () =>
            useSelector(
                (state: AppModel) => state.quotation.quotationsSearch.filterGroup!
            ),
        searchFunction: checkoutClient.getQuotations.bind(checkoutClient),

        setIsLoading: (loading) => dispatch(SetQuotationIsLoading(loading)),
        setResult: (result) =>
            dispatch(SetQuotationResult(result.page!, result.count!, result.result!)),
    });

    const loc = useLocation();

    const searchQuotations = () => {
        const fg = queryParamsToFilters(loc.search);
        dispatch(SetQuotationsFilterGroup(fg));
    };

    const getScenarios = async (profileId?: string) => {
        if (profileId) {
            const profile = await checkoutClient.GetProfile(profileId)
            if (!profile) return
            let lookups: { [k: string]: string } = {}
            profile.scenarios.forEach((scenario: QuotationScenario) => {
                lookups[scenario.code] = scenario.name
            })
            setScenarioLookups(lookups)
        } else {
            setScenarioLookups({})
        }
    }

    useEffect(() => {
        searchQuotations();
    }, [loc.search]);

    const quotationStatus: { [k: string]: string } = {
        draft: "Draft",
        pending: "Pending",
        invoiced: "Invoiced",
        invoiceSent: "Invoice Sent",
        paid: "Paid",
        deleted: "Deleted",
        canceled: "Canceled",
    }

    return (
        <CContainer className="bg-white p-4">
            <NewSearchForm additionalFilters={[
                {
                    field: "CreatedOn",
                    title: "Date Created",
                    type: "date"
                },
                {
                    field: "InvoiceDueDate",
                    title: "Invoice Due Date",
                    type: "date"
                },
                {
                    field: "Email",
                    title: "Email",
                    type: "text"
                },
                {
                    field: "Total",
                    title: "Invoice Value",
                    type: "number"
                },
                {
                    field: "Status",
                    title: "Status",
                    type: "select",
                    lookups: quotationStatus
                },
                {
                    field: "ScenarioCode",
                    title: "Scenario",
                    type: "select",
                    lookups: scenarioLookups
                },
            ]} getScenarios={getScenarios}/>
            <SearchResult/>
        </CContainer>
    );
};

export default SearchQuotations;
