import {
    CCol,
    CContainer,
    CRow,
    CLink,
    CButton,
    CFormGroup,
    CLabel,
    CInputGroup,
    CCard,
    CCardBody,
    CCardText,
    CCardTitle,
    CCollapse,
    CTooltip,
} from "@coreui/react";

import React, {useCallback, useEffect, useState} from "react";

import {useHistory} from "react-router";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import RatesClient from "../../../../../clients/ratesClient";
import {useDispatch} from "react-redux";
import {RemoteBlob} from "../../../../../models/app";
import {SheetInfo} from "../../../../../models/sheetInfo";
import {ShowNotification} from "../../../../../store/actions/auth";
import SWSelect from "../../../../SharedComponents/SWSelect";
import SWInput from "../../../../SharedComponents/SWInput";
import ActionButton from "../../../../SharedComponents/ActionButton";
import ConfirmModal from "../../../../SharedComponents/ConfirmModal";
import ExcelMapper from "../../../ShipmentsImport/ExcelMapper";
import WarehousesClient from "../../../../../clients/warehousesClient";
import {skuFlattedKeys, SkuFlattened, SkuImportSearch} from "../../../../../models/sku";
import SkuFlattenedEdit from "./SkuFlattenedEdit";
import SearchSkuImport from "./SearchSkuImport";
import Loading from "../../../../SharedComponents/Loading";


const CreateImportSku = () => {
    const ratesClient = new RatesClient();
    const warehouseClient = new WarehousesClient();
    const hist = useHistory();
    const dispatch = useDispatch();

    const [accounts, setAccounts] = useState<{ [key: string]: string }>({});
    const [account, setAccount] = useState<string | undefined>(undefined);
    const [file, setFile] = useState<File | undefined>(undefined);
    const [map, setMap] = useState<{ [k: string]: string | null }>({});
    const [defaultValue, setDefaultValue] = useState<SkuFlattened>({});
    const [confirm, setConfirm] = useState(false);
    const [confirmReset, setConfirmReset] = useState(false);
    const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);
    const [isLoadingFile, setIsLoadingFIle] = useState(false);
    const [newMap,setNewMap]=useState<{[key:string]:string|null}>({})
    const [skuImportData,setSkuImportData]=useState<SkuImportSearch>()


    const [collapse, setCollapse] = useState(false);
    const [excelInfo, setExcelInfo] = useState<{
        blobInfo: RemoteBlob | null;
        sheets: SheetInfo[] | null;
    }>({
        blobInfo: null,
        sheets: null,
    });
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);
    const {blobInfo, sheets} = excelInfo;

    useEffect(() => {
        const loadAccounts = async () =>
            setAccounts(await ratesClient.LookupCustomers());
        loadAccounts();
    }, []);
    const loadSkuImport = async () => {
            const r = await warehouseClient.SearchSkuImport({limit: 1, account: account});
            if(r.length>0) {
                setSkuImportData(r[0] ?? {});
                 setDefaultValue(r[0].parameters?.defaultValue ?? {});
            }
            else {
                setSkuImportData(  {});

            }


    };
    useEffect(() => {
        if(account){
            loadSkuImport();
        }
    }, [account]);
    useEffect(() => {
        if(account){
            mapperDataOnChange();
        }
    }, [skuImportData,sheets]);

    const accountChanged = async (v: string | undefined) => {
        setAccount(v);
        if (!v) return;

        setDefaultValue({})
    };
    const [fileLength, setFileLength] = useState<number | null>(0)

    const fileChosen = async (file?: File) => {

        if (!file || (!file?.name?.endsWith("xlsx") && !file?.name?.endsWith("csv") && !file?.name?.endsWith("xls"))) {
            dispatch(ShowNotification("Error", "File is not in the correct format", true))
            return
        }

        setShowError(false);
        setError("");
        setIsLoadingFIle(true)

        const response = await warehouseClient.UploadExcelFile(file!);
        if (!response?.blobInfo) {
            dispatch(ShowNotification("Error", "Invalid excel file", true))
            setIsLoadingFIle(false)
            return
        }
        setExcelInfo(response);
        setFile(file)
        setIsLoadingFIle(false)

    };

    const reset = () => {
        setAccount(undefined);
        setMap({});
        setFile(undefined);
        setDefaultValue({});
        setConfirmReset(false);
        setExcelInfo({sheets: null, blobInfo: null});
        setIsLoadingConfirm(false)
        setIsLoadingFIle(false)

    };
    const importSku = async () => {
        if (!account || !defaultValue || !map || !blobInfo) return;
        setShowError(false);
        setError("");
        setIsLoadingConfirm(true)

        const result = await warehouseClient.CreateSkuImport({
            accountId: account,
            parameters: {map: map, defaultValue: defaultValue},
            fileUrl: blobInfo.name,
            fileName: file?.name,
        })

        if (typeof result != "number") {
            setShowError(true);
            setError(result);
            setConfirm(false);
            setIsLoadingConfirm(false)
            return;
        }
        reset()
        setConfirm(false);

        // hist.push(`/shipments/import/${result}`);
    };
    const handleMapChange = (key: string, selected?: string) => {
        if (selected && Object.keys(map).includes(key)) {
            dispatch(
                ShowNotification("Error", `Already mapped to ${key}`, true)
            );
            return;
        }
        let mapToObject:{[key:string]:string|null}={}



    for( let k in map) {
    if (map[k]?.toLowerCase() != selected?.toLowerCase())
        mapToObject[k] = map[k]
     }

             if(key!=='notMapped' && selected)
                mapToObject[key]=selected?? ''

            setMap(mapToObject);



            // setMap({...map,[key]:selected ?? ''})



    };


    const handleShipmentFlattenedChanges = (name: string, value: any) => {
        setDefaultValue({...defaultValue, [name]: value});
    };
    const mapperDataOnChange=useCallback(()=>{
        let mapData: { [key: string]: string | null } = {};
        if(sheets && skuImportData && skuImportData.parameters){
            for (let mapValue in skuImportData?.parameters?.map ?? {}) {
                if(sheets[0]?.values[0].some((v:string)=>v.toLowerCase() === skuImportData.parameters?.map[mapValue as keyof typeof skuImportData.parameters.map]?.toLowerCase())){
                    mapData[mapValue as keyof typeof skuImportData.parameters.map]=skuImportData?.parameters?.map[mapValue as keyof typeof skuImportData.parameters.map]

                }
            } }
        if(sheets) {
            skuFlattedKeys.forEach((v)=>{
                if(sheets[0]?.values[0].some((s:string)=>v.toLowerCase()===s.toLowerCase()) && !Object.values(mapData).find((k)=>k?.toLowerCase()===v.toLowerCase())){
                    mapData[v as keyof typeof mapData]=v;
                }

            }) }

           setMap(mapData)

    },[sheets,skuImportData?.parameters])



    return (
        <>
        <CContainer className="bg-white p-4 text-primary small ">
            <h5 style={{color: "gray", marginBottom: "2%"}}>Import</h5>
            <CRow className={'align-items-start'}>
                <CCol md={"3"}>
                    <SWSelect
                        values={accounts}
                        label="Account"
                        onChange={accountChanged}
                        value={account}
                    />
                    {(sheets && sheets?.length > 0 && !account) &&
                        <CLabel className="mt-1">You must choose an account</CLabel>}

                </CCol>
                <CCol md={"4"}>
                    {isLoadingFile ? <Loading size={3} /> : blobInfo ? (
                        <CFormGroup>
                            <CLabel>File</CLabel>
                            <CInputGroup>
                                <div className="form-control">
                                    <CTooltip content={file?.name}>
                                        <CLink
                                            style={{fontSize: "10px"}}
                                            className="d-inline-block text-truncate w-100"
                                            href={blobInfo.location}
                                            target="_blank"
                                        >
                                            {file?.name}
                                        </CLink>
                                    </CTooltip>
                                </div>
                            </CInputGroup>
                        </CFormGroup>
                    ) : (
                        <SWInput
                            type="file"
                            label="File"
                            id={"input-file"}
                            onChangeFile={fileChosen}
                            valueFile={file}
                            readonly={!account}
                        />
                    )}
                    {sheets && sheets?.length > 0 && account && <CLabel>
                        Items to be imported: {fileLength}
                    </CLabel>}
                </CCol>
                <CCol md={"2"} style={{marginTop: "auto", marginBottom: "auto"}}>
                    <CRow className="d-flex justify-content-end p-2">
                        <CButton
                            style={{fontSize: "10px"}}
                            onClick={() => setConfirmReset(true)}
                            variant="outline"
                            color="primary"
                            className="mr-2"
                        >
                            Reset
                        </CButton>
                        <ActionButton
                            text={"Import"}
                            extraClass={'mr-2'}
                            disabled={
                                !account ||
                                !sheets ||
                                !map ||
                                Object.keys(map).length == 0}
                            onClick={() => setConfirm(true)}
                        />


                    </CRow>
                </CCol>
            </CRow>
            <>
                {(sheets && Object.keys(sheets[0].values).length > 0 && account) && (
                    <>
                        <CRow>
                            <CCard className="text-center w-100">
                                <CCard className="border-white w-100">
                                    <CRow className="border-white p-2 ml-3 mr-3 justify-content-end">
                                        {collapse ? (
                                            <CRow>
                                                <CLabel className="mt-1">Hide Default Values</CLabel>
                                                <FontAwesomeIcon
                                                    color="primary"
                                                    icon={faAngleUp}
                                                    style={{
                                                        width: "25",
                                                        height: "25",
                                                        cursor: "pointer",
                                                        outlineColor: "white",
                                                        color: "#00007d",
                                                    }}
                                                    onClick={() => setCollapse(!collapse)}
                                                />
                                            </CRow>
                                        ) : (
                                            <CRow>
                                                <CLabel className="mt-1">Show Default Values</CLabel>
                                                <FontAwesomeIcon
                                                    style={{
                                                        width: "25",
                                                        height: "25",
                                                        cursor: "pointer",
                                                        outlineColor: "white",
                                                        color: "#00007d",
                                                    }}
                                                    onClick={() => setCollapse(!collapse)}
                                                    icon={faAngleDown}
                                                />
                                            </CRow>
                                        )}

                                    </CRow>

                                    <CCollapse className="border-white" show={collapse}>
                                        <SkuFlattenedEdit
                                            sku={defaultValue}
                                            onChange={handleShipmentFlattenedChanges}
                                            accountNumber={account}
                                        />
                                    </CCollapse>
                                </CCard>

                                {showError ? (
                                    <CCard
                                        style={{
                                            width: "100%",
                                            margin: "auto",
                                            marginBottom: "25px",
                                            background: "#FFD2D2",
                                            color: "#D8000C",
                                            height: "100%",
                                            border: "none",
                                        }}
                                    >
                                        <CCardBody>
                                            <CCardTitle>Import Error</CCardTitle>
                                            <CCardText>{error}</CCardText>
                                        </CCardBody>
                                    </CCard>
                                ) : null}


                                    <ExcelMapper
                                        onMapChangeSku={handleMapChange}
                                        sheet={sheets[0]}
                                        map={map}
                                        objectDifferentiator="Number"
                                        mapSelectData={skuFlattedKeys}
                                    />

                            </CCard>
                        </CRow>
                    </>
                )}
                {/*<div style={{display: 'flex'}} className={'w-100 flex-row justify-content-end ml-3'}>*/}
                {/*    <CLink*/}
                {/*        className="text-center mt-auto mb-auto"*/}
                {/*        href="/stock/import_history"*/}
                {/*    >*/}
                {/*        View Import History*/}
                {/*    </CLink>*/}
                {/*</div>*/}

            </>
        </CContainer>
            <CContainer className={'mt-3 p-4 text-primary small w-100'}>
                <SearchSkuImport/>
            </CContainer>

            {confirm && (
                <ConfirmModal
                    submitting={isLoadingConfirm}
                    onClose={() => setConfirm(false)}
                    onSubmit={importSku}
                    title="Are you sure you want to start sku import?"
                    body="This action will start adding/updating sku"
                />
            )}
            {confirmReset && (
                <ConfirmModal

                    onClose={() => setConfirmReset(false)}
                    onSubmit={reset}
                    title="Are you sure you want to start over"
                    body="This action will reset the form"
                />
            )}

        </>
    );
};

export default CreateImportSku

