import CookieManager from "../services/cookieManager";

import { Filter, FilterGroup } from "../models/filter";
import { filtersToSearchy } from "../utils/filterUtils";
import {
  AddAttachment,
  QuotationCreate,
  QuotationUpdate,
  UpdateAttachment,
} from "../models/quotation";
import ClientWithErrorNotifier from "./clientWithErrorNotifier";
import { ApiResponse } from "@simplify9/simplyapiclient";
import {CheckoutAccountType} from "../models/checkout";

class CheckoutClient {
  static GetQuotation(id: any) {
    throw new Error("Method not implemented.");
  }
  apiClient: ClientWithErrorNotifier;
  BaseUrl: string;
  pageSize: number;

  constructor() {
    this.BaseUrl = `${process.env.REACT_APP_API_BASE_URL}/checkout/api`;
    //this.BaseUrl = ` http://localhost:5000/checkout/api`;

        this.pageSize = 20;
    this.apiClient = new ClientWithErrorNotifier(this.BaseUrl);
  }

  async getQuotations(filterGroup: FilterGroup, format = 1) {
    const searchyString = filtersToSearchy(filterGroup);
    const rs = await this.apiClient.getAsync(
      `/quotations?${searchyString}&format=${format}`
    );

    return rs.data;
  }

  async NotifyPayment(id: string, method: "sms" | "email" | "smsAndEmail") {
    const rs = await this.apiClient.postAsync(`/quotations/notify`, {
      quotationNumbers: [id],
      method,
    });
    return rs.succeeded;
  }

  async CreateQuotation(body: QuotationCreate): Promise<ApiResponse> {
    const rs = await this.apiClient.postAsync(`/quotations`, body);
    return rs;
  }

  async UpdateQuotation(id: string, body: QuotationUpdate) {
    const rs = await this.apiClient.postAsync(`/quotations/${id}`, body);
    return rs;
  }
  async GetAccount(accountNumber: string):Promise<CheckoutAccountType> {
    const rs = await this.apiClient.getAsync(`account/${accountNumber}`);

    return rs.data;
  }
  async UpdateAccount(accountId:string,body: CheckoutAccountType) {
    const rs = await this.apiClient.postAsync(`account/${accountId}`,body);

    return rs;
  }

  async GetProfiles(lookup: boolean = false): Promise<{ [k: string]: string }> {
    const rs = await this.apiClient.getAsync(
      `/profiles${lookup ? "?format=1" : ""}`
    );
    return rs.data;
  }

  async GetBillingAddresses(
    lookup: boolean = false
  ): Promise<{ [k: string]: string }> {
    const rs = await this.apiClient.getAsync(
      `/profiles${lookup ? "?format=1" : ""}`
    );
    return rs.data;
  }

  async GetProfile(id: string) {
    const rs = await this.apiClient.getAsync(`/profiles/${id}`);
    return rs.data;
  }
  async SendInvoice(id: string | number): Promise<boolean> {
    const rs = await this.apiClient.postAsync(
      `/payments/${id}/sendinvoice`,
      {}
    );
    return rs.succeeded;
  }

  async MarkAsPaid(
    id: string,
    on: string,
    reference: string,
    method: string
  ): Promise<boolean> {
    const rs = await this.apiClient.postAsync(`/payments/${id}/markpaid`, {
      on,
      reference,
      method,
    });
    return rs.succeeded;
  }

  async MarkAsUnPaid(id: string): Promise<boolean> {
    const rs = await this.apiClient.postAsync(`/payments/${id}/unpay`, {});
    return rs.succeeded;
  }

  async Cancel(id: string, on: string, method: string): Promise<boolean> {
    const rs = await this.apiClient.postAsync(`/quotations/${id}/cancel`, {
      date: on,
      method,
    });




    return rs.succeeded;
  }
  async Delete(id: string): Promise<boolean> {
    const rs = await this.apiClient.deleteAsync(`/quotations/${id}`, {});
    return rs.succeeded;
  }

  async IssueInvoice(
    id: string | number,
    date: string,
    dueIn: number
  ): Promise<boolean> {
    const rs = await this.apiClient.postAsync(`/payments/${id}/issueinvoice`, {
      date,
      dueIn,
    });
    return rs.succeeded;
  }

  async EditDueDate(id: string | number, date: string): Promise<boolean> {
    const rs = await this.apiClient.postAsync(`/payments/${id}/updateduedate`, {
      dueDate:date,
    });
    return rs.succeeded;
  }

  async GetQuotation(id: string) {
    const rs = await this.apiClient.getAsync(`/quotations/${id}`);
    return rs;
  }

  async GetInvoice(
    id: string,
    lang: "en" | "fr",
    type: string
  ): Promise<ApiResponse> {
    const rs = await this.apiClient.postAsync(`quotations/${id}/invoice`, {
      language: lang,
      type,
    });

    return rs;
  }

  async MarkAsProcessed(id: string, date: string) {
    const rs = await this.apiClient.postAsync(`/quotations/${id}/processed`, {
      date: date,
    });
    return rs.succeeded;
  }

  async MarkAsUnprocessed(id: string) {
    const rs = await this.apiClient.postAsync(
      `/quotations/${id}/unprocess`,
      {}
    );
    return rs.succeeded;
  }

  async AddAttachment(id: string, body: AddAttachment) {
    const rs = await this.apiClient.postAsync(
      `/quotations/${id}/addattachment`,
      body
    );

    return rs.succeeded;
  }

  async UpdateAttachment(id: string, body: UpdateAttachment) {
    const rs = await this.apiClient.postAsync(
      `/quotations/${id}/updateattachment`,
      body
    );
    return rs.succeeded;
  }

  async DeleteAttachment(id: string, attachmentId: string) {
    const rs = await this.apiClient.postAsync(
      `/quotations/${id}/deleteattachment`,
      {
        id: attachmentId,
      }
    );
    return rs.succeeded;
  }

  async UploadFile(file: any): Promise<{ location: string }> {
    let formData = new FormData();

    formData.append("File", file);

    let res: ApiResponse = await this.apiClient.postFormAsync(
      "attachments/Upload",
      formData
    );
    return res.data;
  }


  async updateshipmentnumber(id: string, shipmentNumber:string) {
    const rs = await this.apiClient.postAsync(
      `/quotations/${id}/updateshipmentnumber`,
      {shipmentNumber :shipmentNumber}
    );
    return rs.succeeded;
  }

  async getQuotationIdByShipmentUid (uid: string) {
    const rs = await this.apiClient.getAsync(`/quotations/${uid}/getbyshipment`)

    return rs.data
  }

}

export default CheckoutClient;
