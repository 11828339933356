import {CButton, CCol, CContainer, CModal, CModalBody, CRow} from "@coreui/react";
import {
    AddContainerAttachmentRequest,
    ContainerAttachmentSearch,
    UpdateContainerAttachmentRequest
} from "../../../../models/container";
import SWSelect from "../../../SharedComponents/SWSelect";
import SWInput from "../../../SharedComponents/SWInput";
import React, {useEffect, useState} from "react";
import ShipClient from "../../../../clients/shipClient";
import {useFormik} from "formik";
import {useDispatch} from "react-redux";
import {ShowNotification} from "../../../../store/actions/auth";

interface IProps {
    containerId: number
    handleClose: () => void
    handleAdd: (attachment: ContainerAttachmentSearch) => void
    handleUpdate: (attachment: ContainerAttachmentSearch) => void
    attachments: ContainerAttachmentSearch[]
    attachmentIndex: number | undefined
    fileUploaded: any
    location: string | undefined
}

const AddUpdateContainerAttachmentModal = (props: IProps) => {
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [attachmentTypes, setAttachmentTypes] = useState<{[k: string]: string}>({})
    
    const dispatch = useDispatch();
    
    const shipClient = new ShipClient();

    const {values, handleSubmit, setFieldValue, handleChange} = useFormik({
        initialValues: {
            location: undefined,
            type: undefined,
            name: undefined
        },
        onSubmit: async (values) => {
            if (isEdit) {
                if (props.attachmentIndex == undefined) return
                const attachment = props.attachments[props.attachmentIndex]
                const body: UpdateContainerAttachmentRequest = {
                    type: values.type ?? "",
                    fileName: values.name ?? ""
                }
                const rs = await shipClient.UpdateContainerAttachment(attachment.id, body)
                if (rs) {
                    props.handleUpdate(rs)
                    dispatch(ShowNotification("Success", "Attachment updated successfully"))
                }
            } else {
                const body: AddContainerAttachmentRequest = {
                    type: values.type ?? "",
                    location: props.location ?? "",
                    fileName: values.name ?? ""
                }
                const attachment = await shipClient.AddContainerAttachment(containerId, body)
                if (attachment) {

                    props.handleAdd(attachment)
                    dispatch(ShowNotification("Success", "Attachment added successfully"))
                }
            }
        }
    })

    useEffect(() => {
        shipClient.getAttachmentTypeLookups("container").then(v => setAttachmentTypes(v))
        if (props.attachmentIndex != undefined) {
            const attachment = props.attachments[props.attachmentIndex]
            setFieldValue("type", attachment.type)
            setFieldValue("name", attachment.name)
            setIsEdit(true)
        } else {
            setIsEdit(false)
        }
    }, [])
    
    const {handleClose, containerId} = props
  return (
      <CModal show onClose={handleClose} centered>
          <CModalBody>
              <CContainer className="bg-white p-4 text-primary">
                  <h5>
                      {isEdit ? "Update Attachment:" : "Add Attachment:"}
                  </h5>
                  <CRow>
                      <CCol>
                          <SWSelect label={"Type"} value={values.type} values={attachmentTypes}
                                    onChange={t => setFieldValue("type", t)}/>
                      </CCol>
                  </CRow>
                  {(isEdit || props.fileUploaded) &&
                      <CRow>
                          <CCol>
                              <SWInput value={values.name} name={"name"} label={"File Name"} onChangeFormik={handleChange}/>
                          </CCol>
                      </CRow>}
                  <CRow>
                      <CCol>
                          <CButton
                              color={"danger"}
                              variant={"outline"}
                              size={"sm"}
                              onClick={props.handleClose}>
                              Cancel
                          </CButton>
                      </CCol>
                      <CCol md={2} className={"text-right"} style={{marginRight: "5px"}}>
                          <CButton
                              color={props.fileUploaded || isEdit ? "primary" : "secondary"}
                              variant={"outline"}
                              disabled={!isEdit && !props.fileUploaded}
                              size={"sm"}
                              onClick={() => handleSubmit()}
                          >{isEdit ? "Update" : "Add"}</CButton>
                      </CCol>
                  </CRow>
              </CContainer>
          </CModalBody>
      </CModal>
  )
}

export default AddUpdateContainerAttachmentModal