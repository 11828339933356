import React, {useEffect} from 'react';
import {CAlert, CButton, CCol, CRow} from "@coreui/react";
import CIcon from '@coreui/icons-react';
import {freeSet} from "@coreui/icons";


interface IProps {
    title?: string;
    message?: string;
    error?: boolean;
    //autoDismissAfter?: number
    onClose: () => any
}

const Component = (props: IProps) => {
    const [show, setShow] = React.useState(true);

    useEffect(() => {
        if (!props.error) {
            setTimeout(() => {
                close()
            }, 5000)
        }
    }, [])

    const close = async () => {
        props.onClose();
        setShow(false);
    }

    return (
        <CAlert
            style={{
                marginLeft:"40%",
                position: "fixed",
                top: 10,
                zIndex: 9999,
                width:"20%",
                maxWidth:"80%"
            }}
            show={show}
            color={props.error ? "danger" : "success"}
        >
            <CRow>
                <CCol style={{marginTop:"auto"}}>
                    <h6><strong>{props.title}:</strong> {props.message}</h6>
                </CCol>
                <CCol md={2}>
                    <CButton size={"sm"} onClick={close} style={{cursor: "pointer"}}>
                        <CIcon content={freeSet.cilX}/>
                    </CButton>
                </CCol>
            </CRow>
        </CAlert>
    );
}


export default Component;