import ClientWithErrorNotifier from "./clientWithErrorNotifier";
import {CodStatus} from "../models/shipment";

class LegacyClient {
    BaseUrl: string;
    pageSize: number;
    apiClient: ClientWithErrorNotifier;

    constructor() {
        this.BaseUrl = `${process.env.REACT_APP_API_BASE_URL}/legacy/api`;
        this.pageSize = 20;
        this.apiClient = new ClientWithErrorNotifier(this.BaseUrl);
    }

    async GetCodStatus(uid: string, number: string) : Promise<CodStatus> {
        let rs = await this.apiClient.postAsync(`/shipments/getcod`, {
            Uid: uid,
            Number: number
        })

        return rs.data
    }

    async GetGeneratedErrors(uid: string) : Promise<string> {
        let rs = await this.apiClient.getAsync(`/shipments/${uid}/geterrors`)

        return rs.data
    }

} export default LegacyClient;