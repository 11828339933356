import {ApiResponse} from "@simplify9/simplyapiclient";
import CookieManager from "../services/cookieManager";
import store from "../store";
import {SetLoading, ShowNotification} from "../store/actions/auth";
import axios from "axios";
import ClientFactory from "./Client";
import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";

interface Options {
    ignoreError?: boolean
    headers?: { [k: string]: string }
}

class ClientWithErrorNotifier {
    isLoading: boolean;
    baseUrl: string
    pageSize: number;
    client: IClient;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl
        this.isLoading = false
        this.pageSize = 20;
        this.client = ClientFactory({baseUrl: this.baseUrl})
    }

    async getAsync(url: string, options?: Options) {
        const result = await this.client.SimplyGetAsync(url)
        store.dispatch(SetLoading(false))
        this.notifyError(result, options);
        return result;
    }

    async postAsync(url: string, body: any, options?: Options) {
        const result = await this.client.SimplyPostAsync(url, body)
        store.dispatch(SetLoading(false))
        this.notifyError(result, options);
        return result;
    }

    /* async putAsync(url: string, body: any, options?: Options) {
        const rs: ApiResponse = await this.client.SimplyPutAsync(url, body, options);
        this.notifyError(rs,options);
        return rs;
    }*/

    async deleteAsync(url: string, body: any, options?: Options) {
        const result = await this.client.SimplyDeleteAsync(url, body)
        this.notifyError(result, options);
        return result;
    }

    async postFormAsync(url: string, body: any, options?: Options) {
        const result = await this.client.SimplyPostFormAsync(url, body)
        this.notifyError(result, options);
        return result;
    }

    notifyError(rs: ApiResponse, options?: Options) {

        if (rs.succeeded || options?.ignoreError)
            return;
        switch (rs.status) {
            case 400:
                if (rs.data.SWException) {
                    store.dispatch(ShowNotification("Error", rs.data.SWException[0], true));
                } else {

                    const messageKey = Object.keys(rs.data)[0];
                    const messageValue = rs.data[messageKey][0];
                    store.dispatch(ShowNotification(messageKey, messageValue, true));
                }
                break;
            case 401:
                store.dispatch(ShowNotification("Unauthorized", "This user is not authorized to view/edit the current page", true))
                break;
            case 404:
                if (typeof rs.data === 'string' || rs.data instanceof String) {
                    store.dispatch(ShowNotification("Error", rs.data.toString(), true));
                } else {
                    store.dispatch(ShowNotification("Error", "Not found", true));
                }
                break;
            case 500:
                store.dispatch(ShowNotification("Error", "Internal server error occurred ", true));
                break;
            case 503:
                store.dispatch(ShowNotification("Error", "Service is temporarily unavailable ", true));
                break;
        }
    }

}

export default ClientWithErrorNotifier;
