import React from "react";
import {CRow, CCol} from "@coreui/react";
import SearchBar from "./components/searchBar";
import ShipmentFinalized from "./components/shipmentFinilized";
import PendingOrders from "./components/pendingOrders";
import AssignedToUsers from "./components/AssignedToUsers";

const Dashboard = () => {
    return (
        <>
            <CRow>
                <CCol md={6}>
                    <SearchBar/>
                </CCol>
            </CRow>
            <CRow>
                <CCol md={3}>
                    <PendingOrders/>
                </CCol>
                <CCol md={3}>
                    <ShipmentFinalized/>
                </CCol>
            </CRow>
            <CRow>
                <CCol md={3}>
                    <AssignedToUsers/>
                </CCol>
            </CRow>
        </>
    )
}

export default Dashboard;