import { AuthenticationAppModel } from "../../models/app";
import cookiesManager from "../../services/cookieManager";

const initialState: AuthenticationAppModel = {
  isAuthenticated: cookiesManager.getAccessToken() != null,
  showSidebar: true,
  loggingIn: false,
  user: null,
  isLoading:false,
  notification:{
    show:false,
  }
};

export default initialState;
