import {CButton, CCol, CContainer, CModal, CModalBody, CRow} from "@coreui/react"
import React, {useState} from "react"
import SWTextArea from "../../../SharedComponents/SWTextArea";
import ActionButton from "../../../SharedComponents/ActionButton";

interface IProps {
    HandleClose: () => void
    handleSubmit: (comment: string) => void
}


const SendSMSModal = (props: IProps) => {
    const [message, setMessage] = useState<string>()

    return (
        <CModal show={true} onClose={props.HandleClose} className={"ml-auto mr-auto"} centered>
            <CModalBody>
                <CContainer className="bg-white p-4 text-primary">
                    <h5>
                        Send SMS:
                    </h5>
                    <SWTextArea
                        value={message}
                        onChange={(e) => setMessage(e)}
                        rows={6}
                        maxLength = {150}
                    />
                    <CRow>
                        <CCol md={2}>
                            <CButton
                                color={"danger"}
                                variant={"outline"}
                                size={"sm"}
                                onClick={props.HandleClose}>
                                Cancel
                            </CButton>
                        </CCol>
                        <CCol/>
                        <CCol md={2} className={"text-right"} style={{marginRight: "5px"}}>
                            <ActionButton
                                text={"Send"}
                                disabled={!message}
                                onClick={() => props.handleSubmit(message ?? "")}
                            />
                        </CCol>
                    </CRow>
                </CContainer>
            </CModalBody>
        </CModal>
    )
}

export default SendSMSModal;