import {
    CBadge,
    CButton,
    CButtonGroup,
    CCol,
    CContainer,
    CDataTable,
    CInputCheckbox,
    CLink,
    CRow,
    CSpinner
} from "@coreui/react";
import NewSearchFrom from "../Shipments/components/NewSearchFrom";
import SearchResult from "../Shipments/components/SearchResult";
import DeleteShipmentsConfirmModal from "../Shipments/modals/DeleteShipmentsConfirmModal";
import RecalculateRouteModal from "../Shipments/modals/RecalculateRouteModal";
import React, {useEffect, useState} from "react";
import PageCount from "../../SharedComponents/PageCount";
import {SetShipmentPage, setShipmentSearchLimit, ToggleShipmentView} from "../../../store/actions/shipments";
import ResultDetails from "../Shipments/components/ResultDetails";
import {ShipmentSearch, ShipmentSearchRequest} from "../../../models/shipment";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {formatDate} from "../../../utils/dateUtil";
import GatewayClient from "../../../clients/gatewayClient";
import {AgentModel} from "../../../models/agents";
import { useDispatch } from "react-redux";
import { SetIsLoading } from "../../../store/actions/ui";


const Agents = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [searchResult, setSearchResult] = useState<AgentModel[]>([]);
    const gatewayClient = new GatewayClient();
    const searchAgents = async () => {
        dispatch(SetIsLoading(true));
        let agents = await gatewayClient.SearchAgents();
        setSearchResult(agents);
    }
    useEffect(() => {
        searchAgents();
    }, [])
    const scopedSlots = {
        links: (item: AgentModel) => {
            return <td>
                <CLink to={`/settings/agents/${item.id}`} className="card-header-action">
                    <FontAwesomeIcon icon={faEye}/>
                </CLink>

            </td>;
        },
        track: (item: AgentModel) => {
            let cap = item.capabilities?.find(a => a.id == "track");
            return (
                <td>{cap && !cap.disabled ? "True" : "False"}</td>
            )

        },
        create: (item: AgentModel) => {
            let cap = item.capabilities?.find(a => a.id == "create");
            return (
                <td>{cap && !cap.disabled ? "True" : "False"}</td>
            )
        },
        delete: (item: AgentModel) => {
            let cap = item.capabilities?.find(a => a.id == "delete");
            return (
                <td>{cap && !cap.disabled ? "True" : "False"}</td>
            )
        },
    }

    return (
        <>
            <CContainer className="bg-white p-4">
                {loading ? (
                    <CSpinner
                        className="mx-auto d-block my-5"
                        color="primary"
                        style={{width: "5em", height: "5em"}}
                    />
                ) : (
                    <>
                        <div className="small">
                            <CDataTable
                                size="sm"
                                hover
                                scopedSlots={scopedSlots}
                                items={searchResult}
                                fields={[
                                    {key: "links", label: "", _style: {width: '7%', padding: '0.25rem'}},
                                    {key: "id", label: "Name"},
                                    {key: "adapterPrefix", label: "Adapter Prefix"},
                                    {key: "track", label: "Track Shipment"},
                                    {key: "create", label: "Create Shipment"},
                                    {key: "delete", label: "Delete Shipment"},

                                ]}
                            />

                        </div>
                    </>


                )}


            </CContainer>

        </>
    )
}

export default Agents;
