import {CButton, CCard, CCol, CLink, CModal, CModalBody, CModalFooter, CModalHeader, CRow,} from "@coreui/react";
import React, {useState} from "react";
import {
  ActionData,
  QuotationChargeInfo,
  QuotationFieldDifference,
  QuotationHistoryItemInfo,
} from "../../../models/quotation";
import {formatDate, formatTime} from "../../../utils/dateUtil";

interface IProps {
  quotationHistory: QuotationHistoryItemInfo[];
}
const QuotationHistory = (props: IProps) => {
  const { quotationHistory } = props;

  const [actionDataModal, setActionDataModal] = useState(false);
  const [actionData, setActionData] = useState<ActionData | undefined>(
    undefined
  );

  const renderHistoryHeader = () => {
    let headerElement = [
      "Date",
      "Time",
      "Status",
      "Action",
      "Details",
      "Modified By",
    ];

    return headerElement.map((key, index) => {
      return (
        <th className="sticky-top bg-white" key={index}>
          {key}
        </th>
      );
    });
  };

  const renderActionDataHeader = () => {
    let headerElement = [
      "attachmentId",
      "attachmentName",
      "date",
      "dueIn",
      "email",
      "includeInInvoiceEmail",
      "includeInPaymentConfirmedEmail",
      "includeInRequestPaymentEmail",
      "phone",
      "attachmentId",
      "reference",
    ];

    return headerElement.map((key, index) => {
      return (
        <th className="sticky-top bg-white" key={index}>
          {key}
        </th>
      );
    });
  };

  const renderFieldsDifferencesHeader = () => {
    let headerElement = ["Field Name", "Old Value", "New Value"];

    return headerElement.map((key, index) => {
      return (
        <th className="sticky-top bg-white" key={index}>
          {key}
        </th>
      );
    });
  };

  const renderAddedHeader = () => {
    let headerElement = [
      "Type ID",
      "Name",
      "Value",
      "Quantity",
      "Tax Rate",
      "Comments",
      "Total Value",
    ];

    return headerElement.map((key, index) => {
      return (
        <th className="sticky-top bg-white" key={index}>
          {key}
        </th>
      );
    });
  };

  const renderRemovedHeader = () => {
    let headerElement = [
      "Type ID",
      "Name",
      "Value",
      "Quantity",
      "Tax Rate",
      "Comments",
      "Total Value",
    ];

    return headerElement.map((key, index) => {
      return (
        <th className="sticky-top bg-white" key={index}>
          {key}
        </th>
      );
    });
  };

  const renderAttachmentDetailsHeader = () => {
    let headerElement = [
      "Attachment Name",
      "Include In Invoice Email",
      "Include In Payment Confirmed Email",
      "Include In Request Payment Email",
    ];

    return headerElement.map((key, index) => {
      return (
        <th className="sticky-top bg-white" key={index}>
          {key}
        </th>
      );
    });
  };

  return (
    <>
      <CCard>
        <table className="position-relative table table-hover table-sm">
          <thead>{renderHistoryHeader()}</thead>
          <tbody>
            {quotationHistory?.map((v: QuotationHistoryItemInfo) => (
              <tr key={v.id}>
                <td>{formatDate(v.createdOn)}</td>
                <td>{formatTime(v.createdOn)}</td>
                <td>{v?.status} </td>
                <td>{v?.actionDescription} </td>

                <td>
                  {v?.actionData.attachmentName ||
                  v?.actionData.updateDetails ? (
                    <CLink
                      onClick={() => {
                        setActionData(v.actionData);
                        setActionDataModal(true);
                      }}
                    >
                      <strong> More Details</strong>
                    </CLink>
                  ) : (
                    <>
                      <p>{formatDate(v.actionData.date)}</p>
                      <p> {v?.actionData?.attachmentName}</p>
                      <p>{v?.actionData?.includeInInvoiceEmail}</p>
                      <p> {v?.actionData?.includeInInvoiceEmail}</p>
                      <p> {v?.actionData?.email}</p>
                      <p>{v?.actionData?.phone}</p>
                      <p>{v?.actionData?.reference}</p>
                      <p>
                        {" "}
                        {v?.actionData?.dueIn
                          ? `Due in ${v.actionData.dueIn}`
                          : ""}
                      </p>
                    </>
                  )}
                </td>
                <td>{v?.createdBy} </td>
              </tr>
            ))}
          </tbody>
        </table>
      </CCard>

      <CModal>
        <CModalBody className="mr-auto ml-auto"></CModalBody>
      </CModal>

      {/* ///// */}

      <CModal
        size="lg"
        centered
        show={actionDataModal}
        onClose={() => setActionDataModal(false)}
        className="mr-2 ml-2"
      >
        <CModalHeader>
          <h5>
            {actionData?.attachmentName
              ? "Attachment Updates"
              : "Updates on Fields/Items "}
          </h5>
        </CModalHeader>
        <CModalBody>
          {actionData?.attachmentName ? (
            <>
              <table className="position-relative table table-hover table-sm">
                <thead>{renderAttachmentDetailsHeader()}</thead>
                <tbody key={actionData.attachmentId}>
                  <td>{actionData?.attachmentName}</td>
                  <td>{actionData?.includeInInvoiceEmail ? "Yes" : "No"} </td>
                  <td>
                    {actionData?.includeInPaymentConfirmedEmail ? "Yes" : "No"}
                  </td>
                  <td>
                    {actionData?.includeInRequestPaymentEmail ? "Yes" : "No"}
                  </td>
                </tbody>
              </table>
              {/* <CCol>
                <CRow>
                <CLabel>Attachment Id:</CLabel>
                <strong> {actionData?.attachmentId} </strong>
              </CRow>
                <CRow>
                  <CLabel>Attachment Name:</CLabel>
                  <strong> {actionData?.attachmentName} </strong>
                </CRow>
                <CRow>
                  <CLabel>Include In Invoice Email: </CLabel>
                  <strong>
                    {actionData?.includeInInvoiceEmail ? "Yes" : "No"}{" "}
                  </strong>
                </CRow>
                <CRow>
                  <CLabel>Include In Payment Confirmed Email:</CLabel>
                  <strong>
                    {actionData?.includeInPaymentConfirmedEmail ? "Yes" : "No"}
                  </strong>
                </CRow>
                <CRow>
                  <CLabel>Include In Request Payment Email:</CLabel>
                  <strong>
                    {actionData?.includeInRequestPaymentEmail ? "Yes" : "No"}
                  </strong>
                </CRow>
              </CCol> */}
            </>
          ) : (
            <>
              {actionData?.updateDetails?.fieldsDifferences &&
                actionData?.updateDetails?.fieldsDifferences.length > 0 && (
                  <>
                    <CModalHeader>
                      <h5>
                        <strong> Field Updates </strong>{" "}
                      </h5>
                    </CModalHeader>
                    <table className="position-relative table table-hover table-sm">
                      <thead>{renderFieldsDifferencesHeader()}</thead>
                      <tbody>
                        {actionData?.updateDetails?.fieldsDifferences?.map(
                          (f: QuotationFieldDifference) => (
                            <tr key={f.id}>
                              <td> {f?.filedName}</td>
                              <td> {f?.oldValue}</td>
                              <td> {f?.newValue}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                    <br />
                  </>
                )}
              <table className="position-relative table table-hover table-sm">
                <thead>{renderAddedHeader()}</thead>
                <h6>Added records:</h6>
                <tbody>
                  {actionData?.updateDetails?.updatedOrAdded?.map(
                    (f: QuotationChargeInfo) => (
                      <tr key={f.typeId}>
                        <td>
                          <strong> {f?.typeId} </strong>
                        </td>
                        <td>
                          <strong> {f?.name} </strong>
                        </td>
                        <td>
                          <strong> {f?.value} </strong>
                        </td>
                        <td>
                          <strong> {f?.quantity} </strong>
                        </td>
                        <td>
                          <strong> {f?.taxRate} </strong>
                        </td>
                        <td>
                          <strong> {f?.comments} </strong>
                        </td>
                        <td>
                          <strong> {f?.totalValue} </strong>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>

              <table className="position-relative table table-hover table-sm">
                <h6>Removed records:</h6>
                <thead>{renderRemovedHeader()}</thead>

                {actionData?.updateDetails?.updatedOrRemoved?.map((f) => (
                  <tr key={f.typeId}>
                    <td>
                      <strong> {f?.typeId} </strong>
                    </td>
                    <td>
                      <strong> {f?.name} </strong>
                    </td>
                    <td>
                      <strong> {f?.value} </strong>
                    </td>
                    <td>
                      <strong> {f?.quantity} </strong>
                    </td>
                    <td>
                      <strong> {f?.taxRate} </strong>
                    </td>

                    <td>
                      <strong> {f?.comments} </strong>
                    </td>
                    <td>
                      <strong> {f?.totalValue} </strong>
                    </td>
                  </tr>
                ))}
              </table>
            </>
          )}
        </CModalBody>
        <CModalFooter>
          <CRow className="justify-content-between w-100">
            <CCol className="text-right">
              <CButton
                style={{ fontSize: "10px" }}
                variant="outline"
                color="danger"
                onClick={() => setActionDataModal(false)}
              >
                Close
              </CButton>
            </CCol>
          </CRow>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default QuotationHistory;
