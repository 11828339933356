import { freeSet } from "@coreui/icons";
import CIcon from "@coreui/icons-react";

import { CBadge, CCardBody, CCol, CInputCheckbox, CLabel, CLink, CRow } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { ShipmentSearch, BookingStatus } from "../../../models/shipment";

interface IProps {
    shipment: ShipmentSearch;
    onEditClick: () => any;
    quotationNumber?: string
}

const ViewService = (props: IProps) => {
    const { shipment } = props;
    const [isHover, setisHover] = useState(false);

    return (
        <>
            <CCardBody
                className={` serviceCard mb-1 rounded  ${isHover ? "bg-secondary" : ""
                    }`}
                onMouseOver={() => {
                    setisHover(true);
                }}
                onMouseLeave={() => {
                    setisHover(false);
                }}
            >
                <CRow>
                    <CCol>
                        <h6>
                            <strong>Agent:</strong>
                        </h6>
                    </CCol>
                    <CCol>
                        <h6>
                            <strong>
                                {shipment.bookings &&
                                    shipment.bookings.length > 0 &&
                                    shipment.bookings[0].agentName}
                            </strong>
                        </h6>
                    </CCol>
                    <CCol className="text-right">
                        <CIcon
                            style={{ cursor: "pointer" }}
                            className={`${isHover ? "primary" : "text-white"}`}
                            content={freeSet.cilPencil}
                            size={"lg"}
                            onClick={props.onEditClick}
                        />
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>Service:</CCol>
                    <CCol sm={8}>
                        <strong>
                            {shipment.bookings &&
                                shipment.bookings.length > 0 &&
                                shipment.bookings[0].service}
                        </strong>
                    </CCol>
                </CRow>
                {/* <CRow>
                    <CCol>Remark:</CCol>
                    <CCol sm={8}>
                        <strong>

                            {shipment.bookings &&
                                shipment.bookings.length > 0 &&
                                BookingStatus[shipment.bookings[0].status]}
                        </strong>
                    </CCol>
                </CRow> */}
                 {shipment?.flags?.includes("Insurance") && 
                <CRow>
                    <CCol>Insurance:</CCol>
                    <CCol sm={8}>
                        <CBadge color="primary">Yes</CBadge>
                    </CCol>
                </CRow>}
                {shipment?.flags?.includes("Weekend1") && 
                <CRow>
                    <CCol>Saturday Delivery:</CCol>
                    <CCol sm={8}>
                        <CBadge color="primary">Yes</CBadge>
                    </CCol>
                </CRow>}
                {shipment?.flags?.includes("Pickup") && 
                <CRow>
                    <CCol>Pickup Requested:</CCol>
                    <CCol sm={8}>
                        <CBadge color="primary">Yes</CBadge>
                    </CCol>
                </CRow>}
                {shipment?.flags?.includes("IdCheck") && 
                <CRow>
                    <CCol>Id Check:</CCol>
                    <CCol sm={8}>
                        <CBadge color="primary">Yes</CBadge>
                    </CCol>
                </CRow>}
                <CRow>
                    <CCol>Created By:</CCol>
                    <CCol sm={8}>
                            {shipment.createdBy}
                    </CCol>
                </CRow>
                {props.quotationNumber &&
                    <CRow>
                        <CCol>Quotation</CCol>
                        <CCol sm={8}>
                            <CLink to={`/quotations/${encodeURI(props.quotationNumber)}`}>
                                {props.quotationNumber}
                            </CLink>
                        </CCol>
                    </CRow>}
                <CRow>
                    <br />
                    {/* <CCol>Alerted by:</CCol>
          <CCol sm={8}>
            <strong> {shipment.shipperName} </strong>
          </CCol> */}
                </CRow>
            </CCardBody>
        </>
    );
};

export default ViewService;
