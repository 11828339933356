import {CButton, CCol, CModal, CModalBody, CModalFooter, CModalHeader, CRow} from "@coreui/react";
import {IssueInvoiceRequest} from "../../../../models/accounting";
import React, {useState} from "react";
import SWInputNumber from "../../../SharedComponents/SWInputNumber";
import ActionButton from "../../../SharedComponents/ActionButton";
import SWDateAndTimePicker from "../../../SharedComponents/SWDateAndTimePicker";
import SWInput from "../../../SharedComponents/SWInput";

interface IProps {
    onClose: () => void
    onSubmit: (body: IssueInvoiceRequest) => void
    defaultDueDays?: number
    defaultInvoiceDate: string
    submitting: boolean
}

const IssueInvoiceModal = (props: IProps) => {
    const {onClose, onSubmit, defaultDueDays, defaultInvoiceDate, submitting} = props
    const [dueDays, setDueDays] = useState<number>(defaultDueDays ?? 0)
    const [invoiceDate, setInvoiceDate] = useState(defaultInvoiceDate)
    const [alternativeInvoiceNumber, setAlternativeInvoiceNumber] = useState<string>()
    return (
        <CModal show={true} onClose={onClose} centered>
            <CModalHeader>Issue Invoice</CModalHeader>
            <CModalBody>
                <CRow>
                    <CCol>
                        <SWDateAndTimePicker label={"Invoice Date"} value={invoiceDate} handleOnChange={v => setInvoiceDate(v)}/>
                    </CCol>
                    <CCol>
                        <SWInputNumber value={dueDays} onChange={v => setDueDays(v ?? 0)} label={"Due Days"} />
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>
                        <SWInput
                            label={"Alternative Invoice Number"}
                            value={alternativeInvoiceNumber}
                            onChange={(v) => setAlternativeInvoiceNumber(v)}
                        />
                    </CCol>
                </CRow>
            </CModalBody>
            <CModalFooter>
                <CRow className="justify-content-between w-100">
                    <CCol className="text-left">
                        <CButton
                            style={{fontSize: "10px"}}
                            variant="outline"
                            color="danger"
                            onClick={onClose}
                        >
                            Cancel
                        </CButton>
                    </CCol>
                    <CCol className="text-right">
                        <ActionButton
                            text={submitting ? "Loading..." : "Yes"}
                            disabled={submitting}
                            onClick={() => onSubmit({
                                alternativeInvoiceNumber : alternativeInvoiceNumber,
                                invoiceDate: invoiceDate,
                                dueDays: dueDays
                            })}
                        />
                    </CCol>
                </CRow>
            </CModalFooter>
        </CModal>
    )
}
export default IssueInvoiceModal