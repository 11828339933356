import {CDataTable} from "@coreui/react";
import {ChargeDto, InvoiceDto} from "../../../../models/accounting";

interface IProps {
    charges?: ChargeDto[]
    invoice?: InvoiceDto
}
const InvoiceSummeryChargesTable = (props: IProps) => {
    const value = (props.invoice?.taxableValue ?? 0) + (props.invoice?.taxLiquidatedValue ?? 0) + (props.invoice?.nonTaxableValue ?? 0)
    const scopedSlots = {
        taxableValue:(item: ChargeDto) => {
            return <td className={"text-right"}>{item.taxCode == "Y" ? item.value.toFixed(2) : 0}</td>
        },
        taxLiquidatedValue:(item: ChargeDto) => {
            return <td className={"text-right"}>{item.taxCode == "E" ? item.value.toFixed(2) : 0}</td>
        },
        nonTaxableValue:(item: ChargeDto) => {
            return <td className={"text-right"}>{item.taxCode != "Y" && item.taxCode != "E" ? item.value.toFixed(2) : 0}</td>
        },
        taxValue:(item: ChargeDto) => {
            return <td className={"text-right"}>{item.taxValue.toFixed(2)}</td>
        },
        taxCode: (item: ChargeDto) => {
            return <td className={"text-center"}>{item.taxCode}</td>
        },
        totalValue:(item: ChargeDto) => {
            return <td className={"text-right"}>{(item.value + item.taxValue).toFixed(2)}</td>
        },
        comments: (item: ChargeDto) => {
            return <td>{item.comments ?? ""}</td>
        }
    }

  return <>
      <CDataTable
          hover
          scopedSlots={scopedSlots}
          items={props.charges}
          size={"sm"}
          fields={[
              {key: "typeId", label: "Charge Code"},
              {key: "name", label: "Charge"},
              "comments",
              {key: "taxableValue", label: (
                      <div style={{ textAlign: "center" }}>
                          <div style={{ marginBottom: "4px" }}>Taxable Amount</div>
                          <div>{props.invoice?.taxableValue?.toFixed(2)}</div>
                      </div>
                  ), _style: {textAlign: "center"}},
              {
                  key: "taxLiquidatedValue",
                  label: (
                      <div style={{ textAlign: "center" }}>
                          <div style={{ marginBottom: "4px" }}>Tax Liquidated Amount</div>
                          <div>{props.invoice?.taxLiquidatedValue?.toFixed(2)}</div>
                      </div>
                  ),
                  _style: { textAlign: "center" }
              },
              {
                  key: "nonTaxableValue",
                  label: (
                      <div style={{ textAlign: "center" }}>
                          <div style={{ marginBottom: "4px" }}>Non Taxable Amount</div>
                          <div>{props.invoice?.nonTaxableValue?.toFixed(2)}</div>
                      </div>
                  ),
                  _style: { textAlign: "center" }
              },
              {
                  key: "taxValue",
                  label: (
                      <div style={{ textAlign: "center" }}>
                          <div style={{ marginBottom: "4px" }}>Tax Amount</div>
                          <div>{props.invoice?.taxValue?.toFixed(2) ?? 0}</div>
                      </div>
                  ),
                  _style: { textAlign: "center" }
              },
              {
                  key: "totalValue",
                  label: (
                      <div style={{ textAlign: "center", color: "blue" }}>
                          <div style={{ marginBottom: "4px" }}>Total With Tax</div>
                          <div>{(value + (props.invoice?.taxValue ?? 0)).toFixed(2)}</div>
                      </div>
                  ),
                  _style: { textAlign: "center" }
              }
          ]}
      />
  </>
}

export default InvoiceSummeryChargesTable;
