
import * as yup from "yup"

export interface SearchFilter {
    number: string,
    accountReference: string
};

export const SearchFilterInitialValues = () => {
    return {
        number: "",
        accountReference: "",
    };
};

export const SearchyRequestInitialValues = () => {
    return {
        conditions: [],
        sorts: [],
        pageSize: 20,
        pageIndex: 0,
        format: 0,
        countRows: true
    }
}

export const searchFilterValidationSchema = () => yup.object({
    number: yup.string().test('is-required', 'Hawb or Reference is required', function (value) {
        return value || this.parent.accountReference;
    }),
    accountReference: yup.string().test('is-required', 'Hawb or Reference is required', function (value) {
        return value || this.parent.number;
    }),
});
