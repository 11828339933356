import {CCol, CContainer, CDataTable, CTooltip} from "@coreui/react";
import React, {useEffect, useState} from "react";
import ShipClient from "../../../../clients/shipClient";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import {downloadBlob} from "../../../../utils/downloadUtils";
import { formatDate } from "../../../../utils/dateUtil";
import PageCount from "../../../SharedComponents/PageCount";
import {useHistory, useLocation} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {querytoMap} from "../../../../utils/filterUtils";
import {IPagination} from "../../../../models/filter";
import {SkuSearchFilter} from "../../../../models/warehouse";
import {SetLoading} from "../../../../store/actions/auth";
import { AppModel } from "../../../../models/app";

const GeneratedCodTable = () => {
    const shipClient = new ShipClient();
    const isLoading = useSelector((state: AppModel) => state.app.isLoading);
    let {search} = useLocation();

    const loc = useLocation();
    const hist = useHistory();
    const [limit, setLimit] = useState<number>(20)

    const dispatch = useDispatch();

    const [pagination, setPagination] = useState<IPagination>({
        limit: 20,
        offset: 0,
    });

    const [pendingCod, setPendingCod] = useState<{matches: any[], total: number}>({matches: [], total: 0})

    useEffect(()=>{
        refreshGeneratedCOD()
    }, [search])

    const refreshGeneratedCOD = async () => {
        const map = querytoMap<SkuSearchFilter>(search)
        setPagination((state) => ({
            offset: map.offset || state.offset,
            limit: map.limit || state.limit,
        }));

        await handleSearch(map);
    }

    const handleSearch = async (filter: SkuSearchFilter) => {
        dispatch(SetLoading(true));

        const res = await shipClient.getGeneratedCod(filter);

        setPendingCod({matches: res.matches, total: res.total});

        dispatch(SetLoading(false));
    };

    const scopedSlots = {
        documentNumber: (item: any)=> {
            return <td className={"text-start"}>{item.documentNumber ?? ""}</td>
        },
        documentDate: (item: any)=> {
            return <td className={"text-center"}>{formatDate(item.documentDate) ?? ""}</td>
        },
        exportToExel: (item:any)=> {
            return <td className={"text-center"}>
                <CTooltip content="Export to Exel">
                    <CIcon
                        content={freeSet.cilCloudDownload}
                        onClick={() => shipClient.getGeneratedReport(item.documentNumber)
                            .then((res:any)=>downloadBlob(res.location, res.name))}

                    />

                </CTooltip>
            </td>
        }
    }

    return(
        <>
            <CContainer >
                <CDataTable
                   loading={isLoading}
                    size="sm"
                    hover
                    scopedSlots={scopedSlots}
                    items={pendingCod.matches}
                    fields={[
                        {key: "exportToExel", label: "", _style: {textAlign: "center", width: '6%' ,padding: '0.25rem' }},
                        {key: "documentNumber", label: "Document Number"},
                        {key: "account", label: "Company Name"},
                        {key: "documentDate", label: "Document Date",_style: {textAlign: "center"}},
                    ]}
                />
                <CCol>
                    <PageCount
                        onPageChange={(p) => {
                            const modifiedSearch = new URLSearchParams(loc.search);
                            modifiedSearch.delete("offset");
                            hist.push(
                                `${loc.pathname}?${modifiedSearch.toString()}&offset=${
                                    p * limit!
                                }`
                            );
                        }}
                        onPageSizeChange={(ps) => {
                            const modifiedSearch = new URLSearchParams(loc.search);
                            modifiedSearch.delete("limit");
                            hist.push(
                                `${loc.pathname}?${modifiedSearch.toString()}&limit=${ps}`
                            );
                            setLimit(ps)
                        }}
                        currentPage={pagination.offset! /limit!}
                        maxPage={Math.ceil(
                            pendingCod.total! / limit!
                        )}
                        maxFound={pendingCod.total}
                    />
                </CCol>

            </CContainer>
        </>
    )
}

export default GeneratedCodTable