import {CCol, CContainer, CRow,} from "@coreui/react";
import WarehousesClient from "../../../../../clients/warehousesClient";
import WarehouseClient from "../../../../../clients/warehousesClient";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    getAllocationStrategyLoookup,
    getAllocationTypeLoookup,
    getUnitLoookup,
    getWeightUnitLoookup
} from "../../../../../utils/lookupUtils";
import SWInputNumber from "../../../../SharedComponents/SWInputNumber";
import {warehouse} from "../../../../../models/warehouse";
import SWMultiSelect from "../../../../SharedComponents/SWMultiSelect";
import SWSelect from "../../../../SharedComponents/SWSelect";
import {SkuFlattened} from "../../../../../models/sku";


interface Props {
    sku: SkuFlattened
    onChange: (name: keyof SkuFlattened, value: any) => void
    accountNumber: string
}


const SkuFlattenedEdit = (props: Props) => {
    const {sku, onChange, accountNumber} = props;
    const [allowedSellerCodes, setAllowedSellerCodes] = useState<string[]>()
    const [sellersLookup, setSellersLookup] = useState<{ [k: string]: string }>({});
    const warehouseClient = new WarehousesClient();
    const [allowedWarehouseIds, setAllowedWarehouseIds] = useState<number[]>()
    const [warehouseLookup, setWarehouseLookup] = useState<{
        [k: string]: string;
    }>({});

    const sellersLookups = async () => {

        const sellers = await warehouseClient.SellersLookup();
        setSellersLookup({null: " None", ...sellers});
    };

    const HandleAccountChange = async () => {
        onChange("seller", "")
        onChange("allowedWarehouses", "")
        let client = new WarehouseClient
        if (accountNumber != undefined) {
            let account = await client.GetAccount(accountNumber);
            if (account) {


                let sellerCode = account?.defaultSellerCode;
                if (sellerCode) {
                    onChange("seller", sellerCode)
                }
                setAllowedSellerCodes(account?.allowedSellers ?? [])
                setAllowedWarehouseIds(account?.allowedWarehouses ?? [])
            }
        }
    }
    const SetAllowedSellers = async () => {
        let client = new WarehouseClient()
        let sellers: { [K: string]: string } = await client.SellersLookup()
        let lookup: { [K: string]: string } = {}
        allowedSellerCodes?.forEach(s => {
            lookup[s] = sellers[s]
        })
        if (!allowedSellerCodes)
            lookup = sellers
        setSellersLookup(lookup)
    }

    const SetAllowedWarehouses = async () => {
        let warehouses: warehouse[] = await warehouseClient.searchWarehouse()
        let allowed: warehouse[]
        if (!allowedWarehouseIds) {
            allowed = warehouses
        } else {
            allowed = warehouses.filter(w => allowedWarehouseIds?.indexOf(w.id) !== -1)
        }
        let lookup: { [K: string]: string } = {}
        allowed.map(a => lookup[a.code] = a.name)
        setWarehouseLookup(lookup)
        if (!allowedWarehouseIds) return
        let selected: { label: string, value: string }[] = []
        allowed.forEach(a => selected.push({label: a.name, value: a.code}))
        onChange('allowedWarehouses', changeArrayToString(selected))
    }


    const changeArrayToString = (v: { value: string, label: string }[]) => {
        let str = ''
        v.forEach((v, index) => {
            if (index === 0)
                str += `${v.value}`
            else str += `,${v.value}`


        })
        return str
    }
    const selectDataLookup = useCallback((fun: { [k: string]: string }) => {
        if (fun)
            return Object.keys(fun).map((v) => {
                return {label: fun[v], value: fun[v]}
            })
        else return []

    }, [])
    const changeStringToArray = (str: string | undefined) => {
        if (str)
            return str?.split(',').map((v) => {
                return {
                    label: warehouseLookup[v],
                    value: v
                }
            })
        else return []
    }

    useEffect(() => {
        sellersLookups().then();
    }, []);
    useEffect(() => {
        SetAllowedSellers().then()
    }, [allowedSellerCodes])
    useEffect(() => {
        HandleAccountChange().then()
    }, [accountNumber]);
    useEffect(() => {
        SetAllowedWarehouses().then()
    }, [allowedWarehouseIds])
    useEffect(() => {
    }, [sku.allowedWarehouses])
    return (<>
        <CContainer className={'text-left'}>
            <CRow>

                <CRow className={'col-md-12'}>
                    <CCol md={3}>
                        <SWMultiSelect
                            value={changeStringToArray(sku.allowedWarehouses)}
                            className="basic-multi-select"
                            label={"Allowed Warehouses"}
                            values={warehouseLookup}
                            name="allowedWarehouseCodes"
                            onChange={(e: { value: string, label: string }[]) => {
                                const str = changeArrayToString(e)
                                onChange('allowedWarehouses', str)

                            }}

                        />
                    </CCol>

                    <CCol md={3}>
                        <SWSelect
                            label={"Seller"}
                            values={sellersLookup as { [k: string]: string }}
                            value={sku.seller}
                            name={"seller"}
                            isClearable={true}
                            onChange={(e) => onChange("seller", e)}
                        />
                    </CCol>

                    <CCol md={3}>
                        <SWInputNumber
                            label={"Expiry Days"}
                            name={"expiryDays"}
                            value={sku.minimumExpiryDays ?? undefined}
                            onChangeFormik={(e) => onChange('minimumExpiryDays', e.target.value)}
                        />
                    </CCol>
                </CRow>


                <CRow className={"col-md-12 "}>
                    <CCol md={3}>
                        <SWSelect

                            label={"Allocation Strategy"}
                            values={
                                selectDataLookup(getAllocationStrategyLoookup())}
                            value={sku.allocationStrategy}
                            name={"allocationStrategy"}
                            onChange={(e) => onChange("allocationStrategy", e)}
                        />
                    </CCol>
                    <CCol md={3}>
                        <SWSelect
                            label={"Allocation Type"}
                            values={selectDataLookup(getAllocationTypeLoookup())}
                            value={sku.allocationType?.toString()}
                            name={"allocationType"}
                            onChange={(e) => onChange("allocationType", e)}
                        />
                    </CCol>

                    <CCol md={3}>
                        <SWInputNumber
                            label={"Break Count"}
                            name={"breakCount"}
                            onChangeFormik={(e) => onChange("breakCount", e as number)}
                            value={sku.breakCount ?? undefined}
                        />
                    </CCol>
                    <CCol md={3}>
                        <SWInputNumber
                            label={"Minimum Reorder"}
                            name={"minReorder"}
                            onChangeFormik={(e) => onChange('minReorder', e)}
                            value={sku.minReorder}

                        />
                    </CCol>
                </CRow>

                <CRow className={"col-md-12 m-0"}>
                    <CCol md={6} className={"h6 text-left p-0"}>
                        Weight
                    </CCol>
                    <CCol md={6} className={"h6 text-left p-0"}>
                        Dimensions
                    </CCol>
                </CRow>
                <CRow className={"col-md-12 "}>
                    <CCol md={3}>
                        <SWInputNumber
                            name={"weightValue"}
                            value={sku?.weightValue ?? undefined}
                            onChangeFormik={(e) => onChange('weightValue', e.target.value)}
                        />
                    </CCol>
                    <CCol md={3}>
                        <SWSelect
                            values={selectDataLookup(getWeightUnitLoookup())}
                            value={sku?.weightUnit}
                            name={"weight.unit"}
                            onChange={(e) => onChange("weightUnit", e)}
                        />
                    </CCol>

                    <CCol>
                        <SWInputNumber

                            name={"dimensions.width"}
                            value={sku.width ?? undefined}
                            onChangeFormik={(e) => onChange("width", e.target.value)}
                        />
                    </CCol>

                    <CCol>
                        <SWInputNumber
                            name={"dimensions.height"}
                            value={sku?.height ?? undefined}
                            onChangeFormik={(e) => onChange("height", e.target.value)}
                        />
                    </CCol>
                    <CCol>
                        <SWInputNumber
                            name={"dimensions.length"}
                            value={sku?.length ?? undefined}
                            onChangeFormik={(e) => onChange("length", e.target.value)}
                        />
                    </CCol>
                    <CCol>
                        <SWSelect
                            // label={"Unit"}
                            values={selectDataLookup(getUnitLoookup())}
                            value={sku.dimensionUnit}
                            name={"dimensions.unit"}
                            onChange={(e) => onChange("dimensionUnit", e)}
                        />
                    </CCol>
                </CRow>


            </CRow>
        </CContainer>
    </>);
}

export default SkuFlattenedEdit;
