import { CTooltip } from '@coreui/react';
import React from 'react';

type Props = {
    color?: string;
    onClick: () => void;
    tabIndex?: number
    tooltipText: string;
    style?:React.CSSProperties;
}


const CameraIcon = ({ color, onClick, tabIndex, tooltipText,style}: Props) => {
    return (
        <CTooltip content={tooltipText}>
            <svg
               style={{ ...style,cursor: 'pointer' }}
                width={"25"}
                height={"25"}
                onClick={onClick}
                tabIndex={tabIndex}
                viewBox="0 -2 32 32"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"><g id="SVGRepo_bgCarrier" strokeWidth="0">
                </g>
                <g id="SVGRepo_tracerCarrier"
                    strokeLinecap="round" strokeLinejoin="round">
                </g><g id="SVGRepo_iconCarrier">
                    <title>camera</title> <desc>Created with Sketch Beta.</desc>
                    <defs> </defs> <g id="Page-1" stroke="none" strokeWidth="1" fill="none"
                        fillRule="evenodd"

                    >
                        <g id="Icon-Set-Filled"
                            transform="translate(-258.000000, -467.000000)"
                            fill="#000000">
                            <path d="M286,471 L283,471 L282,469 C281.411,467.837 281.104,467 280,467 L268,467 C266.896,467 266.53,467.954 266,469 L265,471 L262,471 C259.791,471 258,472.791 258,475 L258,491 C258,493.209 259.791,495 262,495 L286,495 C288.209,495 290,493.209 290,491 L290,475 C290,472.791 288.209,471 286,471 Z M274,491 C269.582,491 266,487.418 266,483 C266,478.582 269.582,475 274,475 C278.418,475 282,478.582 282,483 C282,487.418 278.418,491 274,491 Z M274,477 C270.687,477 268,479.687 268,483 C268,486.313 270.687,489 274,489 C277.313,489 280,486.313 280,483 C280,479.687 277.313,477 274,477 L274,477 Z" id="camera">
                            </path> </g> </g> </g></svg>
        </CTooltip>
    );

}
export default CameraIcon;