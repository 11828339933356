import {CCol, CContainer, CDataTable, CInputCheckbox, CRow} from "@coreui/react";
import React, {useEffect, useState} from "react";
import {Fields, shipDefaultAccountResponse} from "../../../../models/accounting";
import ShipClient from "../../../../clients/shipClient";
import {ShowNotification} from "../../../../store/actions/auth";
import {useDispatch} from "react-redux";
import ActionButton from "../../../SharedComponents/ActionButton";
import SWInput from "../../../SharedComponents/SWInput";
import SWSelect from "../../../SharedComponents/SWSelect";
import {fi} from "date-fns/locale";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import {PendingPrinting, shipmentDropDownData, shipmentFlattenedKeys} from "../../../../models/shipment";

export interface Iprops {
    accountData: shipDefaultAccountResponse;
    setAccountData: React.Dispatch<React.SetStateAction<shipDefaultAccountResponse | undefined>>;
    accountId: string
    isBookingData: boolean;

}

export interface DropDownData {
    label: string;
    value: string;
}

const ViewShipData = (props: Iprops) => {
    const dispatch = useDispatch();

    const [showSaveChangesButton, setShowSaveChangesButton] = useState<boolean>(false);
    const [editData, setEditData] = useState<Fields[] | null>();
    const shipClient = new ShipClient();

    useEffect(() => {
        if (props.isBookingData) {
            if (props.accountData?.bookingData?.fields) {
                setEditData(props.accountData.bookingData.fields);
            }
        } else {
            if (props.accountData?.shipmentImportData?.fields) {
                setEditData(props.accountData.shipmentImportData.fields);

            }
        }
    }, [props.accountData]);

    const NameValues: DropDownData[] = [
        {label: "Agent", value: "Agent"},
        {label: "Shipper.CompanyName", value: "Shipper.CompanyName"},
        {label: "Shipper.Name", value: "Shipper.Name"},
        {label: "Shipper.AddressLine1", value: "Shipper.AddressLine1"},
        {label: "Shipper.AddressLine2", value: "Shipper.AddressLine2"},
        {label: "Shipper.City", value: "Shipper.City"},
        {label: "Shipper.PostCode", value: "Shipper.PostCode"},
        {label: "Shipper.Country", value: "Shipper.Country"},
        {label: "Shipper.Phone", value: "Shipper.Phone"},
        {label: "Shipper.SecondPhone", value: "Shipper.SecondPhone"},
        {label: "Shipper.Email", value: "Shipper.Email"},
        {label: "Consignee.Email", value: "Consignee.Email"},
        {label: "ServiceCode", value: "ServiceCode"},
        {label: "Currency", value: "Currency"},
        {label: "Quotation Profile ID", value: "Qutation_ProfileId"},
        {label: "Quotation Scenario ID", value: "Qutation_ScenarioId"},
        {label: "Weight Unit", value: "Weight_Unit"},
        {label: "Allow Duplicate Order No", value: "AllowDuplicateOrderNo"},
        {label: "Good Description", value: "GOOD_Description"},
        {label: "Good Dutiable", value: "GOOD_Dutiable"},
        {label: "Additional SKU", value: "AdditionalSku"}


    ];

    const TypeValues: DropDownData[] = [{label: 'Default Value', value: 'DefaultValue'}, {label: 'Map', value: 'Map'},]

    const addNewField = () => {
        const newField: Fields = {
            name: "",
            value: "",
            type: "",
            force: false,
            removeLeadingZeros: false,
            position: 0
        };
        setEditData((prevData) => (prevData ? [...prevData, newField] : [newField]));
    };
    const handleUpdate = async () => {
        let rs = null;
        if (props.isBookingData) {
            rs = await shipClient.UpdateAccountDefault(props.accountId, {
                ...props.accountData,
                bookingData: {
                    ...props.accountData.bookingData,
                    fields: editData ? editData : props.accountData.bookingData.fields
                },

            });
        } else {
            rs = await shipClient.UpdateAccountDefault(props.accountId, {
                ...props.accountData,
                shipmentImportData: {
                    ...props.accountData.shipmentImportData,
                    fields: editData ? editData : props.accountData.shipmentImportData.fields
                },

            });
        }
        if (rs?.succeeded) {
            dispatch(ShowNotification("Success", "Updated!"));
        } else {
            dispatch(ShowNotification("Error", JSON.stringify(rs?.data.SWException).toString(), true));

        }

    }

    const scopedSlots = {
        name: (item: any) => {
            return <td><SWSelect style={{width: "50%"}} values={props.isBookingData ? NameValues : shipmentDropDownData}
                                 value={item.name}
                                 isFocus={true}
                                 onChange={(e) => {
                                     editData?.map((field, index) => {
                                             setEditData(prevData =>
                                                 prevData?.map((item, idx) =>
                                                     idx === index ? {...item, name: e ? e : item.name} : item
                                                 )
                                             )
                                         }
                                     );

                                     setShowSaveChangesButton(true)

                                 }}
            /></td>
        },
        value: (item: any) => {
            return <td><SWInput style={{width: "50%"}} value={item.value}
                                onChange={(e) => {
                                    editData?.map((field, index) => {
                                            setEditData(prevData =>
                                                prevData?.map((item, idx) =>
                                                    idx === index ? {...item, value: e ? e : item.value} : item
                                                )
                                            )
                                        }
                                    );

                                    setShowSaveChangesButton(true)

                                }}/></td>
        },
        force: (item: any) => {
            return <td><CInputCheckbox checked={item.force} className={'ml-2'} onChange={(e) => {

                editData?.map((field, index) => {
                        setEditData(prevData =>
                            prevData?.map((item, idx) =>
                                idx === index ? {...item, force: !item.force} : item
                            )
                        )
                    }
                );
                setShowSaveChangesButton(true)

            }}/></td>
        }
    };

    return (
        <>
            <CRow className={'ml-2'}>
                <CCol md={10} className={''}>
                    <h4>
                        {props.isBookingData ? "Booking Defaults" : "File Import Settings"}
                    </h4>
                    {props.isBookingData ? <CRow className={'d-flex justify-content-around'}>
                        <SWInput style={{width: "15%"}} label={"Qutation profile id"}
                                 value={props.accountData?.bookingData?.quotationProfileId?.toString()}
                                 onChange={(e) => {
                                     editData?.map((field, index) => {
                                             setEditData(prevData =>
                                                 prevData?.map((item, idx) =>
                                                     idx === index ? {...item, value: e ? e : item.value} : item
                                                 )
                                             )
                                         }
                                     );

                                     setShowSaveChangesButton(true)

                                 }}/>

                        <SWInput style={{width: "15%"}} label={"Quotation scenario id"}
                                 value={props.accountData?.bookingData?.quotationScenarioId?.toString()}
                                 onChange={(e) => {
                                     editData?.map((field, index) => {
                                             setEditData(prevData =>
                                                 prevData?.map((item, idx) =>
                                                     idx === index ? {...item, value: e ? e : item.value} : item
                                                 )
                                             )
                                         }
                                     );

                                     setShowSaveChangesButton(true)

                                 }}/>
                    </CRow> : <CRow className={'d-flex justify-content-around'}>
                        <SWInput style={{width: "15%"}} label={"Field delimited"}
                                 value={props.accountData?.shipmentImportData?.fieldDelimited?.toString()}
                                 onChange={(e) => {
                                     editData?.map((field, index) => {
                                             setEditData(prevData =>
                                                 prevData?.map((item, idx) =>
                                                     idx === index ? {...item, value: e ? e : item.value} : item
                                                 )
                                             )
                                         }
                                     );

                                     setShowSaveChangesButton(true)

                                 }}/>

                        <SWInput style={{width: "15%"}} label={"File type"}
                                 value={props.accountData?.shipmentImportData?.fileType?.toString()}
                                 onChange={(e) => {
                                     editData?.map((field, index) => {
                                             setEditData(prevData =>
                                                 prevData?.map((item, idx) =>
                                                     idx === index ? {...item, value: e ? e : item.value} : item
                                                 )
                                             )
                                         }
                                     );

                                     setShowSaveChangesButton(true)

                                 }}/>

                        <SWInput style={{width: "15%"}} label={"Number of footers"}
                                 value={props.accountData?.shipmentImportData?.noOfFooters?.toString()}
                                 onChange={(e) => {
                                     editData?.map((field, index) => {
                                             setEditData(prevData =>
                                                 prevData?.map((item, idx) =>
                                                     idx === index ? {...item, value: e ? e : item.value} : item
                                                 )
                                             )
                                         }
                                     );

                                     setShowSaveChangesButton(true)

                                 }}/>

                        <SWInput style={{width: "15%"}} label={"Number of headers"}
                                 value={props.accountData?.shipmentImportData?.noOfHeaders?.toString()}
                                 onChange={(e) => {
                                     editData?.map((field, index) => {
                                             setEditData(prevData =>
                                                 prevData?.map((item, idx) =>
                                                     idx === index ? {...item, value: e ? e : item.value} : item
                                                 )
                                             )
                                         }
                                     );

                                     setShowSaveChangesButton(true)

                                 }}/>
                    </CRow>}
                    <CIcon
                        size={"xl"}
                        style={{cursor: 'pointer', marginBottom: "20px", marginLeft: '10px'}}
                        className="btn-icon"
                        content={freeSet.cilPlus}
                        onClick={addNewField
                        }
                    />
                </CCol>

            </CRow>

            <CContainer>

                <CDataTable
                    size="sm"
                    hover
                    scopedSlots={scopedSlots ?? {}}
                    items={editData ?? []}
                    fields={[
                        {key: "name", label: "System field"},
                        {key: "value", label: "Value"},
                        {key: "force", label: "Force"},
                    ]}
                />
            </CContainer>
            <CRow className="d-flex justify-content-end mt-4"
                  style={{marginBottom: !props.isBookingData ? '10nvm use px' : '0px'}}>
                <CCol md={5} className="d-flex justify-content-end">
                    <CCol md={5} className="d-flex justify-content-end">
                        <ActionButton text={'Save Changes'} disabled={!showSaveChangesButton} color={'success'}
                                      onClick={() => {
                                          handleUpdate()
                                      }}/>
                    </CCol>

                </CCol>
            </CRow>

        </>
    )

}
export default ViewShipData;
