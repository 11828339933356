import {AddressBook} from "../../../../models/addressBook";
import {useFormik} from "formik";
import * as Yup from 'yup';
import RatesClient from "../../../../clients/ratesClient";
import {
    CCol, CInputCheckbox, CLabel,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CRow, CTooltip
} from "@coreui/react";
import SWInput from "../../../SharedComponents/SWInput";
import SWSelect from "../../../SharedComponents/SWSelect";
import ActionButton from "../../../SharedComponents/ActionButton";
import React, {useState} from "react";

type Props = {
    submit: (data: AddressBook) => void
    onClose: () => void
    data: AddressBook | undefined
    type: 'EDIT' | 'ADD'
    countryLookups: { [k: string]: string }

}
const preferredLanguagesValues = {
    "en": "English",
    "fr": "France",

};

const validationSchema = Yup.object({
    addressLine1: Yup.string().required('Street is required'),
    city: Yup.string().required('City is required'),
    country: Yup.string().max(2, 'Country must be 2 characters').required('Country is required'),
    name: Yup.string().test(
        'name-or-companyName',
        'Either Name or Company Name is required',
        function (value) {
            return value || this.parent.companyName;
        }
    ),
    companyName: Yup.string().test(
        'companyName-or-name',
        'Either Company Name or Name is required',
        function (value) {
            return value || this.parent.name;
        }
    ),
    phone: Yup.string().test(
        'phone-or-email',
        'Either Phone or Email is required',
        function (value) {
            return value || this.parent.email;
        }
    ),
    email: Yup.string().email('Invalid email address').test(
        'email-or-phone',
        'Either Email or Phone is required',
        function (value) {
            return value || this.parent.phone;
        }
    ),
});
const AddressBookEditOrAddModal = (props: Props) => {


    const {values, handleSubmit, errors, setFieldValue} = useFormik<AddressBook>({
        initialValues: props.data ?? {},
        onSubmit: (values: AddressBook) => {
            props.submit(values)
        },
        validateOnChange: false,
        validationSchema: validationSchema
    })


    return (<>
        <CModal
            size={"lg"}
            closeOnBackdrop={false}
            show={true}
            centered
            onClose={props.onClose}
        >
            <CModalHeader closeButton>
                <h5>Address Book</h5>
            </CModalHeader>
            <CModalBody>

                <CRow className={"mt-2"} style={{color: 'darkblue'}}>
                    <CCol md={10}>
                        <CRow>
                            <CCol md={4} sm={12}>Company Name:</CCol>
                            <CCol md={8} sm={12}>
                                <SWInput
                                    label={""}
                                    name={`companyName`}
                                    value={values?.companyName}
                                    onChange={(e) => setFieldValue(`companyName`, e)}
                                    type={"text"}
                                    validationErrors={errors?.companyName?.toString() ? "Contact Name or Company Name is required" : ""}
                                />
                            </CCol>
                        </CRow>
                        <div style={{marginTop: -10}}>
                            <CRow>
                                <CCol md={4} sm={12}>Contact Name:</CCol>
                                <CCol md={8} sm={12}>
                                    <SWInput
                                        label={""}
                                        name={`name`}
                                        value={values?.name}
                                        onChange={(e) => setFieldValue(`name`, e)}
                                        type={"text"}
                                        validationErrors={errors?.name?.toString() ? "Contact Name or Company Name is required" : ""}
                                    />
                                </CCol>
                            </CRow>
                        </div>
                    </CCol>

                    <CCol md={10}>
                        <div style={{marginTop: -10}}>
                            <CRow>
                                <CCol md={4} sm={12}>Street:</CCol>
                                <CCol md={8} sm={12}>
                                    <SWInput
                                        label={""}
                                        name={`addressLine1`}
                                        value={values?.addressLine1}
                                        onChange={(e) => setFieldValue(`addressLine1`, e)}
                                        type={"text"}
                                        validationErrors={errors?.addressLine1?.toString() || ""}
                                    />
                                </CCol>
                            </CRow>
                        </div>
                        <div style={{marginTop: -10}}>
                            <CRow>
                                <CCol md={4} sm={12}>Address:</CCol>
                                <CCol md={8} sm={12}>
                                    <SWInput
                                        label={""}
                                        name={`addressLine2`}
                                        value={values?.addressLine2}
                                        onChange={(e) => setFieldValue(`addressLine2`, e)}
                                        type={"text"}
                                        validationErrors={errors?.addressLine2?.toString() || ""}
                                    />
                                </CCol>
                            </CRow>
                        </div>
                    </CCol>


                    <CCol md={10}>
                        <div style={{marginTop: -10}}>
                            <CRow>
                                <CCol md={4}>Zip Code /City:</CCol>
                                <CCol md={3}>
                                    <SWInput
                                        label={""}
                                        name={`postCode`}
                                        value={values?.postCode}
                                        onChange={(e) => setFieldValue(`postCode`, e)}
                                        type={"text"}
                                        validationErrors={errors?.postCode?.toString() || ""}
                                    />
                                </CCol>
                                <CCol md={5}>
                                    <SWInput
                                        label={""}
                                        name={`city`}
                                        value={values?.city}
                                        onChange={(e) => setFieldValue(`city`, e)}
                                        type={"text"}
                                        validationErrors={errors?.city?.toString() || ""}
                                    />


                                </CCol>
                            </CRow>
                        </div>
                        <div style={{marginTop: -10}}>
                            <CRow>
                                <CCol md={4} sm={12}>Country:</CCol>
                                <CCol md={8} sm={12}>
                                    <SWSelect
                                        label={""}
                                        validationErrors={errors?.country?.toString() || ""}
                                        name={`country`}
                                        value={values?.country}
                                        onChange={(e) => setFieldValue(`country`, e)}
                                        values={props.countryLookups}
                                    />
                                </CCol>
                            </CRow>
                        </div>
                    </CCol>


                    <CCol md={10}>
                        <div style={{marginTop: -10}}>
                            <CRow>
                                <CCol md={4} sm={12}>Telephone:</CCol>
                                <CCol md={8} sm={12}>

                                    <SWInput
                                        labelClassName='m-0 small'
                                        label={""}
                                        name={`phone`}
                                        value={values?.phone}
                                        onChange={(e) => setFieldValue(`phone`, e)}
                                        type={"text"}
                                        validationErrors={errors?.phone?.toString() ? "Telephone or Email is required" : ""}
                                    />
                                </CCol>
                            </CRow>
                        </div>
                        <div style={{marginTop: -10}}>
                            <CRow>
                                <CCol md={4} sm={12}>Email:</CCol>
                                <CCol md={8} sm={12}>
                                    <SWInput
                                        label={""}
                                        name={`email`}
                                        value={values?.email}
                                        onChange={(e) => setFieldValue(`email`, e)}
                                        type={"text"}
                                        validationErrors={errors?.email?.toString() ? "Telephone or Email is required" : ""}
                                    />
                                </CCol>
                            </CRow>
                        </div>
                    </CCol>

                    <CCol md={10}>
                        <div style={{marginTop: -10}}>
                            <CRow>
                                <CCol md={4}>Notes:</CCol>
                                <CCol md={8} sm={12}>
                                    <SWInput
                                        label={""}
                                        name={`notes`}
                                        value={values?.notes}
                                        onChange={(e) => setFieldValue(`notes`, e)}
                                        type={"text"}
                                        validationErrors={errors?.notes?.toString() || ""}
                                    />
                                </CCol>
                            </CRow>
                        </div>
                        <div style={{marginTop: -10}}>
                            <CRow>
                                <CCol md={4}>EORI:</CCol>
                                <CCol md={8} sm={12}>
                                    <SWInput
                                        label={""}
                                        name={`eori`}
                                        value={values?.eori}
                                        onChange={(e) => setFieldValue(`eori`, e)}
                                        type={"text"}
                                        validationErrors={errors?.eori?.toString() || ""}
                                    />
                                </CCol>
                            </CRow>
                        </div>
                        <CCol md={12} style={{marginTop: "2%", marginLeft: '2%'}}>
                            <CRow className={'justify-content-end'}>
                                <CCol md={4}>
                                    <CInputCheckbox checked={values.defaultForShipper}
                                                    onChange={() => setFieldValue("defaultForShipper", !values.defaultForShipper)}/>
                                    <CLabel>Default for Shipper</CLabel>
                                </CCol>
                                <CCol md={4}>

                                    <CInputCheckbox checked={values.defaultForConsignee}
                                                    onChange={() => setFieldValue("defaultForConsignee", !values.defaultForConsignee)}/>
                                    <CLabel>Default for Consignee</CLabel>
                                </CCol>
                            </CRow>
                        </CCol>
                    </CCol>
                </CRow>


            </CModalBody>

            <CModalFooter>
                {
                    <CRow className="justify-content-between w-100 mt-2">
                        <CCol className={"text-right"}>
                            <ActionButton
                                text={"Submit"}
                                onClick={
                                    handleSubmit
                                }
                            />
                        </CCol>
                    </CRow>}
            </CModalFooter>
        </CModal>

    </>)
}
export default AddressBookEditOrAddModal
