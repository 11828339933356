import React, {useState} from 'react';
import {freeSet} from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {CRow, CCol, CCardBody, CTooltip, CInputCheckbox, CLabel} from '@coreui/react';
import SWInput from '../../../SharedComponents/SWInput';
import {FormikProps} from 'formik';
import {CreateShipment} from '../../../../models/shipment';
import SWSelect from '../../../SharedComponents/SWSelect';
import ResetIcon from '../../../../icons/resetIcon';

interface IProps {
    formik: FormikProps<CreateShipment>;
    showFields: boolean;
    setShowFields: (e: boolean) => void
}

const AdditionalDetails = ({formik, showFields, setShowFields}: IProps) => {
    const {values, setFieldValue, touched, errors, setValues} = formik;
    const [isHover, setisHover] = useState(false);
    const [focusedInput, setFocusedInput] = useState<boolean>(false)
    const [onlineCod, setOnlineCod] = useState<boolean>(Boolean(values?.onlineCOD) ?? false)
    
    const currencies = [
        {label: "EUR", value: "EUR"},
        {label: "AED", value: "AED"}, 
        {label: "INR", value: "INR"},
        {label: "USD", value: "USD"},
        {label: "GBP", value: "GBP"},
        {label: "CHF", value: "CHF"},
        {label: "HKD", value: "HKD"},
    ]
    const handleClear = () => {
        setValues({
            ...values,
            weight: {value: 0.1},
            value: {amount: 0, currency: "EUR"},
            cOD: {amount: 0, currency: "EUR"},
            onlineCOD: 0,
            incoterm: "DDU",
            iOSS: ""
        });
    }
    return (
        <>
            <CCardBody
                className={`mt-0 pt-2 pb-0 mt-0 mb-0  rounded text-black  ${(isHover || focusedInput) ? "bg-secondary" : ""
                }`}
                onMouseOver={() => {
                    setisHover(true);
                }}
                onMouseLeave={() => {
                    setisHover(false);
                }}
            >
                <CRow className="mb-1">
                    <h6>
                        <strong>Shipment Data:</strong>
                    </h6>
                    <CCol className="text-right">
                        {(isHover || focusedInput) &&
                            <>
                                {!showFields &&
                                    <CTooltip content={"Unlock"}>
                                        <CIcon
                                            content={freeSet.cilLockLocked}
                                            onClick={() => setShowFields(true)}
                                            style={{
                                                height: "1.5em",
                                                width: "1.5em",
                                                cursor: "pointer"
                                            }}
                                        />
                                    </CTooltip>}
                                {showFields &&
                                    <>
                                        <ResetIcon onClick={() => handleClear()} tooltipText={"Clear"}/>
                                        <CTooltip content={"Lock"}>
                                            <CIcon
                                                content={freeSet.cilLockUnlocked}
                                                onClick={() => setShowFields(false)}
                                                style={{
                                                    height: "1.5em",
                                                    width: "1.5em",
                                                    cursor: "pointer",
                                                    marginLeft: 8
                                                }}
                                            />
                                        </CTooltip>
                                    </>}

                            </>
                        }
                    </CCol>
                </CRow>
                <CRow>
                    <CCol md={2}/>
                    <CCol md={10}>
                        <CRow>
                            <CCol md={4}>Value:</CCol>
                            <CCol md={showFields ? 5 : 6}>
                                {showFields ?
                                    <SWInput
                                        onfocus={() => setFocusedInput(true)}
                                        onBlur={() => setFocusedInput(false)}
                                        label={""}
                                        name={"value.amount"}
                                        value={values?.value?.amount?.toString() ?? ""}
                                        onChange={(e) => setFieldValue("value.amount", e)}
                                        type={"number"}
                                        validationErrors={touched?.value?.amount && errors?.value?.amount?.toString() || ""}
                                    /> : <strong>
                                        {`${values?.value?.amount ?? ''} ${values?.value?.currency ?? ''}`}
                                    </strong>}
                            </CCol>
                            <CCol md={showFields ? 3 : 0}>
                                {showFields ?
                                    <SWSelect
                                        onFocus={() => setFocusedInput(true)}
                                        onBlur={() => setFocusedInput(false)}
                                        label={""}
                                        validationErrors={touched?.value?.currency && errors?.value?.currency?.toString() || ""}
                                        name={"value.currency"}
                                        value={values?.value?.currency}
                                        onChange={(e) => setFieldValue("value.currency", e)}
                                        values={currencies}
                                    /> :
                                    <></>

                                }
                            </CCol>
                        </CRow>
                    </CCol>
                </CRow>
                <div style={{marginTop: showFields ? -10 : 0}}>
                    <CRow>
                        <CCol md={2}/>
                        <CCol md={10}>
                            <CRow>
                                <CCol md={4}>COD:</CCol>
                                <CCol className="ml-4 mr-1">
                                    <CInputCheckbox
                                        readOnly={!showFields}
                                        disabled={!showFields}
                                        name={"onlineCod"}
                                        checked={onlineCod}
                                        onChange={(e) => {
                                            setFieldValue("cOD.amount", 0)
                                            setFieldValue("onlineCOD", 0)
                                            setOnlineCod(!onlineCod)
                                        }}
                                    />
                                    <CLabel className={"mt-1"}>Online</CLabel>
                                </CCol>
                                {onlineCod ?
                                    <>
                                        <CCol md={showFields ? 3 : 6} className={!showFields ? 'mt-1' : ''}>
                                            {showFields ?
                                                <SWInput
                                                    onfocus={() => setFocusedInput(true)}
                                                    onBlur={() => setFocusedInput(false)}
                                                    label={""}
                                                    name={"onlineCOD"}
                                                    value={values?.onlineCOD?.toString() ?? ""}
                                                    onChange={(e) => {
                                                        setFieldValue("onlineCOD", e)
                                                    }}
                                                    type={"number"}
                                                    validationErrors={touched?.onlineCOD && errors?.onlineCOD?.toString() || ""}
                                                /> : <strong>
                                                    {`${values?.onlineCOD ?? ''} ${values?.value?.currency ?? ''}`}
                                                </strong>
                                            }
                                        </CCol>
                                        <CCol md={showFields ? 3 : 0}>
                                            {showFields ?
                                                <SWSelect
                                                    readonly  // the currency of the online cod will be same as the value
                                                    onFocus={() => setFocusedInput(true)}
                                                    onBlur={() => setFocusedInput(false)}
                                                    label={""}
                                                    name={"value.currency"}
                                                    value={values?.value?.currency}
                                                    onChange={(e) => setFieldValue("value.currency", e)}
                                                    values={currencies}
                                                /> :
                                                <></>

                                            }
                                        </CCol>

                                    </>
                                    :
                                    <>
                                        <CCol md={showFields ? 3 : 6} className={!showFields ? 'mt-1' : ''}>
                                            {showFields ?
                                                <SWInput
                                                    onfocus={() => setFocusedInput(true)}
                                                    onBlur={() => setFocusedInput(false)}
                                                    label={""}
                                                    name={"cOD.amount"}
                                                    value={values?.cOD?.amount?.toString() ?? ""}
                                                    onChange={(e) => {
                                                        setFieldValue("cOD.amount", e)

                                                    }}
                                                    type={"number"}
                                                    validationErrors={touched?.cOD?.amount && errors?.cOD?.amount?.toString() || ""}
                                                /> : <strong>
                                                    {`${values?.cOD?.amount ?? ''} ${values?.cOD?.currency ?? ''}`}
                                                </strong>
                                            }
                                        </CCol>
                                        <CCol md={showFields ? 3 : 0}>
                                            {showFields ?
                                                <SWSelect
                                                    onFocus={() => setFocusedInput(true)}
                                                    onBlur={() => setFocusedInput(false)}
                                                    label={""}
                                                    validationErrors={touched?.cOD?.currency && errors?.cOD?.currency?.toString() || ""}
                                                    name={"cOD.currency"}
                                                    value={values?.cOD?.currency}
                                                    onChange={(e) => setFieldValue("cOD.currency", e)}
                                                    values={currencies}
                                                /> :
                                                <></>

                                            }
                                        </CCol>
                                    </>
                                }
                            </CRow>
                        </CCol>
                    </CRow>
                </div>
                <div style={{marginTop: showFields ? -10 : 0}}>
                    <CRow>
                        <CCol md={2}/>
                        <CCol md={10}>
                            <CRow>
                                <CCol md={4}>Incoterm:</CCol>
                                <CCol md={8}>
                                    {showFields ?
                                        <SWSelect
                                            onFocus={() => setFocusedInput(true)}
                                            onBlur={() => setFocusedInput(false)}
                                            label={""}
                                            validationErrors={touched?.incoterm && errors?.incoterm?.toString() || ""}
                                            name={"incoterm"}
                                            value={values?.incoterm}
                                            onChange={(e) => setFieldValue("incoterm", e)}
                                            values={[{label: "DDU", value: "DDU"}, {
                                                label: "DAP",
                                                value: "DAP"
                                            }, {label: "DDP", value: "DDP"}]}
                                        />
                                        :
                                        <strong>
                                            {values?.incoterm}
                                        </strong>}
                                </CCol>
                            </CRow>
                        </CCol>
                    </CRow>
                </div>
                <div style={{marginTop: showFields ? -10 : 0}}>
                    <CRow>
                        <CCol md={2}/>
                        <CCol md={10}>
                            <CRow>
                                <CCol md={4}>IOSS:</CCol>
                                <CCol md={8}>
                                    {showFields ?
                                        <SWInput
                                            onfocus={() => setFocusedInput(true)}
                                            onBlur={() => setFocusedInput(false)}
                                            isFieldHidden={!showFields}
                                            labelClassName='m-0 small'
                                            label={""}
                                            name={"iOSS"}
                                            value={values?.iOSS}
                                            onChange={(e) => setFieldValue("iOSS", e)}
                                            type={"text"}
                                            validationErrors={touched?.iOSS && errors?.iOSS?.toString() || ""}
                                        /> :
                                        <strong>
                                            {values?.iOSS}
                                        </strong>}
                                </CCol>
                            </CRow>
                        </CCol>
                    </CRow>
                </div>
            </CCardBody>
        </>

    )

}

export default AdditionalDetails;