
import React, { useState } from "react";
import { CRow, CCol, CButtonGroup, CButton } from "@coreui/react";
import CreateImport from "./CreateImport";


interface Props {
    onAdd: () => void
    onExport?: () => void
    cardId?: number
    getCard?: () => void
    importDataType?: "DestZone" | "OrgZone" | "RateEntry" | "Surcharges"
    keys?: string[]
    hideImport?:boolean
}

const TabActions = ({ onAdd, onExport, cardId, getCard, importDataType, keys,hideImport }: Props) => {
    const [importModal, setImportModal] = useState<boolean>(false)
    return (
        <CRow className={"my-2 mx-1"}>
            <CCol md={12} className="mr-3">
                <CButtonGroup className="mb-0 ">
                    <CButton
                        size="sm"
                        style={{ fontSize: "10px" }}
                        className="mr-2"
                        color="primary"
                        onClick={() => onAdd()}
                    >
                        Add
                    </CButton>
                    {!hideImport &&
                    <CButton
                        size="sm"
                        style={{ fontSize: "10px" }}
                        className="mr-2"
                        color="primary"
                        variant="outline"
                        onClick={() => {
                            setImportModal(true)
                        }}
                    >
                        Import
                    </CButton>}
                    {onExport &&
                        <CButton
                            size="sm"
                            style={{ fontSize: "10px" }}
                            className="mr-2"
                            color="success"
                            variant="outline"
                            onClick={() => onExport?.()}
                        >
                            Export to Excel
                        </CButton>}
                </CButtonGroup>
            </CCol>
            {importModal &&
                <CreateImport
                    getCard={() => getCard?.()}
                    cardId={cardId}
                    keys={keys!}
                    importDataType={importDataType}
                    onClose={() => setImportModal(false)}
                />
            }
        </CRow>
    )
}

export default TabActions;