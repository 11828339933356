import {GetShipmentLogs} from "../../../../models/getShipmentLogs";
import {CCol, CLabel, CRow} from "@coreui/react";
import {PathLogsType} from "./viewLogs";
import {formatDateTime, getLocalDateTime} from "../../../../utils/dateUtil";
import {useEffect} from "react";

type Props = {
    data: GetShipmentLogs[]
    addPath: (req: PathLogsType) => void
    changeLogs:(req:PathLogsType)=>void
}
const LogsInfo = (props: Props) => {
    const addPathHandler = (req: PathLogsType) => {
        props.addPath(req)
    }
    useEffect(() => {
        if(props.data.length==1)
            props.changeLogs({name:'EXTERNAL',
            title:'Log 1', logsIndex:0})
    }, [props.data.length,props.changeLogs]);
    return (<>

            <CRow>


                <>


                        <CRow className={'logs-values-path-container'}>
                            {props?.data?.map((logs, index) => {
                                return (
                                    <CCol md={12} className={'border-bottom mb-2'}><CLabel className={'logs-values-path'}
                                                                                   onClick={() => addPathHandler({
                                                                                       name: "EXTERNAL",
                                                                                       title: `Logs ${index + 1}`,
                                                                                       logsIndex: index
                                                                                   })}
                                                                                   type={'button'}>{`${formatDateTime(logs?.on) ?? '-'} `}</CLabel></CCol>
                                )
                            })}
                        </CRow></>

            </CRow>

        </>
    )
}
export default LogsInfo
