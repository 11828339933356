import {
  CButton,
  CCollapse,
  CNav,
  CNavItem,
  CNavLink,
  CSidebarNavItem,
  CSidebarNavTitle,
  CRow,
  CCol,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { RouteData } from "../../routes";
import { routeDataToRoute } from "../../utils/routeUtils";
import CIcon from "@coreui/icons-react";
import { freeSet } from "@coreui/icons";
import { useLocation } from "react-router";
import {useDispatch, useStore} from "react-redux";
import {SetShipmentPage, setShipmentSearchLimit, SetShipmentsFilterGroupFilters} from "../../store/actions/shipments";

interface Props {
  routeData: RouteData;
}
const NavItem = (props: Props) => {
  const [show, setShow] = useState(false);
  const loc = useLocation();
  const dispatch = useDispatch()
const handleOnClick = (name: string) => {
    if(name == "Shipments"){
      dispatch(SetShipmentsFilterGroupFilters([]))
      dispatch(SetShipmentPage(0))
      dispatch(setShipmentSearchLimit(20))
    }
}
  useEffect(() => {
    if (!loc.pathname.includes(routeData.path)) {
      setShow(false);
    } else setShow(true);
  }, [loc.pathname]);

  const { routeData } = props;
  return (
    <div>
      {routeData.subRoutes && (
        <>
          <CSidebarNavTitle
            style={{ cursor: "pointer", fontWeight: "normal" }}
            onClick={() => setShow(!show)}
          >
            {show ? (
              <CIcon
                content={freeSet.cilArrowBottom}
                name="bottom"
                className="mr-2"
              />
            ) : (
              <CIcon
                content={freeSet.cilArrowRight}
                name="right"
                className="mr-2"
              />
            )}
            {routeData.name}
          </CSidebarNavTitle>

          <CCollapse show={show}>
            {routeData.subRoutes
              .filter((p) => p.isPage)
              .map((sb) => (
                <CSidebarNavItem
                  className="ml-2"
                  style={{ transition: "0.25s ease-in" }}
                  icon={sb.icon}
                  to={`${routeData.path}${sb.path}`}
                  onClick={()=>handleOnClick(routeData.name)}
                  key={`${routeData.path}${sb.path}`}
                  name={`${sb.name}`}
                />
              ))}
          </CCollapse>
        </>
      )}
    </div>
  );
};

export default NavItem;
