import React, { useState } from 'react';
import { freeSet } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CRow, CCol, CCardBody, CTooltip } from '@coreui/react';
import { FormikProps } from 'formik';
import { CreateShipment } from '../../../../models/shipment';
interface IProps {
    formik: FormikProps<CreateShipment>;
    showFields: boolean;
    setShowFields: (e: boolean) => void
}

const CustomsDetails = ({ formik, showFields, setShowFields }: IProps) => {
    const { values, setFieldValue, touched, errors } = formik;
    const [isHover, setisHover] = useState(false);
    const [focusedInput, setFocusedInput] = useState<boolean>(false)

    return (
        <>
            <CCardBody
                className={`mt-0 pt-2 pb-0 mt-0 mb-0 rounded text-black  ${(isHover || focusedInput) ? "bg-secondary" : ""
                    }`}
                onMouseOver={() => {
                    setisHover(true);
                }}
                onMouseLeave={() => {
                    setisHover(false);
                }}
            >
                  <CRow className="mb-1">
                    <h6>
                        <strong>Custom Data:</strong>
                    </h6>
                    <CCol className="text-right">
                        {(isHover || focusedInput) &&
                            <>
                                {!showFields &&
                                    <CTooltip content={"Unlock"} >
                                        <CIcon
                                            content={freeSet.cilLockLocked}
                                            onClick={() => setShowFields(true)}
                                            style={{
                                                height: "1.5em",
                                                width: "1.5em",
                                                cursor: "pointer",
                                                marginLeft:8
                                            }}
                                        />
                                    </CTooltip>}
                                {showFields &&
                                    <CTooltip content={"Lock"} >
                                        <CIcon
                                            content={freeSet.cilLockUnlocked}
                                            onClick={() => setShowFields(false)}
                                            style={{
                                                height: "1.5em",
                                                width: "1.5em",
                                                cursor: "pointer"
                                            }}
                                        />
                                    </CTooltip>}

                            </>
                        }
                    </CCol>
                </CRow>
            </CCardBody>
        </>

    )

}

export default CustomsDetails;