import {CFormGroup, CFormText, CInput, CLabel} from "@coreui/react";
import React, {forwardRef} from "react";
import DatePicker from "react-datepicker";
import {formatDateTime} from "../../../utils/dateUtil";
import DateFormat from "dateformat";

interface Props{
    field: any,
    readonly?: any,
    label: string,
    form: any,
    showTimeInput: boolean;
}

const DateInputField = ({ field,readonly, label, form,showTimeInput, ...props }:Props) => {
    const handleOnChange = (date : any)=> {
        form.setFieldValue(field.name, date)
    }

    const formatValue = (value:any, showTime:boolean)=>{
        if (!value) return ""

        if (showTime)
            return DateFormat(value, "dd-mm-yyyy hh:MM TT")
        else
            return DateFormat(value, "dd-mm-yyyy")
    }

    const CustomInput = forwardRef<any, any>(({ value, onClick, showTime }, ref) => {
        return (
            <span className="form-control" style={{ fontSize: "10px", height: "26px" }} onClick={onClick} ref={ref}>
        {field.value && formatValue(field.value, showTime)
            }
      </span>
        );
    });

    return (
        <CFormGroup>
            <CLabel htmlFor={field.name}>{label}</CLabel>
            <CFormGroup>
                <DatePicker
                    showYearDropdown
                    readOnly={readonly}
                    selected={field.value}
                    showTimeInput={showTimeInput ?? true}
                    onChange={handleOnChange}
                    customInput={<CustomInput showTime={showTimeInput}/>}
                    className="form-control"
                />
            </CFormGroup>
            {
                form.touched[field.name] && form.errors[field.name]
                    ? <CFormText
                        style={{ fontSize: "1em" }}
                        color="danger"
                        className="help-block"
                    >
                        {form.errors[field.name]}
                    </CFormText>
                    : null
            }
        </CFormGroup>
    )
}

export default DateInputField