import {CDataTable, CLink, CSpinner} from "@coreui/react";
import React, {useState} from "react";
import {IPaginatedResult, IPagination} from "../../../../../models/filter";
import {InventoryDetailsReportItem, InventoryTransaction, InventoryTransactionDetailDto} from "../../../../../models/warehouse";
import PageCount from "../../../../SharedComponents/PageCount";
import {useHistory, useLocation} from "react-router";
import {formatDate} from "../../../../../utils/dateUtil";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";

interface Props {
    paginatedStock: IPaginatedResult<InventoryTransactionDetailDto>
    pagination: IPagination
    isLoading: boolean
    accountsLookup: { [k: string]: string }
    type: { [k: string]: string }
}

const SearchResultDetails = (props: Props) => {
    const [limit, setLimit] = useState<number>(20)

    const hist = useHistory()
    const loc = useLocation()

    return (
        <div className={"small"}>
            {props.isLoading ? (
                <CSpinner
                    className="mx-auto d-block my-5"
                    color="primary"
                    style={{width: "5em", height: "5em"}}
                />
            ) : (
                <>
                    <CDataTable
                        hover
                        size={"sm"}
                        items={props.paginatedStock.matches}
                        fields={[
                            {key: "action", label: ""},
                            {key:"on", label: "Date"},
                            "sku",
                            "status",
                            "reference",
                            {key:"account", label: "Account"},
                            {key:"code", label:"Id"},
                            {key:"subType", label: "Type"},
                            "location",
                            "quantity",
                            {key:"quantityAfter", label: "Current Quantity"},
                            {key: "quantityAvailableAfter", label: "Available Quantity"}
                        ]}
                        scopedSlots={{
                            account:(item : InventoryDetailsReportItem) => {
                                return<td width={"100px"}>{item.account}</td>
                            },
                            code:(item:InventoryTransaction)=>{
                                return<td>{item.code?.slice(0,12)}</td>
                            },
                            subType:(item:InventoryTransaction)=> {
                                return<td>{props.type[item.subType]}</td>
                            },
                            reference: (item: InventoryTransaction)=> {
                                if (item.shipmentNumber)
                                    return <td><CLink href={`/shipments/${item.reference}`}>{item.shipmentNumber}</CLink></td>
                                else if (item.reference)
                                    return <td>{item.reference}</td>
                                return <td />
                            },
                            on:(item: InventoryTransaction) => {
                                return <td>{formatDate(item.on)}</td>
                            },
                            location:(item: InventoryDetailsReportItem) => {
                                return <td>{`${item.warehouse} - ${item.location}`}</td>
                            },
                            action:(item:InventoryDetailsReportItem)=>{
                                return  <td><CLink to={`/stock/transactions/${encodeURI(item.code)}`} className="card-header-action">
                                    <FontAwesomeIcon icon={faEye}/>
                                </CLink></td>
                            },
                            sku:(item: InventoryDetailsReportItem)=>{
                                return <td width={"100px"}>{item.sku} - {item.skuCode}</td>
                            },
                            quantity:(item: InventoryDetailsReportItem)=> {
                                return <td>{item.quantity != 0 ? item.quantity : item.quantityAvailable}</td>
                            }
                        }}
                    />
                    <PageCount
                        onPageChange={(p) => {
                            const modifiedSearch = new URLSearchParams(loc.search);
                            modifiedSearch.delete("offset");
                            hist.push(
                                `${loc.pathname}?${modifiedSearch.toString()}&offset=${p*props?.pagination?.limit!}`
                            );
                        }}
                        onPageSizeChange={(ps) => {
                            const modifiedSearch = new URLSearchParams(loc.search);
                            modifiedSearch.delete("limit");
                            hist.push(
                                `${loc.pathname}?${modifiedSearch.toString()}&limit=${ps}`
                            );
                            setLimit(ps)
                        }}
                        currentPage={Math.ceil(props?.pagination?.offset!/ limit!)}
                        maxPage={Math.ceil(  props.paginatedStock?.total!/limit!)}
                        maxFound={props.paginatedStock.total}
                    />
                </>
            )}
        </div>
    )
}

export default SearchResultDetails;