import { CCard, CContainer, CNav, CNavLink, CTabContent, CTabPane, CTabs } from "@coreui/react";
import React, { Fragment, useState } from "react";
import AddCODForm from "./components/AddCODForm";
import PendingCODTable from "./components/PendingCODTable";
import GeneratedCodTable from "./components/GeneratedCodTable";
import BankDepositsTable from "./components/BankDeposits";

const CODManagement = () => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    return (
        <Fragment>
            <CContainer className="bg-white p-4 text-primary small">
                <AddCODForm setIsSubmitting={setIsSubmitting} />
                <div className={"py-4"}></div>
                {!isSubmitting ?
                    <CTabs>
                        <CCard className="small">
                            <CNav variant="tabs">
                                <CNavLink>Pending COD</CNavLink>
                                <CNavLink>Generated Reports</CNavLink>
                                <CNavLink>Bank Deposits</CNavLink>
                            </CNav>
                            <CTabContent>
                                <CTabPane>
                                    <PendingCODTable setIsSubmitting={setIsSubmitting} />
                                </CTabPane>
                                <CTabPane>
                                    <GeneratedCodTable />
                                </CTabPane>
                                <CTabPane>
                                    <BankDepositsTable setIsSubmitting={setIsSubmitting} />
                                </CTabPane>
                            </CTabContent>
                        </CCard>
                    </CTabs>
                    : null}
            </CContainer>
        </Fragment>
    )
}

export default CODManagement