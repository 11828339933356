import React, { Fragment, useEffect, useState } from 'react';
import { freeSet } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CRow, CTooltip, CCol, CCardBody } from '@coreui/react';
import { useFormik } from 'formik';
import { CreateShipment, getWeightUnitAsString, StandardShipmentItem } from '../../../../models/shipment';
import SWInput from '../../../SharedComponents/SWInput';
import * as yup from "yup"
import SWSelect from '../../../SharedComponents/SWSelect';
import WarehousesClient from '../../../../clients/warehousesClient';
import { SkuSearch, warehouse } from '../../../../models/warehouse';
import { FormikProps } from 'formik';
import ResetIcon from '../../../../icons/resetIcon';
import { ShowNotification } from '../../../../store/actions/auth';
import { useDispatch } from 'react-redux';
interface IProps {
    account?: string
    items: StandardShipmentItem[]
    addItem: (values: StandardShipmentItem) => void
    handleDeleteItem: (index: number) => void
    handleEditItem: (index: number, key: string, newValue: string | number) => void
    formik: FormikProps<CreateShipment>;
    showFields: boolean;
    setShowFields: (e: boolean) => void
}



const OrderItemDetails = ({ items, addItem, handleDeleteItem, handleEditItem, account, formik, showFields, setShowFields }: IProps) => {
    const dispatch = useDispatch();
    const warehouseClient = new WarehousesClient();
    const [isHover, setIsHover] = useState(false);
    const [warehouseLookups, setWarehouseLookups] = useState<{ [k: string]: string }>({})
    const [skuLookups, setSkuLookups] = useState<{ [k: string]: string }>({})
    const [allSkus, setAllSkus] = useState<SkuSearch[]>([])
    const [focusedInput, setFocusedInput] = useState<boolean>(false)

    const { values, setFieldValue, resetForm } = useFormik<StandardShipmentItem>({
        enableReinitialize: true,
        initialValues: {
            description: "",
            weight: undefined,
            quantity: undefined,
            warehouse: "",
            dutiable: false, // from stock
            productCode: "",
            quantityAvailable: ""
        },
        onSubmit: (values) => {
        },
        validationSchema: yup.object({
        })
    })

    useEffect(() => {
        getAccount()
    }, [account]);


    const getAccount = async () => {
        if (account) {
            const res = await warehouseClient.GetAccount(account);
            if (res?.warehouse) {
                setFieldValue("warehouse", res?.warehouse)
                await initSkus(res?.warehouse)
            } else {
                setFieldValue("warehouse", "")
                setSkuLookups({})
                setAllSkus([])
            }

            if (res?.allowedWarehouses) {
                let warehouses: warehouse[] = await warehouseClient.searchWarehouse();
                const allowedIds = new Set(res.allowedWarehouses);
                const allowed = warehouses.filter((e) => allowedIds.has(e.id));
                const warehouseLookup: { [k: string]: string } = {};
                allowed?.forEach((warehouse) => {
                    warehouseLookup[warehouse.code] = warehouse.name;
                });
                setWarehouseLookups(warehouseLookup);
            } else {
                setWarehouseLookups({});
            }
        }
    };


    const initSkus = async (w?: string) => {
        const warehouses = await warehouseClient.searchWarehouse()
        const skuSearch = await warehouseClient.SearchSku({
            accountNumber: account,
            limit: 3000,
            warehouse: w
        })
        const skus: SkuSearch[] = skuSearch?.data?.matches?.filter((sku: SkuSearch)=>sku?.quantityAvailable != 0)
        setAllSkus(skus)
        const skuLookup: { [k: string]: string } = {}
        skus?.forEach(s => {
            let warehouse = '';
            if (s.allowedWarehouses?.length > 0) {
                let selectedWarehouse = warehouses.find(w => w.id == s.allowedWarehouses[0])
                warehouse = selectedWarehouse?.code ?? ""
            } else if (s.warehouse)
                warehouse = s.warehouse
            skuLookup[s.code] = `${warehouse ? warehouse + " - " : ""}${s.code} - ${s.name}`
        })
        setSkuLookups(skuLookup)
    }

    const handleSkuChanged = (code?: string) => {
        const sku = allSkus?.find(s => s.code == code)
        formik.setFieldValue("itemsFromStock", [...formik.values.itemsFromStock || [], {
            description: sku?.name,
            productCode: sku?.code,
            weight: sku?.weight?.value && getWeightUnitAsString(sku?.weight?.unit)?.toLowerCase() == "gm" ? sku?.weight?.value / 1000 : sku?.weight?.value,
            quantity: 1,
            dutiable: false, // stock
            warehouse: values?.warehouse,
            quantityAvailable: sku?.quantityAvailable,
        }]);
    }


    const handleClear = () => {
        formik.setFieldValue("itemsFromStock", [])
        resetForm();
    }
    return (
        <CCardBody
            className={`mt-1 pt-2 pb-1 mt-0 mb-0  rounded text-black  ${(isHover || focusedInput) ? "bg-secondary" : ""
                }`}
            onMouseOver={() => {
                setIsHover(true);
            }}
            onMouseLeave={() => {
                setIsHover(false);
            }}
        >
            <CRow className="mb-1">
                <h6>
                    <strong>Contents from stock:</strong>
                </h6>
                <CCol className="text-right">
                    {(isHover || focusedInput) &&
                        <>
                            {!showFields &&
                                <CTooltip content={"unlock"} >
                                    <CIcon
                                        content={freeSet.cilLockLocked}
                                        onClick={() => setShowFields(true)}
                                        style={{
                                            height: "1.5em",
                                            width: "1.5em",
                                            cursor: "pointer",

                                        }}
                                    />
                                </CTooltip>}
                            {showFields &&
                                <>
                                    <ResetIcon onClick={() => handleClear()} tooltipText={"Clear"} />
                                    <CTooltip content={"Lock"} >
                                        <CIcon
                                            content={freeSet.cilLockUnlocked}
                                            onClick={() => setShowFields(false)}
                                            style={{
                                                height: "1.5em",
                                                width: "1.5em",
                                                cursor: "pointer",
                                                marginLeft: 8
                                            }}
                                        />
                                    </CTooltip></>}

                        </>
                    }
                </CCol>
            </CRow>
            {showFields &&
                <CRow className={"ml-5"}>
                    <CCol className={"p-0 mr-2"} md={4} sm={4}  >
                        <SWSelect
                            className=''
                            onFocus={() => setFocusedInput(true)}
                            onBlur={() => setFocusedInput(false)}
                            label={"Warehouse"} values={warehouseLookups}
                            value={values?.warehouse}
                            readonly={formik.values?.itemsFromStock?.length && formik.values?.itemsFromStock?.length > 0 || !showFields}
                            onChange={async (w) => {
                                setFieldValue("warehouse", w)
                                await initSkus(w)
                            }} isClearable />
                    </CCol>
                    <CCol className={"p-0"} md={7} sm={4} >
                        <SWSelect
                            readonly={!showFields}
                            onFocus={() => setFocusedInput(true)}
                            onBlur={() => setFocusedInput(false)}
                            label={"Sku"} values={skuLookups}
                            value={""}
                            onChange={s => {
                                s && handleSkuChanged(s)
                            }}
                            isClearable
                        />
                    </CCol>
                </CRow>}


            {items?.map((item: StandardShipmentItem, index: number) => {
                return (
                    <Fragment key={index}>
                        <CRow className={'ml-5 mb-1'}>
                            <CCol className={"p-0 mr-2"} md={6} sm={4}  >
                                <SWInput
                                    onfocus={() => setFocusedInput(true)}
                                    onBlur={() => setFocusedInput(false)}
                                    style={{ marginTop: -10 }}
                                    isFieldHidden={!showFields}
                                    labelClassName='m-0 small'
                                    label={index == 0 ? "Description" : ""}
                                    name={"description"}
                                    value={item?.description}
                                    onChange={(e) => handleEditItem(index, "description", e)}
                                    type={"text"}
                                />  {!showFields && <strong> {item?.description}</strong>}
                            </CCol>


                            <CCol className={"p-0 mr-2"} md={2} sm={4} >
                                <SWInput
                                    onfocus={() => setFocusedInput(true)}
                                    onBlur={() => setFocusedInput(false)}
                                    style={{ marginTop: -10 }}
                                    isFieldHidden={!showFields}
                                    labelClassName='m-0 small'
                                    label={index == 0 ? "Quantity" : ""}
                                    name={"quantity"}
                                    value={item?.quantity?.toString() ?? ""}
                                    onChange={(e) => {
                                        if (Number(e) > Number(item?.quantityAvailable)) {
                                            dispatch(ShowNotification("Error", `Available quantity ${item?.quantityAvailable}`, true));
                                        } else {
                                            handleEditItem(index, "quantity", e)
                                        }
                                    }
                                    }
                                    type={"text"}
                                />  {!showFields && <strong> {item?.quantity}</strong>}
                            </CCol>



                            <CCol className={"p-0"} md={2} sm={4} >
                                <SWInput
                                    readonly
                                    onfocus={() => setFocusedInput(true)}
                                    onBlur={() => setFocusedInput(false)}
                                    style={{ marginTop: -10 }}
                                    isFieldHidden={!showFields}
                                    labelClassName='m-0 small'
                                    label={index == 0 ? "Kg" : ""}
                                    name={"weight"}
                                    value={item?.weight?.toString() ?? ""}
                                    onChange={(e) => handleEditItem(index, "weight", Number(e))}
                                    type={"text"}
                                />  {!showFields && <strong> {item?.weight}</strong>}
                            </CCol>

                            <CCol style={{ marginTop: -5 }} className={"p-0"} md={1} sm={4} >
                                {showFields &&
                                    <CTooltip content={"Delete"}>
                                        <CIcon
                                            tabIndex={-1}
                                            content={freeSet.cilTrash}
                                            onClick={() => handleDeleteItem(index)}
                                            style={{
                                                height: "1em",
                                                width: "1.5em",
                                                cursor: "pointer",
                                                marginTop: index == 0 ? "1rem" : 0
                                            }}
                                        />
                                    </CTooltip>}
                            </CCol>
                        </CRow>
                    </Fragment>
                )
            })}
        </CCardBody>
    )
}

export default OrderItemDetails;