import { CModal, CModalHeader, CModalBody, CModalFooter, CRow, CCol } from "@coreui/react"
import ActionButton from "../../../SharedComponents/ActionButton"
import PlacesAutocomplete, {
    geocodeByAddress,
} from "react-places-autocomplete";
import { useDispatch } from "react-redux";
import { useState, KeyboardEvent } from "react";
import { SetLoading } from "../../../../store/actions/auth";
interface Props {
    onClose: () => void
    onSubmit: (e: addressGoogleResults) => void
}

export type addressGoogleResults = { long_name: string, short_name: string, types: string[] }[]

const GoogleLookupModal = ({ onClose, onSubmit }: Props) => {
    const dispatch = useDispatch()
    const [searchValue, setSearchValue] = useState<string>("");
    const [results, setResults] = useState<addressGoogleResults>();

    const handleSelect = async (value: string) => {
        setSearchValue(value);
        try {
            const results = await geocodeByAddress(value);
            setResults(results?.[0]?.address_components)
        } catch (error) {
            console.log("error")
        }
        dispatch(SetLoading(false));
    };

    const handleSave = () => {
        results && onSubmit(results)
    }

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleSave();
        }
    };


    return (
        <CModal
            className="text-primary small"
            show={true}
            centered
            onClose={onClose}
            onKeyDown={handleKeyDown}
        >
            <CModalHeader>
                <h5>Google Lookup</h5>
            </CModalHeader>

            <CModalBody>
                <PlacesAutocomplete
                    value={searchValue}
                    onChange={(value) => setSearchValue(value)}
                    onSelect={handleSelect}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div style={{ position: "relative" }}>
                            <label>Search</label>
                            <input
                                {...getInputProps({
                                    placeholder: "Search places",
                                    style: {
                                        width: "100%",
                                        maxHeight: "28px",
                                        padding: "0.375rem 0.75rem",
                                        fontSize: "10px",
                                        borderRadius: "4px",
                                        border: "1px solid #ced4da",
                                        backgroundColor: "#fff",
                                        color: "#495057",
                                    },
                                })}
                            />
                            <div
                                style={{
                                    backgroundColor: "#fff",
                                    color: "primary",
                                    fontSize: "10px",
                                    position: "absolute",
                                    zIndex: "1",
                                    width: "100%",
                                    maxHeight: "200px",
                                    overflow: "auto",
                                    boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)"
                                }}
                            >
                                {loading && <div>Loading...</div>}
                                {!loading &&
                                    suggestions?.map((suggestion, index) => (
                                        <div
                                            {...getSuggestionItemProps(suggestion)}
                                            key={index}
                                            style={
                                                suggestion.active
                                                    ? {
                                                        ...{
                                                            padding: "10px",
                                                            cursor: "pointer",
                                                        }, ...{
                                                            backgroundColor: "#deebff",
                                                        }
                                                    }
                                                    : {
                                                        padding: "10px",
                                                        cursor: "pointer",
                                                    }
                                            }
                                        >
                                            {suggestion.description}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
            </CModalBody>
            <CModalFooter>
                <CRow className="justify-content-between w-100">
                    <CCol className="text-right">
                        <ActionButton
                            disabled={!results}
                            text={"Ok"}
                            onClick={() => handleSave()}
                        />
                    </CCol>
                </CRow>
            </CModalFooter>
        </CModal>
    )
}
export default GoogleLookupModal;