import { UserAppModel } from "../../models/app";

const userState: UserAppModel = {
  usersSearch: {
    count: 0,
    isLoading: false,
    limit: 10,
    page: 0,
    result: [],
    filterGroup: {
      page: 0,
      limit: 10,
      filters: [],
    },
  },
};

export default userState;
