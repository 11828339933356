import ResetPasswordContainer from "./ResetPasswordContainer";
import {Formik, useFormik} from "formik";
import * as yup from 'yup';
import AuthClient from "../../../clients/authClient";
import {CButton, CCol} from "@coreui/react";
import SWInput from "../../SharedComponents/SWInput";
import React, {useState} from "react";
import {useHistory, useLocation} from "react-router";
import {LogOut} from "../../../store/actions/auth";
import {useDispatch} from "react-redux";
import cookieManager from "../../../services/cookieManager";

type Props = {
    token: string,
    accountId: string
}
const EnterNewPassword = ({token, accountId}: Props) => {
    const authClient = new AuthClient();
    const his = useHistory()
    const dispatch=useDispatch()


    const [loader, setLoader] = useState(false)
    const {values, setFieldValue, errors, handleSubmit} = useFormik({
        initialValues: {
            password: '',
            confirmPassword: ''
        },
        onSubmit: async (v) => {
            setLoader(true)
            const value = {
                token,
                newPassword: v.password
            }
            const res = await authClient.PasswordReset(accountId,value)
            if(res.succeeded){
                dispatch(LogOut())
                his.push('/Login')
            }
            setLoader(false)



        },
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
            password: yup.string().min(8, 'minimum is 8 digit'),
            confirmPassword: yup.string().min(8, 'minimum is 8 digit').test(
                'confirm password',
                'Password is not match',
                function (value) {
                    return value == this.parent.password;
                }
            ),

        })

    })

    return (
        <ResetPasswordContainer>
            <ResetPasswordContainer.Title>
                Enter your new Password
            </ResetPasswordContainer.Title>
            <ResetPasswordContainer.Body>
                <>
                    <CCol style={{rowGap: '8px'}}
                          className={'d-flex justify-content-center align-items-center flex-column'}>
                        <SWInput style={{width: '300px'}} type={'password'} value={values.password} name={'password'}
                                 label={'New Password'}
                                 onChange={(e) => setFieldValue('password', e)}
                                 validationErrors={errors.password}/>
                        <SWInput style={{width: '300px'}} type={'password'} value={values.confirmPassword}
                                 name={'confirmPassword'}
                                 label={'Confirm new password'}
                                 onChange={(e) => setFieldValue('confirmPassword', e)}
                                 validationErrors={errors.confirmPassword}/>
                        <CButton disabled={loader}
                                 style={{width: '300px'}}
                                 className="mr-2"
                                 color="primary" onClick={handleSubmit}>
                            Send
                        </CButton>
                    </CCol>
                </>
            </ResetPasswordContainer.Body>
        </ResetPasswordContainer>
    )
}

export default EnterNewPassword
