import {CCard, CCardBody, CCardHeader, CCol, CContainer, CRow} from "@coreui/react";

type Props = {
    children: React.ReactNode
}

const ResetPasswordContainer = (props: Props) => {

    return (
        <div className="c-app c-default-layout flex-row align-items-center justify-content-center">
            <CContainer className={'d-flex justify-content-center align-items-center'}>
                <CCol lg={8} >
                <CCard className="p-5 shadow-lg">
                    {props.children}
                </CCard>
                </CCol>
            </CContainer>


        </div>
    )
}
type SectionProps = {
    children: React.ReactNode;
};
ResetPasswordContainer.Title = ({children}: SectionProps) => (
    <CCardHeader>
        <CCol  className="w-100 d-flex justify-content-center align-items-center">
            <div className={'font-xl text-primary'}>
                {children}
            </div>
        </CCol>
    </CCardHeader>
);

ResetPasswordContainer.Body = ({children}: SectionProps) => (
    <CCardBody>
        <CCol>{children}</CCol>
    </CCardBody>
);


export default ResetPasswordContainer
