import {CButton, CCol, CModal, CModalBody, CModalFooter, CModalHeader, CRow, CButtonGroup} from "@coreui/react";
import React from "react";
import ActionButton from "../../../SharedComponents/ActionButton";

interface Props {
    totalShipments?: number
    selectedUids: string[]
    onClose: () => void
    onSubmitSelected: () => void
    onSubmitAll: () => void
}

const ConfirmModal = (props: Props) => {
    return (
        <div className="small text-primary">
            <CModal
                show={true}
                size={"lg"}
                onClose={props.onClose}
                centered
            >
                <CModalHeader>
                    <h6>Recalculate Route</h6>
                </CModalHeader>
                <CModalBody>
                    Are you sure you want to recalculate routes
                </CModalBody>
                <CModalFooter>
                    <CRow className="justify-content-between w-100">
                        <CCol className="text-left">
                            <CButton
                                style={{fontSize: "10px"}}
                                variant="outline"
                                color="danger"
                                onClick={props.onClose}
                            >
                                Cancel
                            </CButton>
                        </CCol>
                        <CCol className="text-right">
                            <CButtonGroup >
                                <CButton
                                    className="mr-2"
                                    style={{fontSize: "10px"}}
                                    variant="outline"
                                    color="primary"
                                    onClick={() => props.onSubmitAll()}
                                >
                                    Recalculate All <strong>{props.totalShipments}</strong> shipments
                                </CButton>
                                <CButton
                                    className="mr-2"
                                    style={{fontSize: "10px"}}
                                    variant="outline"
                                    color="primary"
                                    disabled={props.selectedUids.length==0}
                                    onClick={() => props.onSubmitSelected()}
                                >
                                    Recalculate Selected <strong>{props.selectedUids.length == 0 ? " ": props.selectedUids.length } </strong> shipments
                                </CButton>
                                
                            </CButtonGroup>

                        </CCol>
                    </CRow>
                </CModalFooter>
            </CModal>
        </div>

    )
}

export default ConfirmModal