import { CCol, CContainer, CRow } from "@coreui/react";
import { Form, Input, Submit } from "../../SharedComponents/SWForm";
import React, {useEffect, useState} from "react";
import ShipClient from "../../../clients/shipClient";
import { useHistory } from "react-router";
import { notEmpty } from "../../../utils/commonValidators";
import ActionButton from "../../SharedComponents/ActionButton";

const CreateContainer = () => {
  const shipClient = new ShipClient();
  const hist = useHistory();

    useEffect(()=> {
        loadLookups();
    },[]);

    const [containerTypes, setContainerTypes] = useState<{ [k: string]: string; }>({});
    const loadLookups = async () => {

        const types =  await shipClient.GetContainerTypes(null, true) as { [k: string]: string; }
        setContainerTypes(types);

    };

  return (
    <CContainer className="bg-white p-4 text-primary small">
      <h5 style={{color:"gray"}}>Create Container</h5>
      <Form
        className="my-4"
        onSubmit={async (body) =>
          shipClient.CreateContainer(body).then((v) => {
            if (v) {
              hist.push(`/containers/${v}?isEdit=true`);
            }
          })
        }
      >
        <CRow>
            <CCol>
                <Input
                    label="Type"
                    type="typeahead"
                    field="type"
                    lookUp={containerTypes}
                />
            </CCol>
          <CCol>
            <Input
              label="Number"
              type="text"
              field="number"
              validators={[notEmpty]}
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
              <ActionButton
                  text={"Create"}
              />
          </CCol>
        </CRow>
      </Form>
    </CContainer>
  );
};

export default CreateContainer;
