import React from "react";
import "bs-stepper/dist/css/bs-stepper.min.css";
import Stepper from "bs-stepper";
import { CContainer } from "@coreui/react";

interface IProps {
  draftDate?: string;
  pendingDate?: string;
  invoicedDate?: string;
  paidDate?: string;
  quotationId: string;
  invoiceSentOn:string;
  invoiceNumber: string;
  // dueDate:string;
}

const Component = (props: IProps) => {
  const renderStep = (
    number: string,
    title: string,
    subTitle?:string,
    msg?: string,
    active?: boolean,
    final?: boolean
  ) => (
    <>
      <div className={`step'}`} data-target="#test-l-1">
        <CContainer className="p-1 d-flex flex-row align-items-center">
          <span className={`bs-stepper-circle  ${active ? "bg-primary " : "bg-secondary"}`}>{number} </span>
          <div>
            <div>
              <span className={"font-weight-bold text-primary"}>{subTitle}</span>
              <span className={`bs-stepper-label m-0 ml-1 ${active ? "text-primary " : "text-secondary"}`}>{title} </span>
            </div>
            <div>
              <span className={"small"}>{msg}</span>
            </div>
          </div>

        </CContainer>
      </div>
      {!final && (
        <div
          className={`line  ${active ? "bg-primary " : "bg-secondary"}`}
        ></div>
      )}
    </>
  );

  return (
    <>
      <div id="stepper1" className="bs-stepper" style={{ fontSize: ".8rem" }}>
        <div className="bs-stepper-header">
          {renderStep("1", "Draft", props.quotationId,props.draftDate, Boolean(props.draftDate))}
          {renderStep("2", "Pending", "", "",Boolean(props.pendingDate))}
          {renderStep("3", "Invoiced",props.invoiceNumber,props.invoicedDate, Boolean(props.invoicedDate))}
          {renderStep("4", "Invoice Sent","",props.invoiceSentOn, Boolean(props.invoiceSentOn))}
          {renderStep( "5","Paid","", props.paidDate,Boolean(props.paidDate),true)}
        </div>
      </div>
    </>
  );
};

export default Component;
