import React, {useState} from 'react';
import {CButton, CCol, CModal, CModalBody, CModalFooter, CModalHeader, CRow} from "@coreui/react";
import DateAndTimePicker from "../../../SharedComponents/SWForm/DateAndTimePicker";
import ActionButton from "../../../SharedComponents/ActionButton";


interface IProps{
    onClose:()=>any
    onSubmit:() => any
}

const Component = (props:IProps) => {
    const [disabled, setDisabled] = useState(false);
  const onClick = () => {
    setDisabled(true);
    props.onSubmit()
    
  }

    return (
        <div className="small">
            <CModal
            show={true}
            onClose={props.onClose}
        >
            <CModalHeader>
                <h6>Are you sure you want to delete the booking?</h6>
            </CModalHeader>
            <CModalBody>
                This action will delete this booking.
            </CModalBody>
            <CModalFooter>
                <CRow className="justify-content-between w-100">
                    <CCol className="text-left">
                        <CButton
                        style={{fontSize:"10px"}}
                            variant="outline"
                            color="danger"
                            onClick={props.onClose}
                        >
                            Cancel
                        </CButton>
                    </CCol>
                    <CCol className="text-right">
                        <ActionButton
                            text={"Yes"}
                            disabled={disabled}
                            onClick={onClick}
                        />
                    </CCol>
                </CRow>
            </CModalFooter>
        </CModal>
        </div>

    )
}

export default Component;