import {AllocationStrategy, AllocationType, DimensionUnit, WeightUnit} from "../models/warehouse";

export const getUnitLoookup = () : { [k: string]: string }=>{
    return{
        [DimensionUnit.in.toString()]:DimensionUnit[DimensionUnit.in],
        [DimensionUnit.cm.toString()]:DimensionUnit[DimensionUnit.cm],
        [DimensionUnit.M.toString()]:DimensionUnit[DimensionUnit.M],

    }
}
export const getWeightUnitLoookup = () : { [k: string]: string }=>{
    return{
        [WeightUnit.kg.toString()]:WeightUnit[WeightUnit.kg],
        [WeightUnit.gm.toString()]:WeightUnit[WeightUnit.gm],
        [WeightUnit.lb.toString()]:WeightUnit[WeightUnit.lb],
        [WeightUnit.oz.toString()]:WeightUnit[WeightUnit.oz],
    }
}
export const getAllocationStrategyLoookup = () : { [k: string]: string }=>{
    return{
        [AllocationStrategy.None.toString()]:AllocationStrategy[AllocationStrategy.None],
        [AllocationStrategy.BatchNumber.toString()]:AllocationStrategy[AllocationStrategy.BatchNumber],
        [AllocationStrategy.ExpiryDate.toString()]:AllocationStrategy[AllocationStrategy.ExpiryDate],
        [AllocationStrategy.Serial.toString()]:AllocationStrategy[AllocationStrategy.Serial],
    }
}
export const getAllocationTypeLoookup = () : { [k: string]: string }=>{
    return{
        [AllocationType.Fifo.toString()]:AllocationType[AllocationType.Fifo],
        [AllocationType.Lifo.toString()]:AllocationType[AllocationType.Lifo],
    }
}
