import React from "react";
import { CDataTable, CTooltip } from "@coreui/react";
import { BatchShipmentGenerateRequest, PendingPrintingDetail } from "../../../../../models/shipment";
import { freeSet } from "@coreui/icons";
import CIcon from "@coreui/icons-react";


interface IProps {
    shipments: PendingPrintingDetail[]
    loading: boolean,
    handleGenerate: (data: BatchShipmentGenerateRequest) => void
    account: string
    agent: string
    hasCod: boolean | undefined
}

const PendingDetailsInnerTable = (props: IProps) => {
    const { shipments, loading, handleGenerate, account, agent, hasCod } = props;
    const scopedSlots = {
        skuCode: (item: PendingPrintingDetail) => {
            return <td>{item?.skuCode ?? ""}</td>
        },
        skuName: (item: PendingPrintingDetail) => {
            return <td>{item?.skuName ?? ""}</td>
        },
        hasCod: (item: PendingPrintingDetail) => {
            return <td>{item?.hasCod ?? ""}</td>
        },
        numberOfShipments: (item: PendingPrintingDetail) => {
            return <td>{item?.numberOfShipments ?? ""}</td>
        },
        generate: (item: PendingPrintingDetail) => {
            return <td>
                <CTooltip content="Generate">
                    <CIcon
                        content={freeSet.cilDescription}
                        onClick={() => {
                            handleGenerate({
                                account: account,
                                hasCod: hasCod,
                                agent: agent,
                                skuCode: item?.skuCode
                            })
                        }}
                        style={{ cursor: "pointer" }}
                    />
                </CTooltip>
            </td>
        },
        viewDetails: (item: PendingPrintingDetail, index: number) => {
            return <td className={""}>

            </td>
        },
    }
    return (
        <div className="" style={{ overflowY: "scroll", overflowX: "scroll", maxHeight: "350px", zIndex: 0 }}>
            <CDataTable
                loading={loading}
                size="sm"
                hover
                scopedSlots={scopedSlots}
                items={shipments}
                fields={[
                    { key: 'viewDetails', label: "",_style:{width:"5%"  }},
                    { key: "skuName", label: "Sku Name",_style:{width:"25%"} },
                    { key: "numberOfShipments", label: "Number of shipments" ,_style:{width:"15%"  }},
                    { key: "generate", label: "Actions" ,_style:{width:"5%"  }}
                ]} 

            />
        </div>
    );
};

export default PendingDetailsInnerTable;
