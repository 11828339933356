import {
  CButton,
  CButtonGroup,
  CContainer,
  CNav,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CTabs,
  CTooltip,
} from "@coreui/react";
import {Form} from "../../SharedComponents/SWForm";
import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import {useComplexState} from "../../../hooks/helperHooks";
import ShipClient from "../../../clients/shipClient";

import {ContainerType} from "../../../models/containertype";
import ContainerTypeInputs from "./components/ContainerTypeInputs";
import AddEditField from "./components/AddEditItem";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import ActionButton from "../../SharedComponents/ActionButton";

interface Props {}

const ViewContainerType = (props: Props) => {
  const { id } = useParams() as any;

  const hist = useHistory();
  const [
    containertype,
    setContainerTypeProp,
    setContainerType,
  ] = useComplexState<ContainerType>({});
  const [inEditMode, setInEditMode] = useState(false);

  const shipClient = new ShipClient();

  const saveChanges = async () => {
    shipClient.UpdateContainerType(id, containertype).then((v) => {
      setInEditMode(false);
    });
  };

  useEffect(() => {
    shipClient.GetContainerType(id).then((v) => {
      setContainerType(v);
    });
  }, [id, inEditMode]);

  return (
    <CContainer className="bg-white p-4 text-black small">
      <CRow>
        <CTooltip content="Back">
          <CButton>
            <CIcon
                content={freeSet.cilArrowLeft}
                onClick={() => hist.goBack()}
            />
          </CButton>
        </CTooltip>
      </CRow>
      <h5>Container type</h5>
      <CTabs activeTab="basic">
        <CNav variant="tabs">
          <CNavLink data-tab="basic">Basic Information</CNavLink>
          <CNavLink data-tab="customFields">Custom Fields</CNavLink>
        </CNav>

        <CTabContent>
          <CTabPane className="my-3" data-tab="basic">
            <Form
            
              readonly={!inEditMode}
              className="mt-5 small"
              initialState={containertype}
              onSubmit={async (body) => saveChanges()}
            >
              <ContainerTypeInputs />
            </Form>
          </CTabPane>
          <CTabPane className="my-3" data-tab="customFields">
            <AddEditField
              readonly={!inEditMode}
              items={containertype.additionalFields}
              onDelete={(index) => {
                setContainerType({
                  ...containertype,
                  additionalFields: containertype.additionalFields.filter(
                    (v, idx) => idx !== index
                  ),
                });
              }}
              onAdd={(body) =>
                setContainerType({
                  ...containertype,
                  additionalFields: [...containertype.additionalFields, body],
                })
              }
              onEdit={(id, body) => {
                const newItems = [...containertype.additionalFields];
                newItems[id] = body;
                setContainerType({
                  ...containertype,
                  additionalFields: newItems,
                });
              }}
            />
          </CTabPane>
        </CTabContent>
      </CTabs>
      <CButtonGroup>
        <CButton
        style={{fontSize:"10px"}}
          variant="outline"
          color="primary"
          onClick={() => setInEditMode(!inEditMode)}
          className="mr-2"
        >
          {inEditMode ? "Cancel" : "Edit"}
        </CButton>
        {inEditMode && (
          <ActionButton
          text={"Save"}
          disabled={!inEditMode}
          onClick={() => inEditMode && saveChanges()}
          />
        )}
      </CButtonGroup>
    </CContainer>
  );
};

export default ViewContainerType;
