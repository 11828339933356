import React from "react";
import { CCard, CCol, CContainer, CLink, CNav, CNavLink, CRow, CTabContent, CTabPane, CTabs } from "@coreui/react";
import Shipment from "./Shipment";
import Attachments from "./attachments";
import SWInput from "../../SharedComponents/SWInput";
import { useFormik } from "formik";
import { calculateTotalWeight, CreateShipment, createShipmentInitialValues, createShipmentValidationSchema } from "../../../models/shipment";
import { useDispatch, useSelector } from "react-redux";
import { AppModel } from "../../../models/app";
import { setCreateShipmentData } from "../../../store/actions/shipments";
import { useHistory, useLocation } from "react-router";


const Create = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const state = location.state as { uId: number } | undefined;
    const uId = state && state.uId;
    const createShipmentInitialData = useSelector((state: AppModel) => state.shipment?.createShipmentData);


    const formik = useFormik<CreateShipment>({
        enableReinitialize: true,
        initialValues: createShipmentInitialValues(createShipmentInitialData),
        onSubmit: async (values) => {
            let totalShipmentWeight = calculateTotalWeight(values?.pieces)?.weight ?? 0.1
            dispatch(setCreateShipmentData({
                ...values,
                pieces: values?.pieces?.map((e) => ({...e,
                    weight: Number(e?.weight) ?? 0,
                    length: Number(e?.length) ?? 0,
                    width:  Number(e?.width) ?? 0,
                    height: Number(e?.height) ?? 0,
                })),
                weight: { value: totalShipmentWeight, unit: 1 },//kg
            }))
            history.push({
                pathname: '/shipments/view_shipment_agents',
            });
        },
        validationSchema: () => createShipmentValidationSchema(formik.values.isDocument)
    })

    return (
        <CContainer className="bg-white pt-0 pb-0 pl-4 pr-4 mt-0 mb-0 text-primary pb-2 pt-2">
            <CRow className="mt-0 mb-0 pt-0 pb-0" style={{ alignItems: "center" }}>
                <CCol>
                    <h5 style={{ color: "gray" }}>New</h5>
                    {uId &&
                        <CLink style={{ textDecoration: "underline" }} to={`/shipments/${uId}`}>last created shipment</CLink>
                    }
                </CCol>
                <CCol>
                    <SWInput
                        labelClassName='m-0 small'
                        label={"Hawb"}
                        name={"number"}
                        value={formik?.values?.number ?? ""}
                        onChange={(e) => formik?.setFieldValue("number", e?.trim())}
                        type={"text"}
                        validationErrors={formik?.touched?.number && formik?.errors?.number?.toString() || ""}
                    />
                </CCol>
            </CRow>

            <CTabs>
                <CCard>
                    <CNav variant="tabs">
                        <CNavLink className={"small"}>Booking</CNavLink>
                         {/* <CNavLink className={"small"}>Customs</CNavLink> */}
                        <CNavLink className={"small"}>Attachments</CNavLink>
                    </CNav>
                    <CTabContent>
                        <CTabPane>
                            <Shipment formik={formik}/>
                        </CTabPane>
                          {/* <CTabPane>
                            <Shipment hawb={hawb} isCustom />
                        </CTabPane> */}
                        <CTabPane>
                            <Attachments formik={formik} />
                        </CTabPane>
                    </CTabContent>
                </CCard>
            </CTabs>
        </CContainer>
    );
};

export default Create;

