import React, { useEffect, useState } from "react";
import { CButton, CButtonGroup, CCol, CContainer, CLink, CRow } from "@coreui/react";
import ActionButton from "../../../SharedComponents/ActionButton";
import { useFormik } from "formik";
import * as yup from "yup"
import SWSelect from "../../../SharedComponents/SWSelect";
import SWInput from "../../../SharedComponents/SWInput";
import { RateSearchRequest, SearchRateCardModel, SellingSearch } from "../../../../models/retes";
import { useDispatch } from "react-redux";
import { SetIsLoading } from "../../../../store/actions/ui";
import RatesClient from "../../../../clients/ratesClient";
import { ShowNotification } from "../../../../store/actions/auth";
import { setSearchSelling } from "../../../../store/actions/rates";

interface IProps {
    setData: (arg: Array<SearchRateCardModel>) => void
    initialData?: RateSearchRequest
}


const SearchRateManagement = ({ setData, initialData }: IProps) => {
    const dispatch = useDispatch();
    const ratesClient = new RatesClient();
    const [accounts, setAccounts] = useState<{ [k: string]: string }>({});
    const handleInitialSearchByValues = () => {
        if (!!initialData?.cardName) return "cardName"
        if (!!initialData?.customer) return "customer"
        return undefined
    }
    const handleInitialSearchValue= () => {
        if (!!initialData?.cardName) return initialData?.cardName
        if (!!initialData?.customer) return initialData?.customer
        return ""
    }
    const { values, handleSubmit, setFieldValue, resetForm, errors, touched } = useFormik<SellingSearch>({
        enableReinitialize: true,
        initialValues: {
            searchBy: handleInitialSearchByValues(),
            searchValue: handleInitialSearchValue(),
        },
        onSubmit: async (values) => {
            const mappedValues = values.searchBy ? { [values.searchBy]: values.searchValue } : {}
            dispatch(setSearchSelling(mappedValues))
            handleSearchSelling(mappedValues)
        },
        validationSchema: yup.object({
            searchBy: yup.string().required("Required"),
            searchValue: yup.string().required("Required"),
        })
    })
    useEffect(() => {
        getAccounts()
    }, []);

    const getAccounts = async () => {
        let accounts = await ratesClient.GetAccounts()
        setAccounts(accounts)
    }

    const handleSearchSelling = async (values: RateSearchRequest) => {
        dispatch(SetIsLoading(true))
        const res = await ratesClient.SearchSelling(values);
        if (res?.succeeded) {
            setData(res?.data)
        } else {
            dispatch(ShowNotification("Error", res?.error ?? "Error", true));
        }
        dispatch(SetIsLoading(false))
    }

    const handleClear = async () => {
        resetForm()
        handleSearchSelling({})
        dispatch(setSearchSelling({}))
    }

    const labelResolver = (key: "cardName" | "customer") => {
        switch (key) {
            case "cardName":
                return "Card";
            case "customer":
                return "Customer"
            default:
                return "Card/Customer";
        }
    }

    return (
        <fieldset className="b-fieldset p-2 text-primary">
            <legend className="b-legend" style={{ width: "60px" }}>
                <h5>Search</h5>
            </legend>
            <CContainer className="form-group mb-0">
                <CRow>
                    <CCol className="col-sm-3">
                        <SWSelect
                            label="Search By"
                            validationErrors={touched?.searchBy && errors?.searchBy?.toString() || ""}
                            name="searchBy"
                            value={values?.searchBy}
                            onChange={(e) => setFieldValue("searchBy", e)}
                            values={[
                                {
                                    label: "Card",
                                    value: "cardName",
                                },
                                {
                                    label: "Customer",
                                    value: "customer",

                                },
                            ]}
                        />
                    </CCol>
                    <CCol className="col-sm-3">
                        {values?.searchBy === "customer" ?
                            <SWSelect
                                label={labelResolver(values?.searchBy)}
                                validationErrors={touched?.searchValue && errors?.searchValue?.toString() || ""}
                                name="searchValue"
                                value={values?.searchValue}
                                onChange={(e) => setFieldValue("searchValue", e)}
                                values={accounts}
                            />
                            :
                            <SWInput
                                label={values?.searchBy ? labelResolver(values?.searchBy) : "Card/Customer"}
                                name={"searchValue"}
                                value={values?.searchValue}
                                onChange={(e) => setFieldValue("searchValue", e?.trim())}
                                type={"text"}
                                validationErrors={touched?.searchValue && errors?.searchValue?.toString() || ""}
                            />
                        }
                    </CCol>
                </CRow>
            </CContainer>
            <CCol className="text-right">
                <CButtonGroup className="mb-0 mr-2">
                    <CButton
                        size="sm"
                        style={{ fontSize: "10px" }}
                        className="mr-2"
                        variant="outline"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Search
                    </CButton>
                    <CButton
                        size="sm"
                        style={{ fontSize: "10px" }}
                        className="mr-2"
                        variant="outline"
                        color="danger"
                        onClick={handleClear}
                    >
                        Clear
                    </CButton>
                </CButtonGroup>
                <CButtonGroup className="mb-0 ">
                    <CLink to={'/financial/addCard?type=selling'} className="card-header-action">
                        <ActionButton text={"Create new card"} />
                    </CLink>
                </CButtonGroup>
            </CCol>
        </fieldset>
    )
}

export default SearchRateManagement