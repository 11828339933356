import React, {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";
import SWFormContext from "./SWFormContext";
import { Action } from "../../../models/app";
import { CForm } from "@coreui/react";
import { FormFields, InputFieldValue } from ".";
import { SWInputFieldValue } from "./model";

interface Props {
  children: JSX.Element[] | JSX.Element;
  className?: string;
  id?: string;
  readonly?: boolean;
  initialState?: any;
  onSubmit: (state: any) => Promise<any> | any;
}

const setValue = (obj: any, path: string, value: any) => {
  
  const split = path.split(".");
  let o = obj;
  while (split.length - 1) {
    const seg = split.shift()!;
    if (!(seg in o)) o[seg] = {};
    o = o[seg];
  }
  if (!o) o = {};
  if (!o) o = {};
  o[split[0]] = value;
};

const getValue = (obj: any, path: string) => {
  
  const split = path.split(".").reverse();
  let o = { ...obj };
  while (split.length - 1) {
    const seg = split.pop();
    o = o[seg!];
    if (!o) return null;
  }
  const value = o[split.pop()!];
  return value;
};

const SWForm = (props: Props, ref: Ref<any>) => {
  const { readonly, children, onSubmit, className, initialState, id } = props;

  const transformBody = (body: FormFields) => {
    const cleanseValue = (val: any) => {
      if (typeof val === "string") {
        return val.trim();
      }
      return val;
    };

    const values = Object.entries(body.values)
      .map(([k, v]) => {
        return [k, cleanseValue(v)];
      })
      .reduce<{ [k: string]: any }>((pv, [k, v]) => {
        pv[k] = v;
        return pv;
      }, {});
    
    
    const attributedVals = Object.keys(body.attributes);
    for (const attributedVal of attributedVals) {
      const val = values[attributedVal];
      if (body.attributes[attributedVal].multifield) {
        values[attributedVal] = (val && val.split("\n")) || val;
      }
    }

    return values;
  };

  const reducer = (state: FormFields, action: Action): FormFields => {
    switch (action.type) {
      case "addOrEdit": {
        const copy = { ...state };
        setValue(
          copy,
          `values.${action.payload!.field}`,
          action.payload!.value
        );
        return copy;
      }
      case "setAttribute": {
        const copy = { ...state };
        setValue(
          copy,
          `attributes.${action.payload!.field}.${action.payload!.attribute}`,
          action.payload!.value
        );
        return copy;
      }
      case "setAllFormValues": {
        return {
          ...state,
          values: { ...state.values, ...action.payload!.formValues },
        };
      }
      case "setValidators": {
        const copy = { ...state };
        setValue(
          copy,
          `validators.${action.payload!.field}`,
          action.payload!.validators
        );
        return copy;
      }
      case "setValidationErrors": {
        const copy = {
          ...state,
        };
        setValue(
          copy,
          `validationErrors.${action.payload!.field}`,
          action.payload!.validationErrors
        );
        return copy;
      }
      default:
        return state;
    }
  };

  const [formState, dispatch] = useReducer(reducer, {
    validationErrors: {},
    validators: {},
    values: {},
    attributes: {},
  } as FormFields);

  // const [dispatch] = useState<any>((args: Action) => {
  //   reducerDispatch(args);
  // });

  useEffect(() => {
    if (initialState) {
      dispatch({
        type: "setAllFormValues",
        payload: {
          formValues: initialState,
        },
      });
    }
  }, [initialState]);

  const validateField = (field: SWInputFieldValue) => {
    const validationErrors: string[] = formState.validators[field]
      ? formState.validators[field!].reduce<string[]>((pv, cv) => {
          const error = cv(formState.values[field]);
          return error ? [...pv, error] : pv;
        }, [])
      : [];
    dispatch({
      type: "setValidationErrors",
      payload: {
        field,
        validationErrors,
      },
    });
  };

  const hasErrors = () => {
    let errors: string[] = [];
    for (const field in formState.values) {
      const validationErrors: string[] = formState.validators[field]
        ? formState.validators[field!].reduce<string[]>((pv, cv) => {
            const error = cv(formState.values[field]);
            return error ? [...pv, error] : pv;
          }, [])
        : [];
      if (validationErrors && validationErrors.length > 0) {
        errors = errors.concat(validationErrors);
      }
    }

    if (errors.length > 0) return true;
    return false;
  };

  const changeValue = (field: string, value: string | number | any[]) => {
    dispatch({
      type: "addOrEdit",
      payload: {
        field,
        value,
      },
    });
  };

  const getFormValue = (field: string) => getValue(formState.values, field);

  const saveForm = async () => {
    if (hasErrors()) {
      for (let val of Object.keys(formState.values)) {
        validateField(val);
      }
      return;
    }

    const rs = await onSubmit(transformBody(formState));
    if (rs)
      dispatch({
        type: "setAllFormValues",
        payload: {
          formValues: rs,
        },
      });
  };

  useImperativeHandle(ref, () => ({
    saveForm,
    changeValue,
    getFormValue,
    formState,
  }));

  return (
    <SWFormContext.Provider
      value={{
        formReadonly: readonly || false,
        formFields: formState,
        hasErrors,
        setAttribute: (field, attribute, value) => {
          dispatch({
            type: "setAttribute",
            payload: {
              field,
              attribute,
              value,
            },
          });
        },
        setValidators: (field, validators) => {
          dispatch({
            type: "setValidators",
            payload: {
              field,
              validators,
            },
          });
        },
        getFormValue,
        validateField,
        changeValue,
      }}
    >
      <CForm
        className={className}
        id={id}
        onSubmit={async (e) => {
          e.preventDefault();
          saveForm();
        }}
      >
        {children}
      </CForm>
    </SWFormContext.Provider>
  );
};

export default forwardRef(SWForm);
