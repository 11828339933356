import {
    CButton,
    CCard,
    CCol,
    CImg,
    CInputCheckbox, CInputGroup,
    CLabel,
    CNav,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CTabs, CTooltip,
} from "@coreui/react";
import SWSelect from "../../../SharedComponents/SWSelect";
import SWInput from "../../../SharedComponents/SWInput";
import {
    getAllocationStrategyLoookup,
    getAllocationTypeLoookup,
    getUnitLoookup,
    getWeightUnitLoookup,
} from "../../../../utils/lookupUtils";
import React, { useEffect, useState } from "react";
import RatesClient from "../../../../clients/ratesClient";
import { setNestedObjectValues, useFormik } from "formik";
import {
    AllocationStrategy,
    AllocationType,
    DimensionUnit,
    InventoryDetailsReportItem,
    InventoryDetailsReportQueryParameters,
    InventoryTransactionDetailReportItem, OpenReservationDetails,
    SkuCreate, SkuSearch,
    SkuUpdate, warehouse,
    WeightUnit,
} from "../../../../models/warehouse";
import WarehouseClient from "../../../../clients/warehousesClient";
import { useDispatch } from "react-redux";
import { SetLoading, ShowNotification } from "../../../../store/actions/auth";
import { Account } from "../../../../models/account";
import * as yup from "yup";
import SWInputNumber from "../../../SharedComponents/SWInputNumber";
import { useHistory, useParams } from "react-router";
import ConfirmModal from "../../../SharedComponents/ConfirmModal";
import SWMultiSelect from "../../../SharedComponents/SWMultiSelect";
import ExportToExcelButton from "../../../SharedComponents/ExportToExcelButton";
import { RemoteBlob } from "../../../../models/app";
import SWTextArea from "../../../SharedComponents/SWTextArea";
import PageCount from "../../../SharedComponents/PageCount";
import { IPaginatedResult } from "../../../../models/filter";
import CIcon from "@coreui/icons-react";
import { freeSet } from "@coreui/icons";
import { formatDate, formatDateTime } from "../../../../utils/dateUtil";
import ActionButton from "../../../SharedComponents/ActionButton";
import UpdateImageModal from "./modals/UpdateImage";


const CreateUpdateSku = () => {

    const ratesClient = new RatesClient();
    const warehouseClient = new WarehouseClient();
    const {id} = useParams() as any;
    const hist = useHistory();

    useEffect(() => {
        loadLookups();
        sellersLookups();
    }, []);

    const [quantitiesReport, setQuantitiesReport] = useState<InventoryDetailsReportItem[]>([])
    const [transactionHistory, setTransactionHistory] = useState<IPaginatedResult<InventoryTransactionDetailReportItem>>()
    const [openReservations, setOpenReservations] = useState<IPaginatedResult<OpenReservationDetails>>()
    const [subTypeLookups, setSubTypeLookups] = useState<{[K: string]: string}>()
    const [allowedSellerCodes, setAllowedSellerCodes] = useState<string[]>()
    const [allowedWarehouseIds, setAllowedWarehouseIds] = useState<number[]>()
    const [selectedWarehouses, setSelectedWarehouses] = useState<{value: string, label: string}[]>([])
    const [transactionPage, setTransactionPage] = useState<number>(0)
    const [openTransactionPage, setOpenTransactionPage] = useState<number>(0)
    const [warehouseValidator, setWarehouseValidator] = useState<boolean>(false)
    const [transactionHistoryPageSize, setTransactionHistoryPageSize] = useState<number>(20)
    const [openTransactionPageSize, setOpenTransactionPageSize] = useState<number>(20)
    const [updateImageModal, setUpdateImageModal] = useState<boolean>(false);

    const dispatch = useDispatch();

    const [accountsLookup, setAccountsLookup] = useState<{
        [k: string]: string;
    }>();
    const [sellersLookup, setSellersLookup] = useState<{ [k: string]: string }>();
    const [fullWarehouse, setFullWarehouse] = useState<warehouse[]>()
    const [warehouseLookup, setWarehouseLookup] = useState<{
        [k: string]: string;
    }>({});
    const [activationModal, setActivationModal] = useState<boolean>(false)
    const [activationTitle, setActivationTitle] = useState<string>("Deactivate")
    const [loadedSku, setLoadedSku] = useState<SkuSearch>()

    const sellersLookups = async () => {
        const sellers = await warehouseClient.SellersLookup();
        setSellersLookup({null: " None", ...sellers});
    };
    const loadLookups = async () => {


        // const warehouses = await warehouseClient.warehousesLookup();
        // setWarehouseLookup(warehouses);
        const customers = await ratesClient.LookupCustomers();
        setAccountsLookup(customers);
        const subTypes = await warehouseClient.getSubTypesLookup()
        setSubTypeLookups(subTypes)

    };

    const {
        setTouched,
        setValues,
        values,
        errors,
        handleBlur,
        touched,
        handleChange,
        resetForm,
        setFieldValue,
        handleSubmit,
    } = useFormik<SkuCreate>({
        initialValues: {
            imageUrl:'',
            imageUrls: [],
            expiryDays: null,
            warehouse: "",
            barcode: "",
            id: 0,
            name: "",
            accountNumber: "",
            active: null,
            allocationStrategy: AllocationStrategy.None,
            allocationType: AllocationType.Fifo,
            code: "",
            dimensions: {
                height: null,
                length: null,
                width: null,
                unit: DimensionUnit.cm,
            },
            internalCode: '',
            breakCount: 1,
            minReorder: 0,
            seller: null,
            weight: {
                value: null,
                unit: WeightUnit.kg,
            },
        },
        onSubmit: async (values) => {

            dispatch(SetLoading(true));
            let client = new WarehouseClient();
            let res;

            // @ts-ignore
            let warehouses: warehouse[] =
                selectedWarehouses.map(w => {
                    return fullWarehouse?.find(v => v.code == w.value)
                })
            let allowedWarehouses = warehouses.map(w => {
                return w.id
            })
            let barcodes = values.barcode.split("\n");
            let req = {...values, allowedWarehouses, barcodes}

            if (id) res = await client.UpdateSKU(id, req as SkuUpdate);
            else res = await client.CreateSKU(req);
            dispatch(SetLoading(false));

            if (!res.succeeded)
                return;

            if (id) {
                dispatch(ShowNotification("Success ", "Sku updated Successfully", false));
                hist.push(`/stock/skus`);
                return;
            } else {
                dispatch(ShowNotification("Success ", "Sku added Successfully", false));
                resetForm();
            }

        },
        validationSchema: yup.object({
            code: yup.string().max(20),
            name: yup.string().max(100),
            accountNumber: yup.string().required("Required"),
            breakCount: yup.number().required("Required"),
            minReorder: yup.number().required("Required"),
            weight : yup.object({
                value: yup.string().nullable().required("Required")
            })
        }),
    });

    const getTransactionHistory = async (code : string, account:string) => {
        const res = await warehouseClient.inventoryTransactionDetailsReport({
            sku: code,
            account: account,
            limit: transactionHistoryPageSize,
            offset: transactionPage * 10
        });
        // @ts-ignore
        setTransactionHistory(res)

    }
    const getOpenReservations = async (code : string, account:string) => {
        const res = await warehouseClient.openReservations({
            skuCode: code,
            account: account,
            limit:openTransactionPageSize,
            offset: openTransactionPage * 10
        })
        setOpenReservations(res);
    }

    useEffect(() => {
        getOpenReservations(loadedSku?.code?? "", loadedSku?.accountNumber?? "")
    }, [openTransactionPage, openTransactionPageSize])

    useEffect(() => {
        getTransactionHistory(loadedSku?.code?? "", loadedSku?.accountNumber?? "")
    }, [transactionPage, transactionHistoryPageSize])

    const getWarehouseCodes = async (ids?: number[]) => {
        if (!ids) return
        let client = new WarehouseClient()
        let warehouses : warehouse[] = await client.searchWarehouse()
        let allowed = warehouses.filter(w => ids.indexOf(w.id) !== -1)
        setFullWarehouse(allowed)
        let codes = allowed.map(a => a.code)
        let res : {value: string, label: string}[] = []
        codes.forEach(code => {
            res.push({value: code, label:code})
        })
        setSelectedWarehouses(res)
    }


    const save = () => {

        if (Object.keys(errors).length > 0) {
            setTouched(setNestedObjectValues(errors, true));

        }
        if (selectedWarehouses.length == 0) {
            setWarehouseValidator(true)
            return
        }
        handleSubmit();
    }
    const getAccount = async () => {

        let res = await warehouseClient.GetAccount(values.accountNumber);
        if (res?.allowedSellers)
            setAllowedSellerCodes(res.allowedSellers)
        if (res?.allowedWarehouses)
            setAllowedWarehouseIds(res.allowedWarehouses)
    }
    useEffect(() => {
        if (values.accountNumber) {

            (async () => await getAccount())()
        }
    }, [id, values.accountNumber]);

    useEffect(() => {
        if (id) {
            setSku();
        }
    }, [id]);


    useEffect(() => {
        if (values.active) setActivationTitle("Deactivate")
        else setActivationTitle("Activate")
    }, [values.active])

    useEffect(() => {
        SetAllowedWarehouses()
    }, [allowedWarehouseIds])

    useEffect(() => {
        SetAllowedSellers()
    }, [allowedSellerCodes])

    const loadQuantities = async (code : string, account:string) => {
        let req: InventoryDetailsReportQueryParameters = {
            account: account,
            code: code,
        }
        const rs = await warehouseClient.inventoryDetailsReport(req)
        setQuantitiesReport(rs)
    }

    const setSku = async () => {
        let client = new WarehouseClient();
        let sku = await client.getSku(id);

        await getWarehouseCodes(sku.allowedWarehouses)

        await loadQuantities(sku.code,sku.accountNumber)

        setLoadedSku(sku)
        await setValues({ ...sku,imageUrls:!!sku?.imageUrl ? [sku?.imageUrl] : [] });
        await setFieldValue("barcode", sku.barcodes?.join("\n"))


    };

    const HandleAccountChange = async (val: string | undefined) => {
        await setFieldValue("accountNumber", val)

        if (val != undefined) {
            let account = await warehouseClient.GetAccount(val);

            let sellerCode = account.defaultSellerCode;
            await setFieldValue("seller", sellerCode)
        }
    }

    const handleActivation = async () => {
        if (values.active) {
            warehouseClient.activateSku(values.code, values.accountNumber).then(res => {
                if(res.succeeded)
                    window.location.reload()
            });
        } else {
            warehouseClient.deactivateSku(values.code, values.accountNumber).then(res => {
                if(res.succeeded)
                    window.location.reload()
            });
        }
    };

    const SetAllowedWarehouses = async () => {
        let client = new WarehouseClient()
        let warehouses : warehouse[] = await client.searchWarehouse()
        let allowed = warehouses.filter(w => allowedWarehouseIds?.indexOf(w.id) !== -1 )
        setFullWarehouse(allowed)
        let lookup : {[K: string]: string} = {}
        allowed.forEach(a => lookup[a.code] = a.name)
        setWarehouseLookup(lookup)
    }

    const SetAllowedSellers = async () => {
        let client = new WarehouseClient()
        let sellers : {[K : string] : string} = await client.SellersLookup()
        let lookup: {[K : string] : string} = {}
            allowedSellerCodes?.forEach(s => {
                lookup[s] = sellers[s]
            })
            setSellersLookup(lookup)
    }

    // @ts-ignore
    const HandleExcelExport :() => Promise<RemoteBlob> = async () => {
        let client = new WarehouseClient()
        return await client.inventoryTransactionDetailsReportExcel({
            account:loadedSku?.accountNumber?? "",
            sku:loadedSku?.code?? ""
        })
    }

    return (
        <CCard className="bg-white p-4 text-black">
            <CRow>
                <CCol md={2}>
                    <CTooltip content="Back">
                        <CButton>
                            <CIcon
                                content={freeSet.cilArrowLeft}
                                onClick={() => hist.goBack()}
                            />
                        </CButton>
                    </CTooltip>
                </CCol>
                <CCol />
                <CCol md={2}>
                    <CInputGroup>
                        <CInputCheckbox
                            style={{ cursor: "pointer" }}
                            checked={values.active?? undefined}
                            onClick={() => setActivationModal(true)}
                        />
                        <CLabel className="text-primary">Active</CLabel>
                    </CInputGroup>
                </CCol>
            </CRow>
            <CRow className={"col-md-12"}>
                <CCol md={9}>
                    <CRow>
                        <CCol md={4}>
                            <SWSelect readonly={Boolean(id)} onBlur={handleBlur}
                                validationErrors={touched.accountNumber && errors.accountNumber || ""} label={"Account"}
                                values={accountsLookup as { [k: string]: string }} value={values.accountNumber}
                                name={"accountNumber"} onChange={HandleAccountChange}/>
                        </CCol>
                        <CCol md={4}>
                            <SWMultiSelect
                                value={selectedWarehouses}
                                label={"Allowed Warehouses"}
                                values={warehouseLookup}
                                name="allowedWarehouseCodes"
                                onChange={(e: {value: string, label: string}[]) => {
                                    setSelectedWarehouses(e)
                                    if (e.length == 0)
                                        setWarehouseValidator(true)
                                    else
                                        setWarehouseValidator(false)
                                }}
                                validationErrors={warehouseValidator && "please select at least one warehouse" || ""}
                            />
                        </CCol>


                        <CCol md={4}>
                            <SWSelect
                                onBlur={handleBlur}
                                validationErrors={(touched.seller && errors.seller) || ""}
                                label={"Seller"}
                                values={sellersLookup as { [k: string]: string }}
                                value={values.seller}
                                name={"seller"}
                                isClearable={true}
                                onChange={(e) => setFieldValue("seller", e)}
                            />
                        </CCol>

                        <CCol md={4}>
                            <SWInput
                                readonly={Boolean(id)}
                                onBlur={handleBlur}
                                validationErrors={(touched.code && errors.code) || ""}
                                label={"Code"}
                                name={"code"}
                                value={values.code}
                                onChangeFormik={handleChange}
                            />
                        </CCol>

                        <CCol md={4}>
                            <SWInput
                                onBlur={handleBlur}
                                validationErrors={(touched.name && errors.name) || ""}
                                label={"Name"}
                                name={"name"}
                                value={values.name}
                                onChangeFormik={handleChange}
                            />
                        </CCol>
                        <CCol md={2}>
                            <SWInput
                                onBlur={handleBlur}
                                label={"Internal Code"}
                                name={"internalCode"}
                                value={values.internalCode}
                                onChangeFormik={handleChange}
                            />
                        </CCol>
                        <CCol md={2}>
                            <SWTextArea
                                // readonly={Boolean(id)}
                                onBlur={handleBlur}
                                validationErrors={(touched.barcode && errors.barcode) || ""}
                                value={values.barcode}
                                label={"Barcodes"}
                                name={"barcode"}
                                onChangeFormik={handleChange}
                            />
                        </CCol>
                    </CRow>
                </CCol>
                <CCol style={{ display: "flex", justifyContent: "center", alignItems: "center", border: "2px solid lightGray", borderRadius: '10px' }} >
                    <CImg
                        style={{ cursor: "pointer", width: 200, padding: 2, height: 200, objectFit: 'contain', borderRadius: '10px' }}
                        onClick={() => window.open(values?.imageUrl, '_blank', 'noreferrer')}
                        src={`${Boolean(values?.imageUrl) ? values?.imageUrl : '/placeholder-image.png'}`}
                    />
                    <div
                        className="text-primary"
                        style={{
                            position: 'absolute',
                            top: '0.5rem',
                            right: '0.5rem',
                            cursor: 'pointer',
                            zIndex: 1,
                        }}
                        onClick={() => { }}
                    >
                        {Boolean(values?.imageUrl) &&
                            <>
                                <CTooltip content={"Edit"}>
                                    <CIcon content={freeSet.cilPencil}
                                        size={"lg"}
                                        style={{ cursor: "pointer", marginRight: "5px" }}
                                        onClick={() => { setUpdateImageModal(true) }} />
                                </CTooltip>
                                <CTooltip content={"Delete"}>
                                    <CIcon
                                        size={"lg"}
                                        style={{ cursor: "pointer" }} content={freeSet.cilTrash} onClick={() => {
                                            setFieldValue('imageUrls', [])
                                            setFieldValue('imageUrl', null)
                                        }} />
                                </CTooltip>
                            </>}
                        {!Boolean(values?.imageUrl) &&
                            <CTooltip content={"Add"}>
                                <CIcon
                                    size={"xl"}
                                    style={{ cursor: "pointer" }} content={freeSet.cilPlus} onClick={() => { setUpdateImageModal(true) }} />
                            </CTooltip>
                        }
                    </div>
                </CCol>
            </CRow>

            <CRow className={"col-md-12"}>
                <CCol md={6} className={"h6"}>
                    Weight
                </CCol>
                <CCol md={6} className={"h6"}>
                    Dimensions
                </CCol>
                <CCol md={1}>
                    <SWInputNumber
                        onBlur={handleBlur}
                        validationErrors={
                            (touched.weight?.value && errors.weight?.value) || ""
                        }

                        // label={"Value"}
                        name={"weight.value"}
                        value={values.weight?.value ?? undefined}
                        onChangeFormik={handleChange}
                    />
                </CCol>
                <CCol md={2}>
                    <SWSelect
                        values={getWeightUnitLoookup() as { [k: string]: string }}
                        value={values.weight?.unit?.toString()}
                        name={"weight.unit"}
                        onChange={(e) => setFieldValue("weight.unit", e)}
                    />
                </CCol>

                <CCol md={3}/>
                <CCol md={1}>
                    <SWInputNumber
                        onBlur={handleBlur}
                        validationErrors={
                            (touched.dimensions?.width && errors.dimensions?.width) ||
                            ""
                        }

                        // label={"Width"}
                        name={"dimensions.width"}
                        value={values.dimensions?.width ?? undefined}
                        onChangeFormik={handleChange}
                    />
                </CCol>
                <CCol md={1}>
                    <SWInputNumber
                        onBlur={handleBlur}
                        validationErrors={
                            (touched.dimensions?.height && errors.dimensions?.height) ||
                            ""
                        }

                        // label={"Height"}
                        name={"dimensions.height"}
                        value={values.dimensions?.height ?? undefined}
                        onChangeFormik={handleChange}
                    />
                </CCol>
                <CCol md={1}>
                    <SWInputNumber
                        onBlur={handleBlur}
                        validationErrors={
                            (touched.dimensions?.length && errors.dimensions?.length) ||
                            ""
                        }

                        // label={"Length"}
                        name={"dimensions.length"}
                        value={values.dimensions?.length ?? undefined}
                        onChangeFormik={handleChange}
                    />
                </CCol>
                <CCol md={2}>
                    <SWSelect
                        // label={"Unit"}
                        values={getUnitLoookup() as { [k: string]: string }}
                        value={values.dimensions?.unit?.toString()}
                        name={"dimensions.unit"}
                        onChange={(e) => setFieldValue("dimensions.unit", e)}
                    />
                </CCol>
            </CRow>

            <CRow className={"col-md-12"}>
                <CCol md={3}>
                    <SWSelect

                        label={"Allocation Strategy"}
                        values={
                            getAllocationStrategyLoookup() as { [k: string]: string }
                        }
                        value={values.allocationStrategy?.toString()}
                        name={"allocationStrategy"}
                        onChange={(e) => setFieldValue("allocationStrategy", e)}
                    />
                </CCol>
                <CCol md={3}>
                    <SWSelect
                        label={"Allocation Type"}
                        values={getAllocationTypeLoookup() as { [k: string]: string }}
                        value={values.allocationType?.toString()}
                        name={"allocationType"}
                        onChange={(e) => setFieldValue("allocationType", e)}
                    />
                </CCol>

                <CCol md={3}>
                    <SWInputNumber
                        label={"Break Count"}
                        name={"breakCount"}
                        onChangeFormik={handleChange}
                        value={values.breakCount ?? undefined}
                        validationErrors={touched.breakCount && errors.breakCount || ""}
                    />
                </CCol>
                <CCol md={3}>
                    <SWInputNumber
                        label={"Minimum Reorder"}
                        name={"minReorder"}
                        onChangeFormik={handleChange}
                        value={values.minReorder}
                        validationErrors={touched.minReorder && errors.minReorder || ""}

                    />
                </CCol>
            </CRow>
            <CRow className={"col-md-12"}>
                <CCol md={3}>
                    <SWInputNumber
                        onBlur={handleBlur}
                        validationErrors={(touched.expiryDays && errors.expiryDays) || ""}
                        label={"Expiry Days"}
                        name={"expiryDays"}
                        value={values.expiryDays ?? undefined}
                        onChangeFormik={handleChange}
                    />
                </CCol>
            </CRow>

            <CRow className={"col-md-12 mb-2"}>
                <CCol className="text-right">
                    <ActionButton
                        text={id ? "Update" : "Create"}
                        onClick={save}
                    />
                </CCol>
            </CRow>

            {id &&
                <CTabs>
                    <CCard>
                        <CNav variant="tabs">
                            <CNavLink>Quantity Details</CNavLink>
                            <CNavLink onClick={()=>
                                getTransactionHistory(loadedSku?.code?? "", loadedSku?.accountNumber?? "")}>
                                Transaction History
                            </CNavLink>
                            <CNavLink onClick={()=>
                                getOpenReservations(loadedSku?.code?? "", loadedSku?.accountNumber?? "")}>
                                Open Reservations
                            </CNavLink>
                        </CNav>
                        <CTabContent>
                            <CTabPane >
                                <CCard>
                                    <table className="position-relative table table-hover table-sm">
                                        <thead>
                                            <tr>
                                                <th>Area</th>
                                                <th>Location</th>
                                                {values.allocationStrategy == AllocationStrategy.ExpiryDate  &&
                                                    <>
                                                        <th>Expiry Date</th>
                                                        <th>Batch Number</th>
                                                        <th>item Number</th>
                                                    </>
                                                }
                                                {values.allocationStrategy == AllocationStrategy.BatchNumber &&
                                                    <>
                                                        <th>Batch Number</th>
                                                        <th>item Number</th>
                                                    </>
                                                }
                                                <th>Quantity Available</th>
                                                <th>Quantity</th>

                                            </tr>

                                        </thead>

                                        <tbody>
                                            {quantitiesReport.map((op, index) => {

                                                return <tr key={index}>
                                                    <td>{op.area}</td>
                                                    <td>{op.location}</td>
                                                    {values.allocationStrategy == AllocationStrategy.ExpiryDate &&
                                                        <>
                                                            <td>{formatDate(op.expiryDate)}</td>
                                                            <td>{op.batchNumber}</td>
                                                            <td>{op.itemNumber}</td>
                                                        </>
                                                    }
                                                    {values.allocationStrategy == AllocationStrategy.BatchNumber &&
                                                        <>
                                                            <td>{op.batchNumber}</td>
                                                            <td>{op.itemNumber}</td>
                                                        </>
                                                    }
                                                    <td>{op.quantityAvailable}</td>
                                                    <td>{op.quantity}</td>
                                                </tr>
                                            }
                                            )}

                                        </tbody>
                                    </table>
                                </CCard>


                            </CTabPane>
                            <CTabPane>
                                <CCard>
                                    <CRow>
                                        <CCol md={12} className={"text-right"}>
                                            <ExportToExcelButton
                                                getData={HandleExcelExport}
                                                fileNamePrefix={"TransactionHistory"} />
                                        </CCol>
                                    </CRow>
                                    <table className="position-relative table table-hover table-sm">
                                        <thead>
                                            <tr>
                                                <th>Id</th>
                                                <th>Created On</th>
                                                <th>On</th>
                                                <th>Type</th>
                                                <th>Reference</th>
                                                <th>Quantity</th>
                                                <th>Available</th>
                                                <th>Location</th>
                                                <th>Area</th>
                                                <th>Warehouse</th>
                                                <th>By</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {transactionHistory?.matches.map((tr, index) => {

                                                return <tr key={index}>
                                                    <td>{tr.code.slice(0, 12)}</td>
                                                    <td>{formatDateTime(tr.createdOn)}</td>
                                                    <td>{formatDateTime(tr.on)}</td>
                                                    <td>{subTypeLookups ? subTypeLookups[tr.subType] : ""}</td>
                                                    <td>{tr.shipmentNumber ? tr.shipmentNumber : tr.reference}</td>
                                                    <td>{tr.quantity}</td>
                                                    <td>{tr.quantityAvailable}</td>
                                                    <td>{tr.location}</td>
                                                    <td>{tr.area}</td>
                                                    <td>{tr.warehouse}</td>
                                                    <td>{tr.createdBy}</td>
                                                </tr>
                                            }
                                            )}

                                        </tbody>
                                    </table>
                                </CCard>
                                <PageCount
                                    maxFound={transactionHistory?.total ?? 0}
                                    onPageChange={e => setTransactionPage(e)}
                                    onPageSizeChange={e => setTransactionHistoryPageSize(e)}
                                    currentPage={transactionPage}
                                    maxPage={Math.ceil(  transactionHistory?.total!/transactionHistoryPageSize)} />
                            </CTabPane>


                            <CTabPane>
                                <CCard>

                                    <table className="position-relative table table-hover table-sm">
                                        <thead>
                                            <tr>
                                                <th>Code</th>
                                                <th>Created On</th>
                                                <th>On</th>
                                                <th>Reference</th>
                                                <th>BatchNumber</th>
                                                <th>ItemNumber</th>
                                                <th>ExpiryDate</th>
                                                <th>Quantity</th>

                                            </tr>
                                        </thead>

                                        <tbody>
                                            {openReservations?.matches?.map((tr, index) => {

                                                return <tr key={index}>
                                                    <td>{tr.transactionCode}</td>
                                                    <td>{formatDateTime(tr.createdOn)}</td>
                                                    <td>{formatDate(tr.on)}</td>
                                                    <td>{tr.reference}</td>
                                                    <td>{tr.batchNumber}</td>
                                                    <td>{tr.itemNumber}</td>
                                                    <td>{formatDate(tr.expiryDate)}</td>
                                                    <td>{Math.abs(tr.quantityReserved) }</td>
                                                </tr>
                                            }
                                            )}

                                        </tbody>
                                    </table>
                                </CCard>
                                <PageCount
                                    maxFound={openReservations?.total ?? 0}
                                    onPageChange={e => setOpenTransactionPage(e)}
                                    onPageSizeChange={e => setOpenTransactionPageSize(e)}
                                    currentPage={openTransactionPage}
                                    maxPage={Math.ceil(  openReservations?.total!/openTransactionPageSize)} />
                            </CTabPane>
                        </CTabContent>
                    </CCard>
                </CTabs>
            }
            {activationModal &&
                <ConfirmModal
                    onClose={() => setActivationModal(false)}
                    onSubmit={handleActivation}
                    title={activationTitle}
                    body={`Are you sure you want to ${activationTitle.toLowerCase()} this SKU ?`}
                />}

            {updateImageModal &&
                <UpdateImageModal
                    account={values?.accountNumber}
                    onSubmit={(attachmentLink: string) => {
                        setFieldValue('imageUrls', [attachmentLink]);
                        setFieldValue('imageUrl', attachmentLink);
                        setUpdateImageModal(false)
                    }}
                    onClose={() => setUpdateImageModal(false)} />
            }
        </CCard>

    );
}

export default CreateUpdateSku;
