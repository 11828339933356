import {
  CCol,
  CContainer,
  CRow,
  CLink,
  CButton,
  CFormGroup,
  CLabel,
  CInputGroup,
  CCard,
  CCardBody,
  CCardText,
  CCardTitle,
  CCollapse,
  CTooltip,
} from "@coreui/react";
import SWInput from "../../SharedComponents/SWInput";
import SWSelect from "../../SharedComponents/SWSelect";
import React, { useEffect, useState } from "react";
import RatesClient from "../../../clients/ratesClient";
import ShipClient from "../../../clients/shipClient";
import { ShipmentFlattened,shipmentFlattenedKeys } from "../../../models/shipment";
import { RemoteBlob } from "../../../models/app";
import { SheetInfo } from "../../../models/sheetInfo";
import { useHistory } from "react-router";
import ExcelMapper from "./ExcelMapper";
import ShipmentFlattenedEdit from "./ShipmentFlattenedEdit";
import { ShowNotification } from "../../../store/actions/auth";
import { useDispatch } from "react-redux";
import ConfirmModal from "../../SharedComponents/ConfirmModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import ActionButton from "../../SharedComponents/ActionButton";
import Loading from "../../SharedComponents/Loading";

interface Props {}

const CreateImportShipment = (props: Props) => {
  const ratesClient = new RatesClient();
  const shipClient = new ShipClient();
  const hist = useHistory();
  const dispatch = useDispatch();

  const [accounts, setAccounts] = useState<{ [key: string]: string }>({});
  const [account, setAccount] = useState<string | undefined>(undefined);
  // const [importType, setImportType] = useState<string | undefined>(undefined);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [map, setMap] = useState<{ [k: string]: string | null }>({});
  const [defaultValue, setDefaultValue] = useState<ShipmentFlattened>({});
  const [confirm, setConfirm] = useState(false);
  const [confirmReset, setConfirmReset] = useState(false);

  const [collapse, setCollapse] = useState(false);
  const [excelInfo, setExcelInfo] = useState<{
    blobInfo: RemoteBlob | null;
    sheets: SheetInfo[] | null;
  }>({
    blobInfo: null,
    sheets: null,
  });
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const { blobInfo, sheets } = excelInfo;
  const [isLoadingFile, setIsLoadingFIle] = useState(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);


  useEffect(() => {
    const loadAccounts = async () =>
      setAccounts(await ratesClient.LookupCustomers());
    loadAccounts();
  }, []);

  const accountChanged = async (v: string | undefined) => {
    setAccount(v);
    if (!v) return;
    const defaultParams = await shipClient.GetDefaultImportOptions(v);
    setMap(defaultParams?.map ?? {});
    setDefaultValue(defaultParams?.defaultValue ?? {});
  };
  const [fileLength, setFileLength] = useState<number | null>(0)

  const fileChosen = async (file?: File) => {
    console.log(file,file)

    if (!file || (!file?.name?.endsWith("xlsx") && !file?.name?.endsWith("csv") && !file?.name?.endsWith("xls"))) {
      dispatch(ShowNotification("Error", "File is not in the correct format", true))
      return
    }

    setShowError(false);
    setError("");
    setIsLoadingFIle(true)
    const response = await shipClient.UploadExcelFile(file!);
    if (!response?.blobInfo) {
      dispatch(ShowNotification("Error", "Invalid excel file", true))
      setIsLoadingFIle(false)

      return
    }
    setExcelInfo(response);
    setFile(file)
    setIsLoadingFIle(false)
    const sheet = response.sheets?.[0];
    const newMap: { [k: string]: string | null } = {};
    setFileLength(sheet?.rowCount-1)
    const header = sheet?.values["0"].map((v) => v?.toString().trim() ?? "");
    for (const v in map) {
      if (header.includes(v)) {
        newMap[v] = map[v];
      }
    }

    setMap(newMap);
  };

  const reset = () => {
    setAccount(undefined);
    // setImportType(undefined);
    setMap({});
    setFile(undefined);
    setDefaultValue({});
    setConfirmReset(false);
    setExcelInfo({ sheets: null, blobInfo: null });
    setFileLength(null)
    setIsLoadingFIle(false)
    setIsLoadingConfirm(false)

  };
  const importShipments = async () => {
    if (!account || !defaultValue || !map || !blobInfo) return;
    setShowError(false);
    setError("");
    setIsLoadingConfirm(true)

    const result = await shipClient.importShipments({
      account: account,
      parameters: { map: map, defaultValue: defaultValue },
      fileUrl: blobInfo.name,
      fileName: file?.name
    });

    if (typeof result != "number") {
      setConfirm(false);
      setShowError(true);
      setError(result);
      setIsLoadingConfirm(false)

      return;
    }

    hist.push(`/shipments/import/${result}`);
  };
  const handleMapChange = (key: string, selected?: string | null) => {
    if (selected && Object.values(map).includes(selected)) {
      const headerKey = Object.keys(map).find((key) => map[key] === selected);

      dispatch(
        ShowNotification("Error", `Already mapped to ${headerKey}`, true)
      );
      return;
    }

    setMap({
      ...map,
      [key]: selected == "notMapped" ? null : selected ?? null,
    });
  };

  const handleShipmentFlattenedChanges = (name: string, value: any) => {
    setDefaultValue({ ...defaultValue, [name]: value });
  };

  return (
    <CContainer className="bg-white p-4 text-primary small ">
      <h5 style={{color:"gray", marginBottom:"2%"}}>Import</h5>
      <CRow className={'align-items-start'}>
        <CCol md={"3"}>
          <SWSelect
            values={accounts}
            label="Account"
            onChange={accountChanged}
            value={account}
          />
        </CCol>
        <CCol md={"4"}>
          {isLoadingFile ? <Loading size={3} /> : blobInfo ? (
            <CFormGroup>
              <CLabel>File</CLabel>
              <CInputGroup>
                <div className="form-control">
                  <CTooltip content={file?.name}>
                    <CLink
                    style={{fontSize:"10px"}}
                      className="d-inline-block text-truncate w-100"
                      href={blobInfo.location}
                      target="_blank"
                    >
                      {file?.name}
                    </CLink>
                  </CTooltip>
                </div>
              </CInputGroup>
            </CFormGroup>
          ) : (
            <SWInput
              type="file"
              label="File"
              id={"input-file"}
              onChangeFile={fileChosen}
              valueFile={file}
              readonly={!account}
            />
          )}
          <CLabel>
            Items to be imported:  {fileLength}
          </CLabel>
        </CCol>
        <CCol md={"3"} style={{ marginTop: "auto", marginBottom: "auto" }}>
          <CRow className="d-flex justify-content-end p-2">
            <CButton
              style={{ fontSize: "10px" }}
              onClick={() => setConfirmReset(true)}
              variant="outline"
              color="primary"
              className="mr-2"
            >
              Reset
            </CButton>
            <ActionButton
                text={"Import"}
                extraClass={'mr-2'}
                disabled={
                    !account ||
                    !sheets ||
                    !map ||
                    Object.keys(map).length == 0}
                onClick={() => setConfirm(true)}
            />

            <CLink
              className="text-center mt-auto mb-auto"
              href="/shipments/import_history"
            >
              View Import History
            </CLink>
          </CRow>
        </CCol>
      </CRow>
      {sheets && Object.keys(sheets[0].values).length > 0 && (
        <>
          <CRow >
            <CCard className="text-center w-100">
              <CCard className="border-white w-100">
                <CRow className="border-white p-2 ml-3 mr-3 justify-content-end">
                  {collapse ? (
                    <CRow>
                      <CLabel className="mt-1">Hide Default Values</CLabel>
                      <FontAwesomeIcon
                        color="primary"
                        icon={faAngleUp}
                        style={{
                          width: "25",
                          height: "25",
                          cursor: "pointer",
                          outlineColor: "white",
                          color: "#00007d",
                        }}
                        onClick={() => setCollapse(!collapse)}
                      />
                    </CRow>
                  ) : (
                    <CRow>
                      <CLabel className="mt-1">Show Default Values</CLabel>
                      <FontAwesomeIcon
                        style={{
                          width: "25",
                          height: "25",
                          cursor: "pointer",
                          outlineColor: "white",
                          color: "#00007d",
                        }}
                        onClick={() => setCollapse(!collapse)}
                        icon={faAngleDown}
                      />
                    </CRow>
                  )}

                                    {/*
                            <CButton
                                color="primary"
                                onClick={() => setCollapse(!collapse)}
                                className={'mb-1'}
                            >
                                {collapse ? "Hide Default Values" : "Show Default Values"}
                            </CButton> */}
                </CRow>

                <CCollapse className="border-white" show={collapse}>
                  <ShipmentFlattenedEdit
                    shipment={defaultValue}
                    onChange={handleShipmentFlattenedChanges}
                  />
                </CCollapse>
              </CCard>

              {showError ? (
                <CCard
                  style={{
                    width: "98%",
                    margin: "auto",
                    marginBottom: "25px",
                    background: "#FFD2D2",
                    color: "#D8000C",
                    height: "100%",
                    border: "none",
                  }}
                >
                  <CCardBody>
                    <CCardTitle>Import Error</CCardTitle>
                    <CCardText>{error}</CCardText>
                  </CCardBody>
                </CCard>
              ) : null}

                            <>
                                <ExcelMapper mapSelectData={shipmentFlattenedKeys}
                                             onMapChange={handleMapChange}
                                             sheet={sheets[0]}
                                             map={map}
                                             objectDifferentiator="Number"
                                />
                            </>
                        </CCard>
                    </CRow>
                </>
            )}

      {confirm && (
        <ConfirmModal
            submitting={isLoadingConfirm}
          onClose={() => setConfirm(false)}
          onSubmit={importShipments}
          title="Are you sure you want to start shipments import?"
          body="This action will start adding/updating shipments"
        />
      )}
      {confirmReset && (
        <ConfirmModal
          onClose={() => setConfirmReset(false)}
          onSubmit={reset}
          title="Are you sure you want to start over"
          body="This action will reset the form"
        />
      )}
    </CContainer>
  );
};

export default CreateImportShipment;
