import ReactSelect from "react-select";
import React from "react";
import selectStyle from "./SelectStyles";
import {CFormGroup, CFormText, CInput, CLabel} from "@coreui/react";


const SelectField = ({ field, change, form, readonly, label, options, ...props }:any) =>{
    let optionsArray: any[]
    if(options?.length){
        optionsArray = options
    }else {
        optionsArray = options &&
            Object.entries(options).map((v) => ({
                label: v[1],
                value: v[0],
                id: v[1],
            }));
    }

    const handleOnChange = (option : any)=> {
        form.setFieldValue(field.name, option.value)
        
    }

    const handleValue = () => {
        let value = optionsArray?.find((option:any) => option.value === field.value)
        return value ? value : ''
    }

    return ( <CFormGroup>
                <CLabel htmlFor={field.name}>{label}</CLabel>
                <CFormGroup>
                    <ReactSelect
                        style={{ fontSize: "10px" }}
                        //@ts-ignore
                        styles={selectStyle}
                        classNamePrefix="mySelect"
                        className="w-100"
                        name={field.name}
                        value={optionsArray ? handleValue() : ''}
                        onChange={change !== undefined? change: handleOnChange}
                        onBlur={field.onBlur}
                        isDisabled={readonly}
                        options={optionsArray}
                    />
                </CFormGroup>
                {
                    form.touched[field.name] && form.errors[field.name]
                        ? <CFormText
                            style={{ fontSize: "1em" }}
                            color="danger"
                            className="help-block"
                        >
                            {form.errors[field.name]}
                        </CFormText>
                        : null
                }
            </CFormGroup>
        )
}

export default SelectField