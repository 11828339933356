import { CRow, CCol } from "@coreui/react";
import React from "react";
import validator from "validator";
import ShipClient from "../../../../clients/shipClient";
import { Input } from "../../../SharedComponents/SWForm";

interface Props {
  className?: string;
  containerTypes?: { [k: string]: string; }
}

const ContainerBasicInfo = (props: Props) => {
  const { className,containerTypes } = props;
  const shipClient = new ShipClient();
  return (
    <div className={className}>
      <CRow>
        <CCol>
          <Input label="Number" readonly type="text" field="number" />
        </CCol>
        <CCol>
          <Input
            label="Type"
            type="typeahead"
            field="type"
            readonly
            lookUp={containerTypes}
          />
        </CCol>
      </CRow>
    </div>
  );
};

export default ContainerBasicInfo;
