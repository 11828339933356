import {AccountFilterGroup, Filter, FilterGroup, FilterType} from "../models/filter";

const SEPERATOR = "@";

const filterMaps = [
    {
        str: "eq",
        type: FilterType.Equals,
        searchy: "1",
    },
    {
        str: "neq",
        type: FilterType.NotEquals,
        searchy: "2",
    },
    {
        str: "gt",
        type: FilterType.GreaterThan,
        searchy: "5",
    },
    {
        str: "lt",
        type: FilterType.LessThan,
        searchy: "8",
    },
    {
        str: "of",
        type: FilterType.OneOf,
        searchy: "9",
    },
    {
        str: "lik",
        type: FilterType.Like,
        searchy: "4",
    },
    {
        str: "rng",
        type: FilterType.Range,
        searchy: "21",
    },
];

const strToFilterType = (str: string) =>
    filterMaps.find((f) => f.str === str)?.type;
const filterTypeToStr = (eType: FilterType) =>
    filterMaps.find((f) => f.type === Number.parseInt(eType as any))?.str;
const filterTypeToSearchy = (eType: FilterType) =>
    filterMaps.find((f) => f.type === Number.parseInt(eType as any))?.searchy;

const getValueForSearchy = (filter: Filter) => {
    if (filter.type === FilterType.OneOf) {
        const vals = (filter.value as string).split("\n").join("|");
        return `text|${vals}`;
    }
    if (filter.inputType?.toLowerCase().includes("date")) {
        return (filter.value as Date).toISOString();
    }

    return filter.value;
};

const getValueForQParams = (filter: Filter) => {
    if (filter.type === FilterType.OneOf) {
        const vals = decodeURIComponent((filter.value as string).split("\n").join("|"));
        return vals;
    }
    if (filter.inputType?.toLowerCase().includes("date")) {
        return (filter.value as any).toISOString
            ? (filter.value as Date).toISOString()
            : filter.value;
    }
    return filter.value;
};

const getValueForFilterGroup = (val: string, type?: FilterType) => {
    if (type === FilterType.OneOf) {
        return val.split("|").join("\n");
    }
    return val;
};

export const filterGroupToQueryParams = (filterGroup: FilterGroup): string => {
    if (!filterGroup) return "";

    const pageAndLimit = `page=${filterGroup.page ?? 0}&size=${
        filterGroup.limit ?? 20
    }`;

    let filterQueryParams = [];
    for (const filter of filterGroup.filters ?? []) {
        filterQueryParams.push(`filter=${filter.field}@${filterTypeToStr(filter.type)}@${getValueForQParams(filter)}`)
    }


    if (filterGroup.filters && filterGroup.filters.length > 0) {
        return `${filterQueryParams.join("&")}&${pageAndLimit}`
    } else {
        return pageAndLimit;
    }
};

export const queryParamsToFilters = (queryParams: string): FilterGroup => {
    const params = new URLSearchParams(queryParams);

    const filters = params.getAll("filter");

    const page = Number.parseInt(params.get("page") as string) || 0;
    const limit = Number.parseInt(params.get("limit") as string) || 20;
    const fg = {
        page: page,
        limit: limit,
        filters: filters.map<Filter>((f) => {
            const split = f.split(SEPERATOR);
            return {
                field: split[0],
                type: strToFilterType(split[1])!,
                value: getValueForFilterGroup(split[2], strToFilterType(split[1])),
            };
        }),
    };
    return fg;
};

export const filtersToSearchy = (filterGroup: FilterGroup) => {


    if (!filterGroup) return "";
    const pageAndLimit = `page=${filterGroup.page}&size=${filterGroup.limit}`;
    if (filterGroup.filters && filterGroup.filters.length > 0) {

        return `${
            filterGroup.filters &&
            filterGroup.filters.reduce((pv, cv, i, arr) => {
                return [
                    `${pv}${pv == "" ? "" : "&"}filter=${cv.field}`,
                    `${filterTypeToSearchy(cv.type)}`,
                    `${getValueForSearchy(cv)}`,
                ].join(":");
            }, "")
        }&${pageAndLimit}`;
    } else {
        return pageAndLimit;
    }
};

export const filtersToSearchyOld = (filterGroup: FilterGroup) => {
    if (!filterGroup) return "";
    const pageAndLimit = `page=${filterGroup.page}&size=${filterGroup.limit}`;
    if (filterGroup.filters && filterGroup.filters.length > 0) {
        return `${
            filterGroup.filters &&
            filterGroup.filters.reduce((pv, cv, i, arr) => {
                const searchyFilter = [
                    `${pv}${cv.field}`,
                    `${filterTypeToSearchy(cv.type)}`,
                    `${getValueForSearchy(cv)}${i === arr.length - 2 ? "&filter=" : ""}`,
                ].join(":");
                return searchyFilter;
            }, "filter=")
        }&${pageAndLimit}`;
    } else {
        return pageAndLimit;
    }
};


export const toQueryParam = (filter: Record<string, any>): string => {
    let query = "";
    const arr = Object.keys(filter);

    arr.reduce((previousValue, currentValue) => {
        let paramValue = filter[currentValue];

        if (paramValue || typeof (paramValue) === 'boolean') {
            if (Array.isArray(paramValue)) {
                paramValue.forEach((value) => {
                    query += previousValue ? `&${currentValue}=${value}` : `${currentValue}=${value}`;
                    previousValue = query;
                });
            } else {
                query = previousValue ? `${previousValue}&${currentValue}=${paramValue}` : `${currentValue}=${paramValue}`;
            }
        }

        return query;
    }, query);

    return query;
};


export const toIndividualParam = (filterGroup: AccountFilterGroup): string => {
    const params = new URLSearchParams();

    const filters: Record<string, any> = filterGroup.filters ?? {};

    Object.keys(filters).forEach(key => {
        const value = filters[key];
        if (value !== undefined && value !== null) {
            params.append(key, value.toString());
        }
    });

    return params.toString();
};

export const querytoMap = <T extends object>(params: string): T => {
    let obj = Object.fromEntries(new URLSearchParams(params));
    return obj as T;

}





