import React, { Fragment, useState } from "react";
import { CContainer } from "@coreui/react";
import PendingTable from "./tables/pendingTable";
import GeneratedShipmentsTable from "./tables/generatedShipmentsTable";
import ShipmentsWithErrors from "./tables/pendingWithErrorsTable";


const NotGenerated = () => {

    const [refreshGeneratedShipmentsTable, setRefreshGeneratedShipmentsTable] = useState<boolean>(false);

    return (
        <Fragment>
            <CContainer className="bg-white px-4 py-2 text-primary small">
                <PendingTable refreshGeneratedShipmentsTable={() => setRefreshGeneratedShipmentsTable(!refreshGeneratedShipmentsTable)} />
            </CContainer>
            <CContainer className="bg-white px-4 py-2 text-primary small">
                <ShipmentsWithErrors />
            </CContainer>
            <CContainer className="bg-white px-4 py-2 text-primary small">
                <GeneratedShipmentsTable refreshGeneratedShipmentsTable={refreshGeneratedShipmentsTable} />
            </CContainer>
        </Fragment>
    )
}

export default NotGenerated