import React, { Fragment, useState } from "react";
import { CContainer } from "@coreui/react";
import PrintedShipmentTable from "./tables/printedShipmentTable";
import PendingTable from "./tables/pendingTable";

const Print = () => {
    const [refreshPrintedShipmentTable,setRefreshPrintedShipmentTable] = useState<boolean>();
    return (
        <Fragment>
                <CContainer className="bg-white px-4 py-2 text-primary small">
                <PendingTable refreshPrintedShipmentTable={()=>setRefreshPrintedShipmentTable(!refreshPrintedShipmentTable)} />
            </CContainer>
            <CContainer className="bg-white px-4 py-2 text-primary small">
                <PrintedShipmentTable refreshPrintedShipmentTable={refreshPrintedShipmentTable}/>
            </CContainer>
        </Fragment>
    )
}

export default Print