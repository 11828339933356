import {
    CButton,
    CButtonGroup,
    CCard,
    CCol,
    CContainer,
    CInputCheckbox,
    CLabel,
    CNav,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CTabs,
    CTooltip,
} from "@coreui/react";
import SWTextArea from "../../SharedComponents/SWTextArea";
import SWDateAndTimePicker from "../../SharedComponents/SWDateAndTimePicker";
import {Fragment, useEffect, useState} from "react";
import {useFormik} from "formik";
import * as yup from "yup"
import SWInput from "../../SharedComponents/SWInput";
import SWSelect from "../../SharedComponents/SWSelect";
import {
    mapDimUnit,
    mapWeightUnit,
    RateEntry,
    RateItem,
    RateItemService,
    ratesKeys,
    Surcharge,
    surchargesKeys,
    Zone,
    zoneKeys
} from "../../../models/retes";
import RatesClient from "../../../clients/ratesClient";
import {useLocation, useParams} from "react-router";
import {getUnitLoookup, getWeightUnitLoookup} from "../../../utils/lookupUtils";
import {useDispatch} from "react-redux";
import {SetLoading, ShowNotification} from "../../../store/actions/auth";
import {SetIsLoading} from "../../../store/actions/ui";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import AddNewServiceModal from "./components/Modals/AddNewServiceModal";
import LookupsClient from "../../../clients/lookupsClient";
import _ from "lodash";
import ZoneModal from "./components/Modals/ZoneModal";
import ZoneTable from "./components/Tables/ZoneTable";
import RatesModal from "./components/Modals/RateEntryModal";
import RateEntryTable from "./components/Tables/RateEntryTable";
import SurchargesTable from "./components/Tables/SurchargesTable";
import SurchargesModal from "./components/Modals/SurchargesModal";
import ServicesTable from "./components/Tables/ServicesTable";
import ServicesModal from "./components/Modals/ServicesModal";
import TabActions from "./components/TabActions";
import {useHistory} from "react-router";

export const CardEditor = () => {
    const ratesClient = new RatesClient();
    const lookups = new LookupsClient()
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    const {cardId} = params as { cardId: number }
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type');
    const [cardItem, setCardItem] = useState<Partial<RateItem>>();
    const readOnly = Boolean(cardItem);
    const [agents, setAgents] = useState<{ [k: string]: string }>({})
    const [countryLookups, setCountryLookups] = useState<{ [k: string]: string }>({})
    const [services, setServices] = useState<{ [k: string]: string }>({});
    const [addAgentServiceModal, setAddAgentServiceModal] = useState<boolean>(false)
    const [zoneModal, setZoneModal] = useState<{
        key: "originZones" | "destinationZones",
        data?: Zone,
        isOpen: boolean,
        isUpdate?: boolean
    } | null>();
    const [rateEntryModal, setRateEntryModal] = useState<{
        data?: RateEntry,
        isOpen: boolean,
        isUpdate?: boolean
    } | null>();
    const [surchargesModal, setSurchargesModal] = useState<{
        data?: Surcharge,
        isOpen: boolean,
        isUpdate?: boolean
    } | null>();
    const [rateItemServicesModal, setRateItemServicesModal] = useState<{
        data?: RateItemService,
        isOpen: boolean,
        isUpdate?: boolean
    } | null>();


    useEffect(() => {
        if (cardId) {
            getCard(cardId)
        }
    }, [cardId, type]);

    useEffect(() => {
        getAgents()
        getCountries()
    }, []);


    const getCountries = async () => {
        let countries = await lookups.getCountries()
        setCountryLookups(countries)
    }

    const getAgents = async () => {
        let agents = await ratesClient.LookupAgents()
        setAgents(agents)
    }

    const getCard = (async (id: number) => {
        dispatch(SetLoading(true))
        const res = type === 'costs' ? await ratesClient.GetCost(id) : await ratesClient.GetSelling(id)
        if (res) {
            setCardItem(res)
        }
        dispatch(SetLoading(false))
    })

    const initialValues: Partial<RateItem> = {
        id: cardId,
        cardId: cardItem?.cardId ?? "",
        cardName: cardItem?.cardName ?? "",
        cardDescription: cardItem?.cardDescription ?? "",
        weightFactor: cardItem?.weightFactor ?? 0, // no volume weight
        weightUnit: cardItem?.weightUnit ?? 1, // kg
        dimensionUnit: cardItem?.dimensionUnit ?? 0, // cm
        expiresOn: cardItem?.expiresOn ?? null,
        perPiece: cardItem?.perPiece ?? false,
        hasPickup: cardItem?.hasPickup ?? false,
        hasWeekend1: cardItem?.hasWeekend1 ?? false,
        hasWeekend2: cardItem?.hasWeekend2 ?? false,
        hasIdCheck: cardItem?.hasIdCheck ?? false,
        hasInsurance: cardItem?.hasInsurance ?? false,
        hasCOD: cardItem?.hasCOD ?? false,
        uniqueOriginZones: cardItem?.uniqueOriginZones ?? "",
        uniqueDestinationZones: cardItem?.uniqueDestinationZones ?? "",
        entries: cardItem?.entries ?? [],
        surcharges: cardItem?.surcharges ?? [],
        originZones: cardItem?.originZones ?? [],
        destinationZones: cardItem?.destinationZones ?? [],
        rateItemServices: cardItem?.rateItemServices ?? [],
        agent: cardItem?.agent ?? "",
        service: cardItem?.service ?? "",
        serviceName: cardItem?.serviceName ?? "",
        serviceDescription: cardItem?.serviceDescription ?? "",
    }
    const validationSchema = () => yup.object().shape({
        cardName: yup.string().required("Required"),
        ...(type === 'costs' && {
            agent: yup.string().required("Required"),
            service: yup.string().required("Required"),
        }),
    })

    const submitHandler = async (values: Partial<RateItem>) => {
        const parsedValues = {
            ...values,
            description: values?.cardDescription,
            name: values?.cardName,
            validFrom: values?.expiresOn?.toString(),
            id: Boolean(cardItem)?cardId:0
        }
        
        let res = type === 'costs' ? await ratesClient.UpsertCost(parsedValues) : await ratesClient.UpsertSelling(parsedValues)
        if (res?.succeeded) {
            dispatch(ShowNotification("Success", "Saved Successfully!", false));
            if (!cardId) {
                history.push({
                    pathname: `/financial/card/${res?.data}`,
                    search: `?type=${type === 'costs' ? 'costs' : 'selling'}`,
                });
            } else {
                await getCard(res?.data)
            }
        } else {
            dispatch(ShowNotification("Error", JSON.stringify(res?.data.SWException).toString(), true));
        }
        resetForm()
    }

    const formik = useFormik<Partial<RateItem>>({
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: (values) => submitHandler(values),
        validationSchema: validationSchema
    })
    const {values, setFieldValue, touched, errors, resetForm, handleSubmit} = formik

    const isAgentSelected = Boolean(values?.agent)


    const getAgentServices = async () => {
        let services = await ratesClient.GetAgentServices(values?.agent!)
        setServices(services)
    }

    const handleExportToExcel = async (type: "OrgZone" | "DestZone" | "RateEntry" | "Surcharges") => {
        dispatch(SetIsLoading(true))
        if (cardId) {
            const res = await ratesClient.ExportRatesData({rateVersionId: cardId, exportDataType: type})
            if (res) {
                window.open(res?.location, "_blank", "noreferrer")
            }
        }
        dispatch(SetIsLoading(false))
    }

    useEffect(() => {
        if (type === "costs") {
            const agent = values?.agent;
            const service = values?.service;
            setFieldValue("cardName", !!agent && !!service ? `${agent}-${service}` : "");
            if (!!agent) {
                getAgentServices();
            } else {
                setServices({})
            }
        }
    }, [type, values?.agent, values?.service]);

    return (

        <Fragment>

            {addAgentServiceModal &&
                <AddNewServiceModal
                    onClose={() => setAddAgentServiceModal(false)}
                    agentCode={values?.agent!}
                    refreshAgentServices={() => getAgentServices()}
                />
            }

            <CContainer className="bg-white p-4 text-primary small">
                <CRow className={"flex align-items-center"}>
                    <CTooltip content="Back">
                        <CButton className={" mb-2"}>
                            <CIcon
                                content={freeSet.cilArrowLeft}
                                onClick={() => {
                                    history.push({
                                        pathname: type === "costs" ? '/financial/agentsRates' : '/financial/sellingCards',
                                    });
                                }}
                            />
                        </CButton>
                    </CTooltip>
                    <h5 style={{color: "gray"}}>{Boolean(cardItem) ? "Edit Card" : "Create Card"}</h5>
                </CRow>

                <CRow className="p-2">
                    <CCol className="col-sm-5">
                        {type == "costs" &&
                            <>
                                <CRow>
                                    <CCol className="col-sm-10">

                                        <SWSelect
                                            readonly={readOnly && Boolean(values?.agent)}
                                            label="Agent"
                                            name="agent"
                                            value={values?.agent ?? ""}
                                            onChange={(e) => setFieldValue("agent", e)}
                                            values={agents}
                                            validationErrors={touched?.agent && errors?.agent?.toString() || ""}
                                        />
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol className="col-sm-10">
                                        <SWSelect
                                            readonly={readOnly && Boolean(values?.service)}
                                            label="Service"
                                            name="service" value={values?.service?.toString() ?? ""}
                                            onChange={(e) => setFieldValue("service", e)}
                                            values={services}
                                            validationErrors={touched?.service && errors?.service?.toString() || ""}
                                        />
                                    </CCol>

                                    <CCol className="col-sm-2">
                                        <CTooltip content={"Add new service"}>
                                            <CIcon
                                                tabIndex={!isAgentSelected ? -1 : undefined}
                                                content={freeSet.cilPlus}
                                                onClick={isAgentSelected ? () => setAddAgentServiceModal(true) : undefined}
                                                style={{
                                                    height: "1.5em",
                                                    width: "1.5em",
                                                    cursor: isAgentSelected ? "pointer" : undefined,
                                                    marginTop: "1.5rem",
                                                    color: !isAgentSelected ? "gray" : undefined
                                                }}
                                            />
                                        </CTooltip>
                                    </CCol>
                                </CRow>

                            </>
                        }
                        <CRow>
                            <CCol className="col-sm-10">
                                <SWInput
                                    label="Card Name"
                                    name={"cardName"}
                                    value={values.cardName}
                                    onChange={(e) => setFieldValue("cardName", e)}
                                    type={"text"}
                                    readonly={type == "costs" ? true : readOnly}
                                    validationErrors={touched?.cardName && errors?.cardName?.toString() || ""}
                                />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol className="col-sm-10">
                                <SWTextArea rows={10}
                                            label={"Description"}
                                            name={"cardDescription"}
                                            value={values?.cardDescription ?? ""}
                                            onChange={(e) => setFieldValue("cardDescription", e)}
                                            type={"text"}
                                            validationErrors={touched?.cardDescription && errors?.cardDescription?.toString() || ""}
                                />
                            </CCol>
                        </CRow>
                    </CCol>

                    <CCol className="col-sm-2"/>

                    <CCol className="col-sm-4">
                        <CRow className="p-2">
                            <CCol className="col-sm-6">
                                <SWSelect
                                    label="Weight Factor"
                                    name="weightFactor" value={values?.weightFactor?.toString()}
                                    onChange={(e) => setFieldValue("weightFactor", Number(e))}
                                    values={[{value: "6000", label: "6000"}, {
                                        value: "5000",
                                        label: "5000"
                                    }, {value: "4000", label: "4000"}, {value: "0", label: "No Volume Weight"}]}
                                    validationErrors={touched?.weightFactor && errors?.weightFactor?.toString() || ""}
                                />
                            </CCol>
                            <CCol className="col-sm-6">
                                <SWDateAndTimePicker
                                    label={"Expiry Date"}
                                    name={"expiryDate"}
                                    value={values?.expiresOn}
                                    handleOnChange={(e) => setFieldValue("expiresOn", e)}
                                    validationErrors={touched?.expiresOn && errors?.expiresOn?.toString() || ""}
                                />
                            </CCol>
                        </CRow>

                        <CRow className="p-2">
                            <CCol className="col-sm-6">
                                <SWSelect
                                    label="Weight unit"
                                    name="weightUnit" value={mapWeightUnit(values?.weightUnit?.toString() ?? "")}
                                    onChange={(e) => setFieldValue("weightUnit", e)}
                                    values={getWeightUnitLoookup() as { [k: string]: string }}
                                    validationErrors={touched?.weightUnit && errors?.weightUnit?.toString() || ""}
                                />
                            </CCol>
                            <CCol className="col-sm-6">
                                <SWSelect
                                    label="Dimension unit"
                                    name="dimensionUnit" value={mapDimUnit(values?.dimensionUnit?.toString() ?? "")}
                                    onChange={(e) => setFieldValue("dimensionUnit", e)}
                                    values={getUnitLoookup() as { [k: string]: string }}
                                    validationErrors={touched?.dimensionUnit && errors?.dimensionUnit?.toString() || ""}
                                />
                            </CCol>
                        </CRow>


                        <CRow className="ml-1">
                            <CCol className="col-sm-4 pl-4">
                                <CInputCheckbox
                                    name={"cod"}
                                    checked={values.hasCOD}
                                    onChange={(e) => setFieldValue("hasCOD", !values?.hasCOD)}
                                />

                                <CLabel className={"mt-1"}>COD</CLabel>
                            </CCol>
                            <CCol className="col-sm-4 pl-4">
                                <CInputCheckbox
                                    name={"perPrice"}
                                    checked={values?.perPiece}
                                    onChange={(e) => setFieldValue("perPiece", !values?.perPiece)}
                                />
                                <CLabel className={"mt-1"}>Per Piece</CLabel>
                            </CCol>
                            <CCol className="col-sm-4 pl-4">
                                <CInputCheckbox
                                    name={"weekendOne"}
                                    checked={values?.hasWeekend1}
                                    onChange={(e) => setFieldValue("hasWeekend1", !values?.hasWeekend1)}

                                />
                                <CLabel className={"mt-1"}>Weekend 1</CLabel>
                            </CCol>
                            <CCol className="col-sm-4 pl-4">
                                <CInputCheckbox
                                    name={"idCheck"}
                                    checked={values?.hasIdCheck}
                                    onChange={(e) => setFieldValue("hasIdCheck", !values?.hasIdCheck)}

                                />
                                <CLabel className={"mt-1"}>Id Check</CLabel>
                            </CCol>
                            <CCol className="col-sm-4 pl-4">
                                <CInputCheckbox
                                    name={"hasPickup"}
                                    checked={values?.hasPickup}
                                    onChange={(e) => setFieldValue("hasPickup", !values?.hasPickup)}

                                />
                                <CLabel className={"mt-1"}>Pickup</CLabel>
                            </CCol>
                            <CCol className="col-sm-4 pl-4">
                                <CInputCheckbox
                                    name={"weekend2"}
                                    checked={values?.hasWeekend2}
                                    onChange={(e) => setFieldValue("hasWeekend2", !values?.hasWeekend2)}

                                />
                                <CLabel className={"mt-1"}>Weekend 2</CLabel>
                            </CCol>
                            <CCol className="col-sm-4 pl-4">
                                <CInputCheckbox
                                    name={"insurance"}
                                    checked={values?.hasInsurance}
                                    onChange={(e) => setFieldValue("hasInsurance", !values?.hasInsurance)}

                                />
                                <CLabel className={"mt-1"}>Insurance</CLabel>
                            </CCol>

                        </CRow>

                    </CCol>
                </CRow>

                <CRow className="p-2">
                    <CCol className="text-right">
                        <CButtonGroup className="mb-0 mr-2">
                            <CButton
                                size="lg"
                                style={{fontSize: "10px"}}
                                className="mr-2"
                                variant="outline"
                                color="primary"
                                onClick={() => handleSubmit()}
                            >
                                Save
                            </CButton>
                        </CButtonGroup>

                    </CCol>
                </CRow>
            </CContainer>


            <CContainer className="bg-white p-4 text-primary small">
                <h5 style={{color: "gray"}}>Card Details</h5>
                <CTabs>
                    <CCard className="small">
                        <CNav variant="tabs">
                            <CNavLink>Origin Zone</CNavLink>
                            <CNavLink>Destination Zone</CNavLink>
                            <CNavLink>Rates</CNavLink>
                            <CNavLink>Surcharges</CNavLink>
                            {type != "costs" &&
                                <CNavLink>Services</CNavLink>}
                        </CNav>
                        <CTabContent>
                            <CTabPane>
                                <TabActions
                                    getCard={() => getCard(cardId)}
                                    cardId={cardId}
                                    onAdd={() => setZoneModal({isOpen: true, key: "originZones"})}
                                    onExport={() => handleExportToExcel("OrgZone")}
                                    keys={zoneKeys}
                                    importDataType={"OrgZone"}
                                />
                                <ZoneTable
                                    zones={values.originZones ?? []}
                                    onRemove={(itemToRemove: Zone) => {
                                        setFieldValue("originZones", [...values?.originZones?.filter((item) => !_.isEqual(item, itemToRemove)) || []])
                                        handleSubmit()
                                    }}
                                    onEdit={(e: Zone) => setZoneModal({
                                        data: e,
                                        isOpen: true,
                                        key: "originZones",
                                        isUpdate: true
                                    })}
                                />
                            </CTabPane>
                            <CTabPane>
                                <TabActions
                                    getCard={() => getCard(cardId)}
                                    cardId={cardId}
                                    onAdd={() => setZoneModal({isOpen: true, key: "destinationZones"})}
                                    onExport={() => handleExportToExcel("DestZone")}
                                    keys={zoneKeys}
                                    importDataType={"DestZone"}
                                />
                                <ZoneTable
                                    zones={values.destinationZones ?? []}
                                    onRemove={async (itemToRemove: Zone) => {
                                        setFieldValue("destinationZones", [...values?.destinationZones?.filter((item) => !_.isEqual(item, itemToRemove)) || []])
                                        handleSubmit()
                                    }}
                                    onEdit={(e: Zone) => setZoneModal({
                                        data: e,
                                        isOpen: true,
                                        key: "destinationZones",
                                        isUpdate: true
                                    })}
                                />
                            </CTabPane>
                            <CTabPane>
                                <TabActions
                                    getCard={() => getCard(cardId)}
                                    cardId={cardId}
                                    onAdd={() => setRateEntryModal({isOpen: true})}
                                    onExport={() => handleExportToExcel("RateEntry")}
                                    keys={ratesKeys}
                                    importDataType={"RateEntry"}
                                />
                                <RateEntryTable
                                    entries={values.entries ?? []}
                                    onRemove={async (itemToRemove: RateEntry) => {
                                        setFieldValue("entries", [...values?.entries?.filter((item) => !_.isEqual(item, itemToRemove)) || []])
                                        handleSubmit()
                                    }}
                                    onEdit={(e: RateEntry) => setRateEntryModal({
                                        data: e,
                                        isOpen: true,
                                        isUpdate: true
                                    })}
                                />
                            </CTabPane>
                            <CTabPane>
                                <TabActions
                                    getCard={() => getCard(cardId)}
                                    cardId={cardId}
                                    onAdd={() => setSurchargesModal({isOpen: true})}
                                    onExport={() => handleExportToExcel("Surcharges")}
                                    keys={surchargesKeys}
                                    importDataType={"Surcharges"}
                                />
                                <SurchargesTable
                                    surcharges={values.surcharges ?? []}
                                    onRemove={async (itemToRemove: Surcharge) => {
                                        setFieldValue("surcharges", [...values?.surcharges?.filter((item) => !_.isEqual(item, itemToRemove)) || []])
                                        handleSubmit()
                                    }}
                                    onEdit={(e: Surcharge) => setSurchargesModal({
                                        data: e,
                                        isOpen: true,
                                        isUpdate: true
                                    })}/>
                            </CTabPane>
                            <CTabPane>
                                <TabActions
                                    hideImport
                                    onAdd={() => setRateItemServicesModal({isOpen: true})}
                                />
                                <ServicesTable
                                    services={values.rateItemServices ?? []}
                                    onRemove={async (itemToRemove: RateItemService) => {
                                        setFieldValue("rateItemServices", [...values?.rateItemServices?.filter((item) => !_.isEqual(item, itemToRemove)) || []])
                                        handleSubmit()
                                    }}
                                    onEdit={(e: RateItemService) => setRateItemServicesModal({
                                        data: e,
                                        isOpen: true,
                                        isUpdate: true
                                    })}
                                />
                            </CTabPane>
                        </CTabContent>
                    </CCard>
                </CTabs>
            </CContainer>
            {zoneModal?.key == "originZones" && zoneModal?.isOpen &&
                <ZoneModal
                    title={"Origin Zone"}
                    isUpdate={zoneModal?.isUpdate}
                    onClose={() => setZoneModal(null)}
                    countryLookups={countryLookups}
                    valuesToUpdate={zoneModal?.data}
                    onAdd={(e: Zone) => {
                        setFieldValue("originZones", [...values.originZones || [], e])
                        handleSubmit()
                    }}
                    onUpdate={(newValue: Zone) => {
                        const updatedValues = (values?.originZones || []).map(item =>
                            _.isEqual(item, zoneModal?.data) ? newValue : item
                        )
                        setFieldValue("originZones", updatedValues)
                        handleSubmit()
                    }}
                />
            }
            {zoneModal?.key == "destinationZones" && zoneModal?.isOpen &&
                <ZoneModal
                    title={"Destination Zone"}
                    isUpdate={zoneModal?.isUpdate}
                    onClose={() => setZoneModal(null)}
                    countryLookups={countryLookups}
                    valuesToUpdate={zoneModal?.data}
                    onAdd={(e: Zone) => {
                        setFieldValue("destinationZones", [...values.destinationZones || [], e])
                        handleSubmit()
                    }}
                    onUpdate={(newValue: Zone) => {
                        const updatedValues = (values?.destinationZones || []).map(item =>
                            _.isEqual(item, zoneModal?.data) ? newValue : item
                        )
                        setFieldValue("destinationZones", updatedValues)
                        handleSubmit()
                    }}
                />
            }
            {rateEntryModal?.isOpen &&
                <RatesModal
                    isUpdate={rateEntryModal?.isUpdate}
                    onClose={() => setRateEntryModal(null)}
                    valuesToUpdate={rateEntryModal?.data}
                    onAdd={(e: RateEntry) => {
                        setFieldValue("entries", [...values.entries || [], e])
                        handleSubmit()
                    }}
                    onUpdate={(newValue: RateEntry) => {
                        const updatedValues = (values?.entries || []).map(item =>
                            _.isEqual(item, rateEntryModal?.data) ? newValue : item
                        )
                        setFieldValue("entries", updatedValues)
                        handleSubmit()
                    }}
                />
            }
            {surchargesModal?.isOpen &&
                <SurchargesModal
                    isUpdate={surchargesModal?.isUpdate}
                    onClose={() => setSurchargesModal(null)}
                    valuesToUpdate={surchargesModal?.data}
                    onAdd={(e: Surcharge) => {
                        setFieldValue("surcharges", [...values.surcharges || [], e])
                        handleSubmit()
                    }}
                    onUpdate={(newValue: Surcharge) => {
                        const updatedValues = (values?.surcharges || []).map(item =>
                            _.isEqual(item, surchargesModal?.data) ? newValue : item
                        )
                        setFieldValue("surcharges", updatedValues)
                        handleSubmit()
                    }}
                />
            }
            {rateItemServicesModal?.isOpen &&
                <ServicesModal
                    isUpdate={rateItemServicesModal?.isUpdate}
                    onClose={() => setRateItemServicesModal(null)}
                    valuesToUpdate={rateItemServicesModal?.data}
                    onAdd={(e: RateItemService) => {
                        setFieldValue("rateItemServices", [...values.rateItemServices || [], e])
                        handleSubmit()
                    }}
                    onUpdate={(newValue: RateItemService) => {
                        const updatedValues = (values?.rateItemServices || []).map(item =>
                            _.isEqual(item, rateItemServicesModal?.data) ? newValue : item
                        )
                        setFieldValue("rateItemServices", updatedValues)
                        handleSubmit()
                    }}
                />
            }
        </Fragment>
    );
};
export default CardEditor;