import { CCardBody} from "@coreui/react"
import { useMemo, useState} from "react";
import {GetShipmentLogs} from "../../../../models/getShipmentLogs";
import LogsInfo from "./logsInfo";
import LogsExternal from "./LogsExternal";
import LogsExternalDetails from "./LogsExternalDetails";
import LogsPath from "./LogsPath";
import ViewXmlOrJson from "../../../SharedComponents/ViewXmlOrJson";

export type PathLogsType = {
    name: 'LOGS' | 'EXTERNAL' | 'EXTERNAL_DETAILS' | 'VIEW_DATA',
    title: string,
    externalIndex?: number
    logsIndex?: number
    type?: 'json' | 'xml'
    dataToView?: string
}

type Props = {
    data: GetShipmentLogs[]
}
const ViewLogs = ({data}: Props) => {
    const logsPathInitial: PathLogsType[] = [{title: 'Logs', name: 'LOGS'}]
    const [logsPathState, setLogsPathState] = useState<PathLogsType[]>(logsPathInitial)

    const addLogs = (pathEvent: PathLogsType) => {
        const path = [...logsPathState]
        path.push({
            ...pathEvent,
            externalIndex: pathEvent.externalIndex ?? logsPathState[logsPathState.length - 1].externalIndex ?? 0,
            logsIndex: pathEvent.logsIndex ?? logsPathState[logsPathState.length - 1].logsIndex ?? 0,

        })

        setLogsPathState(path)

    }
    const changeLogs = (pathEvent: PathLogsType) => {
        if (data.length==1)
            setLogsPathState([pathEvent])

       else setLogsPathState([...logsPathState,pathEvent])

    }
    const removeLog = (name: string) => {
        let path: PathLogsType[] = []
        let found = false;
        logsPathState.forEach((d) => {
            if (!found) {
                path.push(d)
                if (name === d.name) {
                    found = true

                }
            }
        })
        setLogsPathState(path)
    }
    const componentToLoad = useMemo(() => {
            if (logsPathState[logsPathState.length - 1].name === 'LOGS')
                return <LogsInfo addPath={addLogs} changeLogs={changeLogs} data={data}/>
            else if (logsPathState[logsPathState.length - 1].name === 'EXTERNAL')
                return <LogsExternal addPath={addLogs} data={data[logsPathState[logsPathState.length - 1].logsIndex!]}/>
            else if (logsPathState[logsPathState.length - 1].name === 'EXTERNAL_DETAILS')
                return <LogsExternalDetails addPath={addLogs}
                                            data={data[logsPathState[logsPathState.length - 1].logsIndex!].log.ExternalCallLogs[logsPathState[logsPathState.length - 1].externalIndex!]}/>
            else if (logsPathState[logsPathState.length - 1].name === 'VIEW_DATA')
                return <ViewXmlOrJson name={logsPathState[logsPathState.length - 1].title}
                                      data={logsPathState[logsPathState.length - 1].dataToView!}
                                      type={logsPathState[logsPathState.length - 1].type!}/>
        }
        , [logsPathState, data, addLogs]
    )


    return (
        <>

            <CCardBody style={{minHeight: '500px'}} className={`bg-white p-5 text-black small`}>
                <LogsPath logsPath={logsPathState} removePath={removeLog}/>
                {componentToLoad}
            </CCardBody>


        </>
    )
}

export default ViewLogs
