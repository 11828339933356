


import React from 'react';
import { CTooltip } from '@coreui/react';

type Props = {
    color?: string;
    onClick: () => void;
    tooltipText: string;
    tabIndex?: number
    style?:React.CSSProperties;
}


const ResetIcon = ({ onClick, color, tooltipText,tabIndex ,style}: Props) => {
    return (

        <CTooltip content={tooltipText}>
            <svg
             style={{ ...style,cursor: 'pointer'}}
               tabIndex={tabIndex}
                width={"25"}
                height={"25"}
                onClick={onClick}
                viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg" fill="#000000" stroke="#000000" strokeWidth="1.218"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g fill="none" fillRule="evenodd" stroke={color??"#000000"} strokeLinecap="round" strokeLinejoin="round" transform="matrix(0 1 1 0 2.5 2.5)"> <path d="m3.98652376 1.07807068c-2.38377179 1.38514556-3.98652376 3.96636605-3.98652376 6.92192932 0 4.418278 3.581722 8 8 8s8-3.581722 8-8-3.581722-8-8-8"></path> <circle cx="8" cy="8" fill={color ??"#000000"} r="2"></circle> <path d="m4 1v4h-4" transform="matrix(1 0 0 -1 0 6)"></path> </g> </g></svg>
        </CTooltip>
    );
};

export default ResetIcon;







