import React from "react";
import { CCol, CModal, CModalBody, CModalFooter, CModalHeader, CRow } from "@coreui/react";
import ActionButton from "../../../../SharedComponents/ActionButton";
import { useFormik } from "formik";
import * as yup from "yup"
import SWInput from "../../../../SharedComponents/SWInput";
import SWTextArea from "../../../../SharedComponents/SWTextArea";
import { ServiceAdd } from "../../../../../models/retes";
import RatesClient from "../../../../../clients/ratesClient";
import { useDispatch } from "react-redux";
import { ShowNotification } from "../../../../../store/actions/auth";


interface Props {
    onClose: () => void
    agentCode: string
    refreshAgentServices: () => void
}

const AddNewServiceModal = (props: Props) => {
    const { onClose, agentCode, refreshAgentServices } = props;
    const ratesClient = new RatesClient();
    const dispatch = useDispatch()
    const { values, setFieldValue, resetForm, touched, errors, submitForm } = useFormik<ServiceAdd>({
        enableReinitialize: true,
        initialValues: {
            serviceCode: "",
            serviceName: "",
            serviceDescription: ""
        },
        onSubmit: async () => {
            const res = await ratesClient.AddAgentService(values, agentCode)
            if (res?.succeeded) {
                onClose()
                resetForm()
                dispatch(ShowNotification("Success", "Saved Successfully!", false));
                refreshAgentServices()
            } else {
                dispatch(ShowNotification("Error", res?.error, true));
            }
        },
        validationSchema: yup.object({
            serviceCode: yup.string().required("Required"),
            serviceName: yup.string().required("Required"),
            serviceDescription: yup.string().required("Required"),

        })
    })
    return (
        <div className="small text-primary">
            <CModal
                closeOnBackdrop={false}
                show={true}
                centered
                onClose={props.onClose}
            >
                <CModalHeader closeButton>
                    <h5>Add new service</h5>
                </CModalHeader>
                <CModalBody>
                    <SWInput
                        label="Code"
                        name={"serviceCode"}
                        value={values.serviceCode ?? ""}
                        onChange={(e) => setFieldValue("serviceCode", e)}
                        type={"text"}
                        validationErrors={touched?.serviceCode && errors?.serviceCode?.toString() || ""}
                    />
                    <SWInput
                        label="Name"
                        name={"serviceName"}
                        value={values.serviceName ?? ""}
                        onChange={(e) => setFieldValue("serviceName", e)}
                        type={"text"}
                        validationErrors={touched?.serviceName && errors?.serviceName?.toString() || ""}
                    />
                    <SWTextArea
                        rows={5}
                        label={"Description"}
                        name={"serviceDescription"}
                        value={values?.serviceDescription ?? ""}
                        onChange={(e) => setFieldValue("serviceDescription", e)}
                        type={"text"}
                        validationErrors={touched?.serviceDescription && errors?.serviceDescription?.toString() || ""}
                    />
                </CModalBody>
                <CModalFooter>
                    <CRow className="justify-content-between w-100">
                        <CCol className="text-right">
                            <ActionButton
                                text={"Submit"}
                                onClick={() => submitForm()}
                            />
                        </CCol>
                    </CRow>
                </CModalFooter>
            </CModal>
        </div>

    )
}

export default AddNewServiceModal