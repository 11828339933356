import {Field, Form, Formik} from "formik";
import {
    CButton,
    CButtonGroup,
    CCol,
    CContainer,
    CInputCheckbox,
    CInputRadio,
    CLabel,
    CRow,
    CSpinner
} from "@coreui/react";
import InputField from "../../../SharedComponents/FormikCustomFields/InputField";
import React, {useEffect, useState} from "react";
import {IAddAccount} from "../../../../models/shipment";
import * as yup from "yup"
import {useDispatch} from "react-redux";
import SWSelect from "../../../SharedComponents/SWSelect";
import AccountingClient from "../../../../clients/accountingClient";
import ActionButton from "../../../SharedComponents/ActionButton";
import SWMultiSelect from "../../../SharedComponents/SWMultiSelect";
import {ShowNotification} from "../../../../store/actions/auth";
import {useHistory} from "react-router";
const preferredLanguagesValues = {
    "en": "English",
    "fr": "France",

};

interface Props {
    setIsSubmitting?: any;
    accountInfo?: IAddAccount;
    setAccountInfo?: React.Dispatch<React.SetStateAction<IAddAccount | undefined>>;
    isEdit: boolean;
    countryLookups: { [k: string]: string }

}


const AddAccountForm = (props: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [accountingProfiles, setAccountingProfiles] = useState<{ [k: string]: string }>({});
    const his = useHistory()

    const initialAgentAndCustomer = {
        isAgent:props.isEdit && (props.accountInfo?.accountType == 2 || props.accountInfo?.accountType == 3),
        isCustomer:props.isEdit && (props.accountInfo?.accountType == 1 || props.accountInfo?.accountType == 3),
    }

    const initialValues = {
        dueDays: props.accountInfo?.dueDays ?? undefined,
        eori: props.accountInfo?.eori,
        id: props.accountInfo?.id ?? "",
        name: props.accountInfo?.name ?? "",
        publicName: props.accountInfo?.publicName ?? "",
        configurations: {
            preferredLanguages: props.accountInfo?.configurations?.preferredLanguages ?? [],
        },
        profileId: props.accountInfo?.profileId ?? '',
        taxType: props.accountInfo?.taxType ?? props.isEdit ? "": 'VAT' ,
        state: props.accountInfo?.state ?? "",
        street: props.accountInfo?.street ?? "",
        postCode: props.accountInfo?.postCode ?? "",
        email: props.accountInfo?.email ?? "",
        country: props.accountInfo?.country ?? "",
        addressLine1: props.accountInfo?.addressLine1 ?? "",
        addressLine2: props.accountInfo?.addressLine2 ?? "",
        addressLine3: props.accountInfo?.addressLine3 ?? "",
        phone: props.accountInfo?.phone ?? "",
        secondPhone: props.accountInfo?.secondPhone ?? "",
        vat: props.accountInfo?.vat ?? "",
        city: props.accountInfo?.city ?? "",
        attachment: props.accountInfo?.attachment ?? [],
        isAgent: (props.accountInfo?.accountType == 2 ||props.accountInfo?.accountType == 3)  ?? false,
        isCustomer: (props.accountInfo?.accountType == 1 || props.accountInfo?.accountType == 3) ?? false,
        notes: props.accountInfo?.notes ?? "",
        contactPerson: props.accountInfo?.contactPerson ?? "",

    };
    const dispatch = useDispatch();
    const validationSchema = yup.object().shape({
        id: yup.string().required("required"),
        profileId: yup.string().test('Profile-Id','Profile Id Is required', function (value) {
            if(props.isEdit)
                return true
            return !this.parent.isCustomer;
        }),
        isAgent:yup.boolean().test('agentOrCustomer','Agent Or Customer is required', function (value) {

            return (this.parent.isCustomer || this.parent.isCustomer);
        }),
        email: yup.string().email("Invalid email address"),
        vat: yup.string().when('taxType', {
            is: 'EU',
            then: yup.string().required('VAT is required when Tax Type is EU'),
            otherwise: yup.string(),
        }),

    });
    const clearForm = (form: any) => {
        form.resetForm();
    };


    const accountingClient = new AccountingClient();


    useEffect(() => {
        GetAccountingProfiles();
    }, [])

    const GetAccountingProfiles = async () => {
        const profiles = await accountingClient.LookupProfiles()
        const res = await accountingClient.GetAllowedProfiles({})
        if (res?.allowAllProfiles) {
            setAccountingProfiles(profiles)
            return
        }
        const allowedProfileIds = res?.allowedProfileIds
        if (res.allowedProfileIds) {
            let dictionary: {
                [k: string]: string
            } = Object.assign({}, ...allowedProfileIds.map((x) => ({[x]: profiles[x]})));
            setAccountingProfiles(dictionary)
        }
    }


    const handleSubmit = async (values: any, formikHelpers: any) => {
        let rs = null;

        if (props?.isEdit) {
            rs = await accountingClient.UpdateAccount(values);

        } else {
            rs = await accountingClient.CreateAccount(values);
        }
        if(rs) {
            dispatch(ShowNotification('success', props.isEdit ? "The Account has been edited." : "The Account has been Added.", false))
            if (!props?.isEdit) {
                his.push('/financial/account')
            }
        }




    };


    return (
        <fieldset className="b-fieldset p-2 text-primary">
            {!props.isEdit && <legend className="b-legend" style={{width: props.isEdit ? "110px" : '130px'}}>
                <h5>Create Account</h5>
            </legend>}
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                validateOnChange={false}
            >
                {(formik) => (
                    <>

                        {isLoading ?
                            <CSpinner
                                className="mx-auto d-block my-5"
                                color="primary"
                                style={{width: "5em", height: "5em"}}
                            /> :

                            <Form>
                                <CContainer className="form-group mb-0">
                                    <CCol md={12}>
                                        <CRow>
                                            <CCol md={8}>
                                                <CRow>

                                                    <CCol md={4}>
                                                        <SWSelect values={accountingProfiles}
                                                                  value={formik.values?.profileId ? formik.values?.profileId.toString() :null}
                                                                  labelClassName={"text-lg"}
                                                                  validationErrors={formik.errors?.profileId}
                                                                  label={"Profile"}
                                                                  onChange={(e) => formik.setFieldValue(`profileId`, e)}
                                                        />
                                                    </CCol>
                                                    <CCol md={4}>
                                                        <div>Account Type</div>
                                                        <CRow className={"flex justify-content-around "}>
                                                            <div>
                                                                <CInputCheckbox label={"Customer"} name={"isCustomer"}
                                                                                checked={formik.values?.isCustomer}
                                                                                disabled={initialAgentAndCustomer.isCustomer}
                                                                                onChange={(e) => {
                                                                                    formik.setFieldValue(`isCustomer`, !formik.values?.isCustomer)
                                                                                }}/>
                                                                <CLabel className={"mt-1"}>Customer</CLabel>
                                                            </div>
                                                            <div>
                                                                <CInputCheckbox label={"Customer"} name={"isAgent"}
                                                                                disabled={initialAgentAndCustomer.isAgent}
                                                                                checked={formik.values?.isAgent}

                                                                                onChange={(e) =>
                                                                                    formik.setFieldValue(`isAgent`, !formik.values?.isAgent)
                                                                                }/>
                                                                <CLabel className={"mt-1"}>Agent</CLabel>
                                                            </div>

                                                        </CRow>
                                                        {formik.errors.isAgent &&<div style={{color:'#E55353'}}>{formik.errors.isAgent}</div>}
                                                    </CCol>
                                                    <CCol md={4}>
                                                    <SWMultiSelect label={'Languages'}
                                                                       values={preferredLanguagesValues}
                                                                       value={formik.values?.configurations?.preferredLanguages?.map(value => {
                                                                           return {
                                                                               value: value,
                                                                               label: preferredLanguagesValues[value as keyof typeof preferredLanguagesValues]
                                                                           }
                                                                       })}
                                                                       onChange={(e: {
                                                                           value: string,
                                                                           label: string
                                                                       }[]) => {
                                                                           const selectedValues = e.map(option => option.value);
                                                                           formik.setFieldValue(`configurations.preferredLanguages`, selectedValues)
                                                                       }}/>

                                                    </CCol>


                                                </CRow>

                                                <CRow>

                                                    <CCol md={4}>
                                                        <Field type="string" name="id" label={"Account Code"}
                                                               readonly={props?.isEdit}
                                                               component={InputField}/>
                                                    </CCol>
                                                    <CCol md={4}>
                                                        <Field type="text" name="name" label={"Company Name"}
                                                               component={InputField}/>
                                                    </CCol>
                                                    <CCol md={4}>
                                                        <Field type="text" name="publicName" label={"Internal Name"}
                                                               component={InputField}/>

                                                    </CCol>


                                                </CRow>
                                                <CRow>

                                                    <CCol md={4}>
                                                        <Field type="string" name="addressLine1" label={"Street"}
                                                               component={InputField}/>
                                                    </CCol>
                                                    <CCol md={4}>
                                                        <Field type="string" name="addressLine2"
                                                               label={"Additional Address"}
                                                               component={InputField}/>
                                                    </CCol>
                                                    <CCol md={4}>
                                                        <Field type="string" name="addressLine3"
                                                               label={"Additional Address 2"}
                                                               component={InputField}/>

                                                    </CCol>


                                                </CRow>
                                                <CRow>

                                                    <CCol md={4}>
                                                        <Field type="string" name="postCode" label={"Post code"}
                                                               component={InputField}/>
                                                    </CCol>
                                                    <CCol md={4}>
                                                        <Field type="string" name="city"
                                                               label={"City"}
                                                               component={InputField}/>
                                                    </CCol>
                                                    <CCol md={4}>
                                                        <SWSelect
                                                            labelClassName={"text-lg"}

                                                            label={"Country"}
                                                            validationErrors={formik.errors?.country?.toString() || ""}
                                                            name={`country`}
                                                            value={formik.values?.country ?? ""}
                                                            onChange={(e) => formik.setFieldValue(`country`, e)}
                                                            values={props.countryLookups}
                                                        />


                                                    </CCol>


                                                </CRow>
                                            </CCol>

                                            <CCol md={4} className={'border-dark rounded-lg pt-1 mb-3'}>
                                                <CCol md={12}>

                                                    <CRow style={{marginLeft: "6px"}}>
                                                        <CCol>
                                                            <CInputRadio
                                                                style={{
                                                                    width: "1.25em",
                                                                    height: "1.25em",
                                                                    fontSize: "10px",
                                                                }}
                                                                checked={ formik.values?.taxType.toUpperCase() == 'VAT'}
                                                                onClick={() => {
                                                                    formik.setFieldValue(`taxType`, 'VAT')
                                                                }}
                                                            />
                                                            <CLabel>VAT</CLabel>
                                                        </CCol>
                                                        <CCol>
                                                            <CInputRadio
                                                                style={{
                                                                    width: "1.25em",
                                                                    height: "1.25em",
                                                                    fontSize: "10px",
                                                                }}
                                                                checked={formik.values?.taxType.toUpperCase() == 'EU'}
                                                                onClick={() => {
                                                                    formik.setFieldValue(`taxType`, 'EU')
                                                                }}
                                                            />
                                                            <CLabel>EU</CLabel>
                                                        </CCol>
                                                        <CCol>
                                                            <CInputRadio
                                                                style={{
                                                                    width: "1.25em",
                                                                    height: "1.25em",
                                                                    fontSize: "10px",
                                                                }}
                                                                checked={formik.values?.taxType.toUpperCase() == 'NON-EU'}
                                                                onClick={() => {
                                                                    formik.setFieldValue(`taxType`, 'NON-EU')
                                                                }}
                                                            />
                                                            <CLabel>NON-EU</CLabel>
                                                        </CCol>
                                                    </CRow>
                                                </CCol>
                                                <CCol md={12}>
                                                    <CRow>
                                                        <CCol md={6}>
                                                            <Field type="text" name="vat" label={"Vat"}
                                                                   component={InputField}/>
                                                        </CCol>
                                                        <CCol md={6}>
                                                            <Field type="text" name="eori" label={"Eori"}
                                                                   component={InputField}/>
                                                        </CCol>
                                                    </CRow>
                                                </CCol>
                                                <CCol md={12}>
                                                    <CRow>
                                                        <CCol md={12}>
                                                            <Field type="number" name="dueDays"
                                                                   label={"Invoicing Due Days"}
                                                                   component={InputField}/>

                                                        </CCol>
                                                    </CRow>
                                                </CCol>
                                                <CCol md={12}>
                                                    <CRow>
                                                        <CCol md={12}>
                                                            <Field type="text" name="notes" label={"Notes"}
                                                                   component={InputField}/>

                                                        </CCol>
                                                    </CRow>
                                                </CCol>
                                            </CCol>

                                        </CRow>

                                        <CRow>
                                            <CCol md={8}>
                                                <CRow>
                                                    <CCol md={4}>
                                                        <Field type="text" name="contactPerson" label={"Contact Name"}
                                                               component={InputField}/>
                                                    </CCol>
                                                    <CCol md={4}>
                                                        <Field type="text" name="email" label={"Email"}
                                                               component={InputField}/>
                                                    </CCol>
                                                    <CCol md={4}>
                                                        <Field type="string" name="phone" label={"Phone"}
                                                               component={InputField}/>

                                                    </CCol>
                                                </CRow>
                                            </CCol>
                                            <CCol md={4}> <CCol md={9} sm={12}>
                                                <Field type="string" name="secondPhone" label={"Phone 2"}
                                                       component={InputField}/>


                                            </CCol>
                                            </CCol>
                                        </CRow>


                                    </CCol>
                                    <CCol className="text-right">
                                        <CButtonGroup className="mb-0 mr-2">
                                            {!props.isEdit && <CButton
                                                variant="outline"
                                                size="sm"
                                                color="danger"
                                                onClick={() => clearForm(formik)}
                                                style={{fontSize: "10px"}}
                                            >
                                                Clear
                                            </CButton>}
                                        </CButtonGroup>
                                        <CButtonGroup className="mb-0 ">
                                            <ActionButton text={props.isEdit ? "Update" : "Add"} type="submit"/>
                                        </CButtonGroup>
                                    </CCol>

                                </CContainer>
                            </Form>
                        }
                    </>
                )}
            </Formik>

        </fieldset>


    )
        ;
}

export default AddAccountForm;
