import DateFormat from "dateformat";
import {format} from "date-fns";

export const formatDateTime = (val?: string | null | Date) => {

    try {
        if (!val)
            return ""
        return DateFormat(val, "dd-mm-yyyy") + " " + DateFormat(val, "shortTime");
    } catch (error) {
        // @ts-ignore
        console.error("An error occurred:", error.message);
        return ""
    }
}

export const formatTime = (val?: string | null | Date) => val ? DateFormat(val, "shortTime") : "";

export const formatDate = (val?: string | null | Date) => val ?
    DateFormat(val, "dd-mm-yyyy") : "";

export const getLocalDateTime = () => format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");

export const convertToLocalDate = (dateStr: string) => {
    if (!dateStr) return ""
    const date = new Date(dateStr + "Z")
    return date?.toLocaleString()
}