import {CCol, CModal, CModalBody, CModalHeader, CRow } from "@coreui/react";
import React from "react";
import {ShipmentAddTraceResult, ShipmentAddTraceResultDetails} from "../../../../models/shipment";
import {formatDateTime} from "../../../../utils/dateUtil";

interface IProps {
    result: ShipmentAddTraceResultDetails[]
    handleClose: () => void
}

const getClassName= (d:ShipmentAddTraceResultDetails)=> d.existingTraces.length> 0 ? "text-primary" : "text-danger"

const BulkTraceResultModal = (props : IProps) => (
    <CModal show={true} onClose={props.handleClose} size={"lg"}>

        <CModalBody>

            {props.result?.map(r => 
                <>
                    <CRow className={getClassName(r)}>
                        
                        <CCol>
                            {r.number}
                        </CCol>
                        <CCol>
                            {r.shipmentNotFound ? "ERROR! Shipment not found" : ""}
                            {r.duplicateTrace ? "ERROR! Can not add duplicate trace" : ""}
                            {r.shipmentRepeated == 2 ? "ERROR! Shipment duplicate" : ""}
                            {r.shipmentRepeated > 2 ? `ERROR! Shipment repeated ${r.shipmentRepeated} times` : ""}
                            {r.existingTraces.length> 0  && !r.duplicateTrace ? "(SAVED) Trace already found on :" +
                                r.existingTraces.map(s=> formatDateTime(s)).join(',') : ""}
                        </CCol>
                    </CRow>
                    <hr/>
                </>)}
        </CModalBody>
    </CModal>
)

export default BulkTraceResultModal;