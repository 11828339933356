import Login from "../ViewComponents/Login/Login";
import Logout from "../ViewComponents/Login/Logout";
import ResetPassword from "../ViewComponents/ResetPassword/ResetPassword";
import {useLocation} from "react-router";
import {useSelector} from "react-redux";
import {AppModel} from "../../models/app";
import {  Redirect, Route, Switch,} from "react-router-dom";
import {useEffect} from "react";


const publicRoute = [
    {
        path: '/Login',
        component: Login
    },
    {
        path: '/Logout',
        component: Logout
    },
    {
        path: '/ResetPassword',
        component: ResetPassword
    },
]

const PublicMain = () => {
    const location = useLocation();
    const isAuthenticated = useSelector((state: AppModel) => state.app.isAuthenticated);
    const isRedirect = !isAuthenticated && !publicRoute.some(r => location.pathname===r.path)

    return (<>
        {isRedirect && <Redirect to={'/Login'}/>}
        {publicRoute.map(r => {
            return <Route path={r.path} component={r.component}/>
        })}
    </>)

}
export default PublicMain
