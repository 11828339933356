import {BasePagedSearch, IPagination} from "./filter";

export interface SkuCreate {
    id: number;
    accountNumber: string;
    code: string;
    internalCode: string;
    name: string;
    dimensions: Dimensions;
    weight: Weight;
    active: boolean | null;
    allocationStrategy: AllocationStrategy;
    allocationType: AllocationType;
    seller?: string | null;
    barcode: string;
    barcodes?: string[];
    warehouse: string;
    breakCount: number;
    minReorder: number;
    legacyId?: number;
    quantityAvailable?: number
    expiryDays?: number | null
    imageUrls?: string[]
    imageUrl?: string

}

export interface SkuUpdate {
    id: number;
    name: string;
    dimensions: Dimensions;
    weight: Weight;
    allocationStrategy: AllocationStrategy;
    allocationType: AllocationType;
    seller: string;
    warehouse: string;
    expiryDays?: number | null
    imageUrls?: string[]
}

export interface ItemReservation {
    account: string;
    warehouse: string | null;
    updatedSkus: updatedSKU[]
}

export interface AddItemReservation {
    warehouse: string | null;
    lot: {
        account: string | null;
        sku: string | null;
        status: string | null;
        batchNumber: string | null;
        expiryDate: Date | null;
        itemNumber: string | null;
    },
    area: string | null;
    location: string | null;
    quantity: string | null;
}

export interface updatedSKU {
    code: string | null;
    quantity: string | null;
}


export interface SkuSearch extends SkuCreate {
    createdOn: Date
    allowedWarehouses: number[]
    quantity?: number
}

export interface SkuSearchDetailsReportItem extends SkuSearch {
    status: string
    batchNumber: string
    itemNumber: string
    expiryDate: Date
    location: string
}

export interface subType {
    id: string
    name: string
    type: string
}

export interface SkuSearchFilter extends IPagination {
    showDetails?: boolean;
    id?: string
    lookup?: boolean
    codeAndName?: string;
    inActive?: boolean;
    accountNumber?: string;
    code?: string;
    codes?: string
    barcode?: string;
    internalCode?: string;
    name?: string;
    seller?: string;
    profile?: string;
    allocationStrategy?: AllocationStrategy | undefined;
    includeInactive?: boolean
    expiryDate?: string
    batchNumber?: string
    ItemNumber?: string
    Barcode?: string
    warehouse?: string
    location?: string
    ExportToExcel?: boolean
    calculateQuantityForOrders?: boolean
}

export enum AllocationStrategy {
    None,
    ExpiryDate,
    BatchNumber,
    Serial
}

export enum AllocationType {
    Fifo,
    Lifo
}


export interface Dimensions {
    length: number | null;
    width: number | null;
    height: number | null;
    unit?: DimensionUnit | null;
}

export enum DimensionUnit {
    cm = 0,
    M = 1,
    in = 2
}

export interface Weight {
    value: number | null;
    unit?: WeightUnit | null;
}

export enum WeightUnit {
    gm = 0,
    kg = 1,
    lb = 2,
    oz = 3
}

export enum SkuStatus {
    intact,
    coverDamaged,
    damaged
}

export interface InventoryTransaction {
    shipmentNumber?: string;
    code?: string;
    posted?: boolean;
    reversalCode?: string
    reversedCode?: string
    subType: string;
    accountNumber: string
    reference?: string
    on?: string
    createdOn?: string
    warehouse: string
    store: string
    area: string
    type: string
    notes?: string
    details: InventoryTransactionDetailDto[]
    toArea?: string
    toLocation?: string
    location?: string
    batchNumber?: string
    itemNumber?: string
    barcode?: string
    sku?: string
    expiryDate?: string
    quantity?: number
    status?: SkuStatus
    newStatus?: string,
    attachments?: TransactionAttachmentDto[]

}

export interface InventoryTransferringRequest extends InventoryTransaction {

}

export interface InventoryReceivingRequest extends InventoryTransaction {
    post?: boolean
}

export interface InventoryTransactionDetailDto {
    lot: LotDto
    warehouse: string;
    store: string
    area: string
    location: string
    current?: number
    available?: number
    newStatus?: string
    quantity?: number
    quantityAvailable?: number
    fromWarehouse?: string;
    fromStore?: string
    fromArea?: string
    fromLocation?: string
    toWarehouse?: string;
    toStore?: string
    toArea?: string
    toLocation?: string
    relatedDetails?: InventoryTransactionDetailDto[]
    transaction?: InventoryTransaction
}

export interface warehouseDto {
    warehouse: warehouse
    area: [area]
    location: [location]
}

export interface warehouse {
    id: number
    code: string
    name: string
}

export interface area {
    id: number
    code: string
    name: string
    warehouse: string
}

export interface location {
    id: number
    code: string
    name: string
    area: string
    store: string
    warehouse: string
}

export interface LotDto {
    account: string
    itemNumber?: string;
    sku: string
    skuName?: string
    skuWeight?: number;
    skuWeightUnit?: string;
    status: SkuStatus
    batchNumber?: string
    serialNumber?: string
    expiryDate?: string
    skuId?: number
}

export interface WarehouseDto {
    code: string,
    name: string
}

export interface WarehouseAreaDto {
    code: string,
    name: string,
    warehouse: string
}

export interface WarehouseLocationDto {
    code: string,
    name: string,
    warehouse: string,
    area: string
}

export interface InventoryTransactionSearchFilter extends IPagination {
    closedReservation?: boolean;
    nullReversed?: boolean;
    reversed?: boolean;
    openReservation?: boolean;
    on?: string;
    account: string
    code?: string
    draftOnly?: boolean;
    postedOnly?: boolean;
    subType?: string;
    onFrom?: string;
    onTo?: string;
    reference?: string;
    DateTypeFilter?: string
    shipmentNumber?: string
    showDetails?: boolean
    exportToExcel?: boolean
    isNotPagination?: boolean
}

export interface InventorySummeryReportQueryParameters {
    account?: string
    warehouse?: string
    store?: string
    area?: string
    allocationStrategy?: AllocationStrategy
}

export interface InventorySummeryReportItem {
    account: string
    accountNumber: string
    code: string
    name: string
    quantity: number
    quantityAvailable: number
    seller: string
}

export interface InventoryDetailsReportQueryParameters {
    account: string
    code: string
    warehouse?: string
    store?: string
    area?: string
    location?: string
    excludeZero?: boolean
    ExportToExcel?: boolean
    batchNumber?: string
    itemNumber?: string
    expiryDate?: string
}

export interface InventoryTransactionDetailsReportQueryParameters {
    account: string
    sku: string
    warehouse?: string
    store?: string
    area?: string
    location?: string
    excludeZero?: boolean
    ExportToExcel?: boolean
}

export interface InventoryDetailsReportItem {
    code: string;
    sku?: string;
    skuCode?: string
    account?: string
    warehouse: string,
    store: string,
    area: string,
    location: string,
    status: SkuStatus,
    batchNumber?: string,
    itemNumber?: string,
    expiryDate?: string,
    serialNumber?: string,
    quantity: number,
    quantityAvailable: number
}

export interface SkuLookup {
    code: string
    excludeZero?: boolean
    account?: string
    warehouse?: string
    maxResults?: number
}

export interface SkuLookupResult {
    id: number
    code: string
    name: string
    allowedWarehouses: number[]
    allowedWarehousesCodes: string[]
    accountId: string
    accountName: string
    warehouseCode: string
    warehouseName: string
    quantity: number
    quantityAvailable: number
}

export interface InventoryTransactionDetailsSearchModel extends InventoryTransactionSearchFilter {
    account: string
    sku: string
    IsNotPagination?: boolean

}

export interface InventoryTransactionDetailReportItem extends InventoryTransactionDetailDto {
    account?: string
    code: string;
    posted: boolean;
    subType: string;
    accountNumber: string
    reference?: string
    shipmentNumber?: string
    on?: string
    warehouse: string
    store: string
    area: string
    type: string
    notes?: string
    createdOn: string
    createdBy: string
    skuCode?: string,
    sku?: string,
    skuWeight?: number,
    skuWeightUnit?: string,
    expiryDate?: string,
    batchNumber?: string,
    itemNumber?: string
}


export interface OpenReservationDetails {
    transactionCode: string
    on: string
    createdOn: string
    reference?: string
    batchNumber?: string
    itemNumber?: string
    expiryDate?: string
    quantityReserved: number
    quantityConsumed: number
}

export interface OpenReservationDetailsRequest {
    skuCode: string
    account: string
    limit: number
    offset: number
}

export interface SellerSearch {
    id: string,
    name: string
}

export interface TransactionAttachmentDto {
    id: number;
    location: string;
    fileName: string;
    type: string;
    urlKey: string;
    inventoryTransactionId: number;
    createdBy: string;
    createdOn: string;
    getAttachmentLink: string
}

export interface TransactionAttachment {
    location: string;
    fileName: string;
    type: string;
    urlKey: string;
    inventoryTransactionId: number;
}

export interface TransactionAttachmentSearchRequest extends BasePagedSearch {
    inventoryTransactionId?: number | null;
    type?: string;
    location?: string;
    fileName?: string;
    urlKey?: string;
    deleted?: boolean;
    active?: boolean;
}

export interface AddBulkTransactionAttachmentRequest {
    attachments: AddTransactionAttachmentRequest[];
}

export interface AddTransactionAttachmentRequest {
    type: string;
    location: string;
    fileName: string;
    inventoryTransactionCode: string;
}

export interface DeleteTransactionAttachmentRequest {
    id: number;
}

export interface UpdateTransactionAttachmentRequest {
    id: number;
    type: string;
    fileName: string;
}

export interface AccountingWarehouseType {

    defaultDimensionUnit?: DimensionUnit | null;
    defaultWeightUnit?: WeightUnit | null;
    allowedWarehouses?: number[];
    allowedSellers?: string[] | null;
    minimumExpiryDays?: number | null;
    name?: string;
    active?: boolean
    currency?: string,
    defaultSellerCode?: string,
    warehouse?: string,

}

export interface SkuSearchResultForBoxes {
    code: string;
    barcodes: string[];

}
