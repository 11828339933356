import React, {useState} from "react";
import {
    CButton,
    CCol,
    CInput,
    CInputCheckbox,
    CLabel,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CRow,
} from "@coreui/react";
import DateAndTimePicker from "../../../SharedComponents/SWForm/DateAndTimePicker";
import {QuotationUpdate} from "../../../../models/quotation";
import ActionButton from "../../../SharedComponents/ActionButton";

interface IProps {
    onClose: () => any;
    onSubmit: () => any;
}

const Component = (props: IProps) => {


    const {onClose, onSubmit} = props;
    const [disabled, setDisabled] = useState(false);
    const onClick = () => {
        setDisabled(true);
        onSubmit()

    }


    return (
        <div className="small">
            <CModal show={true} onClose={onClose} centered>
                <CModalHeader>
                    <h5>Send Invoice</h5>
                </CModalHeader>
                <CModalBody>
                    Are you sure you want to send  invoice
                </CModalBody>
                <CModalFooter>
                    <CRow className="justify-content-between w-100">
                        <CCol className="text-left">
                            <CButton style={{fontSize: "10px"}} variant="outline" color="danger" onClick={onClose}>
                                Cancel
                            </CButton>
                        </CCol>
                        <CCol className="text-right">
                            <ActionButton
                                text={"Yes"}
                                disabled={disabled}
                                onClick={onClick}
                            />
                        </CCol>
                    </CRow>
                </CModalFooter>
            </CModal>
        </div>

    );
};

export default Component;
