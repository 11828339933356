import {
    AccountAppModel,
    Action,
} from "../../models/app";
import initialState from "../initialStates/accountInitialState";
import {
    SET_ACCOUNT_FILTER_GROUP,
    SET_ACCOUNT_IS_LOADING,
    SET_ACCOUNT_RESULT,
} from "../types";

const accountReducer = (
    state: AccountAppModel = initialState,
    action: Action
): AccountAppModel => {
    switch (action.type) {
        case SET_ACCOUNT_FILTER_GROUP:
            return {
                ...state,
                accountSearch: {
                    ...state.accountSearch,
                    filterGroup: {
                        ...state.accountSearch.filterGroup,
                        ...action.payload!,
                    },
                },
            };

        case SET_ACCOUNT_IS_LOADING:
        case SET_ACCOUNT_RESULT:
            return {
                ...state,
                accountSearch: {
                    ...state.accountSearch,
                    ...action.payload,
                },
            };
        default:
            return state;
    }
};

export default accountReducer;
