import {
  CButton,
  CImg,
  CSidebar,
  CSidebarBrand,
  CSidebarFooter,
  CRow,
  CCol,
  CForm,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { freeSet } from "@coreui/icons";
import React, { useState } from "react";
import routes from "../../routes";
import Navmenu from "../RoutingComponents/NavMenu";
import { useDispatch, useSelector } from "react-redux";
import { AppModel, AuthenticationAppModel } from "../../models/app";
import { cilAccountLogout } from "@coreui/icons";
import { getMsalInstance } from "../../utils/msalUtils";
import cookieManager from "../../services/cookieManager";
import { LogOut, SetLoggingIn, SetUser } from "../../store/actions/auth";
import AuthClient from "../../clients/authClient";
import {useHistory} from "react-router";

interface Props {}
const Sidebar = (props: Props) => {
  const [show, setShow] = useState(true);
  const name = useSelector((state: AppModel) => state.app.user?.displayName);
  const dispatch = useDispatch();
  const hist=useHistory()

  return (
    <CSidebar className="c-sidebar-light" show={show}>
      <CSidebarBrand className="bg-white" to="/">
        <CCol>
          <CImg height={45} fluid src="/logo.svg" />
        </CCol>
      </CSidebarBrand>
      <Navmenu routesData={routes} />

      <CSidebarFooter className="bg-white divider-top">
        <CRow>
          <span className="c-sidebar-nav-title">{name}</span>
        </CRow>
        <CRow>
          <CForm
            onSubmit={(e) => {
              e.preventDefault()
              dispatch(LogOut())
            }}

          >
            <CButton type="submit">
              <CIcon content={freeSet.cilLockLocked} className="mfe-2" />
              Logout
            </CButton>
          </CForm>
        </CRow>
      </CSidebarFooter>
    </CSidebar>
  );
};

export default Sidebar;
