import {
  CButton,
  CCol,
  CContainer,
  CFormText,
  CRow,
  CTooltip,
} from "@coreui/react";
import { Form, FormRef, Input, Submit } from "../../SharedComponents/SWForm";
import React, { useEffect, useRef, useState } from "react";
import CheckoutClient from "../../../clients/checkoutClient";
import { useHistory } from "react-router";
import { notEmpty } from "../../../utils/commonValidators";
import RatesClient from "../../../clients/ratesClient";
import LookupsClient from "../../../clients/lookupsClient";
import { ShipmentSearch } from "../../../models/shipment";
import { ShowNotification } from "../../../store/actions/auth";
import { useDispatch } from "react-redux";
import ShipClient from "../../../clients/shipClient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlaneArrival, faPlaneDeparture } from "@fortawesome/free-solid-svg-icons";
import {QuotationProfile, QuotationScenario} from "../../../models/quotation";
import ActionButton from "../../SharedComponents/ActionButton";


const CreateContainer = () => {
  const checkoutClient = new CheckoutClient();
  const ratesClient = new RatesClient();
  const lookupsClient = new LookupsClient();
  const shipClient = new ShipClient();
  const [error, setError] = useState("");
  const hist = useHistory();
  const formRef = useRef<FormRef | null>(null);
  const [scenarios, setScenarios] = useState<QuotationScenario[]>()
  const [triggerProfile, setTriggerProfile] = useState<boolean>(true)
  const [cashAccount, setCashAccount] = useState("");
  const [lookups, setLookups] = useState<Lookups | undefined>(undefined);
  const dispatch = useDispatch();

  const [quotationShipment, setQuotationShipment] = useState<
    ShipmentSearch | undefined
  >(undefined);
  const [isLoadingShipment, setIsLoadingShipment] = useState(false);

  interface Lookups {
    customers: { [k: string]: string };
    profiles: { [k: string]: string };
    countries: { [k: string]: string };
  }
  useEffect(() => {
    loadLookups();
  }, []);

  const ScenarioSelect = (triggerProfile: boolean) => {
    if (triggerProfile) {
      return <Input
          lookUp={scenarioOptions}
          label="Scenario"
          type="typeahead"
          field="scenarioCode"
      />
    }
  }

  const loadLookups = async () => {
    const profiles = (await checkoutClient.GetProfiles(true)) as {
      [k: string]: string;
    };
    const countries = (await lookupsClient.getCountries()) as {
      [k: string]: string;
    };
    const customers = await ratesClient.LookupCustomers();
    setLookups({ customers, countries, profiles });
  };

    let scenarioOptions : {[k: string] : string} = {}
    scenarios?.forEach(scenario => {
      scenarioOptions[scenario.code] = scenario.name
    })


  const retrieveQuotationShipment = async () => {
    //shipment numbet entered by user
    const shipmentNumber = formRef.current?.getFormValue("shipmentNumber");

    // if shipment number was not eneterd
    if (!shipmentNumber) setQuotationShipment(undefined);
    // if was
    if (shipmentNumber == quotationShipment?.number) {
      return;
    }
    setIsLoadingShipment(true);
    const rs = await shipClient.getShipmentByNumber(shipmentNumber);

    setIsLoadingShipment(false);
    if (rs && rs.found) {
      setQuotationShipment(rs.shipment);
    } else {
      dispatch(ShowNotification("Not Found", "Shipment not Found", true));
      setQuotationShipment(undefined);
    }
  };

  const handleFillShipperInfo = () => {
    formRef.current?.changeValue(
      "phone",
      quotationShipment?.shipperPhone ?? ""
    );
    formRef.current?.changeValue("name", quotationShipment?.shipperName ?? " ");
    formRef.current?.changeValue(
      "email",
      quotationShipment?.shipperEmail ?? " "
    );
    formRef.current?.changeValue(
      "billingAddress.companyName",
      quotationShipment?.shipperCompanyName ?? " "
    );
    formRef.current?.changeValue(
      "billingAddress.name",
      quotationShipment?.shipperName ?? " "
    );
    formRef.current?.changeValue(
      "billingAddress.street",
      quotationShipment?.shipperAddressLine1 ?? " "
    );
    formRef.current?.changeValue(
      "billingAddress.addressLine2",
      quotationShipment?.shipperAddressLine2 ?? " "
    );
    formRef.current?.changeValue(
      "billingAddress.zipcode",
      quotationShipment?.shipperPostCode ?? ""
    );
    formRef.current?.changeValue(
      "billingAddress.city",
      quotationShipment?.shipperCity ?? " "
    );
    formRef.current?.changeValue(
      "billingAddress.country",
      quotationShipment?.shipperCountry ?? " "
    );
  };

  const handleFillConsigneeInfo = () => {
    formRef.current?.changeValue(
      "phone",
      quotationShipment?.consigneePhone ?? ""
    );
    formRef.current?.changeValue(
      "name",
      quotationShipment?.consigneeName ?? ""
    );
    formRef.current?.changeValue(
      "email",
      quotationShipment?.consigneeEmail ?? " "
    );
    formRef.current?.changeValue(
      "billingAddress.companyName",
      quotationShipment?.consigneeCompanyName ?? " "
    );
    formRef.current?.changeValue(
      "billingAddress.name",
      quotationShipment?.consigneeName ?? " "
    );
    formRef.current?.changeValue(
      "billingAddress.street",
      quotationShipment?.consigneeAddressLine1 ?? " "
    );
    formRef.current?.changeValue(
      "billingAddress.addressLine2",
      quotationShipment?.consigneeAddressLine2 ?? " "
    );
    formRef.current?.changeValue(
      "billingAddress.zipcode",
      quotationShipment?.consigneePostCode ?? ""
    );
    formRef.current?.changeValue(
      "billingAddress.city",
      quotationShipment?.consigneeCity ?? " "
    );
    formRef.current?.changeValue(
      "billingAddress.country",
      quotationShipment?.consigneeCountry ?? " "
    );
  };

  return (
    <CContainer className="bg-white p-4 text-primary small">
        <h5 style={{color:"gray"}}>Create Quotation</h5>
      <Form
        initialState={{ currency: "EUR" }}
        ref={formRef}
        className="mt-3"
        onSubmit={async (body) => {
          let res = await checkoutClient.CreateQuotation(body);

          if (res.succeeded) {
            hist.push(`/quotations/${res.data.id}`);
          } else {
            if (res.status == 400) setError(res.data.SWException[0]);
          }
        }}
      >

        <CRow className="p-2">
          <CCol className="col-sm-2 pr-0">
            <Input
              lookUp={lookups?.profiles}
              onChange={(val) => {
                checkoutClient.GetProfile(val.toString()).then((v: QuotationProfile) => {
                  formRef.current?.changeValue(
                    "paymentMethods",
                    v?.configuration?.supportedPaymentMethods ?? []
                  );
                  setCashAccount(v?.configuration?.cashAccount ?? []);
                  setTriggerProfile(false)
                  setScenarios(v.scenarios)
                  setTriggerProfile(true)
                });
              }}
              label="Profile"
              type="typeahead"
              field="profileId"
            />
            
            <Input
              validators={[notEmpty]}
              label="Contact Name"
              type="text"
              field="name"
            />
            {ScenarioSelect(triggerProfile)}
          </CCol>

          <CCol className="col-sm-2 pr-0">
            <Input
              label="Account"
              type="typeahead"
              field="accountNumber"
              lookUp={lookups?.customers}
              onChange={(val) => {
                ratesClient.GetCustomer(val.toString()).then((v) => {
                  const account = v?.id == cashAccount ? {} : v;
                  formRef.current?.changeValue(
                    "name",
                    account?.contactPerson ?? ""
                  );

                  formRef.current?.changeValue("email", account?.email ?? "");
                  formRef.current?.changeValue("phone", account?.phone ?? "");
                  formRef.current?.changeValue(
                    "taxType",
                    account?.taxType ?? "VAT"
                  );

                  formRef.current?.changeValue(
                    "billingAddress.companyName",
                    account?.name ?? " "
                  );
                  formRef.current?.changeValue(
                    "billingAddress.name",
                    account?.contactPerson ?? " "
                  );
                  formRef.current?.changeValue(
                    "billingAddress.street",
                    account?.addressLine1 ?? " "
                  );
                  formRef.current?.changeValue(
                    "billingAddress.addressLine2",
                    account?.addressLine2 ?? " "
                  );
                  formRef.current?.changeValue(
                    "billingAddress.zipcode",
                    account?.postCode ?? ""
                  );
                  formRef.current?.changeValue(
                    "billingAddress.city",
                    account?.city ?? " "
                  );
                  formRef.current?.changeValue(
                    "billingAddress.country",
                    account?.country ?? " "
                  );
                  formRef.current?.changeValue(
                    "billingAddress.eori",
                    account?.eori ?? " "
                  );
                  formRef.current?.changeValue(
                    "billingAddress.vat",
                    account?.vat ?? " "
                  );
                });
              }}
            />

            <Input
              label="Email"
              type="text"
              field="email"
            />
          </CCol>
          <CCol className="col-sm-2 pr-0">
            <Input
            
              label="Shipment Number"
              type="text"
              field="shipmentNumber"
              onBlur={retrieveQuotationShipment}
            />
            <Input label="Phone" type="text" field="phone" />
          </CCol>
          <CCol className="col-sm-2">
            <CRow className="nav-item mt-4">
              <>
                {quotationShipment?.number && (
                  <>
                    <CTooltip content={"Get Shipper Data "}>
                      <CButton
                        style={{ cursor: "pointer" }}
                        disabled={isLoadingShipment}
                        onClick={handleFillShipperInfo}
                      >
                        <FontAwesomeIcon style={{color:"#00007d"}}  icon={faPlaneDeparture} />
                      </CButton>
                    </CTooltip>
                    <CTooltip content={"Get Receiver Data"}>
                      <CButton
                        style={{ cursor: "pointer" }}
                        disabled={isLoadingShipment}
                        onClick={handleFillConsigneeInfo}
                      >
                        <FontAwesomeIcon style={{color:"#00007d"}}  icon={faPlaneArrival} />
                      </CButton>
                    </CTooltip>
                  </>
                )}
              </>
            </CRow>
          </CCol>
          <CCol className="col-sm-4">
            <CRow>
              <Input
                className="col-sm-11 p-0"
                validators={[notEmpty]}
                lookUpMulti={[
                  { value: "credit-card", label: "Credit Card" },
                  { value: "bank-transfer", label: "Bank Transfer" },
                ]}
                label="Payment Methods"
                field="paymentMethods"
                type="multi-select"
              />
            </CRow>
            <CRow className="">
              <CCol className="col-sm-2 p-0">
                <Input readonly label="Currency" type="text" field="currency" />
              </CCol>

              <CCol className="col-sm-10 pr-0">
                <Input
                  lookUp={{
                    VAT: "VAT",
                    EU: "EU",
                    "NON-EU": "NON-EU",
                  }}
                  label="Tax Type"
                  field="taxType"
                  type="radio"
                />
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <fieldset className="b-fieldset p-2">
          <legend className="b-legend">
            <h5>Billing Address</h5>
          </legend>
          <CRow>
            <CCol className="col-sm-6">
              <CRow>
                <CCol className="col-sm-4 pr-0">
                  <Input
                    label="Company Name"
                    field="billingAddress.companyName"
                    type="text"
                  />
                </CCol>
                <CCol className="col-sm-4 pr-0">
                  <Input
                    label="Contact Name"
                    field="billingAddress.name"
                    type="text"
                  />
                </CCol>
              </CRow>

              <CRow>
                <CCol className="col-sm-4 pr-0">
                  <Input
                    label="Street"
                    field="billingAddress.street"
                    type="text"
                  />
                </CCol>
                <CCol className="col-sm-4 pr-0">
                  <Input
                    label="Additional Address"
                    field="billingAddress.addressLine2"
                    type="text"
                  />
                </CCol>
                <CCol className="col-sm-4">
                  <CRow>
                    <CCol className="col-sm-6">
                      <Input
                        label="Zip Code"
                        field="billingAddress.zipcode"
                        type="text"
                      />
                    </CCol>
                    <CCol className="col-sm-6 pr-0 pl-0">
                      <Input
                        label="City"
                        field="billingAddress.city"
                        type="text"
                      />
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CCol>

            <CCol className="col-sm-2" />
            <CCol className="col-sm-4">
              <CRow>
                <CCol className="col-sm-6 p-0">
                  <Input label="VAT" field="billingAddress.vat" type="text" />
                </CCol>
                <CCol className="col-sm-6">
                  <Input label="Eori" field="billingAddress.eori" type="text" />
                </CCol>
              </CRow>
              <CRow>
                <CCol className="col-sm-12 pl-0">
                  {" "}
                  <Input
                    lookUp={lookups?.countries}
                    label="Country"
                    type="typeahead"
                    field="billingAddress.country"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </fieldset>
        <CRow className="p-3">
          <CCol>
            <br />
            <CRow>
              <ActionButton
                  text={"Create"}
              />
              <CCol>
                <CFormText color={"danger"}>{error}</CFormText>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </Form>
    </CContainer>
  );
};

export default CreateContainer;
