import {
    CCol,
    CRow,
    CContainer,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CButton, CSpinner
} from "@coreui/react";
import React from "react";
import {useFormik} from "formik";
import {CreateUser, UserCreateRequest} from "../../../../models/user";
import * as yup from "yup"
import SWInput from "../../../SharedComponents/SWInput";


interface Props {
    onClose: () => void
    onSubmit: (v:{currentPassword:string,newPassword:string}) => void
    loader:boolean

}

const ChangePasswordModal = (props: Props) => {
    const {values, setFieldValue, handleSubmit, errors} = useFormik({
        initialValues: {
            currentPassword:'',
            newPassword: '',
            confirmPassword: '',

        },
        onSubmit: async (v) => {
            const {confirmPassword, ...rest} = v;
            props.onSubmit(rest)
        },
        validationSchema: yup.object({
            confirmPassword: yup.string().test('confirm-Password-is-required', 'Password is not Match', function (value) {
                return value === this.parent.newPassword;
            }).min(8, 'Minimum is 8 digits'),
            newPassword: yup.string().required('New Password is required').min(8, 'Minimum is 8 digits'),
            currentPassword: yup.string().required('Current Password is required'),
        }),
        validateOnBlur: false,
        validateOnChange: false

    })
    return (
        <CContainer className="bg-white p-4 text-black">
            <CModal show={true} size={'lg'} onClose={props.onClose} centered>
                <CModalHeader style={{width: '100%', justifyContent: 'space-between'}}  closeButton={!props.loader}>
                    <CRow className={'flex-row w-100 d-flex justify-content-between align-items-center'}>
                        <h5 className={'text-center'}>Change Password</h5>
                        {props.loader && <CSpinner
                            color="primary" style={{width: '20px', height: '20px'}}/>}
                    </CRow>

                </CModalHeader>
                <CModalBody>
                    <CRow>
                        <CCol md={12}>

                            <SWInput name={'currentPassword'} label={'Current Password'} validationErrors={errors.currentPassword} value={values.currentPassword}
                                     onChange={(e: string) => setFieldValue('currentPassword', e)}/>
                            <SWInput name={'newPassword'} validationErrors={errors.newPassword} label={'New Password'}
                                     type={'password'} value={values.newPassword}
                                     onChange={(e: string) => setFieldValue('newPassword', e)}/>
                            <SWInput name={'confirmPassword'} label={'Confirm Password'}
                                     validationErrors={errors.confirmPassword} type={'password'}
                                     value={values.confirmPassword}
                                     onChange={(e: string) => setFieldValue('confirmPassword', e)}/>


                        </CCol>
                    </CRow>

                </CModalBody>
                <CModalFooter >
                    <CButton disabled={props.loader} size="sm"
                             className="mr-2"
                             color="primary" onClick={handleSubmit}>
                        Create
                    </CButton>
                </CModalFooter>
            </CModal>

        </CContainer>
    );
};

export default ChangePasswordModal;
