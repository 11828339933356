import {
    CCol,
    CContainer,
    CRow,
    CLabel,
    CInputCheckbox
} from "@coreui/react";
import SWInput from "../../SharedComponents/SWInput";
import SWSelect from "../../SharedComponents/SWSelect";
import React, {useEffect, useState} from "react";
import RatesClient from "../../../clients/ratesClient";
import {ShipmentFlattened} from "../../../models/shipment";
import LookupsClient from "../../../clients/lookupsClient";
import WarehousesClient from "../../../clients/warehousesClient";

interface Props {
    shipment: ShipmentFlattened
    onChange: (name: string, value: any) => void
}



const ShipmentFlattenedEdit = (props: Props) => {
    const {shipment, onChange} = props;

    const lookupClient = new LookupsClient();
    const ratesClient = new RatesClient();
    const warehouseClient = new WarehousesClient();
    
    const [countries, setCountries] = useState<{[k: string]: string}>({})
    const [agents, setAgents] = useState<{[k: string]: string}>({})
    const [warehouses, setWarehouses] = useState<{[k: string]: string}>({})
    
    useEffect(() => {
        lookupClient.getCountries().then(v => setCountries(v))
        ratesClient.LookupAgents().then(v => setAgents(v))
        warehouseClient.warehousesLookup().then(v => setWarehouses(v))
    }, [])
    
    const handleTextChange=(value: string,name: string) => {
        // const v = value ? value : null;
        onChange(name,value);
    }
    return (<>
        <CRow>
            <CCol>
                <CContainer className="text-left">
                    <h6>
                        <strong>Shipper:</strong>
                    </h6>
                    <SWInput name="shipperCompanyName" value={shipment.shipperCompanyName} label="Company Name"  onChange={handleTextChange} />
                    <SWInput name="shipperEori" value={shipment.shipperEori} label="EORI" onChange={handleTextChange}/>
                    <SWInput name="shipperName" value={shipment.shipperName} label="Contact Name" onChange={handleTextChange}/>
                    <SWInput name="shipperAddressLine1" value={shipment.shipperAddressLine1} label="Street" onChange={handleTextChange}/>
                    <SWInput name="shipperAddressLine2" value={shipment.shipperAddressLine2} label="Address Line 2" onChange={handleTextChange}/>
                    <SWInput name="shipperAddressLine3" value={shipment.shipperAddressLine3} label="Address Line 3" onChange={handleTextChange}/>
                    <SWSelect values={countries} value={shipment.shipperCountry} label="Country" onChange={(c)=> onChange("shipperCountry",c)} isClearable />
                    <SWInput name="shipperPostCode" value={shipment.shipperPostCode} label="Zip Code" onChange={handleTextChange}/>
                    <SWInput name="shipperCity" value={shipment.shipperCity} label="City" onChange={handleTextChange}/>
                    <SWInput name="shipperPhone" value={shipment.shipperPhone} label="Telephone" onChange={handleTextChange}/>
                    <SWInput name="shipperEmail" value={shipment.shipperEmail} label="Email" onChange={handleTextChange}/>
                </CContainer>
            </CCol>
            <CCol>
                <CContainer className="text-left">
                    <h6>
                        <strong>Receiver</strong>
                    </h6>
                    <SWInput name="consigneeCompanyName" value={shipment.consigneeCompanyName} label="Company Name"  onChange={handleTextChange} />
                    <SWInput name="consigneeEori" value={shipment.consigneeEori} label="EORI" onChange={handleTextChange}/>
                    <SWInput name="consigneeName" value={shipment.consigneeName} label="Contact Name" onChange={handleTextChange}/>
                    <SWInput name="consigneeAddressLine1" value={shipment.consigneeAddressLine1} label="Street" onChange={handleTextChange}/>
                    <SWInput name="consigneeAddressLine2" value={shipment.consigneeAddressLine2} label="Address Line 2" onChange={handleTextChange}/>
                    <SWInput name="consigneeAddressLine3" value={shipment.consigneeAddressLine3} label="Address Line 3" onChange={handleTextChange}/>
                    <SWSelect values={countries} value={shipment.consigneeCountry} label="Country" onChange={(c)=> onChange("consigneeCountry",c)} isClearable />
                    <SWInput name="consigneePostCode" value={shipment.consigneePostCode} label="Zip Code" onChange={handleTextChange}/>
                    <SWInput name="consigneeCity" value={shipment.consigneeCity} label="City" onChange={handleTextChange}/>
                    <SWInput name="consigneePhone" value={shipment.consigneePhone} label="Telephone" onChange={handleTextChange}/>
                    <SWInput name="consigneeEmail" value={shipment.consigneeEmail} label="Email" onChange={handleTextChange}/>             
                </CContainer>
            </CCol>
            <CCol>
                <CContainer className="text-left">
                    <h6>
                        <strong>Other</strong>
                    </h6>
                    <SWInput name="reference1Type" value={shipment.reference1Type} label="Reference 1 Type"  onChange={handleTextChange} />
                    <SWInput name="reference2Type" value={shipment.reference2Type} label="Reference 2 Type"  onChange={handleTextChange} />
                    <SWInput name="reference3Type" value={shipment.reference3Type} label="Reference 3 Type"  onChange={handleTextChange} />
                    <SWInput name="iOSS" value={shipment.iOSS} label="IOSS"  onChange={handleTextChange} />
                    <SWSelect values={countries} value={shipment.countryOfOrigin} label="Items Country Of Origin" onChange={(c)=> onChange("countryOfOrigin",c)} isClearable />
                    <SWSelect values={agents} value={shipment.agent} label={"Agent"} onChange={(a) => onChange("agent", a)} isClearable />
                    <SWInput name="serviceCode" value={shipment.serviceCode} label="Service Code" onChange={handleTextChange} />
                    <SWSelect values={warehouses} label={"Warehouse"} value={shipment.warehouseCode} onChange={(w) => onChange("warehouseCode", w)} isClearable />
                    <CRow style={{marginLeft:"5px"}}>
                        <CCol>
                    <CInputCheckbox checked={shipment.orderFulfillment} disabled /><CLabel style={{marginTop:"8px"}}>Order Fulfillment</CLabel>
                        </CCol>
                        <CCol>
                    <CInputCheckbox checked={shipment.multipleItemsInRow} disabled /><CLabel style={{marginTop:"8px"}}>Multiple Items In Row</CLabel>
                        </CCol>
                    </CRow>
                </CContainer>
            </CCol>
        </CRow>
    </>);
}

export default ShipmentFlattenedEdit;