import React from "react";
import {ShipmentGet, StandardShipmentTrace} from "../../../../models/shipment";
import {formatDate, formatTime} from "../../../../utils/dateUtil";
import {TableHeader} from "./TableHeader";

interface IProps {
    shipment: ShipmentGet;
}

const MissingTraces = (props: IProps) => {
    const {shipment} = props;
    return (
        <>
            <div>
                <table className="position-relative table table-hover table-sm">
                    <TableHeader header={["Date", "Time", "Location", "Status", "Comment", "User"]}/>
                    <tbody>
                    {shipment.missingTraces?.length < 1 && (
                        <div className="mr-2 ml-2 mt-2 mb-2">
                            <span> No Available Tracking</span>
                        </div>
                    )}

                    {shipment.missingTraces?.map((trace: StandardShipmentTrace) => (
                        <tr key={trace.id}>
                            <td>{formatDate(trace.actionDate)}
                            </td>
                            <td>
                                {formatTime(trace.actionDate)}
                            </td>
                            <td>
                                <>
                                    {trace?.country && trace?.postCode
                                        ? trace?.country + "-" + trace?.postCode
                                        : " "}{" "}
                                </>
                            </td>

                            <td>{trace?.statusName || ""}</td>
                            <td>{trace?.comment1 || ""}</td>
                            <td>{trace?.createdBy || ""}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </>

    )
}
export default MissingTraces;
