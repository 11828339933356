import {
  CButton,
  CCol,
  CFormGroup,
  CInput,
  CInputGroup,
  CRow,
  CSelect,
  CTextarea,
} from "@coreui/react";
import React, { CSSProperties, useContext } from "react";
import { Filter, FilterType, InputType } from "../../../models/filter";
import SWAdvancedSearchContext from "./SWAdvancedSearchContext";
import "react-datepicker/dist/react-datepicker.css";
import DateRangeFilter from "./DateRangeFilter";
import DateAndTimePicker from "../../SharedComponents/SWForm/DateAndTimePicker";
import ReactSelect, { StylesConfig } from "react-select";
import SWInput from "../SWForm/SWInput";

interface Props {
  filter: Filter;
}

const SWAdvancedSearchFilter = (props: Props) => {
  const { changeFilterProperty, deleteFilter, availableFilters } = useContext(
      SWAdvancedSearchContext
  );

  const getDateValue = (val: string) => {
    const d = new Date(val);
    if (d.valueOf()) return d;
    else return null;
  };

  const { filter } = props;

  const operatorss = {
    Like: FilterType.Like,
    Equals: FilterType.Equals,
    "Not Equals": FilterType.NotEquals,
    "One Of": FilterType.OneOf,
    "Less Than": FilterType.LessThan,
    "Greater Than": FilterType.GreaterThan,
    Range: FilterType.Range,
  };

  let operators: { [k: string]: FilterType };
  switch (filter.inputType) {
    case "datetime":
    case "date":
      operators = {
        Range: FilterType.Range,
        Equals: FilterType.Equals,
        "Greater Than": FilterType.GreaterThan,
        "Less Than": FilterType.LessThan,
      };
      break;
    case "select":
      operators = {
        Equals: FilterType.Equals,
        "Not Equals": FilterType.NotEquals,
      };
      break;
    case "text":
      operators = {
        Equals: FilterType.Equals,
        Like: FilterType.Like,
        "Not Equals": FilterType.NotEquals,
        "One Of": FilterType.OneOf,
      };
      break;
    default:
      operators = {
        Like: FilterType.Like,
        Equals: FilterType.Equals,
        "Not Equals": FilterType.NotEquals,
        "One Of": FilterType.OneOf,
        "Less Than": FilterType.LessThan,
        "Greater Than": FilterType.GreaterThan,
        Range: FilterType.Range,
      };
  }

  return (
      <CFormGroup>
        <CInputGroup className="my-2" key={filter.field}>
          <CRow className="w-100">
            <CCol sm={1}>
              <CButton
                  size="sm"
                  style={{ fontSize: "10px" }}
                  onClick={() => deleteFilter(filter.id!)}
                  variant="outline"
                  color="danger"
              >
                Remove
              </CButton>
            </CCol>
            <CCol>
              {/* <ReactSelect
                        style={{fontSize: "10px"}}
                        value={filter.field}
                        onChange={(e) =>
                            changeFilterProperty(
                                filter.id!,
                                (e?.target as HTMLSelectElement).value,
                                "field",
                                true
                            )
                        }
                        options={availableFilters}

                        /> */}

              <ReactSelect
                  style={{ fontSize: "10px" }}
                  //@ts-ignore
                  styles={selectStyle}
                  classNamePrefix="mySelect"
                  className="w-100"
                  value={availableFilters
                      ?.map((af): any => {
                        return { value: af.field, label: af.title ?? af.field };
                      })
                      .find((o) => o.value == filter.field)}
                  onChange={(e: any) => {
                    changeFilterProperty(filter.id!, e.value, "field", true);
                  }}
                  options={availableFilters.map((af): any => {
                    return { value: af.field, label: af.title ?? af.field };
                  })}
              />

              {/*<CSelect*/}
              {/*    style={{fontSize: "10px"}}*/}
              {/*    value={filter.field}*/}
              {/*    onChange={(e) =>*/}
              {/*        changeFilterProperty(*/}
              {/*            filter.id!,*/}
              {/*            (e.target as HTMLSelectElement).value,*/}
              {/*            "field",*/}
              {/*            true*/}
              {/*        )*/}
              {/*    }*/}
              {/*>*/}
              {/*    {availableFilters.map((af) => (*/}
              {/*        <option*/}
              {/*            key={af.field}*/}
              {/*            value={af.field}*/}
              {/*            label={af.title ?? af.field}*/}
              {/*        />*/}
              {/*    ))}*/}
              {/*</CSelect>*/}
            </CCol>
            <CCol>
              <ReactSelect
                  style={{ fontSize: "10px" }}
                  //@ts-ignore
                  styles={selectStyle}
                  classNamePrefix="mySelect"
                  className="w-100"
                  value={Object.entries(operators)
                      .map((v): any => {
                        return { value: v[1], label: v[0] };
                      })
                      .find((o) => o.value == filter.type)}
                  onChange={(e: any) => {
                    changeFilterProperty(filter.id!, e.value, "type");
                  }}
                  options={Object.entries(operators).map((v): any => {
                    return { value: v[1], label: v[0] };
                  })}
              />
              {/*<CSelect*/}
              {/*    style={{fontSize: "10px"}}*/}
              {/*    value={filter.type}*/}
              {/*    onChange={(e) =>*/}
              {/*        changeFilterProperty(*/}
              {/*            filter.id!,*/}
              {/*            (e.target as HTMLSelectElement).value,*/}
              {/*            "type"*/}
              {/*        )*/}
              {/*    }*/}
              {/*>*/}

              {/*    {Object.entries(operators).map(v =>*/}
              {/*            <option value={v[1]} key={v[1]}>*/}
              {/*                {v[0]}*/}
              {/*            </option>*/}
              {/*        )}*/}
              {/*</CSelect>*/}
            </CCol>

            <CCol>
              {availableFilters[
                  availableFilters.findIndex((v) => v.field == filter.field)
                  ]?.lookUp ? (
                      <ReactSelect
                          style={{ fontSize: "10px" }}
                          //@ts-ignore
                          styles={selectStyle}
                          classNamePrefix="mySelect"
                          className="w-100"
                          value={Object.entries(
                              availableFilters[
                                  availableFilters.findIndex((v) => v.field == filter.field)
                                  ].lookUp!
                          )
                              .map((v): any => {
                                return { value: v[0], label: v[1] };
                              })
                              .find((o) => o.value == filter.value)}
                          onChange={(e: any) => {
                            changeFilterProperty(filter.id!, e.value, "value");
                          }}
                          options={Object.entries(
                              availableFilters[
                                  availableFilters.findIndex((v) => v.field == filter.field)
                                  ].lookUp!
                          ).map((v): any => {
                            return { value: v[0], label: v[1] };
                          })}
                      />
                  ) : // <CSelect
                  //     style={{fontSize: "10px"}}
                  //     value={filter.value as string | number}
                  //     onChange={(e) =>
                  //         changeFilterProperty(
                  //             filter.id!,
                  //             (e.target as HTMLSelectElement).value,
                  //             "value"
                  //         )
                  //     }
                  // >
                  //     {Object.entries(
                  //         availableFilters[
                  //             availableFilters.findIndex((v) => v.field == filter.field)
                  //             ].lookUp!
                  //     ).map((v) => (
                  //         <option value={v[0]} key={v[0]}>
                  //             {v[1]}
                  //         </option>
                  //     ))}
                  // </CSelect>
                  filter.type == FilterType.Range ? (
                      <DateRangeFilter
                          value={filter.value as string}
                          onChange={(v) => {
                            changeFilterProperty(filter.id!, v, "value");
                          }}
                          withTime={false}
                      />
                  ) : filter.type == FilterType.OneOf ? (
                      <CTextarea
                          style={{ fontSize: "10px" }}
                          value={(filter.value as string | number) ?? ""}
                          onChange={(e) => {
                            changeFilterProperty(
                                filter.id!,
                                (e.target as HTMLInputElement).value,
                                "value"
                            );
                          }}
                      />
                  ) : filter.inputType === "datetime" ||
                  filter.inputType === "date" ? (
                      <DateAndTimePicker
                          showTimeInput={false}
                          readOnly={false}
                          value={getDateValue(filter.value as string) || new Date()}
                          handleOnChange={(e: any) => {
                            changeFilterProperty(filter.id!, e, "value");
                          }}
                      />
                  ) : (
                      <CInput
                          style={{ fontSize: "10px" }}
                          type={filter.inputType}
                          value={(filter.value as string | number) ?? ""}
                          onChange={(e) => {
                            changeFilterProperty(
                                filter.id!,
                                (e.target as HTMLInputElement).value,
                                "value"
                            );
                          }}
                      />
                  )}
            </CCol>
          </CRow>
        </CInputGroup>
      </CFormGroup>
  );
};

const customMultiStyles: CSSProperties = {
  fontSize: "10px",
  backgroundColor: "#f7f7f7",
};

const selectStyle: StylesConfig<
    { lable: string; value: string; id: string },
    false
    > = {
  control: (provided, state) => {
    return {
      ...provided,
      ...customMultiStyles,
      minHeight: "26px",
      backgroundColor: state.isDisabled ? provided.backgroundColor : "none"
    };
  },
  indicatorsContainer: (provided, state) => {
    return {
      ...provided,
      ...customMultiStyles,
      height: "15px",
      marginTop: "7px",
      backgroundColor: state.isDisabled ? provided.backgroundColor : "none"

    };
  },
  valueContainer: (provided, state) => {
    return {
      ...provided,
      ...customMultiStyles,
      height: "20px",
      backgroundColor:  "none"
    };
  },
  indicatorSeparator: (provided, state) => {
    return {
      ...provided,
      backgroundColor: "none",
    };
  },
  dropdownIndicator: (provided, state) => {
    return {
      ...provided,
      height: "26px",
      marginBottom: "14px",
    };
  },
};

export default SWAdvancedSearchFilter;
