import {CButton, CDataTable, CLink, CTooltip} from "@coreui/react";
import {ChargeDto, InvoiceDto, InvoiceStatus} from "../../../../models/accounting";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";

export interface IProps {
    invoice?: InvoiceDto
    addCharge: () => void
    updateCharge: (chargeId: number) => void
    deleteCharge: (chargeId: number) => void
    detachCharge: (chargeId: number) => void
}

const InvoiceDetailedChargesTable = (props: IProps) => {
    const value = (props.invoice?.taxableValue ?? 0) + (props.invoice?.taxLiquidatedValue ?? 0) + (props.invoice?.nonTaxableValue ?? 0)
    const scopedSlots = {
        actions: (item: ChargeDto) => {
            return props.invoice?.status == InvoiceStatus.Draft ?
                <td className={"text-right"}>
                    <CTooltip content={"Edit Charge"}>
                        <CIcon content={freeSet.cilPencil} style={{cursor: "pointer"}} className={"mr-2"}
                               onClick={() => props.updateCharge(item.id)}/>
                    </CTooltip>
                    {item.shipmentUid &&
                        <CTooltip content={"Detach Charge"}>
                            <CIcon content={freeSet.cilCut} className={"mr-2"} style={{cursor: "pointer"}}
                                   onClick={() => props.detachCharge(item.id)}/>
                        </CTooltip>}
                    <CTooltip content={"Delete Charge"}>
                        <CIcon content={freeSet.cilTrash} style={{cursor: "pointer"}}
                               onClick={() => props.deleteCharge(item.id)}/>
                    </CTooltip>
                </td>
                : <td/>
        },
        shipment: (item: ChargeDto) => {
            return <td>{item.shipmentUid ? <CLink
                to={`/shipments/${item.shipmentUid}`}>{item.shipmentNumber ?? item.shipmentUid}</CLink> : ""}</td>
        },
        taxableValue: (item: ChargeDto) => {
            return <td className={"text-right"}>{item.taxCode == "Y" ? item.value.toFixed(2) : 0}</td>
        },
        taxLiquidatedValue: (item: ChargeDto) => {
            return <td className={"text-right"}>{item.taxCode == "E" ? item.value.toFixed(2) : 0}</td>
        },
        nonTaxableValue: (item: ChargeDto) => {
            return <td
                className={"text-right"}>{item.taxCode != "Y" && item.taxCode != "E" ? item.value.toFixed(2) : 0}</td>
        },
        taxValue: (item: ChargeDto) => {
            return <td className={"text-right"}>{item.taxValue.toFixed(2)}</td>
        },
        totalValue: (item: ChargeDto) => {
            return <td className={"text-right"}>{(item.value + item.taxValue).toFixed(2)}</td>
        },
        comments: (item: ChargeDto) => {
            return <td>{item.comments ?? ""}</td>
        }
    }

    return <>
        {props.invoice?.status == InvoiceStatus.Draft &&
            <CButton onClick={props.addCharge}>
                <CTooltip content={"Add charge"}>
                    <CIcon content={freeSet.cilPlus} size={"lg"} style={{cursor: "pointer"}}/>
                </CTooltip>
            </CButton>}
        <CDataTable
            hover
            size={"sm"}
            itemsPerPage={20}
            pagination
            items={props.invoice?.detailedCharges}
            scopedSlots={scopedSlots}
            fields={[
                {key: "typeId", label: "Charge Code"},
                {key: "name", label: "Charge"},
                "comments",
                "shipment",
                {
                    key: "taxableValue", label: (
                        <div style={{textAlign: "center"}}>
                            <div style={{marginBottom: "4px"}}>Taxable Amount</div>
                            <div>{props.invoice?.taxableValue?.toFixed(2)}</div>
                        </div>
                    ), _style: {textAlign: "center"}
                },
                {
                    key: "taxLiquidatedValue",
                    label: (
                        <div style={{textAlign: "center"}}>
                            <div style={{marginBottom: "4px"}}>Tax Liquidated Amount</div>
                            <div>{props.invoice?.taxLiquidatedValue?.toFixed(2)}</div>
                        </div>
                    ),
                    _style: {textAlign: "center"}
                },
                {
                    key: "nonTaxableValue",
                    label: (
                        <div style={{textAlign: "center"}}>
                            <div style={{marginBottom: "4px"}}>Non Taxable Amount</div>
                            <div>{props.invoice?.nonTaxableValue?.toFixed(2)}</div>
                        </div>
                    ),
                    _style: {textAlign: "center"}
                },
                {
                    key: "taxValue",
                    label: (
                        <div style={{textAlign: "center"}}>
                            <div style={{marginBottom: "4px"}}>Tax Amount</div>
                            <div>{props.invoice?.taxValue?.toFixed(2) ?? 0}</div>
                        </div>
                    ),
                    _style: {textAlign: "center"}
                },
                {
                    key: "totalValue",
                    label: (
                        <div style={{textAlign: "center", color: "blue"}}>
                            <div style={{marginBottom: "4px"}}>Total With Tax</div>
                            <div>{(value + (props.invoice?.taxValue ?? 0)).toFixed(2)}</div>
                        </div>
                    ),
                    _style: {textAlign: "center"}
                },
                {key: "actions", label: "Actions", _style: {width: "10%", textAlign: "center"}}
            ]}
        />

    </>
}

export default InvoiceDetailedChargesTable
