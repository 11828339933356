import React from "react";
import { CModal, CModalHeader, CModalBody, CModalFooter, CRow, CCol } from "@coreui/react";
import ActionButton from "../../../../SharedComponents/ActionButton";
import SWSelect from "../../../../SharedComponents/SWSelect";
import SWInput from "../../../../SharedComponents/SWInput";
import * as yup from "yup";
import { useFormik } from "formik";


interface IProps {
    onClose: () => void
    onSave: (e: { type: string, fileName: string }) => void
    transactionAttachmentsTypesLookup: { [k: string]: string }
    attachmentType?: string
    fileName?: string
}

const TransactionAttachmentTypesModal = ({ onClose, transactionAttachmentsTypesLookup, attachmentType, fileName, onSave }: IProps) => {

    const formik = useFormik<{ type: string, fileName: string }>({
        enableReinitialize: true,
        initialValues: {
            type: attachmentType ?? "",
            fileName: fileName ?? ""
        },
        onSubmit: async (values) => {
            onSave(values)
        },
        validationSchema: yup.object({
            type: yup.string().required("Required"),
            fileName: yup.string().required("Required"),
        }),
    })

    const { touched, errors, handleSubmit, resetForm, setFieldValue, values } = formik;

    return (
        <CModal
            show={true}
            centered
            onClose={() => {
                resetForm();
                onClose();
            }}
            closeOnBackdrop={false}
        >
            <CModalHeader closeButton>
                <h5>Attachment Type</h5>
            </CModalHeader>
            <CModalBody>
                <CCol>
                    <SWSelect label={"Type"} value={values?.type ?? ""} values={transactionAttachmentsTypesLookup}
                        onChange={(e) => setFieldValue("type", e)}
                        validationErrors={touched?.type && errors?.type?.toString() || ""}
                    />
                </CCol>
                <CCol>
                    <SWInput
                        label={"File Name"}
                        name={`fileName`}
                        value={values?.fileName ?? ""}
                        onChange={(e) => setFieldValue("fileName", e)}
                        type={"text"}
                        validationErrors={touched?.fileName && errors?.fileName?.toString() || ""}
                    />
                </CCol>
            </CModalBody>
            <CModalFooter>
                <CRow className="justify-content-between w-100">
                    <CCol className="text-right">
                        <ActionButton
                            text={"Save"}
                            onClick={() => handleSubmit()}
                        />
                    </CCol>
                </CRow>
            </CModalFooter>
        </CModal>
    )
}

export default TransactionAttachmentTypesModal;