import { CRow, CCol } from "@coreui/react";
import React from "react";
import ShipClient from "../../../../clients/shipClient";
import { notEmpty } from "../../../../utils/commonValidators";
import { Input } from "../../../SharedComponents/SWForm";

const SortingRuleInputs = () => {
  const shipClient = new ShipClient();
  return (
    <>
      <CRow>
        <CCol>
          <Input
            label="Container Type"
            type="typeahead"
            field="containerType"
            getLookUp={async () =>
              (await shipClient.GetContainerTypes(null, true)) as {
                [k: string]: string;
              }
            }
          />
        </CCol>
        <CCol>
          <Input
            validators={[notEmpty]}
            label="Container Prefix"
            type="text"
            field="containerPrefix"
          />
        </CCol>
        <CCol>
          <Input label="Order" type="text" field="order" />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <Input
            validators={[notEmpty]}
            label="Property Name"
            type="text"
            field="propertyName"
          />
        </CCol>
        <CCol>
          <Input
            label="Operator"
            type="typeahead"
            field="operator"
            lookUp={{
              "0": "Equals",
            }}
          />
        </CCol>
        <CCol>
          <Input
            validators={[notEmpty]}
            label="Value"
            type="text"
            field="value"
          />
        </CCol>
        <CCol>
          <Input label="Color" type="color" field="extraData.color" />
        </CCol>
      </CRow>
    </>
  );
};

export default SortingRuleInputs;
