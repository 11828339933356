import React, { useEffect, useState } from "react";
import {
    CButtonGroup,
    CCol,
    CRow,
    CButton,
    CCard,
    CDataTable,
    CModal, CInputGroup, CTooltip, CForm, CLink, CTabs, CTabContent, CNav, CNavLink, CTabPane, CModalHeader, CModalBody, CInput, CModalFooter
} from "@coreui/react";
import WarehouseClient from "../../../../clients/warehousesClient";
import { useFormik } from "formik";
import {
    InventoryTransactionDetailDto,
    AllocationStrategy,
    SkuSearch,
    InventoryTransaction,
    InventoryDetailsReportQueryParameters, InventoryDetailsReportItem, subType, warehouseDto, SkuLookupResult, TransactionAttachmentDto,
} from "../../../../models/warehouse";
import SWInput from "../../../SharedComponents/SWInput";
import SWSelect from "../../../SharedComponents/SWSelect";

import { SetLoading, ShowNotification } from "../../../../store/actions/auth";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import SWDateAndTimePicker from "../../../SharedComponents/SWDateAndTimePicker";
import CIcon from "@coreui/icons-react";
import { freeSet } from "@coreui/icons";
import { useHistory, useParams } from "react-router";
import StockStamp from "./StockStamp";
import SWInputNumber from "../../../SharedComponents/SWInputNumber";
import ConformModal from '../../../SharedComponents/ConfirmModal'
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { ShipmentSearch } from "../../../../models/shipment";
import { formatDate } from "../../../../utils/dateUtil";
import ShipClient from "../../../../clients/shipClient";
import ActionButton from "../../../SharedComponents/ActionButton";
import CameraIcon from "../../../../icons/camera";
import CaptureImageModal from "../../../SharedComponents/CaptureImageModal";
import { FilterGroup, IPaginatedResult } from "../../../../models/filter";
import { RemoteBlob } from "../../../../models/app";
import PageCount from "../../../SharedComponents/PageCount";
import TransactionAttachmentTypesModal from "./components/transactionAttachmentTypesModal";

interface QuantityHelper extends InventoryDetailsReportItem {
    quantityChange?: number
    newStatus?: number
    toLocation?: string
}

const CreateStock = () => {

    const {id} = useParams() as any;
    useEffect(() => {
        loadForm();
        searchTransactionAttachmentsTypesLookup();
    }, [id]);
    const hist = useHistory();


    const {width} = useWindowDimensions();


    const [accountsLookup, setAccountsLookup] = useState<{ [k: string]: string }>({})
    const [subTypesLookup, setSubTypesLookup] = useState<{ [k: string]: string }>({})
    const [warehouseLookup, setWarehouseLookup] = useState<{ [k: string]: string }>({})
    const [transactionAttachmentConfirmDelete,setTransactionAttachmentConfirmDelete]=useState<{id:number,name:string}|null>()
    const [locationsLookup, setLocationsLookup] = useState<{ [k: string]: string }>({})
    const [skusLookup, setSkusLookup] = useState<{ [k: string]: string }>({})
    const [skus, setSkus] = useState<SkuSearch[]>([])
    const [selectedSku, setSelectedSku] = useState<SkuSearch>()
    const [selectedWarehouse, setSelectedWarehouse] = useState<warehouseDto | undefined>(undefined);
    const [selectedSkuQuantities, setSelectedSkuQuantities] = useState<QuantityHelper[]>([])
    const [title, setTitle] = useState<string>()
    const [posted, setPosted] = useState(false)
    const [isReverseModalShowing, setIsReverseModalShowing] = useState(false)
    const skuStatus: { [p: string]: string } = {
        0: "intact",
        1: "Cover Damaged",
        2: "Damaged"
    };
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isPosting, setIsPosting] = useState<boolean>(false)
    const [isWarning, setIsWarning] = useState<boolean>(false)
    const [reversed, setReversed] = useState<boolean>(false)
    const [reversal, setReversal] = useState<boolean>(false)
    const [reverseCode, setReverseCode] = useState<string>()
    const [formBarcodeHelper, setFormBarcodeHelper] = useState("")
    const [formDetailsBarcodeHelper, setFormDetailsBarcodeHelper] = useState("")
    const [showSkuSelector, setShowSkuSelector] = useState(false)
    const [skuLookupResult, setSkuLookupResult] = useState<SkuLookupResult[]>([]);

    const [captureModal, setCaptureModal] = useState<boolean>(false);
    const [fileLoading, setFileLoading] = useState<boolean>(false);
    const [transactionAttachments, setTransactionAttachments] = useState<TransactionAttachmentDto[]>();

    const [selectedAttachmentType,setSelectedAttachmentType] = useState<string>("");
    const [transactionAttachmentTypesModal,setTransactionAttachmentTypesModal] = useState<{fileData?:RemoteBlob,toBeUpdatedData?:TransactionAttachmentDto,isOpen:boolean,type:"Update"|"Add"} | null>(null)
    const [transactionAttachmentsTypesLookup,setTransactionAttachmentsTypesLookup] = useState<{ [k: string]: string }>({});
    const warehouseClient = new WarehouseClient();

    const [filterGroup, setFilterGroup] = useState<FilterGroup>({
        page: 0,
        pageSize: 20,
        limit: 20
    });

    const getReservationStatus = (details: InventoryTransactionDetailDto[]): string => {
        const totalReserved = details.reduce((total, b) => total + (b?.quantityAvailable ?? 0), 0)
        const consumptionDetails = details.filter(d => d.relatedDetails != undefined)
            .reduce((a: InventoryTransactionDetailDto[], b) => a.concat(b.relatedDetails ?? []), []);


        const totalConsumed = consumptionDetails.reduce((total, b) => total + (b?.quantity ?? 0), 0);

        if (totalConsumed == 0)
            return ""

        const consumptionPropertiesArray = consumptionDetails.map(d => `${d.transaction?.code} On ${formatDate(d.transaction?.on)}`);

        const consumptionProperties = consumptionPropertiesArray.filter((value, index, self) => self.findIndex((m) => m === value) === index);

        if (totalConsumed == totalReserved)
            return ` Consumed by ${consumptionProperties.join()}`

        return ` Partially Consumed by ${consumptionProperties.join()}`

    }

    const dispatch = useDispatch();


    const searchTransactionAttachmentsTypesLookup = async () => {
        const res = await warehouseClient.SearchTransactionAttachmentsTypesLookup();
        const formattedData: { [key: string]: string } = {};
        res?.data?.forEach((item) => {
          formattedData[item.code] = item.value;
        });
       setTransactionAttachmentsTypesLookup(formattedData)
    }
    const loadForm = async () => {
        setIsLoading(true)
        setTitle("Loading...")

        const customers = await warehouseClient.LookupCustomers();
        setAccountsLookup(customers);
        const warehouses = await warehouseClient.warehousesLookup()
        setWarehouseLookup(warehouses);
        const subTypes = await LoadSubtypes()
        if (!id) {
            setIsLoading(false)
            setTitle("New Transaction")
            return
        }

        const rs = await warehouseClient.getStock(id);
        if (!rs) {
            setIsLoading(false)
            return
        }
        const stock = rs.transaction.posted ? rs.transaction : rs.originalRequest;
        setPosted(stock?.posted ?? false);
        if (stock.reversedCode) {
            setReversal(true)
            setReverseCode(stock.reversedCode)
            setReversed(false)
        } else if (stock.reversalCode) {
            setReversed(true)
            setReverseCode(stock.reversalCode)
            setReversal(false)
        }


        //await setFieldValue("type", stock.type)
        await setFieldValue("subType", stock.subType)
        await setFieldValue("reference", stock.reference)
        await setFieldValue("shipmentNumber", stock.shipmentNumber)
        await setFieldValue("notes", stock.notes)
        await setFieldValue("on", stock.on)
        await GetType(stock.subType);

        const subTypeName = subTypes[stock.subType ?? ""];


        if (stock.posted)
            setTitle(`${subTypeName} ${stock.code}`)
        else
            setTitle(subTypeName)


        setIsLoading(false)
        if (!stock.details || stock.details.length == 0) {
            return;
        }
        const data: warehouseDto = await warehouseClient.GetWarehouse(stock.warehouse ?? stock.details[0].warehouse ?? stock.details[0].fromWarehouse)
        setSelectedWarehouse(data)
        const skus = await loadSkus(stock.details[0].lot.account);
        for (const d of stock.details) {
            d.lot.skuName = skus[d.lot.sku];
            const data = await warehouseClient.inventoryDetailsReport({
                batchNumber: d.lot.batchNumber,
                location: stock.type == "TSFR" ? d.fromLocation : d.location,
                warehouse: d.warehouse,
                excludeZero: true,
                account: d.lot.account,
                code: d.lot.sku,
                itemNumber: d.lot.itemNumber,
                expiryDate: d.lot.expiryDate

            });
            d.current = data.find(() => true)?.quantity ?? 0;
            d.available = data.find(() => true)?.quantityAvailable ?? 0;

        }
        setTransactionAttachments(rs.transaction?.attachments ?? [])
        await setFieldValue("details", stock.details)
        await LoadAreasAndLocation(stock.warehouse ?? stock.details[0].warehouse ?? stock.details[0].fromWarehouse)
        await setFieldValue("area", stock.details[0].area);
        await setFieldValue("accountNumber", stock.details[0].lot.account)
        await setFieldValue("warehouse", stock.details[0].warehouse ?? stock.warehouse)


    };

    const filteredStatus = (ignore: string) => Object.keys(skuStatus)
        .filter(key => key != ignore).reduce((obj, key) => {

            return {
                ...obj,
                [key]: skuStatus[key]
            };
        }, {});

    // const filteredLocation = (ignore: string) => {
    //     return Object.keys(locationsLookup)
    //         .filter(key => key != ignore).reduce((obj, key) => {
    //             return {
    //                 ...obj,
    //                 [key]: locationsLookup[key]
    //             };
    //         }, {});
    //
    // }

    const checkShipmentExists = async (number: string) => {
      const shipClient = new ShipClient()
       const res = await shipClient.getShipmentByNumber(number)
        return res?.found
    }
    const {
        values,
        errors,
        handleBlur,
        touched,
        handleChange,
        setFieldValue,
        handleSubmit
    } = useFormik<InventoryTransaction>({
        initialValues: {
            on: (new Date(Date.now())).toISOString(),

            subType: "",
            type: "",
            accountNumber: "",
            warehouse: "",
            store: "MAIN",
            area: "",
            toArea: "",
            toLocation: "",
            details: [],
            sku: "",
            notes: "",
            location: "",
            barcode: "",
            reference: "",
            shipmentNumber: "",
            batchNumber: "",
            itemNumber: "",
            status: 0,
        },
        onSubmit: async (values) => {

            dispatch(SetLoading(true));
            if (!values.details || values.details.length == 0) {
                const result = await warehouseClient.deleteTransaction(id);
                dispatch(SetLoading(false));
                if (!result)
                    return;
                hist.push(`/stock/transactions/create`);
                return;
            }
            const postBody = {
                ...values,
                details: values.details.filter(d => d.quantity !== undefined || d.quantity !== 0)
            }
            if (values.shipmentNumber)
            {
                let shipmentExists = checkShipmentExists(values.shipmentNumber)
                if (!shipmentExists) {
                    dispatch(ShowNotification("Error", `Shipment with number: ${values.shipmentNumber} not found`, true))
                    return
                }
            }
            let type = values.type
            if (id) {
                let res;
                switch (type) {
                    case "RECV" :
                        res = await warehouseClient.updateReceiving(id, postBody)
                        break;
                    case "CONS" :
                        res = await warehouseClient.updateConsuming(id, postBody)
                        break;
                    case "TSFR" :
                        res = await warehouseClient.updateTransferring(id, postBody)
                        break;
                    case "CHST" :
                        res = await warehouseClient.updateChangeStatus(id, postBody)
                        break;
                }

            } else {
                let res;
                switch (type) {
                    case "RECV" :
                        res = await warehouseClient.createReceiving(postBody)
                        break;
                    case "CONS" :
                        res = await warehouseClient.createConsuming(postBody)
                        break;
                    case "TSFR" :
                        res = await warehouseClient.createTransferring(postBody)
                        break;
                    case "CHST" :
                        res = await warehouseClient.createChangeStatus(postBody)
                        break
                }

                dispatch(SetLoading(false));
                if (res?.succeeded) {
                    hist.push(`/stock/transactions/${res.data}`);
                }
            }


        },
        validationSchema: yup.object({
            reference: yup.string().max(50),

            accountNumber: yup.string().required()
        })
    });

    const quantityHelperQuantityChanged = (index: number, quantity?: number) => {


        if (values.type != "RECV" && quantity && selectedSkuQuantities[index].quantityAvailable < (quantity))
            return;

        setSelectedSkuQuantities(selectedSkuQuantities.map((sq, i) => i == index ? {
            ...sq,
            quantityChange: quantity
        } : sq))
    }


    const quantityHelperStatusChanged = (index: number, status?: number) =>
        setSelectedSkuQuantities(selectedSkuQuantities.map((sq, i) => i == index ? {...sq, newStatus: status} : sq))
    const quantityHelperLocationChanged = async (index: number, toLocation?: string) => {
        if (selectedSkuQuantities[index].location == toLocation) {
            dispatch(ShowNotification("Same location chosen", "Can not transfer to same location", true));
            return;
        }
        setSelectedSkuQuantities(selectedSkuQuantities.map((sq, i) => i == index ? {
            ...sq,
            toLocation: toLocation
        } : sq))


        //let location = data.location.find(l => l.code == val)
    }


    const addFromHelper = (index: number) => {
        const item = selectedSkuQuantities[index];

        const itemFound = values.details.find(c => c.lot.sku == selectedSku?.code && c.location == item.location &&
            c.newStatus == item.newStatus && c.toLocation == item.toLocation &&
            c.lot.batchNumber == item.batchNumber && c.lot.itemNumber == item.itemNumber &&
            dateAreEqual(c.lot.expiryDate, item.expiryDate) && c.lot.status == item.status);


        if (itemFound) {
            const index = values.details.indexOf(itemFound);

            setFieldValue("details", [
                ...values.details.slice(0, index),
                {
                    ...values.details[index],
                    quantity: item.quantityChange,
                    newStatus: item.newStatus,
                    toLocation: item.toLocation
                },
                ...values.details.slice(index + 1)
            ])
        } else {

            setFieldValue("details", [...values.details, {
                area: item.area,
                store: item.store,
                lot: {
                    account: values.accountNumber,
                    batchNumber: item.batchNumber,
                    itemNumber: item.itemNumber,
                    sku: selectedSku?.code,
                    skuName: selectedSku?.name,
                    expiryDate: item.expiryDate,

                    //serialNumber: values.serialNumber,
                    status: item.status
                },
                warehouse: item.warehouse,
                fromWarehouse: item.warehouse,
                fromStore: item.store,
                fromArea: item.area,
                fromLocation: item.location,

                location: item.location ?? "",
                quantity: item.quantityChange,
                newStatus: item.newStatus,
                toWarehouse: item.warehouse,
                toStore: selectedWarehouse?.location.find(l => l.code == item.toLocation)?.store,
                toArea: selectedWarehouse?.location.find(l => l.code == item.toLocation)?.area,
                toLocation: item.toLocation,
                type: values.type,
                subType: values.subType,
                available: item.quantityAvailable,
                current: item.quantity
            }])

        }

        handleSubmit()

    }
    const LoadAreasAndLocation = async (w?: string) => {
        let data = await warehouseClient.GetWarehouseWithLookups(w)

        setLocationsLookup(data.locationLookup)
    }

    const GetType = async (subType: string) => {
        let res = await warehouseClient.getSubTypes(subType)
        await setFieldValue("type", res.type)

    }


    const onLookupSelected = async (lookupResult: SkuLookupResult) => {
        await setFieldValue("accountNumber", lookupResult.accountId)
        setShowSkuSelector(false);
        const warehouseSelected = lookupResult.warehouseCode ?? lookupResult.allowedWarehousesCodes.length == 1 ?
            lookupResult.allowedWarehousesCodes[0] : undefined;


        if (warehouseSelected)
            onWarehouseChange(warehouseSelected);
        const skusRes = await warehouseClient.SearchSku({limit: 50000, accountNumber: lookupResult.accountId})
        setSkus(skusRes.data.matches)

        const skusForLookup: { [k: string]: string } = skusRes.data.matches.reduce((dic: { [x: string]: any; }, s: { code: string | number; name: any; }) => (dic[s.code] = s.name + " - " + s.code, dic), {});
        setSkusLookup(skusForLookup);
        setFieldValue("sku", lookupResult.code);
        // @ts-ignore
        const selected = skusRes.data.matches.find(sku => sku.code == lookupResult.code);

        setSelectedSku(selected);
        setFieldValue("code", selected?.code)
        if (!warehouseSelected)
            return;
        let body: InventoryDetailsReportQueryParameters = {
            code: lookupResult.code ?? "",
            account: lookupResult.accountId,
            warehouse: lookupResult.warehouseCode,
            excludeZero: true
        }

        let data = await warehouseClient.inventoryDetailsReport(body)

        setSelectedSkuQuantities(data);

        const input = document.getElementById("loc");
        input?.focus();


    }

    const onAccountChange = async (a?: string) => {
        await setFieldValue("accountNumber", a)
        let data = await warehouseClient.GetAccount(a ?? "")
        if (data.warehouse) {
            onWarehouseChange(data.warehouse);
        }
        await loadSkus(a);
        await setFieldValue("sku", null)
        setSelectedSku(undefined)
    }

    const onSkuChange = async (s?: string) => {

        setFieldValue("sku", s);
        setFieldValue("batchNumber", null);
        setFieldValue("itemNumber", null);
        setFieldValue("expiryDate", null);
        const selected = skus.find(sku => sku.code == s);
        setSelectedSku(selected);
        setFieldValue("code", selected?.code)
        let body: InventoryDetailsReportQueryParameters = {
            code: s ?? "",
            account: values.accountNumber,
            warehouse: values.warehouse,
            excludeZero: true
        }

        let data = await warehouseClient.inventoryDetailsReport(body)

        setSelectedSkuQuantities(data);

    }
    const onWarehouseChange = async (w?: string) => {
        await setFieldValue("warehouse", w)
        await LoadAreasAndLocation(w)
        await setFieldValue("area", null)
        const data: warehouseDto = await warehouseClient.GetWarehouse(w)
        setSelectedWarehouse(data);


    }

    const onSubTypeChange = async (val: string | undefined) => {
        await GetType(val ?? "")
        await setFieldValue("shipmentNumber", "")
        await setFieldValue("subType", val)
    }

    const loadSkus = async (a?: string) => {
        const skusRes = await warehouseClient.SearchSku({limit: 50000, accountNumber: a ?? ""})
        setSkus(skusRes.data.matches)

        const skusForLookup: { [k: string]: string } = skusRes.data.matches.reduce((dic: { [x: string]: any; }, s: { code: string | number; name: any; }) => (dic[s.code] = s.name + " - " + s.code, dic), {});
        setSkusLookup(skusForLookup);
        return skusForLookup;
    }


    const LoadSubtypes = async () => {
        const subtypes = await warehouseClient.getSubTypesLookup()

        if (!subtypes)
            return;
        //delete subtypes.RECV
        delete subtypes.OpeningBalance

        setSubTypesLookup(subtypes)
        return subtypes;
    }

    const onLocationChange = async (loc?: string) => {

        if (values.type == "TSFR" && values.toLocation == loc) {
            dispatch(ShowNotification("Same location chosen", "Can not transfer to same location", true));
            return;
        }
        await setFieldValue("location", loc)
        if (!selectedWarehouse) return;
        let location = selectedWarehouse.location.find(l => l.code == loc)
        await setFieldValue("area", location?.area)
    }

    const onToLocationChange = async (loc?: string) => {

        if (values.location == loc) {
            dispatch(ShowNotification("Same location chosen", "Can not transfer to same location", true));
            return;
        }

        await setFieldValue("toLocation", loc)
        if (!selectedWarehouse) return;

        let location = selectedWarehouse.location.find(l => l.code == loc)
        await setFieldValue("toArea", location?.area)
    }


    const onAddUpdate = async () => {

        const itemFound = values.details.find(c => c.lot.sku == values.sku && c.location == values.location &&
            c.lot.batchNumber == values.batchNumber && c.lot.itemNumber == values.itemNumber &&
            dateAreEqual(c.lot.expiryDate, values.expiryDate) && c.lot.status == values.status);

        if (values.type !== "RECV") {
            const quantitiesAvailable = await getQuantitiesData();
            const item = quantitiesAvailable.find(c => c.location == values.location &&
                c.batchNumber == values.batchNumber && c.itemNumber == values.itemNumber &&
                dateAreEqual(c.expiryDate, values.expiryDate));

            if ((values.quantity ?? 0) > (item?.quantityAvailable ?? 0)) {
                dispatch(ShowNotification(`Insufficient Quantity ${values.quantity}`,
                    item?.quantityAvailable ? `Maximum allowed is ${item?.quantityAvailable}` :
                        "No quantity is available"
                    , true));
                return;
            }

        }

        setFieldValue("details", [...values.details, {
            area: values.area,
            store: values.store,
            lot: {
                account: values.accountNumber,
                batchNumber: values.batchNumber,
                itemNumber: values.itemNumber,
                sku: values.sku ?? "",
                skuName: selectedSku?.name,
                expiryDate: values.expiryDate,

                //serialNumber: values.serialNumber,
                status: values.status
            },
            location: values.location ?? "",
            warehouse: values.warehouse,
            toLocation: values.toLocation ?? "",
            fromWarehouse: values.warehouse,
            fromStore: values.store,
            fromArea: values.area,
            fromLocation: values.location,
            newStatus: values.newStatus,
            toWarehouse: values.warehouse,
            toStore: selectedWarehouse?.location.find(l => l.code == values.toLocation)?.store,
            toArea: selectedWarehouse?.location.find(l => l.code == values.toLocation)?.area,
            quantity: values.quantity ?? 1,
            type: values.type,
            subType: values.subType
        }])
        setFieldValue("sku", null);
        setFieldValue("batchNumber", null);
        setFieldValue("expiryDate", null);
        setFieldValue("itemNumber", null);

        setSelectedSku(undefined);
        setFieldValue("code", null)
        setFieldValue("location", null);
        setFieldValue("area", null);
        setFieldValue("quantity", "");
        setFieldValue("toLocation", null);
        setFieldValue("toArea", null);

        setSelectedSkuQuantities([]);
        handleSubmit();


    }

    const handlePost = async () => {
        setIsPosting(true);
        const postBody = {...values, details: values.details.filter(d => d.quantity !== undefined || d.quantity !== 0)}
        let type = values.type
        if (id) {
            let res;
            switch (type) {
                case "RECV" :
                    res = await warehouseClient.updateReceiving(id, postBody)
                    break;
                case "CONS" :
                    res = await warehouseClient.updateConsuming(id, postBody)
                    break;
                case "TSFR" :
                    res = await warehouseClient.updateTransferring(id, postBody)
                    break;
                case "CHST" :
                    res = await warehouseClient.updateChangeStatus(id, postBody)
                    break;
            }
            if (res?.succeeded) {
                let postRes;
                switch (type) {
                    case "RECV" :
                        postRes = await warehouseClient.postReceiving(id)
                        break;
                    case "CONS" :
                        postRes = await warehouseClient.postConsuming(id)
                        break;
                    case "TSFR" :
                        postRes = await warehouseClient.postTransferring(id)
                        break;
                    case "CHST" :
                        postRes = await warehouseClient.postChangeStatus(id)
                        break;
                }
                setIsPosting(false);
                if (postRes?.succeeded) {
                    hist.push(`/stock/transactions/${postRes.data}`);
                }
            } else {
                setIsPosting(false);
            }
        } else {
            let res;
            switch (type) {
                case "RECV" :
                    res = await warehouseClient.createReceiving({...postBody, post: true})
                    break;
                case "CONS" :
                    res = await warehouseClient.createConsuming({...postBody, post: true})
                    break;
                case "TSFR" :
                    res = await warehouseClient.createTransferring({...postBody, post: true})
                    break;
                case "CHST" :
                    res = await warehouseClient.createChangeStatus({...postBody, post: true})
                    break
            }
            setIsPosting(false);
            if (res?.succeeded) {
                hist.push(`/stock/transactions/${res.data}`);
            }
        }
    }

    const getQuantitiesData = async () => {
        let req: InventoryDetailsReportQueryParameters = {
            account: values.accountNumber,
            code: values.sku ?? "",
            warehouse: values.warehouse,
            store: values.store,
            area: values.area,
            location: values.location
        }
        return await warehouseClient.inventoryDetailsReport(req)
    }

    const handleReverse = async () => {
        let res;
        switch (values.type) {
            case "RECV" :
                res = await warehouseClient.reverseReceiving(id)
                break;
            case "CONS" :
                res = await warehouseClient.reverseConsuming(id)
                break;
            case "TSFR" :
                res = await warehouseClient.reverseTransferring(id)
                break;
            case "CHST" :
                res = await warehouseClient.reverseChangeStatus(id)
                break;
            case "RSRV" :
                res = await warehouseClient.reverseReservation(id)
                break;
        }
        if (res?.succeeded)
            hist.push('/stock/transactions')
    }
    const removeDetails = async (index: number) => {
        await setFieldValue("details", values.details.filter((item, i) => i != index));
        handleSubmit()
    }

    const inTd = (val: any) => <td>{val}</td>;
    const postedScopedSlots = {
        location: (item: InventoryTransactionDetailDto) => inTd(item.location ?? item.fromLocation),
        skuCode: (item: InventoryTransactionDetailDto) => <td><CLink to={`/stock/skus/${item.lot.skuId}`} >{item.lot.sku} </CLink></td>,
        skuName: (item: InventoryTransactionDetailDto) => <td><CLink to={`/stock/skus/${item.lot.skuId}`} >{item.lot.skuName} </CLink></td>,
        skuStatus: (item: InventoryTransactionDetailDto) => inTd(skuStatus[item.lot.status]),
        batchNumber: (item: InventoryTransactionDetailDto) => inTd(item.lot.batchNumber),
        itemNumber: (item: InventoryTransactionDetailDto) => inTd(item.lot.itemNumber),
        expiryDate: (item: InventoryTransactionDetailDto) => inTd(formatDate(item.lot.expiryDate)),
        current: (item: InventoryTransactionDetailDto) => inTd(item.current),
        available: (item: InventoryTransactionDetailDto) => inTd(item.available),
        actions: (item: InventoryTransactionDetailDto, index: number) => {
            if (posted) return <td>{item.quantity}</td>

            return <td>
                {item.quantity}
                {values.type == "CHST" && ` / ${skuStatus[item.newStatus ?? ""]}`}
                {values.type == "TSFR" && ` / ${item.toLocation}`}
                {/*<CRow>*/}
                {/*    <SWInputNumber readonly className={"col"} name={'quantity'} value={item.quantity}*/}
                {/*                   onChange={val => setFieldValue(`details[${index}].quantity`, val)}/>*/}
                {/*    {values.type == "CHST" &&*/}
                {/*    <SWSelect readonly className={"col-6"} values={filteredStatus(item.lot.status.toString())}*/}
                {/*              value={item.newStatus}*/}
                {/*              onChange={val => setFieldValue(`details[${index}].newStatus`, val)}/>}*/}
                {/*    {values.type == "TSFR" &&*/}
                {/*    <SWSelect readonly className={"col-6"} values={filteredLocation(item.location)}*/}
                {/*              value={item.toLocation}*/}
                {/*              onChange={val => onTsfrLocationChange(index, val)}/>}*/}
                {/*</CRow>*/}
            </td>;
        },
        remove: (item: InventoryTransactionDetailDto, index: number) => (
            <td>{!posted &&
                <CIcon style={{cursor: 'pointer'}} content={freeSet.cilTrash}
                       onClick={() => removeDetails(index)}/>}</td>
        )
    };

    const handleClear = () => {
        setFieldValue("details", [])
        handleSubmit()
        setIsWarning(false)
    }

    const dateAreEqual = (left: string | null | undefined, right: string | null | undefined) => {
        if (!left && !right)
            return true;
        if (!left && right)
            return false;
        if (left && !right)
            return false;
        return formatDate(left) == formatDate(right);

    }

    const handleFileImport = async (file?: File) => {
        setFileLoading(true)
        if (!file) return
        let res = await warehouseClient.UploadTransactionAttachment(file, id)
        await loadForm();
        if (res?.location) {
            setTransactionAttachmentTypesModal({fileData:res,isOpen:true,type:"Add"})
            setFileLoading(false)
        }
    }
    const addTransactionAttachment = async (type:string,fileName:string,fileData:RemoteBlob) => {
            await warehouseClient.AddTransactionAttachment({
                type,
                fileName,
                location:fileData?.location,
                inventoryTransactionCode: id
            })
            setTransactionAttachmentTypesModal(null);
            await loadForm();
    }
    const handleAddBulkTransactionAttachments = async (attachments: RemoteBlob[]) => {
        const mappedAttachments = attachments?.map((attachment: RemoteBlob) => ({
            fileName: attachment?.name,
            location: attachment?.location,
            type: selectedAttachmentType,
            inventoryTransactionCode: id
        }))
        await warehouseClient.AddBulkTransactionAttachments({ attachments: mappedAttachments })
        await loadForm();
        setCaptureModal(false)
        setSelectedAttachmentType('')
    }
    const handleDeleteTransactionAttachments = async (id: number) => {
        await warehouseClient.DeleteTransactionAttachment({ id })
        await loadForm();
        setTransactionAttachmentConfirmDelete(null)
    }

    const handleUpdateTransactionAttachments = async (id:number,type:string,fileName:string) => {
        await warehouseClient.UpdateTransactionAttachment({ id ,type,fileName})
        await loadForm();
    }

    const handleDownload = (text: string) => {
        console.log(text)
        const link = document.createElement("a");
        link.href = text;
        link.click();
        link.remove();
    }

    const transactionAttachmentsScopedSlots = {
        download: (e: TransactionAttachmentDto) => {
            return (
                <td>
                    <CTooltip content={"Download"}>
                        <CIcon
                            content={freeSet.cilCloudDownload}
                            style={{ cursor: "pointer", margin: "auto" }}
                            onClick={() => handleDownload(e.getAttachmentLink)}
                        />
                    </CTooltip>
                </td>
            )
        },
        createdOn: (e: TransactionAttachmentDto) => {
            return (
                <td>
                    {formatDate(e?.createdOn)}
                </td>
            )
        },
        action: (e: TransactionAttachmentDto) => {
            return <td>
                <CTooltip content={"Edit"}>
                    <CIcon
                        content={freeSet.cilPencil}
                        style={{ cursor: "pointer", marginRight: "5px"  }}
                        onClick={() => setTransactionAttachmentTypesModal({isOpen:true,type:"Update",toBeUpdatedData:e})}
                    />
                </CTooltip>
                <CTooltip content={"Delete"}>
                    <CIcon
                        content={freeSet.cilTrash}
                        style={{ cursor: "pointer" }}
                        onClick={() =>setTransactionAttachmentConfirmDelete({name:e?.fileName,id:e?.id})}
                    />
                </CTooltip>
            </td>
        },
    }

    return <CCard className="bg-white p-4 text-primary">
        <CRow>
            <CTooltip content="Back">
                <CButton>
                    <CIcon
                        content={freeSet.cilArrowLeft}
                        onClick={() =>  hist.goBack()}
                    />
                </CButton>
            </CTooltip>
        </CRow>
        <CRow>
            {reversed && <StockStamp/>}
            {id ? <CCol><h5 style={{color: "gray"}}>{title} {getReservationStatus(values.details)} </h5></CCol> :
                <CCol>
                    <h5 style={{marginBottom: "20px", color: "gray"}}>New Transaction</h5>
                    <SWSelect readonly={posted || (values.details?.length != 0 ?? false) || isLoading}
                              onBlur={handleBlur}
                              validationErrors={touched.subType && errors.subType || ""}
                              values={subTypesLookup} value={values.subType} name="subType" label={"Type"}
                              onChange={onSubTypeChange}/>
                </CCol>
            }
            {!id && (values.subType == "OrderFulfillment" || values.subType == "ReturnFromDelivery") &&
                <CCol md={3} style={{marginTop:"41px"}}>
                    <SWInput readonly={posted || isLoading} onBlur={handleBlur}
                             validationErrors={touched.shipmentNumber && errors.shipmentNumber || ""}
                             label={"Shipment Number"} name={"shipmentNumber"} value={values.shipmentNumber}
                             onChangeFormik={handleChange} />
                </CCol>
            }

            <CCol md={3}/>
            {reversed && <h4>Reversed by :<CButton onClick={() => hist.push(`/stock/transactions/${reverseCode}`)}
                                                   color={"link"}>{reverseCode}</CButton></h4> ||
                reversal && <h4>Reversal of :<CButton onClick={() => hist.push(`/stock/transactions/${reverseCode}`)}
                                                      color={"link"}>{reverseCode}</CButton></h4>}
        </CRow>
        <CRow>
            {!id ?
                <CCol md={3}>
                    <CForm
                        onSubmit={async (e) => {
                            e.preventDefault();
                            if (!formBarcodeHelper)
                                return;
                            const result = await warehouseClient.lookup({
                                code: formBarcodeHelper,
                                excludeZero: values.type != "RECV"
                            })
                            if (result.length == 1)
                                onLookupSelected(result[0]);
                            if (result.length > 1) {
                                setSkuLookupResult(result)
                                setShowSkuSelector(true)
                            }
                            if (result.length == 0)
                                dispatch(ShowNotification("No match found", "Can not find a match for barcode"
                                    , true));
                            setFormBarcodeHelper("");

                        }}>
                        <SWInput readonly={!values.type}
                                 label={"Scan barcode"} name={"formBarcode"} value={formBarcodeHelper}
                                 onChange={(c => setFormBarcodeHelper(c))}
                                 append={<CButton style={{height: "28px"}} className="input-group-text"
                                                  disabled={!formBarcodeHelper}
                                                  type={"submit"}> ?</CButton>}/>
                    </CForm>


                </CCol> :
                <CCol md={3}>
                    <SWInput readonly={posted || isLoading} onBlur={handleBlur}
                             validationErrors={touched.shipmentNumber && errors.shipmentNumber || ""}
                             label={"Shipment Number"} name={"shipmentNumber"} value={values.shipmentNumber}
                             onChangeFormik={handleChange} />
                </CCol>
            }
            <CCol md={3}>
                <SWSelect readonly={posted || (values.details?.length != 0 ?? false) || isLoading} onBlur={handleBlur}
                          validationErrors={touched.accountNumber && errors.accountNumber || ""}
                          label={"Account"} values={accountsLookup}
                          value={values.accountNumber}
                          name="accountNumber" onChange={onAccountChange}/>
            </CCol>


            <CCol md={3}>
                <SWSelect readonly={posted || (values.details?.length != 0 ?? false) || isLoading} onBlur={handleBlur}
                          validationErrors={touched.warehouse && errors.warehouse || ""}
                          label={"Warehouse"} values={warehouseLookup}
                          value={values.warehouse}
                          name="warehouse" onChange={onWarehouseChange}/>
            </CCol>
        </CRow>

        <CRow>
            <CCol md={3}>
                <SWInput readonly={posted || isLoading || values.shipmentNumber ? true: false} onBlur={handleBlur}
                         validationErrors={touched.reference && errors.reference || ""}
                         label={"Reference"} name={"reference"} value={values.reference}
                         onChangeFormik={handleChange}/>

            </CCol>
            <CCol md={3}>
                <SWInput readonly={posted || isLoading} onBlur={handleBlur}
                         validationErrors={touched.notes && errors.notes || ""}
                         label={"Notes"} name={"notes"} value={values.notes}
                         onChangeFormik={handleChange}/>

            </CCol>
            <CCol md={3}>
                <SWDateAndTimePicker readOnly={posted || isLoading} onBlur={handleBlur}
                                     validationErrors={touched.on && errors.on || ""}
                                     label={"Date"} name={"on"} value={values.on}
                                     handleOnChange={(e) => setFieldValue("on", e)}/>
            </CCol>
        </CRow>

        {!posted && skus.length > 0 && values.warehouse &&
            <>
                <h4 style={{color: "gray"}}>Details</h4>
                <CRow>
                    <CCol md={3}>
                        <CForm
                            onSubmit={async (e) => {
                                e.preventDefault();
                                if (!formDetailsBarcodeHelper)
                                    return;

                                const result = await warehouseClient.lookup({
                                    code: formDetailsBarcodeHelper,
                                    excludeZero: values.type != "RECV",
                                    account: values.accountNumber
                                })
                                if (result.length == 1)
                                    onLookupSelected(result[0]);
                                if (result.length > 1) {
                                    setSkuLookupResult(result)
                                    setShowSkuSelector(true)
                                }
                                if (result.length == 0)
                                    dispatch(ShowNotification("No match found", "Can not find a match for barcode"
                                        , true));
                                setFormDetailsBarcodeHelper("");

                            }}>
                            <SWInput
                                label={"scan barcode"} name={"formBarcode"} value={formDetailsBarcodeHelper}
                                onChange={(c => setFormDetailsBarcodeHelper(c))}
                                append={<CButton style={{height: "28px"}} className="input-group-text"
                                                 disabled={!formDetailsBarcodeHelper}
                                                 type={"submit"}> ?</CButton>}/>
                        </CForm>


                    </CCol>

                    <CCol md={3}>
                        <SWSelect onBlur={handleBlur} validationErrors={touched.sku && errors.sku || ""}
                                  label={"Select Sku"}
                                  values={skusLookup} value={values.sku} name="sku"
                                  onChange={onSkuChange}/>
                    </CCol>
                    <CCol md={3}>
                        <SWSelect id={"loc"} onBlur={handleBlur}
                                  validationErrors={touched.location && errors.location || ""}
                                  label={values.type == "TSFR" ? "from location" : "location"}
                                  values={locationsLookup} value={values.location} name="location"
                                  onChange={onLocationChange}/>
                    </CCol>

                    {values.type == "TSFR" &&
                        <CCol md={3}>
                            <SWSelect onBlur={handleBlur} validationErrors={touched.location && errors.location || ""}
                                      label={"to location"}
                                      values={locationsLookup} value={values.toLocation} name="toLocation"
                                      onChange={onToLocationChange}/>
                        </CCol>
                    }
                    <CCol md={2}>
                        <SWSelect onBlur={handleBlur} validationErrors={touched.status && errors.status || ""}
                                  label={values.type == "CHST" ? "Old Status" : "Status"}
                                  value={values.status?.toString()}
                                  values={skuStatus} name="status"
                                  onChange={val => setFieldValue("status", val)}/>
                    </CCol>
                    <CCol md={1}>
                        <SWInputNumber onBlur={handleBlur} validationErrors={touched.quantity && errors.quantity || ""}
                                       label={"Quantity"} name={"quantity"} value={values.quantity}
                                       onChangeFormik={handleChange}/>

                    </CCol>

                    <CCol>
                        {!posted && selectedSku && !(selectedSku.allocationStrategy == AllocationStrategy.BatchNumber || selectedSku.allocationStrategy == AllocationStrategy.ExpiryDate) &&
                            <CButton
                                disabled={!values.sku || !values.quantity || !values.location || (values.type == "TSFR" && !values.toLocation) || (values.type == "TSFR" && values.toLocation == values.location) || (values.type == "CHST" && !values.newStatus) || (values.type == "CHST" && values.newStatus == values.status)}
                                onClick={onAddUpdate}
                                variant="outline" color="primary" className="small"
                                style={{marginTop: "28px"}}>
                                Update
                            </CButton>
                        }
                    </CCol>
                </CRow>
                {selectedSku && (selectedSku.allocationStrategy == AllocationStrategy.BatchNumber || selectedSku.allocationStrategy == AllocationStrategy.ExpiryDate) &&
                    <CRow>
                        <CCol md={2}>
                            <SWInput onBlur={handleBlur}
                                     validationErrors={touched.batchNumber && errors.batchNumber || ""}
                                     label={"Batch Number"} name={"batchNumber"} value={values.batchNumber}
                                     onChangeFormik={handleChange}/>
                        </CCol>
                        <CCol md={2}>
                            <SWInput onBlur={handleBlur}
                                     validationErrors={touched.itemNumber && errors.itemNumber || ""}
                                     label={"Batch Barcode"} name={"itemNumber"} value={values.itemNumber}
                                     onChangeFormik={handleChange}/>
                        </CCol>
                        {selectedSku && selectedSku.allocationStrategy == AllocationStrategy.ExpiryDate &&
                            <CCol md={2}>
                                <SWDateAndTimePicker onBlur={handleBlur}
                                                     validationErrors={touched.expiryDate && errors.expiryDate || ""}
                                                     label={"Expiry Date"} name={"expiryDate"} value={values.expiryDate}
                                                     handleOnChange={(e) => setFieldValue("expiryDate", e)}/>
                            </CCol>

                        }
                        <CCol>

                            <CButton disabled={!values.sku || !values.quantity || !values.location}
                                     onClick={onAddUpdate}
                                     variant="outline" color="primary" className="small"
                                     style={{marginTop: "28px"}}>
                                Update
                            </CButton>

                        </CCol>
                    </CRow>

                }
            </>
        }
        <CTabs>
            <CNav className={"small"} variant="tabs">
                <CNavLink>Details</CNavLink>
                <CNavLink disabled={Boolean(!id)}>Attachments</CNavLink>
            </CNav>
            <CTabContent className={"small"}>
                <CTabPane>
                    {selectedSkuQuantities && selectedSkuQuantities.length > 0 &&
                        <div className="position-relative table-responsive">
                            <table className="table table-borderless table-sm">
                                <thead>
                                    <tr>
                                        {width > 500 &&
                                            <th className="">
                                                <div className="d-inline">Quantity</div>
                                            </th>
                                        }
                                        <th className="">
                                            <div className="d-inline">Available</div>
                                        </th>
                                        <th className="">
                                            <div className="d-inline">Location</div>
                                        </th>
                                        <th className="">
                                            <div className="d-inline">Code</div>
                                        </th>
                                        <th className="">
                                            <div className="d-inline">sku</div>
                                        </th>
                                        <th className="">
                                            <div className="d-inline">Status</div>
                                        </th>
                                        <th className="">
                                            <div className="d-inline">Batch Number</div>
                                        </th>
                                        <th className="">
                                            <div className="d-inline">Batch Barcode</div>
                                        </th>
                                        <th className="">
                                            <div className="d-inline">Expiry Date</div>
                                        </th>
                                        <th className="">
                                            <div className="d-inline">Current</div>
                                        </th>
                                        <th className="">
                                            <div className="d-inline" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedSkuQuantities.filter(s => {
                                        let match = true;

                                        if (values.status && values.status != s.status)
                                            match = false;
                                        if (values.location && values.location != s.location)
                                            match = false;
                                        if (values.batchNumber && values.batchNumber != s.batchNumber)
                                            match = false;
                                        if (values.itemNumber && values.itemNumber != s.itemNumber)
                                            match = false;

                                        if (values.expiryDate && !dateAreEqual(values.expiryDate, s.expiryDate))
                                            match = false;

                                        return match;
                                    }).map((sq, index) => <tr key={index}>
                                        {width > 500 &&
                                            <td style={{ minWidth: "40px" }}>
                                                <CInputGroup>
                                                    <SWInputNumber style={{ width: "90px" }} name={'quantity'} value={sq.quantityChange}
                                                    onChange={val => quantityHelperQuantityChanged(index, val)} />
                                                    {values.type == "CHST" &&
                                                        <SWSelect style={{ width: "120px" }} values={filteredStatus(sq.status.toString())}
                                                            value={sq.newStatus?.toString()}
                                                            onChange={val => quantityHelperStatusChanged(index, Number(val))} />}
                                                    {values.type == "TSFR" &&
                                                        <SWSelect style={{ width: "120px" }} values={locationsLookup}
                                                            value={sq.toLocation}
                                                            onChange={val => quantityHelperLocationChanged(index, val)} />}
                                                    <CButton size={"sm"} style={{ height: "28px" }}
                                                        disabled={!sq.quantityChange || (values.type == "TSFR" && !sq.toLocation) || (values.type == "CHST" && sq.newStatus == null)}
                                                        onClick={() => addFromHelper(index)}
                                                        variant="outline" color="primary"
                                                    >
                                                        Update
                                                    </CButton>

                                                </CInputGroup>
                                            </td>
                                        }

                                        <td>{sq.quantityAvailable}</td>
                                        <td>{sq.location}</td>
                                        <td>{selectedSku?.code}</td>
                                        <td>{selectedSku?.name}</td>
                                        <td>{skuStatus[sq.status]}</td>
                                        <td>{sq.batchNumber}</td>
                                        <td>{sq.itemNumber}</td>
                                        <td>{formatDate(sq.expiryDate)}</td>

                                        <td>{sq.quantity}</td>


                                    </tr>)}
                                </tbody>
                            </table>
                        </div>

                    }
                    {posted &&
                        <CDataTable
                            scopedSlots={postedScopedSlots}
                            fields={[
                                { key: "quantity", label: "Quantity" },
                                { key: "quantityAvailable", label: "Available" },
                                { key: "skuCode", label: "Code" },
                                { key: "skuName", label: "sku" },
                                { key: "skuStatus", label: "Status" },
                                { key: "batchNumber", label: "Batch Number" },
                                { key: "itemNumber", label: "Batch Barcode" },
                                { key: "expiryDate", label: "Expiry Date" },
                                { key: "location", label: "Location" },

                            ]}
                            items={values.details}

                        />
                    }
                    {!posted &&
                        <CDataTable
                            scopedSlots={postedScopedSlots}
                            fields={[
                                {
                                    key: "actions",
                                    label: values.type == "TSFR" && "Quantity/New Location" || values.type == "CHST" && "Quantity/New Status" || "Quantity"
                                },
                                { key: "skuCode", label: "Code" },
                                { key: "skuName", label: "sku" },
                                { key: "skuStatus", label: "Status" },
                                { key: "batchNumber", label: "Batch Number" },
                                { key: "itemNumber", label: "Batch Barcode" },
                                { key: "expiryDate", label: "Expiry Date" },
                                { key: "location", label: "Location" },
                                { key: "current", label: 'Current' },
                                { key: "available", label: "Available" },

                                { key: "remove", label: "" }
                            ]}
                            items={values.details}

                        />
                    }

                    <br />
                    <br />
                    {!posted && (values.details?.length != 0 ?? false) &&
                        <CRow>
                            <CCol className="text-left">

                                <CButton className="mr-2" style={{ fontSize: "10px" }} color={"danger"} variant={"outline"}
                                    onClick={() => setIsWarning(true)}>Clear</CButton>
                            </CCol>
                            <CCol className="text-right">
                                <CButtonGroup style={{ fontSize: "10px" }}>
                                    {values.type == "RECV" || values.type == "CONS" &&
                                        <ActionButton
                                            extraClass={"mr-2"}
                                            text={"Save"}
                                            onClick={handleSubmit}
                                        />
                                    }

                                    <ActionButton
                                        extraClass={"mr-2"}
                                        text={"Post"}
                                        disabled={isPosting}
                                        onClick={handlePost}
                                    />
                                </CButtonGroup>
                            </CCol>
                        </CRow>}

                    {!reversed && !reversal && posted &&
                        <CCol>
                            <CButton size={"sm"} color={"danger"} variant={"outline"}
                                onClick={() => setIsReverseModalShowing(true)}>
                                Reverse
                            </CButton></CCol>
                    }
                    {isWarning &&
                        <ConformModal
                            onClose={() => setIsWarning(false)}
                            onSubmit={handleClear}
                            title={"Clear all items"}
                            body={"Are you sure you want to clear all items ?"}
                        />}
                    {isReverseModalShowing &&
                        <ConformModal
                            onClose={() => setIsReverseModalShowing(false)}
                            onSubmit={handleReverse}
                            title={"Reverse Transaction"}
                            body={"Are you sure you want to reverse this transaction ?"}
                        />}


                    <CModal size={"xl"} style={{ padding: '10px' }} show={showSkuSelector} onClose={() => setShowSkuSelector(false)}>

                        <CDataTable clickableRows fields={[
                            { key: "links", label: "" },
                            { key: "code", label: "Code" },
                            { key: "name", label: "Name" },
                            { key: "accountName", label: "Account" },
                            { key: "warehouseName", label: "Warehouse" },
                            { key: "quantity", label: "Current" },
                            { key: "quantityAvailable", label: "Available" },]}
                            scopedSlots={{
                                links: (item: ShipmentSearch) => {
                                    return <td>
                                        <CLink className="card-header-action">
                                            <FontAwesomeIcon icon={faEye} />

                                        </CLink>

                                    </td>;
                                }
                            }}
                            items={skuLookupResult} onRowClick={(r: SkuLookupResult) => onLookupSelected(r)} />

                    </CModal>

                </CTabPane>
                <CTabPane>
                    <CCol>
                        <CRow className={" w-20 mt-3 px-2"}>
                            {!fileLoading ? <SWInput type={"file"} id={"input-file"}
                                onChangeFile={(f) => handleFileImport(f)} />
                                : <div
                                    className={"rounded mb-3 h-100 m-0 py-1 px-2 d-flex align-items-center justify-content-center border-primary"}>Please
                                    wait...</div>}
                            <CameraIcon
                                style={{
                                    marginLeft: 9
                                }}
                                tabIndex={-1}
                                onClick={() => setCaptureModal(true)}
                                tooltipText={'Capture'} />
                        </CRow>
                    </CCol>
                    <CDataTable fields={[
                        { key: "fileName", label: "File Name" },
                        { key: "type", label: "Type" },
                        { key: "download", label: "Download" },
                        { key: "createdBy", label: "Created By" },
                        { key: "createdOn", label: "Created On" },
                        { key: "action", label: "Actions" }
                    ]}
                        scopedSlots={transactionAttachmentsScopedSlots}
                        items={transactionAttachments} />

                </CTabPane>
            </CTabContent>
        </CTabs>
        {captureModal &&
            <CaptureImageModal
            children={
                <CRow>
                    <CCol md={5}>
                        <SWSelect label={"Type"} value={selectedAttachmentType} values={transactionAttachmentsTypesLookup}
                            onChange={(e) => setSelectedAttachmentType(e!)} />
                    </CCol>
                </CRow>
            }
                transactionUploadData={{ inventoryTransactionCode: id }}
                onClose={() => {
                    setCaptureModal(false)
                    setSelectedAttachmentType('')
                }}
                isSaveDisabled={!selectedAttachmentType}
                onSave={(e) => {
                    handleAddBulkTransactionAttachments(e)
                }}
            />}
        {Boolean(transactionAttachmentConfirmDelete?.id) &&
            <ConformModal
                onClose={() => setTransactionAttachmentConfirmDelete(null)}
                onSubmit={()=>handleDeleteTransactionAttachments(transactionAttachmentConfirmDelete?.id!)}
                title={"Delete transaction attachment"}
                body={`Are you sure you want to delete this transaction (${transactionAttachmentConfirmDelete?.name}) ?`}
            />}
            {transactionAttachmentTypesModal?.isOpen &&
             <TransactionAttachmentTypesModal
                onClose={()=>setTransactionAttachmentTypesModal(null)}
                transactionAttachmentsTypesLookup={transactionAttachmentsTypesLookup}
                attachmentType={transactionAttachmentTypesModal?.toBeUpdatedData?.type}
                fileName={transactionAttachmentTypesModal?.toBeUpdatedData?.fileName ?? transactionAttachmentTypesModal.fileData?.name}
                onSave={(values:{ type: string, fileName: string })=>{
                    const {type,fileName} = values;
                    const {fileData,toBeUpdatedData} = transactionAttachmentTypesModal
                    setTransactionAttachmentTypesModal(null)
                    transactionAttachmentTypesModal?.type == "Add" ?  addTransactionAttachment(type,fileName,fileData!) : handleUpdateTransactionAttachments(toBeUpdatedData?.id!,type,fileName)}}
             />
            }
    </CCard>
}

export default CreateStock;
