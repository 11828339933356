import React, {useEffect, useState} from "react";
import {CButton, CButtonGroup, CCol, CContainer, CRow} from "@coreui/react";
import ShipperAndConsigneeDetails from "./components/shipperAndConsigneeDetails";
import {FormikProps} from "formik";
import {
    CreateShipment,
    StandardShipmentItem,
    createShipmentInitialValues,
    CreateShipmentStandardShipmentPiece
} from "../../../models/shipment";
import ParcelDetails from "./components/parcelDetails";
import ItemDetails from "./components/itemDetails";
import Details from './components/details';
import ShipmentDetails from "./components/shipmentDetails";
import ActionButton from "../../SharedComponents/ActionButton";
import OrderItems from "./components/orderItems";
import {useLocation} from "react-router";
import CustomsDetails from "./components/customsDetails";
import ShipClient from "../../../clients/shipClient";
import {AddressBook} from "../../../models/addressBook";
import AddressBookModal from "./components/addressBookModal";
import WarehousesClient from "../../../clients/warehousesClient";
import {useDispatch} from "react-redux";
import {ShowNotification} from "../../../store/actions/auth";
import ConfirmModal from "../../SharedComponents/ConfirmModal";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faForward} from '@fortawesome/free-solid-svg-icons';
import LookupsClient from "../../../clients/lookupsClient";
import CaptureImageModal from "../../SharedComponents/CaptureImageModal";
import QuickPricingModal from "./components/quickPricingModal";
import {SetIsLoading} from "../../../store/actions/ui";

interface IProps {
    isCustom?: boolean
    formik: FormikProps<CreateShipment>;
}

const CreateShipmentComponent = ({isCustom, formik}: IProps) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const state = location.state as { duplicate: boolean } | undefined;
    const isDuplicate = state && state.duplicate;
    const shipClient = new ShipClient();
    const warehouseClient = new WarehousesClient();
    const lookups = new LookupsClient()
    const [countryLookups, setCountryLookups] = useState<{ [k: string]: string }>({})
    const [addressBook, setAddressBook] = useState<AddressBook[]>();
    const [isShipperCardOpen, setIsShipperCardOpen] = useState<boolean>(true);
    const [isConsigneeCardOpen, setIsConsigneeCardOpen] = useState<boolean>(true);
    const [clearAllConfirmationModal, setClearAllConfirmationModal] = useState<boolean>(false);
    const [saveDefaultConfirmationModal, setSaveDefaultConfirmationModal] = useState<{
        isShown: boolean,
        type: "shipper" | "consignee"
    } | null>(null)
    const [removeDefaultConfirmationModal, setRemoveDefaultConfirmationModal] = useState<{
        isShown: boolean,
        type: "shipper" | "consignee"
    } | null>(null)
    const [saveInAddressBookConfirmationModal, setSaveInAddressBookConfirmationModal] = useState<{
        isShown: boolean,
        data: AddressBook
    } | null>(null)
    const [addressBookModal, setAddressBookModal] = useState<{
        type: "shipper" | "consignee",
        isShown: boolean
    } | null>(null);
    const [captureModal, setCaptureModal] = useState<boolean>(false);
    const [costQuickPricing, setCostQuickPricing] = useState<boolean>(false)
    const [sellingQuickPricing, setSellingQuickPricing] = useState<boolean>(false)
    const [ignoreDefaults, setIgnoreDefaults] = useState<boolean>(isDuplicate ?? false);
    const [showFields, setShowFields] = useState<{
        isShown: boolean,
        type: "shipper" | "items" | "orderItems" | "consignee" | "pieces" | "shipment" | "customs"
    }[]>([
        {isShown: true, type: "shipper"},
        {isShown: true, type: "consignee"},
        {isShown: true, type: "shipment"},
        {isShown: true, type: "pieces"},
        {isShown: true, type: "orderItems"},
        {isShown: true, type: "items"},
        {isShown: true, type: "customs"},
    ]);
    useEffect(() => {
        getCountries()
    }, []);
    useEffect(() => {
        searchAddressBook();
        if (!ignoreDefaults) {
            getDefault();
        }
        skuSearch(); // to check if the account has stock or not
    }, [formik?.values?.account, ignoreDefaults]);

    useEffect(() => {
        // by default >> isDocument true
        // items from stock >> isDocument false
        const {itemsFromStock, items} = formik?.values;
        if ((itemsFromStock?.length && itemsFromStock?.length > 0)) {
            formik?.setFieldValue("isDocument", false)
        }
    }, [formik?.values?.itemsFromStock]);

    const getCountries = async () => {
        let countries = await lookups.getCountries()
        setCountryLookups(countries)
    }
    const skuSearch = async () => {
        if (!!formik?.values?.account) {
            dispatch(SetIsLoading(true));
            const res = await warehouseClient.SearchSku({
                accountNumber: formik?.values?.account,
                limit: 1,
            })
            const skusLength = res?.data?.matches?.length
            formik.setFieldValue("orderFulfillment", skusLength > 0 ? true : false)
        } else {
            formik.setFieldValue("orderFulfillment", false)
        }

    }
    const searchAddressBook = async () => {
        if (!!formik?.values?.account) {
            dispatch(SetIsLoading(true));
            let data = await shipClient.SearchAddressBook({account: formik?.values?.account!, lookup: false})
            setAddressBook(data)
        } else {
            setAddressBook([])
        }
    }
    const getDefault = async () => {
        const {values, setFieldValue, setValues} = formik;
        const {account} = values;
        if (!!account) {
            dispatch(SetIsLoading(true));
            let data = await shipClient.GetDefault({account});
            const defaultShipper = !!data?.Shipper;
            const defaultConsignee = !!data?.Consignee;
            setFieldValue('shipper', defaultShipper ? {
                ...data?.Shipper,
                default: true
            } : createShipmentInitialValues()?.shipper);
            setIsShipperCardOpen(!defaultShipper);
            setFieldValue('consignee', defaultConsignee ? {
                ...data?.Consignee,
                default: true
            } : createShipmentInitialValues()?.consignee);
            setIsConsigneeCardOpen(!defaultConsignee);
        } else {
            setValues({
                ...values,
                consignee: {...createShipmentInitialValues()?.consignee},
                shipper: {...createShipmentInitialValues()?.shipper}
            });
        }
    };

    const handleShowField = (index: number, isShown: boolean) => {
        const newState = {...showFields};
        newState[index] = {...newState[index], isShown: isShown};
        setShowFields(newState);
    }
    const handleAddPieceAndItem = (newItem: StandardShipmentItem | CreateShipmentStandardShipmentPiece, type: "pieces" | "items" | "itemsFromStock") => {
        formik.setFieldValue(type, [...formik.values[type] || [], newItem]);
    };
    const handleEditPieceAndItem = (index: number, key: string, newValue: string | number, type: "pieces" | "items" | "itemsFromStock") => {
        formik.setFieldValue(`${type}[${index}].${key}`, newValue);
    };
    const handleDeletePieceAndItem = (index: number, type: "pieces" | "items" | "itemsFromStock") => {
        const updatedValues = [...formik.values[type] || []];
        updatedValues?.splice(index, 1);
        formik.setFieldValue(type, updatedValues);
    };
    const handleDeleteAddressBook = async (e: AddressBook) => {
        dispatch(SetIsLoading(true));
        await shipClient.DeleteAddressBook(e?.id?.toString()!)
        dispatch(ShowNotification("Success", "Deleted Successfully!", false));
        searchAddressBook()
    };
    const handleUpdateAddressBook = async (e: AddressBook) => {
        dispatch(SetIsLoading(true));
        await shipClient.UpdateAddressBook(e)
        dispatch(ShowNotification("Success", "Updated Successfully!", false));
        searchAddressBook()
    };
    const handleAddAddressBook = async (e: AddressBook) => {
        dispatch(SetIsLoading(true));
        const res = await shipClient.AddAddressBook(e)
        if (res.succeeded) {
            dispatch(ShowNotification("Success", "Saved successfully!"))
        }
        searchAddressBook()
    };

    const handleSaveDefault = async (type: "shipper" | "consignee") => {
        dispatch(SetIsLoading(true));
        const {values} = formik;
        const {account, shipper, consignee} = values;
        const defaultAddressFor = type === "shipper" ? 0 : 1;
        const address = type === "shipper" ? shipper : consignee;
        const res = await shipClient.SetDefault(account!, {defaultAddressFor, address});
        if (res?.location) {
            dispatch(ShowNotification("Success", "Saved successfully!"));
            type === "shipper" ? setIsShipperCardOpen(false) : setIsConsigneeCardOpen(false);
        }
    };

    const handleRemoveDefault = async (type: "shipper" | "consignee") => {
        dispatch(SetIsLoading(true));
        const {values} = formik;
        const {account} = values;
        const defaultAddressFor = type === "shipper" ? 0 : 1;
        const res = await shipClient.RemoveDefault({account: account!, defaultAddressFor});
        if (res) {
            dispatch(ShowNotification("Success", "Removed successfully!"));
        }
    };

    const handleClearAll = () => {
        const {setValues, values} = formik;
        const {account, shipper, consignee} = values;
        setValues({
            ...createShipmentInitialValues(),
            account,
            shipper: shipper?.default ? shipper : {...createShipmentInitialValues()?.shipper},
            consignee: consignee?.default ? consignee : {...createShipmentInitialValues()?.consignee},
            orderFulfillment: formik?.values?.orderFulfillment,
        });
    };

    return (
        <CContainer className="bg-white pb-1 text-primary small">
            <div className="mt-1"></div>
            <CRow>
                <Details
                    setIgnoreDefaults={(e) => setIgnoreDefaults(e)}
                    formik={formik}
                    handleCapture={() => setCaptureModal(true)}
                    handleClearAll={() => setClearAllConfirmationModal(true)}/>
            </CRow>
            <CRow>
                <CCol className={"mb-1"} md={6} sm={12}>
                    <ShipperAndConsigneeDetails
                        countryLookups={countryLookups}
                        type={"shipper"}
                        formik={formik}
                        showFields={isShipperCardOpen}
                        setShowFields={(e: boolean) => setIsShipperCardOpen(e)}
                        setAddressBook={() => setAddressBookModal({isShown: true, type: "shipper"})}
                        saveInAddressBook={() => setSaveInAddressBookConfirmationModal({
                            isShown: true,
                            data: {...formik?.values?.shipper, account: formik?.values?.account}
                        })}
                        saveAsDefault={() => setSaveDefaultConfirmationModal({isShown: true, type: "shipper"})}
                        removeDefault={() => setRemoveDefaultConfirmationModal({isShown: true, type: "shipper"})}
                    />
                </CCol>
                <CCol md={6} sm={12}>
                    <ShipperAndConsigneeDetails
                        countryLookups={countryLookups}
                        type={"consignee"}
                        formik={formik}
                        showFields={isConsigneeCardOpen}
                        setShowFields={(e: boolean) => setIsConsigneeCardOpen(e)}
                        setAddressBook={() => setAddressBookModal({isShown: true, type: "consignee"})}
                        saveInAddressBook={() => setSaveInAddressBookConfirmationModal({
                            isShown: true,
                            data: {...formik?.values?.consignee, account: formik?.values?.account}
                        })}
                        saveAsDefault={() => setSaveDefaultConfirmationModal({isShown: true, type: "consignee"})}
                        removeDefault={() => setRemoveDefaultConfirmationModal({isShown: true, type: "consignee"})}
                    />
                </CCol>
                <CCol md={6} sm={12}>
                    <ShipmentDetails formik={formik}
                                     showFields={showFields?.[2]?.isShown}
                                     setShowFields={(e: boolean) => handleShowField(2, e)}
                    />
                    {isCustom &&
                        <CustomsDetails formik={formik}
                                        showFields={showFields?.[6]?.isShown}
                                        setShowFields={(e: boolean) => handleShowField(6, e)}
                        />}
                </CCol>
                <CCol md={6} sm={12}>
                    <ParcelDetails
                        formik={formik}
                        showFields={showFields?.[3]?.isShown}
                        setShowFields={(e: boolean) => handleShowField(3, e)}
                        addPiece={(e: CreateShipmentStandardShipmentPiece) => handleAddPieceAndItem(e, "pieces")}
                        pieces={formik.values.pieces || []}
                        handleDeletePiece={(index: number) => handleDeletePieceAndItem(index, "pieces")}
                        handleEditPiece={(index: number, key: string, newValue: string | number) => handleEditPieceAndItem(index, key, newValue, "pieces")}
                    />
                    {formik?.values?.orderFulfillment &&
                        <OrderItems
                            showFields={showFields?.[4]?.isShown}
                            setShowFields={(e: boolean) => handleShowField(4, e)}
                            formik={formik}
                            account={formik.values?.account}
                            addItem={(e: StandardShipmentItem) => handleAddPieceAndItem(e, "itemsFromStock")}
                            items={formik.values.itemsFromStock || []}
                            handleDeleteItem={(index: number) => handleDeletePieceAndItem(index, "itemsFromStock")}
                            handleEditItem={(index: number, key: string, newValue: string | number) => handleEditPieceAndItem(index, key, newValue, "itemsFromStock")}
                        />}
                    <ItemDetails
                        countryLookups={countryLookups}
                        formik={formik}
                        showFields={showFields?.[5]?.isShown}
                        setShowFields={(e: boolean) => handleShowField(5, e)}
                        addItem={(e: StandardShipmentItem) => handleAddPieceAndItem(e, "items")}
                        items={formik.values.items || []}
                        handleDeleteItem={(index: number) => handleDeletePieceAndItem(index, "items")}
                        handleEditItem={(index: number, key: string, newValue: string | number) => handleEditPieceAndItem(index, key, newValue, "items")}
                    />
                </CCol>
            </CRow>
            <CCol className="text-right mt-2">
                <CButtonGroup className="mb-0 mr-2">
                    <CButton
                        variant="outline"
                        size="sm"
                        color="primary"
                        onClick={() => setCostQuickPricing(true)}
                        style={{fontSize: "10px"}}
                    >
                        Agents Costs
                    </CButton>
                </CButtonGroup>
                <CButtonGroup className="mb-0 mr-2">
                    <CButton
                        variant="outline"
                        size="sm"
                        color="primary"
                        onClick={() => setSellingQuickPricing(true)}
                        style={{fontSize: "10px"}}
                    >
                        Customers Selling Rates
                    </CButton>
                </CButtonGroup>
                <CButtonGroup className="mb-0 ">
                    <ActionButton
                        icon={
                            <FontAwesomeIcon icon={faForward}
                                             style={{cursor: "pointer", alignItems: "center"}}
                            />
                        }
                        text={"Next"}
                        onClick={() => {
                            formik.submitForm()
                        }}
                    />
                </CButtonGroup>
            </CCol>
            {addressBookModal?.isShown &&
                <AddressBookModal
                    countryLookups={countryLookups}
                    account={formik.values?.account}
                    onClose={() => setAddressBookModal(null)}
                    data={addressBook}
                    onSave={(e: AddressBook) => {
                        formik?.setFieldValue(addressBookModal?.type, {
                            companyName: e?.companyName ?? "",
                            notes: e?.notes ?? "",
                            name: e?.name ?? "",
                            email: e?.email ?? "",
                            phone: e?.phone ?? "",
                            country: e?.country ?? "",
                            postCode: e?.postCode ?? "",
                            city: e?.city ?? "",
                            addressLine1: e?.addressLine1 ?? "",
                            addressLine2: e?.addressLine2 ?? "",
                            eori: e?.eori ?? ""

                        })
                        setAddressBookModal(null)
                    }}
                    handleDeleteAddressBook={(e: AddressBook) => handleDeleteAddressBook(e)}
                    handleUpdateAddressBook={(e: AddressBook) => handleUpdateAddressBook(e)}
                />
            }

            {saveDefaultConfirmationModal?.isShown &&
                <ConfirmModal
                    onClose={() => setSaveDefaultConfirmationModal(null)}
                    title={"Save as default"}
                    body={`Are you sure you want to set this as the default?`}
                    onSubmit={() => {
                        handleSaveDefault(saveDefaultConfirmationModal?.type)
                        setSaveDefaultConfirmationModal(null)
                    }}
                />}

            {removeDefaultConfirmationModal?.isShown &&
                <ConfirmModal
                    onClose={() => setRemoveDefaultConfirmationModal(null)}
                    title={"Remove default"}
                    body={`Are you sure you want to remove this as the default?`}
                    onSubmit={() => {
                        handleRemoveDefault(removeDefaultConfirmationModal?.type)
                        setRemoveDefaultConfirmationModal(null)
                    }}
                />}

            {saveInAddressBookConfirmationModal?.isShown &&
                <ConfirmModal
                    onClose={() => setSaveInAddressBookConfirmationModal(null)}
                    title={"Save as default"}
                    body={"Are you sure you want to save this in address book?"}
                    onSubmit={() => {
                        handleAddAddressBook(saveInAddressBookConfirmationModal?.data)
                        setSaveInAddressBookConfirmationModal(null)
                    }}
                />}
            {clearAllConfirmationModal &&
                <ConfirmModal
                    onClose={() => setClearAllConfirmationModal(false)}
                    title={"Clear all data"}
                    body={"Are you sure you want to clear all data?"}
                    onSubmit={() => {
                        handleClearAll()
                        setClearAllConfirmationModal(false)
                    }}
                />
            }
            {captureModal &&
                <CaptureImageModal
                    onClose={() => {
                        setCaptureModal(false)
                    }}
                    onSave={(e) => {
                        formik?.setFieldValue("attachments", [...formik?.values?.attachments || [], ...e?.map((attachment) => ({
                            fileName: attachment?.name,
                            location: attachment?.location,
                            type: "Shipment Photo"
                        }))]);
                    }}
                />}

            {costQuickPricing &&
                <QuickPricingModal
                    title={"Agents Costs"}
                    isCost
                    data={formik?.values}
                    onClose={() => setCostQuickPricing(false)}/>
            }

            {sellingQuickPricing &&
                <QuickPricingModal
                    title={"Customers Selling Rates"}
                    isSelling
                    data={formik?.values}
                    onClose={() => setSellingQuickPricing(false)}/>
            }


        </CContainer>
    );
};

export default CreateShipmentComponent;

