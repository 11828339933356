import CreateContainer from "./components/ViewComponents/Containers/CreateContainer";
import SearchContainers from "./components/ViewComponents/Containers/SearchContainers";
import ViewContainer from "./components/ViewComponents/Containers/ViewContainer";
import SearchShipments from "./components/ViewComponents/Shipments/SearchShipments";
import SearchQuotations from "./components/ViewComponents/Quotations/SearchQuotations";
import CreateQuotation from "./components/ViewComponents/Quotations/CreateQuotation";
import ViewQuotation from "./components/ViewComponents/Quotations/ViewQuotation";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import React from "react";
import ScanShipments from "./components/ViewComponents/Shipments/ScanShipments";
import {Redirect} from "react-router-dom";
import CreateContainerType from "./components/ViewComponents/ContainerTypes/CreateContainerType";
import SearchContainerTypes from "./components/ViewComponents/ContainerTypes/SearchContainerTypes";
import ViewContainerType from "./components/ViewComponents/ContainerTypes/ViewContainerType";
import TrackingEvents from "./components/ViewComponents/Tracking/Events";
import ViewShipmentDetails from "./components/ViewComponents/Shipments/ViewShipmentDetails";
import CreateImportShipment from "./components/ViewComponents/ShipmentsImport/CreateImportShipment";
import ViewImport from "./components/ViewComponents/ShipmentsImport/ViewImport";
import SearchImport from "./components/ViewComponents/ShipmentsImport/SearchImport";
import SearchSkus from "./components/ViewComponents/Warehouses/SKU/SearchSKUs";
import SearchStock from "./components/ViewComponents/Warehouses/Stock/SearchStock";
import CreateStock from "./components/ViewComponents/Warehouses/Stock/CreateStock";
import WarehouseManagement from "./components/ViewComponents/Warehouses/Warehouse/WarehouseManagement";
import CreateUpdateSku from "./components/ViewComponents/Warehouses/SKU/CreateUpdateSku";
import SearchMachineLogs from "./components/ViewComponents/SortingMachine/SearchMachineLogs";
import CODManagement from "./components/ViewComponents/Accounting/CODManagement";
import MonthReporting from "./components/ViewComponents/Accounting/MonthReporting";
import CreateSortingRule from "./components/ViewComponents/SortingRules/CreateSortingRule";
import SearchSortingRules from "./components/ViewComponents/SortingRules/SearchSortingRules";
import ViewSortingRule from "./components/ViewComponents/SortingRules/ViewSortingRule";
import BillingManagement from "./components/ViewComponents/Accounting/BillingManagement";
import ViewInvoice from "./components/ViewComponents/Accounting/ViewInvoice";
import Agents from "./components/ViewComponents/Agents/Agents";
import ViewAgent from "./components/ViewComponents/Agents/ViewAgent";
import TraceMaps from "./components/ViewComponents/TraceMaps/TraceMaps";
import ViewTraceMap from "./components/ViewComponents/TraceMaps/ViewTraceMap";
import RateManagement from "./components/ViewComponents/RateMangement/RateManagement";
import CardEditor from "./components/ViewComponents/RateMangement/AddCard";
import FuelSurcharges from "./components/ViewComponents/FuelSurcharges/FuelSurcharges";
import ShipmentCreate from "./components/ViewComponents/ShipmentCreate";
import ShipmentPrint from "./components/ViewComponents/ShipmentPrint/index";
import SearchShipmentAgents from "./components/ViewComponents/ShipmentCreate/agentsRates";
import Dashboard from "./components/ViewComponents/Dashboard/Dashboard";
import ViewLogs from "./components/ViewComponents/Shipments/logs/viewLogs";
import Logs from "./components/ViewComponents/Shipments/logs/Logs";
import CreateImportSku from "./components/ViewComponents/Warehouses/SKU/skuImport/CreateImportSku";
import CreateAccount from "./components/ViewComponents/Accounting/CreateAccount";
import ViewCharges from "./components/ViewComponents/Accounting/ViewCharges";
import AccountingAccounts from "./components/ViewComponents/Accounting/modals/Account";
import ViewAccountingAccount from "./components/ViewComponents/Accounting/components/ViewAccountingAccount";
import SearchUsers from "./components/ViewComponents/Users/SearchUsers";
import ResetPassword from "./components/ViewComponents/ResetPassword/ResetPassword";
import ShipmentBox from "./components/ViewComponents/Shipments/shipmentBox";
export interface SubRouteData {
    icon?: JSX.Element;
    component?: (props: any) => JSX.Element;
    path: string;
    name: string;
    isPage: boolean;
}

export interface RouteData extends SubRouteData {
    subRoutes?: SubRouteData[];
}

const routes: RouteData[] = [
    {
        name: "Dashboard",
        path: "/",
        isPage: false,
        component: Dashboard,
    },
    {
        name: "Shipments",
        path: "/shipments",
        isPage: false,
        component: () => {
            return <Redirect to="/shipments/search"/>;
        },
        subRoutes: [
            {
                name: "Search",
                path: "/search",
                component: SearchShipments,
                icon: <CIcon content={freeSet.cilMagnifyingGlass} className="mfe-2"/>,
                isPage: true,
            },
            {
                name: "New",
                path: "/create",
                component: ShipmentCreate,
                icon: <CIcon content={freeSet.cilPencil} className="mfe-2"/>,
                isPage: true,
            },
            {
                name: "View Shipment Agents",
                path: "/view_shipment_agents",
                component: SearchShipmentAgents,
                isPage: false,
            },
            {
                name: "Origin Receipt",
                path: "/originreceipt",
                component: ScanShipments,
                icon: <CIcon content={freeSet.cilGrid} className="mfe-2"/>,
                isPage: true,
            },
            {
                name: "Box",
                path: "/box",
                component: ShipmentBox,
                icon: <CIcon content={freeSet.cilShareBoxed} className="mfe-2"/>,
                isPage: true,
            },
            {
                name: "Sorting Machine",
                path: "/sorting_machine",
                component: SearchMachineLogs,
                icon: <CIcon content={freeSet.cilTransfer} className="mfe-2"/>,
                isPage: true,
            },
            {
                name: "Bulk Traces",
                path: "/traces",
                isPage: true,
                icon: <CIcon content={freeSet.cilPlaylistAdd} className="mfe-2"/>,
                component: TrackingEvents,
            },
            {
                name: "Import",
                path: "/create_import",
                component: CreateImportShipment,
                icon: <CIcon content={freeSet.cilSpreadsheet} className="mfe-2"/>,
                isPage: true,
            },
            {
                name: "Print",
                path: "/print",
                component: ShipmentPrint,
                icon: <CIcon content={freeSet.cilPrint} className="mfe-2"/>,
                isPage: true,
            },
            {
                name: "Import History",
                path: "/import_history",
                component: SearchImport,
                isPage: false,
            },
            {
                name: "View",
                path: "/:id",
                component: ViewShipmentDetails,

                isPage: false,
            },
            {
                name: "Logs",
                path: "/:id/Logs",
                component: Logs,

                isPage: false,
            },
            {
                name: "View Import",
                path: "/import/:id",
                component: ViewImport,
                isPage: false,
            },
        ],
    },
    {
        name: "Containers",
        path: "/containers",
        component: () => {
            return <Redirect to="/containers/search"/>;
        },
        isPage: false,
        subRoutes: [
            {
                name: "Search",
                path: "/search",
                icon: <CIcon content={freeSet.cilMagnifyingGlass} className="mfe-2"/>,
                component: SearchContainers,
                isPage: true,
            },
            {
                name: "Create",
                path: "/create",
                component: CreateContainer,
                icon: <CIcon content={freeSet.cilPencil} className="mfe-2"/>,
                isPage: true,
            },
            {
                name: "View",
                path: "/:id",

                component: ViewContainer,
                isPage: false,
            },
        ],
    },
    {
        name: "Quotations",
        path: "/quotations",
        isPage: false,
        component: () => {
            return <Redirect to="/quotations/search"/>;
        },
        subRoutes: [
            {
                name: "Search",
                path: "/search",
                component: SearchQuotations,
                icon: <CIcon content={freeSet.cilMagnifyingGlass} className="mfe-2"/>,
                isPage: true,
            },
            {
                name: "Create",
                path: "/create",
                component: CreateQuotation,
                icon: <CIcon content={freeSet.cilPencil} className="mfe-2"/>,
                isPage: true,
            },
            {
                name: "View",
                path: "/:id",
                component: ViewQuotation,

                isPage: false,
            },
        ],
    },{
        name: "Warehouse",
        path: "/stock",

        isPage: false,
        subRoutes: [
            {
                name: "SKU Management",
                path: "/skus",
                component: SearchSkus,
                icon: <CIcon content={freeSet.cilTag} className="mfe-2"/>,
                isPage: true,
            },
            {
                name: "create sku",
                path: "/skus/create",
                component: CreateUpdateSku,
                isPage: false,
            },
            {
                name: "view sku",
                path: "/skus/:id",
                component: CreateUpdateSku,
                isPage: false,
            },
            {
                name: "Stock Transactions",
                path: "/transactions",
                component: SearchStock,
                icon: <CIcon content={freeSet.cilFeaturedPlaylist} className="mfe-2"/>,
                isPage: true,
            },
            {
                name: "create",
                path: "/transactions/create",
                component: CreateStock,
                isPage: false,
            },
            {
                name: "view",
                path: "/transactions/:id",
                component: CreateStock,
                isPage: false,
            },
            {
                name: "Import",
                path: "/create_import",
                component: CreateImportSku,
                icon: <CIcon content={freeSet.cilSpreadsheet} className="mfe-2"/>,
                isPage: true,
            },

            // {
            //     name: "Transaction History",
            //     path: "/history",
            //     component: TransactionHistory,
            //     icon: <CIcon content={freeSet.cilClock} className="mfe-2" />,
            //     isPage: true,
            // },


        ],
    },
    {
        name: "Accounting",
        path: "/financial",
        isPage: false,
        component: () => {
            return <Redirect to="/financial/addCOD"/>;
        },
        subRoutes: [

            {
                name: "Charges",
                path: "/view-Charges",
                component: ViewCharges,
                icon:<CIcon content={freeSet.cilMagnifyingGlass} className="mfe-2"/>,
                isPage: true,
            },


            {
                name: "COD Management",
                path: "/codmanagement",
                component: CODManagement,
                icon: <CIcon content={freeSet.cilDollar} className="mfe-2"/>,
                isPage: true,
            },
            {
                name: "End of Month Report",
                path: "/month-reports",
                component: MonthReporting,
                icon: <CIcon content={freeSet.cilBarChart} className="mfe-2"/>,
                isPage: true,
            },
            {
                name: "Invoicing",
                path: "/billing",
                component: BillingManagement,
                icon: <CIcon content={freeSet.cilMoney} className="mfe-2" />,
                isPage: true
            },
            {
                name: "view",
                path: "/billing/:id",
                component: ViewInvoice,
                isPage: false
            },
            {
                name: "Selling cards",
                path: "/sellingCards",
                component: RateManagement,
                icon: <CIcon content={freeSet.cilSpreadsheet} className="mfe-2"/>,
                isPage: true
            },
            {
                name: "Agents Rates",
                path: "/agentsRates",
                component: RateManagement,
                icon: <CIcon content={freeSet.cilSpreadsheet} className="mfe-2"/>,
                isPage: true
            },
            {
                name: "Card",
                path: "/card/:cardId/",
                component: CardEditor,
                isPage: false
            },
            {
                name: "AddCard",
                path: "/addCard/",
                component: CardEditor,
                isPage: false
            },
            {
                name: "FSC",
                path: "/fsc/",
                component: FuelSurcharges,
                icon: <CIcon content={freeSet.cilDollar} className="mfe-2"/>,
                isPage: true
            },
            {
                name: "Account",
                path: "/account",
                component: AccountingAccounts,
                icon: <CIcon content={freeSet.cilUser} className="mfe-2"/>,
                isPage: true
            },
            {
                name: "Account",
                path: "/account/view/:accountId",
                component: ViewAccountingAccount,
                isPage: false
            },

            {
                name: "Account",
                path: "/account/create",
                component: CreateAccount,
                isPage: false
            },

        ],
    },
    {
        name: "Settings",
        path: "/settings",
        component: () => {
            return <Redirect to="/settings/sortingrules/search"/>;
        },
        isPage: false,
        subRoutes: [
            {
                name: "Create Container Type",
                path: "/containertypes/create",
                component: CreateContainerType,
                isPage: false,
            },
            {
                name: "Container types",
                path: "/containertypes/search",
                component: SearchContainerTypes,
                icon: <CIcon content={freeSet.cilBriefcase} className="mfe-2"/>,
                isPage: true,
            },
            {
                name: "View",
                path: "/containertypes/:id",
                component: ViewContainerType,
                isPage: false,
            },
            {
                name: "Warehouse Management",
                path: "/warehouse",
                component: WarehouseManagement,
                icon: <CIcon content={freeSet.cilHouse} className="mfe-2"/>,
                isPage: true,
            },
            {
                name: "Create Sorting Rule",
                path: "/sortingrules/create",
                component: CreateSortingRule,
                icon: <CIcon content={freeSet.cilCompass} className="mfe-2"/>,
                isPage: false,
            },
            {
                name: "Sorting rules",
                path: "/sortingrules/search",
                component: SearchSortingRules,
                icon: <CIcon content={freeSet.cilCompass} className="mfe-2"/>,
                isPage: true,
            },
            {
                name: "View",
                path: "/sortingrules/:id",
                component: ViewSortingRule,

                isPage: false,
            },
            {
                name: "Agents",
                path: "/agents",
                component: Agents,
                icon: <CIcon content={freeSet.cilFork} className="mfe-2"/>,
                isPage: true,
            },
            {
                name: "Agent",
                path: "/agents/:id",
                component: ViewAgent,
                isPage: false,
            },
            {
                name: "TraceMaps",
                path: "/tracemaps",
                component: TraceMaps,
                icon: <CIcon content={freeSet.cilMap} className="mfe-2"/>,
                isPage: true,
            },
            {
                name: "TraceMap",
                path: "/tracemaps/:id",
                component: ViewTraceMap,
                isPage: false,
            },
            {
                name: "Users",
                path: "/users/search",
                component: SearchUsers,
                icon: <CIcon content={freeSet.cilUser} className="mfe-2"/>,
                isPage: true,
            },
            // {
            //   name: "Create",
            //   path: "/users/create",
            //   component: CreateUser,
            //   isPage: false,
            // },
            // {
            //   name: "View",
            //   path: "/users/:id",
            //   component: ViewUser,

            //   isPage: false,
            // },
        ],
    },
];

export default routes;
