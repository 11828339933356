import { CreateShipment, ShipmentSearch } from "../../models/shipment";
import { Filter, FilterGroup } from "../../models/filter";
import {
    SET_SHIPMENT_FILTER_GROUP,
    SET_SHIPMENT_IS_LOADING,
    SET_SHIPMENT_RESULT,
    TOGGLE_SHIPMENT_VIEW,
    SET_SHIPMENT_REPRINT_RESULT,
    SET_SHIPMENT_REPRINT_FILTER_GROUP,
    SET_SHIPMENT_REPRINT_IS_LOADING,
    SET_CREATE_SHIPMENT_DATA
} from "../types";
import { ContainerSearch } from "../../models/container";

export const SetShipmentPage = (page: number) => {
    return {
        type: SET_SHIPMENT_FILTER_GROUP,
        payload: {
            page: page,
        },
    };
};

export const SetShipmentsIsLoading = (loading: boolean) => {
    return {
        type: SET_SHIPMENT_IS_LOADING,
        payload: {
            isLoading: loading,
        },
    };
};

export const SetShipmentsFilterGroupFilters = (filters: Filter[]) => {
    return {
        type: SET_SHIPMENT_FILTER_GROUP,
        payload: {
            page: 0,
            filters: filters,
        },
    };
};

export const SetShipmentsFilterGroup = (filterGroup: FilterGroup) => {
    return {
        type: SET_SHIPMENT_FILTER_GROUP,
        payload: filterGroup,
    };
};

export const SetShipmentResult = (
    page: number,
    count: number,
    result: ShipmentSearch[],
    missingShipments?: string[],
    error?: string
) => {
    return {
        type: SET_SHIPMENT_RESULT,
        payload: {
            count: count,
            result: result,
            error: error,
            missingShipments: missingShipments
        },
    };
};


export const ToggleShipmentView = (
    toggle?: ShipmentSearch,
) => {
    return {
        type: TOGGLE_SHIPMENT_VIEW,
        payload: toggle,
    };
};

export const setShipmentSearchLimit = (limit: number) => {
    return {
        type: SET_SHIPMENT_RESULT,
        payload: {
            limit: limit
        }
    }
}

export const SetShipmentReprintFilterGroup = (filterGroup: FilterGroup) => {
    return {
        type: SET_SHIPMENT_REPRINT_FILTER_GROUP,
        payload: filterGroup,
    };
};

export const SetShipmentReprintResult = (
    page: number,
    count: number,
    result: ContainerSearch[],
    error?: string
) => {
    return {
        type: SET_SHIPMENT_REPRINT_RESULT,
        payload: {
            count: count,
            result: result,
            error: error,
        },
    };
};

export const setShipmentReprintSearchLimit = (limit: number) => {
    return {
        type: SET_SHIPMENT_REPRINT_RESULT,
        payload: {
            limit: limit
        }
    }
}
export const SetShipmentReprintIsLoading = (loading: boolean) => {
    return {
      type: SET_SHIPMENT_REPRINT_IS_LOADING,
      payload: {
        isLoading: loading,
      },
    };
  };

  export const setCreateShipmentData = (data: CreateShipment) => {
    return {
      type: SET_CREATE_SHIPMENT_DATA,
      payload:{
        data
    }
    };
  };


