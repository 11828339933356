import {CButton, CCardBody, CCol, CRow, CSpinner} from "@coreui/react";
import React, {useEffect, useState} from "react";
import {ShipmentSearch} from "../../../models/shipment";
import ActionButton from "../../SharedComponents/ActionButton";
import AuthClient from "../../../clients/authClient";
import ShipClient from "../../../clients/shipClient";

interface IProps {
    shipment: ShipmentSearch;
    onEditClick: () => any;
    quotationNumber?: string;
    allowedUsers: { [p: string]: string }
}

const ViewAssignedTo = (props: IProps) => {
    const {shipment} = props;
    const [myName, setMyName] = useState<string>();
    const [loader, setLoader] = useState<boolean>(false);
    const authClient = new AuthClient();
    const shipClient = new ShipClient();

    useEffect(() => {
        const fetchMyName = async () => {
            setLoader(true);
            const res = await authClient.Me();
            if (res) {
                setMyName(res.name);
            }
            setLoader(false);
        };
        fetchMyName();
    }, []);

    const changeAssignmentToMe = async () => {
        const res = await shipClient.assignToMe(shipment.uid);
        if (res.succeeded) {
            window.location.reload();
        }
    };

    const removeAssignmentTo = async () => {
        const res = await shipClient.removeAssignTo(shipment.uid);
        if (res.succeeded) {
            window.location.reload();
        }
    };


    return (
        <>
            {!loader ? (
                <CCardBody>
                    <CRow>
                        <CCol md={4}>
                            <h6>
                                <strong>Assigned To:</strong>
                            </h6>
                        </CCol>
                        <CCol>
                            <CRow className="align-items-center">
                                <CCol>
                                    <h6>
                                        <CButton
                                            size="md"
                                            style={{fontSize: "15px"}}
                                            color="primary"
                                            onClick={() => {
                                                props.onEditClick();
                                            }}
                                        >
                                            {props.allowedUsers[shipment?.assignedTo] ?? "No User Assigned"}
                                        </CButton>
                                    </h6>
                                </CCol>
                            </CRow>
                        </CCol>
                    </CRow>
                </CCardBody>
            ) : (
                <CSpinner
                    className="mx-auto d-block my-5"
                    color="primary"
                    style={{width: "5em", height: "5em"}}
                />
            )}
        </>
    );
};

export default ViewAssignedTo;
