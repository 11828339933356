import {
    CForm,
    CCard,
    CCardBody,
    CFormGroup,
    CLabel,
    CInputGroup,
    CInput,
    CInputGroupAppend,
    CButton,
    CFormText,
    CCollapse,
    CCardFooter, CButtonGroup,
} from "@coreui/react";
import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppModel} from "../../../../models/app";
import {FilterType} from "../../../../models/filter";
import SWAdvancedSearch from "../../../SharedComponents/SWAdvancedSearch/SWAdvancedSearch";
import {filterGroupToQueryParams} from "../../../../utils/filterUtils";
import {useHistory, useLocation} from "react-router";

import CreateUserModal from "./CreateUserModal";
import {UserCreateRequest} from "../../../../models/user";
import AuthClient from "../../../../clients/authClient";
import {SetUserResult} from "../../../../store/actions/users";
import ChangePasswordModal from "./ChangePasswordModal";

interface Props {
    userRole: string

}

interface ChildComponentHandles {
    clearFilters: () => void;
}

const SearchForm = (props: Props) => {
    const userClient = new AuthClient()
    const loc = useLocation();
    const dispatch = useDispatch()
    const [ViewModal, setViewModal] = useState<"NONE" | "CREATE" | "CHANGEPASSWORD">("NONE");
    const hist = useHistory();
    const userEmailProvider=useSelector((state:AppModel)=>state.app.user?.emailProvider)

    const [modalLoader, setModalLoader] = useState(false)

    const filterGroup = useSelector(
        (state: AppModel) => state.user.usersSearch.filterGroup
    );
    const handleSubmitCreate = async (data: UserCreateRequest) => {
        setModalLoader(true)
        const res = await userClient.CreateUser(data)
        if (res.succeeded) {
            setViewModal("NONE")
            hist.push('/settings/users/search?page=0&limit=10')
            const result = await userClient.SearchUsers({})
            dispatch(SetUserResult(result.page!, result.count!, result.result!))
        }
        setModalLoader(false)

    }
    const handleSubmitChangePassword = async (data: {currentPassword:string,newPassword:string}) => {
        setModalLoader(true)
        const res = await userClient.ChangePassword(data)
        if (res.succeeded) {
            setViewModal("NONE")
            hist.push('/settings/users/search?page=0&limit=10')
            const result = await userClient.SearchUsers({})
            dispatch(SetUserResult(result.page!, result.count!, result.result!))
        }
        setModalLoader(false)

    }
    console.log('userEmailProvider',userEmailProvider)
    return (
        <>

            <fieldset className="b-fieldset p-2 text-primary">
                <legend className="b-legend" style={{width: "52px"}}><h5>Users</h5></legend>
                <SWAdvancedSearch
                    cancelCb={() => {
                    }}
                    className="m-0 p-0"
                    filter={(fg) => {
                        hist.push(
                            `${loc.pathname}?${filterGroupToQueryParams({
                                filters: fg,
                                page: 0,
                                limit: 20,
                            })}`
                        );
                    }}
                    filterGroup={filterGroup}
                    defaultFilters={[
                        {
                            field: "Email",
                            type: FilterType.Equals,
                            inputType: "text",
                            value: "",
                        },
                    ]}
                    availableFilters={[
                        {
                            field: "Email",
                            type: FilterType.Equals,
                            inputType: "text",
                            value: "",
                        }, {
                            field: "UserName",
                            type: FilterType.Equals,
                            inputType: "text",
                            value: "",
                        }

                    ]}
                >
                    <CButtonGroup>

                        {props.userRole.toLowerCase() === 'admin' && <CButton
                            size="sm"
                            style={{fontSize: "10px"}}
                            className="mr-2"
                            variant="outline"
                            color="primary"
                            onClick={() => setViewModal('CREATE')}
                        >
                            Create User
                        </CButton>}
                        {userEmailProvider?.toLowerCase()==='none' &&  <CButton
                            size="sm"
                            style={{fontSize: "10px"}}
                            className="mr-2"
                            variant="outline"
                            color="primary"
                            onClick={() => setViewModal('CHANGEPASSWORD')}
                        >
                            Change Password
                        </CButton>}
                    </CButtonGroup>

                </SWAdvancedSearch>

            </fieldset>
            {ViewModal === "CREATE" &&
                <CreateUserModal loader={modalLoader} onSubmit={handleSubmitCreate} onClose={() => {setViewModal('NONE')}}/>}

            {ViewModal === "CHANGEPASSWORD" &&
                <ChangePasswordModal loader={modalLoader} onSubmit={handleSubmitChangePassword} onClose={() => {setViewModal('NONE')}}/>}

        </>

    );
};

export default SearchForm;
