import React, { Fragment, useEffect, useState } from "react";
import { CContainer, CDataTable } from "@coreui/react";
import SearchSelling from "./components/SearchSelling";
import { useHistory, useLocation } from "react-router";
import SearchCosts from "./components/SearchCosts";
import RatesClient from "../../../clients/ratesClient";
import { SearchRateCardModel } from "../../../models/retes";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { SetIsLoading } from "../../../store/actions/ui";
import { ShowNotification } from "../../../store/actions/auth";
import { AppModel } from "../../../models/app";
import { formatDate } from "../../../utils/dateUtil";

const ratesClient = new RatesClient();
const RateManagement = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const isCosts = pathname.includes("agentsRates");
    const costsSearch = useSelector((state: AppModel) => state.rates?.costsSearch);
    const sellingSearch = useSelector((state: AppModel) => state.rates?.sellingSearch);
    const [data, setData] = useState<Array<SearchRateCardModel>>([]);
    const { push: nav } = useHistory();

    const fetchData = async () => {
        dispatch(SetIsLoading(true));
        let res = isCosts ? await ratesClient.SearchCosts(costsSearch) :  await ratesClient.SearchSelling(sellingSearch);
        if(res?.succeeded){
            setData(res.data);
        }else{
            dispatch(ShowNotification("Error", res?.error??"Error", true));
        }
        dispatch(SetIsLoading(false));
       
    };

    useEffect(() => {
        fetchData()
    }, [isCosts]);

    const onClickViewCard = (cardId: number) => {
        nav(`/financial/card/${cardId}?type=${isCosts ? 'costs' : 'selling'}`)
    }
    return (
        <Fragment>
            <CContainer className="bg-white p-4 text-primary small">
                {isCosts ?
                    <SearchCosts 
                    initialData={costsSearch}
                    setData={setData} />
                    :
                    <SearchSelling 
                    initialData={sellingSearch}
                    setData={setData} />
                }
                <div className={"py-4"}></div>
                <CDataTable
                    size="sm"
                    hover
                    scopedSlots={{
                        action: (e: SearchRateCardModel) => {
                            return <td className={"card-header-action"}>
                                <FontAwesomeIcon
                                    icon={faEye}
                                    onClick={() => {
                                        onClickViewCard(e.cardId)
                                    }}
                                    style={{ cursor: "pointer" }}
                                />
                            </td>
                        },
                        expiresOn: (e: SearchRateCardModel) => {
                            return <td>
                                {formatDate(e.expiresOn) ?? "-"}
                            </td>
                        },
                        cardDescription: (e: SearchRateCardModel) => {
                            return <td>
                                {Boolean(e.cardDescription) ? e.cardDescription : "-"}
                            </td>
                        }

                    }}
                    items={data||[]}
                    fields={[
                        { key: "action", label: "" , _style: {width: "2%"}},
                        { key: "cardId", label: "Card ID" },
                        { key: "cardName", label: "Card Name" },
                        { key: "cardDescription", label: "Description" },
                        { key: "expiresOn", label: "Expiry Date" },
                        { key: "service", label: "Service" },
                        ...([(isCosts ? { key: "agent", label: "Agent" } : { key: "customer", label: "Customer" })]),
                    ]}
                />
            </CContainer>
        </Fragment>
    )
}

export default RateManagement