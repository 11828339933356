import {
    CButton,
    CBadge,
    CCol,
    CButtonGroup,
    CContainer,
    CFormGroup,
    CRow,
    CInputRadio, CLabel, CLink, CTooltip
} from "@coreui/react";
import React, {useEffect, useState} from "react";
import {ContainerSearch, ContainerShipmentSearch, ContainerUpdate} from "../../../../models/container";
import ConfirmModal from "../../../SharedComponents/ConfirmModal";
import ShipClient from "../../../../clients/shipClient";
import {ShowNotification} from "../../../../store/actions/auth";
import {useDispatch} from "react-redux";
import ActionButton from "../../../SharedComponents/ActionButton";

type IntegrationFilterType = "All" | "Succeeded" | "Failed"


interface Props {
    container: ContainerSearch
    refresh: () => void
}

const ContainerIntegration = (props: Props) => {

    const dispatch = useDispatch();
    const client = new ShipClient();
    const {container, refresh} = props;

    const [integrationCode, setIntegrationCode] = useState(container.containerType.integrations[0])

    const [filterType, setFilterType] = useState<IntegrationFilterType>("All")
    const [confirmRunAll, setConfirmRunAll] = useState(false);
    const [confirmRunFailed, setConfirmRunFailed] = useState(false);
    const [confirmRun, setConfirmRun] = useState<ContainerShipmentSearch | null>(null);

    const shipments = () => {
        if (!container) return [];
        switch (filterType) {
            case "All":
                return container.shipments;
            case "Succeeded":
                return container.shipments.filter(s => s.integrations && s.integrations.some(i => i.integrationCode == integrationCode && i.success));
            case "Failed":
                const c = container.shipments.filter(s => s.integrations && s.integrations.some(i => i.integrationCode == integrationCode && !i.success));
                return c;
        }
    }
    const reRun = async () => {
        if (!confirmRun)
            return;

        const rs = await client.rerun(container, integrationCode, [confirmRun]);
        if (rs.succeeded)
            dispatch(ShowNotification("In Progress", "Integration of shipment is being processed"));
        setConfirmRun(null)
    }

    const reRunAll = async () => {

        const rs = await client.rerun(container, integrationCode, container.shipments);
        if (rs.succeeded)
            dispatch(ShowNotification("In Progress", "Integration of all shipments is being processed"));
        setConfirmRunAll(false);
    }

    const reRunFailed = async () => {
        const rs = await client.rerunFailed(container, integrationCode);
        if (rs.succeeded)
            dispatch(ShowNotification("In Progress", "Integration of all failed is being processed"));

        setConfirmRunAll(false);
    }

    const renderButton = (title: string, onPress?: () => any) => (
        <CButton
            style={{fontSize: "10px"}}
            onClick={onPress}
            variant="outline"
            color="primary"
            className="mr-2"
        >
            {title}
        </CButton>
    );

    return (
        <CContainer>
            <CRow>
                <CCol>
                    <CRow className="w-100">
                        <CCol>
                            <CFormGroup>
                                <CLabel>
                                    <CInputRadio
                                        className="ml-0 position-relative mr-2"
                                        style={{
                                            width: "1.25em",
                                            height: "1.25em",
                                            fontSize: "10px"
                                        }}
                                        radioGroup="filter"
                                        onClick={() => setFilterType("All")}
                                        label="Show All"
                                        checked={filterType == "All"}
                                    />

                                    Show All
                                </CLabel>
                            </CFormGroup>
                        </CCol>
                        <CCol>
                            <CFormGroup>
                                <CLabel>
                                    <CInputRadio
                                        className="ml-0 position-relative mr-2"
                                        style={{
                                            width: "1.25em",
                                            height: "1.25em",
                                            fontSize: "10px"
                                        }}
                                        radioGroup="filter"
                                        onClick={() => setFilterType("Failed")}

                                        label="Failed"
                                        checked={filterType == "Failed"}
                                    />

                                    Failed
                                </CLabel>
                            </CFormGroup>
                        </CCol>
                        <CCol>
                            <CFormGroup>


                                <CLabel>
                                    <CInputRadio
                                        className="ml-0 position-relative mr-2"
                                        style={{
                                            width: "1.25em",
                                            height: "1.25em",
                                            fontSize: "10px"
                                        }}
                                        radioGroup="filter"
                                        onClick={() => setFilterType("Succeeded")}

                                        label="Succeeded"
                                        checked={filterType == "Succeeded"}
                                    />

                                    Succeeded
                                </CLabel>
                            </CFormGroup>
                        </CCol>
                    </CRow>


                </CCol>

                <CCol>
                    <CButtonGroup style={{fontSize: "10px"}}>
                        {renderButton("Refresh Results", refresh)}


                        {container.shipments.some(s => s.integrations?.some(i => !i.success)) &&
                            renderButton("Run Failed", () => setConfirmRunFailed(true))
                        }
                    </CButtonGroup>
                </CCol>

            </CRow>

            <br/>
            {shipments().map((s) => {
                return (

                    <CRow className="border-bottom">
                        <CCol lg={1}>
                            <CLink to={`/shipments/${encodeURI(s.shipmentUid)}`} className="card-header-action">
                                <CTooltip content={"View Shipment"}>
                                    <span>{s.number}</span>
                                </CTooltip>
                            </CLink>
                        </CCol>

                        {s.integrations && s.integrations.some(i => i.integrationCode == integrationCode) && s.integrations?.filter(i => i.integrationCode == integrationCode).map(i =>
                            <>
                                <CCol lg={1} className={'ml-3'}>
                                    <CBadge color={i.success ? "success" : "danger"}>
                                        {i.success ? "Success" : "Fail"}
                                    </CBadge>
                                </CCol>
                                <CCol lg={2} className={"mb-1 mt-1"}>
                                    {renderButton("Run Again", () => setConfirmRun(s))}

                                </CCol>
                                {!i.success &&
                                    <CCol>
                                        {i.lastError}
                                    </CCol>

                                }
                            </>
                        )}

                    </CRow>

                );
            })}

            {confirmRun && (
                <ConfirmModal
                    onClose={() => setConfirmRun(null)}
                    title={`Are you sure you want to initiate integration for shipment ${confirmRun.number}?`}
                    body={`This action will  initiate integration for shipment ${confirmRun.number} in the background`}
                    onSubmit={reRun}
                />
            )}

            {confirmRunAll && (
                <ConfirmModal
                    onClose={() => setConfirmRun(null)}
                    title="Are you sure you want to initiate integration for all shipments"
                    body="This action will  initiate integration for all shipments in the background"
                    onSubmit={reRunAll}
                />
            )}

            {confirmRunFailed && (
                <ConfirmModal
                    onClose={() => setConfirmRun(null)}
                    title="Are you sure you want to initiate integration for all failed"
                    body="This action will  initiate integration for all failed in the background"
                    onSubmit={reRunFailed}
                />
            )}
            <CRow className={'mt-2 d-flex justify-content-end mr-3'}>
                <ActionButton text={"Run all again"} onClick={(e) => {
                    e.preventDefault();
                    setConfirmRunAll(true)
                }}/>
            </CRow>

        </CContainer>
    );
};

export default ContainerIntegration;
