import {
  CModal,
  CModalBody,
  CModalFooter,
  CButton,
  CModalHeader,
  CRow,
  CCol,
} from "@coreui/react";
import React from "react";
import {
  ShipmentSearch,
  StandardShipmentItem,
} from "../../../../models/shipment";

interface IProps {
  isShowing: boolean;
  onClose: () => any;
  onSave: () => any;
  shipment: ShipmentSearch;
}
const WarningModal = (props: IProps) => {
  const { isShowing, onClose, onSave, shipment } = props;

    let sum: number = 0;
    const total = shipment?.items?.forEach((item: StandardShipmentItem) =>(sum= sum+ (item?.value || 0)));
  

  return (
    <>
      <CModal centered={true} show={isShowing} onClose={onClose}>
        <CModalHeader>
          <h5 className="text-danger">Warning</h5>
        </CModalHeader>
        <CModalBody>
          <p className={"mb-2"}>
            Shipment Total does not equal to ( total Items Value :<> <strong className="text-danger">{sum}{" "}{shipment?.valueCurrency}</strong> </> )... 
            <><br/> </>
            <><br/> </>

            Are you sure you want to edit shipment value ???
          </p>
        </CModalBody>
        <CModalFooter>
          <CRow className="justify-content-between w-100">
            <CCol className="text-left">
              <CButton variant="outline" color="danger" onClick={onClose}>
                No
              </CButton>
            </CCol>
            <CCol className="text-right">
              <CButton variant="outline" color="primary" onClick={onSave}>
                Yes
              </CButton>
            </CCol>
          </CRow>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default WarningModal;
