import { freeSet } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CTooltip,
  CLink,
  CDataTable,
  CModal,
  CModalHeader,
  CModalBody,
  CButton,
  CCol,
  CRow,
} from "@coreui/react";
import React, { useState } from "react";
import {
  AdditionalField,
  ContainerType,
} from "../../../../models/containertype";
import { Form, Input, Submit } from "../../../SharedComponents/SWForm";
import ActionButton from "../../../SharedComponents/ActionButton";

interface Props {
  onAdd: (body: AdditionalField) => void;
  onEdit: (idx: number, body: AdditionalField) => void;
  onDelete: (idx: number) => void;
  items: AdditionalField[];
  readonly?: boolean;
}
const AddEditField = (props: Props) => {
  const { items, onAdd, onEdit, onDelete, readonly } = props;

  const [editTarget, setEditTarget] = useState<number | null>(null);
  const [createMode, setCreateMode] = useState(false);

  const types: any = {
    "0": "Text",
    "1": "Boolean",
    "20": "Integer",
    "30": "Decimal",
    "40": "DateTime",
  };

  const scopedSlots = {
    type: (e: AdditionalField) => {
      return (
        <td>
          <span>{types[e.type]}</span>
        </td>
      );
    },
    actions: (e: AdditionalField, idx: number) => {
      return (
        <td>
          <CTooltip content="Delete field">
            <CLink
              className={`mr-2 ${readonly ? "text-secondary" : "text-primary"}`}
              disabled={readonly}
              onClick={() => {
                onDelete(idx);
              }}
            >
              <CIcon content={freeSet.cilTrash} />
            </CLink>
          </CTooltip>

          <CTooltip content="Edit field">
            <CLink
              className={readonly ? "text-secondary" : "text-primary"}
              disabled={readonly}
              onClick={() => {
                setEditTarget(idx);
              }}
            >
              <CIcon content={freeSet.cilPencil} />
            </CLink>
          </CTooltip>
        </td>
      );
    },
  };

  return (
    <div className="my-3 small">
      <p className="mb-0">
        <CTooltip content="Add item">
          <CLink
            className={readonly ? "text-secondary" : "text-primary"}
            disabled={readonly}
            onClick={() => {
              setEditTarget(null);
              setCreateMode(true);
            }}
          >
            <CIcon
              className="m-2"
              style={{
                height: "1.5em",
                width: "1.5em",
              }}
              content={freeSet.cilPlus}
            />
          </CLink>
        </CTooltip>
      </p>
      <CDataTable
        scopedSlots={scopedSlots}
        fields={["name", "type", "required", "actions"]}
        items={items}
      />
      <CModal
        onClosed={() => {
          setEditTarget(null);
          setCreateMode(false);
        }}
        onClose={() => {
          setEditTarget(null);
          setCreateMode(false);
        }}
        show={createMode || editTarget != null}
        className="p-3"
      >
        <CModalHeader>{createMode ? "Add" : "Edit"} Field</CModalHeader>
        <CModalBody>
          <Form
            initialState={
              editTarget != null
                ? items[editTarget]
                : {
                    name: "",
                    type: "0",
                    required: "false",
                  }
            }
            className="p-3"
            onSubmit={(body) => {
              if (editTarget == null) {
                onAdd(body);
              } else {
                onEdit(editTarget, body);
              }
              setEditTarget(null);
              setCreateMode(false);
            }}
          >
            <Input label="Name" field="name" type="text" />

            <Input
              field="required"
              label="Is Required"
              type="typeahead"
              lookUp={{
                true: "true",
                false: "false",
              }}
            />

            <Input field="type" type="typeahead" label="Type" lookUp={types} />
            <CRow className="justify-content-between">
              <CCol>
                <CButton
                className="small"
                style={{fontSize:"10px"}}
                  onClick={() => {
                    setEditTarget(null);
                    setCreateMode(false);
                  }}
                  color="danger"
                  variant="outline"
                >
                  Cancel
                </CButton>
              </CCol>
              <CCol className="text-right">
                <CButton />
                  <ActionButton
                      extraClass={"ml-2"}
                      text={`Save ${editTarget != null ? "" : "new "} item`}
                  />
              </CCol>
            </CRow>
          </Form>
        </CModalBody>
      </CModal>
    </div>
  );
};

export default AddEditField;
