import React, { useEffect, useState } from "react";
import { CCol, CCollapse, CDataTable, CLabel, CRow, CTooltip } from "@coreui/react";
import { faArrowAltCircleUp, faArrowCircleDown, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import ShipClient from "../../../../../clients/shipClient";
import { AppModel } from "../../../../../models/app";
import { PendingGenerateWithErrors, PendingGenerateWithErrorsDetail } from "../../../../../models/shipment";
import { SetIsLoading } from "../../../../../store/actions/ui";
import ErrorsModal from "../modals/ErrorsModal";


const ShipmentsWithErrors = () => {
    const dispatch = useDispatch();
    const shipClient = new ShipClient();
    const isLoading = useSelector((state: AppModel) => state.app.isLoading);
    const [data, setData] = useState<PendingGenerateWithErrors[]>([]);
    const [errorsModal, setErrorsModal] = useState<{ isOpen: boolean, data: PendingGenerateWithErrorsDetail[] } | null>(null);
    const [showTable, setShowTable] = useState<boolean>(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        dispatch(SetIsLoading(true));
        const res = await shipClient.GetPendingWithErrors();
        setData(res)
    };

    const handleGetErrorsDetails = async (account: string) => {
        dispatch(SetIsLoading(true));
        const res = await shipClient.GetPendingWithErrorsDetails({ account });
        setErrorsModal({ isOpen: true, data: res })
    };


    const scopedSlots = {
        account: (item: PendingGenerateWithErrors) => {
            return <td>{item?.accountName ?? ""}</td>
        },
        numberOfShipments: (item: PendingGenerateWithErrors) => {
            return <td>{item?.numberOfShipments ?? ""}</td>
        },
        error: (e: PendingGenerateWithErrors) => {
            return <td>
                <CTooltip content={"View Errors"}>
                    <FontAwesomeIcon
                        tabIndex={-1}
                        onClick={() => handleGetErrorsDetails(e?.account)}
                        icon={faInfoCircle}
                        size="lg"
                        color={"red"}
                        style={{ cursor: "pointer", marginLeft: 3 }}
                    />
                </CTooltip>
            </td>
        },
    }

    return (
        <>
            {data && data?.length > 0 &&
                <>
                    <hr />
                    <CRow >
                        <CCol>
                            <CTooltip content={showTable ? "Collapse" : "Expand"}>
                                <FontAwesomeIcon
                                    tabIndex={-1}
                                    onClick={() => setShowTable(!showTable)}
                                    icon={showTable ? faArrowAltCircleUp : faArrowCircleDown}
                                    size="lg"
                                    color={"#FF8800"}
                                    style={{ cursor: "pointer", marginRight: 10 }}
                                />

                            </CTooltip>
                            <CLabel>Errored shipments</CLabel>

                        </CCol>
                    </CRow>
                    <CCollapse show={showTable}>
                        <CDataTable
                            loading={isLoading}
                            size="sm"
                            hover
                            scopedSlots={scopedSlots}
                            items={data || []}
                            fields={
                                [
                                    { key: "error", label: "", _style: { width: "1%" } },
                                    { key: "account", label: "Account" },
                                    { key: "numberOfShipments", label: "Number of shipments" },
                                ]
                            }
                        />
                    </CCollapse>
                    <ErrorsModal
                        onClose={() => setErrorsModal(null)}
                        data={errorsModal?.data?.map((e) => ({
                            id: e?.id,
                            shipmentUid: e?.uid,
                            accountReference: e?.accountReference,
                            errors: e?.errors,
                        }))}
                        isOpen={errorsModal?.isOpen}

                    />
                    <hr />
                </>
            }
        </>
    )
}

export default ShipmentsWithErrors;