import {
  CCol,
  CFormGroup,
  CFormText,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupPrepend,
  CInputGroupText,
  CInputRadio,
  CLabel, CLink,
  CRow,
  CTextarea,
} from "@coreui/react";
import React, {
  CSSProperties,
  forwardRef,
  useContext,
  useEffect,
} from "react";
import { Validator } from ".";
import SWFormContext from "./SWFormContext";
import DatePicker from "react-datepicker";
import CIcon from "@coreui/icons-react";
import { SWInputFieldValue } from "./model";
import ReactSelect, { StylesConfig } from "react-select";
import DateAndTimePicker from "./DateAndTimePicker";
import {formatDateTime} from "../../../utils/dateUtil";

const customMultiStyles: CSSProperties = {
  fontSize: "10px",
  backgroundColor: "#f7f7f7",
};

type IsMulti = true;

const selectMultiStyle: StylesConfig<MultiOptions, IsMulti> = {
  control: (provided, state) => {
    return {
      ...provided,
      ...customMultiStyles,
      minHeight: "26px",
      backgroundColor: state.isDisabled ? provided.backgroundColor : "none"

    };
  },
  indicatorsContainer: (provided, state) => {
    return {
      ...provided,
      ...customMultiStyles,
      height: "20px",
      marginTop: "3px",
      marginBottom: "3px",
      backgroundColor: state.isDisabled ? provided.backgroundColor : "none"

    };
  },
  valueContainer: (provided, state) => {
    return {
      ...provided,
      ...customMultiStyles,
      height: "20px",
      marginTop: "0px",
      marginBottom: "3px",
      paddingTop: "0px",
      backgroundColor:"none"
    };
  },

  multiValue: (provided, state) => {
    return {
      ...provided,
      alignItems: "center",
      backgroundColor:"none"
    };
  },
  indicatorSeparator:(provided,state)=>{
    return{
      ...provided,
      backgroundColor:"none",
    }
  }
};

const selectStyle: StylesConfig<
  { lable: string; value: string; id: string },
  IsMulti
> = {
  control: (provided, state) => {
    return {
      ...provided,
      ...customMultiStyles,
      minHeight: "26px",
      backgroundColor: state.isDisabled ? provided.backgroundColor : "none"
    };
  },
  indicatorsContainer: (provided, state) => {
    return {
      ...provided,
      ...customMultiStyles,
      height: "15px",
      marginTop: "7px",
      backgroundColor: state.isDisabled? provided.backgroundColor : "none"

    };
  },
  valueContainer: (provided, state) => {
    return {
      ...provided,
      ...customMultiStyles,
      height: "20px",
      backgroundColor:'none'
    };
  },
  indicatorSeparator:(provided,state)=>{
    return{
      ...provided,
      backgroundColor:"none",
    }
  },
  dropdownIndicator:(provided,state)=>{
    return{
      ...provided,
      height: "26px",
      marginBottom:"14px"
    }
  },
  
  
};

interface MultiOptions {
  value: string;
  label: string;
}
interface Props {
  type:
    | "typeahead"
    | "text"
    | "datetime"
    | "radio"
    | "date"
    | "color"
    | "password"
    | "new-date"
    | "new-datetime"
    | "multi-select";
  lookUp?: { [k: string]: string };
  lookUpMulti?: MultiOptions[];
  getLookUp?: () => Promise<{ [k: string]: string }>;
  style?: React.CSSProperties;
  validators?: Validator[];
  readonly?: boolean;
  field: string;
  multifield?: boolean;
  label?: string;
  labelUrl?: string;
  className?: string;
  append?: any;
  onChange?: (val: SWInputFieldValue) => void;
  icon?: any;
  clearable?: boolean;
  maxLength?: number;
  onBlur?: ()=> void;
}

const SWInput = (props: Props) => {
  const {
      onBlur,
    type,
    field,
    label,
    labelUrl,
    lookUp,
    lookUpMulti,
    getLookUp,
    append,
    icon,
    clearable,
    className,
    style,
    readonly,
    onChange,
    multifield,
    maxLength,
    validators,
  } = props;

  const {
    formFields,
    changeValue,
    getFormValue,
    setValidators,
    validateField,
    formReadonly,
    setAttribute,
  } = useContext(SWFormContext);
  const handleOnBlur = () => {
    if(!(formReadonly || readonly)) 
      validateField(field);
    if(onBlur) 
      onBlur();
  }
  const getFormValueMulti = (field: string) => {
    const values = getFormValue(field) as string[];
    if (!values) return [];
    return lookUpMulti?.filter((l) => values.includes(l.value));
  };
  const getDateValue = (val: any) => {
    if (!getFormValue(val)) return undefined;
    return new Date(getFormValue(val));
  };
  const CustomInput = forwardRef<any, any>(({ value, onClick }, ref) => {
    return (
      <span className="form-control" onClick={onClick} ref={ref}>
        {getFormValue(field) &&
          formatDateTime(new Date(getFormValue(field)))}
      </span>
    );
  });

  // Handling lookups

  // set default value
  useEffect(() => {
    switch (type) {
      case "text":
        //changeValue(field, "");
        return;
      case "color":
        changeValue(field, "#000000");
        return;
    }
  }, []);

  useEffect(() => {
    if (multifield) {
      setAttribute(field, "multifield", true);
    }
  }, [multifield]);

  useEffect(() => {
    validators && setValidators(field, validators);
  }, [validators]);

  const getComponent = (type: string) => {
    switch (type) {
      case "date":
      case "datetime":
        // let dateVal: string | Date = getFormValue(field);
        // if (!dateVal) {
        //   dateVal = new Date();
        // } else if (typeof dateVal === "string") {
        //   dateVal = new Date(dateVal);
        // }
        return (
          <DatePicker
              showYearDropdown
            readOnly={formReadonly || readonly}
            selected={getDateValue(field) || undefined}
            showTimeInput={type.includes("time")}
            onChange={handleOnChange}
            customInput={<CustomInput />}
            className="form-control"
          />
        );
      case "new-date":
      case "new-datetime":
        return (
          <DateAndTimePicker
            readOnly={formReadonly || readonly}
            value={getFormValue(field)}
            showTimeInput={type.includes("time")}
            field={field}
            handleOnChange={handleOnChange}
          />
        );
      case "color":
        return (
          <CInput
            readOnly={formReadonly || readonly}
            type={type}
            id={field}
            onBlur={(e) => validateField(field)}
            name={field}
            key={field}
            disabled={formReadonly || readonly}
            value={getFormValue(field) ?? "#000000"}
            onChange={(e) => handleOnChange((e as any).target.value)}
          />
        );

      case "password":
      case "text":
        return (
          (multifield && (
            <CTextarea
              rows={10}
              style={{ fontSize: "10px" }}
              readOnly={formReadonly || readonly}
              id={field}
              onBlur={(e) => validateField(field)}
              name={field}
              key={field}
              value={getFormValue(field) ?? ""}
              onChange={(e) => handleOnChange((e as any).target.value)}
            />
          )) || (
            <CInput
              
              style={{ fontSize: "10px", height: "26px" }}
              maxLength={maxLength}
              readOnly={formReadonly || readonly}
              type={type}
              id={field}
              onBlur={handleOnBlur}
              name={field}
              key={field}
              value={getFormValue(field) ?? ""}
              onChange={(e) => handleOnChange((e as any).target.value)}
            />
          )
        );

      case "multi-select":
        return (
          <ReactSelect
            isMulti
            styles={selectMultiStyle}
            className="w-100"
            value={getFormValueMulti(field)}
            onChange={(e: any) => {
              handleOnChangeMulti(e);
            }}
            options={lookUpMulti}
            isDisabled={formReadonly || readonly}
          />
        );
      case "typeahead":
        const options =
          lookUp &&
          Object.entries(lookUp).map((v) => ({
            label: v[1],
            value: v[0],
            id: v[1],
          }));
        return (
          <ReactSelect
            style={{ fontSize: "10px" }}
            //@ts-ignore
            styles={selectStyle}
            classNamePrefix="mySelect"
            className="w-100"
            value={options?.find((o) => o.value == getFormValue(field))}
            onChange={(e: any) => {
              if (!e) {
                e = {
                  target: field,
                  value: '',
                };
              }
              handleOnChange(e.value);
            }}
            options={options}
            isClearable = {clearable}
            // placeholder={props.placeholder}
            isDisabled={formReadonly || readonly}
          />
          // <Typeahead
          //   className="w-100"
          //   labelKey="label"
          //   id="typeahead"
          //   onChange={(e) => {
          //     e[0] && handleOnChange(e[0].value);
          //   }}
          //   selected={options.find(o => o.value == getFormValue(field))[options.find(o => o.value == getFormValue(field)) ?? getFormValue(field) ?? '']}
          //   options={options}
          //   disabled={formReadonly || readonly}
          //
          // />
        );

      case "radio":
        return (
          <CRow className="w-100">
            {Object.entries<string>(lookUp ?? {}).map((v) => (
              <CCol key={v[0]}>
                <CFormGroup>
                  <CLabel>
                    <CInputRadio
                      disabled={formReadonly || readonly}
                      id={field}
                      name={field}
                      className="ml-0 position-relative mr-2"
                      style={{
                        width: "1.25em",
                        height: "1.25em",
                        fontSize: "10px",
                      }}
                      radioGroup={field}
                      onClick={() => changeValue(field, v[0])}
                      onChange={() => changeValue(field, v[0])}
                      label={v[1]}
                      checked={getFormValue(field) === v[0]}
                    />

                    {v[1]}
                  </CLabel>
                </CFormGroup>
              </CCol>
            ))}
          </CRow>
        );
      // case "lookup":
      //     return (
      //         <CSelect
      //         style={{fontSize:"10px"}}
      //             readOnly={formReadonly || readonly}
      //             id={field}
      //             name={field}
      //             disabled={formReadonly || readonly}
      //             key={field}
      //             value={
      //                 getFormValue(field) ??
      //                 Object.entries<string>(lookUp ?? {any: ""})[0]
      //             }
      //             onChange={(e) => handleOnChange((e as any).target.value)}
      //         >
      //             {[["", "Pick an option"], ...Object.entries(lookUp)].map(
      //                 (v) => (
      //                     <option key={v[0]} value={v[0]}>
      //                         {v[1]}
      //                     </option>
      //                 )
      //             )}
      //         </CSelect>
      //     );
    }
  };
  const handleOnChangeMulti = (value: MultiOptions[]) => {
    if (formReadonly || readonly) return;
    if (!value || value.length == 0) handleOnChange(null);

    handleOnChange(value.map((c) => c.value));
  };
  const handleOnChange = (value: any) => {
    if (formReadonly || readonly) return;
    switch (type) {
      default:
        onChange && onChange(value);
        changeValue(field, value);
    }
  };

  return (
    <div className={className} style={style}>
      <CFormGroup>
        {label && labelUrl == undefined && <CLabel>{label}</CLabel>}
        {label && labelUrl != undefined && <CLink to={labelUrl}> <CLabel style={{cursor: "pointer"}}><u>{label}</u> </CLabel> </CLink>  }
        <CInputGroup>
          {icon && (
            <CInputGroupPrepend>
              <CInputGroupText>
                <CIcon content={icon} />
              </CInputGroupText>
            </CInputGroupPrepend>
          )}
          {getComponent(type)}
          {append && (
            <CInputGroupAppend>
              <CInputGroupText style={{ lineHeight: "1" }}>
                {append}
              </CInputGroupText>
            </CInputGroupAppend>
          )}
        </CInputGroup>
        {formFields.validationErrors[field] ? (
          <CFormText
            style={{ fontSize: "1em" }}
            color="danger"
            className="help-block"
          >
            {formFields.validationErrors[field] &&
              formFields.validationErrors[field]?.reduce((pv, cv, i) => {
                return `${i !== 0 ? `${pv}, ` : ""}${cv}`;
              }, "")}
          </CFormText>
        ) : (
          ""
        )}
      </CFormGroup>
    </div>
  );
};

export default SWInput;
