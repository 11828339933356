import { RatesAppModel } from "../../models/app";

const ratesState: RatesAppModel = {
    costsSearch: {
        service: "",
        agent: "",
    },
    sellingSearch: {
        cardName: "",
        customer: "",
    },
};

export default ratesState;
