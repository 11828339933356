import React, { useEffect, useState } from "react";
import { CButton, CButtonGroup, CCol, CContainer, CLink, CRow } from "@coreui/react";
import ActionButton from "../../../SharedComponents/ActionButton";
import { useFormik } from "formik";
import * as yup from "yup"
import SWSelect from "../../../SharedComponents/SWSelect";
import RatesClient from "../../../../clients/ratesClient";
import { RateSearchRequest, SearchRateCardModel } from "../../../../models/retes";
import { SetIsLoading } from "../../../../store/actions/ui";
import { useDispatch } from "react-redux";
import { ShowNotification } from "../../../../store/actions/auth";
import { setSearchCosts } from "../../../../store/actions/rates";

interface IProps {
    setData: (arg: Array<SearchRateCardModel>) => void
    initialData?:RateSearchRequest
}
const SearchCosts = ({ setData,initialData }: IProps) => {
    const dispatch = useDispatch()
    const ratesClient = new RatesClient();
    const [services, setServices] = useState<{ [k: string]: string }>({});
    const [agents, setAgents] = useState<{ [k: string]: string }>({})
    const { values, handleSubmit, setFieldValue, resetForm, handleReset, initialValues, touched, errors } = useFormik<RateSearchRequest>({
        enableReinitialize:true,
        initialValues: {
            service: initialData?.service ?? "",
            agent: initialData?.agent ?? "",
        },
        onSubmit: async (values) => {
            dispatch(setSearchCosts(values))
            handleSearchCosts(values)
        },
        validationSchema: yup.object({})
    })
    const handleSearchCosts = async (values:RateSearchRequest) => {
        dispatch(SetIsLoading(true))
        const res = await ratesClient.SearchCosts(values)
        if (res?.succeeded) {
            setData(res?.data)
        }else{
            dispatch(ShowNotification("Error", res?.error??"Error", true));
        }
        dispatch(SetIsLoading(false))

    }
    const handleClear = async () => {
        resetForm()
        handleSearchCosts({})
        dispatch(setSearchCosts({}))
    }
    useEffect(() => {
        getServices()
        getAgents()
    }, []);


    useEffect(() => {
        if(Boolean(values?.agent)){
            getAgentServices()
        }
    }, [values?.agent]);

    const getAgents = async () => {
        let agents = await ratesClient.LookupAgents()
        setAgents(agents)
    }
    const getServices = async () => {
        let services = await ratesClient.GetServices()
        setServices(services)
    }
    const getAgentServices = async () => {
        let services = await ratesClient.GetAgentServices(values?.agent!)
        setServices(services)
    }


    return (
        <fieldset className="b-fieldset p-2 text-primary">
            <legend className="b-legend" style={{ width: "110px" }}>
                <h5>Search Costs</h5>
            </legend>
            <CContainer className="form-group mb-0">
                <CRow>
                    <CCol className="col-sm-3">
                        <SWSelect
                            label="Agent Name"
                            name="agent"
                            value={values?.agent}
                            onChange={(e) => {
                                setFieldValue("agent", e)
                                setFieldValue("service", "")
                            }}
                            values={agents}
                            validationErrors={touched?.agent && errors?.agent?.toString() || ""}
                        />
                    </CCol>
                    <CCol className="col-sm-3">
                        <SWSelect
                            label="Service Type"
                            name="service"
                            value={values?.service}
                            onChange={(e) => setFieldValue("service", e)}
                            values={services}
                            validationErrors={touched?.service && errors?.service?.toString() || ""}
                        />
                    </CCol>
                </CRow>
            </CContainer>
            <CCol className="text-right">
                <CButtonGroup className="mb-0 mr-2">
                    <CButton
                        size="sm"
                        style={{ fontSize: "10px" }}
                        className="mr-2"
                        variant="outline"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Search
                    </CButton>
                    <CButton
                        size="sm"
                        style={{ fontSize: "10px" }}
                        className="mr-2"
                        variant="outline"
                        color="danger"
                        onClick={handleClear}
                    >
                        Clear
                    </CButton>
                </CButtonGroup>
                <CButtonGroup className="mb-0 ">
                    <CLink to={'/financial/addCard?type=costs'} className="card-header-action">
                        <ActionButton text={"Create new card"} />
                    </CLink>
                </CButtonGroup>
            </CCol>
        </fieldset>
    )
}

export default SearchCosts