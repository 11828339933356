import {CButton, CCol, CModal, CModalBody, CModalFooter, CModalHeader, CRow} from "@coreui/react";
import SWInput from "../../../SharedComponents/SWInput";
import {InvoiceDto, InvoiceStatus, MarkInvoicePaidRequest} from "../../../../models/accounting";
import {useState} from "react";
import SWInputNumber from "../../../SharedComponents/SWInputNumber";
import SWDateAndTimePicker from "../../../SharedComponents/SWDateAndTimePicker";

interface IProps {
    invoice?: InvoiceDto
    onClose: () => void
    onSubmit: (body: MarkInvoicePaidRequest) => void
}

const ConfirmInvoicePayment = (props: IProps) => {
    const value = (props.invoice?.taxableValue ?? 0) + (props.invoice?.taxLiquidatedValue ?? 0) + (props.invoice?.nonTaxableValue ?? 0) + (props.invoice?.taxValue ?? 0) - (props.invoice?.paymentAmount ?? 0);

    const [paymentRequest, setPaymentRequest] = useState<MarkInvoicePaidRequest>({
        externalPaymentReference: "",
        paidOn: new Date,
        amount: value
    })
    return (
        <CModal show={true} onClose={props.onClose} centered>
            <CModalHeader>Mark invoice: {props.invoice?.invoiceNumber} as paid</CModalHeader>
            <CModalBody>
                <h6>Are you sure you want to mark invoice as paid?</h6>
                <br/>
                <CRow>
                    <CCol>
                        <SWInputNumber label={"Amount"} value={paymentRequest.amount}
                                       onChange={v => setPaymentRequest({...paymentRequest, amount: v})}/>
                    </CCol>
                    <CCol>
                        <SWInput value={paymentRequest.externalPaymentReference} label={"External Reference"}
                                 onChange={(v => setPaymentRequest({...paymentRequest, externalPaymentReference: v}))}/>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>
                        <SWDateAndTimePicker value={paymentRequest.paidOn}
                                             handleOnChange={v => setPaymentRequest({...paymentRequest, paidOn: v})}/>
                    </CCol>
                </CRow>
            </CModalBody>
            <CModalFooter>
                <CCol>
                    <CButton
                        variant={"outline"}
                        color={"danger"}
                        size={"sm"}
                        onClick={props.onClose}
                    >Cancel</CButton>

                </CCol>
                <CCol className={"text-right"}>
                    <CButton
                        variant={"outline"}
                        color={"primary"}
                        size={"sm"}
                        onClick={() => props.onSubmit(paymentRequest)}
                    >Submit</CButton>
                </CCol>
            </CModalFooter>
        </CModal>
    )
}

export default ConfirmInvoicePayment