import {useHistory, useParams} from "react-router";
import {
    CButton,
    CCol,
    CContainer,
    CNav,
    CNavLink,
    CRow,
    CSpinner,
    CTabContent,
    CTabPane,
    CTabs,
    CTooltip
} from "@coreui/react";
import React, {useEffect, useState} from "react";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import AccountingClient from "../../../../clients/accountingClient";
import ViewAccountCheckout from "./ViewAccountCheckout";
import ViewAccountingWarehouse from "./ViewAccountingWarehouse";
import AddAccountForm from "./AddAccountForm";
import {IAddAccount} from "../../../../models/shipment";
import AddressBookSection from "./AddressBookSection";
import ViewShipAccount from "./ViewShipAccount";
import ViewAccountingAttachment from "./ViewAccountingAttachment";
import LookupsClient from "../../../../clients/lookupsClient";

const ViewAccountingAccount = () => {
    const accountClient = new AccountingClient()
    const {accountId} = useParams() as any
    const [accountInfo, setAccountInfo] = useState<IAddAccount>()
    const [countryLookups, setCountryLookups] = useState<{ [k: string]: string }>({});
    const [loader, setLoader] = useState(false)

    const hist = useHistory()
    const fetchData = async () => {
        setLoader(true)
        const res = await accountClient.GetAccount(accountId);
        if (res) {
            setAccountInfo({
                attachment: res.account.attachments,
                accountType: res.account.accountType,
                addressLine1: res.account.addressLine1,
                addressLine2: res.account.addressLine2,
                addressLine3: res.account.addressLine3,
                city: res.billingAddress.city,
                country: res.billingAddress.country,
                dueDays: res.account.dueDays,
                email: res.account.email,
                eori: res.account.eori,
                id: res.account.id,
                name: res.account.name,
                notes: res.account.notes,
                phone: res.account.phone,
                postCode: res.account.postCode,
                configurations: res.account.configurations,
                profileId: res.account.profileId,
                secondPhone: res.account.secondPhone,
                state: res.account.state,
                street: res.account.street,
                taxType: res.account.taxType,
                vat: res.account.vat,
                publicName: res.account.publicName,
                contactPerson: res.account.contactPerson,
            });

        }

        setLoader(false)
    }
    useEffect(() => {
        fetchData().then()
    }, []);

    const lookups = new LookupsClient();


    const getCountries = async () => {
        let countries = await lookups.getCountries();
        setCountryLookups(countries);
    };

    useEffect(() => {
        getCountries();
    }, []);

    return (
        <CContainer className="bg-white p-4 text-black">
            {loader ? <CSpinner
                className="mx-auto d-block my-5"
                color="primary"
                style={{width: "5em", height: "5em"}}
            /> : <><CRow>
                <CTooltip content="Back">
                    <CButton>
                        <CIcon
                            content={freeSet.cilArrowLeft}
                            onClick={() => hist.goBack()}
                        />
                    </CButton>
                </CTooltip>
            </CRow>
                <CRow className={'my-2'}>
                    <CCol md={10}>
                        <h5>{accountInfo?.name}</h5>
                    </CCol>

                </CRow>
                <CTabs activeTab="basic">
                    <CNav disabled={loader} variant="tabs">
                        <CNavLink data-tab="basic">Billing details</CNavLink>
                        {(accountInfo &&accountInfo?.accountType !== 2) && <>
                            <CNavLink data-tab="shipments">Shipments</CNavLink>
                            <CNavLink data-tab="checkout">Quotation</CNavLink>
                            <CNavLink data-tab="warehouse">Warehouse</CNavLink>
                            <CNavLink data-tab="addressBook">Address Book</CNavLink>
                        </>}

                        <CNavLink data-tab="attachments">Attachments</CNavLink>
                    </CNav>
                    <CTabContent>
                        <CTabPane className="my-3" data-tab="basic">
                            <AddAccountForm accountInfo={accountInfo} setAccountInfo={setAccountInfo}
                                            countryLookups={countryLookups} isEdit/>
                        </CTabPane>
                        {(accountInfo && accountInfo?.accountType !== 2) && <>
                            <CTabPane className="my-3" data-tab="shipments">
                                <ViewShipAccount accountId={accountId}/>
                            </CTabPane>
                            <CTabPane className="my-3" data-tab="checkout">
                                <ViewAccountCheckout accountId={accountId}/>
                            </CTabPane>
                            <CTabPane className="my-3" data-tab="warehouse">
                                <ViewAccountingWarehouse accountId={accountId}/>
                            </CTabPane>
                            <CTabPane className="my-3" data-tab="addressBook">
                                <AddressBookSection countryLookups={countryLookups} accountId={accountId}/>
                            </CTabPane>
                        </>}

                        <CTabPane className="my-3" data-tab="attachments">
                            <ViewAccountingAttachment accountInfo={accountInfo}/>
                        </CTabPane>
                    </CTabContent>
                </CTabs>
            </>}


        </CContainer>
    )
}

export default ViewAccountingAccount;
