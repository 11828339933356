import { Action, User } from "../../models/app";
import {LOG_OUT, SET_IS_LOADING, SET_LOGGING_IN, SET_NOTIFICATION, SET_USER} from "../types";

export const LogOut = (): Action => {
  return {
    type: LOG_OUT,
  };
};

export const SetLoggingIn = (loggingIn: boolean) => {
  return {
    type: SET_LOGGING_IN,
    payload: {
      loggingIn
    }
  }
}

export const SetUser = (user: User | null): Action => {
  return {
    type: SET_USER,
    payload: {
      user: user,
    },
  };
};

export const SetLoading = (isLoading:boolean) => {
  return {
    type: SET_IS_LOADING,
    payload:{
      isLoading
    }
  }
}

export const ShowNotification = (title:string, message:string, error?:boolean) => {
  return {
    type: SET_NOTIFICATION,
    payload:{
      notification:{
        show:true,
        title,
        message,
        error
      }
    }
  }
}

export const HideNotification = () => {
  return {
    type: SET_NOTIFICATION,
    payload:{
      notification:{
        show:false
      }
    }
  }
}
