import {CContainer} from "@coreui/react";
import React, {useEffect, useState} from "react";
import ShipClient from "../../../clients/shipClient";

import {useDispatch, useStore} from "react-redux";
import {SearchyResult} from "../../../models/app";

import SearchResult from "./components/SearchResult";
import NewSearchFrom from "./components/NewSearchFrom";
import {SetLoading, ShowNotification} from "../../../store/actions/auth";
import LookupsClient from "../../../clients/lookupsClient";
import RecalculateRouteModal from "./modals/RecalculateRouteModal";
import DeleteShipmentsConfirmModal from "./modals/DeleteShipmentsConfirmModal";
import {
    convertFilterType,
    DataType,
    SearchyCondition,
    SearchyFilter,
    SearchyRequest,
    SearchyRule
} from "../../../models/Searchy";
import {ShipmentSearch, ShipmentSearchRequest} from "../../../models/shipment";
import {IPagination} from "../../../models/filter";

const SearchShipments = () => {
    const [allowedUsers, setAllowedUsers] = useState<{ [k: string]: string }>({});

    const [submitting, setSubmitting] = useState<boolean>(false)
    const [confirmDeleteModel, setConfirmDeleteModal] = useState<boolean>(false)
    const [confirmRoutingModel, setConfirmRoutingModal] = useState<boolean>(false)
    const [shipmentUidsSelected, setShipmentUidsSelected] = useState<string[]>([])
    const [countryLookups, setCountryLookups] = useState<{ [k: string]: string }>({})
    const [searchyRequest, setSearchyRequest] = useState<SearchyRequest>({
        conditions: [],
        sorts: [],
        pageSize: 20,
        pageIndex: 0,
        format: 0,
        countRows: true
    })
    const [paginationResult, setPaginationResult] = useState<SearchyResult<ShipmentSearch>>({
        result: [],
        totalCount: 0,
        missingShipments: null
    })
    const dispatch = useDispatch();
    const store = useStore()
    const shipClient = new ShipClient();

    useEffect(() => {
        getCountries()
        getAllowedUsers()
    }, []);


    const getAllowedUsers = async () => {
        const res = await shipClient.SearchUsersCanTakeTask()
        const formattedData: { [key: string]: string } = {["myself"]: "--Myself--", ["nobody"]: "--Nobody--"};

        if (res?.length > 0) {
            res?.map((index: any) => {
                formattedData[index.username] = index.displayName;
            })

            setAllowedUsers(formattedData)
        }

    }

    const handleFilterChange = (condition: SearchyCondition) => {
        let request = {...searchyRequest}
        request.conditions = [{...condition}]
        setSearchyRequest(request)
    }

    const handlePageChange = (pagination: IPagination) => {
        let newRequest = {...searchyRequest}
        newRequest.pageIndex = pagination.offset! / pagination.limit!
        newRequest.pageSize = pagination.limit
        setSearchyRequest(newRequest)
    }

    const onDeleteButtonClicked = (uids: string[]) => {
        if (uids.length > 0) {
            setShipmentUidsSelected(uids)
            setConfirmDeleteModal(true)
        }
    }

    const onReCalculateButtonClicked = (uids: string[]) => {
        setShipmentUidsSelected(uids)
        setConfirmRoutingModal(true)
    }
    const onRecalculateSelected = async () => {
        setConfirmRoutingModal(false)
        const result = await shipClient.recalculateShipmentUids(shipmentUidsSelected);
        if (result > 0)
            dispatch(ShowNotification("Shipments Route Recalulation Requested",
                "selected shipments routes are being calculated", false))

    }
    const onRecalculateAll = async () => {
        setConfirmRoutingModal(false)
        const result = await shipClient.recalculateShipment(searchyRequest);
        if (result > 0)
            dispatch(ShowNotification("Shipments Route Recalulation Requested",
                `${paginationResult.totalCount} shipments routes are being calculated`, false))

    }
    const onDeleteConfirmed = async () => {
        let rs = await shipClient.batchDelete(shipmentUidsSelected)
        if (rs.succeeded) {
            setConfirmDeleteModal(false)
            dispatch(ShowNotification("Shipments Deleted", "Selected shipments had been deleted successfully", false))
        } else {
            dispatch(ShowNotification("Error", "Error while deleting shipments"))
        }
        //window.location.reload()

        const shipments = await shipClient.getShipments(searchyRequest)
        setPaginationResult(shipments)
        setSubmitting(false)
    }

    const searchShipments = async () => {
        setSubmitting(true)
        dispatch(SetLoading(true))
        const storeFilters = store.getState().shipment.shipmentsSearch
        searchyRequest.pageIndex = storeFilters.filterGroup.page
        searchyRequest.pageSize = storeFilters.limit


        let sc: SearchyCondition = {
            filters: []
        }

        storeFilters.filterGroup.filters.forEach((f: {
            value: string;
            type: string;
            field: any;
            dataType: DataType;
        }) => {
            if (f.value) {
                let isRange = f.type == "9" || f.type == "21"
                let searchyFilter: SearchyFilter = {
                    field: f.field,
                    rule: f.type && Number(f.type) ? Number(f.type) : SearchyRule.EqualsTo,
                    value: convertFilterType(f.value, f.dataType, isRange)
                }
                if (isRange) {
                    if (f.dataType == DataType.string) {
                        searchyFilter.valueStringArray = searchyFilter.value
                        searchyFilter.value = undefined
                    }
                    if (f.dataType == DataType.number) {
                        searchyFilter.valueDecimalArray = searchyFilter.value
                        searchyFilter.value = undefined
                    }
                    if (f.dataType == DataType.date) {
                        searchyFilter.valueDateTimeArray = searchyFilter.value
                        searchyFilter.value = undefined
                    }
                }
                sc.filters?.push(searchyFilter)
            }
        })

        const sr = {
            ...searchyRequest,
            pageIndex: storeFilters.filterGroup.page,
            pageSize: storeFilters.limit,
            conditions: [sc]
        }

        const rs = await shipClient.getShipmentsForStaff(sr)
        setPaginationResult(rs)
        setSubmitting(false)
    };

    const getCountries = async () => {
        let lookupsClient = new LookupsClient();
        let countries = await lookupsClient.getCountries()
        setCountryLookups(countries)
    }

    useEffect(() => {
        searchShipments()

    }, [searchyRequest, store])

    return (
        <CContainer className="bg-white p-4">
            <NewSearchFrom allowedUsers={allowedUsers} additionalFilters={[
                {
                    field: "createdOn",
                    title: "Created On",
                    type: "date"
                },
                {
                    field: "blocked",
                    title: "Blocked",
                    type: "select",
                    lookups: {
                        "true": "yes",
                        "false": "no"
                    }
                },
                {
                    field: "ShipperName",
                    title: "Shipper Name",
                    type: "text"
                },
                {
                    field: "shipperReference",
                    title: "Shipper Reference",
                    type: "text"
                },
                {
                    field: "ShipperAddressLine1",
                    title: "Shipper Street",
                    type: "text"
                },
                {
                    field: "ShipperCompanyName",
                    title: "Shipper Company Name",
                    type: "text"
                },
                {
                    field: "ShipperCity",
                    title: "Shipper City",
                    type: "text"
                },
                {
                    field: "ShipperCountry",
                    title: "Shipper Country",
                    type: "select",
                    lookups: countryLookups
                },
                {
                    field: "ShipperEmail",
                    title: "Shipper Email",
                    type: "text"
                },
                {
                    field: "ShipperNotes",
                    title: "Shipper Notes",
                    type: "text"
                },
                {
                    field: "ShipperPhone",
                    title: "Shipper Phone",
                    type: "text"
                },
                {
                    field: "ShipperPostCode",
                    title: "Shipper PostalCode",
                    type: "text"
                },
                {
                    field: "ConsigneeName",
                    title: "Consignee Name",
                    type: "text"
                },
                {
                    field: "ConsigneeEori",
                    title: "Consignee EURI",
                    type: "text"
                },
                {
                    field: "ConsigneeReference",
                    title: "Consignee Reference",
                    type: "text"
                },
                {
                    field: "ConsigneeAddressLine1",
                    title: "Consignee Street",
                    type: "text"
                },
                {
                    field: "ConsigneeCompanyName",
                    title: "Consignee Company Name",
                    type: "text"
                },
                {
                    field: "ConsigneeCity",
                    title: "Consignee City",
                    type: "text"
                },
                {
                    field: "ConsigneeCountry",
                    title: "Consignee Country",
                    type: "select",
                    lookups: countryLookups
                },
                {
                    field: "ConsigneeEmail",
                    title: "Consignee Email",
                    type: "text"
                },
                {
                    field: "ConsigneeNotes",
                    title: "Consignee Notes",
                    type: "text"
                },
                {
                    field: "ConsigneePhone",
                    title: "Consignee Phone",
                    type: "text"
                },
                {
                    field: "ConsigneePostCode",
                    title: "Consignee PostCode",
                    type: "text"
                },
                {
                    field: "Value",
                    title: "Value",
                    type: "number"
                },
                {
                    field: "COD",
                    title: "COD",
                    type: "number"
                },
                {
                    field: "OnlineCOD",
                    title: "Online COD",
                    type: "number"
                }
            ]} handleFilters={handleFilterChange}
                           missingShipmentNumbers={paginationResult?.missingShipments}/>
            <SearchResult handlePageChange={handlePageChange} searchResult={paginationResult} loading={submitting}
                          omDeleteClicked={onDeleteButtonClicked} onRecalculateClicked={onReCalculateButtonClicked}
                          searchFilters={searchyRequest.conditions[0]?.filters} allowedUsers={allowedUsers}/>
            {confirmDeleteModel &&
                <DeleteShipmentsConfirmModal onClose={() => setConfirmDeleteModal(false)} onSubmit={onDeleteConfirmed}
                                             numberOfShipmentsSelected={shipmentUidsSelected.length}/>}
            {confirmRoutingModel &&
                <RecalculateRouteModal onClose={() => setConfirmRoutingModal(false)}
                                       selectedUids={shipmentUidsSelected} totalShipments={paginationResult.totalCount}
                                       onSubmitSelected={() => onRecalculateSelected()}
                                       onSubmitAll={() => onRecalculateAll()}/>}
        </CContainer>
    );
};

export default SearchShipments;
