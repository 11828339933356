import {CBadge, CDataTable, CLink, CRow, CSpinner} from "@coreui/react";
import React, {useEffect, useState} from "react";
import {AppModel} from "../../../../models/app";
import PageCount from "../../../SharedComponents/PageCount";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router";
import {QuotationSearch} from "../../../../models/quotation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import ShipClient from "../../../../clients/shipClient";
import RatesClient from "../../../../clients/ratesClient";
import {formatDate} from "../../../../utils/dateUtil";
import {setQuotationSearchLimit} from "../../../../store/actions/quotations";

interface Props {
}

const SearchResult = (props: Props) => {
    const shipClient = new ShipClient()
    const [shipmentNumbers, setShipmentNumbers] = useState<{ uid: string, number: string }[]>([])
    const [account, setAccount] = useState<{ [k: string]: string }>({})
    useEffect(() => {
        let client = new RatesClient()
        client.LookupCustomers().then(v => setAccount(v))
    }, [])

    const scopedSlots = {
        profile: (item: QuotationSearch) => <td>{item.profile.name}</td>,
        accountNumber: (item: QuotationSearch) => <td>{account[item.accountNumber ?? ""]}</td>,
        companyName: (item: QuotationSearch) => <td>{item.billingAddress?.companyName}</td>,
        links: (item: QuotationSearch) => (
            <td>
                <CLink to={`/quotations/${item.id}`} className="card-header-action">
                    <FontAwesomeIcon icon={faEye}/>
                </CLink>
            </td>
        ),
        actions: (item: QuotationSearch) => <td></td>,

        createdOn: (item: QuotationSearch) => (
            <td>{formatDate(item.createdOn)}</td>
        ),

        invoiceNumber: (item: QuotationSearch) => (
            <td>
                {" "}
                <CRow>
                    <span className="ml-4"> {item.invoice?.number}</span>
                    <span className="ml-1">
            {" "}
                        {item?.creditNoteNumber && (
                            <CBadge
                                style={{width: "auto", height: "12px", fontSize: "10px"}}
                                shape="pill"
                                color="danger"
                            >
                                {" "}
                                CN: {item?.creditNoteNumber}{" "}
                            </CBadge>
                        )}{" "}
          </span>
                </CRow>{" "}
            </td>
        ),

        processed: (item: QuotationSearch) => (
            <td> {formatDate(item.processedOn)}</td>
        ),
        shipmentNumber: (item: QuotationSearch) => (
            <td> {shipmentNumbers?.find(s => s.uid == item.shipmentUid)?.number}</td>
        ),
        total: (item: QuotationSearch) => (
            <td>{Number(item.total?.toFixed(2))}</td>
        )
    };

    const dispatch = useDispatch();
    const hist = useHistory();
    const loc = useLocation();

    const quotationSearchResult = useSelector((state: AppModel) => {
        return {
            result: state.quotation.quotationsSearch.result,
            isLoading: state.quotation.quotationsSearch.isLoading,
            page: state.quotation.quotationsSearch.page,
            filterGroup: state.quotation.quotationsSearch.filterGroup,
            maxFound: state.quotation.quotationsSearch.count,
            maxPage: Math.ceil(
                state.quotation.quotationsSearch.count! /
                state.quotation.quotationsSearch.limit!
            ),
        };
    });

    useEffect(() => {
        getShipmentNumbers()
    }, [quotationSearchResult.result])

    const getShipmentNumbers = () => {
        const onlyUnique = (value: any, index: any, self: string | any[]) => {
            return self.indexOf(value) === index;
        }

        let ids = quotationSearchResult.result?.map(q => q.shipmentUid)
        ids = ids?.filter(onlyUnique)
        if (ids)
            shipClient.getShipmentNumbersByUids(ids).then(v => setShipmentNumbers(v))
    }

    return (
        <div>
            {quotationSearchResult.isLoading ? (
                <CSpinner
                    className="mx-auto d-block my-5"
                    color="primary"
                    style={{width: "5em", height: "5em"}}
                />
            ) : (
                <>
                    <div className="small">
                        <CDataTable
                            size="sm"
                            hover
                            items={quotationSearchResult.result}
                            scopedSlots={scopedSlots}
                            fields={[
                                {key: "links", label: "", _style: {width: "1%"}},
                                {key: "id", label: "Number"},
                                "profile",
                                {key: "accountNumber", label: "Account"},
                                {key: "companyName", label: "Company"},
                                {key: "name", label: "Name"},
                                "createdOn",
                                {key:"total", label: "Total"},
                                "status",
                                {key: "processed", label: "Processed"},
                                {key: "invoiceNumber", label: "Invoice Number"},
                                {key: "shipmentNumber", label: "Shipment Number"},

                                // "email",
                                // "phone"
                            ]}
                        />
                        <PageCount
                            maxFound={quotationSearchResult.maxFound}
                            onPageChange={(p) => {
                                const modifiedSearch = new URLSearchParams(loc.search);
                                modifiedSearch.delete("page");
                                hist.push(
                                    `${loc.pathname}?${modifiedSearch.toString()}&page=${p}`
                                );
                            }}
                            onPageSizeChange={(ps) => {
                                const modifiedSearch = new URLSearchParams(loc.search);
                                modifiedSearch.delete("limit");
                                hist.push(
                                    `${loc.pathname}?${modifiedSearch.toString()}&limit=${ps}`
                                );
                                dispatch(setQuotationSearchLimit(ps))
                            }}
                            currentPage={quotationSearchResult.filterGroup?.page ?? 0}
                            maxPage={quotationSearchResult.maxPage}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default SearchResult;
