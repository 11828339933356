import {
    CButton,
    CButtonGroup,
    CCol,
    CInputRadio,
    CLabel,
    CRow,
} from "@coreui/react";
import React, {useEffect} from "react";
import {useFormik} from "formik";
import {
    InventoryTransactionSearchFilter,
    SkuSearchFilter,
} from "../../../../../models/warehouse";
import SWSelect from "../../../../SharedComponents/SWSelect";
import SWInput from "../../../../SharedComponents/SWInput";
import {useLocation} from "react-router";
import {querytoMap} from "../../../../../utils/filterUtils";
import {Account} from "../../../../../models/account";
import SWDateAndTimePicker from "../../../../SharedComponents/SWDateAndTimePicker";
import DateRangeFilter from "../../../../SharedComponents/SWAdvancedSearch/DateRangeFilter";
import ExportToExcelButton from "../../../../SharedComponents/ExportToExcelButton";
import {RemoteBlob} from "../../../../../models/app";
import WarehouseClient from "../../../../../clients/warehousesClient";
import ActionButton from "../../../../SharedComponents/ActionButton";

interface Props {
    onSearchClicked: (filter: SkuSearchFilter) => void;
    accountsLookup: { [k: string]: string };
    typesLookup: { [k: string]: string };
    onClearClicked: () => void;
    showDetails: boolean;
}

const SearchForm = (props: Props) => {
    let {search} = useLocation();

    const queryMap = querytoMap<InventoryTransactionSearchFilter>(search);

    // @ts-ignore
    const handleExport: () => Promise<RemoteBlob> = async () => {
        let client = new WarehouseClient()
        return await client.searchStockDetails({...values, exportToExcel: true})
    }

    useEffect(() => {
        setFieldValue("showDetails", props.showDetails)
    }, [props.showDetails])

    const {
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
    } = useFormik<InventoryTransactionSearchFilter>({
        initialValues: {
            account: queryMap.account ?? '',
            code: '',
            draftOnly: queryMap.draftOnly ?? false,
            postedOnly: queryMap.postedOnly ?? false,
            subType: queryMap.subType ?? "",
            limit: 20,
            offset: 0,
            onFrom:queryMap.onFrom ?? "",
            onTo:queryMap.onTo ?? "",
            on:queryMap.on ?? "",
            reference:queryMap.reference ?? "",
            DateTypeFilter:queryMap.DateTypeFilter ?? "eq",
            openReservation: queryMap.openReservation ?? false,
            closedReservation: queryMap.closedReservation ?? false,
            reversed: queryMap.reversed ?? false,
            nullReversed: queryMap.nullReversed ?? false,
            shipmentNumber: queryMap.shipmentNumber ?? "",
            showDetails: queryMap.showDetails ?? false
        },
        onSubmit: props.onSearchClicked,
    });

    return (
        <fieldset className="b-fieldset p-2 text-primary">
            <legend className="b-legend" style={{width: "108px"}}><h5>Transactions</h5></legend>
            <CRow>
                <CCol>
                    <SWSelect
                        values={props.accountsLookup}
                        value={values.account}
                        label={"Account"}
                        isClearable
                        onChange={(e) => setFieldValue("account", e)}
                    />
                </CCol>
                <CCol>
                    <SWInput
                        label={"Shipment"}
                        name={"shipmentNumber"}
                        value={values.shipmentNumber}
                        onChangeFormik={handleChange}
                    />
                </CCol>
                <CCol>
                    <SWInput
                        label={"Transaction Id"}
                        name={"code"}
                        value={values.code}
                        onChangeFormik={handleChange}
                    />
                </CCol>
                <CCol/>
                <CCol md={3}>
                    {/*<CRow>*/}
                    {/*    <CInputCheckbox*/}
                    {/*        checked={values.showDetails}*/}
                    {/*        onClick={() => setFieldValue("showDetails", !values.showDetails)}*/}
                    {/*    /><CLabel>Show Details</CLabel>*/}
                    {/*</CRow>*/}
                    <CRow style={{marginLeft:"1px"}}>
                        <CLabel>Open Reservations</CLabel>
                    </CRow>
                    <CRow style={{marginLeft:"6px"}}>
                        <CCol>
                            <CInputRadio
                                style={{
                                    width: "1.25em",
                                    height: "1.25em",
                                    fontSize: "10px",
                                }}
                                checked={values.openReservation}
                                onClick={() => {
                                    setFieldValue("openReservation", true)
                                    setFieldValue("closedReservation", false)
                                }}
                            />
                            <CLabel>Yes</CLabel>
                        </CCol>
                        <CCol>
                            <CInputRadio
                                style={{
                                    width: "1.25em",
                                    height: "1.25em",
                                    fontSize: "10px",
                                }}
                                checked={values.closedReservation}
                                onClick={() => {
                                    setFieldValue("openReservation", false)
                                    setFieldValue("closedReservation", true)
                                }}
                            />
                            <CLabel>No</CLabel>
                        </CCol>
                        <CCol>
                            <CInputRadio
                                style={{
                                    width: "1.25em",
                                    height: "1.25em",
                                    fontSize: "10px",
                                }}
                                checked={!values.openReservation && !values.closedReservation}
                                onClick={() => {
                                    setFieldValue("openReservation", false)
                                    setFieldValue("closedReservation", false)
                                }}
                            />
                            <CLabel>All</CLabel>
                        </CCol>
                    </CRow>
                </CCol>
            </CRow>
            <CRow>
                <CCol>
                    <SWSelect
                        label={"Type"}
                        name={"subType"}
                        value={values.subType}
                        values={props.typesLookup}
                        isClearable
                        onChange={(e) => setFieldValue("subType", e)}
                    />
                </CCol>
                <CCol>
                    <SWSelect
                        values={{
                            "eq": "Equal",
                            "lt": "Less Than",
                            "gt": "Greater Than",
                            "rg": "Range"
                        }}
                        label={"Date"}
                        value={values.DateTypeFilter}
                        onChange={v => setFieldValue("DateTypeFilter", v)}
                    />
                </CCol>
                {values.DateTypeFilter == "eq" &&
                    <CCol style={{marginTop: "28px"}}>
                        <SWDateAndTimePicker value={values.on} handleOnChange={e => {
                            setFieldValue("on", e)
                            setFieldValue("onFrom", "")
                            setFieldValue("onTo", "")
                        }}/>
                    </CCol> ||
                    values.DateTypeFilter == "lt" &&
                    <CCol style={{marginTop: "28px"}}>
                        <SWDateAndTimePicker value={values.onTo} handleOnChange={e => {
                            setFieldValue("on", "")
                            setFieldValue("onFrom", "")
                            setFieldValue("onTo", e)
                        }}/>
                    </CCol> ||
                    values.DateTypeFilter == "gt" &&
                    <CCol md={3} style={{marginTop: "28px"}}>
                        <SWDateAndTimePicker value={values.onFrom} handleOnChange={e => {
                            setFieldValue("on", "")
                            setFieldValue("onFrom", e)
                            setFieldValue("onTo", "")
                        }
                        }/>
                    </CCol> ||
                    values.DateTypeFilter == "rg" &&
                    <CCol style={{marginTop: "28px"}}>
                        <DateRangeFilter value={`date|${values.onFrom}|${values.onTo}`} onChange={val => {
                            let dates = val.split("|")
                            setFieldValue("onFrom", dates[1])
                            setFieldValue("onTo", dates[2])
                            setFieldValue("on", "")
                        }}
                        />
                    </CCol>}
                <CCol/>
                <CCol md={3}>
                    <CRow style={{marginLeft:"1px"}}>
                        <CLabel>Posted</CLabel>
                    </CRow>
                    <CRow style={{marginLeft:"6px"}}>
                        <CCol>
                            <CInputRadio
                                style={{
                                    width: "1.25em",
                                    height: "1.25em",
                                    fontSize: "10px",
                                }}
                                checked={values.postedOnly}
                                onClick={() => {
                                    setFieldValue("postedOnly", true)
                                    setFieldValue("draftOnly", false)
                                }}
                            />
                            <CLabel>Yes</CLabel>
                        </CCol>
                        <CCol>
                            <CInputRadio
                                style={{
                                    width: "1.25em",
                                    height: "1.25em",
                                    fontSize: "10px",
                                }}
                                checked={values.draftOnly}
                                onClick={() => {
                                    setFieldValue("postedOnly", false)
                                    setFieldValue("draftOnly", true)
                                }}
                            />
                            <CLabel>No</CLabel>
                        </CCol>
                        <CCol>
                            <CInputRadio
                                style={{
                                    width: "1.25em",
                                    height: "1.25em",
                                    fontSize: "10px",
                                }}
                                checked={!values.postedOnly && !values.draftOnly}
                                onClick={() => {
                                    setFieldValue("postedOnly", false)
                                    setFieldValue("draftOnly", false)
                                }}
                            />
                            <CLabel>All</CLabel>
                        </CCol>
                    </CRow>
                    <CRow style={{marginTop:"10px", marginLeft:"1px"}}>
                        <CLabel>Reversed</CLabel>
                    </CRow>
                    <CRow style={{marginLeft:"6px"}}>
                        <CCol>
                            <CInputRadio
                                style={{
                                    width: "1.25em",
                                    height: "1.25em",
                                    fontSize: "10px",
                                }}
                                checked={values.reversed}
                                onClick={() => {
                                    setFieldValue("reversed", true)
                                    setFieldValue("nullReversed", false)
                                }}
                            />
                            <CLabel>Yes</CLabel>
                        </CCol>
                        <CCol>
                            <CInputRadio
                                style={{
                                    width: "1.25em",
                                    height: "1.25em",
                                    fontSize: "10px",
                                }}
                                checked={values.nullReversed}
                                onClick={() => {
                                    setFieldValue("reversed", false)
                                    setFieldValue("nullReversed", true)
                                }}
                            />
                            <CLabel>No</CLabel>
                        </CCol>
                        <CCol>
                            <CInputRadio
                                style={{
                                    width: "1.25em",
                                    height: "1.25em",
                                    fontSize: "10px",
                                }}
                                checked={!values.reversed && !values.nullReversed}
                                onClick={() => {
                                    setFieldValue("reversed", false)
                                    setFieldValue("nullReversed", false)
                                }}
                            />
                            <CLabel>All</CLabel>
                        </CCol>
                    </CRow>
                </CCol>
            </CRow>
            <CRow style={{marginTop:"5px"}}>
                <CCol>
                    <CButton
                        size={"sm"}
                        style={{fontSize: "10px"}}
                        variant="outline"
                        color="danger"
                        onClick={() => {
                            resetForm();
                            props.onClearClicked();
                        }}
                    >
                        Clear all
                    </CButton>
                </CCol>
                <CCol className={"text-right"}>
                    <CButtonGroup className="mr-4">
                    {values.showDetails &&
                        <ExportToExcelButton getData={async () => {
                        return await handleExport()
                    }} fileNamePrefix={"Stock"}/>}
                    </CButtonGroup>
                    <CButtonGroup>
                        <ActionButton
                            text={"Search"}
                            onClick={handleSubmit}
                        />
                    </CButtonGroup>
                </CCol>
            </CRow>
        </fieldset>
    );
};

export default SearchForm;
