import {AccountFilterGroup, FilterGroup, IPaginatedResult} from "../models/filter";
import {toIndividualParam, toQueryParam} from "../utils/filterUtils";
import ClientWithErrorNotifier from "./clientWithErrorNotifier";
import {
    CalculateTaxRequest,
    ChargeAddUpdate,
    ChargeSearch,
    InvoiceDto,
    GeneratedInvoiceRequest,
    GenerateInvoiceRequest,
    PendingInvoicesRequest,
    PendingShipmentsRequest,
    PendingShipmentsResponse,
    InvoiceAttachmentType,
    InvoiceAttachmentAdd,
    InvoiceAttachmentUpdate,
    CreateInvoiceRequest,
    MarkInvoicePaidRequest,
    InvoiceBillingAddress,
    IssueInvoiceRequest,
    IgnoreInvoice,
    IssueCreditNoteRequest,
    BulkPayRequest,
    AllowedProfilesRequest,
    AllowedProfiles, ChargeSearchFilters, ChargeExport, ChargeIsGroupedSearch, SearchAccountsRequest,
} from "../models/accounting";
import {SearchModel, RemoteBlob} from "../models/app";
import {ApiResponse} from "@simplify9/simplyapiclient";
import {
    AddShipmentAttachmentRequest,
    IAddAccount, ShipmentAttachment,
} from "../models/shipment";
import {
    AccountAttachment,
    AddAccountAttachmentRequest, DeleteAccountAttachmentRequest,
    UpdateAccountAttachmentRequest
} from "../store/actions/accounts";

class AccountingClient {
    apiClient: ClientWithErrorNotifier;
    BaseUrl: string;
    pageSize: number;

    constructor() {
        this.BaseUrl = `${process.env.REACT_APP_API_BASE_URL}/accounting/api`;
        // this.BaseUrl = 'https://localhost:14185/accounting/api'
        // this.BaseUrl = `http://localhost:5000/accounting/api`;
        this.pageSize = 20;
        this.apiClient = new ClientWithErrorNotifier(this.BaseUrl);
    }

    async GetShipmentCharges(shipmentUid: string): Promise<ChargeSearch[]> {
        const rs = await this.apiClient.getAsync(`charges/${shipmentUid}/GetShipmentCharges`)
        return rs.data;
    }


    async getAttachmentTypeLookups(appliesOn: string) {
        const rs = await this.apiClient.getAsync(`accountattachments/types?AppliesOn=${appliesOn}&lookup=true`)
        return rs.data
    }

    async DeleteCharge(id: number): Promise<boolean> {
        const result = await this.apiClient.deleteAsync(`/charges/${id}`, null);
        return result.succeeded;
    }


    async AddCharge(charge: ChargeAddUpdate): Promise<number> {
        const result = await this.apiClient.postAsync(`/charges`, charge);
        return result.data;
    }

    async AddChargeToInvoice(invoiceId: number, chargeId: number) {
        const rs = await this.apiClient.postAsync(`/invoice/${invoiceId}/addcharge`, {chargeId: chargeId})

        return rs.data
    }

    async UpdateCharge(charge: ChargeAddUpdate): Promise<number> {
        const result = await this.apiClient.postAsync(`/charges/${charge.id}`, charge);
        return result.succeeded;
    }

    async SearchCharge(chargeFilter: ChargeSearchFilters): Promise<SearchModel<ChargeSearch>> {
        const query = toQueryParam(chargeFilter)
        const result = await this.apiClient.getAsync(`/charges?${query}`);
        return result.data;
    }

    async SearchChargeGrouped(chargeFilter: ChargeSearchFilters): Promise<SearchModel<ChargeIsGroupedSearch>> {
        const query = toQueryParam(chargeFilter)
        const result = await this.apiClient.getAsync(`/charges/searchGrouped?${query}`);
        return result.data;
    }

    async ChargeExcelExport(chargeExport: ChargeExport): Promise<RemoteBlob> {
        const result = await this.apiClient.postAsync(`/charges/excelExport`, chargeExport);
        return result.data;
    }

    async ChargeExcelGroupedExport(chargeExport: ChargeExport): Promise<RemoteBlob> {
        const result = await this.apiClient.postAsync(`/charges/groupedExcelExport`, chargeExport);
        return result.data;
    }

    async UploadFile(file: any): Promise<{ location: string }> {
        let formData = new FormData();

        formData.append("File", file);

        let res = await this.apiClient.postFormAsync(
            "attachments/Upload",
            formData
        );
        return res.data;
    }

    async GetPendingInvoice(body: PendingInvoicesRequest): Promise<ApiResponse> {
        const query = toQueryParam(body)
        const rs = await this.apiClient.getAsync(`invoice/pending?${query}`)

        return rs
    }

    async GetIgnoredInvoice(body: PendingInvoicesRequest): Promise<ApiResponse> {
        const query = toQueryParam(body)
        const rs = await this.apiClient.getAsync(`invoice/ignored?${query}`)

        return rs
    }

    async GetPendingCharges(body: PendingShipmentsRequest): Promise<PendingShipmentsResponse[]> {
        const query = toQueryParam(body)
        const rs = await this.apiClient.getAsync(`shipments/pending?${query}`)

        return rs.data
    }

    async GetGeneratedInvoices(body: GeneratedInvoiceRequest): Promise<IPaginatedResult<InvoiceDto>> {
        const query = toQueryParam(body)
        const rs = await this.apiClient.getAsync(`/invoice/generated?${query}`)

        return rs.data
    }

    async GetGeneratedInvoicesReport(body: GeneratedInvoiceRequest, reportId: number): Promise<RemoteBlob> {
        body.format = 2;
        body.reportId = reportId
        const query = toQueryParam(body)
        const rs = await this.apiClient.getAsync(`/invoice/generated?${query}`)

        return rs.data
    }

    async GetInvoice(id: string): Promise<InvoiceDto> {
        const rs = await this.apiClient.getAsync(`/invoice/${id}`)

        return rs.data
    }

    async Invoice(body: GenerateInvoiceRequest): Promise<{ quotationId: string, invoiceId: string }> {
        const query = toQueryParam(body)
        const rs = await this.apiClient.getAsync(`/invoice?${query}`)

        return rs.data
    }

    async GetExcelInvoiceDescription(body: GenerateInvoiceRequest): Promise<RemoteBlob> {
        const rs = await this.apiClient.postAsync(`/charges/excel`, body)

        return rs.data
    }

    async CalculateTaxes(body: CalculateTaxRequest) {
        const rs = await this.apiClient.postAsync(`/charges/calculateTax`, body)

        return rs.data;
    }

    async GetInvoiceAttachment(id: string, type: InvoiceAttachmentType, language?: string): Promise<RemoteBlob> {
        const rs = await this.apiClient.postAsync(`/invoice/${id}/getFile`, {type: type, language: language})

        return rs.data
    }

    async DeleteInvoice(invoiceId: string): Promise<ApiResponse> {
        const rs = await this.apiClient.deleteAsync(`/invoice/${invoiceId}`, {})

        return rs
    }

    async IssueInvoice(invoiceId: string, body: IssueInvoiceRequest): Promise<ApiResponse> {
        const rs = await this.apiClient.postAsync(`/invoice/${invoiceId}/issue`, body)

        return rs
    }

    async DetachCharge(chargeId: number) {
        const rs = await this.apiClient.postAsync(`/invoice/removecharge`, {chargeId: chargeId})

        return rs.succeeded
    }

    async AddInvoiceAttachment(body: InvoiceAttachmentAdd) {
        const rs = await this.apiClient.postAsync("/invoiceAttachments", body)

        return rs.data
    }

    async UpdateInvoiceAttachment(attachmentId: number, body: InvoiceAttachmentUpdate) {
        const rs = await this.apiClient.postAsync(`/invoiceAttachments/${attachmentId}`, body)

        return rs.succeeded
    }

    async DeleteInvoiceAttachment(attachmentId: number) {
        const rs = await this.apiClient.deleteAsync(`/invoiceAttachments/${attachmentId}`, {})

        return rs.succeeded
    }

    async LookupProfiles(): Promise<{ [k: string]: string }> {
        const rs = await this.apiClient.getAsync("/profiles?lookup=true")

        return rs.data
    }

    async SendInvoice(id: number) {
        const rs = await this.apiClient.postAsync(`/invoice/${id}/send`, {})

        return rs.data
    }

    async CreateInvoice(body: CreateInvoiceRequest) {
        const rs = await this.apiClient.postAsync("/invoice/createDraft", body)

        return rs.data
    }

    async DeleteShipmentCharges(uid: string, systemOnly: boolean) {
        const rs = await this.apiClient.postAsync(`/shipments/${uid}/deleteCharges`, {systemOnly: systemOnly})

        return rs.succeeded
    }

    async MarkAsPaid(id: number, body: MarkInvoicePaidRequest) {
        const rs = await this.apiClient.postAsync(`/invoice/${id}/markPaid`, body)

        return rs.succeeded
    }

    async GetAccount(id: string) {
        const rs = await this.apiClient.getAsync(`/account/${id}`)

        return rs.data
    }

   

    async SearchAccount(): Promise<any> {
        const rs = await this.apiClient.getAsync(`/account`)
        return rs.data;
    }

    async CreateAccount(body: IAddAccount) {
        const rs = await this.apiClient.postAsync(`/Account`, body)

        return rs.data

    }

    async UpdateAccount(body: IAddAccount) {
        const rs = await this.apiClient.postAsync(`/Account/Update`, body)

        return rs.data

    }

    async UpdateBillingAddress(invoiceId: number, billingAddress: InvoiceBillingAddress) {
        const rs = await this.apiClient.postAsync(`/invoice/${invoiceId}/updateBillingAddress`, billingAddress)

        return rs.succeeded;
    }

    async ModifyIgnoreInvoice(request: IgnoreInvoice) {
        const rs = await this.apiClient.postAsync("/invoice/modifyIgnore", request);

        return rs.succeeded;
    }

    async CreditNote(request: IssueCreditNoteRequest) {
        const rs = await this.apiClient.postAsync("/invoice/creditNote", request)

        return rs.data
    }

    async BulkPayment(body: BulkPayRequest) {
        const rs = await this.apiClient.postAsync("/invoice/bulkPayment", body)

        return rs
    }

    async GetAllowedProfiles(body: AllowedProfilesRequest): Promise<AllowedProfiles> {
        const query = toQueryParam(body)
        const rs = await this.apiClient.getAsync(`profiles/getAllowed?${query}`)
        return rs.data
    }


    async GetAccounts(profileId: string) {
        const rs = await this.apiClient.getAsync(`profiles/${profileId}/getaccounts`)

        return rs.data
    }
    async updateAttachment(uid: string, body: UpdateAccountAttachmentRequest): Promise<AccountAttachment> {
        const rs = await this.apiClient.postAsync(`/accountattachments/${uid}/update`, body)

        return rs.data
    }

    async deleteAttachment(id: string, body: DeleteAccountAttachmentRequest) {
        return await this.apiClient.postAsync(`/accountattachments/${id}/delete`, body)
    }

    async addAttachment(uid: string, body: AddAccountAttachmentRequest): Promise<AccountAttachment> {
        const res = await this.apiClient.postAsync(`/accountattachments/${uid}/add`, body)

        return res.data
    }

    async searchAttachment(appliesOn: string, accountId: string) {
        const res = await this.apiClient.getAsync(`/accountattachments?accountId=${accountId}&appliesOn=${appliesOn}`, {})

        return res.data
    }

    async addBulkAttachment(id: string, body: AddAccountAttachmentRequest[]): Promise<ShipmentAttachment> {
        const res = await this.apiClient.postAsync(`/accountattachments/${id}/AddBulk`, body)
        return res.data
    }
}

export default AccountingClient;
