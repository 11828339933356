export const QuotationSearchInitalState = {
    filters: [
        {
            name: "invoiceAndCreditNote",
            type: "0",
            value: ""
        },
        {
            name:"Id",
            type:"0",
            value:""
        },
        {
            name:"ProfileId",
            type:"0",
            value:""
        },
        {
            name:"HasShipment",
            type:"0",
            value:""
        },
        {
            name:"ShipmentNumber",
            type:"8",
            value:""
        },
        {
            name:"ContactInfo",
            type:"1",
            value:""
        },
        {
            name:"AccountNumber",
            type:"0",
            value:""
        },
        {
            name:"Status",
            type:"0",
            value:""
        },
        {
            name:"CompanyName",
            type:"",
            value:""
        },
        {
            name:"HasInvoice",
            type:"0",
            value:""
        },
        {
            name:"IsPaid",
            type:"0",
            value:""
        },
        {
            name:"Processed",
            type:"0",
            value:""
        },
    ]
}