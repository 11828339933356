import { UserSearch } from "../../models/user";
import { Filter, FilterGroup } from "../../models/filter";
import {
  SET_USER_FILTER_GROUP,
  SET_USER_IS_LOADING,
  SET_USER_RESULT,
} from "../types";

export const SetUserPage = (page: number) => {
  return {
    type: SET_USER_FILTER_GROUP,
    payload: {
      page: page,
    },
  };
};

export const SetUserIsLoading = (loading: boolean) => {
  return {
    type: SET_USER_IS_LOADING,
    payload: {
      isLoading: loading,
    },
  };
};

export const SetUsersFilterGroupFilters = (filters: Filter[]) => {
  return {
    type: SET_USER_FILTER_GROUP,
    payload: {
      page: 0,
      filters: filters,
    },
  };
};

export const SetUsersFilterGroup = (filterGroup: FilterGroup) => {
  return {
    type: SET_USER_FILTER_GROUP,
    payload: filterGroup,
  };
};

export const SetUserResult = (
  page: number,
  count: number,
  result: UserSearch[],
  error?: string
) => {
  return {
    type: SET_USER_RESULT,
    payload: {
      count: count,
      result: result,
      error: error,
    },
  };
};
