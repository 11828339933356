import CookieManager from "../services/cookieManager";

import {ClientFactory} from "@simplify9/simplyapiclient";
import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import {RateItem, RateSearchRequest, SearchRateCardModel, RateExportRequest, RateDataImportRequest, ListFscRequest, UpdateAgentFscRequest, RateQuery, ServiceAdd, RateUpsertSelling, RateUpsertCost} from "../models/retes";

class RatesClient {


    client: IClient;
    BaseUrl: string;
    pageSize: number;

    constructor() {
        this.BaseUrl = `${process.env.REACT_APP_API_BASE_URL}/rates/api`;
        this.client = ClientFactory({
            baseUrl: this.BaseUrl,
            authType: "bearer",
            getBearer: () => CookieManager.getAccessToken(),
        });
        this.pageSize = 20;
    }

    async LookupCustomers(): Promise<{ [key: string]: string }> {
        const rs = await this.client.SimplyGetAsync("/customers?format=1");
        return rs.data ?? {};
    }

    async LookupAgents(): Promise<{ [k: string]: string }> {
        const rs = await this.client.SimplyGetAsync("/agents?format=1");
        return rs.data ?? {}
    }

    async SearchSelling(search?:RateSearchRequest) {
        const rs = await this.client.SimplyPostAsync("/rates/searchselling", search ? search : {});
        return rs;
    }

    async SearchCosts(search?:RateSearchRequest) {
        const rs = await this.client.SimplyPostAsync("/rates/searchcost", search ? search : {});
        return rs
    }

    async GetSelling(cardId: number): Promise<RateItem> {
        const rs = await this.client.SimplyGetAsync(`/rates/${cardId}`);
        return rs.data
    }

    async GetCost(cardId: number): Promise<RateItem> {
        const rs = await this.client.SimplyGetAsync(`/rates/${cardId}/getcost`);
        return rs.data
    }

    async GetCustomer(id: string) {
        const rs = await this.client.SimplyGetAsync(`/accounts/${id}`);
        return rs.data ?? {};
    }

    async UpsertCost(data:Partial<RateUpsertCost>){
        const rs = await this.client.SimplyPostAsync(`/rates/upsertcost`,data);
        return rs
    }
    async UpsertSelling(data:Partial<RateUpsertSelling>){
        const rs = await this.client.SimplyPostAsync(`/rates/upsertselling`,data);
        return rs
    }
    async GetServices() {
        const rs = await this.client.SimplyGetAsync(`/services/list?format=1`);
        return rs.data ?? {};
    }
    async GetAgentsWithServicesList() {
        const rs = await this.client.SimplyGetAsync(`/services/getagentlist`);
        return rs.data ?? {};
    }
    async GetAgentServices(agentCode:string) {
        const rs = await this.client.SimplyGetAsync(`/services/${agentCode}/getagentservices`);
        return rs.data ?? {};
    }
    async AddAgentService(data:ServiceAdd,agentCode:string){
        const rs = await this.client.SimplyPostAsync(`/services/${agentCode}/addagentservice`,data);
        return rs
    }
    async GetAccounts() {
        const rs = await this.client.SimplyGetAsync(`/accounts/?format=1`);
        return rs.data ?? {};
    }

    async ExportRatesData(data:RateExportRequest) {
        const rs = await this.client.SimplyPostAsync("/rates/exportdata", data);
        return rs.data ?? []
    }

    async ImportRatesData(data:RateDataImportRequest) {
        const rs = await this.client.SimplyPostAsync("/rates/importratedata", data);
        return rs.data ?? []
    }
    async ListFsc(data?:ListFscRequest) {
        const rs = await this.client.SimplyPostAsync("/agents/listfsc", {});
        return rs.data ?? []
    }
    async UpdateAgentFsc(id:string,data:UpdateAgentFscRequest) {
        const rs = await this.client.SimplyPostAsync(`/rates/${id}/updateagentfsc`, data);
        return rs
    }

    async Calculate(data:RateQuery) {
        const rs = await this.client.SimplyPostAsync(`/rates/calculate`, data);
        return rs
    }

}

export default RatesClient;
