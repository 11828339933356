import React, { useEffect, useState } from "react";
import { CDataTable, CLink, CTooltip } from "@coreui/react";
import { ContainerSearch, ContainerShipmentSearch } from "../../../../../models/container";
import {  formatDate } from "../../../../../utils/dateUtil";
import CIcon from "@coreui/icons-react";
import { freeSet } from "@coreui/icons";
import PageCount from "../../../../SharedComponents/PageCount";
import { useDispatch, useSelector } from "react-redux";
import { AppModel, SearchyResult } from "../../../../../models/app";
import ShipClient from "../../../../../clients/shipClient";
import { ShowNotification } from "../../../../../store/actions/auth";
import { FilterGroup } from "../../../../../models/filter";
import { SetIsLoading } from "../../../../../store/actions/ui";
import ProgressBar from "../../../../SharedComponents/ProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ErrorsModal from "../modals/ErrorsModal";

interface IProps {
    refreshGeneratedShipmentsTable?: boolean

}
const GeneratedShipmentTable = ({ refreshGeneratedShipmentsTable }: IProps) => {
    const labelGenerationContainerId = process.env.REACT_APP_LABEL_GENERATION_CONTAINER_ID ?? "6"
    const shipClient = new ShipClient();
    const dispatch = useDispatch();
    const isLoading = useSelector((state: AppModel) => state.app.isLoading);
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
    const [errorsModal, setErrorsModal] = useState<{ data: ContainerShipmentSearch[], isOpen: boolean } | null>(null);
    const [filterGroup, setFilterGroup] = useState<FilterGroup>({
        page: 0,
        pageSize: 20,
        limit: 20
    });
    const [data, setData] = useState<SearchyResult<ContainerSearch>>();
    const [firstTimeFetchData,setFirstTimeFetchData]=useState<boolean>(true);

    const fetchData = async (filterGroup: FilterGroup) => {
        if(firstTimeFetchData) {
            dispatch(SetIsLoading(true))
            setFirstTimeFetchData(false)
        }
        const res = await shipClient.SearchContainers(filterGroup, labelGenerationContainerId);
        setData(res)
    };

    useEffect(() => {
        fetchData(filterGroup)
    }, [refreshGeneratedShipmentsTable]);


    
    

    useEffect(() => {
        if (data?.result?.some((item) => item?.shipments?.find((e) => e?.processing))) {
            const id = setInterval(() => {
                fetchData(filterGroup);
            }, 5000);
            setIntervalId(id);
        } else {
            if (intervalId !== null) {
                clearInterval(intervalId);
            }
            setIntervalId(null);
        }
        return () => {
            if (intervalId !== null) {
                clearInterval(intervalId);
            }
            setIntervalId(null);
        };
    }, [data?.result]);


    useEffect(() => {
        return () => {
            if (intervalId !== null) {
                clearInterval(intervalId);
                setIntervalId(null);
            }
        };
    }, []);


    const scopedSlots = {
        records: (e: ContainerSearch) => {
            return <td>
                {e.totalShipments ?? "-"}
            </td>
        },
        actions: (e: ContainerSearch) => {
            return <td className="" style={{ display: "flex", flexDirection: "row" }}>
                <CLink to={`/containers/${e?.id}?isEdit=false`} className="card-header-action">
                    <CTooltip content={"View Container"}>
                        <FontAwesomeIcon
                            icon={faEye}
                        />
                    </CTooltip>
                </CLink>
                {e?.shipments?.some((i) => i?.processing) &&
                    <CIcon
                        content={freeSet.cilSync}
                        className={"ml-2 rotate-icon"}
                    />
                }
            </td>
        },
        openedOn: (e: ContainerSearch) => {
            return <td>{formatDate(e.openedOnFormatted)}</td>
        },
        account: (e: ContainerSearch) => {
            return <td>
                {e.shipments?.[0]?.accountName ?? "-"}
            </td>
        },
        success: (e: ContainerSearch) => {
            return <td>
                {e.shipments?.filter((e) => e?.number && !e?.processing)?.length ?? "-"}
            </td>
        },

        progress: (e: ContainerSearch) => {
            const successfulShipments = e?.shipments.filter(item => !item.processing)?.length;
            const total = e?.shipments?.length;
            return <td>
                <ProgressBar value={Number(((successfulShipments / total) * 100)?.toFixed(2))} />
            </td>
        },
        error: (e: ContainerSearch) => {
            const shipmentWithErrors = e?.shipments?.filter((e) => e?.errors && e?.errors?.length > 0)
            return <td>
                {shipmentWithErrors?.length > 0 &&
                    <CTooltip content={"View Errors"}>
                        <FontAwesomeIcon
                            tabIndex={-1}
                            onClick={() => setErrorsModal({ data: shipmentWithErrors, isOpen: true })}
                            icon={faInfoCircle}
                            size="lg"
                            color={"red"}
                            style={{ cursor: "pointer" }}
                        />
                    </CTooltip>}
            </td>
        },
    };

    return (
        <>
            <CDataTable
                loading={isLoading}
                size="sm"
                hover
                scopedSlots={scopedSlots}
                items={data?.result || []}
                fields={
                    [
                        { key: "actions", label: "", _style: { width: "1%" } },
                        { key: "error", label: "", _style: { width: "1%" } },
                        { key: "openedOn", label: "Date" },
                        { key: "account", label: "Account" },
                        { key: "records", label: "Shipments" },
                        { key: "success", label: "Success" },
                        { key: "progress", label: "Progress", _style: { textAlign: "center" } },
                        { key: "createdBy", label: "User" },
                    ]
                }
            />
            <PageCount
                maxFound={data?.totalCount ?? 0}
                onPageChange={(p) => {
                    setFilterGroup({ ...filterGroup, page: p })
                    fetchData({ ...filterGroup, page: p })
                }}
                onPageSizeChange={(ps) => {
                    setFilterGroup({ ...filterGroup, limit: ps })
                    fetchData({ ...filterGroup, limit: ps })
                }}
                currentPage={filterGroup?.page ?? 1}
                maxPage={Math.ceil(data?.totalCount! / filterGroup?.limit!)}
            />
            <ErrorsModal
                onClose={() => setErrorsModal(null)}
                data={errorsModal?.data}
                isOpen={errorsModal?.isOpen}

            />
        </>
    )
}

export default GeneratedShipmentTable