import {
  Action,
  AuthenticationAppModel,
  SortingRuleAppModel,
} from "../../models/app";
import initialState from "../initialStates/sortingRuleInitialState";
import {
  SET_SORTINGRULE_FILTER_GROUP,
  SET_SORTINGRULE_IS_LOADING,
  SET_SORTINGRULE_RESULT,
} from "../types";
import { SortingRuleSearch } from "../../models/sortingrule";

const sortingruleReducer = (
  state: SortingRuleAppModel = initialState,
  action: Action
): SortingRuleAppModel => {
  switch (action.type) {
    case SET_SORTINGRULE_FILTER_GROUP:
      return {
        ...state,
        sortingrulesSearch: {
          ...state.sortingrulesSearch,
          filterGroup: {
            ...state.sortingrulesSearch.filterGroup,
            ...action.payload!,
          },
        },
      };

    case SET_SORTINGRULE_IS_LOADING:
    case SET_SORTINGRULE_RESULT:
      return {
        ...state,
        sortingrulesSearch: {
          ...state.sortingrulesSearch,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default sortingruleReducer;
