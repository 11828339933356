import {useEffect, useState} from "react";
import {AddressBook} from "../../../../models/addressBook";
import ShipClient from "../../../../clients/shipClient";
import AddressBookTable from "./AddressBookTable";
import ConfirmModal from "../../../SharedComponents/ConfirmModal";
import AddressBookEditOrAddModal from "./AddressBookEditOrAddModal";

type Props = {
    accountId: string
    countryLookups: { [k: string]: string }

}
const AddressBookSection = ({accountId, countryLookups}: Props) => {
    const shipClient = new ShipClient()
    const [addressBook, setAddressBook] = useState<AddressBook[]>([])
    const [openModal, setOpenModal] = useState<{ type: 'EDIT' | 'ADD' | 'DELETE', id: string | null } | null>(null)
    const [loader, setLoader] = useState(false)
    const fetchData = async () => {
        const res = await shipClient.SearchAddressBook({account: accountId, lookup: false})
        if (res)
            setAddressBook(res)
    }
    const handleClick = (e: { type: 'EDIT' | 'DELETE' | 'ADD', id: string | null }) => {
        setOpenModal(e)
    }
    const handleDelete = async () => {
        setLoader(true)
        const res = await shipClient.DeleteAddressBook(openModal?.id!)
        if (res) {
            await fetchData()
            setOpenModal(null)
        }
        setLoader(false)
    }

    const handleEditOrCreate = async (data: AddressBook) => {
        setLoader(true)
        let res;
        if (openModal?.type == 'EDIT')
            res = await shipClient.UpdateAddressBook({...data, account: accountId})
        else res = await shipClient.AddAddressBook({...data, account: accountId})
        if (res) {
            await fetchData()
            setOpenModal(null)
        }
        setLoader(false)
    }


    useEffect(() => {
        fetchData().then()
    }, []);


    return (
        <>
            <AddressBookTable data={addressBook} handleClick={handleClick}/>
            {openModal?.type === 'DELETE' &&
                <ConfirmModal submitting={loader} onSubmit={handleDelete} onClose={() => setOpenModal(null)}
                              title={'Delete address'}
                              body={'Are you sure you want to delete'}/>}
            {(openModal?.type === 'EDIT' || openModal?.type == 'ADD') &&
                <AddressBookEditOrAddModal countryLookups={countryLookups} submit={handleEditOrCreate}
                                           onClose={() => setOpenModal(null)}
                                           data={openModal.id ? addressBook.find((a) => openModal.id == a.id?.toString()) : {}}
                                           type={openModal.type}/>}
        </>
    )
}

export default AddressBookSection
