import {CButton, CButtonGroup, CCol, CInputCheckbox, CLabel, CRow,} from "@coreui/react";
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {AllocationStrategy, SkuSearch, SkuSearchFilter, warehouse} from "../../../../../models/warehouse";
import SWSelect from "../../../../SharedComponents/SWSelect";
import SWInput from "../../../../SharedComponents/SWInput";
import {getAllocationStrategyLoookup} from "../../../../../utils/lookupUtils";
import {useLocation} from "react-router"
import {querytoMap} from "../../../../../utils/filterUtils";
import WarehouseClient from "../../../../../clients/warehousesClient";
import ExportToExcelButton from "../../../../SharedComponents/ExportToExcelButton";
import SWDateAndTimePicker from "../../../../SharedComponents/SWDateAndTimePicker";
import {RemoteBlob} from "../../../../../models/app";
import ActionButton from "../../../../SharedComponents/ActionButton";

interface Props {
    onSearchClicked: (filter: SkuSearchFilter) => void
    onClearClicked: () => void
    accountsLookup: { [k: string]: string }
    showDetails: boolean
}

const SearchForm = (props: Props) => {
    let loc = useLocation();
    useEffect(() => {
        loadLookups();
    }, [props.accountsLookup]);
    const [sellersLookup, setSellersLookup] = useState<{ [k: string]: string }>({});
    const [warehouseLookup, setWarehouseLookup] = useState<{ [K: string]: string }>({})
    const [warehouses, setWarehouses] = useState<warehouse[]>([])
    const [locationLookup, setLocationLookup] = useState<{ [K: string]: string }>({})
    const [skuLookup, setSkuLookup] = useState<{ [k: string]: string }>({})

    const loadLookups = async () => {
        const client = new WarehouseClient();

        const sellers = await client.SearchSellers();
        if (!Array.isArray(sellers)) {
            return
        }
        sellers?.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        })
        let lookups: { [k: string]: string } = {}
        sellers.forEach(s => lookups[` ${s.id}`] = s.name)
        setSellersLookup(lookups);

        const warehouses = await client.searchWarehouse()
        setWarehouses(warehouses)

        const warehouseLookups = await client.warehousesLookup()
        setWarehouseLookup(warehouseLookups)

        const skuResult = await client.SearchSku({limit: 30000})
        const skus: SkuSearch[] = skuResult.data.matches
        const skuLookup: { [k: number]: string } = {}
        skus.forEach(s => {
            let warehouse = '';
            if (s.allowedWarehouses?.length > 0) {
                let selectedWarehouse = warehouses.find(w => w.id == s.allowedWarehouses[0])
                warehouse = selectedWarehouse?.code ?? ""
            } else if (s.warehouse)
                warehouse = s.warehouse
            skuLookup[s.id] = `${warehouse ? warehouse + " - " : ""}${s.code} - ${s.name} - ${props.accountsLookup[s.accountNumber]}`
        })
        setSkuLookup(skuLookup)
    };

    const queryMap = querytoMap<SkuSearchFilter>(loc.search);

    useEffect(() => {
        setFieldValue("showDetails", props.showDetails)
    }, [props.showDetails])

    const {setValues, values, handleChange, handleSubmit, setFieldValue, resetForm} = useFormik<SkuSearchFilter>({
        initialValues: {
            id: "",
            includeInactive: true,
            internalCode: queryMap.internalCode ?? "",
            code: queryMap.code ?? "",
            codeAndName: queryMap.codeAndName ?? "",
            barcode: queryMap.barcode ?? "",
            seller: queryMap.seller ?? "",
            accountNumber: queryMap.accountNumber ?? "",
            name: queryMap.name ?? "",
            allocationStrategy: queryMap.allocationStrategy,
            batchNumber: queryMap.batchNumber ?? "",
            ItemNumber: queryMap.ItemNumber ?? "",
            warehouse: queryMap.warehouse ?? "",
            location: queryMap.location ?? "",
            ExportToExcel: queryMap.ExportToExcel ?? false,
            inActive: queryMap.inActive ?? false,
            showDetails: queryMap.showDetails ?? false
        },
        onSubmit: props.onSearchClicked
    })


    useEffect(() => {
        setValues({...queryMap})
    }, [loc.search]);

    const handleWarehouseChange = async (w?: string) => {
        await setFieldValue("warehouse", w)
        await setFieldValue("location", null)
        if (w) {
            let client = new WarehouseClient()
            let data = await client.GetWarehouseWithLookups(w)
            setLocationLookup(data.locationLookup)
        } else {
            setLocationLookup({})
        }
    }

    // @ts-ignore
    const handleExport: () => Promise<RemoteBlob> = async () => {
        let client = new WarehouseClient()
        if (values.showDetails) {
            let res = await client.SearchSkuDetails({...queryMap, ExportToExcel: true})
            return res.data
        } else
            return await client.SearchSkuExcel(queryMap)
    }

    const handleAccountChange = async (e?: string) => {
        await setFieldValue("accountNumber", e)
        await setFieldValue("id", "")
        let client = new WarehouseClient()
        let skuResult = await client.SearchSku({limit: 30000, accountNumber: e})
        const skus: SkuSearch[] = skuResult.data.matches
        const skuLookup: { [k: number]: string } = {}
        skus.forEach(s => {
            let warehouse = '';
            if (s.allowedWarehouses?.length > 0) {
                let selectedWarehouse = warehouses.find(w => w.id == s.allowedWarehouses[0])
                warehouse = selectedWarehouse?.code ?? ""
            } else if (s.warehouse)
                warehouse = s.warehouse
            skuLookup[s.id] = `${warehouse ? warehouse + " - " : ""}${s.code} - ${s.name} - ${props.accountsLookup[s.accountNumber]}`
        })
        setSkuLookup(skuLookup)
    }

    return (
        <fieldset className="b-fieldset p-2 text-primary">
            <legend className="b-legend" style={{width: "48px"}}>
                <h5>SKUs</h5>
            </legend>
            <CRow>
                <CCol md={3}>
                    <SWSelect label={"Account"} values={props.accountsLookup as { [k: string]: string }}
                              value={values.accountNumber} name={"accountNumber"} isClearable
                              onChange={async (e) => {
                                  handleAccountChange(e)
                              }}/>
                </CCol>
                <CCol md={3}>
                    <SWSelect values={skuLookup} value={values.id} name={"id"} label={"Name - Code"}
                              onChange={(e) => setFieldValue("id", e)} isClearable/>
                </CCol>
                <CCol md={3}>
                    <SWInput label={"Barcode"} name={"barcode"} value={values.barcode} onChangeFormik={handleChange}/>
                </CCol>
                <CCol/>
                <CCol md={1}>
                    <CRow><CInputCheckbox checked={!values.inActive}
                                          onChange={() => setFieldValue("inActive", !values.inActive)}/>
                        <CLabel>Active</CLabel>
                    </CRow>
                </CCol>
            </CRow>
            <CRow>
                <CCol md={3}>
                    <SWSelect value={values.warehouse} label={"Warehouse"} values={warehouseLookup}
                              onChange={handleWarehouseChange} isClearable/>
                </CCol>
                <CCol md={3}>
                    <SWInput label={"Internal Code"} name={"internalCode"} value={values.internalCode}
                             onChangeFormik={handleChange}/>
                </CCol>
                <CCol md={3}>
                    <SWSelect label={"Seller"} values={sellersLookup} value={values.seller}
                              name={"seller"} onChange={(e) => setFieldValue("seller", e)} isClearable/>
                </CCol>
            </CRow>
            <CRow>
                <CCol md={3}>
                    <SWSelect value={values.location} label={"Location"} values={locationLookup}
                              onChange={l => setFieldValue("location", l)} isClearable/>
                </CCol>
                <CCol md={3}>
                    <SWSelect label={"Allocation Strategy"} isClearable
                              values={getAllocationStrategyLoookup() as { [k: string]: string }}
                              value={values.allocationStrategy?.toString()} name={"allocationStrategy"}
                              onChange={(e) => setFieldValue("allocationStrategy", e)}/>
                </CCol>
                {values.allocationStrategy == AllocationStrategy.ExpiryDate &&
                    <CCol md={3}>
                        <SWDateAndTimePicker handleOnChange={e => setFieldValue("expiryDate", e)} label={"Expiry Date"}
                                             name={"expiryDate"} value={values.expiryDate}/>
                    </CCol>
                }
                {(values.allocationStrategy == AllocationStrategy.BatchNumber || values.allocationStrategy == AllocationStrategy.ExpiryDate) &&
                    <>
                        <CCol md={3}>
                            <SWInput label={"Batch Number"} name={"batchNumber"} value={values.batchNumber}
                                     onChangeFormik={handleChange}/>
                        </CCol>
                        <CCol md={3}>
                            <SWInput label={"Batch Barcode"} name={"ItemNumber"} value={values.ItemNumber}
                                     onChangeFormik={handleChange}/>
                        </CCol>
                    </>}
            </CRow>
            <CRow>
                <CCol>
                    <CButton
                        size={"sm"}
                        style={{fontSize: "10px"}}
                        variant="outline"
                        color="danger"
                        onClick={() => {
                            resetForm();
                            props.onClearClicked()
                        }}
                    >
                        Clear all
                    </CButton>
                </CCol>
                <CCol md={2} className={"text-right"}>
                    <CButtonGroup className="mr-4">
                        <ExportToExcelButton
                            getData={handleExport}
                            fileNamePrefix={"Skus"}
                        />
                    </CButtonGroup>
                    <CButtonGroup>
                        <ActionButton
                            text={"Search"}
                            onClick={handleSubmit}
                        />
                    </CButtonGroup>
                </CCol>
            </CRow>
        </fieldset>
    );
};

export default SearchForm;
