import {
  Action,
  AuthenticationAppModel,
  ShipmentAppModel,
} from "../../models/app";
import initialState from "../initialStates/shipmentInitialState";
import {
  SET_SHIPMENT_FILTER_GROUP,
  SET_SHIPMENT_IS_LOADING,
  SET_SHIPMENT_RESULT,
  TOGGLE_SHIPMENT_VIEW,
  SET_SHIPMENT_REPRINT_FILTER_GROUP,
  SET_SHIPMENT_REPRINT_RESULT,
  SET_SHIPMENT_REPRINT_IS_LOADING,
  SET_CREATE_SHIPMENT_DATA
} from "../types";
import { ShipmentSearch } from "../../models/shipment";

function toggleDetails<T>(details: T[], index: T): T[] {
  const position = details.indexOf(index);
  let newDetails = details.slice();
  if (position !== -1) {
    newDetails.splice(position, 1);
  } else {
    newDetails = [...details, index];
  }
  return newDetails;
}

const shipmentReducer = (
  state: ShipmentAppModel = initialState,
  action: Action
): ShipmentAppModel => {
  switch (action.type) {
    case SET_SHIPMENT_FILTER_GROUP:
      return {
        ...state,
        shipmentsSearch: {
          ...state.shipmentsSearch,
          filterGroup: {
            ...state.shipmentsSearch.filterGroup,
            ...action.payload!,
          },
        },
      };

    case SET_SHIPMENT_IS_LOADING:
    case SET_SHIPMENT_RESULT:
      return {
        ...state,
        shipmentsSearch: {
          ...state.shipmentsSearch,
          ...action.payload,
        },
      };
    case TOGGLE_SHIPMENT_VIEW:
      //const detailedShown = toggleDetails(state.shipmentsSearch.detailedShown ?? [], action.payload);

      return {
        ...state,
        shipmentsSearch: {
          ...state.shipmentsSearch,
          view: action.payload as ShipmentSearch,
          toggleView: !state.shipmentsSearch.toggleView,
        },
      };

      case SET_SHIPMENT_REPRINT_FILTER_GROUP:
        return {
          ...state,
          shipmentReprintSearch: {
            ...state.shipmentReprintSearch,
            filterGroup: {
              ...state.shipmentReprintSearch.filterGroup,
              ...action.payload!,
            },
          },
        };
      case SET_SHIPMENT_REPRINT_IS_LOADING:
      case SET_SHIPMENT_REPRINT_RESULT:
        return {
          ...state,
          shipmentReprintSearch: {
            ...state.shipmentReprintSearch,
            ...action.payload,
          },
        };
        case SET_CREATE_SHIPMENT_DATA:
          return {
            ...state,
            createShipmentData: action.payload?.data,
          };
    default:
      return state;
  }
};

export default shipmentReducer;
