import {CButton, CCol, CContainer, CModal, CModalBody, CModalHeader, CRow, CTooltip} from "@coreui/react";
import React, {useState} from "react";
import ShipClient from "../../../../clients/shipClient";
import {useDispatch} from "react-redux";
import {ShowNotification} from "../../../../store/actions/auth";
import {downloadBlob} from "../../../../utils/downloadUtils";
import ActionButton from "../../../SharedComponents/ActionButton";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";

interface IProps {
    isShowing: boolean;
    onClose: () => any;
    data: {
        name: string
        account: string;
        consigneeCountry: string | null;
        agent: string | null | number;
        pickupDate: Date;
    }[]
    setIsSubmitting: any
    onDone: () => void

}

const GenerateMonthReportArrayModal = (props: IProps) => {
    const shipClient = new ShipClient();
    const dispatch = useDispatch()
    const {isShowing, setIsSubmitting, data, onDone, onClose} = props;
    const [errorReports, setErrorReports] = useState<any>([])
    const [successReports, setSuccessReports] = useState<any>([])
    const [showStatus, setShowStatus] = useState(false)
    const [showGenerate, setShowGenerate] = useState(true)

    const handleGenerate = async () => {
        setShowStatus(true)
        setShowGenerate(false)
        let promises: any = []
        let result: any = []
        let goodResults: any = []
        let reports = data
        reports.forEach(report => {
            promises.push(shipClient.generateMonthReport(report))
        })

        const promiseData = await Promise.all(promises);
        promiseData.forEach(({status, data}: any, index) => {
            if (!(status >= 200 && status <= 204)) {
                result = [...result, reports[index].account];
            } else {
                dispatch(ShowNotification("Success", "Report was generated successfully", false))
                // downloadBlob(data.location, `${reports[index].Name}_${new Date()}`, "_self");
                goodResults = [...goodResults, {
                    id: generateId(reports[index]),
                    location: data.location,
                    account: reports[index].account
                }]
            }

        });

        setErrorReports(result)
        setSuccessReports(goodResults)
    }

    const handleRetry = async (report: any) => {
        const rs = await shipClient.generateMonthReport(report)
        setIsSubmitting(false)
        if (rs.succeeded) {
            dispatch(ShowNotification("Success", "Report was generated successfully", false))
            downloadBlob(rs.data.location, `${report.name}_${new Date()}`, "_self");
            setErrorReports(errorReports.filter((i: any) => i !== report.account))
        }
    }

    const generateId = (item: any) => "" + item.account + "-" + item.consigneeCountry + "-" + item.agent

    return <>
        <CModal centered={true} show={isShowing} onClose={onClose}>
            <CModalHeader>
                <CRow className={" d-flex w-100 align-items-center"}>
                    <CCol>
                        <h5>Generate Report</h5>
                    </CCol>
                    <CIcon
                        content={freeSet.cilX}
                        style={{cursor: "pointer"}}
                        onClick={onClose}
                    />
                </CRow>
            </CModalHeader>
            <CModalBody>
                <CContainer>
                    <CRow className={'d-flex mx-1 mb-3 justify-content-end'}>
                        {showGenerate && <ActionButton
                            text={"Generate"}
                            onClick={handleGenerate}
                        />}
                        {!showGenerate && <ActionButton
                            text={"Done"}
                            color="success"
                            onClick={() => {
                                onDone()
                                onClose()
                            }}
                        />}
                    </CRow>
                    {data?.map((report: any) => {
                        const keys = Object.keys(report).filter(i => i != "generate" && i != "id")
                        return <div>
                            {showStatus &&
                                <div className={"border-top border-right border-left py-1 d-flex align-items-center"}>
                                    <CCol className={"text-primary"}>Status</CCol>
                                    <CCol className={"d-flex align-items-center"}>{
                                        successReports.length === 0 && errorReports.length === 0 ?
                                            <p className={"text-info mb-0"}>Loading...</p>
                                            : errorReports.includes(report.account)
                                                ? <p className={"text-danger mb-0"}>Failed</p>
                                                : <p className={"text-success mb-0"}>Successful</p>
                                    }</CCol>
                                    {errorReports.includes(report.account) ?
                                        <CCol className={"d-flex justify-content-end"}>
                                            <CButton
                                                size="sm"
                                                style={{fontSize: "10px"}}
                                                variant="outline"
                                                color="danger"
                                                onClick={() => handleRetry(report)}
                                            >
                                                retry
                                            </CButton>
                                        </CCol> : <CCol className={"d-flex justify-content-end"}>
                                            {
                                                successReports.length > 0 ? successReports.map((i: any) => {
                                                    return i?.id == report.id && <CTooltip content="Export to Exel">
                                                        <CIcon
                                                            content={freeSet.cilCloudDownload}
                                                            onClick={() => downloadBlob(i.location, "", "_self")}

                                                        />
                                                    </CTooltip>
                                                }) : null

                                            }

                                        </CCol>}
                                </div>}
                            <div className={"d-flex mb-2 py-2 border flex-column"}>
                                {
                                    keys.map(key => {

                                        if (key.includes("Date")) {
                                            return <CCol className={""}>
                                                <CRow>
                                                    <CCol>
                                                        {key}
                                                    </CCol>
                                                    <CCol colSpan={2}>
                                                        {new Date(report[key]).toLocaleDateString()}
                                                    </CCol>
                                                </CRow>
                                            </CCol>
                                        }

                                        if (key == "name") {
                                            return <CCol className={""} title={"Automatically generated"}>
                                                <CRow>
                                                    <CCol>
                                                        {key}
                                                    </CCol>
                                                    <CCol colSpan={2}>
                                                        {report[key]}
                                                    </CCol>
                                                </CRow>
                                            </CCol>
                                        }

                                        return report[key] && <CCol>
                                            <CRow>
                                                <CCol>
                                                    {key}
                                                </CCol>
                                                <CCol colSpan={2}>
                                                    {report[key]}
                                                </CCol>
                                            </CRow>
                                        </CCol>
                                    })
                                }

                            </div>
                        </div>
                    })}

                </CContainer>
            </CModalBody>
        </CModal>
    </>
}

export default GenerateMonthReportArrayModal