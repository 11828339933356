import React, { Fragment, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CCard, CContainer, CNav, CNavLink, CTabContent, CTabPane, CTabs } from "@coreui/react";
import Reprint from "./reprint";
import Print from "./print";
import NotGenerated from "./notGenerated";

const Index = () => {
    const history = useHistory();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState<number>(0);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const isNotGenerated = searchParams.get("notGenerated") === "true";
        if (isNotGenerated) {
            setActiveTab(2);
        }
    }, [location]);

    const handleTabChange = (tabIndex: number) => {
        setActiveTab(tabIndex);
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.has("notGenerated")) {
            searchParams.delete("notGenerated");
            history.push({ search: searchParams.toString() });
        }
    };


    return (
        <Fragment>
            <CContainer className="bg-white p-4 text-primary">
                <CTabs activeTab={activeTab}>
                    <CCard className="">
                        <CNav variant="tabs">
                            <CNavLink onClick={() => handleTabChange(0)}>Print</CNavLink>
                            <CNavLink onClick={() => handleTabChange(1)}>Reprint</CNavLink>
                            <CNavLink onClick={() => handleTabChange(2)}>Not Generated</CNavLink>
                        </CNav>
                        <CTabContent>
                            <CTabPane>
                                {activeTab === 0 && <Print />}
                            </CTabPane>
                            <CTabPane>
                                {activeTab === 1 && <Reprint />}
                            </CTabPane>
                            <CTabPane>
                                {activeTab === 2 && <NotGenerated />}
                            </CTabPane>
                        </CTabContent>
                    </CCard>
                </CTabs>
            </CContainer>
        </Fragment>
    )
}

export default Index;
