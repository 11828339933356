import React from "react";
import { freeSet } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CDataTable, CTooltip } from "@coreui/react";
import {  RateItemService} from "../../../../../models/retes";


type Props = {
    services?: RateItemService[]
    onRemove?: (arg: RateItemService) => void;
    onEdit?: (arg: RateItemService) => void;
}


const ServicesTable = ({ services, onRemove, onEdit }: Props) => {

    return (
        <CDataTable
        size="sm"
        hover
        scopedSlots={{
            actions: (e: RateItemService) => {
                return <td className={"text-center"}>
                    <CTooltip content={"Edit"}>
                        <CIcon content={freeSet.cilPencil}
                            style={{ cursor: "pointer", marginRight: "5px" }}
                            onClick={() => {
                                onEdit?.(e)
                            }} />
                    </CTooltip>
                    <CTooltip content={"Delete"}>
                        <CIcon style={{ cursor: "pointer" }} content={freeSet.cilTrash} onClick={() => onRemove?.(e)} />
                    </CTooltip>
                </td>
            },
        }}
        items={services || []}
        fields={[
            { key: "account", label: "Account" },
            { key: "agent", label: "Agent" },
            { key: "service", label: "Service" },
            { key: "actions", label: "Actions", _style: { textAlign: "center" } }
        ]}
    />

    )
}

export default ServicesTable;