import {
    CFormGroup,
    CFormText,
    CTextarea,
    CInputGroup,
    CInputGroupAppend,
    CInputGroupPrepend,
    CInputGroupText,
    CLabel
} from "@coreui/react";
import React from "react";

import CIcon from "@coreui/icons-react";

interface Props {
    style?: React.CSSProperties;
    readonly?: boolean;
    label?: string;
    className?: string;
    append?: any;
    onChange?: (val: string, name:string) => void
    onChangeFile?: (val: File | undefined) => void
    onBlur?: (e:any) => any
    icon?: any
    maxLength?: number
    id?: string
    name?: string
    key?: any
    value?: string
    valueFile?: File
    validationErrors?: string
    type?: "text" | "file" | "number";
    onChangeFormik?:(e:any) => void
    rows?: number
}

const SWTextArea = (props: Props) => {
    const {
        key,
        id,
        name,
        value,
        valueFile,
        label,
        append,
        icon,
        className,
        style,
        readonly,
        onChange,
        onChangeFile,
        maxLength,
        onBlur,
        validationErrors,
        type,
        rows,
        onChangeFormik
    } = props;

    const onChangeCall = (e: React.FormEvent<HTMLInputElement>) => {

        if(type && type == "file" )
            onChangeFile && onChangeFile(e.currentTarget?.files ? e.currentTarget.files[0] : undefined );
        else {
            onChange && onChange((e as any).target.value, (e as any).target.name);
            onChangeFormik && onChangeFormik(e);
        }
    }

    return (
        <div className={className} style={style}>
            <CFormGroup >
                {label && <CLabel className={"small"}>{label}</CLabel>}
                <CInputGroup>
                    {icon && (
                        <CInputGroupPrepend>
                            <CInputGroupText >
                                <CIcon content={icon}/>
                            </CInputGroupText>
                        </CInputGroupPrepend>
                    )}
                    <CTextarea
                        rows={rows}
                        style={{fontSize: "10px"}}
                        maxLength={maxLength}
                        readOnly={readonly}
                        id={id}
                        onBlur={(e) => onBlur ? onBlur(e) : undefined}
                        name={name}
                        key={key}
                        value={value}
                        onChange={onChangeCall}
                        type={type}
                    />
                    {append && (
                        <CInputGroupAppend>
                            <CInputGroupText>{append}</CInputGroupText>
                        </CInputGroupAppend>
                    )}
                </CInputGroup>
                {validationErrors ? (
                    <CFormText
                        style={{fontSize: "1em"}}
                        color="danger"
                        className="help-block">
                        {validationErrors}

                    </CFormText>
                ) : (
                    ""
                )}
            </CFormGroup>
        </div>
    );
};

export default SWTextArea;
