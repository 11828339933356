import React from 'react';
import { CTooltip } from '@coreui/react';

type Props = {
  color?: string;
  onClick: () => void;
  tooltipText: string;
  tabIndex?: number
}


const NotAllowedIcon = ({ onClick, color, tooltipText, tabIndex }: Props) => {
  return (

    <CTooltip

      content={tooltipText}>
      <svg
        tabIndex={tabIndex}
        viewBox="0 0 24 24"
        style={{ cursor: 'pointer' }}
        fill="none"
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
      >
        <g id="SVGRepo_bgCarrier"
          strokeWidth="0">
        </g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round">
        </g><g id="SVGRepo_iconCarrier">
          <path d="M12 3V5M12 5C15.866 5 19 8.13401 19 12M12 5C11.5608 5 11.131 5.04045 10.7142 5.11783M12 19V21M12 19C8.13401 19 5 15.866 5 12M12 19C13.933 19 15.683 18.2165 16.9497 16.9497M3 12H5M5 12C5 10.065 5.78512 8.3134 7.05417 7.04634M19 12H21M19 12C19 12.4385 18.9597 12.8675 18.8826 13.2837M12 15C10.3431 15 9 13.6569 9 12M12 15C12.7684 15 13.4692 14.7111 14 14.2361M12 15C12.8274 15 13.5766 14.665 14.1194 14.1233M9 12C9 11.2316 9.28885 10.5308 9.76389 10M9 12C9 11.1716 9.33579 10.4216 9.87868 9.87868M3 3L21 21"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"></path>
        </g></svg>
    </CTooltip>
  );
};

export default NotAllowedIcon;







