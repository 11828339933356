import {CButton, CCol, CModal, CModalBody, CModalFooter, CModalHeader, CRow} from "@coreui/react";
import React from "react";
import ActionButton from "./ActionButton";

interface Props {
    onClose: () => any
    onSubmit?: () => any
    title: string
    body: string
    children?: JSX.Element
    submitting?: boolean
    isDisabled?:boolean
}

const ConfirmModal = (props: Props) => {
    return (
        <div className="small text-primary">
            <CModal
                show={true}
                centered
                onClose={props.onClose}
                closeOnBackdrop={false}
            >
                <CModalHeader closeButton>
                    <h5>{props.title}</h5>
                </CModalHeader>
                <CModalBody>
                    <h6>{props.body}</h6>
                    {!!props.children && props.children}
                </CModalBody>
                <CModalFooter>
                    <CRow className="justify-content-between w-100">
                        <CCol className="text-left">
                            <CButton
                                style={{fontSize: "10px"}}
                                variant="outline"
                                color="danger"
                                disabled={props.submitting}
                                onClick={props.onClose}
                            >
                                Cancel
                            </CButton>
                        </CCol>
                        {!!props?.onSubmit &&
                        <CCol className="text-right">
                            <ActionButton
                                text={props.submitting ? "Loading..." : "Yes"}
                                disabled={props.submitting || props.isDisabled}
                                onClick={() => props.onSubmit?.()}
                            />
                        </CCol>}
                    </CRow>
                </CModalFooter>
            </CModal>
        </div>

    )
}

export default ConfirmModal
