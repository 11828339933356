import React, { useEffect, useState } from "react";
import { CCollapse, CContainer, CDataTable, CTooltip } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "./card";
import {faFile, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { SetIsLoading } from "../../../../store/actions/ui";
import ShipClient from "../../../../clients/shipClient";
import { ShowNotification } from "../../../../store/actions/auth";
import { DashboardCountsResponse, GroupedShipmentsModel, ShipmentsByCustomer } from "../../../../models/shipment";
import { useHistory } from "react-router";


const ShipmentFinalized = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const shipClient = new ShipClient();
    const [data, setData] = useState<DashboardCountsResponse>();
    const [details, setDetails] = useState<{ key: string }[]>([]);
    const [detailsData, setDetailsData] = useState<{ [key: string]: ShipmentsByCustomer[] }>({});
    const totalNumberOfShipments = (data?.yesterdaysShipments ? data?.yesterdaysShipments?.total : 0) +
        (data?.beforeYesterdaysShipments ? data?.beforeYesterdaysShipments?.total : 0);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        dispatch(SetIsLoading(true))
        const res = await shipClient.DashboardCounts()
        if (res?.succeeded) {
            setData(res?.data)
        } else {
            dispatch(ShowNotification("Error", res?.error, true));
        }
    };

    const keyMappings: Record<string, string> = {
        todaysShipments: 'Today',
        yesterdaysShipments: 'Yesterday',
        beforeYesterdaysShipments: 'Before yesterday',
    };

    const toggleDetails = async (item: { key: string, value: GroupedShipmentsModel }) => {
        const { key, value } = item;
        const position = details.findIndex(e => e.key === key);
        let newDetails;
        if (position !== -1) {
            newDetails = details.slice();
            newDetails.splice(position, 1);
        } else {
            newDetails = [...details, { key }];
        }
        setDetails(newDetails);
        if (position !== -1) {
            return;
        }
        setDetailsData({ ...detailsData, [key]: value?.shipments });
    }

    const detailsScopedSlots = {
        account: (item: ShipmentsByCustomer) => {
            return <td>{item.accountName ?? ""}</td>
        },
        numberOfShipments: (item: ShipmentsByCustomer) => {
            return <td className="text-right">{item.total ?? ""}</td>
        },
    };


    const scopedSlots = {
        viewDetails: (item: { key: string, value: GroupedShipmentsModel }) => {
            if (!Boolean(item?.value?.total)) return <td />
            return <td >
                <CTooltip
                    content="Details"
                >
                    <CContainer>
                        <FontAwesomeIcon icon={faInfoCircle} size={"1x"}
                            onClick={() => {
                                toggleDetails(item)
                            }}
                            color={"#FF8800"}
                            style={{ cursor: "pointer" }}
                        />
                    </CContainer>
                </CTooltip>
            </td>
        },
        day: (item: { key: string, value: GroupedShipmentsModel }) => {
            return <td>{keyMappings[item.key] ?? ""}</td>
        },
        numberOfShipments: (item: { key: string, value: GroupedShipmentsModel }) => {
            return <td className="text-right">{item?.value?.total ?? "0"}</td>
        },
        details:
            (item:  { key: string, value: GroupedShipmentsModel }, index: number) => {
                return (
                    <CCollapse show={details?.some(detail => detail.key === item.key)}>
                        <div className={'bg-secondary'}>
                            <CDataTable
                                header={false}
                                size="sm"
                                hover
                                scopedSlots={detailsScopedSlots ?? {}}
                                items={detailsData[`${item.key}`] ?? []}
                                fields={[
                                    { key: "account", label: "Account" },
                                    { key: "numberOfShipments", label: "Number of shipments" },
                                ]}
                            />
                        </div>
                    </CCollapse>
                )
            }
    };
    const mappedData = (response: DashboardCountsResponse): Array<{ key: string, value: GroupedShipmentsModel }> => {
        const result: Array<{ key: string, value: GroupedShipmentsModel }> = [];
        result.push({ key: 'todaysShipments', value: response.todaysShipments });
        result.push({ key: 'yesterdaysShipments', value: response.yesterdaysShipments });
        result.push({ key: 'beforeYesterdaysShipments', value: response.beforeYesterdaysShipments });
        return result;
    };


    return (
        <Card
            title={"Shipments Finalized"}
            subTitle={""}
            total={totalNumberOfShipments?.toString() ?? "0"}
            onView={() =>  history.push({
                pathname: '/shipments/search',
            })}
            icon={
                <FontAwesomeIcon
                    icon={faFile}
                    tabIndex={-1}
                    style={{  width: 45, height: 45, alignItems: "center" }}
                />}
            children={
                <div className="small" style={{ overflowY: "auto", maxHeight: "100px" }}>
                    <CDataTable
                        header={false}
                        size="sm"
                        hover
                        scopedSlots={scopedSlots ?? {}}
                        items={data ? mappedData(data) : []}
                        fields={[
                            { key: "viewDetails", label: "" },
                            { key: "day", label: "Day" },
                            { key: "numberOfShipments", label: "Number of shipments" },
                        ]}
                    />
                </div>
            }
        />
    )
}

export default ShipmentFinalized;