import {CButton, CCol, CContainer, CInputCheckbox, CLabel, CModal, CModalBody, CRow} from "@coreui/react";
import React, {useEffect, useState} from "react";
import SWSelect from "../../../SharedComponents/SWSelect";
import SWInput from "../../../SharedComponents/SWInput";
import SWDateAndTimePicker from "../../../SharedComponents/SWDateAndTimePicker";
import {useFormik} from "formik";
import LookupsClient from "../../../../clients/lookupsClient";
import ShipClient from "../../../../clients/shipClient";
import {ShipmentGet} from "../../../../models/shipment";
import {Submit} from "../../../SharedComponents/SWForm";
import {getLocalDateTime} from "../../../../utils/dateUtil";
import {TraceType} from "../../../../models/lookups";
import {reasonLookups} from "../../Tracking/Events";
import ActionButton from "../../../SharedComponents/ActionButton";

interface IProps {
    HandleClose: () => void
    handleSubmit: (values: any, attachmentId?: number, sendTo?: "shipper" | "consignee" | "both") => void
    shipment: ShipmentGet
}

const AddTraceModal = (props: IProps) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)
    const [fullTraces, setFullTraces] = useState<TraceType[]>()
    const [tracking, setTracking] = useState<{ [k: string]: string }>({})
    const [allowEmail, setAllowEmail] = useState<boolean>(false)
    const [allowAttachment, setAllowAttachment] = useState<boolean>(false)
    const shipClient = new ShipClient()

    const lookupsClient = new LookupsClient();
    useEffect(() => {
        lookupsClient.searchTraces().then((v) => handleGetTraces(v.result));
    }, []);

    const {values, setFieldValue, handleChange, handleSubmit} = useFormik({
        initialValues: {
            code: "",
            comment1: "",
            comment2: "",
            actionDate: getLocalDateTime(),
            attachmentLocation: "",
            attachmentType: "",
            attachmentFileName: "",
            sendToShipper: false,
            sendToConsignee: false
        },
        onSubmit: async (values) => {
            setLoading(true)
            if (!values.code) {
                setError(true)
                setLoading(false)
            } else {
                props.handleSubmit(values)
                setError(false)
                setLoading(false)
            }
        }
    })

    const handleGetTraces = (traces: TraceType[]) => {
        setFullTraces(traces)
        let tracesLookups: { [k: string]: string } = {}
        traces.forEach(trace => {
            tracesLookups[trace.id] = trace.description
        })
        setTracking(tracesLookups)
    }

    useEffect(() => {
        setFieldValue("attachmentLocation", "")
        setFieldValue("sendToShipper", false)
        setFieldValue("sendToConsignee", false)
    }, [values.code])

    const handleFileImport = async (file?: File) => {
        setLoading(true)
        if (!file) {
            setLoading(false)
            return
        }
        let res = await shipClient.UploadFile(file, props.shipment.number)
        await setFieldValue("attachmentLocation", res.location)
        setLoading(false)
    }

    const handleCommentTypeChange = (t?: string) => {
        setFieldValue("comment1", t)
        setError(false)
        if (!t) return;
    }

    const handleTraceChange = (t?: string) => {
        setFieldValue("code", t)
        setError(false)
        if (!t) return
        const selected = fullTraces?.find(tr => tr.id == t)
        if (!selected) return;
        setAllowEmail(selected.allowSendEmail)
        setAllowAttachment(!!selected.allowAttachmentWithType)
        setFieldValue("attachmentType", selected.allowAttachmentWithType)
    }

    return (
        <CModal closeOnBackdrop={false}  show={true} onClose={props.HandleClose} className={"ml-auto mr-auto"} size={"lg"} centered>
            <CModalBody>
                <CContainer className="bg-white p-4 text-primary">
                    <h5>
                        Add Trace:
                    </h5>
                    <CRow >
                        <CCol md={4}>
                            <SWSelect values={tracking} label={"Trace"} value={values.code} name={"code"}
                                      onChange={(v) => handleTraceChange(v)}
                                      validationErrors={error ? "Code is required" : undefined}/>
                            { values.code == "SHDL201" ?
                            <SWDateAndTimePicker value={values.actionDate} showTimeInput
                                                 handleOnChange={(v) => setFieldValue("actionDate", v)}/>: null }
                        </CCol>
                        { values.code == "SHDL201" ?
                            <CCol md={4}>
                                <SWSelect values={reasonLookups} label={"Comment"} value={values.comment1} name={"comment1"}
                                          onChange={(v) => handleCommentTypeChange(v)}
                                />
                                <SWInput value={values.comment2} name={"comment2"}
                                         onChangeFormik={handleChange}/>
                            </CCol> :
                            <CCol md={4}>
                                <SWInput label={"Comment"} value={values.comment1} name={"comment1"}
                                        onChangeFormik={handleChange}/>
                            </CCol>
                        }
                        { values.code !== "SHDL201" ?
                        <CCol md={4} style={{marginTop: "24px"}}>
                            <SWDateAndTimePicker value={values.actionDate} showTimeInput
                                                 handleOnChange={(v) => setFieldValue("actionDate", v)}/>
                        </CCol>: null}
                    </CRow>
                    {props.shipment.number &&
                        <CRow>
                            {allowAttachment &&
                                <>
                                    <CCol md={4}>
                                        <SWInput
                                            label={"Upload"}
                                            type={"file"}
                                            id={"input-file"}
                                            onChangeFile={(f) => handleFileImport(f)}
                                        />
                                    </CCol>
                                    {values.attachmentLocation &&
                                        <CCol>
                                            <SWInput label={"File Name"} value={values.attachmentFileName}
                                                     name={"attachmentFileName"}
                                                     onChangeFormik={handleChange}/>
                                        </CCol>}
                                </>}
                            {allowEmail &&
                                <CCol style={{marginLeft: "35px", marginTop: "20px"}}>
                                    {props.shipment.shipperEmail &&
                                        <CRow style={{height: "20px"}}>
                                            <CInputCheckbox
                                                checked={values.sendToShipper}
                                                onChange={() => setFieldValue("sendToShipper", !values.sendToShipper)}
                                            />
                                            <CLabel
                                                style={{marginTop: "8px"}}
                                            >Send To Shipper</CLabel>
                                        </CRow>}
                                    {props.shipment.consigneeEmail &&
                                        <CRow>
                                            <CInputCheckbox
                                                checked={values.sendToConsignee}
                                                onChange={() => setFieldValue("sendToConsignee", !values.sendToConsignee)}
                                            />
                                            <CLabel
                                                style={{marginTop: "8px"}}
                                            >Send To Consignee</CLabel>
                                        </CRow>}
                                </CCol>}
                        </CRow>
                    }
                    <CRow>
                        <CCol md={1}>
                            <CButton variant={"outline"} color={"danger"} size={"sm"}
                                     onClick={props.HandleClose}>Cancel</CButton>
                        </CCol>
                        <CCol className={"text-right"}>
                            <ActionButton
                                text={loading ? "Loading..." : "Submit"}
                                disabled={loading}
                                onClick={handleSubmit}
                            />
                        </CCol>
                    </CRow>
                </CContainer>
            </CModalBody>
        </CModal>
    )
}

export default AddTraceModal;