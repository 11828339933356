import {CContainer, CCard, CCardBody, CRow, CCol, CLabel, CButton} from '@coreui/react'
import SWSelect from "../../../SharedComponents/SWSelect";
import React, {useEffect, useState} from "react";
import WarehouseClient from "../../../../clients/warehousesClient";
import {useFormik} from "formik";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import WarehouseAddEditModal from "./WarehouseAddEditModal";

const WarehouseManagement = () => {
    const [warehouseLookups, setWarehouseLookup] = useState<{ [k: string]: string }>({})
    const [areaLookups, setAreaLookups] = useState<{ [k: string]: string }>({})
    const [locationLookups, setLocationLookups] = useState<{ [k: string]: string }>({})
    const [showing, setShowing] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [isDelete, setIsDelete] = useState<boolean>(false)
    const [type, setType] = useState<string>()
    const [warehouse, setWarehouse] = useState<{ code: string, name: string }>({code: '', name: ''})
    const [area, setArea] = useState<{ code: string, name: string }>({code: '', name: ''})
    const [location, setLocation] = useState<{ code: string, issuePriority: number }>({code: '', issuePriority: 0})
    const [data, setData] = useState<{ code: string, name: string, issuePriority: number }>({
        code: "",
        name: "",
        issuePriority: 0
    })
    const [error, setError] = useState<string>()

    const client = new WarehouseClient();

    useEffect(() => {
        LoadWarehouse()
    }, [])

    const {
        values,
        setFieldValue,
        handleSubmit,
        resetForm
    } = useFormik({
        initialValues: {
            warehouse: '',
            area: '',
            warehouseName: '',
            areaName: '',
            location: '',
        },
        onSubmit: async (values) => {
            let req = {...values, ...data}
            let res;
            if (isEdit) {
                switch (type) {
                    case "Warehouse" :
                        res = await client.updateWarehouse(req)
                        break;
                    case "Area" :
                        res = await client.updateArea(req)
                        break;
                    case "Location" :
                        res = await client.updateLocation(req)
                        break;
                }
            } else if (isDelete) {
                switch (type) {
                    case "Warehouse" :
                        res = await client.deleteWarehouse(req)
                        break;
                    case "Area" :
                        res = await client.deleteArea(req)
                        break;
                    case "Location" :
                        res = await client.deleteLocation(req)
                        break;
                }
            } else {
                switch (type) {
                    case "Warehouse" :
                        res = await client.createWarehouse(req)
                        break;
                    case "Area" :
                        res = await client.createArea(req)
                        break;
                    case "Location" :
                        res = await client.createLocation(req)
                        break;
                }
            }
            if (res?.succeeded) {
                await LoadWarehouse()
                setShowing(false)
                resetForm()
            } else {
                setError(res?.error)
            }
        }
    })

    const LoadWarehouse = async () => {
        const warehouses = await client.warehousesLookup()
        setWarehouseLookup(warehouses);
    }

    const onWarehouseChange = async (v?: string) => {
        await setFieldValue("warehouse", v)
        await setFieldValue("warehouseName", warehouseLookups[v ?? ""])
        setWarehouse({code: v ?? "", name: warehouseLookups[v ?? ""]})
        await setFieldValue('area', null)
        await setFieldValue('location', null)
        await loadAreas(v)
    }

    const HandleCallback = ((code: string, name: string, issuePriority: number) => {
        setData({code, name, issuePriority})
        handleSubmit()
    })


    const onAreaChange = async (v?: string) => {
        await setFieldValue("area", v)
        await setFieldValue("areaName", areaLookups[v ?? ""])
        setArea({code: v ?? "", name: areaLookups[v ?? ""]})
        await setFieldValue('location', null)
        await loadLocations();
    }

    const loadAreas = async (v?: string) => {
        let areas = await client.areaLookup(v, "MAIN");
        setAreaLookups(areas)
    }

    const loadLocations = async () => {
        let locations = await client.locationLookups(values.warehouse ?? undefined, "MAIN", values.area ?? undefined);
        setLocationLookups(locations)
    }

    const onLocationChange = async (v?: string) => {
        await setFieldValue("location", v)
        // get issue priority
        setLocation({code: v ?? '', issuePriority: 0})
    }

    const warehouseIcons = (type: string, value: string) => {
        return <CRow>
            <CIcon content={freeSet.cilPlus} onClick={() => HandleAdd(type)}
                   style={{cursor: 'pointer', marginLeft: '20px'}}/>
            {value &&
                <>
                    <CIcon content={freeSet.cilPencil} onClick={() => HandleEdit(type)}
                           style={{cursor: 'pointer', marginLeft: '10px'}}/>
                    <CIcon content={freeSet.cilTrash} onClick={() => HandleDelete(type)}
                           style={{cursor: 'pointer', marginLeft: '10px'}}/>
                </>}
        </CRow>
    }

    const HandleAdd = (v: string) => {
        setShowing(true)
        setIsEdit(false)
        setIsDelete(false)
        setType(v)
    }

    const HandleEdit = (v: string) => {
        setType(v)
        setIsEdit(true)
        setIsDelete(false)
        setShowing(true)
    }

    const HandleDelete = (v: string) => {
        setType(v)
        setShowing(true)
        setIsDelete(true)
        setIsEdit(false)
    }

    return (
        <CContainer className="bg-white p-4 text-primary small">
            <h5 style={{color: 'gray', marginBottom:"2%"}}>Warehouse Management</h5>
            <CCol md={3}>
                <CRow>
                    <CLabel>Warehouse</CLabel> {warehouseIcons("Warehouse", values.warehouse)}
                </CRow>
                <CRow>
                    <SWSelect style={{width: '100%'}} value={values.warehouse} values={warehouseLookups}
                              name={"warehouse"}
                              onChange={onWarehouseChange}/>
                </CRow>
            </CCol>
            <CCol md={1}/>
            {values.warehouse &&
                <>
                    <CCol md={3}>
                        <CRow>
                            <CLabel>Area</CLabel> {warehouseIcons("Area", values.area)}
                        </CRow>
                        <CRow>
                            <SWSelect style={{width: '100%'}} values={areaLookups} name={"area"}
                                      value={values.area}
                                      onChange={onAreaChange}/>
                        </CRow>
                    </CCol>
                </>
            }
            <CCol md={1}/>
            {values.area &&
                <>
                    <CCol md={3}>
                        <CRow>
                            <CLabel>Location</CLabel> {warehouseIcons("Location", values.location)}
                        </CRow>
                        <CRow>
                            <SWSelect style={{width: '100%'}} values={locationLookups} name={"location"}
                                      value={values.location}
                                      onChange={onLocationChange}/>
                        </CRow>
                    </CCol>
                </>
            }
            <WarehouseAddEditModal
                type={type ?? ""}
                isShowing={showing}
                isEdit={isEdit}
                isDelete={isDelete}
                onClose={() => setShowing(false)}
                warehouse={warehouse}
                area={area}
                location={location}
                error={error}
                onSubmit={HandleCallback}
            />
        </CContainer>
    )
}

export default WarehouseManagement;