import {useEffect, useMemo, useState} from "react";
import {CCol, CRow, CSpinner} from "@coreui/react";
import ActionButton from "../../../SharedComponents/ActionButton";
import SWMultiSelect from "../../../SharedComponents/SWMultiSelect";
import WarehousesClient from "../../../../clients/warehousesClient";
import {useFormik} from "formik";
import {AccountingWarehouseType, warehouse} from "../../../../models/warehouse";
import SWInput from "../../../SharedComponents/SWInput";
import SWInputNumber from "../../../SharedComponents/SWInputNumber";
import SWSelect from "../../../SharedComponents/SWSelect";
import {getUnitLoookup, getWeightUnitLoookup} from "../../../../utils/lookupUtils";

type Props = {
    accountId: string
}
const preferredLanguagesValues = {
    "en": "English",
    "fr": "France",

};
const ViewAccountingWarehouse = ({accountId}: Props) => {
    const warehouseClient = new WarehousesClient()
    const [loader, setLoader] = useState(false)
    const [allowedWarehousesValues, setAllowedWarehousesValues] = useState<{ [key: string]: string }>({})
    const [allowedSellersValues, setAllowedSellersValues] = useState<{ [key: string]: string }>({})
    const {values, handleSubmit, setValues, setFieldValue} = useFormik<AccountingWarehouseType>({
        onSubmit: (values) => {
            submit(values)
        },
        initialValues: {}

    })

    const firstFetch = async () => {

        setLoader(true)
        await fetchData()
        const res = await warehouseClient.searchWarehouse()
        allowedWareHouseLookup(res)
        warehouseClient.SellersLookup().then(v => setAllowedSellersValues(v))
        setLoader(false)

    }
    const allowedWareHouseLookup = (wareHouse: warehouse[]) => {
        let lookup: Record<number, string> = {};
        for (let w of wareHouse) {
            lookup[w.id] = w.name
        }
        setAllowedWarehousesValues(lookup)

    }
    const fetchData = async () => {
        const res = await warehouseClient.GetAccount(accountId)
        if (res) {
            await setValues({
                ['defaultDimensionUnit']: res?.defaultDimensionUnit,
                ['defaultWeightUnit']: res?.defaultWeightUnit,
                ["allowedWarehouses"]: res?.allowedWarehouses ?? [],
                ['allowedSellers']: res?.allowedSellers ?? [],
                ['minimumExpiryDays']: res?.minimumExpiryDays
            })
        }
    }

    const mappingArray = (v: string[] | null | number[], object: Record<string, string>) => {
        if (v)
            return v.map(newValue => {
                return {label: object[newValue], value: newValue.toString()}
            })
        else return null

    }
    const arrayConverter = (object: { value: string | number, label: string }[]): number[] => {
        return object.map(o => Number(o.value))
    }

    useEffect(() => {
        firstFetch().then()

    }, []);
    const submit = async (values: AccountingWarehouseType) => {
        setLoader(true)
        await warehouseClient.UpdateAccount(accountId, values)
        fetchData().then()
        setLoader(false)

    }
    return (
        <CCol md={12}>
            {loader ? <CSpinner className="mx-auto d-block my-5"
                                color="primary"
                                style={{width: "3em", height: "3em"}}/> : <CRow>
                <CCol md={4}>
                    <SWMultiSelect label={'Allowed Warehouse'} values={allowedWarehousesValues}
                                   value={mappingArray(values?.allowedWarehouses ?? [], allowedWarehousesValues)}
                                   onChange={(e: { value: string | number, label: string }[]) => {

                                       setFieldValue('allowedWarehouses', arrayConverter(e))
                                   }}/>
                </CCol>
                <CCol md={4}>
                    <SWMultiSelect label={'Allowed Seller'} values={allowedSellersValues}
                                   value={mappingArray(values?.allowedSellers ?? [], allowedSellersValues)}
                                   onChange={(e: { value: string | number, label: string }[]) => {

                                       setFieldValue('allowedSellers', arrayConverter(e))
                                   }}/>
                </CCol>
                <CCol md={4}>
                    <SWInputNumber
                        label={'Minimum expiry days'}
                                 value={values?.minimumExpiryDays ?? undefined}
                                   onChange={(e)=> setFieldValue('minimumExpiryDays', Number(e))
                                   }/>
                </CCol>
                <CCol md={4}>
                    <SWSelect
                        isClearable={true}
                        label={"Default weight unit"}
                        values={getWeightUnitLoookup()}
                        value={values?.defaultWeightUnit?.toString() ?? undefined}
                        name={"defaultWeight.unit"}
                        onChange={(e) => setFieldValue("defaultWeightUnit", e)}
                    />
                </CCol>
                <CCol md={4}>
                        <SWSelect
                            isClearable={true}
                            label={"Default dimension unit"}
                            values={getUnitLoookup()}
                            value={values?.defaultDimensionUnit?.toString() ?? undefined}
                            name={"dimensions.unit"}
                            onChange={(e) => setFieldValue("defaultDimensionUnit", e)}
                        />
                </CCol>
            </CRow>}
            <CRow className={'justify-content-end'}>
                <ActionButton disabled={loader} onClick={() => handleSubmit()} text={"update"}/>
            </CRow>
        </CCol>
    )
}
export default ViewAccountingWarehouse
