
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AppModel } from "../../../../models/app";
import { FilterType } from "../../../../models/filter";
import { SetContainerTypesFilterGroupFilters } from "../../../../store/actions/containertypes";
import SWAdvancedSearch from "../../../SharedComponents/SWAdvancedSearch/SWAdvancedSearch";

interface Props {}
const SearchForm = (props: Props) => {
  const [showAdvanced, setShowAdvanced] = useState(false);
  const dispatch = useDispatch();
  const filterGroup = useSelector(
    (state: AppModel) => state.containertype.containertypesSearch.filterGroup
  );

  return (
      <fieldset className="b-fieldset p-2 text-primary">
        <legend className="b-legend" style={{width: "133px"}}><h5 style={{width:"90pc"}}>Container Types</h5></legend>
        <SWAdvancedSearch

          cancelCb={() => setShowAdvanced(false)}
          className="m-0 p-0 small"
          filter={(fg) => dispatch(SetContainerTypesFilterGroupFilters(fg))}
          filterGroup={filterGroup}
          defaultFilters={[
            {
              field: "TypeName",
              type: FilterType.Equals,
              inputType: "text",
              value: "",
            },
          ]}
          availableFilters={[
            {
              field: "TypeName",
              type: FilterType.Equals,
              inputType: "text",
              value: "",
            },
          ]}
        />
      </fieldset>
  );
};

export default SearchForm;
