import { CSpinner, CDataTable } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { AppModel } from "../../../../models/app";
import {
  SortingRuleInformation,
  SortingRuleSearch,
} from "../../../../models/sortingrule";
import PageCount from "../../../SharedComponents/PageCount";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import ShipClient from "../../../../clients/shipClient";

interface Props {}

const SearchResult = (props: Props) => {
  const dispatch = useDispatch();
  const hist = useHistory();
  const loc = useLocation();

  const [containerTypes, setContainerTypes] = useState<{ [k: string]: string }>(
    {}
  );

  const shipClient = new ShipClient();

  useEffect(() => {
    shipClient.GetContainerTypes(null, true).then((v) => {
      setContainerTypes(v as { [k: string]: string });
    });
  }, []);

  const sortingruleSearchResult = useSelector((state: AppModel) => {
    return {
      result: state.sortingrule.sortingrulesSearch.result,
      isLoading: state.sortingrule.sortingrulesSearch.isLoading,
      page: state.sortingrule.sortingrulesSearch.page,
      filterGroup: state.sortingrule.sortingrulesSearch.filterGroup,
      maxPage: Math.ceil(
        state.sortingrule.sortingrulesSearch.count! /
          state.sortingrule.sortingrulesSearch.limit!
      ),
    };
  });

  const scopedSlots = {
    containerType: (v: SortingRuleInformation) => {
      const type = containerTypes[v.containerType.toString()];
      if (type) {
        return <td>{type}</td>;
      }
      return <td>{v.containerType}</td>;
    },
    operator: (v: SortingRuleInformation) => {
      switch (v.operator) {
        case 0:
          return <td>Equals</td>;
        default:
          return <td>{v}</td>;
      }
    },
  };

  return (
    <div className="small">
      {sortingruleSearchResult.isLoading ? (
        <CSpinner
          className="mx-auto d-block my-5"
          color="primary"
          style={{ width: "5em", height: "5em" }}
        />
      ) : (
        <>
          <CDataTable 
          size="sm"
          hover
            clickableRows={true}
            onRowClick={(e: SortingRuleSearch) =>
              hist.push(`/settings/sortingrules/${e.id}`)
            }
            items={sortingruleSearchResult.result}
            scopedSlots={scopedSlots}
            fields={[
              "propertyName",
              "operator",
              "value",
              "containerPrefix",
              "containerType",
              "order",
            ]}
          />
          <PageCount
              maxFound={sortingruleSearchResult.maxPage}
            onPageChange={(p) => {
              const modifiedSearch = new URLSearchParams(loc.search);
              modifiedSearch.delete("page");
              hist.push(
                `${loc.pathname}?${modifiedSearch.toString()}&page=${p}`
              );
            }} 
            onPageSizeChange={(ps) => {
              const modifiedSearch = new URLSearchParams(loc.search);
              modifiedSearch.delete("limit");
              hist.push(
                  `${loc.pathname}?${modifiedSearch.toString()}&limit=${ps}`
              );
            }}
            currentPage={sortingruleSearchResult.filterGroup?.page ?? 0}
            maxPage={sortingruleSearchResult.maxPage}
          />
        </>
      )}
    </div>
  );
};

export default SearchResult;
