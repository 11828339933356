import {CButton, CCol, CContainer, CInputCheckbox, CLabel, CModal, CModalBody, CRow} from "@coreui/react"
import React, {useEffect, useState} from "react"
import SWInput from "../../../SharedComponents/SWInput";
import SWSelect from "../../../SharedComponents/SWSelect";

import ShipClient from "../../../../clients/shipClient";
import {useFormik} from "formik";
import AccountingClient from "../../../../clients/accountingClient";
import {
    AccountAttachment,
    AddAccountAttachmentRequest,
    UpdateAccountAttachmentRequest
} from "../../../../store/actions/accounts";

interface IProps {
    handleClose: () => void
    handleAdd: (attachment: AccountAttachment) => void
    handleUpdate: (attachment: AccountAttachment) => void
    id: string
    attachments: AccountAttachment[]
    attachmentIndex: number | undefined
    fileUploaded: any
    location: string | undefined
}

const AddAccountAttachmentModal = (props: IProps) => {
    const accountingClient = new AccountingClient();
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [attachmentTypes, setAttachmentTypes] = useState<{ [k: string]: string }>({})


    const {values, handleSubmit, setFieldValue, handleChange} = useFormik({
        initialValues: {
            location: undefined,
            type: undefined,
            fileName: undefined
        },
        onSubmit: async (values) => {
            if (isEdit) {
                if (props.attachmentIndex == undefined) return
                const attachment = props.attachments[props.attachmentIndex]
                const body: UpdateAccountAttachmentRequest = {
                    id: attachment.id,
                    type: values.type ?? "",
                    fileName: values.fileName,

                }
                const rs = await accountingClient.updateAttachment(props.id, body)
                if (rs)
                    props.handleUpdate(rs)

            } else {
                const body: AddAccountAttachmentRequest = {
                    type: values.type ?? "",
                    location: props.location,
                    fileName: values.fileName,

                }
                const attachment = await accountingClient.addAttachment(props.id, body)
                props.handleAdd(attachment)
            }
        }
    })

    useEffect(() => {
        accountingClient.getAttachmentTypeLookups("shipment").then(v => setAttachmentTypes(v))
        if (props.attachmentIndex != undefined) {
            const attachment = props.attachments[props.attachmentIndex]
            setFieldValue("type", attachment.type)
            setFieldValue("fileName", attachment.fileName)
            setIsEdit(true)
        } else {
            setIsEdit(false)
        }
    }, [])

    return (
        <CModal show={true} onClose={props.handleClose} className={"ml-auto mr-auto"} centered>
            <CModalBody>
                <CContainer className="bg-white p-4 text-primary">
                    <h5>
                        {isEdit ? "Update Attachment:" : "Add Attachment:"}
                    </h5>
                    <CRow>
                        <CCol>
                            <SWSelect label={"Type"} value={values.type} values={attachmentTypes}
                                      onChange={t => setFieldValue("type", t)}/>
                        </CCol>
                    </CRow>
                    
                        <CRow>
                            <CCol>
                                <SWInput value={values.fileName} name={"fileName"} label={"File Name"}
                                         onChangeFormik={handleChange}/>
                            </CCol>
                        </CRow>
                    <CRow>
                        <CCol>
                            <CButton
                                color={"danger"}
                                variant={"outline"}
                                size={"sm"}
                                onClick={props.handleClose}>
                                Cancel
                            </CButton>
                        </CCol>
                        <CCol md={2} className={"text-right"} style={{marginRight: "5px"}}>
                            <CButton
                                color={"primary"}
                                variant={"outline"}
                                size={"sm"}
                                onClick={() => handleSubmit()}
                            >{isEdit ? "Update" : "Add"}</CButton>
                        </CCol>
                    </CRow>
                </CContainer>
            </CModalBody>
        </CModal>
    )
}

export default AddAccountAttachmentModal