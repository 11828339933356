import React from "react";
import { CDataTable } from "@coreui/react";
import { RateQueryResultCharge } from "../../../../models/retes";


interface IProps {
    charges: RateQueryResultCharge[]
    isSelling?: boolean
    chargeableWeight?: number | null
    bgColor?:string
}

const ChargesDetailsTable = (props: IProps) => {
    const { charges, isSelling,bgColor,chargeableWeight } = props;
    const scopedSlots = {
        type: (item: RateQueryResultCharge) => {
            return <td>{item.type ?? ""}</td>
        },
        name: (item: RateQueryResultCharge) => {
            return <td>{item.name ?? ""}</td>
        },
        amount: (item: RateQueryResultCharge) => {
            return <td className="text-right pr-3" >{item.amount ?? ""} </td>
        },
        taxAmount: (item: RateQueryResultCharge) => {
            return <td  className={"text-right pr-3"} style={{ color: "#9e9e9eb0" }}>{item.taxAmount ?? ""}</td>
        },
    }
    return (
        <div className={`bg-${bgColor??"white"}`} style={{ overflowY: "auto", maxHeight: "350px", zIndex: 0,paddingLeft:5 }}>
            {chargeableWeight && isSelling && 
            <div className="text-primary mt-2 mb-2">
            Chargeable Weight (Kg) : {chargeableWeight?.toFixed(2)}
            </div>}
            <CDataTable
                hover
                scopedSlots={scopedSlots}
                items={charges}
                fields={[
                    { key: "type", label: "Type", _style: { width: "8rem" } },
                    { key: "name", label: "Name", _style: { width: "8rem" } },
                    { key: "amount", label: "Amount", _style: { width: "5rem" ,textAlign: "end" ,paddingRight:"1rem" } },
                    ...(isSelling ? [{ key: "taxAmount", label: "Tax Amount", _style: { width: "5rem" , textAlign: "end" ,paddingRight:"1rem"} }] : []),
                ]}
            />
        </div>
    );
};

export default ChargesDetailsTable;
