import {GetShipmentLogs,} from "../../../../models/getShipmentLogs";
import {PathLogsType} from "./viewLogs";
import {CCol, CLabel, CRow} from "@coreui/react";
import {formatDateTime} from "../../../../utils/dateUtil";

type Props = {
    data: GetShipmentLogs
    addPath: (req: PathLogsType) => void

}
const LogsExternal = (props: Props) => {
    const addPathHandler = (req: PathLogsType) => {
        props.addPath(req)
    }
    return (
        <>
            {props.data && <>
                <CCol className={'mb-3 p-0'}>
                    <CCol className={'p-0'} md={12}>
                        <CRow >
                            <CLabel className={'font-weight-bold font-sm'}>On:</CLabel>
                            <CLabel className={'font-sm'}>{formatDateTime(props?.data?.on ?? '')}</CLabel>
                        </CRow>
                    </CCol>
                    <CCol className={'p-0'} md={12}>
                        <CRow className={'p-0'}>

                        <CLabel className={'font-weight-bold font-sm'}>Agent Id:</CLabel>
                        <CLabel className={'font-sm'}>{props?.data?.log?.AgentId}</CLabel>
                            </CRow>
                    </CCol>

                </CCol>



                    <CRow className={'logs-values-path-container p-0'}>

                        {props.data.log.ExternalCallLogs.map((logs, index) => {
                            return (
                                <CCol >

                                    <CLabel
                                        className={'logs-values-path'}
                                        onClick={() => addPathHandler({
                                            name: "EXTERNAL_DETAILS",
                                            title: `External ${index + 1}`,
                                            externalIndex: index
                                        })} type={'button'}>{`External ${index + 1} `}</CLabel>
                                </CCol>

                            )
                        })}

                        <CCol >
                            <CLabel onClick={() => props.addPath({
                                title: 'Request',
                                name: 'VIEW_DATA',
                                type: 'json',
                                dataToView: JSON.stringify(props.data.log.Request)
                            })} className={'logs-values-path'}>Request</CLabel>
                        </CCol>
                        <CCol >
                            <CLabel  onClick={() => props.addPath({
                                title: 'Response',
                                name: 'VIEW_DATA',
                                type: 'json',
                                dataToView: JSON.stringify(props.data.log.Response)
                            })}  className={'logs-values-path'}>Response</CLabel>
                        </CCol>
                    </CRow>

            </>}

        </>
    )
}
export default LogsExternal
