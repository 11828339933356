import React, { useState } from "react";
import {
  CButton,
  CCol,
  CInputCheckbox,
  CInputGroup,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
} from "@coreui/react";
import DateAndTimePicker from "../../../SharedComponents/SWForm/DateAndTimePicker";
import set from "date-fns/fp/set/index.js";
import ActionButton from "../../../SharedComponents/ActionButton";

interface IProps {
  onClose: () => any;
  onSubmit: (date: any, methods: any) => any;
}

const Component = (props: IProps) => {
  const [disabled, setDisabled] = useState(false);
  const onClick = () => {
    setDisabled(true);
    onSubmit(date, methods as any)
  }


  const [date, setDate] = useState<any>(new Date(Date.now()));
  const [methods, setMethods] = useState<string[]>([]);
  const { onClose, onSubmit } = props;
  return (
    <div className="small">
      <CModal show={true} onClose={onClose} centered>
      <CModalHeader>
        <h5>Confirm</h5>
      </CModalHeader>
      <CModalBody>
        <p className={"mb-2"}>Are you sure you want to cancel this quotation</p>
        <DateAndTimePicker
          value={date}
          handleOnChange={(v: string) => setDate(v)}
        />

        <CRow className={"mt-2 pl-4"}>
          <CCol>
            <CInputGroup className="">
              <CInputCheckbox
                checked={methods.includes("sms")}
                onChange={() =>
                  methods.includes("sms")
                    ? setMethods([...methods.filter((m) => m != "sms")])
                    : setMethods([...methods, "sms"])
                }
              />
              <CLabel>SMS</CLabel>
            </CInputGroup>
            <CInputGroup className="">
              <CInputCheckbox
                checked={methods.includes("email")}
                onChange={() =>
                  methods.includes("email")
                    ? setMethods([...methods.filter((m) => m != "email")])
                    : setMethods([...methods, "email"])
                }
              />
              <CLabel>Email</CLabel>
            </CInputGroup>
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CRow className="justify-content-between w-100">
          <CCol className="text-left">
            <CButton style={{fontSize:"10px"}} variant="outline" color="danger" onClick={onClose}>
              Cancel
            </CButton>
          </CCol>
          <CCol className="text-right">
            <ActionButton
                text={"Yes"}
                disabled={disabled}
                onClick={onClick}
            />
          </CCol>
        </CRow>
      </CModalFooter>
    </CModal>
    </div>

  );
};

export default Component;
