import {CButton, CCol, CDataTable, CRow, CTooltip} from "@coreui/react";
import {
    InvoiceAttachment,
    InvoiceAttachmentAdd,
    InvoiceAttachmentType,
    InvoiceDto,
    InvoiceStatus
} from "../../../../models/accounting";
import {freeSet} from "@coreui/icons";
import React, {useEffect, useState} from "react";
import AddUpdateInvoiceAttachmentModal from "./AddUpdateInvoiceAttachmentModal";
import AccountingClient from "../../../../clients/accountingClient";
import {useDispatch} from "react-redux";
import {ShowNotification} from "../../../../store/actions/auth";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {downloadBlob} from "../../../../utils/downloadUtils";
import CIcon from "@coreui/icons-react";
import ConfirmModal from "../../../SharedComponents/ConfirmModal";
import SWInput from "../../../SharedComponents/SWInput";
import {tr} from "date-fns/locale";
import ItemsTable from "../../Quotations/components/ItemsTable";
import {handleCopyToClipboard} from "../../../SharedComponents/Extensions";

interface IProps {
    invoice?: InvoiceDto
    refreshData: () => void
}

const InvoiceAttachmentsTable = (props: IProps) => {
    const accountingClient = new AccountingClient();
    const [showAttachmentModal, setShowAttachmentModal] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [selectedAttachment, setSelectedAttachment] = useState<InvoiceAttachment>()
    const [showDeleteAttachmentModal, setShowDeleteAttachmentModal] = useState<boolean>(false)
    const [attachmentLocation, setAttachmentLocation] = useState<string>()
    const dispatch = useDispatch();

    const handleAttachmentSaved = async (attachment: InvoiceAttachmentAdd) => {
        let rs;
        if (isEdit)
            rs = await accountingClient.UpdateInvoiceAttachment(selectedAttachment?.id ?? 0, attachment)
        else
            rs = await accountingClient.AddInvoiceAttachment(attachment)
        if (rs) {
            dispatch(ShowNotification("Success", "Attachment saved successfully", false))
            setIsEdit(false)
            setSelectedAttachment(undefined)
            setShowAttachmentModal(false)
            props.refreshData();
        }
    }

    const handleUploadFile = async (file?: File) => {
        const blob = await accountingClient.UploadFile(file)
        await setAttachmentLocation(blob.location)
        setShowAttachmentModal(true)
    }

    const scopedSlots = {
        download: (item: InvoiceAttachment) => {
            return <td>
                <CIcon
                    content={freeSet.cilCloudDownload}
                    style={{cursor: "pointer", margin: "auto"}}
                    onClick={() => downloadBlob(item.location, item.name)}
                />
                <CIcon
                    content={freeSet.cilCopy}
                    style={{cursor: "pointer", marginLeft: "8px"}}
                    onClick={() => {
                        handleCopyToClipboard(item.location).then(() =>{
                            dispatch(ShowNotification("Success", "Invoice URL Copied successfully"))
                        })
                    }}
                />
            </td>
        },
        type: (item: InvoiceAttachment) => {
            return <td>
                {InvoiceAttachmentType[item.type]}
            </td>
        },
        actions: (item: InvoiceAttachment) => {
            return <td>
                <>
                    <CTooltip content={"Edit Attachment"}>
                        <CIcon content={freeSet.cilPencil}
                               style={{cursor: "pointer", marginRight: "5px"}}
                               onClick={() => {
                                   setSelectedAttachment(item)
                                   setIsEdit(true)
                                   setShowAttachmentModal(true)
                               }}/>
                    </CTooltip>
                    <CTooltip content={"Delete Attachment"}>
                        <CIcon style={{cursor: "pointer"}} content={freeSet.cilTrash} onClick={() => {
                            setSelectedAttachment(item)
                            setShowDeleteAttachmentModal(true)
                        }}/>
                    </CTooltip>
                </>
            </td>
        }
    }

    return <>
            <CCol>
                <CRow className={" w-20 mt-3 px-2"}>
                    <SWInput type={"file"} id={"input-file"}
                             onChangeFile={val => handleUploadFile(val)}/>
                </CRow>
            </CCol>
        <CDataTable
            hover
            size={"sm"}
            scopedSlots={scopedSlots}
            items={props.invoice?.attachments}
            fields={[
                "name",
                "type",
                "download",
                "includeInInvoiceEmail",
                {key: "actions", label: "", _style: {width: "5%"}}
            ]}
        />
        {showAttachmentModal &&
            <AddUpdateInvoiceAttachmentModal location={attachmentLocation} attachment={selectedAttachment}
                                             isEdit={isEdit} onClose={() => {
                setIsEdit(false)
                setSelectedAttachment(undefined)
                setAttachmentLocation(undefined)
                setShowAttachmentModal(false)
            }} handleSubmit={(v) => handleAttachmentSaved(v)}
                                             invoiceId={props.invoice?.id ?? 0}/>
        }
        {showDeleteAttachmentModal &&
            <ConfirmModal onClose={() => {
                setSelectedAttachment(undefined)
                setShowDeleteAttachmentModal(false)
            }} onSubmit={() => {
                accountingClient.DeleteInvoiceAttachment(selectedAttachment?.id ?? 0).then(v => {
                    if (v) {
                        setSelectedAttachment(undefined)
                        setShowDeleteAttachmentModal(false)
                        dispatch(ShowNotification("Success", "Attachment deleted successfully", false))
                        props.refreshData()
                    }
                })
            }} title={"Delete attachment"}
                          body={`Are you sure you want to delete attachment: '${selectedAttachment?.name}'?`}/>
        }
    </>
}

export default InvoiceAttachmentsTable