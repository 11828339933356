import React, { useEffect, useState, KeyboardEvent } from "react";
import { CCol, CModal, CModalBody, CModalFooter, CModalHeader, CRow, CTooltip } from "@coreui/react";
import { AddressBook } from "../../../../models/addressBook";
import ActionButton from "../../../SharedComponents/ActionButton";
import SWSelect from "../../../SharedComponents/SWSelect";
import ShipClient from "../../../../clients/shipClient";
import { useFormik } from "formik";
import { freeSet } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import SWInput from "../../../SharedComponents/SWInput";
import { useDispatch } from "react-redux";
import { SetIsLoading } from "../../../../store/actions/ui";
import { validateAddressBook } from "../../../../models/shipment";

interface Props {
    account?: string
    onClose: () => any
    onSave: (selected: AddressBook) => any
    children?: JSX.Element
    submitting?: boolean
    data?: AddressBook[]
    handleDeleteAddressBook?: (e: AddressBook) => void
    handleUpdateAddressBook?: (e: AddressBook) => void
    countryLookups: { [k: string]: string }
}

const AddressBookModal = (props: Props) => {
    const { data, onSave, onClose, handleUpdateAddressBook, handleDeleteAddressBook, account, countryLookups } = props;
    const shipClient = new ShipClient();
    const dispatch = useDispatch();
    const [addressBookLookups, setAddressBookLookups] = useState<{ [k: string]: string }>({});
    const [selectedAddressBook, setSelectedAddressBook] = useState<string>();
    const [action, setAction] = useState<"edit" | "delete" | null>(null);
    const [emailOrPhoneValidationError,setEmailOrPhoneValidationError] = useState<boolean>(false);
    const [companyNameOrNameValidationError,setCompanyNameOrNameValidationError] = useState<boolean>(false);


    useEffect(() => {
        getAddressBook()
    }, [data]);
    const getAddressBook = async () => {
        if (!!account) {
            dispatch(SetIsLoading(true));
            let data = await shipClient.SearchAddressBook({ account: account, lookup: true })
            setAddressBookLookups(data)
        } else {
            setAddressBookLookups({})
        }
    }

    const formik = useFormik<AddressBook>({
        enableReinitialize: true,
        // todo 
        initialValues: {},
        onSubmit: async (values) => {
            onSave(values);
            getAddressBook()
            setAction(null)
        },
        // todo 
        // validationSchema: {}
    })

    const { values, setFieldValue, touched, errors, setValues, submitForm, handleSubmit, resetForm } = formik;

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    const updateAddressBook = () => {
        const {phone,email,name,companyName} = values;
        setCompanyNameOrNameValidationError(false);
        setEmailOrPhoneValidationError(false);
        const addressBookValidation = validateAddressBook({
            email,
            phone,
            companyName,
            name
        })
        if(addressBookValidation.isValid){
            handleUpdateAddressBook?.(values)
            setAction(null)
        }else{
            if(!addressBookValidation?.isCompanyNameOrContactNameValid) setCompanyNameOrNameValidationError(true)
            if(!addressBookValidation?.isEmailOrPhoneValid) setEmailOrPhoneValidationError(true)
        }
    };

    return (
        <CModal
            size={"lg"}
            closeOnBackdrop={false}
            show={true}
            centered
            onClose={onClose}
            onKeyDown={handleKeyDown}
        >
            <CModalHeader closeButton>
                <h5>Address Book</h5>
            </CModalHeader>
            <CModalBody>
                <CRow>
                    <CCol md={12}>
                        <SWSelect
                            isClearable={true}
                            optionLabelIcon={
                                <>
                                    <CTooltip content={"Edit"}>
                                        <CIcon content={freeSet.cilPencil}
                                            style={{ cursor: "pointer", marginRight: "5px" }}
                                            onClick={(e) => setAction("edit")}
                                        />
                                    </CTooltip>
                                    <CTooltip content={"Delete"}>
                                        <CIcon style={{ cursor: "pointer" }}
                                            content={freeSet.cilTrash}
                                            onClick={(e) => {
                                                setAction("delete")
                                            }} />
                                    </CTooltip>
                                </>
                            }
                            labelClicked={()=>{setAction(null)}}
                            handleIconClick={(e: string) => { }}
                            label={"Search"}
                            name={"addressBook"}
                            value={selectedAddressBook ?? ""}
                            onChange={(e) => {
                                setSelectedAddressBook(e)
                                setValues(data?.find((item) => item.id == Number(e)) ?? {});
                            }}
                            values={addressBookLookups}
                        />
                    </CCol>
                </CRow>
                {action == "edit" && selectedAddressBook &&
                    <CRow className={"mt-2"}>
                        <CCol md={10}>
                            <CRow>
                                <CCol md={4} sm={12}>Company Name:</CCol>
                                <CCol md={8} sm={12} >
                                    <SWInput
                                        label={""}
                                        name={`companyName`}
                                        value={values?.companyName}
                                        onChange={(e) => setFieldValue(`companyName`, e)}
                                        type={"text"}
                                        validationErrors={touched?.companyName && errors?.companyName?.toString() || companyNameOrNameValidationError ? "Contact Name or Company Name is required" :""}
                                    />
                                </CCol>
                            </CRow>
                            <div style={{ marginTop: -10 }}>
                                <CRow>
                                    <CCol md={4} sm={12}>Contact Name:</CCol>
                                    <CCol md={8} sm={12} >
                                        <SWInput
                                            label={""}
                                            name={`name`}
                                            value={values?.name}
                                            onChange={(e) => setFieldValue(`name`, e)}
                                            type={"text"}
                                            validationErrors={touched?.name && errors?.name?.toString() || companyNameOrNameValidationError ? "Contact Name or Company Name is required" :""}
                                        />
                                    </CCol>
                                </CRow>
                            </div>
                        </CCol>

                        <CCol md={10} >
                            <div style={{ marginTop: -10 }}>
                                <CRow>
                                    <CCol md={4} sm={12}>Street:</CCol>
                                    <CCol md={8} sm={12} >
                                        <SWInput
                                            label={""}
                                            name={`addressLine1`}
                                            value={values?.addressLine1}
                                            onChange={(e) => setFieldValue(`addressLine1`, e)}
                                            type={"text"}
                                            validationErrors={touched?.addressLine1 && errors?.addressLine1?.toString() || ""}
                                        />
                                    </CCol>
                                </CRow>
                            </div>
                            <div style={{ marginTop: -10 }}>
                                <CRow>
                                    <CCol md={4} sm={12}>Address 2:</CCol>
                                    <CCol md={8} sm={12} >
                                        <SWInput
                                            label={""}
                                            name={`addressLine2`}
                                            value={values?.addressLine2}
                                            onChange={(e) => setFieldValue(`addressLine2`, e)}
                                            type={"text"}
                                            validationErrors={touched?.addressLine2 && errors?.addressLine2?.toString() || ""}
                                        />
                                    </CCol>
                                </CRow>
                            </div>
                        </CCol>


                        <CCol md={10} >
                            <div style={{ marginTop: -10 }}>
                                <CRow>
                                    <CCol md={4}>Zip Code /City:</CCol>
                                    <CCol md={3}>
                                        <SWInput
                                            label={""}
                                            name={`postCode`}
                                            value={values?.postCode}
                                            onChange={(e) => setFieldValue(`postCode`, e)}
                                            type={"text"}
                                            validationErrors={touched?.postCode && errors?.postCode?.toString() || ""}
                                        />
                                    </CCol>
                                    <CCol md={5}>
                                        <SWInput
                                            label={""}
                                            name={`city`}
                                            value={values?.city}
                                            onChange={(e) => setFieldValue(`city`, e)}
                                            type={"text"}
                                            validationErrors={touched?.city && errors?.city?.toString() || ""}
                                        />


                                    </CCol>
                                </CRow>
                            </div>
                            <div style={{ marginTop: -10 }}>
                                <CRow>
                                    <CCol md={4} sm={12}>Country:</CCol>
                                    <CCol md={8} sm={12} >
                                        <SWSelect
                                            label={""}
                                            validationErrors={touched?.country && errors?.country?.toString() || ""}
                                            name={`country`}
                                            value={values?.country}
                                            onChange={(e) => setFieldValue(`country`, e)}
                                            values={countryLookups}
                                        />
                                    </CCol>
                                </CRow>
                            </div>
                        </CCol>



                        <CCol md={10} >
                            <div style={{ marginTop: -10 }}>
                                <CRow>
                                    <CCol md={4} sm={12}>Telephone:</CCol>
                                    <CCol md={8} sm={12} >

                                        <SWInput
                                            labelClassName='m-0 small'
                                            label={""}
                                            name={`phone`}
                                            value={values?.phone}
                                            onChange={(e) => setFieldValue(`phone`, e)}
                                            type={"text"}
                                            validationErrors={touched?.phone && errors?.phone?.toString() || emailOrPhoneValidationError ? "Telephone or Email is required" :""}
                                        />
                                    </CCol>
                                </CRow>
                            </div>
                            <div style={{ marginTop: -10 }}>
                                <CRow>
                                    <CCol md={4} sm={12}>Email:</CCol>
                                    <CCol md={8} sm={12} >
                                        <SWInput
                                            label={""}
                                            name={`email`}
                                            value={values?.email}
                                            onChange={(e) => setFieldValue(`email`, e)}
                                            type={"text"}
                                            validationErrors={touched?.email && errors?.email?.toString() || emailOrPhoneValidationError ? "Telephone or Email is required" :""}
                                        />
                                    </CCol>
                                </CRow>
                            </div>
                        </CCol>

                        <CCol md={10} >
                            <div style={{ marginTop: -10 }}>
                                <CRow>
                                    <CCol md={4}>Notes:</CCol>
                                    <CCol md={8} sm={12} >
                                        <SWInput
                                            label={""}
                                            name={`notes`}
                                            value={values?.notes}
                                            onChange={(e) => setFieldValue(`notes`, e)}
                                            type={"text"}
                                            validationErrors={touched?.notes && errors?.notes?.toString() || ""}
                                        />
                                    </CCol>
                                </CRow>
                            </div>
                            <div style={{ marginTop: -10 }}>
                                <CRow>
                                    <CCol md={4}>EORI:</CCol>
                                    <CCol md={8} sm={12} >
                                        <SWInput
                                            label={""}
                                            name={`eori`}
                                            value={values?.eori}
                                            onChange={(e) => setFieldValue(`eori`, e)}
                                            type={"text"}
                                            validationErrors={touched?.eori && errors?.eori?.toString() || ""}
                                        />
                                    </CCol>
                                </CRow>
                            </div>
                        </CCol>
                        <CRow className="justify-content-between w-100 mt-2 mx-auto">
                            <CCol className={""}>
                                <ActionButton
                                    variant="outline"
                                    color="danger"
                                    text={"Cancel"}
                                    disabled={!selectedAddressBook}
                                    onClick={() => {
                                        setSelectedAddressBook(undefined)
                                        setAction(null)
                                    }}
                                />
                            </CCol>
                            <CCol className={"text-right"}>
                                <ActionButton
                                    text={"Update"}
                                    disabled={!selectedAddressBook}
                                    onClick={() => updateAddressBook()}
                                />
                            </CCol>
                        </CRow>
                    </CRow>
                }

                {action == "delete" && selectedAddressBook &&
                    <>
                        <CCol className={"mt-2"}>
                            <h6>{`Are you sure you want to delete this address book?`}</h6>
                        </CCol>
                        <CRow className="justify-content-between w-100 mt-4 mx-auto ">
                            <CCol className={""}>
                                <ActionButton
                                    variant="outline"
                                    color="danger"
                                    text={"Cancel"}
                                    disabled={!selectedAddressBook}
                                    onClick={() => {
                                        setSelectedAddressBook(undefined)
                                        setAction(null)
                                    }}
                                />
                            </CCol>
                            <CCol className={"text-right"}>
                                <ActionButton
                                    text={"Delete"}
                                    disabled={!selectedAddressBook}
                                    onClick={() => {
                                        handleDeleteAddressBook?.(values)
                                        setSelectedAddressBook(undefined)
                                        setAction(null)
                                    }}
                                />
                            </CCol>
                        </CRow>
                    </>
                }
            </CModalBody>

            <CModalFooter>
                {!action &&
                    <CRow className="justify-content-between w-100 mt-2">
                        <CCol className={"text-right"}>
                            <ActionButton
                                text={"Ok"}
                                disabled={!selectedAddressBook}
                                onClick={() => {
                                    submitForm()
                                }}
                            />
                        </CCol>
                    </CRow>}
            </CModalFooter>
        </CModal >
    )
}

export default AddressBookModal