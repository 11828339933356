import {
    CButton,
    CButtonGroup,
    CCardBody,
    CCol,
    CContainer, CInputCheckbox, CLabel,
    CModal,
    CModalBody,
    CModalHeader,
    CRow,
} from "@coreui/react";
import React, {useEffect, useState} from "react";
import {BillingType, CalculateTaxRequest, ChargeAddUpdate, ChargeSearch} from "../../../../models/accounting";
import {useFormik} from "formik";
import {ShowNotification} from "../../../../store/actions/auth";
import * as yup from "yup";
import {StandardShipment} from "../../../../models/shipment";
import SWSelect from "../../../SharedComponents/SWSelect";
import SWInputNumber from "../../../SharedComponents/SWInputNumber";
import SWInput from "../../../SharedComponents/SWInput";
import AccountingClient from "../../../../clients/accountingClient";
import {TableHeader} from "../ShipmentTablesComponents/TableHeader";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import {useDispatch} from "react-redux";
import ConfirmModal from "../../../SharedComponents/ConfirmModal";
import {tr} from "date-fns/locale";
import ShipClient from "../../../../clients/shipClient";

interface Props {
    onSave?: (charge: ChargeAddUpdate) => void;
    onClose: () => void;
    chargeToEdit?: ChargeSearch
    chargeTypes: { [k: string]: string }
    billingType: BillingType
    shipment?: StandardShipment
    shipmentUid?: string
    account: string
    showList: boolean,
    accountingClient: AccountingClient
    onChargePresent?: (present: boolean) => void
    invoiceId?: number
    shipClient?: ShipClient
    hideIsReadyToBeInvoicedCheckbox?: boolean
    hideIsGroupedCheckBox: boolean
}

const AddEditCharge = (props: Props) => {
    const [showConfirmDelete, setShowConfirmDelete] = useState(0);
    const [isSaving, setIsSaving] = useState(false)
    const dispatch = useDispatch()
    const {
        onSave,
        onClose,
        chargeToEdit,
        chargeTypes,
        billingType,
        account,
        invoiceId,
        shipment,
        shipmentUid,
        accountingClient,
        showList,
        onChargePresent,
        shipClient,
        hideIsReadyToBeInvoicedCheckbox,
        hideIsGroupedCheckBox
    } = props;
    const Td = (value: { val: any, empty?: string }) => <td> {value.val ?? value.empty} </td>
    const header =
        ["Id", "Type", "Value", "Tax", "Total With Tax", "Is ReadyTo Be Invoiced",
            "Comments", ""];

    const [charges, setCharges] = useState<ChargeSearch[]>([])
    const [shipmentNumber, setShipmentNumber] = useState<string>()
    const [hasInvalidShipmentNumber, setHasInvalidShipmentNumber] = useState<boolean>(false)
    const [shipmentValidationError, setShipmentValidationError] = useState<string>("")


    const {
        setTouched,
        setValues,
        values,
        errors,
        handleBlur,
        touched,
        handleChange,
        resetForm,
        setFieldValue,
        handleSubmit,
    } = useFormik<ChargeAddUpdate>({
        initialValues: {
            account: account,
            value: chargeToEdit?.value ?? 0,
            typeId: chargeToEdit?.typeId ?? "",
            billingType: billingType,
            taxRate: (chargeToEdit?.taxRate ?? 0) * 100,
            shipmentUid: shipmentUid,
            id: chargeToEdit?.id ?? 0,
            currency: "EUR",
            isReadyToBeInvoiced: chargeToEdit?.isReadyToBeInvoiced ?? true,
            comments: chargeToEdit?.comments ?? "",
            taxCode: chargeToEdit?.taxCode ?? "",
            isTaxable: chargeToEdit ? chargeToEdit?.taxCode == "Y" : true,
            addedManually: true,
            isGrouped: chargeToEdit?.isGrouped ?? false,
            totalValue:chargeToEdit?.totalValue ?? undefined,
            taxValue:chargeToEdit?.taxValue ?? undefined

        },
        onSubmit: async (values) => {
            if (onSave)
                onSave({...values, taxRate: values.taxRate / 100})
            if (showList)
                await handleSave({...values, taxRate: values.taxRate / 100})

        },
        validationSchema: yup.object({
            typeId: yup.string().required("Required"),
            comments: yup.string().max(1000),
            value: yup.number().required("required"),

        }),
    });


    const handleSave = async (toAddOrUpdate: ChargeAddUpdate) => {
        if (!accountingClient)
            return;
        setIsSaving(true)
        const result = toAddOrUpdate.id == 0 ?
            await accountingClient.AddCharge(toAddOrUpdate) :
            await accountingClient.UpdateCharge(toAddOrUpdate);
        setIsSaving(false)
        if (result) {
            dispatch(ShowNotification("Success", "Charge saved successfully", false));
            await refreshCharges()
            resetForm();
        }

    }
    const handleDelete = async () => {
        if (!accountingClient)
            return;
        const result = await accountingClient.DeleteCharge(showConfirmDelete);
        if (result) {
            dispatch(ShowNotification("Success", "Charge deleted successfully", false));
            if (values.id == showConfirmDelete)
                resetForm();
            await refreshCharges()
        }

        setShowConfirmDelete(0);
    }
    const handleEdit = (toAddOrUpdate: ChargeAddUpdate) => {
        setFieldValue("id", toAddOrUpdate.id)
        setFieldValue("value", toAddOrUpdate.value)
        setFieldValue("taxRate", toAddOrUpdate.taxRate * 100)
        setFieldValue("comments", toAddOrUpdate.comments)
        setFieldValue("billingType", toAddOrUpdate.billingType)
        setFieldValue("typeId", toAddOrUpdate.typeId)

    }
    useEffect(() => {
        if (!showList)
            return;
        refreshCharges()
    }, [])

    const refreshCharges = async () => {
        if (!shipmentUid) return
        if (!accountingClient)
            return;

        const allCharges = await accountingClient.GetShipmentCharges(shipmentUid);
        if (!allCharges)
            return;
        const chargesByType = allCharges.filter(ch => ch.billingType == billingType)
        setCharges(chargesByType)

        if (onChargePresent)
            onChargePresent(chargesByType.length > 0)

    }
    const onTypeChange = async (v?: string) => {
        await setFieldValue("typeId", v)
        if (!v || values.billingType == BillingType.Cost)
            return;
        await CalculateTax(v)
    }

    const CalculateTax = async (v: string, isTaxable?: boolean) => {
        const request: CalculateTaxRequest = {
            typeId: v,
            shipmentUid: values.shipmentUid ?? undefined,
            account: values.account,
            isTaxable: isTaxable
        }
        const tax = await accountingClient?.CalculateTaxes(request)
        await setFieldValue("taxRate", tax.taxRate);
        await setFieldValue("taxCode", tax.taxCode)
    }

    const validateShipment = async () => {
        if (!shipmentNumber) {
            setHasInvalidShipmentNumber(false)
            setShipmentValidationError("")
            return
        }
        const rs = await shipClient?.getShipmentByNumber(shipmentNumber)
        if (!rs?.found || !rs.shipment) {
            setHasInvalidShipmentNumber(true)
            setShipmentValidationError("Invalid shipment number")
            await setFieldValue("shipmentUid", undefined)
        } else if (rs.shipment.account != props.account) {
            setHasInvalidShipmentNumber(true)
            setShipmentValidationError(`This shipment doesn't belong to this customer`)
        } else {
            await setFieldValue("shipmentUid", rs.shipment.uid)
            setHasInvalidShipmentNumber(false)
            await CalculateTax(values.typeId)
        }
    }
    return (
        <>
            <CModal className={"ml-auto mr-auto"} size={"lg"} centered
                    closeOnBackdrop={false}
                    onClose={onClose}
                    show
            >
                <CModalBody>
                    <CContainer className="form-group large">
                        <CCardBody className="editShipperDetailsCard ml-3 mb-3">
                            <h6>
                                <CModalHeader className={"px-0"}>
                                    <strong>{chargeToEdit ? "Edit" : "Add"} Charge</strong>
                                </CModalHeader>
                            </h6>
                            <CRow className="mb-4 mt-4">
                                <CCol>
                                    <SWSelect labelClassName={"col-form-label"} label={"type"} values={chargeTypes}
                                              value={values.typeId}
                                              onChange={onTypeChange}
                                              validationErrors={errors.typeId}/>
                                </CCol>
                                <CCol>
                                    <SWInputNumber labelClassName={"col-form-label"} label={"Amount"}
                                                   value={values.value}
                                                   onChange={(v) => setFieldValue("value", v)}
                                                   validationErrors={errors.value} acceptsNegative/>
                                </CCol>
                            </CRow>
                            <CRow>
                                {!shipmentUid &&
                                    <CCol>
                                        <SWInput
                                            labelClassName={"col-form-label"}
                                            validationErrors={shipmentNumber && hasInvalidShipmentNumber ? shipmentValidationError : ""}
                                            label={"Shipment Number"} value={shipmentNumber}
                                            onChange={v => {
                                                if (v)
                                                    setHasInvalidShipmentNumber(true)
                                                else
                                                    setHasInvalidShipmentNumber(false)
                                                setShipmentNumber(v)
                                            }} onBlur={() => validateShipment()}/>
                                    </CCol>}
                                <CCol md={6}>
                                    <SWInput labelClassName={"col-form-label"} label={"Comments"}
                                             value={values.comments} validationErrors={errors.taxRate}
                                             onChange={(v) => setFieldValue("comments", v)}/>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol>
                                    <h5>Tax Information:</h5>
                                    <h6 className={"ml-3"}>Tax Code: {values.taxCode}</h6>
                                          <h6 className={"ml-3"}>Tax
                                        Amount: {values?.taxValue??(values.taxRate * values.value).toFixed(2)}</h6>
                                         <h6 className={"ml-3"}>Total With
                                        Tax: {values?.totalValue??(Number(values.value) + Number(values.taxRate * values.value)).toFixed(2) ?? 0}</h6>
                                    {!values.shipmentUid && <div style={{marginLeft: "6%"}}>
                                        <CInputCheckbox checked={values.isTaxable} onClick={async () => {
                                            await CalculateTax(values.typeId, !values.isTaxable)
                                            await setFieldValue("isTaxable", !values.isTaxable)
                                        }}/><CLabel className={"mt-1"}>Taxable</CLabel></div>}
                                </CCol>
                            </CRow>
                            {billingType == BillingType.Selling && !hideIsReadyToBeInvoicedCheckbox && <CRow>
                                <CCol className={"ml-4"}>
                                    <CInputCheckbox checked={values.isReadyToBeInvoiced}
                                                    onClick={() => setFieldValue("isReadyToBeInvoiced", !values.isReadyToBeInvoiced)}/>
                                    <CLabel className={"mt-1"}>Is Ready To Be Invoiced</CLabel>
                                </CCol>
                            </CRow>}
                            {billingType == BillingType.Selling && !hideIsGroupedCheckBox && <CRow>
                                <CCol className={"ml-4"}>
                                    <CInputCheckbox checked={values.isGrouped}
                                                    onClick={() => setFieldValue("isGrouped", !values.isGrouped)}/>
                                    <CLabel className={"mt-1"}>Grouped</CLabel>
                                </CCol>
                            </CRow>}
                            <CRow>
                                <CCol className="text-right">
                                    <CButtonGroup className="mb-0 mt-4">
                                        <CButton
                                            onClick={handleSubmit}
                                            type="button"
                                            className="w-auto bg-primary text-white ml-1 mr-1"
                                            disabled={isSaving || hasInvalidShipmentNumber}
                                        >
                                            Save
                                        </CButton>
                                        <CButton
                                            className="w-auto bg-primary text-white ml-1 mr-1"
                                            onClick={onClose}
                                        >
                                            Close
                                        </CButton>
                                    </CButtonGroup>
                                </CCol>
                            </CRow>
                            <br/>
                            {showList &&
                                <CRow>
                                    <CCol>
                                        <table className="position-relative table table-hover table-sm">
                                            <TableHeader header={header}/>

                                            <tbody>
                                            {chargeTypes && charges?.map(charge =>
                                                <tr key={charge.id}>
                                                    <Td val={charge.id}/>
                                                    <Td val={chargeTypes[charge.typeId]}/>
                                                    <Td val={charge.value}/>
                                                    <Td val={charge.taxRate * charge.value}/>
                                                    <Td val={charge.totalValue}/>
                                                    <Td val={charge.isReadyToBeInvoiced ? "true" : "false"}/>
                                                    <Td val={charge.comments}/>

                                                    {!charge.quotationNumber && !charge.invoiceNumber ?
                                                        <td>
                                                            <CIcon
                                                                content={freeSet.cilPencil}
                                                                style={{cursor: "pointer", margin: "auto"}}
                                                                onClick={() => handleEdit(charge)}
                                                            />
                                                            <CIcon
                                                                content={freeSet.cilTrash}
                                                                style={{cursor: "pointer", margin: "auto"}}
                                                                onClick={() => setShowConfirmDelete(charge.id)}
                                                            />
                                                        </td>
                                                        : <td/>}
                                                </tr>)
                                            }
                                            </tbody>
                                        </table>
                                    </CCol>

                                </CRow>
                            }
                        </CCardBody>
                    </CContainer>
                </CModalBody>
            </CModal>

            {showConfirmDelete != 0 &&
                <ConfirmModal body={"Are you sure you want to delete charge"} onClose={() => setShowConfirmDelete(0)}
                              title={"Delete charge"} onSubmit={handleDelete}/>}
        </>

    );
};

export default AddEditCharge;
