import {ContainerSearch, ContainerShipmentSearch} from "./container";
import {SearchyRequest} from "./Searchy";
import {DimensionUnit, SkuSearch, Weight} from "./warehouse";
import * as yup from "yup"
import {AccountAttachment} from "../store/actions/accounts";
import {number} from "yup";
import {DropDownData} from "../components/ViewComponents/Accounting/components/ViewShipmentData";

export interface ShipmentSearchRequest extends SearchyRequest {
    reportId?: number
}

export interface GenerateLabelRequest {
    reportId: number;
}

export interface AddShipmentTrace {
    code: string;
    comment1?: string;
    comment2?: string;
    actionDate: string;
    country?: string;
    state?: string;
    postCode?: string;
    city?: string;
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    longitude?: number | null;
    latitude?: number | null;
}

export interface StandardShipmentTrace {
    id: number;
    code: string;
    pieceNumber: string;
    reference: string;
    comment1: string;
    comment2: string;
    actionDate: string;
    country: string;
    state: string;
    postCode: string;
    city: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    longitude: number | null;
    latitude: number | null;
    createdOn: string;
    createdBy: string;
    statusName: string;

}

export interface StandardShipmentItem {
    productCode?: string
    reference?: string
    description?: string
    countryOfOrigin?: string
    quantity?: number
    unitOfMeasure?: string
    dutiable?: boolean
    hsCode?: string
    value?: number
    weight?: number
    warehouse?: string
    quantityAvailable?: string
}

export interface StandardShipmentPiece {
    number?: string
    reference?: string
    weight?: number
    length?: number
    width?: number
    height?: number
    dimensionsAvailable?: boolean
}


export interface BookingSearch {
    agent: string,
    agentName: string,
    number: string,
    service: string,
    status: number,
}

export interface BookingUpdate {
    agent?: string,
    number?: string,
    service?: string,
    status?: string,
    flags?: number
}

export interface ShipmentSortResult {
    containerNumber: string;
}


export interface ScanResult {
    shipmentNumber: string;
    status: string;
    containerNumber: string;
    containerPrefix: string;
    color?: string;
}


export interface ShipmentExtraServiceSearch {
    description: string;
    reference: string;
    type: string;
}


export interface ContainerAddShipmentsResult {
    notFound: string[]
    found: ContainerShipmentSearch[]
    alreadyIn: ContainerShipmentSearch[]
}

export interface ShipmentSearch {
    shipmentCod: NewCodStatusDetails[]
    agent: string;
    service: string;
    createdBy: string;
    incoterm: string;
    hasBooking: boolean
    id: number;
    uid: string;
    number: string;
    flags: string;
    traceFlags: string;
    createdOn: string;
    pickupDate: string | null;
    account: string;
    accountReference: string;
    weight: number | null;
    chargeWeight: number | null;
    weightUnit: string;
    dimensionUnit: string;
    totalPieces: number;
    totalItems: number;
    value: number | null;
    valueCurrency: string;
    customsShippingCost: number | null;
    customsShippingCostCurrency: string;
    cOD: number | null;
    cod: number | null;
    onlineCOD: number | string;
    cODCurrency: string;
    codCurrency: string;
    shipperCompanyName: string;
    shipperReference: string;
    shipperEori: string;
    shipperNotes: string;
    shipperName: string;
    shipperEmail: string;
    shipperPhone: string;
    shipperSecondPhone: string;
    shipperAddressReference: string;
    shipperCountry: string;
    shipperState: string;
    shipperPostCode: string;
    shipperCity: string;
    shipperAddressLine1: string;
    shipperAddressLine2: string;
    shipmentTraceSummaryItem: ShipmentTraceSummaryItem | null;
    shipperAddressLine3: string;
    consigneeCompanyName: string;
    consigneeReference: string;
    consigneeEori: string;
    consigneeNotes: string;
    consigneeName: string;
    consigneeEmail: string;
    consigneePhone: string;
    consigneeSecondPhone: string;
    consigneeAddressReference: string;
    consigneeCountry: string;
    consigneeState: string;
    consigneePostCode: string;
    consigneeCity: string;
    consigneeAddressLine1: string;
    consigneeAddressLine2: string;
    consigneeAddressLine3: string;
    ioss: string;
    traces: StandardShipmentTrace[];
    newTraces: StandardShipmentTrace[];
    missingTraces: StandardShipmentTrace[];
    items: StandardShipmentItem[];
    pieces: StandardShipmentPiece[];
    bookings: BookingSearch[];
    extraServices: ShipmentExtraServiceSearch[];
    comments: ShipmentComment[];
    attachments: ShipmentAttachment[]
    deleted: boolean
    isForceDeleted: boolean;
    blocked?: boolean
    hasErrors?: boolean
    pendingPayment?: boolean;
    assignedTo: string;
}

export interface Money {
    amount?: number,
    currency?: string
}

export interface ShipmentParty {
    companyName?: string
    reference?: string
    notes?: string
    name?: string
    email?: string
    phone?: string
    secondPhone?: string

    addressReference?: string
    country?: string
    state?: string
    postCode?: string
    city?: string
    addressLine1?: string
    addressLine2?: string
    addressLine3?: string
    eori?: string
}

export interface StandardShipment {
    uid?: string
    number?: string
    pickupDate?: string
    accountReference?: string
    account?: string
    weight: Weight
    dimensionUnit?: DimensionUnit
    value: Money
    cOD: Money
    iOSS?: string
    incoterm?: string
    shipper: ShipmentParty
    consignee: ShipmentParty
    pieces?: StandardShipmentPiece[]
    items?: StandardShipmentItem[]
    //extraServices? StandardShipmentExtraService[]
    //
    // public ICollection<StandardShipmentTrace> Traces { get; set; }
    //
    // public ICollection<StandardShipmentReference> References { get; set; }
    //
    // public IDictionary<string, string> ExtraData { get; set; }
    //
    createdOn?: string
    onlineCOD?: number
    flags?: ShipmentFlags
}

export enum ShipmentFlags {
    Pickup = 1,
    Residential = 2,
    IdCheck = 4,
    Insurance = 8,
    Weekend1 = 16,
    Weekend2 = 32
}

export interface ShipmentGet extends ShipmentSearch {
    communications: any[];
    attachments: ShipmentAttachment[]
    routes: ShipmentRouteGet[]
    containers: ContainerSearch[]
    hasErrors?: boolean
    errors?: ShipmentErrorGet[]
}

export interface ShipmentErrorGet {
    source: string;
    sourceName: string;
    code: string;
    message: string;
    subject: string;
    level: string;
};

export interface GenerateReport {
    Account: string;
    PayMode: string | null;
    DocumentNumber: string;
    DocumentDate: Date;
    ConsigneeCountry: string | null;
    Agent: string | number | null;
    hasShipmentNumber: boolean | null
}

export interface GetDiscrepancyCodRecord {
    account: string;
    payMode: string | null;
    consigneeCountry: string | null;
    agent: string | null;
    hasShipmentNumber: boolean | null;
}

export interface ShipmentCOD {
    payMode: string;
    totalAmount: number;
    totalShipments: number;
    totalRecords: number
}

export interface BankDepositReportsResponseModel {
    id: number;
    name: string;
    location: string;
    shipmentCods: ShipmentCodModel[];
    createdBy: string,
    totalRecords: number
    totalShipments: number,
    totalAmount: number,
    createdOn: string
    excelReportLocation: string;
}

export interface ShipmentCodModel {
    shipmentNumber: string;
    cod: number | null;
    amount: number;
    payMode: string;
    checkNumber: string;
    senderBank: string;
    documentDate: string;
    documentNumber: string;
}

export interface BankDepositModel extends BankDepositReportsResponseModel, ShipmentCodModel {
}

export interface DiscrepancyCodRecord {
    cod: number;
    shipmentNumber: string
    amount: number
    payMode: string
    senderBank: string
    documentNumber: string
    documentDate: string
}

export enum errorLevel {
    warning = 'Warning',
}

export enum errorSource {
    SN = 'SN',
}

export interface ShipmentTraceSummaryItem {
    createdBy: string | null;
    createdOn: string | Date | null;
    criticalCode: string | null;
    criticalDate: string | Date | null;
    deliveredCode: string | null;
    deliveredDate: string | Date | null;
    discrepancyCode: string | null;
    discrepancyDate: string | Date | null;
    finalCode: string | null;
    finalDate: string | Date | null;
    friendlyCodeName: traceStatus | null;
    lastUpdateCode: string;
    lastUpdateDate: string | Date | null;
    returnedCode: string | null;
    returnedDate: string | Date | null;
    shipmentId: number;
}

export type traceStatus =
    "PREPARED"
    | "CANCELLED"
    | "SHIPPED"
    | "TRANSIT"
    | "DISCREP"
    | "CUSTOMS"
    | "AGENCY"
    | "RETURNING"
    | "DELIVERED"
    | "RETURNED"
    | "PROBLEM"

export interface ShipmentAttachment {
    id: number
    location: string
    fileName: string
    by: string
    on: string
    agent: string
    type?: string
    urlKey: string
    sendToShipper: boolean
    sendToConsignee: boolean
    hideOnCustomer: boolean
}

export interface ShipmentRouteGet {
    id: number
    scenario?: string
    containerPrefix?: string
    containerRoute?: string
    containerType?: number
    containerTypeName?: string
    route?: string
    barcodes?: string[]
    exported: boolean
    canceled: boolean
    exportedOn?: string
    exportedBy?: string
    createdOn: string
    createdBy: string
}

export interface CountryLookup {
    [k: string]: string;
}

export interface ShipmentsImportRequest {
    account: string,
    fileUrl: string,

    fileName?: string
    type?: string,
    parameters: ShipmentsImportParameters
}

export interface ShipmentsImportParameters {
    map: { [k: string]: string | null }
    defaultValue?: ShipmentFlattened
}

export interface ShipmentsImportSearchRequest {
    limit?: number
    offset?: number
    createdOnFrom?: string
    createdOnTo?: string
    finished?: boolean
    account?: string
}

export interface ShipmentsImportSearch {
    createdOnFormatted: string;
    id: string
    fileUrl: string
    rowsImported: number
    totalRows: number
    shipmentsAdded: number
    shipmentsUpdated: number
    parameters: ShipmentsImportParameters
    finished: boolean
    account: string
    accountName: string
    createdOn: string
    createdBy: string
    modifiedOn: string
    modifiedBy: string

    errorDescription: ErrorDescription[]
}

export interface ErrorDescription {
    rowNumber: number
    errorCode: string
    errorDescription: string
}

export const shipmentFlattenedKeys = ["Number", "PieceNumber", "PickupDate", "ShipmentFlags", "Account", "Agent", "AccountReference", "WeightValue", "WeightUnit", "DimensionUnit",
    "ValueAmount", "ValueCurrency", "CustomsShippingCost", "CustomsShippingCostCurrency", "CODAmount", "CODCurrency", "OnlineCOD", "IOSS", "Incoterm", "ShipperCompanyName", "ShipperReference", "ShipperNotes",
    "ShipperName", "ShipperEmail", "ShipperPhone", "ShipperSecondPhone", "ShipperAddressReference", "ShipperCountry",
    "ShipperState", "ShipperPostCode", "ShipperCity", "ShipperAddressLine1", "ShipperAddressLine2", "ShipperAddressLine3", "ShipperEori", "SkuCodes", "SkuCounts",
    "ConsigneeCompanyName", "ConsigneeReference", "ConsigneeNotes", "ConsigneeName", "ConsigneeEmail", "ServiceCode", "ConsigneePhone",
    "ConsigneeSecondPhone", "ConsigneeAddressReference", "ConsigneeCountry", "ConsigneeState", "ConsigneePostCode", "ConsigneeCity",
    "ConsigneeAddressLine1", "ConsigneeAddressLine2", "ConsigneeAddressLine3", "ConsigneeEori", "OrderFulfillment", "MultipleItemsInRow", "WarehouseCode", "ProductCode", "ItemReference",
    "ItemDescription", "CountryOfOrigin", "Quantity", "ItemUnitOfMeasure", "Dutiable", "HSCode", "ItemValue", "ItemWeight",
    "PieceReference", "PieceWeight", "PieceLength", "PieceWidth", "PieceHeight", "Reference1Type", "Reference1Value", "Reference2Type",
    "Reference2Value", "Reference3Type", "Reference3Value", "SkuCodes", "SkuCounts"];

export const shipmentDropDownData: DropDownData[] = shipmentFlattenedKeys.map(key => ({
    label: key,
    value: key
}));

export interface ShipmentFlattened {
    warehouseCode?: string;
    multipleItemsInRow?: boolean;
    orderFulfillment?: boolean;
    agent?: string;
    serviceCode?: string;
    pickupDate?: string
    shipmentFlags?: string
    account?: string
    accountReference?: string
    weightValue?: number
    weightUnit?: string
    dimensionUnit?: string
    valueAmount?: number
    valueCurrency?: string
    cODAmount?: number
    cODCurrency?: string
    OnlineCOD?: number
    iOSS?: string
    incoterm?: string

    shipperCompanyName?: string
    shipperReference?: string
    shipperNotes?: string
    shipperName?: string
    shipperEmail?: string
    shipperPhone?: string
    shipperSecondPhone?: string
    shipperAddressReference?: string
    shipperCountry?: string
    shipperState?: string
    shipperPostCode?: string
    shipperCity?: string
    shipperAddressLine1?: string
    shipperAddressLine2?: string
    shipperAddressLine3?: string
    shipperEori?: string

    consigneeCompanyName?: string
    consigneeReference?: string
    consigneeNotes?: string
    consigneeName?: string
    consigneeEmail?: string
    consigneePhone?: string
    consigneeSecondPhone?: string
    consigneeAddressReference?: string
    consigneeCountry?: string
    consigneeState?: string
    consigneePostCode?: string
    consigneeCity?: string
    consigneeAddressLine1?: string
    consigneeAddressLine2?: string
    consigneeAddressLine3?: string
    consigneeEori?: string
    // items
    productCode?: string
    itemReference?: string
    itemDescription?: string
    countryOfOrigin?: string
    quantity?: number
    itemUnitOfMeasure?: string
    dutiable?: boolean
    hSCode?: string
    itemValue?: number
    itemWeight?: number
    // pieces
    pieceReference?: string
    pieceWeight?: number
    pieceLength?: number
    pieceWidth?: number
    pieceHeight?: number
    // References
    reference1Type?: string
    reference1Value?: string
    reference2Type?: string
    reference2Value?: string
    reference3Type?: string
    reference3Value?: string

}

export interface ShipmentComment {
    id: number;
    createdOn: string
    createdBy: string
    comment: string
}

export interface ShipmentCommunication {
    id: number;
    type: string;
    to: string;
    by: string;
    body: string;
    on: string;
}

export interface CodStatus {
    total: string
    details: CodStatusDetails[]
}

export interface IAddCOD {
    amount: string;
    payMode: string;
    date: Date;
    checkNumber: string | null;
    shipmentNumber: string;
    senderBank: string | null;
    onHold?: boolean
}

export interface IAddAccount {
    email: string;
    street?: string;
    city: string;
    country: string;
    state?: string;
    postCode: string;
    vat: string;
    eori: string;
    addressLine1?: string,
    addressLine2?: string,
    addressLine3?: string,
    phone: string;
    secondPhone?: string;
    id: string;
    name: string;
    publicName: string;
    notes?: string;
    taxType: string;
    accountType: number;
    dueDays?: number | null;
    profileId?: number | null;
    configurations: AccountConfigurations;
    contactPerson: string;
    attachment: AccountAttachment[]
}

export interface AccountConfigurations {
    preferredLanguages: string[];
}

export interface CodStatusDetails {
    cod: number
    pay_mode: string
    date: string
    deposit_DOCNO: string
    deposit_DATE: string
}

export interface NewCodStatusDetails {
    amount: number
    payMode: string
    date: string
    documentNumber: string
    documentDate: string
}

export enum BookingStatus {
    Tentative,
    Confirmed,
    Void
}

export interface ShipmentAddTraceResultDetails {
    barcode: string
    number: string
    shipmentNotFound: boolean
    duplicateTrace: boolean
    shipmentRepeated: number
    existingTraces: string[]
}

export interface ShipmentAddTraceResult {
    totalAdded: number,
    details: ShipmentAddTraceResultDetails[]
}

export interface AddShipmentAttachmentRequest {
    type: string,
    location?: string
    fileName?: string
    hideOnCustomer: boolean
}

export interface UpdateShipmentAttachmentRequest {
    id: number
    type?: string,
    fileName?: string
    hideOnCustomer: boolean
}

export interface SendShipmentAttachmentRequest {
    uid: string,
    attachmentId: number
}

export interface SendTraceRequest {
    uid: string
    trace: StandardShipmentTrace
    attachmentId?: number
}

export const getWeightUnitAsString = (unit: number | null | undefined) => {
    if (unit == undefined) return "";
    if (unit == 0)
        return "gm";
    if (unit == 1)
        return "kg";
    if (unit == 2)
        return "lb";
    return "oz"
}

export const calculateVolumetricAndChargeWeight = (pieces?: StandardShipmentPiece[], weightUnit?: string) => {
    let totalVolumetric = 0;
    let totalCalculated = 0;

    if (!pieces || pieces.length == 0)
        return {totalVolumetric, totalCalculated}

    pieces.forEach(p => {
        const weight = p.weight
        const height = p.height
        const length = p.length
        const width = p.width
        let calculated: number;
        let volumetric: number;
        if (!height || !length || !width)
            volumetric = 0
        else
            volumetric = Math.round((height * width * length / 5000 + Number.EPSILON) * 100) / 100
        if (weightUnit?.toLowerCase() == "gm")
            volumetric = volumetric * 1000
        if (weight && weight > volumetric)
            calculated = weight
        else
            calculated = volumetric
        totalVolumetric = totalVolumetric + volumetric;
        totalCalculated = totalCalculated + calculated;
    })

    totalVolumetric = Number(totalVolumetric.toFixed(2))
    totalCalculated = Number(totalCalculated.toFixed())

    return {totalVolumetric, totalCalculated}
}

export interface ReprintShipmentRequest {
    numbers: string[];
    references: string[];
    account: string;
    mergeLabelWithPackingList?: boolean
}

export interface CheckReprintShipmentRes {
    type: "Number" | "Reference"
    notFoundShipments: string[];
    alreadPrinted: string[]
}


export interface PendingPrinting {
    account: string;
    accountName: string;
    agent: string;
    numberOfShipments: number;
    hasCod?: boolean;
}


export interface selectedPendingPrinting extends PendingPrinting {
    index: number
}

export interface GetContainersByIdsRequest {
    ids: number[];
}

export interface GetNotPrintedDetailRequest {
    account: string;
    agent: string;
    hasCod?: boolean;
    generated?: boolean
}

export interface GetNotGeneratedDetailRequest {
    account: string;
    agent: string;
    hasCod?: boolean;
    generated?: boolean
}

export interface PendingPrintingDetail {
    skuCode: string;
    skuName: string;
    hasCod: boolean | null;
    numberOfShipments: number;
}

export interface PrintShipmentRequest {
    account: string;
    agent: string;
    skuCode?: string;
    hasCod?: boolean;
    mergeLabelWithPackingList?: boolean
}

export interface BatchShipmentGenerateRequest extends PrintShipmentRequest {
    labelSize?: string;
    pickupDate?: Date;
}

export interface GenerateShipmentRequest {
    size: string;
    pickupDate: string | null;
}

export interface DownloadShipmentPrintRequest {
    printType: ShipmentPrintType;
}

export enum ShipmentPrintType {
    Label = 0,
    Cod = 1,
    Invoice = 2,
    Packing = 3
}

export interface BookingCreateRequestService {
    code: string;
    description: string;
    pDF: string;
}

export interface AddAttachmentRequest {
    type: string;
    location: string;
    fileName: string;
}

export interface BookingCreateRequest extends StandardShipment {
    agent?: string;
    serviceCode?: string;
    services?: BookingCreateRequestService[]; // not used
    orderFulfillment?: boolean; // stock >> true else false
    attachments?: AddAttachmentRequest[];
}

export interface ShipmentCreateRequest extends StandardShipment {
    agent?: string;
    serviceCode?: string;
    services?: BookingCreateRequestService[]; // not used
    generateLabel?: boolean; // true to get a number and label from agent
    attachments?: AddAttachmentRequest[];
    size?: string
}

export interface ShipmentCreateResult extends StandardShipment {
    attachments: ShipmentAttachmentsGet[];
}

export interface ShipmentAttachmentsGet {
    agent: string;
    location: string;
    fileName: string;
    type: string;
    on: string;
    by: string;
    urlKey: string;
    id: number;
    sendToShipper: boolean;
    sendToConsignee: boolean;
}


export interface CreateShipmentShipmentParty extends ShipmentParty {
    default?: boolean
}

export interface CreateShipmentStandardShipmentPiece extends StandardShipmentPiece {
    quantity?: string
}

export interface CreateShipment extends BookingCreateRequest {
    itemsFromStock?: StandardShipmentItem[];
    shipper: CreateShipmentShipmentParty
    consignee: CreateShipmentShipmentParty
    pieces?: CreateShipmentStandardShipmentPiece[]
    isDocument?: boolean // ui
}

export const createShipmentValidationSchema = (isDocument?: boolean) => {
    return yup.object({
        number: yup.string(),
        // weight: yup.object({
        //     value: yup.number().min(0.1, 'Weight cannot be less than 0.1 kg').required('Weight value is required'),
        //     unit: yup.number().required('Weight unit is required'),
        // }),
        iOSS: yup.string(),
        incoterm: yup.string().required('Incoterm is required'),
        value: yup.object({
            currency: yup.string().required('Currency is required'),
            amount: yup.number().required('Amount is required'),
        }),
        cOD: yup.object({
            currency: yup.string().required('COD currency is required'),
            amount: yup.number().required('COD amount is required'),
        }),
        account: yup.string().required('Account is required'),
        pickupDate: yup.string(),
        accountReference: yup.string(),
        shipper: yup.object().shape({
            country: yup.string().required('Country is required'),
            postCode: yup.string().required('Zip code is required').max(200, 'Zip code cannot be longer than 200 characters'),
            city: yup.string().required('City is required').max(200, 'City name cannot be longer than 200 characters'),
            addressLine1: yup.string().required('Street is required').max(200, 'Street cannot be longer than 200 characters'),
            addressLine2: yup.string().max(200, 'Address 2 cannot be longer than 200 characters'),
            eori: yup.string().max(20, 'EORI number cannot be longer than 20 characters'),

            phone: yup.string().max(200, 'Telephone cannot be longer than 200 characters').test('is-required', 'Telephone or Email is required', function (value) {
                return value || this.parent.email;
            }),
            email: yup.string().email('Invalid email address').max(200, 'Email cannot be longer than 200 characters').test('is-required', 'Telephone or Email is required (please enter a valid email)', function (value) {
                return value || this.parent.phone;
            }),
            name: yup.string().max(200, 'Contact Name cannot be longer than 200 characters').test('is-required', 'Contact Name or Company Name is required', function (value) {
                return value || this.parent.companyName;
            }),
            companyName: yup.string().max(200, 'Company Name cannot be longer than 200 characters').test('is-required', 'Contact Name or Company Name is required', function (value) {
                return value || this.parent.name;
            }),
            notes: yup.string().max(200, 'Notes cannot be longer than 200 characters'),
        }),
        consignee: yup.object().shape({
            country: yup.string().required('Country is required'),
            postCode: yup.string().required('Zip code is required').max(200, 'Zip code cannot be longer than 200 characters'),
            city: yup.string().required('City is required').max(200, 'City name cannot be longer than 200 characters'),
            addressLine1: yup.string().required('Street is required').max(200, 'Street cannot be longer than 200 characters'),
            addressLine2: yup.string().max(200, 'Address 2 cannot be longer than 200 characters'),
            eori: yup.string().max(20, 'EORI number cannot be longer than 20 characters'),

            phone: yup.string().max(200, 'Telephone cannot be longer than 200 characters').test('is-required', 'Telephone or Email is required', function (value) {
                return value || this.parent.email;
            }),
            email: yup.string().email('Invalid email address').max(200, 'Email cannot be longer than 200 characters').test('is-required', 'Telephone or Email is required (please enter a valid email)', function (value) {
                return value || this.parent.phone;
            }),
            name: yup.string().max(200, 'Contact Name cannot be longer than 200 characters').test('is-required', 'Contact Name or Company Name is required', function (value) {
                return value || this.parent.companyName;
            }),
            companyName: yup.string().max(200, 'Company Name cannot be longer than 200 characters').test('is-required', 'Contact Name or Company Name is required', function (value) {
                return value || this.parent.name;
            }),
            notes: yup.string().max(200, 'Notes cannot be longer than 200 characters'),
        }),
        pieces: yup.array().of(
            yup.object({
                weight: yup.number().required('required'),
                quantity: yup.string().required('required')
            })
        ).min(1, 'At least one piece is required'),

    })
};

export const createShipmentInitialValues = (init?: CreateShipment) => {
    return {
        isDocument: init?.isDocument ?? true, // ui flag
        attachments: init?.attachments ?? [],
        orderFulfillment: init?.orderFulfillment ?? false,
        agent: init?.number ?? "",
        serviceCode: init?.serviceCode ?? "",
        // services:init?.services ?? undefined,
        number: init?.number ?? "",
        dimensionUnit: init?.dimensionUnit ?? 0 // cm
        ,
        weight: {
            value: init?.weight?.value ?? 0.1, // the total weight of pieces
            unit: init?.weight?.unit ?? 1 // kg
        },
        iOSS: init?.iOSS ?? "",
        incoterm: init?.incoterm ?? "DDU",
        value: {
            currency: init?.value?.currency ?? "EUR",
            amount: init?.value?.amount ?? 0,
        },
        onlineCOD: init?.onlineCOD ?? 0,
        cOD: {
            currency: init?.cOD?.currency ?? "EUR",
            amount: init?.cOD?.amount ?? 0,
        },
        account: init?.account ?? "",
        pickupDate: init?.pickupDate ?? "",
        accountReference: init?.accountReference ?? "",
        shipper: {
            companyName: init?.shipper?.companyName ?? "",
            // reference: init?.shipper?.reference ?? "",
            notes: init?.shipper?.notes ?? "",
            name: init?.shipper?.name ?? "",
            email: init?.shipper?.email ?? "",
            phone: init?.shipper?.phone ?? "",
            //  secondPhone: init?.shipper?.secondPhone ?? "",
            //  addressReference: init?.shipper?.addressReference ?? "",
            country: init?.shipper?.country ?? "",
            // state: init?.shipper?.state ?? "",
            postCode: init?.shipper?.postCode ?? "",
            city: init?.shipper?.city ?? "",
            addressLine1: init?.shipper?.addressLine1 ?? "",
            addressLine2: init?.shipper?.addressLine2 ?? "",
            //  addressLine3: init?.shipper?.addressLine3 ?? "",
            eori: init?.shipper?.eori ?? "",
            default: false
        },
        consignee: {
            companyName: init?.consignee?.companyName ?? "",
            // reference: init?.consignee?.reference ?? "",
            notes: init?.consignee?.notes ?? "",
            name: init?.consignee?.name ?? "",
            email: init?.consignee?.email ?? "",
            phone: init?.consignee?.phone ?? "",
            // secondPhone: init?.consignee?.secondPhone ?? "",
            addressReference: init?.consignee?.addressReference ?? "",
            country: init?.consignee?.country ?? "",
            // state: init?.consignee?.state ?? "",
            postCode: init?.consignee?.postCode ?? "",
            city: init?.consignee?.city ?? "",
            addressLine1: init?.consignee?.addressLine1 ?? "",
            addressLine2: init?.consignee?.addressLine2 ?? "",
            //  addressLine3: init?.consignee?.addressLine3 ?? "",
            eori: init?.consignee?.eori ?? "",
            default: false
        },
        pieces: init?.pieces ?? [{
            reference: "",
            weight: 0.1,
            length: 20,
            width: 15,
            height: 1,
            quantity: "1"
        }],
        items: init?.items ?? [{
            description: "",
            countryOfOrigin: "",
            hsCode: "",
            weight: undefined,
            quantity: undefined,
            dutiable: !(init?.isDocument ?? true)
        }],
        itemsFromStock: init?.itemsFromStock ?? [], // concatenate with the items
    };
};

export const mapGetShipmentDataToStandardShipment = (shipment: ShipmentGet, allSkus: SkuSearch[]): CreateShipment => {
    const itemsFromStock: StandardShipmentItem[] = [];
    const items: StandardShipmentItem[] = [];
    shipment?.items?.forEach((item: StandardShipmentItem) => {
        const foundSku = allSkus?.find((sku) => sku?.code === item?.productCode) ?? null;
        const availableQuantity = foundSku?.quantityAvailable;
        const mappedItem: StandardShipmentItem = {
            description: foundSku?.name ?? item?.description,  // stock >> sku name
            countryOfOrigin: item?.countryOfOrigin,
            quantity: availableQuantity && item?.quantity && availableQuantity < item?.quantity ? availableQuantity : item?.quantity,
            hsCode: item?.hsCode,
            weight: shipment?.weightUnit?.toLowerCase() === "gm" ? (item?.weight ?? 0) / 1000 : item?.weight,
            warehouse: item?.warehouse,
            dutiable: item?.dutiable,
            productCode: item?.productCode,
            quantityAvailable: availableQuantity?.toString() ?? "",
        };
        if (!foundSku) {
            items?.push(mappedItem);
        } else if (availableQuantity != 0) {
            itemsFromStock?.push(mappedItem);
        }
    });
    const isDutiable = items?.some((e) => e?.dutiable)
    const isDocument = ((itemsFromStock?.length > 0) || (isDutiable)) ? false : true
    const standardShipment: CreateShipment = {
        isDocument: isDocument,
        number: "",
        account: shipment?.account,
        weight: {
            value: shipment?.weight ? shipment?.weightUnit?.toLowerCase() == "gm" ? shipment?.weight / 1000 : shipment?.weight : 0.1,
            unit: 1, // kg
        },
        dimensionUnit: 0, //cm
        value: {
            amount: shipment?.value ?? undefined,
            currency: shipment?.valueCurrency,
        },
        cOD: {
            amount: shipment?.cod ?? undefined,
            currency: shipment?.codCurrency,
        },
        iOSS: shipment?.ioss,
        incoterm: shipment?.incoterm,
        shipper: {
            companyName: shipment?.shipperCompanyName,
            notes: shipment?.shipperNotes,
            name: shipment?.shipperName,
            email: shipment?.shipperEmail,
            phone: shipment?.shipperPhone,
            country: shipment?.shipperCountry?.trim(),
            postCode: shipment?.shipperPostCode,
            city: shipment?.shipperCity,
            addressLine1: shipment?.shipperAddressLine1,
            addressLine2: shipment?.shipperAddressLine2,
            eori: shipment?.shipperEori,
        },
        consignee: {
            companyName: shipment?.consigneeCompanyName,
            notes: shipment?.consigneeNotes,
            name: shipment?.consigneeName,
            email: shipment?.consigneeEmail,
            phone: shipment?.consigneePhone,
            country: shipment?.consigneeCountry?.trim(),
            postCode: shipment?.consigneePostCode,
            city: shipment?.consigneeCity,
            addressLine1: shipment?.consigneeAddressLine1,
            addressLine2: shipment?.consigneeAddressLine2,
            eori: shipment?.consigneeEori,
        },
        pieces: shipment?.pieces?.length > 0 ? shipment?.pieces?.map((piece: any) => ({
            reference: piece?.reference,
            weight: shipment?.weightUnit?.toLowerCase() == "gm" ? piece?.weight / 1000 : piece?.weight,
            length: shipment?.dimensionUnit == "m" ? piece?.length * 100 : piece?.length,
            width: shipment?.dimensionUnit == "m" ? piece?.width * 100 : piece?.width,
            height: shipment?.dimensionUnit == "m" ? piece?.height * 100 : piece?.height,
            quantity: "1",
        })) : undefined,
        items: (isDocument || items?.length === 0) ?
            [{
                description: "",
                countryOfOrigin: "",
                hsCode: "",
                weight: undefined,
                quantity: undefined,
                dutiable: true
            }] : items,
        itemsFromStock: itemsFromStock,
        onlineCOD: !!shipment?.onlineCOD ? Number(shipment?.onlineCOD) : undefined,
        orderFulfillment: itemsFromStock ? true : false,

    };
    return createShipmentInitialValues(standardShipment);
}

export const calculateTotalWeight = (pieces: CreateShipmentStandardShipmentPiece[] | undefined): {
    weight: number,
    count: number
} | undefined => {
    let result = pieces?.reduce(
        (acc: { weight: number; count: number }, piece: CreateShipmentStandardShipmentPiece | undefined) => {
            if (!!piece?.weight && !!piece?.quantity) {
                acc.weight += Number(piece.weight) * Number(piece.quantity);
                acc.count += Number(piece.quantity);
            }
            return acc;
        },
        {weight: 0, count: 0}
    );
    return result;
};
export const validateAddressBook = (values: {
    email?: string,
    phone?: string,
    companyName?: string,
    name?: string
}) => {
    const {email, phone, companyName, name} = values;
    const isCompanyNameOrContactNameValid = (!!(companyName) || !!(name)) ? true : false
    const isEmailOrPhoneValid = (!!(email) || !!(phone)) ? true : false
    return {
        isValid: isCompanyNameOrContactNameValid && isEmailOrPhoneValid,
        isCompanyNameOrContactNameValid,
        isEmailOrPhoneValid
    };
};

export interface DashboardCountsResponse {
    todaysShipments: GroupedShipmentsModel
    yesterdaysShipments: GroupedShipmentsModel
    beforeYesterdaysShipments: GroupedShipmentsModel
}

export interface AssignedToCount {
    total: number;
    userName: string;
}

export interface GroupedShipmentsModel {
    total: number
    groupsDate: string
    shipments: ShipmentsByCustomer[]
}

export interface ShipmentsByCustomer {
    accountId: string
    accountName: string
    total: number
}

export const groupErrorsBySource = (errors: ShipmentErrorGet[]) => {
    const groupedErrors: Record<string, ShipmentErrorGet[]> = {};
    for (const error of errors) {
        const sourceName = error.sourceName;
        if (!groupedErrors[sourceName]) {
            groupedErrors[sourceName] = [];
        }
        groupedErrors[sourceName].push(error);
    }
    return Object.entries(groupedErrors);
};

export interface PendingGenerateWithErrors {
    account: string;
    accountName: string;
    numberOfShipments: number;
}

export interface PendingGenerateWithErrorsDetail {
    id: number;
    uid: string;
    accountReference: string;
    errors: ShipmentErrorGet[];
}

export const documentItem = (shipperCountry?: string, weight?: number | null) => {
    return ({
        description: 'Document',
        countryOfOrigin: shipperCountry ?? "",
        hsCode: '49111090',
        weight: weight ?? 0.1,
        quantity: 1,
        dutiable: false, // isDocument >> not dutiable
    })
};

export interface ShipmentBlockRequest {
    blockReasonCode: string;
}


export interface ShipmentBoxesResponse {
    id: number;
    number: number;
    items: shipmentBoxItem[]
}

export interface shipmentBox {
    label: string,
    value: string,
    item: shipmentBoxItem[]
}

export interface shipmentBoxItem {
    id: number;
    productCode: string;
    quantity: number;
    shipmentBoxId: number;
}
