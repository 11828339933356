import {ShipmentCommunication, ShipmentGet, ShipmentSearch} from "../../../../models/shipment";
import {formatDate} from "../../../../utils/dateUtil";
import React from "react";
import {TableHeader} from "./TableHeader";

interface IProps {
    shipment: ShipmentGet;
}

const Communications = (props:IProps) => {
    const shipment = props.shipment;
    return (
        <div>
            <table className="position-relative table table-hover table-sm">
                <TableHeader header={["Date", "Message", "Type", "Receiver", "By"]} />
                <tbody>
                {shipment.communications?.length < 1 && (
                    <div className="mr-2 ml-2 mt-2 mb-2">
                        <span> No Available messages</span>
                    </div>
                )}

                {shipment.communications?.map((message: ShipmentCommunication) => (
                    <tr key={message?.id}>
                        <td style={{width: "10%"}}>{formatDate(message?.on)}</td>
                        <td>{message?.body}</td>
                        <td>{message?.type}</td>
                        <td>{message?.to}</td>
                        <td style={{width: "10%"}}>{message?.by}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}

export default Communications;