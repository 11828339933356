import {CCol, CRow, CLabel, CButton, CButtonGroup, CInputRadio} from "@coreui/react";
import React, {useEffect, useState} from "react";
import SWSelect from "../../../SharedComponents/SWSelect";
import SWInput from "../../../SharedComponents/SWInput";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import {useFormik} from "formik";
import {QuotationSearchInitalState} from "../../../../models/QuotationSearchInitalState";
import CheckoutClient from "../../../../clients/checkoutClient";
import RatesClient from "../../../../clients/ratesClient";
import ExportToExcelButton from "../../../SharedComponents/ExportToExcelButton";
import DateRangeFilter from "../../../SharedComponents/SWAdvancedSearch/DateRangeFilter";
import SWDateAndTimePicker from "../../../SharedComponents/SWDateAndTimePicker";
import SWTextArea from "../../../SharedComponents/SWTextArea";
import {FilterGroup} from "../../../../models/filter";
import {filterGroupToQueryParams, queryParamsToFilters} from "../../../../utils/filterUtils";
import {useHistory, useLocation} from "react-router";
import ActionButton from "../../../SharedComponents/ActionButton";

interface IProps {
    additionalFilters: {
        type: "text" | "select" | "date" | "number",
        field: string,
        title: string,
        lookups?: { [k: string]: string }
    }[],
    getScenarios: (profileId?: string) => void
}

const NewSearchForm = (props: IProps) => {
    const checkoutClient = new CheckoutClient()
    const ratesClient = new RatesClient()

    const [additionalFilters, setAdditionalFilters] = useState([{name: "Email", type: "", value: ""}])
    const [additionalFiltersLookup, setAdditionalFiltersLookup] = useState<{ [k: string]: string }>({})
    const [filterGroup, setFilterGroup] = useState<FilterGroup>({})
    const [profiles, setProfiles] = useState<{ [k: string]: string }>({});
    const [accounts, setAccounts] = useState<{ [k: string]: string }>({})
    const loc = useLocation()
    const hist = useHistory();

    useEffect(() => {
        checkoutClient.GetProfiles(true).then(setProfiles);
        ratesClient.LookupCustomers().then(setAccounts)

        loadAdditionalFilterLookup()
    }, []);

    useEffect(() => {
        loadFiltersFromUrl()
    }, [loc.search])

    // const ops = {
    //     "0": "Equals",
    //     "2": "Not Equals",
    //     "1": "Like",
    //     "3": "GreaterThan",
    //     "4": "LessThan",
    //     "8": "One Of",
    //     "21": "Range",
    // }

    const textOps = {
        "0": "Equals",
        "2": "Not Equals",
        "1": "Like",
        "8": "One Of",
    }

    const selectOps = {
        "0": "Equals",
        "2": "Not Equals",
    }

    const dateTimeOps = {
        "0": "Equals",
        "3": "GreaterThan",
        "4": "LessThan",
        "21": "Range",
    }

    const numberOps = {
        "0": "Equals",
        "2": "Not Equals",
        "3": "GreaterThan",
        "4": "LessThan"
    }

    const {handleChange, handleSubmit, values, setFieldValue, resetForm} = useFormik({
        initialValues: QuotationSearchInitalState,
        onSubmit: async () => {
            let filters = [...values.filters, ...additionalFilters]
            let index = filters.findIndex(f => f.name == "Number")
            if (index >= 0) {
                let numberFilter = filters[index]
                numberFilter.value = numberFilter.value?.replace(/ /g, "")
                filters[index] = numberFilter
            }
            let fg: FilterGroup = {
                filters: [],
                limit: 20,
                page: 0
            }
            filters.forEach((f) => {
                if (f.value) {
                    fg.filters?.push({
                        field: f.name,
                        type: Number(f.type),
                        value: f.value?.trim()
                    })
                }
            })

            const query = filterGroupToQueryParams(fg)
            hist.push(`${loc.pathname}?${query}`)
        }
    })

    const onProfileChange = async (v?: string) => {
        await setFieldValue("filters[2].value", v ?? "")
        props.getScenarios(v)
        let index = additionalFilters.findIndex(af => af.name == "ScenarioCode")
        if (index >= 0)
            handleAdditionalFilterChange("", "value", index)
    }

    const addAdditionalFilter = () => {
        setAdditionalFilters([...additionalFilters, {name: "", type: "", value: ""}])
    }

    const removeAdditionalFilter = (index: number) => {
        const filters = [...additionalFilters]
        filters.splice(index, 1)
        setAdditionalFilters(filters)
    }

    const loadAdditionalFilterLookup = () => {
        let lookup: { [k: string]: string } = {}
        props.additionalFilters.forEach(fi => {
            lookup[fi.field] = fi.title
        })
        setAdditionalFiltersLookup(lookup)
    }

    const loadFiltersFromUrl = () => {
        const fg = queryParamsToFilters(loc.search)
        let additionFilters: { name: string, type: string, value: string }[] = []
        if (fg.filters == null || fg.filters.length == 0) {
            resetForm()
            setAdditionalFilters([{name: "Email", type: "0", value: ""}])
        }
        else
        {
            fg.filters.map(f => {
                let filter = values.filters.find(fi => fi.name == f.field)
                if (filter) {
                    let index = values.filters.findIndex(f => f.name == filter?.name)
                    let filters = values.filters
                    filters[index] = {name: f.field, type: f.type?.toString(), value: f.value?.toString()}
                    setFieldValue("filters", filters)
                } else {
                    additionFilters.push({name: f.field, type: f.type?.toString(), value: f.value?.toString()})
                }
            })
            setAdditionalFilters(additionFilters)
        }
    }

    const handleAdditionalFilterChange = (value: string, type: "name" | "type" | "value", index: number) => {
        let filters = [...additionalFilters]
        let filter = filters[index]
        filter[type] = value
        filters[index] = filter
        setAdditionalFilters(filters)
    }

    const getAdditionalFilterType = (field: string) => {
        let filter = props.additionalFilters.find(f => f.field == field)
        return filter?.type
    }

    const getOpsTypeFromName = (field: string) => {
        let filter = props.additionalFilters.find(af => af.field == field)
        switch (filter?.type) {
            case "text" :
                return textOps as { [l: string]: string }
            case "date":
                return dateTimeOps as { [l: string]: string }
            case "select" :
                return selectOps as { [l: string]: string }
            case "number" :
                return numberOps as { [l: string]: string }
            default :
                return {}
        }
    }

    useEffect(() => {
        let filters = [...values.filters, ...additionalFilters]
        let filterGroup: FilterGroup = {
            page: 0,
            limit: 20,
            filters: []
        }
        filters.forEach(fi => {
            if (fi.value)
                filterGroup.filters?.push({
                    field: fi.name,
                    type: Number(fi.type),
                    value: fi.value
                })
        })
        setFilterGroup(filterGroup)
    }, [values.filters, additionalFilters])

    const getAdditionalFilterLookup = (field: string) => {
        let filter = props.additionalFilters.find(f => f.field == field)
        return filter?.lookups ?? {}
    }

    const handleClear = () => {
        hist.push("/quotations/search")
        window.location.reload()
    }

    return (
        <fieldset className="b-fieldset p-2 text-primary">
            <legend className="b-legend" style={{width: "93px"}}>
                <h5>Quotations</h5>
            </legend>
            <CRow>
                <CCol md={2}>
                    <SWInput value={values.filters[0].value} name={"filters[0].value"} label={"Invoice Number"}
                             onChangeFormik={handleChange}/>
                </CCol>
                <CCol md={2}>
                    <SWInput value={values.filters[1].value} name={"filters[1].value"}
                             label={"Quotation Number"}
                             onChangeFormik={handleChange}/>
                </CCol>
                <CCol md={2}>
                    <SWSelect value={values.filters[6].value} name={"filters[6].value"} values={accounts}
                              label={"Account"} onChange={v => setFieldValue("filters[6].value", v)} isClearable/>
                </CCol>
                <CCol md={2}>
                    <SWSelect value={values.filters[2].value} name={"filters[2].value"} values={profiles}
                              label={"Profile"} onChange={onProfileChange} isClearable/>
                </CCol>
                <CCol/>
                <CCol md={3}>
                    <CRow style={{marginLeft: "1px"}}>
                        <CLabel>Invoiced</CLabel>
                    </CRow>
                    <CRow style={{marginLeft: "6px"}}>
                        <CCol><CInputRadio
                            style={{
                                width: "1.25em",
                                height: "1.25em",
                                fontSize: "10px",
                            }}
                            value={values.filters[9].value}
                            name="filters[9].value"
                            checked={values.filters[9].value == "true"}
                            onClick={() => {
                                setFieldValue("filters[9].value", "true")
                            }
                            }
                        /><CLabel>Yes</CLabel></CCol>
                        <CCol><CInputRadio
                            style={{
                                width: "1.25em",
                                height: "1.25em",
                                fontSize: "10px",
                            }}
                            value={values.filters[9].value}
                            name="filters[9].value"
                            checked={values.filters[9].value == "false"}
                            onClick={() => {
                                setFieldValue("filters[9].value", "false")
                            }}
                        /><CLabel>No</CLabel></CCol>
                        <CCol><CInputRadio
                            style={{
                                width: "1.25em",
                                height: "1.25em",
                                fontSize: "10px",
                            }}
                            value={values.filters[9].value}
                            name="filters[9].value"
                            checked={values.filters[9].value == ""}
                            onClick={() => {
                                setFieldValue("filters[9].value", "")
                            }}
                        /><CLabel>All</CLabel></CCol>
                    </CRow>
                </CCol>
            </CRow>
            <CRow>
                <CCol md={4}>
                    <SWInput value={values.filters[5].value} name={"filters[5].value"}
                             onChangeFormik={handleChange} label={"Contact"}/>
                </CCol>
                <CCol md={2}>
                    <SWSelect values={{"true": "Yes", "false": "No"}} value={values.filters[3].value}
                              name={"filters[3].value"} onChange={v => setFieldValue("filters[3].value", v)}
                              label={"Has Shipment"} isClearable/>
                </CCol>
                <CCol md={2}>
                    <SWTextArea value={values.filters[4].value} name={"filters[4].value"}
                                label={"Shipment Number"} onChangeFormik={handleChange} rows={1}/>
                </CCol>
                <CCol/>
                <CCol md={3}>
                    <CRow style={{marginLeft: "1px"}}>
                        <CLabel>Paid</CLabel>
                    </CRow>
                    <CRow style={{marginLeft: "6px"}}>
                        <CCol><CInputRadio
                            style={{
                                width: "1.25em",
                                height: "1.25em",
                                fontSize: "10px",
                            }}
                            value={values.filters[10].value}
                            name="filters[10].value"
                            checked={values.filters[10].value == "true"}
                            onClick={() => {
                                setFieldValue("filters[10].value", "true")
                            }}
                        /><CLabel>Yes</CLabel></CCol>
                        <CCol><CInputRadio
                            style={{
                                width: "1.25em",
                                height: "1.25em",
                                fontSize: "10px",
                            }}
                            value={values.filters[10].value}
                            name="filters[10].value"
                            checked={values.filters[10].value == "false"}
                            onClick={() => {
                                setFieldValue("filters[10].value", "false")
                            }}
                        /><CLabel>No</CLabel></CCol>
                        <CCol><CInputRadio
                            style={{
                                width: "1.25em",
                                height: "1.25em",
                                fontSize: "10px",
                            }}
                            value={values.filters[10].value}
                            name="filters[10].value"
                            checked={values.filters[10].value == ""}
                            onClick={() => {
                                setFieldValue("filters[10].value", "")
                            }}
                        /><CLabel>All</CLabel></CCol>
                    </CRow>
                </CCol>
            </CRow>
            <CRow>
                <CCol md={1}>
                    <CIcon
                        size={"xl"}
                        style={{cursor: 'pointer', marginBottom: "20px"}}
                        className="btn-icon"
                        content={freeSet.cilPlus}
                        onClick={addAdditionalFilter}
                    />
                </CCol>
                <CCol/>
                <CCol md={3}>
                    <CRow style={{marginLeft: "1px"}}>
                        <CLabel>Processed</CLabel>
                    </CRow>
                    <CRow style={{marginLeft: "6px"}}>
                        <CCol><CInputRadio
                            style={{
                                width: "1.25em",
                                height: "1.25em",
                                fontSize: "10px",
                            }}
                            value={values.filters[11].value}
                            name="filters[11].value"
                            checked={values.filters[11].value == "true"}
                            onChange={() => setFieldValue("filters[11].value", "true")}
                        /><CLabel>Yes</CLabel></CCol>
                        <CCol><CInputRadio
                            style={{
                                width: "1.25em",
                                height: "1.25em",
                                fontSize: "10px",
                            }} value={values.filters[11].value}
                            name="filters[11].value"
                            checked={values.filters[11].value == "false"}
                            onChange={() => setFieldValue("filters[11].value", "false")}
                        /><CLabel>No</CLabel></CCol>
                        <CCol><CInputRadio
                            style={{
                                width: "1.25em",
                                height: "1.25em",
                                fontSize: "10px",
                            }}
                            value={values.filters[11].value}
                            name="filters[11].value"
                            checked={values.filters[11].value == ""}
                            onChange={() => setFieldValue("filters[11].value", "")}
                        /><CLabel>All</CLabel></CCol>
                    </CRow>
                </CCol>
            </CRow>
            {additionalFilters.map((additionalFilter, index) =>
                <CRow key={index}>
                    <CCol md={2}>
                        <SWSelect
                            values={additionalFiltersLookup}
                            value={additionalFilters[index].name}
                            name={`additionalFilters[${index}].name`}
                            onChange={v => {
                                handleAdditionalFilterChange(v ?? "", "name", index)
                                handleAdditionalFilterChange("", "type", index)
                                handleAdditionalFilterChange("", "value", index)
                            }}
                        />
                    </CCol>
                    <CCol md={2}>
                        <SWSelect
                            values={getOpsTypeFromName(additionalFilter.name)}
                            value={additionalFilters[index].type}
                            name={`additionalFilters[${index}].type`}
                            onChange={v => handleAdditionalFilterChange(v ?? "", "type", index)}
                        />
                    </CCol>
                    <CCol md={2}>
                        {getAdditionalFilterType(additionalFilters[index].name) == "date" &&
                            (additionalFilters[index].type == "21" &&
                                <DateRangeFilter value={additionalFilters[index].value}
                                                 onChange={e => handleAdditionalFilterChange(e ?? "", "value", index)}/> ||
                                <SWDateAndTimePicker value={additionalFilters[index].value}
                                                     handleOnChange={e => handleAdditionalFilterChange(e ?? "", "value", index)}/>) ||
                            getAdditionalFilterType(additionalFilters[index].name) == "select" &&
                            <SWSelect values={getAdditionalFilterLookup(additionalFilters[index].name)}
                                      onChange={e => handleAdditionalFilterChange(e ?? "", "value", index)}
                                      value={additionalFilters[index].value}/> ||
                            getAdditionalFilterType(additionalFilters[index].name) == "text" &&
                            (additionalFilters[index].type == "8" &&
                                <SWTextArea value={additionalFilters[index].value}
                                            name={"additionalFilters[i].value"}
                                            onChange={e => handleAdditionalFilterChange(e ?? "", "value", index)}/> ||
                                <SWInput value={additionalFilters[index].value}
                                         name={"additionalFilters[i].value"}
                                         onChange={e => handleAdditionalFilterChange(e ?? "", "value", index)}/>) ||
                            getAdditionalFilterType(additionalFilters[index].name) == "number" &&
                            <SWInput value={additionalFilters[index].value} name={"additionalFilters[i].value"}
                                     onChange={e => handleAdditionalFilterChange(e, "value", index)}/>
                        }
                    </CCol>
                    <CCol md={2}>
                        <CIcon
                            size={"lg"}
                            content={freeSet.cilTrash}
                            style={{cursor: "pointer"}}
                            onClick={() => removeAdditionalFilter(index)}
                        />
                    </CCol>
                </CRow>
            )}
            <CRow style={{marginTop: "10px"}}>
                <CCol md={3}>
                    <CButton
                        size="sm"
                        style={{fontSize: "10px"}}
                        variant="outline"
                        color="danger"
                        onClick={handleClear}
                    >Clear all</CButton>
                </CCol>
                <CCol className={"text-right"}>
                    <CButtonGroup className="mr-4">
                        <ExportToExcelButton
                            fileNamePrefix="shipments"
                            getData={() =>
                                checkoutClient.getQuotations(filterGroup, 2)
                            }
                        />
                    </CButtonGroup>
                    <CButtonGroup>
                        <ActionButton
                            text={"Search"}
                            onClick={handleSubmit}
                        />
                    </CButtonGroup>
                </CCol>
            </CRow>
        </fieldset>
    )
}

export default NewSearchForm;