import { SortingRuleInformation } from "../../models/sortingrule";
import { Filter, FilterGroup } from "../../models/filter";
import {
  SET_SORTINGRULE_FILTER_GROUP,
  SET_SORTINGRULE_IS_LOADING,
  SET_SORTINGRULE_RESULT,
} from "../types";

export const SetSortingRulePage = (page: number) => {
  return {
    type: SET_SORTINGRULE_FILTER_GROUP,
    payload: {
      page: page,
    },
  };
};

export const SetSortingRuleIsLoading = (loading: boolean) => {
  return {
    type: SET_SORTINGRULE_IS_LOADING,
    payload: {
      isLoading: loading,
    },
  };
};

export const SetSortingRulesFilterGroupFilters = (filters: Filter[]) => {
  return {
    type: SET_SORTINGRULE_FILTER_GROUP,
    payload: {
      page: 0,
      filters: filters,
    },
  };
};

export const SetSortingRulesFilterGroup = (filterGroup: FilterGroup) => {
  return {
    type: SET_SORTINGRULE_FILTER_GROUP,
    payload: filterGroup,
  };
};

export const SetSortingRuleResult = (
  page: number,
  count: number,
  result: SortingRuleInformation[],
  error?: string
) => {
  return {
    type: SET_SORTINGRULE_RESULT,
    payload: {
      count: count,
      result: result,
      error: error,
    },
  };
};
