import {
  CButton,
  CButtonGroup,
  CButtonToolbar,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CContainer,
  CForm,
  CFormGroup,
  CImg,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CLink,
  CRow,
} from "@coreui/react";
import * as icons from "@coreui/icons";
import React from "react";
import CIcon from "@coreui/icons-react";
import cookieManager from "../../../services/cookieManager";
import { SetLoggingIn } from "../../../store/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router";
import { AppModel } from "../../../models/app";
import AuthClient from "../../../clients/authClient";

const Login = () => {
  const authClient = new AuthClient();
  const dispatch = useDispatch();
  const user = useSelector((state: AppModel) => state.app.user);
  const his= useHistory()

  const loginHandler = (jwtToken: string, refreshToken: string) => {
    cookieManager.setAccessToken(jwtToken);
    cookieManager.setRefreshToken(refreshToken)
    dispatch(SetLoggingIn(true));

  };
  return (
    <div className="c-app c-default-layout flex-row align-items-center overflow-hidden d-flex">
      <CContainer>
        {user && <Redirect to={'/'}/>}
        <CRow className={'d-flex justify-content-center'} >
          <CCol lg={8}>
            <CCard className="p-5 shadow-lg">
              <CCardHeader>
                <CImg
                  className="d-block mx-auto "
                  width="25%"
                  fluid
                  src="logo.svg"
                />
              </CCardHeader>
              <CCardBody className="p-4">
                <CRow>
                  <CCol>
                    <CForm

                      onSubmit={async (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.target as any); // Create a FormData object from the form
                        const body = {
                          username: formData.get('username') as string,
                          password: formData.get('password') as string,
                        }
                        const res = await authClient.Login(body)
                        if(res.succeeded) {
                          loginHandler(res.data.accessToken,res.data.refreshToken)
                        }

                      }}>
                      <CRow>
                        <CCol>
                          <h5 color="secondary">Login</h5>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol>
                          <CInputGroup className="my-3">
                            <CInputGroupPrepend>
                              <CInputGroupText>
                                <CIcon content={icons.freeSet.cilUser} />
                              </CInputGroupText>
                            </CInputGroupPrepend>
                            <CInput
                              placeholder="username"
                              name="username"
                              id="username"
                              type="text"

                            />
                          </CInputGroup>
                        </CCol>
                      </CRow>

                      <CRow>
                        <CCol>
                          <CInputGroup className="mb-3">
                            <CInputGroupPrepend>
                              <CInputGroupText>
                                <CIcon content={icons.freeSet.cilLockLocked} />
                              </CInputGroupText>
                            </CInputGroupPrepend>
                            <CInput
                              name="password"
                              id="password"
                              type="password"
                              placeholder="password"
                            />
                          </CInputGroup>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol>
                          <CButtonGroup className="my-2">
                            <CButton
                              type="submit"
                              variant="outline"
                              color="primary"
                            >
                              Login
                            </CButton>
                            <CButton
                              className="ml-2"
                              variant="ghost"
                              color="secondary"
                              onClick={()=> his.push('/ResetPassword')}
                            >
                              Forgot password?
                            </CButton>
                          </CButtonGroup>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCol>
                  <CCol className="divider-left">
                    <CRow>
                      <CCol>
                        <h5 style={{ textAlign: "center" }}>
                          Or sign in using the below
                        </h5>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol>
                        <CButtonToolbar justify="center" className="my-2">
                          <CForm
                            method="post"
                            onSubmit={(e) => {
                              SetLoggingIn(true);
                              return true;
                            }}
                            action={`${authClient.BaseUrlNoApi}/external/login/microsoft?returnUrl=${window.location.href}`}
                          >
                            <CButton
                              className="p-3 mx-2 px-4"
                              color="primary"
                              variant="outline"
                              type="submit"
                            >
                              <CIcon
                                className="mr-2"
                                content={icons.brandSet.cibMicrosoft}
                              />
                              Microsoft
                            </CButton>
                          </CForm>
                          <CButton
                            className="p-3 mx-2 px-4"
                            color="warning"
                            variant="outline"
                            disabled
                          >
                            <CIcon
                              className="mr-2"
                              content={icons.brandSet.cibGoogle}
                            />
                            Google
                          </CButton>
                        </CButtonToolbar>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
