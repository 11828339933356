import {
  CCol,
  CButton,
  CModal,
  CButtonGroup,
  CContainer,
  CModalBody,
  CModalHeader,
  CCardBody,
  CRow,
  CInput,
  CForm,
  CLabel,
} from "@coreui/react";
import { useEffect, useState } from "react";
import {
  ShipmentSearch,
  StandardShipmentPiece,
} from "../../../../models/shipment";

interface IProps {
  shipment: ShipmentSearch;
  index?: number;
  isShowing: boolean;
  // onSave: () => any;
  onClose: () => any;

  onChange: (shipment: ShipmentSearch) => any;
}

const AddUpdatePieces = (props: IProps) => {

  const { shipment, index, onChange , onClose} = props;



  const initialPieceValue =
    index != undefined ? { ...shipment.pieces[index] } : {};
  const [piece, setPiece] = useState<StandardShipmentPiece>(initialPieceValue);
  useEffect(() => {
    setPiece(initialPieceValue);
  },[]);

  const handleChange = (e: any) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    setPiece({
      ...piece,
      [name]: value,
    });
  };

  const handleSave= () => {
    if(index == undefined)
    {
      //add
      const newPieces = [...shipment.pieces, piece];

      const updatedShipment = {...shipment, pieces :newPieces};
      onChange(updatedShipment);
    }
    else
    {
      //edit
      const newPieces = [];
      for (let i = 0; i < shipment.pieces.length; i++) {
        const element = shipment.pieces[i];
        if(i == index)
          newPieces.push(piece);
        else
          newPieces.push({...shipment.pieces[i]})
      }
      const updatedShipment = {...shipment, pieces :newPieces};
      onChange(updatedShipment);
    }
    onClose();
  }
  return (
    <>
      <CModal
        className="ml-auto mr-auto"
        size={"lg"}
        show={props.isShowing}
        centered={true}
        onClose={props.onClose}
        closeOnBackdrop={false}
      >
        <CForm
        //onChange={handleChange}
        >
          <CModalBody>
            <CContainer className="form-group small">
              <>
                <CCardBody className="editShipperDetailsCard ml-3 mb-3">
                  <CRow className="mb-4 mt-4">
                    <h6>
                      <CModalHeader>
                        <strong>Add Piece:</strong>
                      </CModalHeader>
                    </h6>
                  </CRow>
                  <CRow className="mb-4 mt-4">
                    <CCol>
                      <CLabel className="ml-0 position-relative mr-2">
                        Number:
                      </CLabel>
                      <CInput
                      style={{fontSize:"10px"}}
                        onChange={handleChange}
                        name="number"
                        value={piece.number}
                      ></CInput>
                    </CCol>

                    <CCol>
                      <CLabel>Reference:</CLabel>
                      <CInput
                      style={{fontSize:"10px"}}
                        onChange={handleChange}
                        name="reference"
                        value={piece.reference}
                      ></CInput>
                    </CCol>
                    <CCol>
                      <CLabel>Weight:</CLabel>
                      <CInput style={{fontSize:"10px"}} onChange={handleChange} name="weight" value={piece.weight} ></CInput>
                    </CCol>
                  </CRow>
                  <CRow className="mb-4 mt-4">
                  
                    <CCol>
                      <CLabel>Length ({shipment?.dimensionUnit}):</CLabel>
                      <CInput style={{fontSize:"10px"}} onChange={handleChange} name="length" value={piece.length}></CInput>
                    </CCol>
                    <CCol>
                      <CLabel>Width ({shipment?.dimensionUnit}):</CLabel>
                      <CInput style={{fontSize:"10px"}} onChange={handleChange} name="width" value={piece.width}></CInput>
                    </CCol>
                    <CCol>
                      <CLabel>Height ({shipment?.dimensionUnit}):</CLabel>
                      <CInput style={{fontSize:"10px"}} onChange={handleChange} name="height" value={piece.height}></CInput>
                    </CCol>
                  </CRow>
                </CCardBody>
              </>
            </CContainer>
            <CCol className="text-right">
              <CButtonGroup className="mb-0 mt-4">
                <CButton
                  onClick={handleSave}
                  type="button"
                  className="w-50 bg-primary text-white ml-1 mr-1"
                >
                  Save
                </CButton>
                <CButton
                  className="w-50 bg-primary text-white ml-1"
                  onClick={onClose}
                >
                  Close
                </CButton>
              </CButtonGroup>
            </CCol>
          </CModalBody>
        </CForm>
      </CModal>
    </>
  );
};

export default AddUpdatePieces;
