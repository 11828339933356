import {
    CBadge,
    CButton,
    CButtonGroup,
    CCard,
    CCol,
    CContainer,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CFormText,
    CInput,
    CInputCheckbox,
    CInputGroup,
    CLabel,
    CLink,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CNav,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CTabs,
    CTooltip,
} from "@coreui/react";
import {Form, FormRef, Input} from "../../SharedComponents/SWForm";
import React, {useEffect, useRef, useState} from "react";
import {useHistory, useLocation, useParams} from "react-router";
import {useComplexState} from "../../../hooks/helperHooks";
import CheckoutClient from "../../../clients/checkoutClient";

import {QuotationChargeInfo, QuotationProfile, QuotationScenario, QuotationUpdate,} from "../../../models/quotation";
import AddEditItem from "./components/AddEditItem";
import RatesClient from "../../../clients/ratesClient";
import ItemsTable from "./components/ItemsTable";
import ActiveAddItem from "./components/ActivateAddItem";
import {useDispatch} from "react-redux";
import {ShowNotification} from "../../../store/actions/auth";
import {notEmpty} from "../../../utils/commonValidators";
import MarkAsProcessedModal from "./modals/MarkAsProcessed";
import ConfirmInvoiceSendModal from "./modals/InvoiceSend";
import ConfirmIssueInvoice from "./modals/InvoiceIssue";
import RequestAPaymentModal from "./modals/RequestAPayment";
import MarkAsPaidModal from "./modals/MarkAsPaid";
import CancelQuotationModal from "./modals/CancelQuotation";
import DeleteQuotationModal from "./modals/DeleteQuotation";
import {freeSet} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import Stepper from "./components/Stepper";
import LookupsClient from "../../../clients/lookupsClient";
import {Account} from "../../../models/account";
import QuotationAttachments from "./QuotationAttachments";
import QuotationHistory from "./QuotationHistory";
import EditInvoiceDueDate from "./modals/EditInvoiceDueDate";
import MarkAsUnProcessed from "./modals/MarkAsUnProcessed";
import MarkAsUnPaid from "./modals/MarkAsUnPaid";
import ShipClient from "../../../clients/shipClient";
import {ShipmentSearch} from "../../../models/shipment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlaneArrival, faPlaneDeparture,} from "@fortawesome/free-solid-svg-icons";
import QuotationStamp from "./components/QuotationStamp";
import {formatDate} from "../../../utils/dateUtil";
import ActionButton from "../../SharedComponents/ActionButton";

interface Lookups {
    customers: { [k: string]: string };
    profiles: { [k: string]: string };
    countries: { [k: string]: string };
}

// const SendingNotification: React.FC<{
//   condition: boolean | null;
//   title: string;
//   reset: () => void;
// }> = ({ condition, title, reset }) => {
//   return (
//     <CModal show={condition != null} onClose={reset}>
//       <CModalHeader>{title}</CModalHeader>
//       <CModalBody>
//         <span className={`text-${condition ? "success" : "danger"}`}>
//           {condition ? "Sending in queue" : "Failed to send"}
//         </span>
//       </CModalBody>
//     </CModal>
//   );
// };


const ViewQuotation = () => {
    const {id} = useParams() as any;
    // const history = useHistory();
    const [tabType, setTabType] = useState("");
    const [tableIsLoading, setTableIsLoading] = useState(false);
    const formRef = useRef<FormRef | null>(null);
    const hist = useHistory();
    const dispatch = useDispatch();
    const loc = useLocation();
    const [lookups, setLookups] = useState<Lookups | undefined>(undefined);
    //ts-ignore
    const [quotation, setQuotationProp, setQuotation] = useComplexState<QuotationUpdate>({});


    // const [quotationHistory, setQuotationHistory] = useState<
    //   QuotationHistoryItemInfo | undefined
    // >(undefined);
    const [inEditMode, setInEditMode] = useState(false);
    const [error, setError] = useState("");
    const [confirmModalOn, setConfirmModalOn] = useState<| "none"
        | "invoice"
        | "sendInvoice"
        | "payment"
        | "process"
        | "pay"
        | "unpay"
        | "cancel"
        | "delete"
        | "dueDate"
        | "unprocess">("none");

    const [customer, setCustomer] = useState<Account | null>(null);

    const [quotationShipment, setQuotationShipment] = useState<ShipmentSearch | undefined>(undefined);
    const [isLoadingShipment, setIsLoadingShipment] = useState(false);

    const [editTarget, setEditTarget] = useState<number | null>(null);
    const [createMode, setCreateMode] = useState(false);
    const [cashAccount, setCashAccount] = useState("");
    const [scenarios, setScenarios] = useState<QuotationScenario[]>();
    const [triggerProfile, setTriggerProfile] = useState<boolean>(true);
    const [editModal, setEditModal] = useState(false);
    const [shipmentNum, setShipmentNum] = useState<string>();
    const checkoutClient = new CheckoutClient();
    const ratesClient = new RatesClient();
    const lookupsClient = new LookupsClient();

    const shipClient = new ShipClient();

    useEffect(() => {
        if (inEditMode) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = null;
        }
    });
    useEffect(() => {
        const params = new URLSearchParams(loc.search);
        if (params.get("editMode")) setInEditMode(false);
    }, [loc.search]);

    const saveForm = async (state: QuotationUpdate, saveOnly?: boolean) => {
        state.items = state.items.map((i: QuotationChargeInfo) => ({
            ...i,
            taxRate: i.taxRate / 100,
        }));
        let res = await checkoutClient.UpdateQuotation(quotation.id, state);
        if (res.succeeded) {
            setInEditMode(false);
            if (!saveOnly) refresh();
        } else {
            if (res.status == 400) setError(res.data.SWException[0]);
        }
    };

    let scenarioOptions: { [k: string]: string } = {};
    scenarios?.forEach((scenario) => {
        scenarioOptions[scenario.code] = scenario.name;
    });

    const requestPayment = async (methods: ("sms" | "email")[]) => {
        inEditMode && (await formRef.current?.saveForm());
        const method = methods.length == 2 ? "smsAndEmail" : methods[0];
        const succeeded = await checkoutClient.NotifyPayment(
            quotation.id,
            method as any
        );
        setConfirmModalOn("none");
        if (succeeded) {
            dispatch(ShowNotification("Success", "Payment request sent"));
            await refresh();
        }
    };

    const editInvoiceDueDate = async (date: string) => {
        const succeeded = await checkoutClient.EditDueDate(quotation.id, date);
        setConfirmModalOn("none");
        if (succeeded) {
            dispatch(ShowNotification("Success", "Due Date Updated"));
            await refreshQuotation();
        }
    };

    // const markAsProcessed = async (date: any) => {
    //   inEditMode && (await formRef.current?.saveForm());
    //   const succeeded = await checkoutClient.MarkAsProcessed(quotation.id, date);
    //   setConfirmModalOn("none");
    //   if (succeeded) {
    //     dispatch(ShowNotification("Success", "Quotation marked as processed"));
    //     await refreshQuotation();
    //   }
    // };

    // const markAsPaid = async (date: any, reference: any, methods: any) => {
    //   const method = methods.length == 2 ? "smsAndEmail" : methods[0];
    //   const succeeded = await checkoutClient.MarkAsPaid(
    //     quotation.id,
    //     date,
    //     reference,
    //     method as any
    //   );
    //   setConfirmModalOn("none");
    //   if (succeeded) {
    //     dispatch(ShowNotification("Success", "Quotation marked as paid"));
    //     await refreshQuotation();
    //   }
    // };

    const cancel = async (date: any, methods: any) => {
        inEditMode && (await formRef.current?.saveForm());
        const method = methods.length == 2 ? "smsAndEmail" : methods[0];

        const succeeded = await checkoutClient.Cancel(
            quotation.id,
            date,
            method as any
        );

        if (succeeded) {
            setConfirmModalOn("none");
            dispatch(ShowNotification("Success", "Quotation cancelled"));
            await refreshQuotation();
        } else {
            dispatch(ShowNotification("Failed", "Failed to Cancel"));
        }
    };

    const deleteQuotation = async () => {
        const succeeded = await checkoutClient.Delete(quotation.id);
        setConfirmModalOn("none");
        if (succeeded) {
            dispatch(ShowNotification("Success", "Quotation deleted"));
            hist.push("/quotations/search");
        }
    };

    const issueInvoice = async (
        date: string,
        dueIn: number,
        sendInvoice: boolean
    ) => {
        inEditMode && (await formRef.current?.saveForm());

        const succeeded = await checkoutClient.IssueInvoice(
            quotation.id,
            date,
            dueIn
        );
        if (succeeded) {
            dispatch(ShowNotification("Success", "Invoice issued successfully"));
            if (sendInvoice) {
                const succeededSend = await checkoutClient.SendInvoice(quotation.id);
                if (succeededSend) {
                    dispatch(ShowNotification("Success", "Invoice sent successfully"));
                }
            }
            setConfirmModalOn("none");
            await refreshQuotation();
        }
    };
    const sendInvoice = async () => {
        inEditMode && (await formRef.current?.saveForm());

        const succeeded = await checkoutClient.SendInvoice(quotation.id);
        setConfirmModalOn("none");
        if (succeeded) {
            dispatch(ShowNotification("Success", "Invoice sent successfully"));
            await refreshQuotation();
        }
    };

    const refreshQuotation = async () => {
        const rs = await checkoutClient.GetQuotation(id);
        if (rs.succeeded)
            setQuotation({
                ...rs.data,
                items: rs.data.items.map((i: QuotationChargeInfo) => ({
                    ...i,
                    taxRate: i.taxRate * 100,
                })),
                currency: "EUR",
            });
        return rs;
    };

    const refresh = async (loadLookups?: boolean) => {
        if (loadLookups) {
            const profiles = (await checkoutClient.GetProfiles(true)) as {
                [k: string]: string;
            };
            const countries = (await lookupsClient.getCountries()) as {
                [k: string]: string;
            };
            const customers = await ratesClient.LookupCustomers();
            setLookups({customers, countries, profiles});
        }
        const rs = await refreshQuotation();
        if (!rs.succeeded) return;
        const q = rs.data;

        const profile = await checkoutClient.GetProfile(q.profile.id);
        profile && setCashAccount(profile.configuration?.cashAccount ?? []);
        profile && setScenarios(profile.scenarios);

        const customer = await ratesClient.GetCustomer(q.accountNumber);
        customer && setCustomer(customer);
        retrieveQuotationShipment();
    };

    useEffect(() => {
        refresh(true);
    }, [id]);

    const onDuplicate = async () => {
        let res = await checkoutClient.CreateQuotation({
            ...quotation,
            profileId: quotation.profile.id,
            scenarioCode: quotation.scenario.code,
            items: quotation.items.map((c: any) => ({
                ...c,
                taxRate: c.taxRate / 100,
            })),
        });

        if (res.succeeded) {
            dispatch(ShowNotification("Success", "Quotation is duplicated"));
            hist.push(`/quotations/${res.data.id}`);
        } else {
            dispatch(ShowNotification("Failed", "Quotation is not duplicated", true));
        }
    };

    const downloadInvoice = async (lang: "en" | "fr", type: string) => {
        let response = await checkoutClient.GetInvoice(quotation.id, lang, type);

        if (response.succeeded) {
            window.open(response.data.location, "_blank", "noreferrer");
        } else {
            dispatch(ShowNotification("Failed", "Invoice was not downloaded", true));
        }
    };

    const renderButton = (title: string, onPress: () => any, color: string = "primary", disableIfNoItems: boolean = true) => (
        <CButton
            style={{fontSize: "10px"}}
            disabled={disableIfNoItems ? quotation.items?.length == 0 : false}
            onClick={onPress}
            variant="outline"
            color={color}
            className="mr-2"
        >
            {title}
        </CButton>
    );

    const handleProcess = async (date: any) => {
        if (!isProcessed) {
            const succeeded = await checkoutClient.MarkAsProcessed(
                quotation.id,
                date
            );
            if (succeeded) {
                dispatch(ShowNotification("Success", "Quotation marked as processed"));
                setConfirmModalOn("none");

                await refreshQuotation();
            }
        }
    };

    const handleUnProcess = async () => {
        if (isProcessed) {
            const succeeded = await checkoutClient.MarkAsUnprocessed(quotation.id);
            if (succeeded) {
                dispatch(
                    ShowNotification("Success", "Quotation marked as Unprocessed")
                );
                setConfirmModalOn("none");

                await refreshQuotation();
            }
        }
    };

    const handlePay = async (date: any, reference: any, methods: any) => {
        if (!isPaid) {
            const method = methods.length == 2 ? "smsAndEmail" : methods[0];
            const succeeded = await checkoutClient.MarkAsPaid(
                quotation.id,
                date,
                reference,
                method as any
            );
            setConfirmModalOn("none");
            if (succeeded) {
                // setIsCheckedPaid(true);
                dispatch(ShowNotification("Success", "Quotation marked as paid"));
                await refreshQuotation();
            }
        }
    };

    const handleUnPay = async () => {
        if (isPaid) {
            const succeeded = await checkoutClient.MarkAsUnPaid(quotation.id);
            if (succeeded) {
                // setIsCheckedPaid(false);
                dispatch(ShowNotification("Success", "Quotation reversed to Unpaid"));
                setConfirmModalOn("none");

                await refreshQuotation();
            }
        }
    };

    const isPaid = quotation.status == "Paid" || quotation.status == "Canceled" && !!quotation.paidOn;
    const isProcessed = quotation.processed;

    const handlePaymentModal = () => {
        setConfirmModalOn(isPaid ? "unpay" : "pay");
    };

    const handleProcessModal = () => {
        setConfirmModalOn(isProcessed ? "unprocess" : "process");
    };

    const ShowScenarios = (triggerProfile: boolean) => {
        if (triggerProfile) {
            return (
                <Input
                    lookUp={scenarioOptions}
                    readonly={!!quotation.invoice}
                    label="Scenarios"
                    type="typeahead"
                    field="scenarioCode"
                />
            );
        }
    };

    const retrieveQuotationShipment = async () => {
        const shipmentNumber = formRef.current?.getFormValue("shipmentNumber");
        if (shipmentNumber) {
            if (shipmentNumber == quotationShipment?.number) {
                return;
            }
            setIsLoadingShipment(true);
            const rs = await shipClient.getShipmentByNumber(shipmentNumber);

            setIsLoadingShipment(false);
            if (rs && rs.found) {
                setQuotationShipment(rs.shipment);
            } else {
                setQuotationShipment(undefined);
            }
        }
        setQuotationShipment(undefined);
    };
    const handleFillShipperInfo = () => {
        formRef.current?.changeValue(
            "phone",
            quotationShipment?.shipperPhone ?? ""
        );
        formRef.current?.changeValue("name", quotationShipment?.shipperName ?? " ");
        formRef.current?.changeValue(
            "email",
            quotationShipment?.shipperEmail ?? " "
        );
        formRef.current?.changeValue(
            "billingAddress.companyName",
            quotationShipment?.shipperCompanyName ?? " "
        );
        formRef.current?.changeValue(
            "billingAddress.name",
            quotationShipment?.shipperName ?? " "
        );
        formRef.current?.changeValue(
            "billingAddress.street",
            quotationShipment?.shipperAddressLine1 ?? " "
        );
        formRef.current?.changeValue(
            "billingAddress.addressLine2",
            quotationShipment?.shipperAddressLine2 ?? " "
        );
        formRef.current?.changeValue(
            "billingAddress.zipcode",
            quotationShipment?.shipperPostCode ?? ""
        );
        formRef.current?.changeValue(
            "billingAddress.city",
            quotationShipment?.shipperCity ?? " "
        );
        formRef.current?.changeValue(
            "billingAddress.country",
            quotationShipment?.shipperCountry ?? " "
        );
    };

    const handleFillConsigneeInfo = () => {
        formRef.current?.changeValue(
            "phone",
            quotationShipment?.consigneePhone ?? ""
        );
        formRef.current?.changeValue(
            "name",
            quotationShipment?.consigneeName ?? ""
        );
        formRef.current?.changeValue(
            "email",
            quotationShipment?.consigneeEmail ?? " "
        );
        formRef.current?.changeValue(
            "billingAddress.companyName",
            quotationShipment?.consigneeCompanyName ?? " "
        );
        formRef.current?.changeValue(
            "billingAddress.name",
            quotationShipment?.consigneeName ?? " "
        );
        formRef.current?.changeValue(
            "billingAddress.street",
            quotationShipment?.consigneeAddressLine1 ?? " "
        );
        formRef.current?.changeValue(
            "billingAddress.addressLine2",
            quotationShipment?.consigneeAddressLine2 ?? " "
        );
        formRef.current?.changeValue(
            "billingAddress.zipcode",
            quotationShipment?.consigneePostCode ?? ""
        );
        formRef.current?.changeValue(
            "billingAddress.city",
            quotationShipment?.consigneeCity ?? " "
        );
        formRef.current?.changeValue(
            "billingAddress.country",
            quotationShipment?.consigneeCountry ?? " "
        );
    };

    const handleUpdateShipmentNumber = async () => {
        const res = await checkoutClient.updateshipmentnumber(id, shipmentNum!);
        if (res) {
            dispatch(
                ShowNotification("Success", "Shipment Number Was Updated Successfully!")
            );
            setEditModal(false);


            await refreshQuotation();
        } else {
            setEditModal(false);

        }
    };

    return (
        <CCard className="bg-white p-4 text-black">
            <ul className="nav">
                <li className="nav-item">
                    <CTooltip content="Back">
                        <CButton style={{fontSize: "10px"}}>
                            <CIcon
                                content={freeSet.cilArrowLeft}
                                onClick={() => hist.goBack()}
                            />
                        </CButton>
                    </CTooltip>
                </li>
                <li className="nav-item">
                    {/* <CTooltip content="attachments">
            <CButton>
              <CIcon content={freeSet.cilPaperclip} />
            </CButton>
          </CTooltip>{" "} */}
                </li>
                <li className="nav-item">
                    {quotation.invoice?.number && (
                        <CDropdown>
                            <CDropdownToggle>
                                <CTooltip content="Download Invoice">
                                    <CIcon content={freeSet.cilFile}/>
                                </CTooltip>
                            </CDropdownToggle>
                            <CDropdownMenu>
                                {quotation.profile.languages.map((l: any) => (
                                    <>
                                        <CDropdownItem
                                            onClick={() => downloadInvoice(l, "invoice")}
                                        >
                                            {l == "en" ? "English Invoice" : "French Invoice"}
                                        </CDropdownItem>
                                        {quotation.creditNoteNumber && (
                                            <CDropdownItem
                                                onClick={() => downloadInvoice(l, "credit_note")}
                                            >
                                                {l == "en"
                                                    ? "English Credit Note"
                                                    : "French Credit Note"}
                                            </CDropdownItem>
                                        )}
                                    </>
                                ))}
                            </CDropdownMenu>
                        </CDropdown>
                    )}
                </li>
                {quotation?.invoice?.dueDate &&
                    quotation.status !== "Canceled" &&
                    quotation.status !== "Paid" && (
                        <li className="nav-item mt-2">
                            {" "}
                            <CBadge color="primary">
                                due date:{" "}
                                {formatDate(quotation?.invoice?.dueDate)}
                            </CBadge>
                        </li>
                    )}{" "}
                <li className="mt-1">
                    {quotation.status == "Canceled" && (
                        <>
                            {/* <CBadge style={{ width: "60px", height: "15px" }} color="danger">
              {" "}
              {quotation.status}
            </CBadge> */}
                            <QuotationStamp title={quotation.status + "- credited"}/>
                        </>
                    )}
                    {quotation.status == "Deleted" && (
                        <>
                            <QuotationStamp title={quotation.status}/>
                        </>
                    )}
                </li>
                <li className="nav-item ml-auto">
                    <CFormText className={"pr-4"} color={"danger"}>
                        {error}
                    </CFormText>{" "}
                </li>
                {quotation.paymentShortUrl &&
                    <li className="nav-item ml-3 mr-3 mt-2">
                        <CLink
                            target="_blank"
                            href={quotation.paymentShortUrl}
                            className="ml-2 mr-1 cursor-pointer"
                        >
                            Payment Link
                        </CLink>
                        <CTooltip content="Copy Payment Link">
                            <CIcon
                                content={freeSet.cilCopy}
                                onClick={() => {
                                    navigator.clipboard.writeText(quotation.paymentShortUrl);
                                }}
                            />
                        </CTooltip>
                    </li>}
                <li className="nav-item ml-3 mr-3 mt-2">
                    <CInputGroup>
                        <CInputCheckbox
                            style={{cursor: "pointer"}}
                            checked={isPaid}
                            onClick={handlePaymentModal}
                        />
                        <CLabel className="text-primary">Paid</CLabel>
                    </CInputGroup>
                </li>
                <li className="nav-item ml-3 mr-3 mt-2">
                    <CInputGroup>
                        <CInputCheckbox
                            style={{cursor: "pointer"}}
                            checked={Boolean(quotation.processedOn !== null || isProcessed)}
                            onClick={handleProcessModal}
                        />
                        <CLabel className="text-primary"> Processed </CLabel>
                    </CInputGroup>{" "}
                </li>
                <li className="nav-item">
                    {(quotation.status == "Draft" ||
                        quotation.status == "Pending" ||
                        quotation.status == "Invoiced") && (
                        <>
                            <CContainer
                                //variant="ghost"
                                className="mt-0 mb-1"
                                onClick={() => setInEditMode(!inEditMode)}
                            >
                                {inEditMode && (
                                    <CTooltip content="save">
                                        <CIcon
                                            className=" ml-3 mt-2"
                                            onClick={formRef.current?.saveForm}
                                            content={freeSet.cilSave}
                                        />
                                    </CTooltip>
                                )}

                                {inEditMode ? (
                                    <CTooltip content="Cancel">
                                        <CIcon
                                            className=" ml-3 mt-2"
                                            style={{cursor: "pointer"}}
                                            name="cancel"
                                            content={freeSet.cilX}
                                        />
                                    </CTooltip>
                                ) : (
                                    <CTooltip content="Edit Quotation">
                                        <CIcon
                                            className="mt-2"
                                            style={{cursor: "pointer"}}
                                            size={"sm"}
                                            name="pencil"
                                            content={freeSet.cilPencil}
                                        />
                                    </CTooltip>
                                )}
                            </CContainer>
                        </>
                    )}
                </li>
            </ul>

            <Stepper
                draftDate={formatDate(quotation.createdOn)}
                pendingDate={
                    quotation.status == "Pending" ||
                    quotation.status == "Invoiced" ||
                    quotation.status == "InvoiceSent" ||
                    quotation.status == "Paid" ||
                    quotation.status == "Canceled" ||
                    quotation.status == "Deleted"
                        ? "1"
                        : undefined
                }
                invoicedDate={formatDate(quotation.invoice?.invoiceDate)}
                invoiceSentOn={
                    quotation.status == "InvoiceSent" ||
                    (quotation.status == "Canceled" && quotation.invoiceSentOn) ||
                    (quotation.status == "Paid" && quotation.invoiceSentOn)
                        ? formatDate(quotation.invoiceSentOn)
                        : ""
                }
                paidDate={
                    quotation.status == "Paid" ||
                    (quotation.status == "Canceled" && quotation.paidOn)
                        ? formatDate(quotation.paidOn)
                        : undefined
                }
                quotationId={quotation.id}
                invoiceNumber={quotation.invoice?.number}
            />
            <Form
                className="mt-4 small"
                ref={formRef}
                id="quotation-form"
                readonly={!inEditMode}
                initialState={quotation}
                onSubmit={saveForm}
            >
                <CRow className="p-2">
                    <CCol className="col-sm-2 pr-0">
                        <Input
                            lookUp={lookups?.profiles}
                            onChange={(val) => {
                                checkoutClient
                                    .GetProfile(val.toString())
                                    .then((v: QuotationProfile) => {
                                        formRef.current?.changeValue(
                                            "paymentMethods",
                                            v?.configuration?.supportedPaymentMethods ?? []
                                        );
                                        setCashAccount(v?.configuration?.cashAccount ?? []);
                                        setTriggerProfile(false);
                                        setScenarios(v?.scenarios);
                                        setTriggerProfile(true);
                                    });
                            }}
                            readonly={!!quotation.invoice}
                            label="Profile"
                            type="typeahead"
                            field="profileId"
                        />

                        <Input
                            validators={[notEmpty]}
                            label="Contact Name"
                            type="text"
                            field="name"
                        />
                        {ShowScenarios(triggerProfile)}
                    </CCol>

                    <CCol className="col-sm-2 pr-0">
                        <Input
                            label="Account"
                            type="typeahead"
                            field="accountNumber"
                            lookUp={lookups?.customers}
                            readonly={!!quotation.invoice}
                            onChange={(val) => {
                                ratesClient.GetCustomer(val.toString()).then((v) => {
                                    const account = v?.id == cashAccount ? {} : v;
                                    formRef.current?.changeValue(
                                        "name",
                                        account?.contactPerson ?? ""
                                    );

                                    formRef.current?.changeValue("email", account?.email ?? "");
                                    formRef.current?.changeValue("phone", account?.phone ?? "");
                                    formRef.current?.changeValue(
                                        "taxType",
                                        account?.taxType ?? "VAT"
                                    );

                                    formRef.current?.changeValue(
                                        "billingAddress.companyName",
                                        account?.name ?? " "
                                    );
                                    formRef.current?.changeValue(
                                        "billingAddress.name",
                                        account?.contactPerson ?? " "
                                    );
                                    formRef.current?.changeValue(
                                        "billingAddress.street",
                                        account?.addressLine1 ?? " "
                                    );
                                    formRef.current?.changeValue(
                                        "billingAddress.addressLine2",
                                        account?.addressLine2 ?? " "
                                    );
                                    formRef.current?.changeValue(
                                        "billingAddress.zipcode",
                                        account?.postCode ?? ""
                                    );
                                    formRef.current?.changeValue(
                                        "billingAddress.city",
                                        account?.city ?? " "
                                    );
                                    formRef.current?.changeValue(
                                        "billingAddress.country",
                                        account?.country ?? " "
                                    );
                                    formRef.current?.changeValue(
                                        "billingAddress.eori",
                                        account?.eori ?? " "
                                    );
                                    formRef.current?.changeValue(
                                        "billingAddress.vat",
                                        account?.vat ?? " "
                                    );
                                });
                            }}
                        />

                        <Input
                            label="Email"
                            type="text"
                            field="email"
                        />
                        <Input
                            type={"new-date"}
                            field={"expiryDate"}
                            label={"Expiry Date"}
                        />
                    </CCol>
                    <CCol className="col-sm-2 pr-0">
                        <Input
                            label="Shipment Number/ Reference"
                            labelUrl={quotation.shipmentUid ? `/shipments/${quotation.shipmentUid}` : undefined}
                            type="text"
                            field="shipmentNumber"
                            onBlur={retrieveQuotationShipment}
                        />

                        <Input label="Phone" type="text" field="phone"/>
                    </CCol>
                    <CCol className="col-sm-2">
                        <CRow className="nav-item mt-4">
                            {quotation.status == "Paid" && (
                                <CTooltip content="Edit shipment number">
                                    <CIcon className="ml-1 mt-1"
                                           content={freeSet.cilPencil}
                                           onClick={() => setEditModal(true)}
                                    />
                                </CTooltip>
                            )}
                            {inEditMode && quotationShipment?.number && (
                                <>
                                    <CTooltip content={"Get Shipper Data "}>
                                        <CButton
                                            style={{cursor: "pointer"}}
                                            disabled={isLoadingShipment}
                                            onClick={handleFillShipperInfo}
                                        >
                                            <FontAwesomeIcon
                                                style={{color: "#00007d"}}
                                                icon={faPlaneDeparture}
                                            />
                                        </CButton>
                                    </CTooltip>
                                    <CTooltip content={"Get Receiver Data"}>
                                        <CButton
                                            style={{cursor: "pointer"}}
                                            disabled={isLoadingShipment}
                                            onClick={handleFillConsigneeInfo}
                                        >
                                            <FontAwesomeIcon
                                                style={{color: "#00007d"}}
                                                icon={faPlaneArrival}
                                            />
                                        </CButton>
                                    </CTooltip>
                                </>
                            )}
                        </CRow>
                    </CCol>
                    <CCol className="col-sm-4">
                        <CRow>
                            <Input
                                className="col-sm-11 p-0"
                                validators={[notEmpty]}
                                lookUpMulti={[
                                    {value: "credit-card", label: "Credit Card"},
                                    {value: "bank-transfer", label: "Bank Transfer"},
                                ]}
                                label="Payment Methods"
                                field="paymentMethods"
                                type="multi-select"
                            />
                        </CRow>
                        <CRow className="">
                            <CCol className="col-sm-2 p-0">
                                <Input readonly label="Currency" type="text" field="currency"/>
                            </CCol>

                            <CCol className="col-sm-10 pr-0">
                                <Input
                                    lookUp={{VAT: "VAT", EU: "EU", "NON-EU": "NON-EU"}}
                                    label="Tax Type"
                                    field="taxType"
                                    type="radio"
                                />
                            </CCol>
                        </CRow>
                    </CCol>
                </CRow>
                <fieldset className="b-fieldset p-2">
                    <legend className="b-legend">
                        <h5>Billing Address</h5>
                    </legend>
                    <CRow>
                        <CCol className="col-sm-6">
                            <CRow>
                                <CCol className="col-sm-4 pr-0">
                                    <Input
                                        label="Company Name"
                                        field="billingAddress.companyName"
                                        type="text"
                                    />
                                </CCol>
                                <CCol className="col-sm-4 pr-0">
                                    <Input
                                        label="Contact Name"
                                        field="billingAddress.name"
                                        type="text"
                                    />
                                </CCol>
                            </CRow>

                            <CRow>
                                <CCol className="col-sm-4 pr-0">
                                    <Input
                                        label="Street"
                                        field="billingAddress.street"
                                        type="text"
                                    />
                                </CCol>
                                <CCol className="col-sm-4 pr-0">
                                    <Input
                                        label="Additional Address"
                                        field="billingAddress.addressLine2"
                                        type="text"
                                    />
                                </CCol>
                                <CCol className="col-sm-4">
                                    <CRow>
                                        <CCol className="col-sm-6">
                                            <Input
                                                label="Zip Code"
                                                field="billingAddress.zipcode"
                                                type="text"
                                            />
                                        </CCol>
                                        <CCol className="col-sm-6 pr-0 pl-0">
                                            <Input
                                                label="City"
                                                field="billingAddress.city"
                                                type="text"
                                            />
                                        </CCol>
                                    </CRow>
                                </CCol>
                            </CRow>
                        </CCol>
                        <CCol className="col-sm-2"/>
                        <CCol className="col-sm-4">
                            <CRow>
                                <CCol className="col-sm-6 p-0">
                                    <Input label="VAT" field="billingAddress.vat" type="text"/>
                                </CCol>
                                <CCol className="col-sm-6">
                                    <Input label="Eori" field="billingAddress.eori" type="text"/>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol className="col-sm-12 pl-0">
                                    <Input
                                        lookUp={lookups?.countries}
                                        label="Country"
                                        type="typeahead"
                                        field="billingAddress.country"
                                    />
                                </CCol>
                            </CRow>
                        </CCol>
                    </CRow>
                </fieldset>

                <br/>
                <CTabs>
                    <CCard>
                        <CNav variant="tabs">
                            <CNavLink onClick={() => setTabType("addItems")}>Items</CNavLink>
                            <CNavLink onClick={() => setTabType("attachments")}>
                                Attachments
                            </CNavLink>
                            <CNavLink onClick={() => setTabType("history")}>
                                Quotation History
                            </CNavLink>
                        </CNav>
                        <CTabContent>
                            <CTabPane>
                                <CRow className="justify-content-start">
                                    <ActiveAddItem
                                        readonly={
                                            tableIsLoading ||
                                            (quotation.status != "Draft" &&
                                                quotation.status != "Pending")
                                        }
                                        setCreateMode={setCreateMode}
                                    />
                                </CRow>

                                <ItemsTable
                                    tableIsLoading={tableIsLoading}
                                    readonly={
                                        tableIsLoading ||
                                        (quotation.status != "Draft" &&
                                            quotation.status != "Pending")
                                    }
                                    onDelete={(index) => {
                                        formRef.current?.changeValue(
                                            "items",
                                            formRef.current
                                                .getFormValue<any[]>("items")
                                                .splice(index, 1)
                                        );
                                        setTableIsLoading(true);
                                        formRef.current?.saveForm().then(() => {
                                            setTableIsLoading(false);
                                        });
                                    }}
                                    setEditTarget={setEditTarget}
                                />
                            </CTabPane>
                            <CTabPane>
                                <QuotationAttachments
                                    attachments={quotation.attachments}
                                    onAttachmentsChanged={refreshQuotation}
                                />
                            </CTabPane>

                            <CTabPane>
                                <QuotationHistory quotationHistory={quotation.history}/>
                            </CTabPane>
                        </CTabContent>
                    </CCard>
                </CTabs>
            </Form>
            <CRow>
                <CCol>
                    <AddEditItem
                        taxRateAllowed={formRef.current?.getFormValue("taxType") == "VAT"}
                        createMode={createMode}
                        setCreateMode={setCreateMode}
                        readonly={!inEditMode}
                        items={formRef.current?.formState.values.items ?? []}
                        editTarget={editTarget}
                        setEditTarget={setEditTarget}
                        onAdd={(body) => {
                            formRef.current?.changeValue("items", [
                                ...formRef.current?.getFormValue("items"),
                                body,
                            ]);
                            formRef.current?.saveForm();
                        }}
                        onEdit={(id, body) => {
                            let newItems = [...formRef.current?.getFormValue("items")];

                            newItems[id] = body;
                            formRef.current?.changeValue("items", newItems);
                            formRef.current?.saveForm();
                        }}
                    />
                </CCol>
            </CRow>

            <CRow className="justify-content-around">
                <CCol>
                    {!inEditMode && renderButton("Duplicate", () => onDuplicate())}
                </CCol>
                <CCol className="text-right">
                    <CButtonGroup style={{fontSize: "10px"}}>
                        {!quotation.associatedInvoiceNumber &&
                            quotation.status != "Canceled" &&
                            quotation.status != "Deleted" &&
                            renderButton("Issue Invoice", () => setConfirmModalOn("invoice"))}

                        {quotation.associatedInvoiceNumber &&
                            renderButton("Send Invoice", () =>
                                setConfirmModalOn("sendInvoice")
                            )}

                        {quotation.status != "Paid" &&
                            quotation.status != "Canceled" &&
                            quotation.status != "Deleted" &&
                            renderButton("Request Payment", () =>
                                setConfirmModalOn("payment")
                            )}

                        {quotation.status != "Canceled" &&
                            quotation.status != "Deleted" &&
                            !quotation.canceledOn &&
                            renderButton("Delete", () =>
                                    quotation.status == "Draft" ? setConfirmModalOn("delete") : setConfirmModalOn("cancel")
                                , "danger", false)}

                        {quotation.status != "Paid" &&
                            quotation.status != "Canceled" &&
                            quotation.status != "Deleted" &&
                            quotation.associatedInvoiceNumber &&
                            renderButton("Edit Due Date", () => setConfirmModalOn("dueDate"))}
                    </CButtonGroup>
                </CCol>
            </CRow>

            {/* Edit Shipment Number */}
            <CModal show={editModal} onClose={() => setEditModal(false)}>
                <CModalHeader>
                    <h5>Edit Shipment Number</h5>
                </CModalHeader>
                <CModalBody>
                    <CRow className={"mt-2 pl-4"}>
                        <CCol>
                            <CLabel>Shipment Number</CLabel>
                            <CInput
                                onChange={(e: any) => {
                                    const value = e.target.value;
                                    setShipmentNum(value);
                                }}
                            />
                        </CCol>
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CRow className="justify-content-between w-100">
                        <CCol className="text-left">
                            <CButton
                                variant="outline"
                                color="danger"
                                onClick={() => setEditModal(false)}
                            >
                                Cancel
                            </CButton>
                        </CCol>
                        <CCol className="text-right">
                            <ActionButton
                                text={"Save"}
                                onClick={handleUpdateShipmentNumber}
                            />
                        </CCol>
                    </CRow>
                </CModalFooter>
            </CModal>

            {confirmModalOn == "dueDate" && (
                <EditInvoiceDueDate
                    dueDate={quotation.invoice.dueDate}
                    onClose={() => setConfirmModalOn("none")}
                    onSubmit={(date) => editInvoiceDueDate(date)}
                />
            )}

            {confirmModalOn == "payment" && (
                <RequestAPaymentModal
                    onClose={() => setConfirmModalOn("none")}
                    onSubmit={(methods) => requestPayment(methods as any)}
                />
            )}

            {confirmModalOn == "process" && (
                <MarkAsProcessedModal
                    onClose={() => setConfirmModalOn("none")}
                    onSubmit={(date: any) => handleProcess(date)}
                />
            )}
            {confirmModalOn == "unprocess" && (
                <MarkAsUnProcessed
                    onClose={() => setConfirmModalOn("none")}
                    onSubmit={() => handleUnProcess()}
                />
            )}
            {confirmModalOn == "invoice" && (
                <ConfirmIssueInvoice
                    initialDueIn={customer?.dueDays ?? 0}
                    onClose={() => setConfirmModalOn("none")}
                    onSubmit={issueInvoice}
                />
            )}
            {confirmModalOn == "sendInvoice" && (
                <ConfirmInvoiceSendModal
                    onClose={() => setConfirmModalOn("none")}
                    onSubmit={sendInvoice}
                />
            )}
            {confirmModalOn == "pay" && (
                <MarkAsPaidModal
                    onClose={() => setConfirmModalOn("none")}
                    onSubmit={(date: any, reference: any, methods: any) =>
                        handlePay(date, reference, methods)
                    }
                />
            )}
            {confirmModalOn == "unpay" && (
                <MarkAsUnPaid
                    onClose={() => setConfirmModalOn("none")}
                    onSubmit={handleUnPay}
                />
            )}
            {confirmModalOn == "cancel" && (
                <CancelQuotationModal
                    onClose={() => setConfirmModalOn("none")}
                    onSubmit={cancel}
                />
            )}
            {confirmModalOn == "delete" && (
                <DeleteQuotationModal
                    onClose={() => setConfirmModalOn("none")}
                    onSubmit={deleteQuotation}
                />
            )}
        </CCard>
    );
};

export default ViewQuotation;
