import React, {useEffect, useState} from "react";
import {CCol, CInputCheckbox, CLink, CRow, CTooltip} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import {TableHeader} from "./TableHeader";

import {BillingType, ChargeAddUpdate, ChargeSearch} from "../../../../models/accounting";
import AccountingClient from "../../../../clients/accountingClient";
import {ShipmentGet} from "../../../../models/shipment";
import {formatDateTime} from "../../../../utils/dateUtil";
import ConfirmModal from "../../../SharedComponents/ConfirmModal";
import {ShowNotification} from "../../../../store/actions/auth";
import {useDispatch} from "react-redux";
import AddEditCharge from "../modals/AddEditCharge";

interface IProps {
    shipment: ShipmentGet
    shipmentCharges: ChargeSearch[];
    onRefresh: () => void;
    billingType: BillingType
    chargeTypes: { [k: string]: string }
}

const Charges = (props: IProps) => {
    const {shipmentCharges, onRefresh, billingType, chargeTypes, shipment} = props
    const dispatch = useDispatch()
    const [showConfirmDelete, setShowConfirmDelete] = useState(0);
    const [showEditCharge, setShowEditCharge] = useState(0);
    const [showAddCharge, setShowAddCharge] = useState(false);
    const [totalTax, setTotalTax] = useState<number>(0);
    const [showDeleted, setShowDeleted] = useState<boolean>(false);
    const [totalValue, setTotalValue] = useState<number>(0);
    const [totalTaxWithValue, setTotalTaxWithValue] = useState<number>(0);

    const accountingClient = new AccountingClient();
    const charges = shipmentCharges.filter(c => c.billingType == billingType);
    const header = billingType == BillingType.Selling ?
        ["Id", "Type", "Value", "Tax", "Total With Tax", "Is Ready To Be Invoiced",
            "Comments", "quotation", "invoice", "On", "By", ""] :
        ["Id", "Type", "Value", "Tax", "Total With Tax",
            "Comments", "invoice", "On", "By", ""]
    const Td = (value: { val: any, empty?: string }) => <td> {value.val ?? value.empty} </td>

    const handleDelete = async () => {
        const result = await accountingClient.DeleteCharge(showConfirmDelete);
        if (result) {
            dispatch(ShowNotification("Success", "Charge deleted successfully", false));
            onRefresh()
        }
        setShowConfirmDelete(0);
    }

    const handleSave = async (toAddOrUpdate: ChargeAddUpdate) => {

        const result = toAddOrUpdate.id == 0 ? await accountingClient.AddCharge(toAddOrUpdate) : await accountingClient.UpdateCharge(toAddOrUpdate);
        if (result) {
            dispatch(ShowNotification("Success", "Charge saved successfully", false));
            setShowEditCharge(0);
            setShowAddCharge(false);
            onRefresh()
        }

    }


    const calculateSumForAllAmounts = () => {
        let Value = 0;
        let Tax = 0;
        let TaxWithValue = 0;
        charges.map((charge) => {
            if (showDeleted) {
                Value += charge.value;
                Tax += charge.taxValue;
                TaxWithValue += charge.totalValue;
            } else {
                if (!charge.deleted) {
                    Value += charge.value;
                    Tax += charge.taxValue;
                    TaxWithValue += charge.totalValue;
                }
            }

        })

        setTotalValue(Value);
        setTotalTax(Tax);
        setTotalTaxWithValue(TaxWithValue)

    }

    useEffect(() => {
        calculateSumForAllAmounts()
    }, [charges, showDeleted]);

    return (
        <>
            <div className="piecesTabTable">

                <CTooltip content={"Add piece"}>
                    <CIcon
                        size={"xl"}
                        color={"primary"}
                        style={{cursor: "pointer"}}
                        content={freeSet.cilPlus}
                        onClick={() => setShowAddCharge(true)}
                    />
                </CTooltip>

                <CCol className={' d-flex justify-content-end mb-2'}>
                    <CRow className={'d-flex justify-content-between font-md font-weight-bolder'} style={{width:'4%'}}>
                    Show deleted
                    
                    <CInputCheckbox
                        checked={showDeleted}
                        onChange={() => {
setShowDeleted(!showDeleted)
                        }}/>
                    </CRow>
                </CCol>
                <table className="position-relative table table-hover table-sm">
                    <TableHeader header={header}/>

                    <tbody>
                    {(charges.length > 0) && <tr>
                        <Td val={''}/>
                        <Td val={''}/>
                        <Td val={totalValue}/>
                        <Td val={totalTax}/>
                        <Td val={totalTaxWithValue}/>
                        <Td val={''}/>
                        <Td val={''}/>
                        <Td val={''}/>
                        <Td val={''}/>
                        <Td val={''}/>

                    </tr>}
                    {chargeTypes && charges?.map(charge => (
                        showDeleted ? (
                            <tr key={charge.id} style={{textDecoration: charge.deleted ? "line-through" : "unset"}}>
                                <Td val={charge.id}/>
                                <Td val={chargeTypes[charge.typeId]}/>
                                <Td val={charge.value}/>
                                <Td val={charge.taxValue}/>
                                <Td val={charge.totalValue}/>
                                {billingType === BillingType.Selling && (
                                    <Td val={charge.isReadyToBeInvoiced ? "true" : "false"}/>
                                )}
                                <Td val={charge.comments}/>
                                {billingType === BillingType.Selling && (
                                    <Td val={charge.quotationNumber}/>
                                )}
                                <Td val={
                                    charge.invoiceId ? (
                                        <CLink to={`/financial/billing/${charge.invoiceId}`}>
                                            {charge.alternativeInvoiceNumber ?? charge.invoiceNumber ?? 'View Draft'}
                                        </CLink>
                                    ) : null
                                }/>
                                <Td val={formatDateTime(charge.createdOn)}/>
                                <Td val={charge.createdBy}/>

                                {!charge.quotationNumber && !charge.invoiceNumber && !charge.deleted ? (
                                    <td>
                                        <CIcon
                                            content={freeSet.cilPencil}
                                            style={{cursor: "pointer", margin: "auto"}}
                                            onClick={() => setShowEditCharge(charge.id)}
                                        />
                                        <CIcon
                                            content={freeSet.cilTrash}
                                            style={{cursor: "pointer", margin: "auto"}}
                                            onClick={() => setShowConfirmDelete(charge.id)}
                                        />
                                    </td>
                                ) : (
                                    <td/>
                                )}
                            </tr>
                        ) : (!charge.deleted &&
                            <tr key={charge.id} style={{textDecoration: charge.deleted ? "line-through" : "unset"}}>
                                <Td val={charge.id}/>
                                <Td val={chargeTypes[charge.typeId]}/>
                                <Td val={charge.value}/>
                                <Td val={charge.taxValue}/>
                                <Td val={charge.totalValue}/>
                                {billingType === BillingType.Selling && (
                                    <Td val={charge.isReadyToBeInvoiced ? "true" : "false"}/>
                                )}
                                <Td val={charge.comments}/>
                                {billingType === BillingType.Selling && (
                                    <Td val={charge.quotationNumber}/>
                                )}
                                <Td val={
                                    charge.invoiceId ? (
                                        <CLink to={`/financial/billing/${charge.invoiceId}`}>
                                            {charge.alternativeInvoiceNumber ?? charge.invoiceNumber ?? 'View Draft'}
                                        </CLink>
                                    ) : null
                                }/>
                                <Td val={formatDateTime(charge.createdOn)}/>
                                <Td val={charge.createdBy}/>

                                {!charge.quotationNumber && !charge.invoiceNumber && !charge.deleted ? (
                                    <td>
                                        <CIcon
                                            content={freeSet.cilPencil}
                                            style={{cursor: "pointer", margin: "auto"}}
                                            onClick={() => setShowEditCharge(charge.id)}
                                        />
                                        <CIcon
                                            content={freeSet.cilTrash}
                                            style={{cursor: "pointer", margin: "auto"}}
                                            onClick={() => setShowConfirmDelete(charge.id)}
                                        />
                                    </td>
                                ) : (
                                    <td/>
                                )}
                            </tr>)
                    ))}


                    </tbody>
                </table>
            </div>
            {showConfirmDelete != 0 &&
                <ConfirmModal body={"Are you sure you want to delete charge"} onClose={() => setShowConfirmDelete(0)}
                              title={"Delete charge"} onSubmit={handleDelete}/>}
            {showAddCharge &&
                <AddEditCharge hideIsGroupedCheckBox={true} onSave={handleSave} account={shipment.account}
                               onClose={() => setShowAddCharge(false)}
                               chargeTypes={chargeTypes} billingType={billingType} shipmentUid={shipment.uid}
                               showList={false} accountingClient={accountingClient}/>}
            {showEditCharge != 0 &&
                <AddEditCharge hideIsGroupedCheckBox={true} onSave={handleSave} account={shipment.account}
                               onClose={() => setShowEditCharge(0)}
                               chargeToEdit={charges.find(c => c.id == showEditCharge)}
                               chargeTypes={chargeTypes} billingType={billingType} shipmentUid={shipment.uid}
                               accountingClient={accountingClient}
                               showList={false}/>}

        </>

    )
}

export default Charges;
