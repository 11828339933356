import {CFormGroup, CFormText, CInput, CInputGroup, CLabel, CRow,} from "@coreui/react";
import React, {forwardRef, useState} from "react";
import DatePicker from "react-datepicker";
import DateFormat from "dateformat";
import {parseISO} from 'date-fns'
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";

interface Props {
    readOnly?: boolean;
    value: string | any | null | undefined;
    showTimeInput?: boolean
    handleOnChange: (value: any) => void
    className?: any
    onBlur?: (e: any) => any
    validationErrors?: string
    label?: string
    name?: string
    range?: boolean
    isClearable?:boolean
}

const valueFormat = "yyyy-mm-dd'T'HH:MM:ss.l";

const ToCoreFormat = (value: string | any | null | undefined): Date | null | undefined => {

    if (!value || value instanceof Date)
        return value;

    return parseISO(value);
    //return new Date(value)
}

const CustomInput = forwardRef<any, any>(({coreValue, showTime, onClick,isClearable}, ref) => {

    return (

        <span className="form-control small" onClick={onClick} ref={ref}>
       <CRow className={`d-flex justify-content-between ml-1 ${isClearable ? "mr-3" : "mr-1"}`}> {formatValue(coreValue, showTime)}<CIcon
           content={freeSet.cilCalendar}/></CRow>
      </span>
    );
});

const formatValue = (value: Date | null | undefined, showTime?: boolean) => {

    if (!value) return ""

    if (showTime)
        return DateFormat(value, "dd-mm-yyyy hh:MM TT")
    else
        return DateFormat(value, "dd-mm-yyyy")
}


const SWDateAndTimePicker = (props: Props) => {


    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();


    const {readOnly, value, showTimeInput, onBlur, handleOnChange, validationErrors, label, name, range} = props;

    const coreValue = ToCoreFormat(value);

    const onChange = (value: Date | null) => {
        if (!value) {
            handleOnChange(value);
            return
        }
        //2021-04-10T10:00:00.0000000
        // @ts-ignore
        // @ts-ignore
        let valueToHandleOnChange = showTimeInput ? DateFormat(value as Date, valueFormat) : DateFormat(new Date(value.getFullYear(), value.getMonth(), value.getDate()), valueFormat)

        handleOnChange(valueToHandleOnChange);
    }

    const onRangeChange = (date: [Date, Date]) => {
        if (date[0]) {
            setStartDate(date[0])
        }
        if (date[1]) {
            setEndDate(date[1])
        }
    }

    return (<div>
        <CFormGroup>
            {label && <CLabel className={"small"}>{label}</CLabel>}
            <CInputGroup>
                {readOnly ?
                    <CInput
                        style={{fontSize: "10px"}}
                        readOnly={readOnly}
                        type="text"
                        disabled={readOnly}
                        value={formatValue(coreValue, showTimeInput)}
                        name={name}
                    /> :
                    range ?
                        <DatePicker
                            isClearable={props.isClearable}
                            selected={startDate}
                            onChange={date => onRangeChange(date as [Date, Date])}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            inline
                        />
                        : <DatePicker
                            isClearable={props.isClearable}
                            shouldCloseOnSelect={!showTimeInput}
                            showYearDropdown
                            onBlur={onBlur}
                            readOnly={readOnly}
                            selected={coreValue}
                            showTimeInput={showTimeInput}
                            onChange={onChange}
                            name={name}
                            customInput={<CustomInput coreValue={coreValue} isClearable={props.isClearable}  showTime={showTimeInput}/>}
                        />
                }
            </CInputGroup>
            {validationErrors ? (
                <CFormText
                    style={{fontSize: "1em"}}
                    color="danger"
                    className="help-block">
                    {validationErrors}

                </CFormText>
            ) : (
                ""
            )}
        </CFormGroup>
    </div>)

};

export default SWDateAndTimePicker;

