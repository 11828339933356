import React, { useState } from "react";
import { CCol, CModal, CModalBody, CModalFooter, CModalHeader, CRow } from "@coreui/react";
import ActionButton from "../../../../SharedComponents/ActionButton";
import SWInput from "../../../../SharedComponents/SWInput";
import CameraIcon from "../../../../../icons/camera";
import CaptureImageModal from "../../../../SharedComponents/CaptureImageModal";
import WarehouseClient from "../../../../../clients/warehousesClient";

interface Props {
    onClose: () => void
    onSubmit: (attachmentLink: string) => void
    account: string
}

const EditImageModal = (props: Props) => {
    const warehouseClient = new WarehouseClient();
    const { account, onClose, onSubmit } = props;
    const [fileLoading, setFileLoading] = useState<boolean>(false);
    const [captureModal, setCaptureModal] = useState<boolean>(false);
    const [attachmentLink, setAttachmentLink] = useState<string>('');

    const handleFileImport = async (file?: File) => {
        setFileLoading(true);
        if (!file) return
        let res = await warehouseClient.UploadSkuImage(file, account)
        if (res.location) {
            setAttachmentLink(res?.location)
            setFileLoading(false)
        }
    }

    return (
        <div className="small text-primary">
            <CModal
                show={true}
                centered
                onClose={() => !!attachmentLink ? onSubmit(attachmentLink) : onClose()}
                closeOnBackdrop={false}
            >
                <CModalHeader closeButton>
                    <h5>Edit Image</h5>
                </CModalHeader>
                <CModalBody>
                    <CCol>
                        <SWInput
                            label={"Attachment Link"}
                            name={"attachmentLink"}
                            value={attachmentLink}
                            onChange={(e) => setAttachmentLink(e)}
                        />
                    </CCol>
                    <CCol style={{ display: "flex", alignItems: "center" }}  >
                        {!fileLoading ? <SWInput
                            className="mt-4" type={"file"} id={"input-file"}
                            onChangeFile={(f) => handleFileImport(f)} />
                            : <div
                                className={"rounded mb-3 h-100 m-0 py-1 px-2 d-flex align-items-center justify-content-center border-primary"}>Please
                                wait...</div>}

                        < CameraIcon
                            style={{
                                marginLeft: "1.5rem",
                                marginTop: "0.5rem"
                            }}
                            tabIndex={-1}
                            onClick={() => setCaptureModal(true)}
                            tooltipText={'Capture'} />
                    </CCol>
                </CModalBody>
                <CModalFooter />
                {captureModal &&
                    <CaptureImageModal
                        allowOneCaptureOnly
                        onClose={(e) => {
                            if (e) {
                                setAttachmentLink(e?.[0]?.location) // one capture
                            }
                            setCaptureModal(false)
                        }}
                        skusUploadData={{account}}
                    />}
            </CModal>
        </div>

    )
}

export default EditImageModal