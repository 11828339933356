import ResetPasswordContainer from "./ResetPasswordContainer";
import {Formik, useFormik} from "formik";
import {CButton, CCol, CRow} from "@coreui/react";
import AuthClient from "../../../clients/authClient";
import * as yup from "yup";
import SWInput from "../../SharedComponents/SWInput";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {ShowNotification} from "../../../store/actions/auth";

const EmailValidation = () => {
    const authClient = new AuthClient();
    const [loader,setLoader]=useState(false)
    const dispatch=useDispatch()

    const {setFieldValue, values, handleSubmit, errors} = useFormik({
        initialValues: {
            emailOrUserName: ''
        },
        onSubmit: async (v) => {
            setLoader(true)
           const res= await authClient.InitiatePasswordReset(v)
            if(res.succeeded){
                dispatch(ShowNotification('success',"The email has been sent.",false))

            }
            setLoader(false)
        },
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: yup.object({
            emailOrUserName: yup.string().required('Email or Username is required.'),

        })
    })
    return (
        <ResetPasswordContainer>
            <ResetPasswordContainer.Title>
                Enter your Email or Username
            </ResetPasswordContainer.Title>
            <ResetPasswordContainer.Body>
                <>
                        <CCol style={{rowGap:'8px'}} className={'d-flex justify-content-center align-items-center flex-column'}>
                            <SWInput style={{width:'300px'}} type={'text'}  value={values.emailOrUserName} name={'email'}
                                     placeHolder={'Email or Username'}
                                     onChange={(e) => setFieldValue('emailOrUserName', e)}
                                     validationErrors={errors.emailOrUserName}/>

                            <CButton disabled={loader}
                                     style={{width:'300px'}}
                                     className="mr-2"
                                     color="primary" onClick={handleSubmit}>
                                Send
                            </CButton>
                        </CCol>

                </>
            </ResetPasswordContainer.Body>
        </ResetPasswordContainer>
    )
}

export default EmailValidation
