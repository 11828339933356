import React, {useState} from 'react';
import {CButton, CCol, CModal, CModalFooter, CModalHeader, CRow} from "@coreui/react";
import ActionButton from "../../../SharedComponents/ActionButton";


interface IProps{
    onClose:()=>any
    onSubmit:(date?:any) => any
}

const Component = (props:IProps) => {
    const [disabled, setDisabled] = useState(false);
  const onClick = () => {
    setDisabled(true);
    props.onSubmit();
    
  }

    return (
        <CModal
            show={true}
            onClose={props.onClose}
        >
            <CModalHeader>
                <h6>Are you sure you want reverse to Unprocessed?</h6>
            </CModalHeader>
            <CModalFooter>
                <CRow className="justify-content-between w-100">
                    <CCol className="text-left">
                        <CButton
                            variant="outline"
                            color="danger"
                            onClick={props.onClose}
                        >
                            Cancel
                        </CButton>
                    </CCol>
                    <CCol className="text-right">
                        <ActionButton
                            text={"Yes"}
                            disabled={disabled}
                            onClick={onClick}
                        />
                    </CCol>
                </CRow>
            </CModalFooter>
        </CModal>
    )
}

export default Component;