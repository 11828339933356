import { CRow, CCol } from "@coreui/react";
import React from "react";
import ShipClient from "../../../../clients/shipClient";
import { Input } from "../../../SharedComponents/SWForm";

const ContainerTypeInputs = () => {
  return (
    <>
      <CRow>
        <CCol>
          <Input label="Type name" type="text" field="name" />
        </CCol>
      </CRow>
    </>
  );
};

export default ContainerTypeInputs;
