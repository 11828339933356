export const SET_USER = "SET_USER";
export const LOG_OUT = "LOG_OUT";
export const SET_LOGGING_IN = "SET_LOGGING_IN"

export const SET_CONTAINER_FILTER_GROUP = "SET_CONTAINER_FILTER_GROUP";
export const SET_CONTAINER_IS_LOADING = "SET_CONTAINER_IS_LOADING ";
export const SET_CONTAINER_RESULT = "SET_CONTAINER_RESULT ";

export const SET_SHIPMENT_FILTER_GROUP = "SET_SHIPMENT_FILTER_GROUP";
export const SET_SHIPMENT_IS_LOADING = "SET_SHIPMENT_IS_LOADING ";
export const SET_SHIPMENT_RESULT = "SET_SHIPMENT_RESULT ";
export const TOGGLE_SHIPMENT_VIEW = "TOGGLE_SHIPMENT_VIEW ";

export const SET_QUOTATION_FILTER_GROUP = "SET_QUOTATION_FILTER_GROUP";
export const SET_QUOTATION_IS_LOADING = "SET_QUOTATION_IS_LOADING ";
export const SET_QUOTATION_RESULT = "SET_QUOTATION_RESULT ";

export const SET_SORTINGRULE_FILTER_GROUP = "SET_SORTINGRULES_FILTER_GROUP";
export const SET_SORTINGRULE_IS_LOADING = "SET_SORTINGRULE_IS_LOADING";
export const SET_SORTINGRULE_RESULT = "SET_SORTINGRULE_RESULT";

export const SET_CONTAINERTYPE_FILTER_GROUP = "SET_CONTAINERTYPE_FILTER_GROUP";
export const SET_CONTAINERTYPE_IS_LOADING = "SET_CONTAINERTYPE_IS_LOADING";
export const SET_CONTAINERTYPE_RESULT = "SET_CONTAINERTYPE_RESULT";

export const SET_USER_FILTER_GROUP = "SET_USER_FILTER_GROUP";
export const SET_USER_IS_LOADING = "SET_USER_IS_LOADING";
export const SET_USER_RESULT = "SET_USER_RESULT";


export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_NOTIFICATION = "SET_NOTIFICATION";

export const SET_SHIPMENT_REPRINT_RESULT = "SET_SHIPMENT_REPRINT_RESULT"
export const SET_SHIPMENT_REPRINT_FILTER_GROUP = "SET_SHIPMENT_REPRINT_FILTER_GROUP"
export const SET_SHIPMENT_REPRINT_IS_LOADING = "SET_SHIPMENT_REPRINT_IS_LOADING"


export const SET_CREATE_SHIPMENT_DATA = "SET_CREATE_SHIPMENT_DATA"


export const SET_SEARCH_COSTS = "SET_SEARCH_COSTS"
export const SET_SEARCH_SELLING = "SET_SEARCH_SELLING"


export const SET_ACCOUNT_FILTER_GROUP = "SET_ACCOUNT_FILTER_GROUP";
export const SET_ACCOUNT_IS_LOADING = "SET_ACCOUNT_IS_LOADING ";
export const SET_ACCOUNT_RESULT = "SET_ACCOUNT_RESULT ";
export const SET_ACCOUNT_FIRST_RESULT = "SET_ACCOUNT_FIRST_RESULT ";