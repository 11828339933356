import {Field, Form, Formik} from "formik";
import {CButton, CButtonGroup, CCol, CContainer, CRow} from "@coreui/react";
import InputField from "../../../SharedComponents/FormikCustomFields/InputField";
import DateInputField from "../../../SharedComponents/FormikCustomFields/DateInputField";
import SelectField from "../../../SharedComponents/FormikCustomFields/SelectField";
import React, {useState} from "react";
import {IAddCOD, StandardShipmentTrace} from "../../../../models/shipment";
import {date, object, string} from "yup";
import ShipClient from "../../../../clients/shipClient";
import WarningModal from "../modals/WarningModal";
import {ShowNotification} from "../../../../store/actions/auth";
import {useDispatch} from "react-redux";
import {formatDate, formatTime} from "../../../../utils/dateUtil";
import ActionButton from "../../../SharedComponents/ActionButton";


const AddCODForm = ({setIsSubmitting}:any) =>{
    const shipClient = new ShipClient();
    const [shipmentCodNumber, setShipmentCodNumber] = useState(0)
    const [shipmentCod, setShipmentCod] = useState<any[]>([])
    const dispatch = useDispatch()
    const [showWarning, setShowWarning] = useState(false)
    const [recentlyAdded, setRecentlyAdded] = useState<any>([])

    const initialValues: IAddCOD = {
        amount: "",
        payMode: "",
        date: new Date(),
        checkNumber: null,
        senderBank: null,
        shipmentNumber: "",
        onHold:false,
    }

    const renderHeader = (header: string[]) => header.map((value, index) => {
        return (
            <th className="position-sticky bg-white" key={index}>
                {value}
            </th>
        );
    });

    const validationSchema = () => object().shape({
        amount: string().required("required"),
        payMode: string().required("required"),
        date: date().required("required"),
        shipmentNumber: string().required("required")
    })

    const clearForm = (form: any) => {
        form.setFieldValue("amount", '')
        form.setFieldValue("checkNumber", '')
        form.setFieldValue("senderBank", '')
        form.setFieldValue("shipmentNumber", '')
    }


    const isCodRequestValid = (values:any) => {
        let codAmount = 0
        shipmentCod.map((item: any)=> {
            codAmount += item.amount
        })
        return shipmentCodNumber === (values.amount + codAmount);
    }

   const handleSubmit = async (values:any)=> {
        if(isCodRequestValid(values)){
           setIsSubmitting(true)
           const rs:any = shipClient.addShipmentCOD({...values,onHold:values?.payMode.toLowerCase() === "check" ? true:false ,amount: values.amount.replaceAll(',', '.')});
           setIsSubmitting(false)
            if (rs.succeeded) {
                dispatch(ShowNotification("Success", "COD added successfully", false))
                setRecentlyAdded([values, ...recentlyAdded])
            }
        } else {
           setShowWarning(true)
        }
    }
    return (
        <fieldset className="b-fieldset p-2 text-primary">

            <legend className="b-legend" style={{width: "150px"}}>
                <h5>COD Management</h5>
            </legend>

            <Formik
                initialValues={initialValues}
                onSubmit = {handleSubmit}
                validationSchema={validationSchema}
            >
                {(formik)=>(   
                    <Form>
                        {showWarning && <WarningModal isShowing={showWarning} shipmentCodNumber={shipmentCodNumber} shipmentCod={shipmentCod} amount={formik.values.amount.replaceAll(',', '.')} onClose={()=>setShowWarning(false)} onSave={async ()=> {
                            setIsSubmitting(true)
                            const rs = await shipClient.addShipmentCOD({...formik.values, onHold: formik.values?.payMode?.toLowerCase() === "check" ? true:false ,amount: formik.values.amount.replaceAll(',', '.')})
                            setIsSubmitting(false)
                            if (rs.succeeded) {
                                dispatch(ShowNotification("Success", "COD added successfully", false))
                                setRecentlyAdded([formik.values, ...recentlyAdded ])
                            }
                            setShowWarning(false)
                            clearForm(formik)

                        }}/>}
                        <CContainer className="form-group mb-0">
                                <CRow>
                                    <CCol className="col-sm-3">
                                        <Field type="text" name="shipmentNumber" onBlur={(e:any)=> {
                                            shipClient.getShipment(e.target.value).then((res:any)=> {
                                                setShipmentCodNumber(res.cod)
                                                setShipmentCod(res.shipmentCod)
                                            })
                                        }} label={"Shipment Number"} component={InputField}/>
                                    </CCol>
                                    <CCol className="col-sm-3">
                                        <Field type="text" name="amount" label={"Amount"} component={InputField}/>
                                    </CCol>
                                    <CCol className="col-sm-3">
                                        <Field label="Date" type="text" name="date" component={DateInputField}/>
                                    </CCol>
                                    <CCol className="col-sm-3">
                                        <Field
                                            as ={'select'}
                                            name="payMode"
                                            label={'Pay Mode'}
                                            component={SelectField}
                                            options = {[
                                                {
                                                    label: "Check",
                                                    value: "Check",
                                                    id: "Check"
                                                },
                                                {
                                                    label: "Cash",
                                                    value: "Cash",
                                                    id: "Chas"
                                                },
                                                {
                                                    label: "Credit Note",
                                                    value: "CN",
                                                    id: "Credit Node"
                                                },
                                                {
                                                    label: "COD Canceled",
                                                    value: "COD Canceled",
                                                    id: "COD Canceled"
                                                },
                                                {
                                                    label: "Transfer",
                                                    value: "Transfer",
                                                    id: "Transfer"
                                                },
                                                {
                                                    label: "Advanced",
                                                    value: "Advanced",
                                                    id: "Advanced"
                                                },
                                                {
                                                    label: "Compensation",
                                                    value: "Compensation",
                                                    id: "Compensation"
                                                },
                                                {
                                                    label: "Online COD",
                                                    value: "Online COD",
                                                    id: "Online COD"
                                                }
                                            ]}
                                        />
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol className="col-sm-3">
                                        <Field label="Check Number" type="text" name="checkNumber" component={InputField}/>
                                    </CCol>
                                    <CCol className="col-sm-3">
                                        <Field label="Sender Bank" type="text" name="senderBank" component={InputField}/>
                                    </CCol>
                                </CRow>
                        </CContainer>
                        <CCol className="text-right">
                            <CButtonGroup className="mb-0 mr-2">
                                <CButton
                                    variant="outline"
                                    size="sm"
                                    color="danger"
                                    onClick={()=>clearForm(formik)}
                                    style={{fontSize: "10px"}}
                                >
                                    Clear
                                </CButton>
                            </CButtonGroup>
                            <CButtonGroup className="mb-0 ">
                                <ActionButton
                                    text={"Add"}
                                />
                            </CButtonGroup>
                        </CCol>
                    </Form>
                )}
            </Formik>
            {recentlyAdded.length > 0 && <div className={"p-3"}>
                <h5 className={""}>Recently Added</h5>
                <table className="position-relative table table-hover table-sm">
                    <thead>{renderHeader(["Shipment Number", "Amount", "Date", "Pay Mode"])}</thead>
                    <tbody>
                    {(recentlyAdded)?.slice(0, 5)?.map((item: any, index:number) => (
                        <tr key={item?.shipmentNumber+"-"+index}>
                            <td>{item?.shipmentNumber || ""}
                            </td>
                            <td>
                                {item?.amount || ""}
                            </td>
                            <td>
                                {new Date(item.date).toLocaleString()}
                            </td>

                            <td>{item?.payMode || ""}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>}
        </fieldset>
    )
}

export default  AddCODForm