import { CModal, CModalHeader, CModalTitle, CModalBody, CTooltip, CImg, CButton, CModalFooter, CCol } from '@coreui/react';
import React, { useState } from 'react';
import Webcam from 'react-webcam';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCircleDot } from '@fortawesome/free-solid-svg-icons';
import ShipClient from '../../clients/shipClient';
import { RemoteBlob } from '../../models/app';
import WarehousesClient from '../../clients/warehousesClient';

const WebcamComponent = () => <Webcam />

interface IProps {
    onClose: (arg?: RemoteBlob[]) => void
    onSave?: (arg: RemoteBlob[]) => void
    children?: JSX.Element
    allowOneCaptureOnly?: boolean
    skusUploadData?:{account:string}
    transactionUploadData?:{inventoryTransactionCode:string}
    isSaveDisabled?:boolean
}
const CaptureImage = ({ onClose,  onSave, children, allowOneCaptureOnly,skusUploadData, transactionUploadData,isSaveDisabled }: IProps) => {
    const shipClient = new ShipClient();
    const warehouseClient = new WarehousesClient();
    const [pictures, setPictures] = useState<RemoteBlob[]>([])
    const webcamRef = React.useRef(null) as any

    const capture = React.useCallback(async () => {
        const imageSrc = webcamRef.current?.getScreenshot();
        if (imageSrc) {
            const blob = dataURItoBlob(imageSrc);
            const file = new File([blob], 'image.jpg', { type: blob.type });
            let result;
            if (Boolean(transactionUploadData)) {
                result = await warehouseClient.UploadTransactionAttachment(file, transactionUploadData?.inventoryTransactionCode!);
            } else if (Boolean(skusUploadData)) {
                result = await warehouseClient.UploadSkuImage(file, skusUploadData?.account!);
            } else {
                result = await shipClient.Upload(file);
            }
            setPictures([...pictures || [], result])
        }
    }, [pictures])

    const dataURItoBlob = (dataURI: string) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    };

    const handleRemove = (index: number) => {
        const newPictures = pictures.filter((_, i) => i !== index);
        setPictures(newPictures);
    };

    const allowedToCapture = !allowOneCaptureOnly || (allowOneCaptureOnly && pictures?.length === 0)

    return (
        <CModal size={"lg"} show={true} onClose={() => onClose(pictures)}>
            <CModalHeader closeButton>
                <CModalTitle>{"Capture"}</CModalTitle>
            </CModalHeader>
            <CModalBody className={"d-flex flex-column align-items-center"}>
                <div style={{ borderRadius: '10px', width: 700, height: 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Webcam
                        style={{ borderRadius: '10px' }}
                        audio={false}
                        height={500}
                        ref={webcamRef}
                        width={700}
                        screenshotFormat="image/jpeg"
                        videoConstraints={{
                            facingMode: 'environment',
                            width: { min: 640, ideal: 3840, max: 3840 },
                            height: { min: 480, ideal: 2160, max: 2160 },
                        }}
                    />
                </div>
                <div style={{ alignSelf: "center", textAlign: "center", marginTop: 20 }}>
                    <CTooltip content={"Capture"}>
                        <FontAwesomeIcon
                            tabIndex={-1}
                            icon={faCircleDot}
                            onClick={(e: any) => {
                                e.preventDefault()
                                if (allowedToCapture) {
                                    capture();
                                }
                            }}
                            color={allowedToCapture ? "#00007d" : "lightGray"}
                            style={{ cursor: allowedToCapture ? "pointer" : "none", width: 40, height: 40 }}
                        />
                    </CTooltip>
                </div>

                {pictures?.length > 0 && (
                    <div
                        style={{
                            marginTop: 8,
                            overflowX: 'auto',
                            borderRadius: '10px',
                            width: '100%',
                            height: 200,
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            columnGap: '1rem',
                            rowGap: '1rem',
                            overflowY: 'hidden',
                            padding: '0 1rem'
                        }}
                    >
                        {pictures?.map((picture, index) => (
                            <div key={index} style={{ position: 'relative', width: 200, flexShrink: 0 }}>
                                <CImg
                                    style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '10px' }}
                                    onClick={() => window.open(picture?.location, '_blank', 'noreferrer')}
                                    src={`${picture?.location}`}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '0.5rem',
                                        right: '0.5rem',
                                        cursor: 'pointer',
                                        color: 'white',
                                        zIndex: 1,
                                    }}
                                    onClick={() => handleRemove(index)}
                                >
                                    <FontAwesomeIcon icon={faTimesCircle} size="2x" />
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                <div style={{ width: "100%" }}>
                    {children}
                </div>
            </CModalBody>
            <CModalFooter>
                {onSave &&
                    <CCol className={"text-right"}>
                        <CButton
                            variant="outline"
                            size="sm"
                            color="primary"
                            onClick={() => onSave?.(pictures)}
                            style={{ fontSize: "10px" }}
                            disabled={isSaveDisabled || pictures?.length == 0}
                        >
                            Save
                        </CButton>
                    </CCol>
                }
            </CModalFooter>
        </CModal>
    );
};
export default CaptureImage