import {CModal, CContainer, CRow, CCol, CModalFooter, CModalBody, CForm, CButton} from "@coreui/react";
import React, {useEffect, useState} from "react";
import SWInput from "../../../SharedComponents/SWInput";
import {useFormik} from "formik";
import SWInputNumber from "../../../SharedComponents/SWInputNumber";
import ActionButton from "../../../SharedComponents/ActionButton";

interface lookup {
    code: string,
    name: string
}

interface IProps {
    isShowing: boolean,
    isEdit: boolean
    isDelete: boolean
    type: string
    onClose: () => void
    warehouse: { code: string, name: string }
    area: { code: string, name: string }
    location: { code: string, issuePriority: number }
    error: string | undefined
    onSubmit: (code: string, name: string, issuePriority: number) => void
}

const WarehouseAddEditModal = (props: IProps) => {
    const [title, setTitle] = useState<string>()
    const [buttonTitle, setButtonTitle] = useState<string>()

    useEffect(() => {
        switch (props.type) {
            case "Warehouse" : {
                setFieldValue("code", props.warehouse.code)
                setFieldValue("name", props.warehouse.name)
            }
                break;
            case "Area" : {
                setFieldValue("code", props.area.code)
                setFieldValue("name", props.area.name)
            }
                break;
            case "Location" : {
                setFieldValue("code", props.location.code)
            }
        }
        if (props.isEdit) {
            setTitle("Edit " + props.type)
            setButtonTitle("Update")
        } else if (props.isDelete) {
            setTitle("Delete " + props.type)
            setButtonTitle("Delete")
        } else {
            setTitle("Add " + props.type)
            setButtonTitle("Create")
            setFieldValue("code", '')
            setFieldValue("name", "")
            setFieldValue("issuePriority", 0)
        }
    }, [props])

    const {values, handleChange, handleSubmit, setFieldValue} = useFormik({
        initialValues: {
            name: "",
            code: "",
            issuePriority: 0
        },
        onSubmit: async (values) => props.onSubmit(values.code, values.name, values.issuePriority)
    })
    return (
        <CModal show={props.isShowing} onClose={props.onClose}>
            <CContainer>
                <CForm onSubmit={handleSubmit}>
                    <CModalBody className="text-primary">
                        <h5>{title}</h5>
                        <CRow>
                            <CCol md={6}>
                                <SWInput readonly={props.isEdit || props.isDelete} label={"Code"} name={"code"}
                                         value={values.code}
                                         onChangeFormik={handleChange}/>
                            </CCol>
                            {props.type != "Location" && <CCol md={6}>
                                <SWInput readonly={props.isDelete} label={"Name"} name={"name"} value={values.name}
                                         onChangeFormik={handleChange}/>
                            </CCol>}
                            {props.type == "Location" && <CCol>
                                <SWInputNumber readonly={props.isDelete} label={"Priority"} name={"issuePriority"}
                                               value={values.issuePriority}
                                               onChange={n => setFieldValue("issuePriority", n)}/>
                            </CCol>}
                        </CRow>
                        {props.isDelete ?
                            <p color={"danger"}>"Are you sure you want to delete this {props.type}"</p> : null}
                    </CModalBody>
                    <CModalFooter>
                        <CCol>
                            <CButton variant={"outline"} color={"danger"} onClick={props.onClose}>Cancel</CButton>
                        </CCol>
                        <CCol className="text-right">

                            <ActionButton
                                text={`${buttonTitle}`}
                            />
                        </CCol>
                    </CModalFooter>
                </CForm>
            </CContainer>
        </CModal>
    )
}

export default WarehouseAddEditModal;