import {freeSet} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {CCardBody, CCol, CRow, CLink, CButton} from "@coreui/react";
import React, {useState} from "react";
import {CodStatus, ShipmentSearch} from "../../../models/shipment";
import {formatDate} from "../../../utils/dateUtil";
import {ClearButton} from "react-bootstrap-typeahead";

interface IProps {
    shipment: ShipmentSearch;
    onEditClick: () => any;
    getCodDetails: () => void
    volumetricWeight: number
    calculatedWeight: number
}

const ViewShipment = (props: IProps) => {
    const {shipment} = props;
    const [isHover, setisHover] = useState(false);

    const sum = (arr: number[]) => {
        let result = 0, n = arr.length || 0; //may use >>> 0 to ensure length is Uint32
        while (n--) {
            result += +arr[n]; // unary operator to ensure ToNumber conversion
        }
        if (result == 0)
            return 0
        return result;
    }

    let cods = shipment.shipmentCod?.map(c => c.amount)
    let totalCollectedCod = cods && cods.length > 0 ? sum(cods) : null

    return (
        <>
            <CCardBody
                className={` shipmentCard mb-1 rounded  ${
                    isHover ? "bg-secondary" : ""
                }`}
                onMouseOver={() => {
                    setisHover(true);
                }}
                onMouseLeave={() => {
                    setisHover(false);
                }}
            >
                <CRow>
                    <CCol>
                        <h6>
                            <strong>Shipment Data:</strong>
                        </h6>
                    </CCol>
                    <CCol className="text-right">
                        <CIcon
                            style={{cursor: "pointer"}}
                            className={`${isHover ? "primary" : "text-white"}`}
                            content={freeSet.cilPencil}
                            size={"lg"}
                            onClick={props.onEditClick}
                        />
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>Weight:</CCol>
                    <CCol sm={8}>
                        <strong>
                            {shipment.weight} {shipment.weightUnit}
                        </strong>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>Volumetric Weight:</CCol>
                    <CCol sm={8}>
                        <strong>
                            {props.volumetricWeight} {shipment.weightUnit}
                        </strong>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>Calculated Weight:</CCol>
                    <CCol sm={8}>
                        <strong>
                            {props.calculatedWeight} {shipment.weightUnit}
                        </strong>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>Value:</CCol>
                    <CCol sm={8}>
                        <strong>
                            {shipment.value} {shipment.valueCurrency}
                        </strong>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>COD:</CCol>
                    <CCol sm={totalCollectedCod != null ? 4 : 8}>
                        <strong>
                            {shipment.cod} {shipment.codCurrency}
                        </strong>
                    </CCol>
                    {totalCollectedCod != null &&
                        <CCol>
                            <CLink onClick={props.getCodDetails}>
                                Collected : {totalCollectedCod} {shipment.codCurrency}
                            </CLink>
                        </CCol>}
                </CRow>
                <CRow>
                    <CCol>Online COD:</CCol>
                    <CCol sm={8}>
                        <strong>
                            {shipment.onlineCOD ?? 0} {shipment.codCurrency}
                        </strong>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>Customs Shipping Cost:</CCol>
                    <CCol sm={8}>
                        <strong>
                            {shipment.customsShippingCost ?? 0} {shipment.customsShippingCostCurrency}
                        </strong>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>Pickup Date:</CCol>
                    <CCol sm={8}>
                        <strong>{formatDate(shipment.pickupDate)}
                        </strong>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>Incoterm:</CCol>
                    <CCol sm={8}>
                        <strong>
                            {shipment?.incoterm}
                        </strong>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>IOSS:</CCol>
                    <CCol sm={8}>
                        <strong>
                            {shipment?.ioss}
                        </strong>
                    </CCol>
                </CRow>


            </CCardBody>
        </>
    );
};

export default ViewShipment;
