import {CDataTable, CModal, CModalBody, CModalHeader} from "@coreui/react";
import {ErrorDescription} from "../../../../models/shipment";

interface IProps {
    account?: string
    handleClose: () => void
    errors: ErrorDescription[]
}

const ImportErrorTable = (props: IProps) => {
    const {account, errors, handleClose} = props
    return (
        <CModal show={true} size={"lg"} onClose={() => handleClose()} centered>
            <CModalHeader className={"text-primary"}>Error importing excel for account: {account}</CModalHeader>
            <CModalBody>
                <CDataTable
                    items={errors}
                    fields={[{key:"rowNumber", label: "Row Number", _style: {width:"20%"}}, "errorDescription"]}
                />
            </CModalBody>
        </CModal>
    )
}

export default ImportErrorTable
