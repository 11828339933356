import {CButton, CCol, CModal, CModalBody, CModalFooter, CModalHeader, CRow} from "@coreui/react";
import SWInput from "../../../SharedComponents/SWInput";
import {BulkPayRequest, InvoiceDto, InvoiceStatus, MarkInvoicePaidRequest} from "../../../../models/accounting";
import {useState} from "react";
import SWInputNumber from "../../../SharedComponents/SWInputNumber";
import SWDateAndTimePicker from "../../../SharedComponents/SWDateAndTimePicker";

interface IProps {
    invoiceIds: number[]
    onClose: () => void
    onSubmit: (body: BulkPayRequest) => void
    error?: string
}

const BulkPayInvoiceModal = (props: IProps) => {
    const [paymentRequest, setPaymentRequest] = useState<BulkPayRequest>({
        externalPaymentReference: "",
        paidOn: new Date,
        amount: undefined,
        InvoiceIds: props.invoiceIds
    })
    return (
        <CModal show={true} onClose={props.onClose} centered>
            <CModalHeader>Mark invoices: {props.invoiceIds.length} as paid</CModalHeader>
            <CModalBody>
                <h6>Are you sure you want to mark these invoices as paid?</h6>
                <br/>
                <CRow>
                    <CCol>
                        <SWInputNumber label={"Amount"} value={paymentRequest.amount}
                                       onChange={v => setPaymentRequest({...paymentRequest, amount: v})}/>
                    </CCol>
                    <CCol>
                        <SWInput value={paymentRequest.externalPaymentReference} label={"External Reference"}
                                 onChange={(v => setPaymentRequest({...paymentRequest, externalPaymentReference: v}))}/>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>
                        <SWDateAndTimePicker value={paymentRequest.paidOn}
                                             handleOnChange={v => setPaymentRequest({...paymentRequest, paidOn: v})}/>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol><div className={"text-danger"}><strong>{props.error}</strong></div></CCol>
                </CRow>
            </CModalBody>
            <CModalFooter>
                <CCol>
                    <CButton
                        variant={"outline"}
                        color={"danger"}
                        size={"sm"}
                        onClick={props.onClose}
                    >Cancel</CButton>

                </CCol>
                <CCol className={"text-right"}>
                    <CButton
                        variant={"outline"}
                        color={"primary"}
                        size={"sm"}
                        onClick={() => props.onSubmit(paymentRequest)}
                    >Submit</CButton>
                </CCol>
            </CModalFooter>
        </CModal>
    )
}

export default BulkPayInvoiceModal