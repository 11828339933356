import {
    CCol,
    CButton,
    CModal,
    CButtonGroup,
    CContainer,
    CModalBody,
    CModalHeader,
    CCardBody,
    CRow,
    CInput,
    CForm,
    CLabel, CModalFooter, CSpinner, CInputCheckbox,
} from "@coreui/react";
import React, {useEffect, useMemo, useState} from "react";
import {
    BookingUpdate,
    ShipmentSearch,
} from "../../../../models/shipment";
import SWSelect from "../../../SharedComponents/SWSelect";
import SWInput from "../../../SharedComponents/SWInput";
import SWDateAndTimePicker from "../../../SharedComponents/SWDateAndTimePicker";
import ActionButton from "../../../SharedComponents/ActionButton";
import AgentTable from "../ShipmentTablesComponents/AgentTable";
import AuthClient from "../../../../clients/authClient";
import ShipClient from "../../../../clients/shipClient";

interface IProps {
    title: string;
    mode: string;
    shipment: ShipmentSearch;
    onSave: () => void;
    onBookingUpdate: (booking: BookingUpdate) => void
    onClose: () => void;
    isShowing: boolean;
    onChange: (shipment: ShipmentSearch) => any;
    costumers: { [key: string]: string };
    countries: { [key: string]: string };
    agentLookups: { [key: string]: string }
    onBlur: () => any;
    isDisabled: boolean;
    initialTotalValue: number | null;
    agentServices?: { [k: string]: string },
    getAgentServices?: (agent: string) => void
    onGenerateLabel: (data: {
        booking: BookingUpdate,
        labelData: { labelSize: string, pickupDate: string | null }
    }) => void
    isLoading?: boolean
    allowedUsers: { [p: string]: string }

}

const ShipmentDetailsEditModal = (props: IProps) => {
    const {
        shipment,
        onClose,
        isShowing,
        onChange,
        onSave,
        onBookingUpdate,
        countries,
        onBlur,
        isDisabled,
        agentLookups,
        costumers,
        agentServices,
        getAgentServices,
        onGenerateLabel,
    } = props;

    const [booking, setBooking] = useState<BookingUpdate>({})
    const [assignedProfile, setAssignedProfile] = useState<string>(shipment?.assignedTo);

    const handleChange = (e: any) => {
        const value = e.target.value;
        onChange({
            ...shipment,
            [e.target.name]: value,
        });
    };
    const onGenerate = async (data: {
        booking: BookingUpdate,
        labelData: { labelSize: string, pickupDate: string | null }
    }) => {
        onGenerateLabel(data)
    }

    const onSaveHandlerService = async (data: BookingUpdate) => {
        onBookingUpdate(data)
    }

    const onSaveAssignedTo = async () => {
        const shipClient = new ShipClient();
        let res;
        if (assignedProfile == 'myself') {
            res = await shipClient.assignToMe(shipment.uid)
        } else if (assignedProfile == 'nobody') {
            res = await shipClient.removeAssignTo(shipment.uid)

        } else {
            res = await shipClient.assignToOthers(shipment.uid, assignedProfile);
        }

        if (res) {
            window.location.reload()
        }
    }

    const ViewAgentTable = useMemo(() => {
        if (!shipment.number && props.mode == 'service')
            return <AgentTable data={shipment} onSaveHandler={onSaveHandlerService} onGenerateHandler={onGenerate}/>
        else return undefined
    }, [shipment, props.mode])

    useEffect(() => {
        if (shipment.bookings && shipment.bookings.length > 0)
            setBooking({
                service: shipment.bookings[0].service,
                agent: shipment.bookings[0].agent,
                status: shipment.bookings[0].status.toString()
            })
    }, [shipment.bookings]);


    useEffect(() => {
        if (shipment.bookings && shipment.bookings.length > 0)
            setBooking({
                service: shipment.bookings[0].service,
                agent: shipment.bookings[0].agent,
                status: shipment.bookings[0].status.toString()
            })
    }, [shipment.bookings]);

    const handleSelectChange = (name: string, val: string | undefined) => {
        onChange({
            ...shipment,
            [name]: val,
        });
    };

    const handleAgentChange = (val?: string) => {
        setBooking({...booking, agent: val, service: ""})
        if (Boolean(val)) {
            getAgentServices?.(val!)
        }
    }

    return (
        <>
            <CModal
                disabled={isDisabled}
                className="ml-auto mr-auto"
                size={(props.mode == 'reference' || props.mode == 'account') ? undefined : 'lg'}
                show={isShowing}
                centered={true}
                onClose={() => onClose()}
            >
                <CForm onChange={handleChange} className={'mt-2'}>
                    <CContainer>
                        {props.mode == "reference" && (
                            <>

                                <CModalHeader closeButton>
                                    <CRow>

                                        <h6>

                                            <strong>Edit Reference:</strong>
                                        </h6>
                                    </CRow>
                                </CModalHeader>


                                <CCardBody className="editReferenceDetailsCard ml-3 mb-3">
                                    <CCol>
                                        <CLabel>Reference:</CLabel>
                                        <CInput
                                            style={{fontSize: "10px", height: "26px"}}
                                            value={shipment.accountReference}
                                            name="accountReference"
                                        />
                                    </CCol>
                                </CCardBody>
                            </>

                        )}
                        {props.mode == "account" && (
                            <>

                                <CModalHeader closeButton>
                                    <CRow>

                                        <h6>
                                            <strong>Edit Account:</strong>
                                        </h6>
                                    </CRow>
                                </CModalHeader>


                                <CCardBody>
                                    <CCol>
                                        <SWSelect label={"Account"} values={costumers} value={shipment.account}
                                                  name={"account"}
                                                  onChange={val => handleSelectChange("account", val)}/>
                                    </CCol>
                                </CCardBody>
                            </>

                        )}
                        {props.mode == "shipper" && (
                            <>


                                <CModalHeader closeButton>
                                    <CRow>

                                        <h6>
                                            <strong>Edit Shipper:</strong>
                                        </h6>
                                    </CRow>

                                </CModalHeader>


                                <CCardBody>
                                    <CRow>
                                        <CCol>
                                            <CLabel>Company Name:</CLabel>
                                            <CInput
                                                style={{fontSize: "10px", height: "26px"}}
                                                value={shipment.shipperCompanyName}
                                                name="shipperCompanyName"
                                            />
                                        </CCol>
                                        <CCol>
                                            <CLabel className="ml-0 position-relative mr-2">
                                                Contact Name:
                                            </CLabel>
                                            <CInput
                                                style={{fontSize: "10px", height: "26px"}}
                                                name="shipperName"
                                                value={shipment.shipperName}
                                            />
                                        </CCol>
                                        <CCol>
                                            <CLabel>Email:</CLabel>
                                            <CInput
                                                style={{fontSize: "10px", height: "26px"}}
                                                value={shipment.shipperEmail}
                                                name="shipperEmail"
                                            />
                                        </CCol>
                                        <CCol>
                                            <CLabel>Telephone:</CLabel>
                                            <CInput
                                                style={{fontSize: "10px", height: "26px"}}
                                                value={shipment.shipperPhone}
                                                name="shipperPhone"
                                            />
                                        </CCol>

                                    </CRow>

                                    <CRow className="mb-4 mt-4">
                                        {/* <CCol>
                        <CLabel>shipperAddressLine3:</CLabel>
                        <CInput
                        style={{ fontSize: "10px", height: "26px" }}
                          value={shipment.shipperAddressLine3}
                          name="shipperAddressLine3"
                        ></CInput>
                      </CCol>  */}
                                    </CRow>
                                    <CRow className="mb-4 mt-4">
                                        <CCol>
                                            <CLabel>Street:</CLabel>
                                            <CInput
                                                style={{fontSize: "10px", height: "26px"}}
                                                value={shipment.shipperAddressLine1}
                                                name="shipperAddressLine1"
                                            />
                                        </CCol>
                                        <CCol>
                                            <CLabel>Additional Address:</CLabel>
                                            <CInput
                                                style={{fontSize: "10px", height: "26px"}}
                                                value={shipment.shipperAddressLine2}
                                                name="shipperAddressLine2"
                                            />
                                        </CCol>
                                        <CCol className="col-sm-3">
                                            <CLabel> Shipper Eori:</CLabel>
                                            <CInput
                                                style={{fontSize: "10px", height: "26px"}}
                                                value={shipment.shipperEori}
                                                name="shipperEori"
                                            />
                                        </CCol>
                                    </CRow>
                                    <CRow className="mb-4 mt-4">
                                        <CCol>
                                            {/* <CLabel>Country:</CLabel>
                        <CSelect
                          style={{ fontSize: "10px", height: "26px" }}
                          value={shipment.shipperCountry}
                          name="shipperCountry"
                        ></CSelect> */}
                                            <SWSelect
                                                values={countries}
                                                label="Country"
                                                onChange={(val) =>
                                                    handleSelectChange("shipperCountry", val)
                                                }
                                                value={shipment.shipperCountry}
                                            />
                                        </CCol>

                                        <CCol>
                                            <CLabel>Zip Code</CLabel>
                                            <CInput
                                                style={{fontSize: "10px", height: "26px"}}
                                                value={shipment.shipperPostCode}
                                                name="shipperPostCode"
                                            />
                                        </CCol>

                                        <CCol>
                                            <CLabel>City:</CLabel>
                                            <CInput
                                                style={{fontSize: "10px", height: "26px"}}
                                                value={shipment.shipperCity}
                                                name="shipperCity"
                                            />
                                        </CCol>
                                    </CRow>
                                    <CRow className="mb-4 mt-4">
                                        <CCol>
                                            <CLabel>Reference:</CLabel>
                                            <CInput
                                                style={{fontSize: "10px", height: "26px"}}
                                                value={shipment.shipperReference}
                                                name="shipperReference"
                                            />
                                        </CCol>
                                        <CCol>
                                            <CLabel>Address Reference:</CLabel>
                                            <CInput
                                                style={{fontSize: "10px", height: "26px"}}
                                                value={shipment.shipperAddressReference}
                                                name="shipperAddressReference"
                                            />
                                        </CCol>
                                    </CRow>
                                    <CRow className="mb-4 mt-4">
                                        <CCol>
                                            <CLabel>Notes</CLabel>
                                            <CInput
                                                style={{fontSize: "10px"}}
                                                value={shipment.shipperNotes}
                                                name="shipperNotes"
                                            />
                                        </CCol>
                                    </CRow>
                                </CCardBody>
                            </>
                        )}
                        {props.mode == "reciever" && (
                            <>


                                <CModalHeader closeButton>
                                    <CRow>

                                        <h6>
                                            <strong>Edit Reciever:</strong>
                                        </h6>
                                    </CRow>
                                </CModalHeader>


                                <CCardBody>
                                    <CRow>
                                        <CCol>
                                            <CLabel>Company Name:</CLabel>
                                            <CInput
                                                style={{fontSize: "10px", height: "26px"}}
                                                value={shipment.consigneeCompanyName}
                                                name="consigneeCompanyName"
                                            />
                                        </CCol>
                                        <CCol>
                                            <CLabel className="ml-0 position-relative mr-2">
                                                Contact Name:
                                            </CLabel>
                                            <CInput
                                                style={{fontSize: "10px", height: "26px"}}
                                                name="consigneeName"
                                                value={shipment.consigneeName}
                                            />
                                        </CCol>
                                        <CCol>
                                            <CLabel>Email:</CLabel>
                                            <CInput
                                                style={{fontSize: "10px", height: "26px"}}
                                                value={shipment.consigneeEmail}
                                                name="consigneeEmail"
                                            />
                                        </CCol>
                                        <CCol>
                                            <CLabel>Telephone:</CLabel>
                                            <CInput
                                                style={{fontSize: "10px", height: "26px"}}
                                                value={shipment.consigneePhone}
                                                name="consigneePhone"
                                            />
                                        </CCol>

                                    </CRow>

                                    <CRow className="mb-4 mt-4">
                                        <CCol>
                                            <CLabel>Street:</CLabel>
                                            <CInput
                                                style={{fontSize: "10px", height: "26px"}}
                                                value={shipment.consigneeAddressLine1}
                                                name="consigneeAddressLine1"
                                            />
                                        </CCol>
                                        <CCol>
                                            <CLabel>Additional Address:</CLabel>
                                            <CInput
                                                style={{fontSize: "10px", height: "26px"}}
                                                value={shipment.consigneeAddressLine2}
                                                name="consigneeAddressLine2"
                                            />
                                        </CCol>
                                        <CCol className="col-sm-3">
                                            <CLabel> Consignee Eori:</CLabel>
                                            <CInput
                                                style={{fontSize: "10px", height: "26px"}}
                                                value={shipment.consigneeEori}
                                                name="consigneeEori"
                                            />
                                        </CCol>
                                    </CRow>
                                    <CRow className="mb-4 mt-4">
                                        <CCol>
                                            <SWSelect
                                                values={countries}
                                                label="Country"
                                                onChange={(val) =>
                                                    handleSelectChange("consigneeCountry", val)
                                                }
                                                value={shipment.consigneeCountry}
                                            />
                                        </CCol>

                                        <CCol>
                                            <CLabel>Zip Code</CLabel>
                                            <CInput
                                                style={{fontSize: "10px", height: "26px"}}
                                                value={shipment.consigneePostCode}
                                                name="consigneePostCode"
                                            />
                                        </CCol>

                                        <CCol>
                                            <CLabel>City:</CLabel>
                                            <CInput
                                                style={{fontSize: "10px", height: "26px"}}
                                                value={shipment.consigneeCity}
                                                name="consigneeCity"
                                            />
                                        </CCol>
                                    </CRow>
                                    <CRow className="mb-4 mt-4">
                                        <CCol>
                                            <CLabel>Reference:</CLabel>
                                            <CInput
                                                style={{fontSize: "10px", height: "26px"}}
                                                value={shipment.consigneeReference}
                                                name="consigneeReference"
                                            />
                                        </CCol>
                                        <CCol>
                                            <CLabel>Address Reference:</CLabel>
                                            <CInput
                                                style={{fontSize: "10px", height: "26px"}}
                                                value={shipment.consigneeAddressReference}
                                                name="consigneeAddressReference"
                                            />
                                        </CCol>
                                    </CRow>
                                    <CRow className="mb-4 mt-4">
                                        <CCol>
                                            <CLabel>Notes</CLabel>
                                            <CInput
                                                style={{fontSize: "10px"}}
                                                value={shipment.consigneeNotes}
                                                name="consigneeNotes"
                                            />
                                        </CCol>
                                    </CRow>
                                </CCardBody>
                            </>
                        )}
                        {props.mode == "shipment" && (
                            <>

                                <CModalHeader closeButton>
                                    <CRow>
                                        <h6>
                                            <strong>Edit Shipment Data:</strong>
                                        </h6>
                                    </CRow>

                                </CModalHeader>


                                <CCardBody>

                                    <CRow className="mb-4 mt-4">
                                        <CCol className="col-sm-6">
                                            <CLabel>Weight:</CLabel>
                                            <CInput
                                                disabled
                                                style={{fontSize: "10px", height: "26px"}}
                                                value={shipment.weight || ""}
                                                name="weight"
                                            />
                                        </CCol>
                                        <CCol className="col-sm-6">
                                            <CLabel>Weight Unit:</CLabel>
                                            <CInput
                                                style={{fontSize: "10px", height: "26px"}}
                                                value={shipment.weightUnit}
                                                name="weightUnit"
                                            />
                                        </CCol>
                                    </CRow>
                                    <CRow className="mb-4 mt-4">
                                        <CCol className="col-sm-6">
                                            <CLabel>Value:</CLabel>
                                            <CInput
                                                onBlur={onBlur}
                                                style={{fontSize: "10px", height: "26px"}}
                                                value={shipment?.value || ""}
                                                name="value"
                                            />
                                        </CCol>
                                        <CCol className="col-sm-6">
                                            <CLabel>Currency Value:</CLabel>
                                            <CInput
                                                style={{fontSize: "10px", height: "26px"}}
                                                value={shipment?.valueCurrency}
                                                name="valueCurrency"
                                            />
                                        </CCol>
                                    </CRow>
                                    <CRow className="mb-4 mt-4">
                                        <CCol className="col-sm-12">
                                            <CLabel>Pickup Date:</CLabel>
                                            <SWDateAndTimePicker
                                                value={shipment.pickupDate}
                                                handleOnChange={v => handleSelectChange("pickupDate", v)}
                                                name={"pickupDate"}
                                            />
                                        </CCol>
                                    </CRow>
                                    <CRow className="mb-4 mt-4">
                                        <CCol className="col-sm-3">
                                            <CLabel>COD:</CLabel>
                                            <CInput
                                                style={{fontSize: "10px", height: "26px"}}
                                                value={shipment?.cod || ""}
                                                name="cod"
                                            />
                                        </CCol>
                                        <CCol className="col-sm-3">
                                            <CLabel>Online COD:</CLabel>
                                            <CInput
                                                style={{fontSize: "10px", height: "26px"}}
                                                value={shipment?.onlineCOD || ""}
                                                name="onlineCOD"
                                            />
                                        </CCol>

                                        <CCol className="col-sm-6">
                                            <CLabel>COD Currency:</CLabel>
                                            <CInput
                                                style={{fontSize: "10px", height: "26px"}}
                                                value={shipment?.codCurrency}
                                                name="codCurrency"
                                            />
                                        </CCol>
                                    </CRow>
                                    <CRow className="mb-4 mt-4">
                                        <CCol className="col-sm-6">
                                            <CLabel>IOSS:</CLabel>
                                            <CInput
                                                style={{fontSize: "10px", height: "26px"}}
                                                value={shipment?.ioss}
                                                name="ioss"
                                            />
                                        </CCol>
                                        <CCol className="col-sm-6">
                                            <CLabel>Incoterm:</CLabel>
                                            <CInput
                                                style={{fontSize: "10px", height: "26px"}}
                                                value={shipment?.incoterm}
                                                name="incoterm"
                                                type="select"
                                            />
                                        </CCol>
                                    </CRow>
                                    <CRow className="mb-4 mt-4">


                                    </CRow>
                                </CCardBody>
                            </>
                        )}
                        {props.mode == "service" && (
                            <>
                                <CModalHeader closeButton>
                                    <CRow className={'align-items-start'}>

                                        <h6>
                                            <strong>Edit Agent:</strong>
                                        </h6>
                                        {props.isLoading && <CSpinner
                                            color="primary"
                                            style={{width: "1.5em", height: "1.5em", marginLeft: '8px'}}
                                        />}
                                    </CRow>

                                </CModalHeader>
                                <CCardBody>
                                    <CRow>
                                        <CCol>
                                            <CLabel>Agent:</CLabel>
                                            <SWSelect
                                                readonly={!!ViewAgentTable}
                                                style={{fontSize: "10px", height: "26px"}}
                                                values={agentLookups} value={booking?.agent}
                                                name="agent" onChange={val => {
                                                handleAgentChange(val)
                                            }}
                                            />
                                        </CCol>
                                        <CCol>
                                            <CLabel>Service:</CLabel>
                                            <SWSelect
                                                readonly={!!ViewAgentTable}
                                                style={{fontSize: "10px", height: "26px"}}
                                                values={Boolean(ViewAgentTable) ? {[booking.service ?? ""]: booking.service ?? ""} : agentServices ?? {}}
                                                value={booking?.service}
                                                name="service"
                                                onChange={val => setBooking({...booking, service: val})}
                                            />
                                        </CCol>
                                    </CRow>
                                    <CRow className="mb-4 mt-4">
                                        {ViewAgentTable && <CCol md={12} className={'p-0 m-0'}>
                                            {ViewAgentTable}
                                        </CCol>}
                                    </CRow>
                                </CCardBody>
                            </>
                        )}
                        {props.mode == "assignedTo" && (
                            <>
                                <CModalHeader closeButton>
                                    <CRow className={'align-items-start'}>

                                        <h6>
                                            <strong>Edit Assigned To:</strong>
                                        </h6>
                                        {props.isLoading && <CSpinner
                                            color="primary"
                                            style={{width: "1.5em", height: "1.5em", marginLeft: '8px'}}
                                        />}
                                    </CRow>

                                </CModalHeader>
                                <CCardBody>
                                    <CRow>
                                        <CCol>
                                            <CLabel>Assigned To:</CLabel>
                                            <SWSelect
                                                readonly={false}
                                                style={{fontSize: "10px", height: "26px"}}
                                                values={props.allowedUsers}
                                                value={assignedProfile}
                                                name="assignedTo"
                                                onChange={(val => {
                                                    setAssignedProfile(val ? val : assignedProfile)
                                                })}
                                            />
                                        </CCol>

                                    </CRow>
                                    <CRow className="mb-4 mt-4">
                                        {ViewAgentTable && <CCol md={12} className={'p-0 m-0'}>
                                            {ViewAgentTable}
                                        </CCol>}
                                    </CRow>
                                </CCardBody>
                            </>
                        )}
                    </CContainer>
                    {Boolean(ViewAgentTable) || <CModalFooter>
                        <CCol className="text-right">
                            <ActionButton
                                text={"Save"}
                                extraClass={"bg-primary text-white ml-1 mr-1"}
                                disabled={isDisabled}
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    props.mode == "service" ? onBookingUpdate(booking!) : props.mode == 'assignedTo' ? onSaveAssignedTo() : onSave()

                                }}
                            />

                        </CCol>
                    </CModalFooter>}
                </CForm>
            </CModal>
        </>
    );
};

export default ShipmentDetailsEditModal;
