import React, { useEffect, useState } from "react";
import { CCol, CModal, CModalBody, CModalFooter, CModalHeader, CRow} from "@coreui/react";
import ActionButton from "../../../SharedComponents/ActionButton";
import SWSelect from "../../../SharedComponents/SWSelect";
import ShipClient from "../../../../clients/shipClient";
import SWInput from "../../../SharedComponents/SWInput";

interface Props {
    onSubmit: (fileName?:string,type?:string) => void
    onClose: () => void
}

const AttachmentModal = (props: Props) => {
    const shipClient = new ShipClient();
    const { onSubmit, onClose } = props;
    const [fileName, setFileName] = useState<string | undefined>("");
    const [type, setType] = useState<string | undefined>("");
    const [attachmentTypes, setAttachmentTypes] = useState<{ [k: string]: string }>({})

    useEffect(() => {
        shipClient.getAttachmentTypeLookups("shipment").then(v => setAttachmentTypes(v))
    }, [])

    return (
        <CModal
            show={true}
            centered
            onClose={onClose}
        >
            <CModalHeader>
                <h5>Update Attachment</h5>
            </CModalHeader>
            <CModalBody>
                <CRow>
                <CCol md={12}>
                        <SWSelect
                            label={"Type"}
                            name={`type`}
                            value={type}
                            onChange={(e) => setType(e)}
                            values={attachmentTypes}
                        />
                    </CCol>
                    <CCol md={12}>
                        <SWInput
                            label={"File Name"}
                            name={`fileName`}
                            value={fileName}
                            onChange={(e) => setFileName(e)}
                            type={"text"}
                        />
                    </CCol>
                  

                </CRow>
            </CModalBody>
            <CModalFooter>
                <CRow className="justify-content-between w-100 mt-2">
                    <CCol className={"text-right"}>
                        <ActionButton
                            text={"Save"}
                            disabled={!Boolean(fileName) && !Boolean(type)}
                            onClick={() => {
                                onSubmit(fileName,type)
                            }}
                        />
                    </CCol>
                </CRow>
            </CModalFooter>
        </CModal >
    )
}

export default AttachmentModal