import {CCol, CCollapse, CDataTable, CInputCheckbox, CLabel, CRow, CSpinner} from "@coreui/react";
import {DimensionUnit, RateQuery, RateQueryResult, RateQueryResultCharge, WeightUnit} from "../../../../models/retes";
import React, {useEffect, useState} from "react";
import {
    BookingCreateRequest, BookingUpdate, createShipmentInitialValues, ShipmentCreateResult,
    ShipmentFlags,
    ShipmentSearch,
} from "../../../../models/shipment";
import ChargesDetailsTable from "../../ShipmentCreate/components/chargesDetailsTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import RatesClient from "../../../../clients/ratesClient";
import SWInput from "../../../SharedComponents/SWInput";
import SWDateAndTimePicker from "../../../SharedComponents/SWDateAndTimePicker";
import LabelModal from "../../ShipmentCreate/components/labelModal";
import { ShowNotification} from "../../../../store/actions/auth";
import {setCreateShipmentData} from "../../../../store/actions/shipments";
import {useHistory} from "react-router";
import {useDispatch} from "react-redux";
import ShipClient from "../../../../clients/shipClient";
import {SetIsLoading} from "../../../../store/actions/ui";
import GenerateLabelAgentModal from "../modals/generateLabelAgentModal";

type Props={
    data?: ShipmentSearch
    onSaveHandler:(data:BookingUpdate)=>void
    onGenerateHandler:(data:{booking:BookingUpdate,labelData:{labelSize:string, pickupDate:string|null}})=>void


}
const AgentTable=({data,onSaveHandler,onGenerateHandler}:Props)=>{
    const [isLoading,setIsLoad]=useState(false)
    const ratesClient = new RatesClient();
    const shipClient = new ShipClient();
    const history = useHistory();
    const dispatch = useDispatch();
    const [clickedRow, setClickedRow] = useState<RateQueryResult | null>(null);
    const [creationModal, setCreationModal] = useState<{ isShown: boolean, data: RateQueryResult } | null>(null)
    const [costPopoverDetails, setCostPopoverDetails] = useState<{ service: string, agent: string } | null>(null)
    const [sellingPopoverDetails, setSellingPopoverDetails] = useState<{ service: string, agent: string } | null>(null)
    const [details, setDetails] = useState<{ service: string, agent: string }[]>([])
    const [detailsData, setDetailsData] = useState<{ [key: string]: any[] }>({});
    const [selling, setSelling] = useState<RateQueryResult[]>([]);
    const [cost, setCost] = useState<RateQueryResult[]>([]);
    const [search, setSearch] = useState<string>("")
    const [printModal, setPrintModal] = useState<{ isShown: boolean, data: ShipmentCreateResult } | null>(null)
   const [labelSizeError,setLabelSizeError]=useState(false)

    const [agentReq, setAgentReq] = useState({
        hasIdCheck:data?.flags?.includes("IdCheck"),
        isPickup: data?.flags?.includes("Pickup"),
        pickupDate: data?.pickupDate,
        hasWeekend1: data?.flags?.includes("Weekend1"),
        hasInsurance:data?.flags?.includes("Insurance"),
    });
    useEffect(() => {
        setIsLoad(true)
        fetchData().then(()=> setIsLoad(false))
    }, [agentReq]);

    const fetchData = async () => {
        await getCost()
        await getSelling()

    }
    const navigateToNewShipment = (uId: string) => {
        dispatch(setCreateShipmentData(createShipmentInitialValues({
            account: data?.account, shipper: {}, consignee: {},
            weight: {
                value: 0.1,
                unit: 1
            },
            cOD: {
                currency: "EUR",
                amount: 0,
            },
            value: {
                currency: "EUR",
                amount: 0,
            },
        })))
        history.push({
            pathname: '/shipments/create',
            state: { uId: uId, duplicate: false }
        });

    }
    const freeShipmentCreation = async (body: BookingCreateRequest) => {
        dispatch(SetIsLoading(true));
        let res = (await shipClient.Create({ ...body, generateLabel: false }))
        if (res?.succeeded) {
            navigateToNewShipment(res?.data?.uid)
        } else {
            dispatch(ShowNotification("Error", res?.error, true));
        }
    }

    const fullShipmentCreation = async (body: BookingCreateRequest, labelSize: string) => {
        dispatch(SetIsLoading(true));
        let res = (await shipClient.Create({ ...body, generateLabel: true, size: labelSize }))
        if (res?.succeeded) {
            setPrintModal({ isShown: true, data: res?.data })
        } else {
            dispatch(ShowNotification("Error", res?.data?.SWException?.[0], true));
        }
    }


    const calculateFlagsSum = () => {
        let sum = 0;
        if (agentReq.isPickup) sum += ShipmentFlags.Pickup;
        if (!Boolean(data?.consigneeCompanyName)) sum += ShipmentFlags.Residential;
        if (agentReq.hasIdCheck) sum += ShipmentFlags.IdCheck;
        if (agentReq.hasWeekend1) sum += ShipmentFlags.Weekend1;
        if (agentReq.hasInsurance) sum += ShipmentFlags.Insurance;
        return sum;
    };

    const getSelling = async () => {
        if (data?.account) {
            let res = (await ratesClient.Calculate({ ...mapGetRatesPayload, customer: data?.account }))
            if (res?.succeeded) {
                setSelling(res?.data)
            }
        }
    }
    function isDimensionUnit(value: any): value is DimensionUnit {
        return Object.values(DimensionUnit).includes(value);
    }
    function isWeightUnit(value: any): value is WeightUnit {
        return Object.values(WeightUnit).includes(value);
    }

    const mapGetRatesPayload: RateQuery = {
        residential: !Boolean(data?.consigneeCompanyName),
        weekend1: agentReq.hasWeekend1,
        insurance: agentReq.hasInsurance,
        idCheck: agentReq.hasIdCheck,
        pickupDate: agentReq.pickupDate,
        pickup: agentReq.isPickup ?? false,
        dimensionUnit: isDimensionUnit(data?.dimensionUnit) ? data?.dimensionUnit : undefined,
        weightUnit: isWeightUnit( data?.weightUnit) ?  data?.weightUnit : undefined,
        from: {
            country: data?.consigneeCountry ?? "",
            city: data?.shipperCity ?? "",
            street: data?.shipperAddressLine1 ? [data?.shipperAddressLine1] : [],
            postCode: data?.shipperPostCode ?? "",
            state: data?.shipperState ?? "",
        },
        to: {
            country: data?.consigneeCountry ?? "",
            city: data?.consigneeCity ?? "",
            street: data?.consigneeAddressLine1! ? [data?.consigneeAddressLine1!] : [],
            postCode: data?.consigneePostCode ?? "",
            state: data?.consigneeState ?? "",
        },
        dutiable: [...data?.items || []]?.some((item) => item.dutiable),
        cOD: Boolean((data?.cOD) && (data?.cOD != 0)),
        valueOfGoods: data?.value ?? 0,
        pieces: data?.pieces.map((e) => ({
            weight: e?.weight ?? 0,
            length: e?.length == 0 ? null : e?.length ?? null,
            width: e?.width == 0 ? null : e?.width ?? null,
            height: e?.height == 0 ? null : e?.height ?? null,
        }))
    }
    const getCost = async () => {
        let res = (await ratesClient.Calculate({ ...mapGetRatesPayload }))
        if (res?.succeeded) {
            setCost(res?.data)
        }
    }
    const handleRowClick = (e: RateQueryResult) => {
        setCreationModal({isShown:true,data:e})
            setClickedRow(e);

    };

    const handleSaveModal= async ()=>{
         onSaveHandler({
            service:clickedRow?.service,
            flags:calculateFlagsSum(),
            agent:clickedRow?.agent,
        })
        setCreationModal(null)


    }

    const scopedSlots = {
        agent: (e: RateQueryResult) => {

            return <td className={clickedRow === e ? "table-active" : ""}>
                {e.agentName ?? "-"}
            </td>
        },
        service: (e: RateQueryResult) => {
            return <td className={clickedRow === e ? "table-active" : ""}>
                {e.serviceDescription ?? e.service ?? "-"}
            </td>
        },
        chargeWeight: (e: RateQueryResult) => {
            return <td className={clickedRow === e ? "table-active" : ""}>
                {e.chargeWeight?.toFixed(2) ?? "-"}
            </td>
        },

        cost: (e: RateQueryResult) => {
            return <td className={` ${clickedRow === e ? "table-active" : ""} text-right`}>
                {calculateCharge(e, "cost")}
                {((costPopoverDetails?.service == e?.service) && (costPopoverDetails?.agent == e?.agent)) &&
                    popover(e?.charges)}
            </td>
        },

        selling: (e: RateQueryResult) => {
            {/* // match the agent and service from selling to get the charges */ }
            const matched = selling?.length > 0 && selling?.find((item) => item?.agent === e?.agent && item?.service === e?.service);
            if (matched) {
                return <td className={`${clickedRow === e ? "table-active" : ""} text-right`}>
                    {calculateCharge(matched, "selling")}
                    {((sellingPopoverDetails?.service == matched?.service) && (sellingPopoverDetails?.agent == matched?.agent)) &&
                        popover(matched?.charges, true, matched?.chargeWeight)}
                </td>
            } else {
                return <td className={`${clickedRow === e ? "table-active" : ""} text-right`}>-</td>
            }
        },
        // for quick pricing
        "details":
            (item: RateQueryResult) => {
                return (
                    <CCollapse show={details?.some(detail => detail.service === item.service && detail.agent === item.agent)}>
                        <ChargesDetailsTable
                            charges={detailsData[`${item.service}-${item.agent}`] ?? []}
                            isSelling={false}
                            bgColor={"secondary"}
                            chargeableWeight={item?.chargeWeight}
                        />
                    </CCollapse>
                )
            }
    };

    const calculateCharge = (e: RateQueryResult, type: "selling" | "cost") => {
        const { charges } = e
        let total: number = charges?.reduce((acc: number, charge: RateQueryResultCharge) => {
            if (!!charge?.amount) {
                return acc + Number(charge?.amount);
            } else {
                return acc;
            }
        }, 0) ?? 0;
        if (total) {
            return (
                <>
                    {total?.toFixed(2)}
                    {infoIcon(e, type)}
                </>
            )

        } else {
            return <>-</>
        }
    };
    const popover = (charges: RateQueryResultCharge[], isSelling?: boolean, chargeableWeight?: number | null) => {
        return (
            <div
                style={{
                    backgroundColor: "white", border: "solid 1px", borderColor: "#FF8800", zIndex: 100,
                    position: "absolute",
                    right: "1rem",
                    paddingLeft: "0.5rem",
                    paddingRight: "0.5rem",
                    textAlign: "left"
                }}
            >
                <ChargesDetailsTable charges={charges} isSelling={isSelling} chargeableWeight={chargeableWeight} />
            </div>
        )
    }

    const infoIcon = (e: RateQueryResult, type: "cost" | "selling") => {
        return (
            <FontAwesomeIcon
                icon={ faInfoCircle}
                size="lg"
                color={"#FF8800"}
                style={{ cursor: "pointer", marginLeft: 3 }}
                // quick pricing >> show details table
                // create shipment >> show popover
                onMouseOver={ (_event) => {
                    type == "cost" ? setCostPopoverDetails({ service: e?.service, agent: e?.agent })
                        : setSellingPopoverDetails({ service: e?.service, agent: e?.agent })
                }}
                onMouseOut={ (_event) => {
                    type == "cost" ? setCostPopoverDetails(null) :
                        setSellingPopoverDetails(null)
                }}
                onClick={(event) => {
                    event.stopPropagation()

                }}
            />
        )
    }
    const filterTableData = (search: string) => {
        let filteredData = [...cost || []] || [];
        if (Boolean(search)) {
            filteredData = filteredData.filter((item) =>
                item?.agentName?.toLowerCase()?.includes(search?.toLowerCase()) || item?.serviceDescription?.toLowerCase()?.includes(search?.toLowerCase())
            );
        }
        return filteredData;
    };



    const generateLabel = async (req:{labelSize:string|undefined,pickupDate:string|null}) => {
        console.log(req.pickupDate)
        if(req.labelSize) {
            setLabelSizeError(false)
            setCreationModal(null)
            onGenerateHandler({
                booking: {
                    service: clickedRow?.service,
                    flags: calculateFlagsSum(),
                    agent: clickedRow?.agent,
                }, labelData: {labelSize: req.labelSize, pickupDate: req.pickupDate}
            })
        }

        else setLabelSizeError(true)

    }

    const getTableFields = () => {
        const baseFields = [
            { key: "agent", label: "Agent" },
            { key: "service", label: "Service" },
            { key: "chargeWeight", label: "Chargeable Weight (Kg)" },
        ];

            return [...baseFields,
                { key: "cost", label: "Cost", _style: { textAlign: "end", paddingRight: "1rem" } },
                { key: "selling", label: "Selling", _style: { textAlign: "end", paddingRight: "1rem" } },]
        }



    return <CCol>
        <CCol md={12}>
            <CRow>
                <CCol>
                    <CInputCheckbox
                        name={"weekendOne"}
                        checked={agentReq.hasWeekend1}
                        onChange={(e) => setAgentReq({...agentReq,hasWeekend1:!agentReq.hasWeekend1})}
                    />
                    <CLabel className={"mt-1"}>Saturday</CLabel>
                </CCol>

                <CCol>
                    <CInputCheckbox
                        name={"idCheck"}
                        checked={agentReq.hasIdCheck}
                        onChange={(e) =>setAgentReq({...agentReq,hasIdCheck:!agentReq.hasIdCheck})}

                    />
                    <CLabel className={"mt-1"}>Id Check</CLabel>
                </CCol>
            </CRow>

            <CRow>
                <CCol md={6}>
                    <CInputCheckbox
                        name={"insurance"}
                        checked={agentReq.hasInsurance}
                        onChange={(e) => setAgentReq({...agentReq,hasInsurance:!agentReq.hasInsurance})}
                    />
                    <CLabel className={"mt-1"}>Insurance</CLabel>
                </CCol>
                    <CCol md={6}>
                    <CRow>

                <CCol md={5} >
                    <CInputCheckbox
                        name={"pickup"}
                        checked={agentReq.isPickup}
                        onChange={(e) => {
                            if (agentReq.isPickup) {
                                setAgentReq({...agentReq,pickupDate:undefined})
                            }
                            setAgentReq({...agentReq,isPickup:!agentReq.isPickup})
                        }}
                    />
                    <CLabel className={"mt-1"}>Pickup</CLabel>

                </CCol>

                    <CCol md={7} style={{height:'35px'}}>
                        {agentReq.isPickup &&
                            <SWDateAndTimePicker
                                showTimeInput
                                label={""}
                                name={"pickupDate"}
                                value={agentReq.pickupDate ? new Date(agentReq.pickupDate) : undefined}
                                handleOnChange={(e) => {
                                    setAgentReq({...agentReq,pickupDate:e})
                                }}
                            />}
                    </CCol>
            </CRow>
                    </CCol>


            </CRow>


        </CCol>

        <CRow className={"mt-2"}>
            <CCol>
                <SWInput
                    label={"Search"}
                    name={`search`}
                    value={search ?? ""}
                    onChange={(e) => setSearch(e)}
                    type={"text"}
                />
            </CCol>
            <CCol />
        </CRow>

        <>
            <CCol  style={{maxHeight:'300px',overflow:'scroll' ,width:'100%' ,padding:'0px',margin:"0px"}}>

            {isLoading ? <CSpinner
                    className="mx-auto d-block my-5"
                    color="primary"
                    style={{ width: "5em", height: "5em" }}
                /> :
                <CDataTable
                    onRowClick={(e: RateQueryResult) => handleRowClick(e)}
                    size="sm"
                    hover
                    scopedSlots={scopedSlots}
                    items={ filterTableData(search)}
                    fields={getTableFields()}
                    {...( { clickableRows: true } )}
                />}
            </CCol>

        </>
        { creationModal?.isShown && <GenerateLabelAgentModal
            dataUid={data?.uid??''}
              onClose={() => {
                setCreationModal(null)
                setClickedRow(null)
                  setLabelSizeError(false)
              }
        }
             onSave={handleSaveModal}
             onCreate={(data)=>generateLabel(data)}
              errorLabel={labelSizeError}

        />}




        {printModal?.isShown &&
            <LabelModal
                data={printModal?.data}
                navigateToNewShipment={(uId: string) => navigateToNewShipment(uId)} />
        }
    </CCol>
}

export default AgentTable
