import {CButton, CCard, CCol, CLink, CRow, CTooltip} from "@coreui/react";
import React, {useEffect, useState} from "react";

import ShipClient from "../../../clients/shipClient";
import {useHistory, useParams} from "react-router";
import {ShipmentsImportSearch} from "../../../models/shipment";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";


interface Props {
}


const ViewImport = (props: Props) => {
    const shipClient = new ShipClient();
    const {id} = useParams() as any;
    const history = useHistory()

    const [importRecord, setImportRecord] = useState<ShipmentsImportSearch | null>(null)

    useEffect(() => {
        const loadImport = async () => setImportRecord(await shipClient.getShipmentsImportById(id));
        loadImport();
    }, [])

    return (
        <CCard className="bg-white p-4 text-black">
            <CRow>
                <CTooltip content="Back">
                    <CButton>
                        <CIcon
                            content={freeSet.cilArrowLeft}
                            onClick={() => history.goBack()}
                        />
                    </CButton>
                </CTooltip>
            </CRow>
            <CRow>
                <CCol>Account: <strong> {importRecord?.accountName}</strong></CCol>
                <CCol>Created On: <strong>{importRecord?.createdOn}</strong> </CCol>
                <CCol>Created by: <strong>{importRecord?.createdBy}</strong> </CCol>
                <CCol>Finished: <strong>{importRecord?.finished ? "Yes" : "No"}</strong></CCol>
            </CRow>
            <CRow>
                <CCol>Total Rows: <strong>{importRecord?.totalRows}</strong> </CCol>
                <CCol>Rows Imported: <strong>{importRecord?.rowsImported}</strong> </CCol>
                <CCol>Shipments Added: <strong>{importRecord?.shipmentsAdded}</strong> </CCol>
                <CCol>Shipments Updated: <strong>{importRecord?.shipmentsUpdated}</strong> </CCol>
            </CRow>
            <CRow>
                <CCol md={3}><CLink
                    href={importRecord?.fileUrl}
                    target="_blank"
                >
                    Download Original File
                </CLink>
                </CCol>
            </CRow>
        </CCard>
    );
}

export default ViewImport;
