import React from "react";
import { CProgress, CProgressBar } from "@coreui/react";

interface IProps {
    value: number;
}
const ProgressBar = ({ value }: IProps) => {
    const getColor = () => {
        if(value === 100){
            return "success";
        }else if(value === 0){
            return "light";
        }else{
            return "warning";
        }
    };
    const getWidth = () => {
        return value === 0 ? "100%" : `${value}%`;
    };
    const getTextStyle = () => {
        if (value === 0) {
          return { color: "black" };
        } else {
            return {};
        }
      };

    return (
        <CProgress style={{ height: '20px' }}>
        <CProgressBar style={{ height: '100%', borderRadius: 0, width: getWidth() }} color={getColor()} value={value}>
          <span style={getTextStyle()}>{value}%</span>
        </CProgressBar>
      </CProgress>
    );
};

export default ProgressBar;