import {
    CFormGroup,
    CFormText,
    CInput,
    CInputGroup,
    CInputGroupAppend,
    CInputGroupPrepend,
    CInputGroupText,
    CLabel
} from "@coreui/react";
import React from "react";

import CIcon from "@coreui/icons-react";

interface Props {
    style?: React.CSSProperties;
    readonly?: boolean;
    label?: string;
    className?: string;
    labelClassName?: string
    append?: any;
    onChange?: (val: number | undefined, name:string) => void

    onBlur?: (e:any) => any
    icon?: any
    maxLength?: number
    id?: string
    name?: string
    key?: any
    value?: number
    acceptsNegative?: boolean
    valueFile?: File
    validationErrors?: string
    onChangeFormik?:(e:any) => void
}

const SWInputNumber = (props: Props) => {
    const {
        key,
        id,
        name,
        value,
        valueFile,
        label,
        append,
        icon,
        className,
        labelClassName,
        style,
        readonly,
        onChange,
        maxLength,
        onBlur,
        validationErrors,
        acceptsNegative,
        onChangeFormik
    } = props;

    const onChangeCall = (e: React.FormEvent<HTMLInputElement>) => {
        const inputValue = (e as any).target.value as number;
        if(inputValue && !acceptsNegative && inputValue <0)
            return;
        const valueToUpdate = e.currentTarget.value === "" ? undefined : inputValue;

        onChange && onChange(valueToUpdate, (e as any).target.name);
        onChangeFormik && onChangeFormik(e);
    }

    return (
        <div className={className} style={style}>
            <CFormGroup>
                {label && <CLabel className={labelClassName ?? "small"}>{label}</CLabel>}
                <CInputGroup>
                    {icon && (
                        <CInputGroupPrepend>
                            <CInputGroupText >
                                <CIcon content={icon}/>
                            </CInputGroupText>
                        </CInputGroupPrepend>
                    )}
                    <CInput
                        style={{fontSize: "10px", maxHeight:"28px"}}
                        maxLength={maxLength}
                        readOnly={readonly}
                        id={id}
                        onBlur={(e) => onBlur ? onBlur(e) : undefined}
                        name={name}
                        key={key}
                        value={value ?? ""}
                        onChange={onChangeCall}
                        type={"number"}
                    />
                    {append && (
                        <CInputGroupAppend>
                            <CInputGroupText>{append}</CInputGroupText>
                        </CInputGroupAppend>
                    )}
                </CInputGroup>
                {validationErrors ? (
                    <CFormText
                        style={{fontSize: "1em"}}
                        color="danger"
                        className="help-block">
                        {validationErrors}

                    </CFormText>
                ) : (
                    ""
                )}
            </CFormGroup>
        </div>
    );
};

export default SWInputNumber;
