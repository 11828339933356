import {CButton, CCol, CModal, CModalBody, CModalFooter, CModalHeader, CRow} from "@coreui/react";
import React from "react";
import ActionButton from "../../../SharedComponents/ActionButton";

interface Props {
    onClose: () => any
    onSubmit: () => any
    numberOfShipmentsSelected: number
}

const DeleteShipmentsConfirmModal = (props : Props) => {
    return (
        <div className="small text-primary">
            <CModal
                show={true}
                centered
                onClose={props.onClose}
            >
                <CModalHeader>
                    <h6>Delete Shipments</h6>
                </CModalHeader>
                <CModalBody>
                    Are you sure you want to delete these shipments?
                </CModalBody>
                <CModalFooter>
                    <CRow className="justify-content-between w-100">
                        <CCol className="text-left">
                            <CButton
                                style={{fontSize: "10px"}}
                                variant="outline"
                                color="danger"
                                onClick={props.onClose}
                            >
                                Cancel
                            </CButton>
                        </CCol>
                        <CCol className="text-right">
                            <ActionButton
                                text={`Delete selected ${props.numberOfShipmentsSelected} shipments`}
                                onClick={() => props.onSubmit()}
                            />
                        </CCol>
                    </CRow>
                </CModalFooter>
            </CModal>
        </div>

    )
}

export default DeleteShipmentsConfirmModal;