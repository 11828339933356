import {
    CButton,
    CButtonGroup,
    CCol,
    CCollapse,
    CContainer,
    CDataTable,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CForm,
    CFormText,
    CLink,
    CListGroup,
    CListGroupItem,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CNav,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CTabs,
    CTextarea,
    CTooltip
} from "@coreui/react";
import _ from "lodash";
import {Form, Input, Submit} from "../../SharedComponents/SWForm";
import React, {useEffect, useState} from "react";
import {useHistory, useLocation, useParams} from "react-router";
import {useComplexState} from "../../../hooks/helperHooks";
import ShipClient from "../../../clients/shipClient";
import ContainerBasicInfo from "./components/ContainerBasicInfo";
import {ContainerAttachmentSearch, ContainerExportType, ContainerSearch, ContainerShipmentSearch} from "../../../models/container";
import {downloadBlob} from "../../../utils/downloadUtils";
import {AdditionalField, ContainerType} from "../../../models/containertype";
import ContainerIntegration from "./components/ContainerIntegration";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import ConfirmModal from "../../SharedComponents/ConfirmModal";
import {useDispatch} from "react-redux";
import {ShowNotification} from "../../../store/actions/auth";
import RatesClient from "../../../clients/ratesClient";
import ActionButton from "../../SharedComponents/ActionButton";
import SWInput from "../../SharedComponents/SWInput";
import {formatDateTime} from "../../../utils/dateUtil";
import addUpdateContainerAttachmentModal from "./Modals/AddUpdateContainerAttachmentModal";
import AddUpdateContainerAttachmentModal from "./Modals/AddUpdateContainerAttachmentModal";
import {ShipmentAttachment} from "../../../models/shipment";
import CameraIcon from "../../../icons/camera";
import CaptureImageModal from "../../SharedComponents/CaptureImageModal";
import { RemoteBlob } from "../../../models/app";
import SWSelect from "../../SharedComponents/SWSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const ViewContainer = () => {
    const {id} = useParams() as any;
    const [addShipments, setAddShipments] = useState("");
    const [
        container,
        setContainerProp,
        setContainer,
    ] = useComplexState<ContainerSearch>({});
    const [inEditMode, setInEditMode] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [fileLoading, setFileLoading] = useState<boolean>(false)
    const [fileUploaded, setFileUploaded] = useState<boolean>(false)
    const [fileLocation, setFileLocation] = useState<string>()
    const [showAttachmentModal, setShowAttachmentModal] = useState<boolean>(false)
    const [attachmentIndex, setAttachmentIndex] = useState<number>()
    const [deleteAttachmentModal, setDeleteAttachmentModal] = useState<boolean>(false)
    const [containerAttachments, setContainerAttachments] = useState<ContainerAttachmentSearch[]>(container.attachments)
    const [captureModal, setCaptureModal] = useState<boolean>(false);

    const shipClient = new ShipClient();
    const ratesClient = new RatesClient();

    const [agents, setAgents] = useState<{ [k: string]: string }>({})
    const [attachmentTypes, setAttachmentTypes] = useState<{[k: string]: string}>({})
    const [selectedAttachmentType,setSelectedAttachmentType] = useState<string>("Photo");


    const [additionalFields, setAdditionalFields] = useState<AdditionalField[]>(
        []
    );
    const [containerTypes, setContainerTypes] = useState<{ [k: string]: string; }>({});
    const [confirmContainerClose, setConfirmContainerClose] = useState(false);
    const getInputType = (c: number): | "new-date" | "new-datetime" | "text" | "typeahead" => {
        let type: | "new-date" | "new-datetime" | "text" | "typeahead"
        switch (c) {
            case 40:
                type = "new-datetime"
                break
            case 20:
                type = "typeahead"
                break
            case 21:
                type = "typeahead"
                break
            case 0:
                type = "text"
                break
            default:
                type = "text"
        }

        return type
    }

    const getLookupByType = (item: any): any => {
        let result = {}
        switch (item.type) {
            case 40:
                result = {}
                break
            case 20:
                result = item.lookup ?? {}
                break
            case 21:
                result = agents ?? {}
                break
            case 0:
                result = {}
                break
            default:
                result = {}
        }

        return result
    }

    const hist = useHistory()
    const {search} = useLocation()
    const dispatch = useDispatch()

    useEffect(() => {
        getAttachmentTypes()
    }, [])

    const getAttachmentTypes = async () => {
        const res = await shipClient.getAttachmentTypeLookups("container");
        setAttachmentTypes(res)
    };
    useEffect(() => {
        ratesClient.LookupAgents().then(v => setAgents(v))
        const params = new URLSearchParams(search)
        if (params.get("isEdit") == "true")
            setInEditMode(true)
        else setInEditMode(false)
    }, [search])

    useEffect(() => {
        if (container.closedOn != null) {
            setInEditMode(false);
        }
    }, [container.closedOn]);

    // const saveChanges = async () =>
    //     shipClient.UpdateContainer(id, container).then((v) => {
    //         setInEditMode(false);
    //     });

    const removeShipment = (number: string) => {
        setContainerProp(
            "shipments",
            container.shipments.filter((s) => s.number !== number)
        );
    };
    const refresh = async (loadLookups?: boolean) => {
        if (loadLookups) {
            const types = await shipClient.GetContainerTypes(null, true) as { [k: string]: string; }
            setContainerTypes(types);
        }
        const data = await shipClient.GetContainer(id);
        setContainer(data);
        setContainerAttachments(data.attachments)

    }

    useEffect(() => {
        refresh(true);
    }, [id, inEditMode]);

    useEffect(() => {
        shipClient.GetContainerTypes(null).then((v) => {
            const arr = v.result as ContainerType[];
            setAdditionalFields(
                arr.find((i) => i.id === container.type)?.additionalFields ?? []
            );
        });
    }, [container.type]);

    const closeContainer = () => {
        setConfirmContainerClose(false);
        shipClient.CloseContainer(id).then((v) => {
            if (v) {
                shipClient.GetContainer(id).then((v) => {
                    setContainer(v);
                });
            }
        });
    };

    const getManifest = (reportId: number) => {
        shipClient.GetContainerReport(id, {reportId}).then((v) => {
            const location = v && v.location;
            if (location) downloadBlob(location, v.name);
        });
    };

    const handleDelete = async () => {
        const res = await shipClient.deleteContainer(container.id.toString())
        if (res.succeeded) {
            dispatch(ShowNotification("Success", "Container deleted successfully", false))
            hist.push("/containers/search")
        }
    }

    const handleFileImport = async (file?: File) => {
        setFileUploaded(false)
        setFileLoading(true)
        if (!file) return
        let res = await shipClient.UploadFile(file, container.number)
        await setFileLocation(res.location)
        if (res.location) {
            setFileUploaded(true)
            setShowAttachmentModal(true)
            setFileLoading(false)
        }
    }

    const renderHeader = (header: string[]) => header.map((value, index) => {
        return (
            <th className="sticky-top bg-white" key={index}>
                {value}
            </th>
        );
    });

    const handleDownload = (text: string) => {
        const link = document.createElement("a");
        link.href = text;
        link.click();
        link.remove();
    }

    const handleCopy = async (text: string) => {
        const baseUrl = window.location.origin;
        await navigator.clipboard.writeText(baseUrl + text)
        dispatch(ShowNotification("Success", "Url coped to clipboard", false))
    }

    const onUpdateAttachment = (index: number) => {
        setAttachmentIndex(index)
        setShowAttachmentModal(true)
    }

    const showDeleteAttachmentModal = (index: number) => {
        setAttachmentIndex(index)
        setDeleteAttachmentModal(true)
    }

    const handleDeleteAttachment = async () => {
        if (attachmentIndex == undefined)
            return
        let attachments = containerAttachments
        let attachmentId = attachments[attachmentIndex].id
        let newAttachments = attachments.filter((attachment, index) => index != attachmentIndex)
        setAttachmentIndex(undefined)
        const res = await shipClient.DeleteContainerAttachment(attachmentId)
        if (res) {
            dispatch(ShowNotification("Success", "Attachment deleted successfully", false))
            setContainerAttachments(newAttachments)
            setDeleteAttachmentModal(false)
        }
    }

    const handleAddAttachment = async (attachment: ContainerAttachmentSearch) => {
        setAttachmentIndex(undefined)
        const attachments = containerAttachments
        attachments?.push(attachment)
        setShowAttachmentModal(false)
        setContainerAttachments(attachments);
    }

    const handleUpdateAttachment = (attachment: ContainerAttachmentSearch) => {
        if (attachmentIndex == undefined) return
        let attachments = containerAttachments
        attachments[attachmentIndex] = attachment
        setShowAttachmentModal(false)
        setAttachmentIndex(undefined)
        setContainerAttachments(attachments)
    }

    const handleAddBulkAttachments = async (attachments: RemoteBlob[]) => {
        const mappedAttachments = attachments?.map((attachment: RemoteBlob) => ({
            fileName: attachment?.name,
            location: attachment?.location,
            type: selectedAttachmentType,
        }))
        await shipClient.AddContainerBulkAttachment(container.id, mappedAttachments)
        setCaptureModal(false)
        refresh()
    }

    const [error, setError] = useState('')

    const shipmentsScopedSlots = {
        view: (e: ContainerShipmentSearch) => {
            return <td>
                {Boolean(e?.shipmentUid) &&
                <CLink to={`/shipments/${encodeURI(e?.shipmentUid) }`} className="card-header-action">
                    <CTooltip content={"View Shipment"}>
                        <FontAwesomeIcon
                            icon={faEye}
                        />
                    </CTooltip>
                </CLink>}
            </td>
        },
        actions: (e: ContainerShipmentSearch) => {
            return <td>
                {container.closedOn == null &&
                    <CTooltip content={"Remove"}>
                        <CIcon style={{ cursor: 'pointer' }} content={freeSet.cilTrash}
                            onClick={() => removeShipment(e.number ?? "")} />
                    </CTooltip>

                }
            </td>
        },
        accountName: (e: ContainerShipmentSearch) => {
            return <td>
                {e.accountName ?? e?.account ?? "-"}
            </td>
        },
        number: (e: ContainerShipmentSearch) => {
            return <td>
                {e.number ??  "-"}
            </td>
        },
        accountReference: (e: ContainerShipmentSearch) => {
            return <td>
                {e.accountReference ?? "-"}
            </td>
        },
    };

    const copyShipmentNumbers = () => {
       const numbers = container?.shipments?.map((shipment)=>shipment?.number)?.join('\n');
       navigator.clipboard.writeText(numbers);
       dispatch(ShowNotification("success", "Copied!"));
      };

    return (
        <CContainer className="bg-white p-4 text-black">
            <CRow>
                <CTooltip content="Back">
                    <CButton>
                        <CIcon
                            content={freeSet.cilArrowLeft}
                            onClick={() => hist.goBack()}
                        />
                    </CButton>
                </CTooltip>
            </CRow>
            <CRow>
                <CCol md={10}>
                    <h5>Container {container["number"]} </h5>
                </CCol>
                {container?.processing &&
                    <CCol md={2}>
                        <h5> Processing  <CIcon content={freeSet.cilSync}
                        /> </h5>
                    </CCol>}
            </CRow>
            <Form className="small"
                readonly={!inEditMode}
                initialState={container}
                onSubmit={async (state) =>
                    shipClient
                        .UpdateContainer(id, state)
                        .then((_) => setInEditMode(false))
                }
            >
                <CTabs activeTab="basic">
                    <CNav variant="tabs">
                        <CNavLink data-tab="basic">Basic Information</CNavLink>
                        <CNavLink data-tab="shipments">
                            Shipments ({container.shipments?.length}){" "}
                        </CNavLink>
                        <CNavLink data-tab="attachments">Attachments</CNavLink>
                        {container && container.closedOn && container.containerType && container.containerType.integrations && container.containerType.integrations.length > 0 &&
                            <CNavLink data-tab="integrations">
                                Integration History
                            </CNavLink>
                        }
                    </CNav>

                    <CTabContent>
                        <CTabPane className="my-3" data-tab="basic">
                            <ContainerBasicInfo containerTypes={containerTypes}/>
                            {_.chunk(additionalFields, 2).map((v) => {
                                return (
                                    <CRow key={`${v[0].name}|${v[1] && v[1].name}`}>
                                        <CCol>

                                            <Input
                                                field={`additionalData.${v[0].name}`}
                                                type={getInputType(v[0].type)}
                                                label={v[0].name}
                                                key={v[0].name}
                                                clearable={!v[0].required}
                                                lookUp={getLookupByType(v[0])}
                                            />
                                        </CCol>
                                        {v[1] && (
                                            <CCol>
                                                <Input
                                                    field={`additionalData.${v[1].name}`}
                                                    type={getInputType(v[1].type)}
                                                    label={v[1].name}
                                                    key={v[1].name}
                                                    clearable={!v[1].required}
                                                    lookUp={getLookupByType(v[1])}
                                                />
                                            </CCol>
                                        )}
                                    </CRow>
                                );
                            })}
                            {!container.closedOn && (
                                <CButton
                                    style={{fontSize: "10px"}}
                                    variant="outline"
                                    color="primary"
                                    onClick={() => setInEditMode(!inEditMode)}
                                    className="mr-2 small"
                                >
                                    {inEditMode ? "Cancel" : "Edit"}
                                </CButton>
                            )}
                            {!inEditMode && container.shipments?.length === 0 &&
                                <CButton
                                    variant={"outline"}
                                    color={"danger"}
                                    size={"sm"}
                                    onClick={() => setShowDeleteModal(true)}
                                >Delete</CButton>
                            }
                            {inEditMode && <ActionButton
                                text={"Save"}
                            />}
                        </CTabPane>
                        <CTabPane className="my-3" data-tab="shipments">

                            <CCollapse show={!container.closedOn}>
                                <CForm className="my-3">

                                    <CTextarea
                                        value={addShipments}
                                        onChange={(e) => setAddShipments((e.target as any).value)}
                                        invalid={Boolean(error)}
                                    />
                                </CForm>
                                <CRow>
                                    <CCol lg={1}>
                                        <CButton
                                            style={{fontSize: "10px"}}
                                            disabled={!addShipments}
                                            onClick={() => {
                                                shipClient
                                                    .AddShipmentsToContainer(
                                                        id,
                                                        addShipments.split("\n")
                                                    )
                                                    .then((v) => {
                                                        if (v.notFound.length > 0) {
                                                            setAddShipments(v.notFound.join('\n'));
                                                            setError(`${v.notFound.length} Shipments not found ${v.alreadyIn.length > 0 ? `and ${v.alreadyIn.length} Shipments already exists` : ""}`)
                                                        } else {
                                                            setAddShipments("");
                                                            setError('')
                                                        }
                                                        refresh();
                                                    });
                                            }}
                                            variant="outline"
                                            color="primary"
                                            className="mb-2"
                                        >
                                            Add
                                        </CButton>
                                    </CCol>
                                    <CCol><CFormText color={"danger"}>{error}</CFormText></CCol>

                                </CRow>
                            </CCollapse>

                            <CDataTable
                                size="sm"
                                hover
                                columnHeaderSlot={{
                                    number:
                                        <><CTooltip content="Copy Numbers">
                                            <CIcon
                                           tabIndex={-1}
                                            style={{cursor:"pointer",marginRight:3}}
                                                content={freeSet.cilCopy}
                                                onClick={() => copyShipmentNumbers()}
                                            />
                                    </CTooltip>Number</>,
                                }}
                                scopedSlots={shipmentsScopedSlots}
                                items={container?.shipments || []}
                                fields={[
                                    { key: "view", label: "",_style: { width: "1%" } },
                                    { key: "number", label: "Number" },
                                    { key: "accountName", label: "Account Name" },
                                    { key: "accountReference", label: "Reference" },
                                    { key: "actions", label: "" }
                                ]}
                            />
                        </CTabPane>
                        <CTabPane className="my-3" data-tab="attachments">
                            <div className="attachmentsTabTable">
                                {container.number &&
                                    <>
                                        <CCol>
                                            <CRow className={" w-20 mt-3 px-2"}>
                                                {!fileLoading ? <SWInput type={"file"} id={"input-file"}
                                                    onChangeFile={(f) => handleFileImport(f)}/>
                                                    : <div
                                                        className={"rounded mb-3 h-100 m-0 py-1 px-2 d-flex align-items-center justify-content-center border-primary"}>Please
                                                        wait...</div>}

                                                <CameraIcon
                                                    style={{
                                                        marginLeft: 9
                                                    }}
                                                    tabIndex={-1}
                                                    onClick={() => setCaptureModal(true)}
                                                    tooltipText={'Capture'} />
                                            </CRow>
                                        </CCol>
                                    </>

                                }
                                <table className="position-relative table table-hover table-sm">
                                    <thead>{renderHeader(["File Name", "Attachment Type", "Download", "Created By",
                                        "Created On", "Edit"])}
                                    </thead>
                                    <tbody>
                                        {container.attachments?.length < 1 && (
                                            <div className="mr-2 ml-2 mt-2 mb-2">
                                                <span> No Available Attachments</span>
                                            </div>
                                        )}
                                        {containerAttachments?.map((a, i) => (
                                            <tr key={a.location}>
                                                <td>{a.name}</td>
                                                <td>{a.type}</td>
                                                <td>
                                                    <CIcon
                                                        content={freeSet.cilCloudDownload}
                                                        style={{cursor: "pointer", margin: "auto"}}
                                                        onClick={() => handleDownload(`/ship/container/attachment/${a.urlKey}`)}
                                                    />
                                                    <CIcon
                                                        content={freeSet.cilCopy}
                                                        style={{cursor: "pointer", marginLeft: "8px"}}
                                                        onClick={() => handleCopy(`/ship/container/attachment/${a.urlKey}`)}
                                                    />
                                                </td>
                                                <td>{a.by}</td>
                                                <td>{formatDateTime(a.on)}</td>
                                                <td>
                                                    <CIcon
                                                        content={freeSet.cilPencil}
                                                        style={{cursor: "pointer"}}
                                                        onClick={() => onUpdateAttachment(i)}
                                                    />
                                                    <CIcon
                                                        content={freeSet.cilTrash}
                                                        style={{cursor: "pointer", marginLeft: "10px"}}
                                                        onClick={() => showDeleteAttachmentModal(i)}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </CTabPane>
                        {container && container.closedOn && container.containerType && container.containerType.integrations && container.containerType.integrations.length > 0 &&
                            <CTabPane className="my-3" data-tab="integrations">
                                <ContainerIntegration container={container} refresh={refresh}/>
                            </CTabPane>
                        }
                    </CTabContent>
                </CTabs>
                <CRow className="justify-content-around w-100">
                    <CCol>
                        <CButtonGroup>
                            {!inEditMode && !!container.closedOn && (
                                <>
                                    <CDropdown>
                                        <CDropdownToggle color="success" variant={"outline"}>Download</CDropdownToggle>
                                        <CDropdownMenu>
                                            {container.containerType?.containerReports?.map(settings =>
                                                <CDropdownItem key={settings.id}
                                                    onClick={() => getManifest(settings.id)}>{settings.name}</CDropdownItem>
                                            )}
                                        </CDropdownMenu>
                                    </CDropdown>
                                </>
                            )}

                        </CButtonGroup>
                    </CCol>
                    <CCol className="text-right">
                        <CButtonGroup>
                            {!inEditMode && (
                                <>
                                    <ActionButton
                                        text={container.closedOn ? "Finalized" : "Finalize"}
                                        extraClass={"mr-2"}
                                        disabled={
                                            !!container.closedOn || container.shipments?.length === 0
                                        }
                                        color={container.closedOn || container.shipments?.length === 0 ? "secondary" : "danger"}
                                        onClick={() => setConfirmContainerClose(true)}
                                    />
                                </>
                            )}

                        </CButtonGroup>
                    </CCol>
                </CRow>


            </Form>
            <CModal show={confirmContainerClose} centered>
                <CModalHeader>
                    <h6>Are you sure you want to finalise container?</h6>
                </CModalHeader>
                <CModalBody>
                    This action will close the container and notify relevant parties
                </CModalBody>
                <CModalFooter>
                    <CRow className="justify-content-between w-100">
                        <CCol className="text-left">
                            <ActionButton
                                text={"Cancel"}
                                color="danger"
                                onClick={() => setConfirmContainerClose(false)}
                            />
                        </CCol>
                        <CCol className="text-right">
                            <ActionButton
                                text={"Yes"}
                                onClick={() => closeContainer()}
                            />
                        </CCol>
                    </CRow>
                </CModalFooter>
            </CModal>
            {showDeleteModal &&
                <ConfirmModal onClose={() => setShowDeleteModal(false)} onSubmit={handleDelete}
                    title={"Delete Container"} body={"Are you sure you want to delete this container?"}/>
            }

            {showAttachmentModal &&
                <AddUpdateContainerAttachmentModal
                    containerId={id}
                    handleClose={() => {
                        setAttachmentIndex(undefined)
                        setFileLoading(false)
                        setFileUploaded(false)
                        setFileLocation(undefined)
                        setShowAttachmentModal(false)
                    }} handleAdd={a => handleAddAttachment(a)}
                    handleUpdate={a => handleUpdateAttachment(a)}
                    attachments={container.attachments}
                    attachmentIndex={attachmentIndex}
                    fileUploaded={fileUploaded} location={fileLocation} />
            }

            {deleteAttachmentModal &&
                <ConfirmModal onClose={() => setDeleteAttachmentModal(false)} onSubmit={() => handleDeleteAttachment()}
                    title={"Delete Attachment"} body={"Are you sure you want to delete this attachment"}/>}
            {captureModal &&
                <CaptureImageModal
                    onClose={() => {
                        setCaptureModal(false)
                    }}
                    onSave={(e) => {
                        handleAddBulkAttachments(e)
                    }}
                    children={
                        <CRow>
                            <CCol md={5}>
                                <SWSelect label={"Type"} value={selectedAttachmentType} values={attachmentTypes}
                                    onChange={(e) => setSelectedAttachmentType(e!)} />
                            </CCol>
                        </CRow>
                    }
                />}
        </CContainer>
    );
};

export default ViewContainer;
