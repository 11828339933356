import { ContainerType } from "../../models/containertype";
import { Filter, FilterGroup } from "../../models/filter";
import {
  SET_CONTAINERTYPE_FILTER_GROUP,
  SET_CONTAINERTYPE_IS_LOADING,
  SET_CONTAINERTYPE_RESULT,
} from "../types";

export const SetContainerTypePage = (page: number) => {
  return {
    type: SET_CONTAINERTYPE_FILTER_GROUP,
    payload: {
      page: page,
    },
  };
};

export const SetContainerTypeIsLoading = (loading: boolean) => {
  return {
    type: SET_CONTAINERTYPE_IS_LOADING,
    payload: {
      isLoading: loading,
    },
  };
};

export const SetContainerTypesFilterGroupFilters = (filters: Filter[]) => {
  return {
    type: SET_CONTAINERTYPE_FILTER_GROUP,
    payload: {
      page: 0,
      filters: filters,
    },
  };
};

export const SetContainerTypesFilterGroup = (filterGroup: FilterGroup) => {
  return {
    type: SET_CONTAINERTYPE_FILTER_GROUP,
    payload: filterGroup,
  };
};

export const SetContainerTypeResult = (
  page: number,
  count: number,
  result: ContainerType[],
  error?: string
) => {
  return {
    type: SET_CONTAINERTYPE_RESULT,
    payload: {
      count: count,
      result: result,
      error: error,
    },
  };
};

export const setContainerTypeSearchLimit = (limit: number) => {
  return {
    type: SET_CONTAINERTYPE_RESULT,
    payload: {
      limit: limit
    }
  }
}
