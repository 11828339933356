export interface SearchyRequest {
    conditions: SearchyCondition[]
    sorts?: SearchySort[]
    pageSize?: number
    pageIndex?: number
    countRows?: boolean
    searchPhrase?: string
    format?: number
}

export interface SearchyCondition {
    filters: SearchyFilter[]
}

export interface SearchyFilter {
    field: string
    rule: SearchyRule
    valueString?: string
    valueStringArray?: string[]
    valueDecimal?: number
    valueDecimalArray?: number[]
    value?: any
    valueDateTime?: string
    valueDateTimeArray?: string[]
}

export enum SearchyRule {
    EqualsTo = 1,
    NotEqualsTo = 2,
    StartsWith = 3,
    Contains = 4,
    GreaterThan = 5,
    GreaterThanOrEquals = 6,
    LessThan = 7,
    LessThanOrEquals = 8,
    EqualsToList = 9,
    Range = 21,
}

interface SearchySort {
    field: string
    sort: SearchySortOrder
}

enum SearchySortOrder {
    ASC = 1,
    DEC = 2,
}

export enum DataType {
    string,
    number,
    date,
    boolean,
    stringArray,
    numberArray,
    DateArray
}

export const convertFilterType = (filter: string, type: DataType, isRange: boolean = false) => {
    if (type == DataType.string) {
        if (isRange)
            return filter.split("\n")
        return filter
    }
    if (type == DataType.number) {
        if (isRange) {
            const strArry = filter.split("\n")
            return strArry.map(s => Number(s))
        }
        return Number(filter)
    }
    if (type == DataType.date) {
        if (isRange) {
            const dates = filter.replace("date|", "")
            return dates.split("|").map(d => d)
        }
        return filter
    }
    if (type == DataType.boolean) {
        return filter == "true"
    }
    return undefined
}