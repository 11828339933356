import React, {useEffect, useState} from "react";
import {
    CContainer,
    CRow,
    CCol,
    CModal,
    CModalBody,
    CModalHeader,
    CModalFooter,
    CButton,
    CInputCheckbox, CLabel
} from "@coreui/react";
import {chargeCalculationTypeOptions, Surcharge} from "../../../../../models/retes";
import SWInput from "../../../../SharedComponents/SWInput";
import SWSelect from "../../../../SharedComponents/SWSelect";
import {useFormik} from "formik";
import {number, object, string} from "yup";
import LookupsClient from "../../../../../clients/lookupsClient";

type Props = {
    valuesToUpdate?: Surcharge;
    onClose: () => void
    onAdd?: (arg: Surcharge) => void;
    onUpdate?: (arg: Surcharge) => void;
    isUpdate?: boolean
}

const SurchargesModal = ({valuesToUpdate, onClose, onAdd, onUpdate, isUpdate}: Props) => {
    const lookups = new LookupsClient()
    const [surchargeLookups, setSurchargeLookups] = useState<{ [k: string]: string }>({})
    const initialValues: Surcharge = {
        type: valuesToUpdate?.type ?? "",
        qualifier: valuesToUpdate?.qualifier ?? "",
        minimumPrice: valuesToUpdate?.minimumPrice ?? 0,
        price: valuesToUpdate?.price ?? 0,
        order: valuesToUpdate?.order ?? 0,
        calculationType: valuesToUpdate?.calculationType ?? "",
        allowDuplicate: valuesToUpdate?.allowDuplicate ?? false,
        groupingCode:valuesToUpdate?.groupingCode ?? "",
    }
    const validationSchema = () => object().shape({
        type: string().required("Required"),
        minimumPrice: number().required("Required"),
        price: number().required("Required"),
        order: number().required("Required"),
        calculationType: string().required("Required"),
    })
    const handleSubmit = async (values: Surcharge, {resetForm}: any) => {
        isUpdate ? onUpdate?.(values) : onAdd?.(values)
        resetForm()
        onClose()
    }
    const formik = useFormik<any>({
        initialValues: initialValues,
        onSubmit: handleSubmit,
        validationSchema: validationSchema
    })
    const {values, setFieldValue, touched, errors, submitForm} = formik

    useEffect(() => {
        getSurcharges()
    }, []);

    const getSurcharges = async () => {
        let surcharges = await lookups.getSurcharges()
        setSurchargeLookups(surcharges)
    }

    return (
        <CModal
            closeOnBackdrop={false}
            show={true}
            size={"lg"}
            className="small text-primary"
            centered
            onClose={() => onClose()}
        >
            <CModalHeader closeButton>
                <h5>Surcharges</h5>
            </CModalHeader>
            <CModalBody>
                <CContainer className="form-group mb-0 mt-2">
                    <CRow>
                        <CCol >
                            <SWSelect
                                label="Surcharge"
                                name="type"
                                value={values?.type ?? ""}
                                onChange={(e) => {
                                    setFieldValue("type", e)
                                }}
                                values={surchargeLookups}
                                validationErrors={touched?.type && errors?.type?.toString() || ""}
                            />

                        </CCol>
                        <CCol>
                            <SWSelect
                                label="Calculation Type"
                                name="calculationType"
                                value={values?.calculationType ?? ""}
                                onChange={(e) => {
                                    setFieldValue("calculationType", e)
                                }}
                                values={chargeCalculationTypeOptions}
                                validationErrors={touched?.calculationType && errors?.calculationType?.toString() || ""}
                            />
                        </CCol>
                        <CCol >
                            <SWInput
                                label="Order"
                                name="order"
                                value={values?.order ?? ""}
                                onChange={(e) => {
                                    setFieldValue("order", e)
                                }}
                                validationErrors={touched?.order && errors?.order?.toString() || ""}
                            />
                        </CCol>

                    </CRow>
                    <CRow>
                        <CCol >
                            <SWInput
                                label="Qualifier"
                                name="qualifier"
                                value={values?.qualifier ?? ""}
                                onChange={(e) => {
                                    setFieldValue("qualifier", e)
                                }}
                            />
                        </CCol>
                        <CCol>
                            <SWInput
                                type="number"
                                label="Price"
                                name="price"
                                value={values?.price ?? ""}
                                onChange={(e) => {
                                    setFieldValue("price", e)
                                }}
                                validationErrors={touched?.price && errors?.price?.toString() || ""}
                            />
                        </CCol>
                        <CCol>
                            <SWInput
                                type="number"
                                label="Minimum Price"
                                name="minimumPrice"
                                value={values?.minimumPrice ?? ""}
                                onChange={(e) => {
                                    setFieldValue("minimumPrice", e)
                                }}
                                validationErrors={touched?.minimumPrice && errors?.minimumPrice?.toString() || ""}
                            />
                        </CCol>

                    </CRow>
                    <CRow className={'d-flex align-items-center justify-content-between'}>
                        <CCol md={4}>
                        <SWInput
                            type="text"
                            label="Grouping code"
                            name="groupingCode"
                            value={values?.groupingCode}
                            onChange={(e) => {
                                setFieldValue("groupingCode", e)
                            }}

                        />
                    </CCol>
                        <CRow rows={1} className={'pr-4'}>
                            <CInputCheckbox
                                checked={values.allowDuplicate}
                                onChange={() => {
                                    setFieldValue("allowDuplicate", !values.allowDuplicate)

                                }}
                            /> <CLabel style={{marginTop:'3px'}}>Allow Duplicate</CLabel>
                        </CRow>
                    </CRow>
                </CContainer>
            </CModalBody>
            <CModalFooter>
                <CButton
                    size="sm"
                    style={{fontSize: "10px"}}
                    className="mr-2"
                    color="primary"
                    onClick={() => submitForm()}
                >
                    Save
                </CButton>
            </CModalFooter>
        </CModal>
)
}

export default SurchargesModal;
