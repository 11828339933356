import { freeSet } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CLink, CTooltip } from "@coreui/react";
import React from "react";

interface Props {
  readonly: boolean;
  setCreateMode: (createMode: boolean) => void;
}
const ActiveAddItem = (props: Props) => {
  const { readonly, setCreateMode } = props;

  return (
    <div className="small">
      <p className="mb-0">
        <CTooltip content="Add item">
          <CLink
            className={readonly ? "text-secondary" : "text-primary"}
            disabled={readonly}
            onClick={() => setCreateMode(true)}
          >
            <CIcon
              className="ml-3 mt-1"
              style={{
                height: "1.5em",
                width: "1.5em",
              }}
              content={freeSet.cilPlus}
            />
          </CLink>
        </CTooltip>
      </p>
    </div>
  );
};

export default ActiveAddItem;
