import ClientWithErrorNotifier from "./clientWithErrorNotifier";
import {
    AgentModel,
    AgentTraceMap,
    CreateAgentTraceMapModel,
    UpdateAgentModel,
    UpdateAgentTraceMapModel
} from "../models/agents";
import {GetShipmentLogs} from "../models/getShipmentLogs";
import {ShipmentGet} from "../models/shipment";

class GatewayClient {
    apiClient: ClientWithErrorNotifier;
    BaseUrl: string;
    pageSize: number;

    constructor() {
        this.BaseUrl = `${process.env.REACT_APP_API_BASE_URL}/gateway/api`;
        //this.BaseUrl = `http://localhost:5000/gateway/api`;
        // this.BaseUrl = `https://traxis.app/gateway/api`;
        // this.BaseUrl = `https://localhost:14182/gateway/api`;

        this.pageSize = 20;
        this.apiClient = new ClientWithErrorNotifier(this.BaseUrl);
    }

    async SearchAgents(id?: string): Promise<AgentModel[]> {
        const rs = await this.apiClient.getAsync(`agents${id ? "?agentId=" + id : ""}`)
        return rs.data;
    }

    async UpdateAgent(id: string, req: UpdateAgentModel): Promise<number> {
        const result = await this.apiClient.postAsync(`/agents/${id}`, req);
        return result.data;
    }

    async SearchTraceMaps(id?: string): Promise<AgentTraceMap[]> {
        const rs = await this.apiClient.getAsync(`tracemaps${id ? "?Id=" + id : ""}`)
        return rs.data;
    }

    async CreateTraceMap(id: string, req: AgentTraceMap): Promise<number> {
        const result = await this.apiClient.postAsync(`/tracemaps/${id}`, req);
        return result.data;
    }

    async UpdateTraceMap(id: string, req: AgentTraceMap): Promise<number> {
        const result = await this.apiClient.postAsync(`/tracemaps/${id}`, req);
        return result.data;
    }

    async getShipmentLogs(uid: string): Promise<GetShipmentLogs[]> {
        const rs = await this.apiClient.getAsync(`/shipments/${uid}/GetCreateShipmentLogs`);
        return rs.data;
    }

    async getTrackLogs(uid: string): Promise<GetShipmentLogs[]> {
        const rs = await this.apiClient.getAsync(`/shipments/${uid}/GetTrackShipmentLogs`);
        return rs.data;
    }

    async getDeleteLogs(uid: string): Promise<GetShipmentLogs[]> {
        const rs = await this.apiClient.getAsync(`/shipments/${uid}/GetDeleteShipmentLogs`);
        return rs.data;
    }
}

export default GatewayClient;
