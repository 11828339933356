import {freeSet} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CButtonGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputCheckbox,
  CInputGroup,
  CLabel,
  CModal,
  CModalBody,
  CModalHeader,
  CRow,
} from "@coreui/react";

import React, {useState} from "react";
import {useParams} from "react-router";

import CheckoutClient from "../../../clients/checkoutClient";
import {UpdateAttachment} from "../../../models/quotation";
import ActionButton from "../../SharedComponents/ActionButton";

interface IProps {
  attachments: UpdateAttachment[];
  onAttachmentsChanged: () => void;
}

function QuotationAttachments(props: IProps) {
  const { attachments, onAttachmentsChanged } = props;
  const checkoutClient = new CheckoutClient();
  const { id } = useParams() as any;
  const [attachment, setAttachment] = useState<UpdateAttachment>({
    url: "",
    id: "",
    name: "",
    includeInInvoiceEmail: false,
    includeInPaymentConfirmedEmail: false,
    includeInRequestPaymentEmail: false,
  });

  const [showModal, setShowModal] = useState<boolean>(false);
  const [inEditMode, setInEditMode] = useState<boolean>(false);

  const handleUploadFile = async (event: any) => {
    if (!event.target.files) return;

    let file = event.target.files[0];
    let fileMinusExtention = file.name?.replace(/\.[^/.]+$/, "");

    const res = await checkoutClient.UploadFile(file);
    // Update the state
    const url = res.location;
    setAttachment({
      url: url,
      id: id,
      name: fileMinusExtention,
      includeInRequestPaymentEmail: false,
      includeInPaymentConfirmedEmail: false,
      includeInInvoiceEmail: false,
    });
  };

  const handleAdd = async () => {
    const succeeded = await checkoutClient.AddAttachment(id, attachment);
    setShowModal(false);
    if (!succeeded) return;
    onAttachmentsChanged();
    setAttachment({
      url: "",
      id: "",
      name: "",
      includeInRequestPaymentEmail: false,
      includeInPaymentConfirmedEmail: false,
      includeInInvoiceEmail: false,
    });
  };

  const handleDelete = async (attachment: UpdateAttachment) => {
    const succeeded = await checkoutClient.DeleteAttachment(id, attachment.id);
    if (!succeeded) return;
    onAttachmentsChanged();
  };

  const handleEdit = async () => {
    const succeeded = await checkoutClient.UpdateAttachment(id, attachment);
    setShowModal(false);
    if (!succeeded) return;
    onAttachmentsChanged();
    setAttachment({
      url: "",
      id: "",
      name: "",
      includeInRequestPaymentEmail: false,
      includeInPaymentConfirmedEmail: false,
      includeInInvoiceEmail: false,
    });
    setInEditMode(false);
  };

  const renderAttatchmentsHeader = () => {
    let headerElement = [
      "Name",
      //"id",
      // "URL",
      "Include In Request Payment Email",
      "Include In Payment Confirmed Email",
      "Include In Invoice Email",
      "Actions",
    ];

    return headerElement.map((key, index) => {
      return (
        <th className="sticky-top bg-white" key={index}>
          {key}
        </th>
      );
    });
  };

  return (
    <div>
      <CContainer>
        <CRow>
          <CIcon
            onClick={() => setShowModal(true)}
            className=" mt-1"
            style={{
              height: "1.5em",
              width: "1.5em",
            }}
            content={freeSet.cilPlus}
          />
        </CRow>
        <CRow>
          <table className="position-relative table table-hover table-sm">
            <thead>{renderAttatchmentsHeader()}</thead>
            <tbody>
              {attachments?.map((file, index) => (
                <tr key={file.id}>
                  <td>
                    <a target="_blank" href={file?.url}>
                      {file?.name}
                    </a>
                  </td>
                  <td>{file?.includeInRequestPaymentEmail ? "Yes" : "No"}</td>
                  <td>{file?.includeInPaymentConfirmedEmail ? "Yes" : "No"}</td>
                  <td>{file?.includeInInvoiceEmail ? "Yes" : "No"}</td>
                  <td>
                    <CIcon
                      content={freeSet.cilTrash}
                      onClick={() => handleDelete(file)}
                    ></CIcon>
                    <CIcon
                      content={freeSet.cilPencil}
                      onClick={() => {
                        setAttachment(file);
                        setShowModal(true);
                        setInEditMode(true);
                      }}
                    ></CIcon>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </CRow>
      </CContainer>

      <CModal
        className="ml-auto mr-auto p-3"
        size={"lg"}
        show={showModal}
        onClose={() => {
          setShowModal(false);
          setInEditMode(false);
        }}
        centered
      >
        <CModalHeader>{inEditMode ? "Edit" : "Add"} Attatchment</CModalHeader>
        <CModalBody>
          <CForm>
            {!inEditMode && (
              <CRow>
                <CCol className="com-sm-12">
                  <CLabel className="ml-0 position-relative mr-2">
                    Upload Attachment:
                  </CLabel>
                  <CInput
                    className="uploadAttachment"
                    style={{ fontSize: "10px" }}
                    size="sm"
                    onChange={handleUploadFile}
                    type="file"
                    id={"input-file"}
                  />
                </CCol>
              </CRow>
            )}
            {attachment.url && (
              <CRow className="mt-4 mb-4">
                <CCol className="col-sm-6">
                  <CLabel>Attachment Name:</CLabel>
                  <CInput
                    style={{ fontSize: "10px" }}
                    size="sm"
                    type="text"
                    value={attachment?.name}
                    onChange={(e: any) =>
                      setAttachment({
                        ...attachment,
                        name: e?.target?.value,
                      })
                    }
                  />
                </CCol>
                <CCol className="col-sm-6">
                  <CRow className="ml-5">
                    <CInputGroup>
                      <CInputCheckbox
                        style={{ cursor: "pointer" }}
                        //disabled={quotation.paidOn !== null}
                        checked={attachment.includeInRequestPaymentEmail}
                        onClick={() =>
                          setAttachment({
                            ...attachment,
                            includeInRequestPaymentEmail: !attachment.includeInRequestPaymentEmail,
                          })
                        }
                      />
                      <CLabel className="text-primary">
                        Include In Request Payment Email
                      </CLabel>
                    </CInputGroup>
                  </CRow>
                  <CRow className="ml-5">
                    <CInputGroup>
                      <CInputCheckbox
                        style={{ cursor: "pointer" }}
                        //disabled={quotation.processed}
                        checked={attachment.includeInInvoiceEmail}
                        onClick={() =>
                          setAttachment({
                            ...attachment,
                            includeInInvoiceEmail: !attachment.includeInInvoiceEmail,
                          })
                        }
                      />
                      <CLabel className="text-primary">
                        Include In Invoice Email
                      </CLabel>
                    </CInputGroup>
                  </CRow>
                  <CRow className="ml-5">
                    <CInputGroup>
                      <CInputCheckbox
                        style={{ cursor: "pointer" }}
                        // disabled={quotation.attachments?.name == ""}
                        checked={attachment.includeInPaymentConfirmedEmail}
                        onClick={() =>
                          setAttachment({
                            ...attachment,
                            includeInPaymentConfirmedEmail: !attachment.includeInPaymentConfirmedEmail,
                          })
                        }
                      />
                      <CLabel className="text-primary">
                        Include In Payment Confirmed Email
                      </CLabel>
                    </CInputGroup>
                  </CRow>
                </CCol>
              </CRow>
            )}

            <br />
            <CRow className="justify-content-between mt-4">
              <CCol>
                <CButton
                  className=" bg-primary text-white ml-1 mr-1"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </CButton>
              </CCol>
              <CCol className="text-right">
                <CButtonGroup>
                  {inEditMode ? (
                      <ActionButton
                          text={"Save"}
                          extraClass={"w-100 bg-primary text-white ml-1 mr-1"}
                          onClick={handleEdit}
                      />
                  ) : (

                    <ActionButton
                    text={"Add"}
                      extraClass={"w-100 bg-primary text-white ml-1 mr-1"}
                    variant="ghost"
                    onClick={handleAdd}
                    />
                  )}
                </CButtonGroup>
              </CCol>
            </CRow>
          </CForm>
        </CModalBody>
      </CModal>
    </div>
  );
}

export default QuotationAttachments;
