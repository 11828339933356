import {AccountAppModel} from "../../models/app";

const accountState: AccountAppModel = {
    accountSearch: {
        count: 0,
        isLoading: false,
        limit: 20,
        page: 0,
        result: [],
        filterGroup: {
            page: 0,
            limit: 10,
            filters: [],
        },
    },
};

export default accountState;
