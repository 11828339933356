import {CSpinner, CDataTable, CLink} from "@coreui/react";
import React from "react";
import {AppModel} from "../../../../models/app";
import {ContainerSearch} from "../../../../models/container";
import PageCount from "../../../SharedComponents/PageCount";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEye} from '@fortawesome/free-solid-svg-icons'
import {formatDate} from "../../../../utils/dateUtil";
import {setContainerSearchLimit} from "../../../../store/actions/containers";

interface Props {
    onRowClick?: (e: ContainerSearch) => void;
    className?: string;
}

const SearchResult = (props: Props) => {
    const scopedSlots = {
        actions: (item: ContainerSearch) => <td></td>,
        links: (item: ContainerSearch) =>

            <td>
                <CLink to={`/containers/${item.id}?isEdit=false`} className="card-header-action">
                    <FontAwesomeIcon icon={faEye}/>
                </CLink>

            </td>
        ,
        type: (item: ContainerSearch) => (
            <td>{item.containerType?.name}</td>
        ),
        openedOn: (item: ContainerSearch) => (
            <td>{formatDate(item.openedOn)}</td>
        ),
        closedOn: (item: ContainerSearch) => (
            <td>{formatDate(item.closedOn)}</td>
        )

    };

    const {className, onRowClick} = props;

    const dispatch = useDispatch();
    const hist = useHistory();
    const loc = useLocation();

    const containerSearchResult = useSelector((state: AppModel) => {
        return {
            result: state.container.containersSearch.result,
            isLoading: state.container.containersSearch.isLoading,
            page: state.container.containersSearch.page,
            filterGroup: state.container.containersSearch.filterGroup,
            maxFound: state.container.containersSearch.count,
            maxPage: Math.ceil(
                state.container.containersSearch.count! /
                state.container.containersSearch.limit!
            ),
        };
    });

    return (
        <div className={className}>
            {containerSearchResult.isLoading ? (
                <CSpinner
                    className="mx-auto d-block my-5"
                    color="primary"
                    style={{width: "5em", height: "5em"}}
                />
            ) : (
                <>
                    <div className="small">
                        <CDataTable
                            size="sm"
                            hover
                            items={containerSearchResult.result}
                            scopedSlots={scopedSlots}
                            fields={[
                                {key: "links", label: "", _style: {width: '1%'}},
                                "id",
                                "number",
                                "type",
                                "openedOn",
                                "closedOn",
                                "totalShipments",
                                "actions",
                            ]}
                        />
                        <PageCount
                            maxFound={containerSearchResult.maxFound}
                            onPageChange={(p) => {
                                const modifiedSearch = new URLSearchParams(loc.search);
                                modifiedSearch.delete("page");
                                hist.push(
                                    `${loc.pathname}?${modifiedSearch.toString()}&page=${p}`
                                );
                            }}
                            onPageSizeChange={(ps) => {
                                const modifiedSearch = new URLSearchParams(loc.search);
                                modifiedSearch.delete("limit");
                                hist.push(
                                    `${loc.pathname}?${modifiedSearch.toString()}&limit=${ps}`
                                );
                                dispatch(setContainerSearchLimit(ps))
                            }}
                        currentPage={containerSearchResult.filterGroup?.page ?? 1}
                        maxPage={containerSearchResult.maxPage}
                        />
                    </div>
                </>
            )}
        </div>
    );
}; 

export default SearchResult;
