import {CContainer} from "@coreui/react";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import AccountSearchForm from "./AccountSearchForm";

import {AppModel} from "../../../../models/app";
import AccountSearchResult from "./AccountSearchResult";
import AccountingClient from "../../../../clients/accountingClient";
import {AccountFilterGroup} from "../../../../models/filter";
import {IAddAccount} from "../../../../models/shipment";
import {SetAccountResult, SetAccountsIsLoading} from "../../../../store/actions/accounts";

const AccountingAccounts = () => {
        const accountingClient = new AccountingClient();

        const dispatch = useDispatch();

        const filterGroup = useSelector(
            (state: AppModel) => state.account.accountSearch.accountFilterGroup
        ) as AccountFilterGroup;

        const resultGroup = useSelector((state: AppModel) => state.account.accountSearch.result);
        const firstResultGroup = useSelector((state: AppModel) => state.account.accountSearch.firstResult);

        const [newFilters, setNewFilters] = useState<AccountFilterGroup>(filterGroup || {filters: {}});


        useEffect(() => {
            const fetchAccounts = async () => {
                dispatch(SetAccountsIsLoading(true))
                const result = await accountingClient.SearchAccount();
                if (result.total > 0) {
                    dispatch(SetAccountResult(1, result.total, result.matches, result.matches, result.limit))


                } else {
                    dispatch(SetAccountResult(1, 0, [], [], 0));
                }
                dispatch(SetAccountsIsLoading(false))
            }
            fetchAccounts();
        }, []);

        useEffect(() => {
            const filteredResults = firstResultGroup?.filter((account: IAddAccount) => {
                const {accountType, profileId, field} = newFilters.filters || {};

                let matchesAccountType = accountType ? accountType == account.accountType : true;
                let matchesProfileId = profileId ? profileId == account.profileId : true;
                let matchesField = field && (account.email != null && account.email != '') ||
                (account.name != null && account.name != '') ||
                (account.phone != null && account.phone != '') ||
                (account.id != null && account.id != '') ? account?.email?.toLowerCase().includes((field != undefined ? field : '')?.toLowerCase()) ||
                    account?.name?.toLowerCase().includes((field ?? '')?.toLowerCase()) ||
                    account?.id?.toLowerCase().includes((field ?? '')?.toLowerCase()) ||
                    account?.phone?.toLowerCase().includes((field ?? '')?.toLowerCase()) : false;
                return matchesAccountType && matchesProfileId && matchesField;
            });

            dispatch(SetAccountResult(1, filteredResults?.length || resultGroup?.length || 0, filteredResults || resultGroup || [], firstResultGroup || [], 20));
        }, [newFilters, dispatch]);

        return (
            <CContainer className="bg-white p-4">
                <AccountSearchForm newFilters={newFilters} setNewFilters={setNewFilters}/>
                <AccountSearchResult/>
            </CContainer>
        );
    }
;

export default AccountingAccounts;
