import React, {useState} from "react";
import {CTooltip} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import {ShipmentComment, ShipmentGet, ShipmentSearch, StandardShipmentTrace} from "../../../../models/shipment";
import {formatDate} from "../../../../utils/dateUtil";
import {TableHeader} from "./TableHeader";
import ShipClient from "../../../../clients/shipClient";
import AddCommentModal from "../modals/AddCommentModal";

interface IProps {
    shipment: ShipmentGet;
    shipClient: ShipClient;
    onUpdateShipment: (shipment: ShipmentSearch) => void;
}

const Comments = (props: IProps) => {
    const {shipment,onUpdateShipment, shipClient} = props;
    const [showAddCommentModal, setShowAddCommentModal] = useState<boolean>(false);
    const handleAddComment = async (comment: string) => {
        let rs = await shipClient.addComment(shipment.uid, comment)
        if (rs.succeeded) {
            let comments = shipment.comments;
            comments.push(rs.data)
            onUpdateShipment({...shipment, comments})
        }
        setShowAddCommentModal(false)
    }
    return (
        <>
            <div>
                <CTooltip content={"Add comment"}>
                    <CIcon
                        size={"xl"}
                        color={"primary"}
                        content={freeSet.cilPlus}
                        style={{cursor: "pointer"}}
                        onClick={() => setShowAddCommentModal(true)}
                    />
                </CTooltip>
                <table className="position-relative table table-hover table-sm">
                    <TableHeader header={["Date", "Comments", "User"]}/>
                    <tbody>
                    {shipment.comments?.length < 1 && (
                        <div className="mr-2 ml-2 mt-2 mb-2">
                            <span> No Available Comments</span>
                        </div>
                    )}

                    {shipment.comments?.map((comment: ShipmentComment) => (
                        <tr key={comment.id}>
                            <td style={{width: "10%"}}>{formatDate(comment.createdOn)}</td>
                            <td>{comment.comment}</td>
                            <td style={{width: "10%"}}>{comment.createdBy}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            {showAddCommentModal &&
                <AddCommentModal
                    HandleClose={() => setShowAddCommentModal(false)}
                    handleSubmit={handleAddComment}
                />
            } 
        </>

    )
}
export default Comments;