import React, {useState} from 'react';
import {CButton, CCol, CModal, CModalBody, CModalFooter, CModalHeader, CRow} from "@coreui/react";
import DateAndTimePicker from "../../../SharedComponents/SWForm/DateAndTimePicker";
import ActionButton from "../../../SharedComponents/ActionButton";


interface IProps{
    onClose:()=>any
    onSubmit:(date:any) => any
}

const Component = (props:IProps) => {

    const [date,setDate] = useState<any>(new Date(Date.now()));
    const [disabled, setDisabled] = useState(false);
  const onClick = () => {
    setDisabled(true);
    props.onSubmit(date);
    
  }

    return (
        <CModal
            show={true}
            onClose={props.onClose}
        >
            <CModalHeader>
                <h6>Are you sure you want to mark the booking as processed?</h6>
            </CModalHeader>
            <CModalBody>
                This action will mark this booking as processed.
                <DateAndTimePicker value={date} handleOnChange={(v: string) => setDate(v)}/>
            </CModalBody>
            <CModalFooter>
                <CRow className="justify-content-between w-100">
                    <CCol className="text-left">
                        <CButton
                            variant="outline"
                            color="danger"
                            onClick={props.onClose}
                        >
                            Cancel
                        </CButton>
                    </CCol>
                    <CCol className="text-right">
                        <ActionButton
                            text={"Yes"}
                            disabled={disabled}
                            onClick={onClick}
                        />
                    </CCol>
                </CRow>
            </CModalFooter>
        </CModal>
    )
}

export default Component;