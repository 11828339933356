import { freeSet } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CTooltip, CLink, CDataTable, CSpinner } from "@coreui/react";
import React, { useContext, useEffect, useState } from "react";
import LookupsClient from "../../../../clients/lookupsClient";
import { QuotationChargeInfo } from "../../../../models/quotation";
import SWFormContext from "../../../SharedComponents/SWForm/SWFormContext";

interface Props {
  readonly?: boolean;
  onDelete: (idx: number) => void;
  setEditTarget: (idx: number) => void;
  tableIsLoading:boolean;
  
}
const ItemsTable = (props: Props) => {
  const { readonly, onDelete, setEditTarget, tableIsLoading } = props;
  const lookupsClient = new LookupsClient();

  const [chargeTypes, setChargeTypes] = useState<{ [k: string]: string }>({});

  const ctx = useContext(SWFormContext);

  useEffect(() => {
    lookupsClient.getCharges({availableOn: "quotation", format: 1}).then((v) => {
      v && setChargeTypes(v);
    });
  }, []);

  const contextItems = ctx.getFormValue("items") as QuotationChargeInfo[];
  
  const total = contextItems?.reduce((a, b) => a +Number(b.value), 0)
  const totalWithVat = contextItems?.reduce((a, b) => a + Number(b.totalValue), 0)
  
  const items = contextItems && [...contextItems, {taxRate: "Total", value: "", totalValue: totalWithVat }]
    
  const scopedSlots = {
    type: (e: QuotationChargeInfo) => {
      return <td>{chargeTypes[e.typeId]}</td>;
    },
    taxRate: (e: QuotationChargeInfo) => {
      return e.typeId ? 
       <td>{e.taxRate}%</td> : <td> <strong>Total</strong> </td> ;
    },
   
    
    comments: (e: QuotationChargeInfo) => {
      return <td>{e.comments}</td>;
    },

    quantity:(e:QuotationChargeInfo)=>{
      return <td> {e.quantity}</td>

    },
    totalValue: (e:QuotationChargeInfo)=>{
      return <td> {Number(e.totalValue.toFixed(2))}</td>
    },
    actions: (e: QuotationChargeInfo, idx: number) => {
      return (
          !e.typeId ? <td/> :
        <td>
          <CTooltip content="Delete item">
            <CLink
              className={`mr-2 ${readonly ? "text-secondary" : "text-primary"}`}
              disabled={readonly}
              onClick={() => {
                onDelete(idx);
              }}
            >
              <CIcon content={freeSet.cilTrash} />
            </CLink>
          </CTooltip>

          <CTooltip content="Edit item">
            <CLink
              className={readonly ? "text-secondary" : "text-primary"}
              disabled={readonly}
              onClick={() => {
                setEditTarget(idx);
              }}
            >
              <CIcon content={freeSet.cilPencil} />
            </CLink>
          </CTooltip>
        </td>
      );
    },
  };

  return (
    <div>
      <CDataTable
        scopedSlots={scopedSlots}
        fields={["type","comments", "value","quantity","taxRate", {key: "totalValue", label: "Total"} , "actions"]}
        items={items}
        
      />
      {/* {tableIsLoading === true ? <>Deleting ... <CSpinner color="primary"/></> : ""} */}


    </div>
  );
};

export default ItemsTable;
