import {CButton, CCard, CCardBody, CCol, CContainer, CRow, CSpinner, CTabs, CTooltip} from "@coreui/react";
import React, {useEffect, useState} from "react";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import GatewayClient from "../../../clients/gatewayClient";
import {AgentTraceMap} from "../../../models/agents";
import {useHistory, useParams} from "react-router";
import SWInput from "../../SharedComponents/SWInput";
import KeyvalueEditor from "../../SharedComponents/keyvalueEditor";
import LookupsClient from "../../../clients/lookupsClient";
import RatesClient from "../../../clients/ratesClient";

const gatewayClient = new GatewayClient();
const lookups = new LookupsClient()
const ViewTraceMap = () => {
    const {id} = useParams() as any;
    const [statusLookup, setStatusLookups] = useState<{ label: string, value: string }[]>([]);
    const [map, setMap] = useState<AgentTraceMap>();
    const hist = useHistory()


    const getTraceMap = async () => {
        let maps = await gatewayClient.SearchTraceMaps(id);
        setMap(maps[0]);
    }
    const getStatusLookups = async () => {
        const data = await lookups.searchTraces()
        setStatusLookups(data.result.map(i => ({label: i.id, value: i.id})))

    }
    useEffect(() => {
        getStatusLookups();
    }, [])

    useEffect(() => {
        getTraceMap();
    }, [])


    const onMapChange = async (newMap: any) => {
        if(map){
            await gatewayClient.UpdateTraceMap(map!.id!, {...map, traceMap:newMap})
            getTraceMap();
        }
    
    }
   

    return (
        <>
            <CContainer className=" p-4">
                {!map ? <CSpinner
                        className="mx-auto d-block my-5"
                        color="primary"
                        style={{width: "5em", height: "5em"}}
                    />
                    :
                    <CCard>
                        <CCardBody>
                            <ul className="nav ">
                                <li className="nav-item">
                                    <CTooltip content="Back">
                                        <CButton>
                                            <CIcon
                                                content={freeSet.cilArrowLeft}
                                                onClick={() => hist.goBack()}
                                            />
                                        </CButton>
                                    </CTooltip>
                                </li>

                                <li className="nav-item mt-2">
                                    {/*<CLabel>{map?.id}</CLabel>*/}
                                </li>

                            </ul>

                            <fieldset className="b-fieldset p-2">
                                <legend className="b-legend">
                                    <h5>Trace Map</h5>
                                </legend>
                                <CRow>

                                    <CCol className="col-sm-3">
                                        <SWInput
                                            className={"col-sm-12"}
                                            label="Id"
                                            name="id"
                                            readonly={true}
                                            value={map.id}
                                        />
                                    </CCol>
                                    <CCol className="col-sm-3 ">
                                        <SWInput
                                            className={"col-sm-12"}
                                            label="Name"
                                            name="name"
                                            value={map.name}
                                        />
                                    </CCol>

                                </CRow>
                            </fieldset>

                            <CTabs>

                                <CContainer>

                                    <div className={"py-3"}>
                                        <KeyvalueEditor
                                            valueLookupValues={statusLookup}
                                            data={map.traceMap}
                                            keyLabel={"Agent Code"}
                                            valueLabel={"Traxis code"}
                                            onChange={onMapChange}
                                        />
                                    </div>

                                </CContainer>
                            </CTabs>

                        </CCardBody>
                    </CCard>
                }


            </CContainer>

        </>
    )
}

export default ViewTraceMap;
