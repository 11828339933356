import React, {useEffect, useState} from "react";
import {CDataTable} from "@coreui/react";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router";
import ShipClient from "../../../../clients/shipClient";
import {AssignedToCount, PendingPrinting} from "../../../../models/shipment";
import {SetIsLoading} from "../../../../store/actions/ui";
import Card from "./card";
import {ShowNotification} from "../../../../store/actions/auth";

const AssignedToUsers = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const shipClient = new ShipClient();


    const [data, setData] = useState<AssignedToCount[]>();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        dispatch(SetIsLoading(true))
        const res = await shipClient.AssignedToCounts();
        if (res?.succeeded) {
            let tempData: AssignedToCount[] = [];
            res?.data?.map((index: AssignedToCount) => {
                tempData.push(index);
            })
            setData(tempData)
        } else {
            dispatch(ShowNotification("Error", res?.error ?? "Error", true));
        }
    };
    const scopedSlots = {
        account: (item: PendingPrinting) => {
            return <td>{item.accountName ?? ""}</td>
        },
        numberOfShipments: (item: PendingPrinting) => {
            return <td>{item.numberOfShipments ?? ""}</td>
        },
    };

    let totalNumberOfAssignedUsers = data?.length ?? 0 > 0 ? data?.reduce((total, object) => {
        return total + object?.total;
    }, 0) : "-";

    const handleView = () => {
        history.push({
            pathname: '/shipments/search',
        });
    };

    return (
        <Card
            title={"Assigned to users"}
            subTitle={""}
            total={totalNumberOfAssignedUsers?.toString()}
            onView={() => handleView()}
            icon={
                <FontAwesomeIcon
                    icon={faUser}
                    tabIndex={-1}
                    style={{width: 45, height: 45, alignItems: "center"}}
                />
            }
            children={
                <div className="small" style={{overflowY: "auto", maxHeight: "100px"}}>
                    <CDataTable
                        header={false}
                        size="sm"
                        hover
                        scopedSlots={scopedSlots ?? {}}
                        items={data}
                        fields={[
                            {key: "userName", label: "User"},
                            {key: "total", label: "Total"},
                        ]}
                    />
                </div>
            }
        />
    )
}

export default AssignedToUsers;