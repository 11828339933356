import { CContainer, CRow, CCol, CModal, CModalBody, CModalHeader, CModalFooter, CButton } from "@coreui/react";
import { RateItemService } from "../../../../../models/retes";
import SWSelect from "../../../../SharedComponents/SWSelect";
import { object, string } from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import RatesClient from "../../../../../clients/ratesClient";

type Props = {
    valuesToUpdate?: RateItemService;
    onClose: () => void
    onAdd?: (arg: RateItemService) => void;
    onUpdate?: (arg: RateItemService) => void;
    isUpdate?:boolean
}

const ServicesModal = ({ valuesToUpdate, onClose ,onAdd,onUpdate,isUpdate}: Props) => {

    const ratesClient = new RatesClient();
    const [services, setServices] = useState<{ [k: string]: string }>({});
    const [customers, setCustomers] = useState<{ [k: string]: string }>({});
    const [agents, setAgents] = useState<{ [k: string]: string }>({});
    const initialValues: RateItemService = {
        account: valuesToUpdate?.account ?? "",
        service: valuesToUpdate?.service ?? "",
        agent: valuesToUpdate?.agent ?? ""
    }
    const validationSchema = () => object().shape({
        account: string().required("Required"),
        service: string().required("Required"),
        agent: string().required("Required"),
    })
    const handleSubmit = async (values: RateItemService, { resetForm }: any) => {
        isUpdate? onUpdate?.(values) :  onAdd?.(values)
        resetForm()
        onClose()
    }
    const formik = useFormik<RateItemService>({
        initialValues: initialValues,
        onSubmit: handleSubmit,
        validationSchema: validationSchema
    })
    const { values, setFieldValue,touched,errors,submitForm } = formik

    useEffect(() => {
        getCustomers();
        getAgentsWithServicesList();
    }, []);

    useEffect(() => {
        if(Boolean(values?.agent)){
            getAgentServices(values?.agent)
        }else{
            setServices({})
        }
    }, [values?.agent]);


    const getCustomers = async () => {
        let customers = await ratesClient.LookupCustomers()
        setCustomers(customers)
    }
    const getAgentsWithServicesList = async () => {
        let agents = await ratesClient.GetAgentsWithServicesList()
        setAgents(agents)
    }
    const getAgentServices = async (agent:string) => {
        let services = await ratesClient.GetAgentServices(agent)
        setServices(services)
    }


    return (
        <CModal
           closeOnBackdrop={false}
            show={true}
            size={"xl"}
            className="small text-primary"
            centered
            onClose={() => onClose()}
        >
            <CModalHeader closeButton>
                <h5>Services</h5>
            </CModalHeader>
            <CModalBody>
            <CContainer className="form-group mb-0 mt-2">
                <CRow>
                    <CCol className="col-sm-3">
                        <SWSelect
                            label="Account"
                            name="account"
                            value={values?.account}
                            onChange={(e) => setFieldValue("account", e)}
                            values={customers}
                            validationErrors={touched?.account && errors?.account?.toString() || ""}
                        />
                    </CCol>
                    <CCol className="col-sm-3">
                        <SWSelect
                            label="Agent"
                            name="agent"
                            value={values?.agent}
                            onChange={(e) => {
                                setFieldValue("agent", e)
                                setFieldValue("service", "")
                            }}
                            values={agents}
                            validationErrors={touched?.agent && errors?.agent?.toString() || ""}
                        />
                    </CCol>
                    <CCol className="col-sm-3">
                        <SWSelect
                            label="Service"
                            name="service"
                            value={values?.service}
                            onChange={(e) => setFieldValue("service", e)}
                            values={services}
                            validationErrors={touched?.service && errors?.service?.toString() || ""}
                        />
                    </CCol>
                </CRow>
            </CContainer>
            </CModalBody>
            <CModalFooter>
                <CButton
                    size="sm"
                    style={{ fontSize: "10px" }}
                    className="mr-2"
                    color="primary"
                    onClick={() => submitForm()}
                >
                    Save
                </CButton>
            </CModalFooter>
        </CModal>
    )
}

export default ServicesModal;