import { CContainer } from "@coreui/react";
import React from "react";

interface IProps {
  title: string;
}
const QuotationStamp = (props: IProps) => {
  const { title } = props;

  return (
    <>
      <CContainer size={100} className="stampContainer mb-1">
        <h4 className="stampText text-danger">{title} </h4>
      </CContainer>
    </>
  );
};

export default QuotationStamp;
