import React, {useEffect, useState, useMemo} from "react";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import {
    CCard,
    CCol,
    CRow,
    CTooltip,
    CButton,
    CAlert,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle, CLink, CSpinner,
} from "@coreui/react";

import ShipClient from "../../../clients/shipClient";
import {useHistory, useParams} from "react-router";
import './Style.css'
import {
    BookingUpdate, calculateVolumetricAndChargeWeight,
    CreateShipment,
    GenerateLabelRequest,
    groupErrorsBySource,
    mapGetShipmentDataToStandardShipment, ShipmentCreateResult,
    ShipmentErrorGet,
    ShipmentSearch,
    ShipmentSearchRequest,
    StandardShipmentItem, StandardShipmentPiece
} from "../../../models/shipment";
import ShipmentTables from "./ShipmentTables";
import RatesClient from "../../../clients/ratesClient";
import ShipmentEditModal from "./modals/ShipmentEditModal";
import Shipper from "./Shipper";
import Consignee from "./Consignee";
import Shipment from "./Shipment";
import Service from "./Service";
import LookupsClient from "../../../clients/lookupsClient";
import {useDispatch, useSelector} from "react-redux";
import {SetLoading, ShowNotification} from "../../../store/actions/auth";
import WarningModal from "./modals/WarningModal";
import LegacyClient from "../../../clients/legacyClient";
import CodStatusDetailsModal from "./modals/CodStatusDetailsModal";
import ConfirmModal from "../../SharedComponents/ConfirmModal";
import ShipmentDeletedStamp from "./components/ShipmentDeletedStamp";
import AccountCard from "./AccountCard";
import ReferenceCard from "./ReferenceCard";
import ShipmentSteps from "./components/ShipmentSteps";
import ShipmentBlockedStamp from "./components/ShipmentBlockedStamp";
import {downloadBlob} from "../../../utils/downloadUtils";
import CheckoutClient from "../../../clients/checkoutClient";
import AccountingClient from "../../../clients/accountingClient";
import {ChargeSearch} from "../../../models/accounting";
import SWSelect from "../../SharedComponents/SWSelect";
import {Report} from "../../../models/Report";
import {AppModel, RemoteBlob} from "../../../models/app";
import SWDateAndTimePicker from "../../SharedComponents/SWDateAndTimePicker";
import {setCreateShipmentData} from "../../../store/actions/shipments";
import WarehousesClient from "../../../clients/warehousesClient";
import {SkuSearch} from "../../../models/warehouse";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import GatewayClient from "../../../clients/gatewayClient";
import LabelModal from "../ShipmentCreate/components/labelModal";
import ViewAssignedTo from "./AssignedTo";

const ViewShipmentDetails = () => {
    const shipClient = new ShipClient();
    const lookupsClient = new LookupsClient();
    const ratesClient = new RatesClient();
    const checkoutClient = new CheckoutClient();
    const accountingClient = new AccountingClient();
    const warehouseClient = new WarehousesClient();
    const dispatch = useDispatch();

    const {id} = useParams() as any;
    const loading = useSelector((state: AppModel) => state.app?.isLoading);
    const [EditModalIsShowing, setEditModalIsShowing] = useState(false);
    const [modalType, setModalType] = useState("null");
    // @ts-ignore
    const [shipment, setShipment] = useState<ShipmentGet>({});
    const [account, setAccount] = useState<any | null>(null);
    const [charges, setCharges] = useState<ChargeSearch[]>([]);
    const [costumers, setCostumers] = useState<{ [key: string]: string }>({})
    const [countries, setCountries] = useState<{ [key: string]: string }>({});
    const [showCodStatusModal, setShowCodStatusModal] = useState<boolean>(false)
    const [generatedError, setGeneratedError] = useState<string>("")
    const [deleteConfirmModal, setDeleteConfirmModal] = useState<boolean>(false)
    const [forceDeleteConfirmModal, setForceDeleteConfirmModal] = useState<boolean>(false)

    const [blockConfirmModal, setBlockConfirmModal] = useState<boolean>(false)
    const [unblockConfirmModal, setUnblockConfirmModal] = useState<boolean>(false);
    const [trackingUrl, setTrackingUrl] = useState<string>()
    const [agentLookups, setAgentLookups] = useState<{ [k: string]: string }>({})

    const [warningModal, setWarningModal] = useState(false);
    const [initialTotalValue, setInitialTotalValue] = useState<number | null>(0)

    const [volumetricWeight, setVolumetricWeight] = useState<number>(0)
    const [calculatedWeight, setCalculatedWeight] = useState<number>(0)

    const [quotationNumber, setQuotationNumber] = useState<string>()
    const [chargeTypes, setChargeTypes] = useState<{ [k: string]: string }>({});

    const [showConfirmEdit, setShowConfirmEdit] = useState<boolean>(false)

    const [generateConfirmModal, setGenerateConfirmModal] = useState<boolean>(false);
    const [labelSize, setLabelSize] = useState<string | undefined>("");
    const [labelSizeError, setLabelSizeError] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [pickupDate, setPickupDate] = useState<string | null>(null);

    const [report, setReport] = useState<Report[]>([])
    const [allSkus, setAllSkus] = useState<SkuSearch[]>([])

    const [agentServices, setAgentServices] = useState<{ [k: string]: string }>({})

    const [shipmentBlockedReasons, setShipmentBlockedReasons] = useState<{ [k: string]: string }>({});
    const [blockReasonCode, setBlockReasonCode] = useState<string>("");
    const [isGenerate, setIsGenerate] = useState(false)

    const [printModal, setPrintModal] = useState<{ isShown: boolean, data: ShipmentCreateResult } | null>(null)
    const [allowedUsers, setAllowedUsers] = useState<{ [k: string]: string }>({});


    useEffect(() => {
        let lookupsClient = new LookupsClient();
        lookupsClient.GetUiReports("ShipmentAttachments").then(v => {
            setReport(v)
        })
        initSkus()
        getShipmentBlockedReasons()
        getAllowedUsers()
    }, [])


    const getAllowedUsers = async () => {
        const shipClient = new ShipClient();
        const res = await shipClient.SearchUsersCanTakeTask()
        const formattedData: { [key: string]: string } = {["myself"]: "--Myself--", ["nobody"]: "--Nobody--"};
        if (res?.length > 0) {
            res?.map((index: any) => {
                formattedData[index.username] = index.displayName;
            })
            setAllowedUsers(formattedData)
        }

    }
    const getShipmentBlockedReasons = async () => {
        const res = await lookupsClient.configurations({type: "ShipmentBlockedReasons"})
        const formattedData: { [key: string]: string } = {};
        if (res?.succeeded) {
            res?.data?.[0]?.data?.forEach((item: { [k: string]: string }) => {
                formattedData[item.code] = item.value;
            });
            setShipmentBlockedReasons(formattedData)
        }
    }

    useEffect(() => {
        dispatch(SetLoading(true))
        shipClient.getShipment(id).then((v) => {
            setShipment(v);
            setInitialTotalValue(v.value);
            getGeneratedError()
            handleWeightCalculation(v.pieces, v.weightUnit)
            loadCharges(v.uid);
            v.account &&
            ratesClient.GetCustomer(v.account).then((a) => {
                setAccount(a);
            });

            ratesClient.LookupAgents().then(v => setAgentLookups(v))
            ratesClient.LookupCustomers().then(v => setCostumers(v))
            if (v.number && v.bookings && v.bookings.length > 0) {
                shipClient.getTrackingLink(v.number)
                    .then((res) => setTrackingUrl(res.data))
            }
            getAgentServices(v?.bookings?.[0]?.agent);
            checkoutClient.getQuotationIdByShipmentUid(v.uid).then(v => {
                if (v && Number(v) > 0)
                    setQuotationNumber(v)
                else
                    setQuotationNumber(undefined)
            })
        });
        lookupsClient.getCountries().then((countries) => setCountries(countries));
        lookupsClient.getCharges({availableOn: "shipment", format: 1}).then(c => setChargeTypes(c))

    }, [id]);

    const handleShipmentApiUpdate = async (shipment: ShipmentSearch) => {
        const succeeded = await shipClient.updateShipment(id, shipment);
        if (succeeded) {
            setWarningModal(false);
            dispatch(ShowNotification("Success", "Shipment Updated Successfully "));

        }

        await refreshShipment();
    };
    const loadCharges = async (uid: string) => {
        if (!uid)
            return;
        const shipmentCharges = await accountingClient.GetShipmentCharges(uid)
        if (shipmentCharges)
            setCharges(shipmentCharges);
    }

    const client = new ShipClient()

    const deleteShipment = async () => {
        let response;
        setIsSubmitting(true)
        if (deleteConfirmModal) {

            response = await client.deleteShipment(shipment.uid)


        } else {
            setForceDeleteConfirmModal(false);
            response = await client.deleteShipment(shipment.uid, true);

        }

        if (response.succeeded) {
            window.location.reload()

        } else if (deleteConfirmModal && response.data.shipmentHasInvoices == null) {

            setDeleteConfirmModal(false);
            setIsSubmitting(false);

            setForceDeleteConfirmModal(true);
        } else {
            dispatch(ShowNotification("Error", response.data.shipmentHasInvoices[0], true))
            setDeleteConfirmModal(false);
            setIsSubmitting(false);
        }

    }

    const deleteShipmentAfterForceDelete = async () => {
        setIsSubmitting(true)

        const response = await client.deleteShipment(shipment.uid);
        if (response.succeeded) {
            window.location.reload();
        }
        else
        {
            dispatch(ShowNotification("Error","Delete from Agent failed", true))

        }
        setIsSubmitting(false);


    }

    const blockShipment = async () => {
        const client = new ShipClient()
        let rs;
        if (shipment.blocked) {
            rs = await client.UnblockShipment(shipment.uid)
            if (rs.succeeded) {
                dispatch(ShowNotification("Success", "Shipment unblocked successfully"))
            }
        } else {
            rs = await client.BlockShipment(shipment.uid, {blockReasonCode})
            if (rs.succeeded) {
                dispatch(ShowNotification("Success", "Shipment blocked successfully"))
            }
        }
        await refreshShipment()
        if (!shipment.blocked)
            setBlockConfirmModal(false);
        else
            setUnblockConfirmModal(false);

    }

    const generateShipmenPackingList = async () => {
        const fileResult = await shipClient.GenerateShipmentAttachment(shipment.uid)
        if (!fileResult.succeeded)
            return
        downloadBlob(fileResult.data.location, fileResult.data.name, "_blank")
    }

    const getGeneratedError = async () => {
        let legacyClient = new LegacyClient()
        if (shipment.number != null) return
        let err = await legacyClient.GetGeneratedErrors(id)
        setGeneratedError(err)
    }
    const handleGenerateLabel = async (data: {
        booking: BookingUpdate,
        labelData: { pickupDate: string | null, labelSize: string }
    }) => {
        setLabelSize(data.labelData.labelSize)
        setPickupDate(data.labelData.pickupDate)
        const rs = await shipClient.updateBooking(shipment.uid, data.booking)
        if (rs.succeeded) {

            await refreshShipment()
            setIsGenerate(true)
        } else {
            setLabelSize(undefined)
            setPickupDate(null)
        }
    }
    const handleBookingUpdate = async (booking: BookingUpdate) => {
        const rs = await shipClient.updateBooking(shipment.uid, booking)
        if (rs.succeeded) {
            await refreshShipment()
            handleOnClose()
            dispatch(ShowNotification("Success", "Booking updated successfully", false))
        }
    }
    const checkItemTotalVal = (shipment: ShipmentSearch) => {
        let sum: number = 0;
        shipment.items.forEach((item: StandardShipmentItem) =>
            item.value ? (sum += item?.value) : 0
        );
        if (shipment?.value && shipment.value !== sum) setWarningModal(true);
    };

    const onEditShipment = () => {
        setShowConfirmEdit(true)
        setEditModalIsShowing(false)
    }

    const handleEditModal = async () => {
        await handleShipmentApiUpdate(shipment)
        handleOnClose();

    };

    const handleShipmentChange = (shipment: ShipmentSearch) => {
        setShipment(shipment);
    };

    const history = useHistory();

    const handleOnClose = () => {
        setEditModalIsShowing(false);
        setShowConfirmEdit(false)
    };

    const handleWeightCalculation = (pieces: StandardShipmentPiece[], weightUnit: string) => {

        const result = calculateVolumetricAndChargeWeight(pieces, weightUnit);

        setVolumetricWeight(result.totalVolumetric)
        setCalculatedWeight(result.totalCalculated)
    }

    const refreshShipment = async () => {
        const rs = await shipClient.getShipment(id);
        if (rs) {
            setShipment(rs)
            handleWeightCalculation(rs.pieces, rs.weightUnit)
        }
        ratesClient.GetCustomer(rs.account).then((a) => {
            setAccount(a);
        });
        await loadCharges(id);
    };

    const handleReferenceModal = async () => {
        await refreshShipment();
        setEditModalIsShowing(true);
        setModalType("reference");
    };

    const handleAccountModal = async () => {
        await refreshShipment();
        setEditModalIsShowing(true);
        setModalType("account");
    };

    const handleShipperModal = async () => {
        await refreshShipment();
        setEditModalIsShowing(true);
        setModalType("shipper");
    };

    const handleRecieverModal = async () => {
        await refreshShipment();
        setModalType("reciever");
        setEditModalIsShowing(true);
    };

    const handleShipmentModal = async () => {
        await refreshShipment();

        setModalType("shipment");
        setEditModalIsShowing(true);
    };

    const handleServiceModal = async () => {
        await refreshShipment();
        setEditModalIsShowing(true);
        setModalType("service");
    };

    const handleAssignedToModal = async () => {
        await refreshShipment();
        setEditModalIsShowing(true);
        setModalType("assignedTo")
    }

    const handleValueReset = () => {
        //reset to initial
        setShipment({...shipment, value: initialTotalValue})
        //close modal
        setWarningModal(false)

    }

    useEffect(() => {
        if (isGenerate) {
            generateLabel().then(() => setIsGenerate(false));
        }
    }, [isGenerate]);
    const generateLabel = async () => {
        dispatch(SetLoading(true))
        if (labelSize) {
            setLabelSizeError(false)
            const client = new ShipClient()
            let rs;
            rs = await client.GenerateShipment(shipment.uid, {size: labelSize, pickupDate})

            if (rs && !rs?.LabelError) {
                setEditModalIsShowing(false)
                dispatch(ShowNotification("Success", "Generated successfully"))
                setPrintModal({isShown: true, data: rs})
            }
            // } else {
            //     dispatch(ShowNotification("Failed", rs?.LabelError ?? 'Something went wrong', true))
            // }
            await refreshShipment()
            setGenerateConfirmModal(false)
            setLabelSize("")
            setPickupDate(null)
        } else {
            setLabelSizeError(true)
        }
    }

    const handleReportDownload = async (reportId: number) => {
        dispatch(SetLoading(true))
        const rq: GenerateLabelRequest = {
            reportId: reportId
        }
        let remoteBlob = await shipClient.GenerateAttachment(id, rq) as RemoteBlob
        if (!remoteBlob?.location)

            return;
        else
            downloadBlob(remoteBlob.location, remoteBlob.name)
    }

    const initSkus = async (w?: string) => {
        const skuSearch = await warehouseClient.SearchSku({
            accountNumber: account,
            limit: 3000,
            warehouse: w
        })
        const skus: SkuSearch[] = skuSearch?.data?.matches
        setAllSkus(skus)
    }


    const getAgentServices = async (agent: string) => {
        const res = await ratesClient.GetAgentServices(agent)
        setAgentServices(res)
    }

    return (

        !isSubmitting ? (<>
            <CCard className="bg-white p-3 text-black small">
                {Boolean(shipment?.blockReasonCode) && (
                    <CRow className={"mx-2 p-3 mb-2 b-warning"}>
                        <CCol>
                            <CRow className={"mb-1"}>
                                <CCol md={3} className={"text-warning"}>
                                    <FontAwesomeIcon
                                        icon={faExclamationCircle}
                                        size="lg"
                                        color={"warning"}
                                        style={{marginRight: 5}}
                                    />
                                    <strong>{shipment?.blockReason} </strong>
                                </CCol>
                            </CRow>
                        </CCol>
                    </CRow>
                )}
                {shipment && shipment?.errors && shipment?.errors?.length > 0 && shipment.hasErrors && (

                    <CRow className={"mx-2 p-3 b-error"}>
                        <CCol>
                            {shipment?.errors && groupErrorsBySource(shipment?.errors)?.map(([sourceName, groupedErrors]) => (
                                <CRow className={"mb-1"} key={sourceName}>
                                    <CCol md={3} className={"text-danger"}>
                                        <FontAwesomeIcon
                                            icon={faExclamationCircle}
                                            size="lg"
                                            color={"red"}
                                            style={{marginRight: 5}}
                                        />
                                        <strong>{sourceName}</strong>
                                    </CCol>
                                    <CCol className={"text-danger"}>
                                        {groupedErrors.map((error, index) => (
                                            <CRow key={index}>
                                                {error.message}
                                            </CRow>
                                        ))}
                                    </CCol>
                                </CRow>
                            ))}
                        </CCol>
                    </CRow>
                )}
                {shipment && shipment?.pendingPayment && (

                    <CRow className={"mx-2 p-3 b-error"}>
                        <CCol>

                            <CRow className={"mb-1"}>
                                <CCol md={3} className={"text-danger"}>
                                    <FontAwesomeIcon
                                        icon={faExclamationCircle}
                                        size="lg"
                                        color={"red"}
                                        style={{marginRight: 5}}
                                    />
                                    <strong>Pending payment</strong>
                                </CCol>
                            </CRow>

                        </CCol>
                    </CRow>
                )}
                <CRow>
                    <CCol md={4}>
                        <CRow>
                            <CTooltip content="Back">
                                <CButton>
                                    <CIcon
                                        content={freeSet.cilArrowLeft}
                                        onClick={() => history.goBack()}
                                    />
                                </CButton>
                            </CTooltip>
                            {/* {shipment.number &&
                            <CTooltip content="Billing">
                                <CButton>
                                    <CIcon
                                        content={freeSet.cilDollar}
                                        onClick={() => window.open(`https://www.gl-net.com/accounting/awb-billing.html?HAWB=${shipment.number}`, "_blank")}
                                    />
                                </CButton>
                            </CTooltip>} */}

                            {
                                shipment.bookings &&
                                shipment.bookings.length > 0 &&
                                trackingUrl &&
                                <CTooltip content={"Track"}>
                                    <CButton>
                                        <CIcon
                                            content={freeSet.cilLocationPin}
                                            onClick={() => window.open(trackingUrl, "_blank")}
                                        />
                                    </CButton>
                                </CTooltip>}

                            <CDropdown>
                                <CDropdownToggle>
                                    <CTooltip content={"Documents"}>
                                        <CIcon content={freeSet.cilBarcode}/>
                                    </CTooltip>
                                </CDropdownToggle>
                                <CDropdownMenu>
                                    {report?.map((r) => (
                                        <CDropdownItem onClick={() => handleReportDownload(r.id)}
                                                       key={r.id}>{r.name}</CDropdownItem>
                                    )) ?? <CDropdownItem disabled>No Reports Configured</CDropdownItem>}
                                </CDropdownMenu>
                            </CDropdown>

                            <CTooltip content="Duplicate">
                                <CButton>
                                    <CIcon
                                        content={freeSet.cilCopy}
                                        onClick={() => {
                                            dispatch(setCreateShipmentData(mapGetShipmentDataToStandardShipment(shipment, allSkus)))
                                            history.push({
                                                pathname: '/shipments/create',
                                                state: {duplicate: true}
                                            });
                                        }}
                                    />
                                </CButton>
                            </CTooltip>

                            {Boolean(shipment?.uid) && <CTooltip content={'Logs'}>
                                <CLink to={`${encodeURI(shipment?.uid)}/Logs`}>

                                    <CButton>
                                        <CIcon content={freeSet.cilDescription}

                                        />
                                    </CButton>
                                </CLink>

                            </CTooltip>}

                        </CRow>
                    </CCol>

                    <CCol md={2}>
                        {generatedError &&
                            <CAlert color={"danger"}>
                                {generatedError}
                            </CAlert>}
                    </CCol>
                    <CCol md={6}>
                        <div id="stepper1" className="bs-stepper" style={{fontSize: ".8rem"}}>
                            <div className="bs-stepper-header">
                                <ShipmentSteps data={shipment?.shipmentTraceSummaryItem ?? {}}/>
                            </div>
                        </div>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>
                        <ReferenceCard shipment={shipment} onEditClick={handleReferenceModal}/>
                    </CCol>
                    <CCol>
                        <AccountCard shipment={shipment} account={account} onEditClick={handleAccountModal}/>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>
                        <Shipper
                            shipment={shipment}
                            countries={countries}
                            onEditClick={handleShipperModal}
                            onUpdateShipment={handleShipmentApiUpdate}
                        />
                    </CCol>
                    <CCol>
                        <Consignee
                            shipment={shipment}
                            onEditClick={handleRecieverModal}
                            countries={countries}
                            onUpdateShipment={handleShipmentApiUpdate}
                        />
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>
                        <Shipment shipment={shipment} onEditClick={handleShipmentModal}
                                  getCodDetails={() => setShowCodStatusModal(true)}
                                  volumetricWeight={volumetricWeight}
                                  calculatedWeight={calculatedWeight}/>
                    </CCol>
                    <CCol>
                        <CRow>
                            <CCol>
                                <Service shipment={shipment} onEditClick={handleServiceModal}
                                         quotationNumber={quotationNumber}/>

                                <ViewAssignedTo shipment={shipment} onEditClick={handleAssignedToModal}
                                                allowedUsers={allowedUsers} quotationNumber={quotationNumber}/>

                            </CCol>
                        </CRow>
                    </CCol>

                </CRow>

                <CRow>
                    <ShipmentTables
                        countries={countries}
                        refresh={refreshShipment}
                        shipment={shipment}
                        onUpdateShipment={handleShipmentApiUpdate}
                        charges={charges}
                        chargeTypes={chargeTypes}
                    />
                </CRow>
                <CRow>
                    <CCol className={"text-right"}>
                        {!shipment.deleted && !shipment.blocked && !shipment.number && shipment.bookings && shipment.bookings.length > 0 &&
                            <CButton
                                style={{marginRight: "10px"}}
                                variant={"outline"}
                                size={"xs"}
                                color={((shipment.deleted) && (shipment.blocked) && !(shipment.number)) ? "secondary" : "success"}
                                disabled={((shipment.deleted) && (shipment.blocked) && !(shipment.number))}
                                onClick={() => setGenerateConfirmModal(true)}
                            >
                                Generate Label
                            </CButton>}
                        {shipment.isForceDeleted && <CButton
                            variant={"outline"}
                            size={"xs"}
                            style={{marginRight: "10px"}}
                            color={"danger"}
                            onClick={() => {
                                deleteShipmentAfterForceDelete()
                            }}
                        >
                            Delete From Agent
                        </CButton>}
                        {!shipment?.deleted && <CButton
                            variant={"outline"}
                            style={{marginRight: "10px"}}
                            size={"xs"}
                            color={shipment.deleted ? "secondary" : "warning"}
                            onClick={() => {
                                !shipment.blocked ? setBlockConfirmModal(true) : setUnblockConfirmModal(true)
                            }}
                        >
                            {shipment.blocked ? "Unb" : "B"}lock Shipment
                        </CButton>}

                        {!shipment?.isForceDeleted && !shipment?.deleted && <CButton
                            variant={"outline"}
                            size={"xs"}
                            color={shipment.deleted ? "secondary" : "danger"}
                            onClick={() => setDeleteConfirmModal(true)}
                        >
                            Delete Shipment
                        </CButton>}

                    </CCol>
                </CRow>
            </CCard>
            {EditModalIsShowing && <ShipmentEditModal
                initialTotalValue={initialTotalValue}
                isDisabled={warningModal}
                shipment={shipment}
                onChange={handleShipmentChange}
                onBookingUpdate={booking => handleBookingUpdate(booking)}
                mode={modalType}
                costumers={costumers}
                title="Edit"
                isShowing={EditModalIsShowing}
                onClose={handleOnClose}
                onSave={handleEditModal}
                onBlur={() => checkItemTotalVal(shipment)}
                countries={countries}
                agentLookups={agentLookups}
                agentServices={agentServices}
                getAgentServices={(agent: string) => getAgentServices(agent)}
                onGenerateLabel={handleGenerateLabel}
                isLoading={loading}
                allowedUsers={allowedUsers}
            />}

            <WarningModal
                shipment={shipment}
                isShowing={warningModal}
                onClose={handleValueReset}
                onSave={() => setWarningModal(false)}
            />

            {showCodStatusModal &&
                <CodStatusDetailsModal ShipmentNumber={shipment.number} newData={shipment?.shipmentCod}
                                       onClose={() => setShowCodStatusModal(false)}/>}

            {deleteConfirmModal &&
                <ConfirmModal onClose={() => setDeleteConfirmModal(false)} onSubmit={() => deleteShipment()}
                              title={"Delete Shipment"}
                              body={`Are you sure you want to delete shipment "${shipment?.number}"`}/>
            }

            {forceDeleteConfirmModal &&
                <ConfirmModal onClose={() => setForceDeleteConfirmModal(false)} onSubmit={() => deleteShipment()}
                              title={"Force Delete Shipment"}
                              body={`The shipment "${shipment?.number}" was not successfully deleted by the agent. Do you want to force delete it? `}/>
            }

            {unblockConfirmModal &&
                <ConfirmModal onClose={() => setUnblockConfirmModal(false)} onSubmit={() => blockShipment()}
                              title={"Unblock Shipment"}
                              body={`Are you sure you want to unblock shipment "${shipment?.uid}"`}/>
            }


            {blockConfirmModal &&
                <ConfirmModal onClose={() => {
                    setBlockReasonCode('')
                    setBlockConfirmModal(false)
                }} onSubmit={() => blockShipment()}
                              title={`${shipment.blocked ? "Unblock" : "Block"} Shipment`}
                              body={`Are you sure you want to ${shipment.blocked ? "unblock" : "block"} shipment "${shipment.uid}"`}
                              children={
                                  <CRow className={"mt-4"}>
                                      <CCol md={6}>
                                          <SWSelect label={"Reason"} value={blockReasonCode}
                                                    values={shipmentBlockedReasons}
                                                    onChange={(e) => setBlockReasonCode(e!)}/>
                                      </CCol>
                                  </CRow>
                              }
                              isDisabled={!Boolean(blockReasonCode)}
                />
            }
            {printModal?.isShown &&
                <LabelModal
                    data={printModal?.data}
                    navigateToNewShipment={(uId: string) => {
                        setPrintModal(null)
                    }}/>
            }
            {
                shipment.deleted && <ShipmentDeletedStamp/>
            }
            {
                !shipment.deleted && shipment.blocked && <ShipmentBlockedStamp/>
            }
            {
                showConfirmEdit && <ConfirmModal onClose={() => handleOnClose()} onSubmit={() => handleEditModal()}
                                                 title={`Edit shipment '${shipment.number ?? shipment.uid}'`}
                                                 body={"Are you sure you want to edit this shipment (editing this shipment will reset all charges associated with it)?"}/>
            }
            {generateConfirmModal &&
                <ConfirmModal
                    submitting={loading}
                    onClose={() => {
                        setLabelSize('')
                        setLabelSizeError(false)
                        setGenerateConfirmModal(false)
                    }} onSubmit={() => generateLabel()}
                    title={`Generate Label`}
                    body={`Are you sure you want to generate label for shipment "${shipment.uid}"?`}
                    children={
                        <CRow className={"mt-3"}>
                            <CCol md={5}>
                                <SWSelect
                                    values={[{label: "A4", value: "A4"}, {label: "A6", value: "A6"}]}
                                    value={labelSize ?? ""} name={"size"}
                                    isClearable={true}
                                    onChange={e => {
                                        setLabelSize(e)
                                    }}
                                    label={"Size"}
                                    validationErrors={labelSizeError ? 'required' : ''}
                                />
                            </CCol>
                            <CCol md={5}>
                                <SWDateAndTimePicker
                                    showTimeInput
                                    label={"Pickup date"}
                                    name={"pickupDate"}
                                    value={pickupDate}
                                    handleOnChange={(e) => setPickupDate(e)}
                                />
                            </CCol>

                        </CRow>
                    }
                />
            }
        </>) : (<> <CSpinner
            className="mx-auto d-block my-5"
            color="primary"
            style={{width: "5em", height: "5em"}}
        /></>)

    );
};

export default ViewShipmentDetails;
