import {AccountFilterGroup, FilterGroup} from "../../models/filter";
import {
    SET_ACCOUNT_FILTER_GROUP, SET_ACCOUNT_FIRST_RESULT,
    SET_ACCOUNT_IS_LOADING,
    SET_ACCOUNT_RESULT,

} from "../types";
import {IAddAccount} from "../../models/shipment";

export const setAccountsFilterGroup = (filterGroup: AccountFilterGroup) => {
    return {
        type: SET_ACCOUNT_FILTER_GROUP,
        payload: filterGroup,
    };
}
export const SetAccountsIsLoading = (loading: boolean) => {
    return {
        type: SET_ACCOUNT_IS_LOADING,
        payload: {
            isLoading: loading,
        },
    };
};

export const SetAccountResult = (
    page: number,
    count: number,
    result: IAddAccount[],
    firstResult: IAddAccount[],
    limit: number,
    error?: string,
) => {
    return {
        type: SET_ACCOUNT_RESULT,
        payload: {
            count: count,
            result: result,
            firstResult: firstResult,
            error: error,
            limit: limit,
        },
    };
};

export interface AccountAttachment {
    id: number
    location: string
    fileName: string
    by: string
    on: string
    agent: string
    type?: string
    urlKey: string
}


export interface AddAccountAttachmentRequest {
    type: string,
    location?: string
    fileName?: string
}

export interface UpdateAccountAttachmentRequest {
    id: number,
    type: string,
    location?: string
    fileName?: string
}

export interface DeleteAccountAttachmentRequest {
    id: number,

}

