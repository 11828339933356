import { createContext } from "react";
import { FormFields, InputFieldValue, Validator } from ".";

const SWFormContext = createContext(
  {} as {
    formReadonly: boolean;
    changeValue: (field: string, value: number | string | any[]) => void;
    validateField: (value: InputFieldValue) => void;
    setValidators: (field: string, validators: Validator[]) => void;
    formFields: FormFields;
    getFormValue: (field: string) => any;
    hasErrors: () => boolean;
    setAttribute: (field: string, attribute: string, value: any) => void;
  }
);

export default SWFormContext;
