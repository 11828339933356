import {
    CButton,
    CCol,
    CInputCheckbox,
    CLabel,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CRow
} from "@coreui/react";
import {InvoiceAttachment, InvoiceAttachmentAdd, InvoiceAttachmentType} from "../../../../models/accounting";
import {useFormik} from "formik";
import SWInput from "../../../SharedComponents/SWInput";
import * as yup from "yup"
import AccountingClient from "../../../../clients/accountingClient";
import {useEffect} from "react";

interface IProps {
    invoiceId: number
    isEdit: boolean
    onClose: () => void
    attachment?: InvoiceAttachment
    location?: string
    handleSubmit: (attachment: InvoiceAttachmentAdd) => void
}

const AddUpdateInvoiceAttachmentModal = (props: IProps) => {
    const accountingClient = new AccountingClient();
    const handleUploadFile = async (file?: File) => {
        const blob = await accountingClient.UploadFile(file)
        await setFieldValue("location", blob.location)
    }

    const {values, errors, handleSubmit, handleChange, setFieldValue} = useFormik<InvoiceAttachmentAdd>({
        initialValues: {
            invoiceId: props.invoiceId,
            location: props.attachment?.location ?? props.location ?? "",
            name: props.attachment?.name ?? "",
            type: props.attachment?.type ?? InvoiceAttachmentType.Other,
            includeInInvoiceEmail: props.attachment?.includeInInvoiceEmail ?? false,
        },
        onSubmit: (values) => props.handleSubmit(values),
        validationSchema: yup.object({
            location: yup.string().required("Please attach a file first"),
            name: yup.string().required("File Name is required")
        })
    })
    return <CModal show={true} onClose={props.onClose} centered>
        <CModalHeader>
            <h5>{props.isEdit ? "Edit Attachment" : "Add Attachment"}</h5>
        </CModalHeader>
        <CModalBody>
            {values.location &&
                <CRow>
                    <CCol>
                        <SWInput validationErrors={errors.name} value={values.name} label={"Name"}
                                 onChangeFormik={handleChange} name={"name"}/>
                    </CCol>
                    <CCol style={{marginTop: "6%"}}>
                        <CInputCheckbox checked={values.includeInInvoiceEmail}
                                        onChange={() => setFieldValue("includeInInvoiceEmail", !values.includeInInvoiceEmail)}/>
                        <CLabel>Include in invoice email</CLabel>
                    </CCol>
                </CRow>
            }
        </CModalBody>
        <CModalFooter>
            <CCol>
                <CButton
                    size={"sm"}
                    color={"danger"}
                    variant={"outline"}
                    onClick={() => props.onClose()}
                >Cancel</CButton>
            </CCol>
            <CCol className={"text-right"}>
                <CButton
                    size={"sm"}
                    color={"primary"}
                    variant={"outline"}
                    onClick={() => handleSubmit()}
                >Submit</CButton>
            </CCol>
        </CModalFooter>
    </CModal>
}
export default AddUpdateInvoiceAttachmentModal