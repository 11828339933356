import React, { useEffect, useState } from "react";
import { CBadge, CContainer, CDataTable, CTooltip } from "@coreui/react";
import ShipClient from "../../../../clients/shipClient";
import CIcon from "@coreui/icons-react";
import { freeSet } from "@coreui/icons";
import { downloadBlob } from "../../../../utils/downloadUtils";
import { BankDepositReportsResponseModel, BankDepositModel, ShipmentCOD } from "../../../../models/shipment";
import { useDispatch, useSelector } from "react-redux";
import { ShowNotification } from "../../../../store/actions/auth";
import { SetIsLoading } from "../../../../store/actions/ui";
import { AppModel } from "../../../../models/app";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { formatDate } from "../../../../utils/dateUtil";

interface Props {
    setIsSubmitting: any
}

const BankDepositsTable = ({ setIsSubmitting }: Props) => {
    const dispatch = useDispatch();
    const shipClient = new ShipClient();
    const isLoading = useSelector((state: AppModel) => state.app.isLoading);
    const [onHoldCod, setOnHoldCod] = useState<ShipmentCOD[]>([])
    const [bankDepositReports, setBankDepositReports] = useState<BankDepositReportsResponseModel[]>([])

    useEffect(() => {
        dispatch(SetIsLoading(true))
        getGroupedOnHoldCods()
        searchBankDepositReports()
    }, []);

    const searchBankDepositReports = async () => {
        const res = await shipClient.searchBankDepositReports();
        if (res?.succeeded) {
            setBankDepositReports(res?.data?.matches)
        } else {
            dispatch(ShowNotification("Error", res?.error, true));
        }
    };
    const getGroupedOnHoldCods = async () => {
        const res = await shipClient.getGroupedOnHoldCods();
        if (res) {
            setOnHoldCod(res)
        }
    };

    const handleReleaseOnHoldCods = async () => {
        setIsSubmitting(true)
        dispatch(SetIsLoading(true))
        const rs = await shipClient.releaseOnHoldCods()
        setIsSubmitting(false)
        if (rs.succeeded) {
            dispatch(ShowNotification("Success", "Report was generated successfully", false))
            downloadBlob(rs.data.location, `${rs.data.name}_${new Date()}`);
        }
    };
    const handelDownload = async (location: string) => {
        window.open(location, "_blank", "noreferrer");
    };

    const scopedSlots = {
        status: (item: BankDepositModel) => {
            if (!item?.id) {
                return <td>
                    <CBadge
                        style={{ backgroundColor: "#FF8800", marginLeft: 6 }}
                        color={"info"}>
                        Pending
                    </CBadge>
                </td>
            } else {
                return <td className={"text-center"}>
                    <CTooltip content="Export to Excel">
                        <CIcon
                            content={freeSet.cilCloudDownload}
                            onClick={() => handelDownload(item?.excelReportLocation)}
                            style={{ marginRight: 2, cursor: "pointer" }}
                        />
                    </CTooltip>
                    <CTooltip content="View Report">
                        <CIcon
                            content={freeSet.cilDescription}
                            onClick={() => handelDownload(item?.location)}
                            style={{ cursor: "pointer" }}
                        />
                    </CTooltip>
                </td >
            }
        },
        totalShipments: (item: BankDepositModel) => {
            return <td className={"text-center"}>{item.totalShipments ?? ""}</td>
        },
        totalRecords: (item: BankDepositModel) => {
            return <td className={"text-center"}>{item.totalRecords ?? ""}</td>
        },
        totalAmount: (item: BankDepositModel) => {
            return <td style={{ textAlign: 'end' }}>{item.totalAmount.toFixed(2) ?? ""}</td>
        },
        id: (item: BankDepositModel) => {
            return <td className={"text-center"}>{item?.id ?? ""}</td>
        },
        date: (item: BankDepositModel) => {
            return <td className={"text-center"}>{formatDate(item?.createdOn) ?? ""}</td>
        },
        createdBy: (item: BankDepositModel) => {
            return <td className={"text-center"}>{item?.createdBy ?? ""}</td>
        },
        generateReport: (item: BankDepositModel) => {
            if (!item?.id) {
                return <td className={"text-center"}>
                    <CTooltip content="Generate Report">
                        <CIcon
                            content={freeSet.cilCopy}
                            onClick={() => handleReleaseOnHoldCods()}
                            style={{ cursor: "pointer" }}
                        />
                    </CTooltip>
                </td>
            } else {
                return <td />
            }

        },
    };

    return (
        <>
            <CContainer>
                <CDataTable
                    loading={isLoading}
                    size="sm"
                    hover
                    scopedSlots={scopedSlots ?? {}}
                    items={[...onHoldCod, ...bankDepositReports] ?? []}
                    fields={[
                        { key: "status", label: "", _style: { textAlign: "center", width: '5%' } },
                        { key: "totalShipments", label: "Shipment", _style: { textAlign: "center" } },
                        { key: "totalRecords", label: "Payments", _style: { textAlign: "center" } },
                        { key: "totalAmount", label: "Total Amount", _style: { textAlign: "end" } },
                        { key: "id", label: "Document Number", _style: { textAlign: "center" } },
                        { key: "date", label: "Date", _style: { textAlign: "center" } },
                        { key: "createdBy", label: "Created By", _style: { textAlign: "center" } },
                        { key: "generateReport", label: "Actions", _style: { textAlign: "center", } }
                    ]}
                />
            </CContainer>
        </>
    )
}

export default BankDepositsTable