import {StylesConfig} from "react-select";

const selectStyle: StylesConfig<{ lable: string; value: string; id: string }, true> = {
    control: (provided, state) => {
        return {
            ...provided,
            minHeight: "26px",
            backgroundColor: state.isDisabled ? provided.backgroundColor : "none"
        };
    },
    indicatorsContainer: (provided, state) => {
        return {
            ...provided,
            height: "15px",
            marginTop: "7px",
            backgroundColor: state.isDisabled? provided.backgroundColor : "none"

        };
    },
    valueContainer: (provided, state) => {
        return {
            ...provided,
            height: "20px",
            backgroundColor:'none'
        };
    },
    indicatorSeparator:(provided,state)=>{
        return{
            ...provided,
            backgroundColor:"none",
        }
    },
    dropdownIndicator:(provided,state)=>{
        return{
            ...provided,
            height: "26px",
            marginBottom:"14px"
        }
    },


};

export  default selectStyle