import React from "react";
import {CButton, CCol, CLabel, CLink, CModal, CModalBody, CModalFooter, CModalHeader, CRow} from "@coreui/react";
import PdfViewer from "../../SharedComponents/pdfViewer";
import ActionButton from "../../SharedComponents/ActionButton";
import printJS from "print-js";


interface Props {
    data?: { uid: string, number: string, location: string },
    closeModal: () => void

}

const PrintOriginReciptLabelModal = (props: Props) => {
    const {data} = props;
    const printPDF = () => {
        !!data?.location && printJS(data.location, 'pdf');
    };

    return (
        <CModal
            closeOnBackdrop={false}
            show={true}
            centered
            size={"lg"}
            onClose={props.closeModal}
        >
            <CModalHeader>
                <h5> Receipt </h5>
            </CModalHeader>
            <CModalBody className={"mb-1"} style={{height: '600px'}}>
                {data?.uid &&
                    <CRow className={"mb-1 align-items-center"}>
                        <CCol md={2}>
                            <CLabel className={"small"}>Shipment Number:</CLabel>
                        </CCol>
                        <CCol>
                            <CLink className={"font-weight-bold"} style={{textDecoration: "underline"}}
                                   to={`/shipments/${data?.uid}`}>{data?.number}</CLink>
                        </CCol>
                        <CCol className="text-right">

                            <ActionButton
                                text={"Print"}
                                onClick={() => printPDF()}
                            />
                        </CCol>
                    </CRow>}

                {!!data?.location &&
                    <PdfViewer link={`${data.location}?view=true`}/>}
            </CModalBody>

            <CModalFooter>
                <CRow className="justify-content-between w-100">
                    <CCol className="text-right">
                        <CButton
                            style={{fontSize: "10px"}}
                            variant="outline"
                            color="primary"
                            onClick={props.closeModal}
                        >
                            Close
                        </CButton>
                    </CCol>
                </CRow>
            </CModalFooter>

        </CModal>
    )
}

export default PrintOriginReciptLabelModal