import {CButton} from "@coreui/react";
import React from "react";
import {useSelector} from "react-redux";
import {AppModel} from "../../models/app";

interface Props{
    onClick?: (e?:any)=> void
    text: string
    disabled?: boolean;
    extraClass?: string;
    color?: string;
    type?: string
    variant?: any;
    icon?:JSX.Element
}

const ActionButton = ({onClick,text, color, disabled, variant, extraClass, type,icon }:Props) => {
    const {isLoading} = useSelector(
        (state: AppModel) => state.app
    );
    return <>
        <CButton
            size="sm"
            style={{fontSize: "10px"}}
            variant={variant ?? "outline"}
            color={color ?? "primary"}
            className={extraClass}
            type={isLoading ? "disabled": (type ?? 'submit')}
            disabled={isLoading || disabled}
            onClick={onClick}
        >{text}{" "}
        {icon && icon}
        </CButton>
    </>
}

export default  ActionButton