import {CFormGroup, CFormText, CInput, CLabel} from "@coreui/react";
import React from "react";

const InputField = ({ field,readonly, onBlur, label,type, form: { touched, errors }, ...props }:any) => {
    return (
        <CFormGroup>
        <CLabel htmlFor={field.name}>{label}</CLabel>
            <CFormGroup>
        <CInput
            type={type}
            {...field} {...props}
            readOnly={readonly}
            onBlur = {onBlur}
            style={{ fontSize: "10px", height: "26px" }}
        />
            </CFormGroup>
            {
                touched[field.name] && errors[field.name]
                    ? <CFormText
                            style={{ fontSize: "1em" }}
                            color="danger"
                            className="help-block"
                      >
                        {errors[field.name]}
                      </CFormText>
                    : null
            }
            </CFormGroup>
    )
}

export default InputField
