import {CSpinner, CDataTable, CLink, CBadge} from "@coreui/react";
import React, {useState} from "react";
import PageCount from "../../../../SharedComponents/PageCount";
import { useHistory, useLocation } from "react-router";
import {
  InventoryTransaction,
  InventoryTransactionDetailDto,
} from "../../../../../models/warehouse";
import {IPaginatedResult, IPagination} from "../../../../../models/filter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {formatDate, formatDateTime} from "../../../../../utils/dateUtil";

interface Props {
  paginatedStock:IPaginatedResult<InventoryTransaction>
  pagination:IPagination
  isLoading:boolean
  accountsLookup:{ [k: string]: string }
  type:{ [k: string]: string }

  onUpdate(code: string | undefined) : void;
}

const SearchResult = (props: Props) => {
  const [limit, setLimit] = useState<number>(20)

  const hist = useHistory();
  const loc = useLocation();
  
  const getReservationStatus = (item: InventoryTransaction):string =>{
    const totalReserved = item.details.reduce((total,b) => total + (b?.quantityAvailable ?? 0), 0)
    
    const consumptionDetails= item.details.filter(d=> d.relatedDetails != undefined)
        .reduce((a: InventoryTransactionDetailDto[], b) =>a.concat(b.relatedDetails ?? []), []);
    
    const totalConsumed = consumptionDetails.reduce((total,b) => total + (b?.quantity ?? 0), 0)
    
    if(totalConsumed == 0)
      return ""

    if(totalConsumed == totalReserved)
      return "(consumed)"
    
    return "(partially consumed)"
    
  }
  return (
      <div className="small">
        {props.isLoading ? (
            <CSpinner
                className="mx-auto d-block my-5"
                color="primary"
                style={{ width: "5em", height: "5em" }}
            />
        ) : (
            <>
              <CDataTable
                  hover
                  size="sm"
                  scopedSlots={
                    {
                      code:(item:InventoryTransaction)=>{
                        return<td>{item.code?.slice(0,12)}</td>
                      },
                      action:(item:InventoryTransaction)=>{
                        return  <td><CLink to={`/stock/transactions/${item.code}`} className="card-header-action">
                          <FontAwesomeIcon icon={faEye}/>
                        </CLink></td>
                      },
                      account:(item:InventoryTransaction)=>{
                        return<td>{item.details && item.details.length > 0 ? props.accountsLookup[item.details[0].lot.account] : ""}</td>
                      },

                      type:(item:InventoryTransaction)=> {
                        return<td>{props.type[item.subType]} <strong> {item.type == "RSRV" ? getReservationStatus(item): ""  }</strong></td>
                      },
                      reference: (item: InventoryTransaction)=> {
                       if (item.shipmentNumber)
                         return <td><CLink to={`/shipments/${item.reference}`}>{item.shipmentNumber}</CLink></td>
                        else if (item.reference)
                          return <td>{item.reference}</td>
                        return <td />
                      },
                      on:(item: InventoryTransaction) => {
                        return <td>{formatDate(item.on)}</td>
                      },
                      createdOn:(item: InventoryTransaction) => {
                        return <td>{formatDateTime(item.createdOn)}</td>
                      },
                      posted:(item:InventoryTransaction) => {
                        if (item.reversedCode) {
                          return <td><CBadge
                              style={{width: "auto", height: "12px", fontSize: "10px"}}
                              shape="pill"
                              color="primary"
                          >Reversal
                          </CBadge></td>
                        } else if (item.reversalCode) {
                          return <td><CBadge
                              style={{width: "auto", height: "12px", fontSize: "10px"}}
                              shape="pill"
                              color="danger"
                          >Reversed
                          </CBadge></td>
                        }
                        return <td>{item.posted? "Yes": "No"}</td>
                      }
                    }
                  }
                  items={props.paginatedStock.matches}
                  fields={[{key: "action", label: ""}, {key:"on", label: "Date"}, {key: "code", label: "Id"},"account", "type", "reference", "createdBy", "posted"]}
              />
              <PageCount
                  onPageChange={(p) => {
                    const modifiedSearch = new URLSearchParams(loc.search);
                    modifiedSearch.delete("offset");
                    hist.push(
                        `${loc.pathname}?${modifiedSearch.toString()}&offset=${p*props?.pagination?.limit!}`
                    );
                  }}
                  onPageSizeChange={(ps) => {
                    const modifiedSearch = new URLSearchParams(loc.search);
                    modifiedSearch.delete("limit");
                    hist.push(
                        `${loc.pathname}?${modifiedSearch.toString()}&limit=${ps}`
                    );
                    setLimit(ps)
                  }}
                  currentPage={Math.ceil(props?.pagination?.offset!/ limit!)}
                  maxPage={Math.ceil(  props.paginatedStock?.total!/limit!)}
                  maxFound={props.paginatedStock.total}
              />

            </>
        )}
      </div>
  );
};

export default SearchResult;
