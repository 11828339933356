import {
  Action,
  AuthenticationAppModel,
  ContainerAppModel,
  QuotationAppModel,
} from "../../models/app";
import initialState from "../initialStates/quotationInitialState";
import {
  SET_QUOTATION_RESULT,
  SET_QUOTATION_IS_LOADING,
  SET_QUOTATION_FILTER_GROUP,
} from "../types";

const quotationReducer = (
  state: QuotationAppModel = initialState,
  action: Action
): QuotationAppModel => {
  switch (action.type) {
    case SET_QUOTATION_FILTER_GROUP:
      return {
        ...state,
        quotationsSearch: {
          ...state.quotationsSearch,
          filterGroup: {
            ...state.quotationsSearch.filterGroup,
            ...action.payload!,
          },
        },
      };

    case SET_QUOTATION_IS_LOADING:
    case SET_QUOTATION_RESULT:
      return {
        ...state,
        quotationsSearch: {
          ...state.quotationsSearch,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default quotationReducer;
