import {
    Action,
    RatesAppModel,
} from "../../models/app";
import initialState from "../initialStates/ratesInitialState";
import {
    SET_SEARCH_SELLING,
    SET_SEARCH_COSTS
} from "../types";

const ratesReducer = (
    state: RatesAppModel = initialState,
    action: Action
): RatesAppModel => {
    switch (action.type) {
        case SET_SEARCH_COSTS:
            return {
                ...state,
                costsSearch: action.payload,
            };
        case SET_SEARCH_SELLING:
            return {
                ...state,
                sellingSearch: action.payload,
            };
        default:
            return state;
    }
};

export default ratesReducer;
