import { CButton } from "@coreui/react";
import React from "react";
import { RemoteBlob } from "../../models/app";
import { downloadBlob } from "../../utils/downloadUtils";
import ActionButton from "./ActionButton";

interface Props {
  getData: () => Promise<RemoteBlob>;
  fileNamePrefix: string;
}

const ExportToExcelButton = (props: Props) => {
  const { getData, fileNamePrefix } = props;

  const handleClick = () => {
    getData().then((v) => {
      downloadBlob(v.location, `${fileNamePrefix}_${new Date()}`);
    });
  };

  return (
    <ActionButton
        text={"Export to Excel"}
        color="success"
        onClick={handleClick}
    />
  );
};

export default ExportToExcelButton;
