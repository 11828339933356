import {CCol, CDataTable, CModal, CModalBody, CModalHeader, CRow} from "@coreui/react";
import React, {useEffect, useState} from "react";
import {DiscrepancyCodRecord} from "../../../../models/shipment";
import {formatDate} from "../../../../utils/dateUtil";

interface IProps {
    handleClose: () => void
    discrepancyCODRecords: DiscrepancyCodRecord[]
}

const ShowDiscrepancyCODRecordsModal = (props: IProps) => {
    const [codRecords, setCodRecords] = useState<{ shipmentNumber: string, codRecords: DiscrepancyCodRecord[] }[]>([])
    const onlyUnique = (value: any, index: number, self: any) =>
        self.indexOf(value) === index;

    const handleGroup = () => {
        let shipmentNumbers = props.discrepancyCODRecords.map(d => d.shipmentNumber)
        shipmentNumbers = shipmentNumbers.filter(onlyUnique)
        let records = shipmentNumbers.map(s => {
            let record = props.discrepancyCODRecords.find(d => d.shipmentNumber == s)
            return {
                shipmentNumber: s,
                cod: record?.cod,
                codRecords: props.discrepancyCODRecords.filter(r => r.shipmentNumber == s)
            }
        })
        setCodRecords(records)
    }

    useEffect(() => {
        handleGroup()
    }, [props.discrepancyCODRecords])

    const scopedSlots = {
        shipmentNumber: (item : { shipmentNumber: string, cod: number, codRecords: DiscrepancyCodRecord[] }) =>
            <td>{item.shipmentNumber} ({item.cod ?? 0})</td>,
        codRecords: (item: { shipmentNumber: string, cod: number, codRecords: DiscrepancyCodRecord[] }) =>
            (
                <td>
                    {item.codRecords.map((c, i) => {
                        const lastIndex = item.codRecords.length
                        return <>
                            <CRow>
                                <CCol>{c.amount}</CCol>
                                <CCol>{c.payMode}</CCol>
                                <CCol>{c.documentNumber ? c.documentNumber : "New"}</CCol>
                                <CCol>{c.documentNumber ? formatDate(c.documentDate) : "New"}</CCol>
                            </CRow>
                            {i + 1 != lastIndex && <hr style={{margin: "1px"}}/>}
                        </>
                    })}
                </td>)
    }
    return (
        <CModal show={true} onClose={props.handleClose} size={"xl"} centered>
            <CModalHeader>
                <h5>Discrepancy COD records</h5>
            </CModalHeader>
            <CModalBody>
                <CDataTable
                    items={codRecords}
                    fields={["shipmentNumber", "codRecords"]}
                    scopedSlots={scopedSlots}
                    columnHeaderSlot={{
                        codRecords:
                            <CRow>
                                <CCol>Amount</CCol>
                                <CCol>PaymentType</CCol>
                                <CCol>Document Number</CCol>
                                <CCol>Document Date</CCol>
                            </CRow>
                    }}
                />
            </CModalBody>
        </CModal>
    )
}

export default ShowDiscrepancyCODRecordsModal;