import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import ShipClient from "../../../../clients/shipClient";
import {AppModel} from "../../../../models/app";
import {FilterType} from "../../../../models/filter";
import {filterGroupToQueryParams} from "../../../../utils/filterUtils";
import SWAdvancedSearch from "../../../SharedComponents/SWAdvancedSearch/SWAdvancedSearch";

interface Props {
}

const SearchForm = (props: Props) => {
    const [sortingruleNumber, setSortingRuleNumber] = useState("");
    const [showAdvanced, setShowAdvanced] = useState(false);
    const dispatch = useDispatch();
    const filterGroup = useSelector(
        (state: AppModel) => state.sortingrule.sortingrulesSearch.filterGroup
    );

    const [containerTypes, setContainerTypes] = useState<{ [k: string]: string }>(
        {}
    );

    const shipClient = new ShipClient();
    const loc = useLocation();
    const hist = useHistory();
    useEffect(() => {
        shipClient.GetContainerTypes(null, true).then((v) => {
            setContainerTypes(v as { [k: string]: string });
        });
    }, []);

    return (
        <fieldset className="b-fieldset p-2 text-primary">
            <legend className="b-legend" style={{width: "112px"}}><h5 style={{width:"90pc"}}>Sorting Roles</h5>
            </legend>
            <SWAdvancedSearch
                cancelCb={() => setShowAdvanced(false)}
                className="m-0 p-0"
                filter={(fg) => {
                    const newUrl = `${loc.pathname}?${filterGroupToQueryParams({
                        filters: fg,
                        page: 0,
                        limit: 10,
                    })}`;
                    hist.push(newUrl);
                }}
                filterGroup={filterGroup}
                defaultFilters={[
                    {
                        field: "Value",
                        type: FilterType.Equals,
                        inputType: "text",
                        value: "",
                    },
                ]}
                availableFilters={[
                    {
                        field: "ContainerType",
                        type: FilterType.Equals,
                        inputType: "select",
                        value: "",
                        lookUp: containerTypes,
                    },
                    {
                        field: "ContainerPrefix",
                        type: FilterType.Equals,
                        inputType: "text",
                        value: "",
                    },
                    {
                        field: "Value",
                        type: FilterType.Equals,
                        inputType: "text",
                        value: "",
                    },
                    {
                        field: "PropertyName",
                        type: FilterType.Equals,
                        inputType: "text",
                        value: "",
                    },
                ]}
            />
        </fieldset>
    );
};

export default SearchForm;
