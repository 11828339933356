import {CSpinner, CDataTable, CButton} from "@coreui/react";
import React, {useMemo, useState} from "react";
import {AppModel} from "../../../../models/app";
import {UserCreateRequest, UserSearch, UserUpdate} from "../../../../models/user";
import {SetUserPage, SetUserResult} from "../../../../store/actions/users";
import PageCount from "../../../SharedComponents/PageCount";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router";
import {ContainerSearch} from "../../../../models/container";
import {ChargeDto} from "../../../../models/accounting";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import AuthClient from "../../../../clients/authClient";
import UpdateUserModal from "./UpdateUserModal";

interface Props {
    userRole: string
}

const SearchResult = (props: Props) => {
    const dispatch = useDispatch();
    const hist = useHistory();
    const loc = useLocation();
    const modifiedSearch = new URLSearchParams(loc.search);
    const userClient = new AuthClient()
    const [openModalUpdate, setOpenModalUpdate] = useState<string | null>(null)
    const [updateUserLoader, setUpdateUserLoader] = useState<boolean>(false)

    const userSearchResult = useSelector((state: AppModel) => {
        return {
            result: state.user.usersSearch.result,
            isLoading: state.user.usersSearch.isLoading,
            page: state.user.usersSearch.page,
            filterGroup: state.user.usersSearch.filterGroup,
            maxPage: Math.ceil(
                state.user.usersSearch.count! / state.user.usersSearch.limit!
            ),
        };
    });
    const updateModalHandler = (id: string) => {
        setOpenModalUpdate(id)
    }
    const initialUpdateData = useMemo(() => {
        return userSearchResult.result?.find((user) => user.accountId == openModalUpdate)
    }, [openModalUpdate])

    const handleUpdateSubmit = async (data: UserUpdate) => {
        setUpdateUserLoader(true)
        const res = await userClient.UpdateUser(openModalUpdate!, data)
        if (res.succeeded) {
            setOpenModalUpdate(null)
            modifiedSearch.delete("page");
            hist.push(`${loc.pathname}?${modifiedSearch.toString()}&page=0`);
            const result = await userClient.SearchUsers({})
            dispatch(SetUserResult(result.page!, result.count!, result.result!))
        }
        setUpdateUserLoader(false)
    }

    const scopedSlots = {
        actions: (item: UserSearch) => {
            return <td>{props.userRole.toLowerCase() === 'admin' && <CIcon
                content={freeSet.cilPencil}
                style={{cursor: "pointer", margin: "auto"}}
                onClick={() => updateModalHandler(item.accountId)}
            />}</td>
        }
    }
    return (
        <div className="small">
            {userSearchResult.isLoading ? (
                <CSpinner
                    className="mx-auto d-block my-5"
                    color="primary"
                    style={{width: "5em", height: "5em"}}
                />
            ) : (
                <>
                    <CDataTable
                        size="sm"
                        // onRowClick={(e: UserSearch) =>
                        //   hist.push(`/settings/users/${e.username}`)
                        // }
                        items={userSearchResult.result}
                        scopedSlots={scopedSlots}
                        fields={["username", "email", "name", "emailProvider", 'actions']}
                    />
                    <PageCount
                        maxFound={1}
                        onPageChange={(p) => {
                            modifiedSearch.delete("page");
                            hist.push(
                                `${loc.pathname}?${modifiedSearch.toString()}&page=${p}`
                            );
                        }}
                        onPageSizeChange={(ps) => {
                            modifiedSearch.delete("limit");
                            modifiedSearch.delete("page");

                            hist.push(
                                `${loc.pathname}?${modifiedSearch.toString()}&limit=${ps}&page=0`
                            );
                        }}
                        currentPage={userSearchResult.filterGroup?.page ?? 0}
                        maxPage={Math.ceil(
                            userSearchResult.maxPage! / (Number(modifiedSearch.get('limit') ?? 0) / 10)
                        )}/>
                </>
            )}
            {openModalUpdate &&
                <UpdateUserModal initialValue={initialUpdateData ?? {} as UserUpdate} loader={updateUserLoader}
                                 onSubmit={handleUpdateSubmit} onClose={() => setOpenModalUpdate(null)}/>}
        </div>
    );
};

export default SearchResult;
