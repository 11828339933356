import React from 'react';
import {  useSelector } from 'react-redux';
import { AppModel } from '../../../models/app';
import AgentsRates from './components/agentsRates';

const AgentsRatesPage = ({ }) => {
    const data = useSelector((state: AppModel) => state.shipment?.createShipmentData);
    return (
     <AgentsRates data={data} />
    )

}

export default AgentsRatesPage;