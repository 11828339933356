
import React from 'react';

interface IProps {
    link: string
}

const PdfViewer = ({ link }: IProps) => {
    return (
        <iframe src={link} height="85%" width="100%">
        </iframe>
    );
};

export default PdfViewer;
