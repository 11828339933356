import {
    CCol,
    CContainer,
    CDataTable, CDropdown, CDropdownItem, CDropdownMenu,
    CDropdownToggle,
    CInputCheckbox, CRow,
    CSpinner,
} from "@coreui/react";
import React, {useEffect, useState} from "react";
import ShipClient from "../../../clients/shipClient";
import {Form, Input} from "../../SharedComponents/SWForm";
import {
    GenerateLabelRequest,
    ScanResult, ShipmentAttachment,

} from "../../../models/shipment";
import ActionButton from "../../SharedComponents/ActionButton";
import {Report} from "../../../models/Report";
import LookupsClient from "../../../clients/lookupsClient";
import {SetLoading} from "../../../store/actions/auth";
import {AppModel, RemoteBlob} from "../../../models/app";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import PrintOriginReciptLabelModal from "./PrintOriginReciptModal";


const ScanShipments = () => {
    const shipClient = new ShipClient();
    const [report, setReport] = useState<Report[]>([])
    const [ScansAndPrints, setScansAndPrints] = useState<ScanResult[]>([]);
    const [print, setPrint] = useState<boolean>(false);
    const [scan, setScan] = useState<boolean>(false);
    const [working, setWorking] = useState(false);
    const [number, setNumber] = useState<number>();
    const [printMethod, setPrintMethod] = useState<Report>();
    const [printModal, setPrintModal] = useState<{
        uid: string, number: string, location: string
    } | null>(null)
    const dispatch = useDispatch();
    const {id} = useParams() as any;
    const loading = useSelector((state: AppModel) => state.app?.isLoading);
    useEffect(() => {
        let lookupsClient = new LookupsClient();
        lookupsClient.GetUiReports("OriginReceiptPrint").then(v => setReport(v))
    }, [])

    const scopedSlots = {
        containerPrefix: (data: ScanResult) => {
            return (
                <td>
                    {data.color ? (
                        <span
                            className="badge badge-pill p-2"
                            style={{backgroundColor: data.color}}
                        >
              {data.containerPrefix}
            </span>
                    ) : (
                        <span className="badge badge-pill badge-primary p-2">
              {data.containerPrefix}
            </span>
                    )}
                </td>
            );
        },
        status: (data: ScanResult) => {
            return (
                <td
                    className={data.status === "Success" ? "text-primary" : "text-danger"}
                >
                    {data.status}
                </td>
            );
        },
    };

    const handleReportDownload = async (body: any) => {
        dispatch(SetLoading(true))
        const rq: GenerateLabelRequest = {
            reportId: printMethod?.id ?? -1
        }

        let remoteBlob = await shipClient.GenerateAttachmentByNumber(body.number as string, rq) as RemoteBlob
        if (!remoteBlob?.location)

            return;
        else {
            setPrintModal({
                uid: remoteBlob.model?.uid ?? '-1',
                number: body?.number,
                location: remoteBlob.location

            })

        }


        dispatch(SetLoading(false))
    }
    const scanShipment = async (body: any) => {
        dispatch(SetLoading(true))
        let scan: ScanResult;
        if (!body.number) return;
        const traceRs = await shipClient.AddTraceByCode(body.number, "SHOR020");
        if (traceRs.succeeded) {
            const originRs = await shipClient.OriginReceipt(body.number);
            scan =
                originRs.succeeded && originRs.data.containerNumber
                    ? {
                        containerNumber: originRs.data.containerNumber,
                        containerPrefix: originRs.data.containerNumber.split(".")[0],
                        status: originRs.data.isDuplicate ? "Duplicate Scan" : "Success",
                        shipmentNumber: body.number,
                        color: originRs.data.sortingRuleInformation?.extraData?.color,
                    }
                    : {
                        containerNumber: "No container applicable",
                        containerPrefix: "N/A",
                        status: originRs.data?.SWException?.join(", ") ?? "Error",
                        shipmentNumber: body.number,
                    };
        } else {
            scan = {
                containerNumber: "No container applicable",
                containerPrefix: "N/A",
                status: `Shipment not found`,
                shipmentNumber: body.number,
            };
        }
        if (ScansAndPrints.length > 10) {
            ScansAndPrints.pop();
        }
        setScansAndPrints([scan, ...ScansAndPrints]);
        dispatch(SetLoading(false))

    };

    return (
        <>
             <CContainer className="bg-white p-4 text-primary small">
                <h5 style={{color: "gray"}}>Origin Receipt</h5>
                {printModal != undefined ?
                    <PrintOriginReciptLabelModal data={printModal} closeModal={() => {
                        setPrintModal(null)
                    }}/>
                    : <Form
                        className="my-4"
                        onSubmit={async (body) => {
                            if (working) return;
                            setWorking(true);
                            if (scan) {
                                await scanShipment(body);
                            }
                            if (print) {
                                await handleReportDownload(body);
                            }


                            setWorking(false);
                            setNumber(undefined)
                            return {number: ""};
                        }}
                    >

                        <CRow>
                            <CCol md={4}>
                            <Input readonly={working} type="text" field="number" label="Number" onChange={(e) => {
                                setNumber(e ? e as number : undefined)
                            }}/>
                                {print ? <CDropdown className={'w-50'}>
                                    <CDropdownToggle className={'border-primary'}>
                                        {printMethod?.name ?? "Select..."}

                                    </CDropdownToggle>
                                    <CDropdownMenu>
                                        {report?.map((r) => (
                                            <CDropdownItem onClick={() => setPrintMethod(r)}
                                                           key={r.id}>{r.name}</CDropdownItem>
                                        ))}
                                    </CDropdownMenu>
                                </CDropdown> : <></>}
                                <ActionButton
                                    text={"Submit"}
                                    disabled={working}
                                    extraClass={'mt-4'}
                                />
                            </CCol>
                            <CCol md={4}>
                                <div className={'ml-4 mb-2'}>
                                    <CInputCheckbox
                                        checked={scan}
                                        onChange={() => {
                                            setScan(!scan);
                                        }}/> Scan
                                </div>
                                <div className={'ml-4 mb-2'}><CInputCheckbox
                                    checked={print}

                                    onChange={() => {
                                        setPrint(!print);
                                    }}
                                /> Print
                                </div>
                              
                            </CCol>

                        </CRow>
                    </Form>}
                {!loading ? <CDataTable
                    scopedSlots={scopedSlots}
                    fields={[
                        "shipmentNumber",
                        "status",
                        "containerNumber",
                        "containerPrefix",
                    ]}
                    items={ScansAndPrints}
                /> : <CSpinner
                    className="mx-auto d-block my-5"
                    color="primary"
                    style={{width: "5em", height: "5em"}}
                />}
            </CContainer>
        </>
    );
};

export default ScanShipments;
