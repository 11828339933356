import {CButton, CCard, CContainer, CDataTable, CLink, CRow, CTooltip} from "@coreui/react";
import React, {useEffect, useState} from "react";
import ShipClient from "../../../clients/shipClient";
import {ErrorDescription, ShipmentsImportSearch} from "../../../models/shipment";
import {useHistory, useParams} from "react-router";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle, faEye} from "@fortawesome/free-solid-svg-icons";
import {convertToLocalDate, formatDate, formatDateTime} from "../../../utils/dateUtil";
import ImportErrorTable from "./Modals/ImportErrorTable";

interface Props {
}


const SearchImport = (props: Props) => {

    const shipClient = new ShipClient();
    const {id} = useParams() as any;

    const [importRecords, setImportRecords] = useState<ShipmentsImportSearch[] | undefined>(undefined)
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
    const [selectedErrors, setSelectedErrors] = useState<ErrorDescription[]>([])
    const [selectedAccount, setSelectedAccount] = useState<string>()

    useEffect(() => {
        const loadImport = async () => {
            const rs = await shipClient.getShipmentsImport({limit: 400})
            setImportRecords(rs.result);
        }
        loadImport();
    }, [])

    const hist = useHistory()

    const scopedSlots = {
        links: (item: ShipmentsImportSearch) => {
            return <td>

                <CLink to={`/shipments/import/${item.id}`} className="card-header-action">
                    <FontAwesomeIcon icon={faEye}/>

                </CLink>

            </td>;
        },
        hasErrors: (item: ShipmentsImportSearch) => {
            return item.errorDescription && item.errorDescription.length > 0 ?
                <td>
                    <CContainer>
                        <CTooltip content={"Show error(s)"}>
                            <FontAwesomeIcon icon={faExclamationCircle} size="lg"
                                             color={"red"}
                                             style={{cursor: "pointer"}}
                                             onClick={() => {
                                                 setSelectedAccount(item.accountName)
                                                 setSelectedErrors(item.errorDescription)
                                                 setShowErrorModal(true)
                                             }}
                            />
                        </CTooltip>
                    </CContainer>
                </td> :
                <td/>
        },
        createdOn: (item: ShipmentsImportSearch) => {
            return <td>{formatDateTime(convertToLocalDate(item.createdOnFormatted))}</td>
        }
    };
    return (
        <CCard className="bg-white p-4 text-black">
            <CRow>
                <CTooltip content="Back">
                    <CButton>
                        <CIcon
                            content={freeSet.cilArrowLeft}
                            onClick={() => hist.push("/shipments/create_import")}
                        />
                    </CButton>
                </CTooltip>
            </CRow>
            <CDataTable
                size="sm"
                hover
                scopedSlots={scopedSlots}
                items={importRecords}
                fields={[
                    {key: "links", label: "", _style: {width: '7%', padding: '0.25rem'}},
                    {key: "hasErrors", label: "", _style: {width: '2%', padding: '0.25rem'}},
                    {key: "id", label: "id"},
                    {key: "accountName", label: "account"},
                    {key: "createdOn", label: "On"},
                    {key: "createdBy", label: "By"},
                    {key: "finished", label: "completed"},
                    {key: "totalRows", label: "Rows"},
                    {key: "rowsImported", label: "Processed"},
                    {key: "shipmentsAdded", label: "Added"},
                    {key: "shipmentsUpdated", label: "Updated"},
                ]}

            />
            {showErrorModal && <ImportErrorTable account={selectedAccount} handleClose={() => {
                setSelectedAccount(undefined)
                setSelectedErrors([])
                setShowErrorModal(false)
            }} errors={selectedErrors}/>}
        </CCard>
    );
}

export default SearchImport;
