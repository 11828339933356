import {CCard, CContainer, CNav, CNavLink, CTabContent, CTabPane, CTabs} from "@coreui/react";
import React, {Fragment, useEffect, useState} from "react";
import PendingReportTable from "./components/PendingReportTable";
import GeneratedReportsTable from "./components/GeneratedReportsTable";
import ShipClient from "../../../clients/shipClient";
import {useHistory, useLocation} from "react-router";
import {useDispatch} from "react-redux";
import {ShowNotification} from "../../../store/actions/auth";

const MonthReporting = () => {
    const [profile, setProfile] = useState("")
    const [trigger, setTrigger] = useState<boolean>(false)
    const shipClient = new ShipClient();
    const [profileLookup, setProfileLookup] = useState<{ [k: string]: string }>({})
    const hist = useHistory();
    const loc = useLocation();
    const dispatch = useDispatch()
    let {search} = useLocation();
    useEffect(() => {
        shipClient.getProfilesReporting().then(res => {
            if (res.status == 200) {

                let data = Object.assign({}, ...res.data.map((x:any) => ({[x.id]: x.name})));

                
                setProfileLookup(data);
                
                const defaultList = res.data?.filter((item: any) => item.isDefault)
                const params = new URLSearchParams(search)
                if (defaultList.length > 0) {
                    if (search.includes("profile")) {
                        const profile = params.get("profile")
                        setProfile(profile ?? "")
                    } else {
                        setProfile(defaultList[0]?.id)
                        setProfileSearch(defaultList[0]?.id)
                    }
                } else {
                    setProfile(res.data[0]?.id)
                }
            }
        })
    }, [search])

    const setProfileSearch = (id: string | number) => {
        hist.push({
            pathname: loc.pathname,
            search: '?profile=' + id
        })
    }

    return (
        <Fragment>
            <CContainer className="bg-white p-4 text-primary">
                <CTabs>
                    <CCard className="small">
                        <CNav variant="tabs">
                            <CNavLink>Pending Reports</CNavLink>
                            <CNavLink onClick={() => setTrigger(!trigger)}>Generated Reports</CNavLink>
                        </CNav>
                        <CTabContent>
                            <CTabPane>
                                <PendingReportTable
                                    profile={profile}
                                    profileLookup={profileLookup} onChange={(e) => {
                                    setProfile(e)
                                    setProfileSearch(e)
                                }}
                                />
                            </CTabPane>
                            <CTabPane>
                                <GeneratedReportsTable
                                    profile={profile}
                                    profileLookup={profileLookup}
                                    onChange={(e) => {
                                        setProfile(e)
                                        setProfileSearch(e)
                                    }}
                                    trigger={trigger}
                                />
                            </CTabPane>
                        </CTabContent>
                    </CCard>
                </CTabs>
            </CContainer>
        </Fragment>
    )
}

export default MonthReporting