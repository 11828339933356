import { CContainer, CRow, CCol, CModal, CModalBody, CModalHeader, CModalFooter, CButton } from "@coreui/react";
import { Zone, zoneTypeOptions } from "../../../../../models/retes";
import SWInput from "../../../../SharedComponents/SWInput";
import SWSelect from "../../../../SharedComponents/SWSelect";
import { object, string } from "yup";
import { useFormik } from "formik";

type Props = {
    valuesToUpdate?: Zone;
    countryLookups: { [k: string]: string }
    onClose: () => void
    onAdd?: (arg: Zone) => void;
    onUpdate?: (arg: Zone) => void;
    isUpdate?: boolean
    title: string
}

const ZoneModal = ({ valuesToUpdate, countryLookups, onClose, onAdd, onUpdate, isUpdate, title }: Props) => {

    const initialValues: Zone = {
        code: valuesToUpdate?.code ?? "",
        country: valuesToUpdate?.country ?? "",
        fromPostCode: valuesToUpdate?.fromPostCode ?? null,
        toPostCode: valuesToUpdate?.toPostCode ?? null,
        type: valuesToUpdate?.type ?? "",
        value: valuesToUpdate?.value ?? "",
        pickup: valuesToUpdate?.pickup ?? false,
        agent: valuesToUpdate?.agent ?? "",
        delivery: valuesToUpdate?.delivery ?? false,
    }
    const validationSchema = () => object().shape({
        code: string().required("Required"),
        country: string().required("Required"),
        type: string().required("Required"),
    })
    const handleSubmit = (values: Zone, { resetForm }: any) => {
        isUpdate ? onUpdate?.(values) : onAdd?.(values)
        resetForm()
        onClose()
    }
    const formik = useFormik<Zone>({
        initialValues: initialValues,
        onSubmit: handleSubmit,
        validationSchema: validationSchema
    })
    const { values, setFieldValue, touched, errors, submitForm } = formik

    return (
        <CModal
            closeOnBackdrop={false}
            show={true}
            size={"xl"}
            className="small text-primary"
            centered
            onClose={() => onClose()}
        >
            <CModalHeader closeButton>
                <h5>{title}</h5>
            </CModalHeader>
            <CModalBody>
                <CContainer className="form-group mb-0 mt-2">
                    <CRow>
                        <CCol className="col-sm-3">
                            <SWInput
                                label="Zone"
                                name="code"
                                value={values?.code ?? ""}
                                onChange={(e) => {
                                    setFieldValue("code", e)
                                }}
                                validationErrors={touched?.code && errors?.code?.toString() || ""}
                            />
                        </CCol>

                        <CCol className="col-sm-3">
                            <SWSelect
                                label="Type"
                                name="type"
                                value={values?.type ?? ""}
                                onChange={(e) => {
                                    setFieldValue("type", e)
                                }}
                                values={zoneTypeOptions}
                                validationErrors={touched?.type && errors?.type?.toString() || ""}
                            />
                        </CCol>
                        <CCol className="col-sm-3">
                            <SWSelect
                                label="Country"
                                name="country"
                                value={values?.country ?? ""}
                                onChange={(e) => {
                                    setFieldValue("country", e)
                                }}
                                values={countryLookups}
                                validationErrors={touched?.country && errors?.country?.toString() || ""}
                            />
                        </CCol>
                        <CCol className="col-sm-3" />
                        <CCol className="col-sm-3">
                            <SWInput
                                type="number"
                                label="From Post Code"
                                name="fromPostCode"
                                value={values?.fromPostCode?.toString() ?? ""}
                                onChange={(e) => {
                                    setFieldValue("fromPostCode", e)
                                }}
                                validationErrors={touched?.fromPostCode && errors?.fromPostCode?.toString() || ""}
                            />
                        </CCol>
                        <CCol className="col-sm-3">
                            <SWInput
                                type="number"
                                label="To Post Code"
                                name="toPostCode"
                                value={values?.toPostCode?.toString() ?? ""}
                                onChange={(e) => {
                                    setFieldValue("toPostCode", e)
                                }}
                                validationErrors={touched?.toPostCode && errors?.toPostCode?.toString() || ""}
                            />
                        </CCol>
                        <CCol className="col-sm-3">
                            <SWInput
                                label="Value"
                                name="value"
                                value={values?.value ?? ""}
                                onChange={(e) => {
                                    setFieldValue("value", e)
                                }}
                                validationErrors={touched?.value && errors?.value?.toString() || ""}
                            />
                        </CCol>
                    </CRow>
                </CContainer>
            </CModalBody>
            <CModalFooter>
                <CButton
                    size="sm"
                    style={{ fontSize: "10px" }}
                    className="mr-2"
                    color="primary"
                    onClick={() => submitForm()}
                >
                    Save
                </CButton>
            </CModalFooter>
        </CModal>
    )
}

export default ZoneModal;